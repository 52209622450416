import { updateWlDeviceAction } from 'actions/admin/device';
import { closeModalAction } from 'actions/ui';
import { required } from 'helpers/validators';
import isEmpty from 'lodash/isEmpty';
import { Field, getFormValues, reduxForm } from 'redux-form';

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import Button from 'components/Button';
import RenderField from 'components/RenderField';

class DeviceForm extends Component {
  render() {
    const {
      pristine,
      submitting,
      updateWlDevice,
      handleSubmit,
      formValues,
      closeModal,
    } = this.props;

    return (
      <Fragment key='admin-device'>
        {!isEmpty(formValues) && (
          <img
            className='edit-device'
            src={formValues.wlUrl}
            alt={'device-img'}
          />
        )}

        <form className='add-device-id'>
          <Field
            name='wlUrl'
            type='text'
            component={RenderField}
            validate={required()}
            label={t({
              id: 'device.form.image.placeholder',
              message: `Image URL`,
            })}
            testSelector='image-url'
          />

          <Button
            classes='inverted'
            onClick={closeModal}
            testSelector='cancel-form'
          >
            <Trans id='device.form.cancel.button'>Cancel</Trans>
          </Button>

          <Button
            type='submit'
            disabled={pristine || submitting}
            onClick={handleSubmit(updateWlDevice)}
            testSelector='submit-form'
          >
            <Trans id='device.form.submit.button'>Submit</Trans>
          </Button>
        </form>
      </Fragment>
    );
  }
}

DeviceForm = reduxForm({
  form: 'admin-device',
})(DeviceForm);

DeviceForm = connect(
  (state) => ({
    formValues: getFormValues('admin-device')(state),
  }),
  {
    updateWlDevice: updateWlDeviceAction,
    closeModal: closeModalAction,
  },
)(DeviceForm);

export default DeviceForm;
