import { searchSalesAction } from 'actions/sales';
import {
  formatDateForDatepicker,
  optionsToArrayObjs,
} from 'helpers/formatters';
import { length } from 'helpers/validators';
import isEmpty from 'lodash/isEmpty';
import {
  change,
  clearFields,
  getFormValues,
  initialize,
  isDirty,
  isInvalid,
} from 'redux-form';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';

import Dropdown from 'components/Dropdown';
import Form from 'components/Form';
import Heading from 'components/Heading';
import RenderDatePicker from 'components/RenderDatePicker';

import { defaultCartStatus, pendingCartStatus } from 'constants/contracts';
import { cartStatus, contractPeriod } from 'constants/status';
import { ADMIN, VENDOR_MANAGER } from 'constants/user';

import './Filter.styl';
import { SEARCH_FORM_NAME } from './Search';

export const FILTER_FORM_NAME = 'SalesForm-Filter';
const INPUT_PERIOD = 'contractPeriod';
const INPUT_STATUS = 'contractStatus';
const INPUT_DATE = 'dateRange';
const INPUT_STORE = 'storeID';

const Filter = ({ initialValues, isSales }) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useSelector(({ user }) => user);
  const showStoreDropdown = [ADMIN, VENDOR_MANAGER].includes(user.role);

  const formValues = useSelector((state) =>
    getFormValues(FILTER_FORM_NAME)(state),
  );

  const isFormDirty = useSelector((state) => isDirty(FILTER_FORM_NAME)(state));
  const isFormInvalid = useSelector((state) =>
    isInvalid(FILTER_FORM_NAME)(state),
  );

  const isSearchFormDirty = useSelector((state) =>
    isDirty(SEARCH_FORM_NAME)(state),
  );
  const isFormLocked = () => isSearchFormDirty;

  const defaultStatusList = isSales ? defaultCartStatus : pendingCartStatus;

  // use effect to perform request on params change
  useEffect(() => {
    dispatch(initialize(FILTER_FORM_NAME, initialValues, false));

    if (searchParams.get('activeForm') === FILTER_FORM_NAME) {
      if (Array.from(searchParams).length === 1) {
        let [startDate, endDate] = initialValues.dateRange.split(' - ');

        dispatch(
          searchSalesAction({
            filter: {
              ...initialValues,
              startDate,
              endDate,
            },
            onlySalesWithPendingActions: !isSales,
          }),
        );
      } else {
        const paramPeriod = searchParams.get(INPUT_PERIOD);
        const paramStatus = searchParams.getAll(INPUT_STATUS);
        const paramDateRange = searchParams.get(INPUT_DATE);
        const dateRange = paramDateRange.split(' - ');
        const startDate = dateRange[0];
        const endDate = dateRange[1];
        const paramStore = searchParams.get(INPUT_STORE);

        dispatch(change(FILTER_FORM_NAME, INPUT_PERIOD, paramPeriod));
        dispatch(change(FILTER_FORM_NAME, INPUT_STATUS, paramStatus));
        dispatch(change(FILTER_FORM_NAME, INPUT_DATE, paramDateRange));
        paramStore &&
          dispatch(change(FILTER_FORM_NAME, INPUT_STORE, paramStore));

        dispatch(
          searchSalesAction({
            filter: {
              [INPUT_PERIOD]: paramPeriod,
              [INPUT_STATUS]: paramStatus,
              startDate: formatDateForDatepicker(startDate),
              endDate: formatDateForDatepicker(endDate),
              [INPUT_STORE]: paramStore,
            },
            onlySalesWithPendingActions: !isSales,
          }),
        );
      }
    }
  }, [dispatch, initialValues, searchParams, isSales]);

  const fields = [
    {
      component: Dropdown,
      placeholder: t({
        id: 'reports.form.filters.contract.period.label',
        message: `Date type`,
      }),
      name: INPUT_PERIOD,
      options: contractPeriod(),
      classes: 'sales-form__reference',
      autoComplete: 'date-type-filter',
      testSelector: 'sales-filter-date-type',
      disabled: isFormLocked(),
    },
    {
      component: Dropdown,
      placeholder: t({
        id: 'reports.form.filters.contract.status.label',
        message: `Contract Status`,
      }),
      name: INPUT_STATUS,
      options: Object.entries(cartStatus()).reduce((all, [value, label]) => {
        return defaultStatusList.indexOf(value) > -1
          ? [...all, { value, label: label.label }]
          : [...all];
      }, []),
      multiplePlaceholder: t({
        id: 'reports.form.filters.contract.status.multiple.placeholder',
        message: `XX of YY selected`,
      }),
      type: 'select-multiple',
      validate: [
        length({
          msg: {
            tooShort: t({
              id: 'reports.form.filters.contract.status.error',
              message: `requires at least 1 option selected`,
            }),
          },
          min: 1,
        }),
      ],
      classes: 'sales-form__contract-status',
      autoComplete: 'contract-status-filter',
      testSelector: 'sales-filter-contract-status',
      disabled: isFormLocked(),
      showInputNameError: false,
    },
    {
      component: RenderDatePicker,
      label: t({
        id: 'reports.form.filters.dates.label',
        message: `Date range`,
      }),
      name: INPUT_DATE,
      classes: 'sales-form__date-range',
      variant: 'full-border',
      autoComplete: 'date-range-filter',
      testSelector: 'sales-filter-date-range',
      disabled: isFormLocked(),
      startDate: formValues?.dateRange.split(' - ')[0],
      endDate: formValues?.dateRange.split(' - ')[1],
      minDate: new Date(2018, 0, 1),
      maxDate: new Date(),
      rangeOptions: {
        selectsRange: true,
        showMonthDropdown: true,
        showYearDropdown: true,
        dropdownMode: 'select',
      },
    },
  ];

  if (showStoreDropdown) {
    fields.push({
      component: Dropdown,
      placeholder: t({
        id: 'reports.form.filters.store.label',
        message: `Store`,
      }),
      name: INPUT_STORE,
      options: optionsToArrayObjs(user.stores),
      classes: 'sales-form__store',
      autoComplete: 'store-filter',
      testSelector: 'sales-filter-store',
      disabled: isFormLocked(),
    });
  }

  const isClearDisabled = () =>
    isFormLocked() || isEmpty(formValues) || !isFormDirty;
  const cleanActionHandler = () => {
    setSearchParams({ activeForm: FILTER_FORM_NAME });
    dispatch(
      clearFields(
        FILTER_FORM_NAME,
        false,
        false,
        INPUT_PERIOD,
        INPUT_STATUS,
        INPUT_DATE,
        INPUT_STORE,
      ),
    );
  };

  const isSubmitDisabled = () =>
    isFormLocked() || isEmpty(formValues) || isFormInvalid;
  const submitActionHandler = () =>
    setSearchParams({
      activeForm: FILTER_FORM_NAME,
      ...formValues,
      timestamp: Date.now(),
    });

  const actionButtons = [
    {
      disabled: isClearDisabled(),
      handleClick: cleanActionHandler,
      label: t({ id: 'reports.form.clear.button', message: 'Clear' }),
      name: 'form-clear-btn',
      testSelector: 'filter-form-clear',
    },
    {
      disabled: isSubmitDisabled(),
      type: 'submit',
      label: t({ id: 'reports.form.apply.button', message: 'Apply' }),
      name: 'form-apply-btn',
      testSelector: 'filter-form-submit',
    },
  ];

  return (
    <div className='sales-form__filter-container'>
      <Heading size='medium' data-test='sales-filters-title'>
        <Trans id='reports.form.filters.title'>Filter</Trans>
      </Heading>
      <p data-test='sales-filter-description'>
        <Trans id='reports.form.filters.subtitle'>
          Choose multiple options to filter sales contracts
        </Trans>
      </p>

      <Form
        onSubmit={submitActionHandler}
        form={FILTER_FORM_NAME}
        classes='sales-form__filter'
        customFieldValidation
        destroyOnUnmount
        fields={fields}
        actionButtons={actionButtons}
        initialValues={initialValues}
        enableReinitialize
      />
    </div>
  );
};

export default Filter;
