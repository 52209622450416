import { upsertCollectionsAction } from 'actions/admin/collection';
import { clearCollectionTimes, getCollectionTimes } from 'actions/recycle';
import { addNotificationAction, openModalAction } from 'actions/ui';
import {
  email,
  format,
  length,
  numericality,
  required,
} from 'helpers/validators';
import isEmpty from 'lodash/isEmpty';
import { Field, getFormValues, initialize, reduxForm } from 'redux-form';

import React, { Fragment, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';

import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import RenderDatePicker, { formatDate } from 'components/RenderDatePicker';
import RenderField from 'components/RenderField';

import { techDataNorway } from 'constants/collections';
import { phoneRegex } from 'constants/forms';
import { createCollectionSuccess } from 'constants/notifications';
import { adminCollectionsUrl } from 'constants/routes';
import { eplehuset } from 'constants/whitelabels';

const CollectionTimeFields = ({ countryCode, postalCode }) => {
  const collectionDates = useSelector(({ recycle }) => recycle.collectionDates);
  const collectionTimeSlots = useSelector(
    ({ recycle }) => recycle.collectionTimeSlots,
  );

  const collectionTimeSlotOptions =
    collectionTimeSlots &&
    Object.entries(collectionTimeSlots)?.map(([value, label]) => ({
      value,
      label,
    }));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearCollectionTimes());
  }, [dispatch]);

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      if (countryCode && postalCode) {
        dispatch(getCollectionTimes({ countryCode, postalCode }));
      } else {
        dispatch(clearCollectionTimes());
      }
    }, 2000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [countryCode, postalCode, dispatch]);

  return (
    <Fragment>
      <div className='collectionDateTimeContainer'>
        <Field
          name='collectionDate'
          component={RenderDatePicker}
          label={t({
            id: 'forms.recycle.placeholder.collection.date',
            message: `Collection Date`,
          })}
          format={formatDate}
          parse={formatDate}
          includeDates={collectionDates || []}
          validate={required()}
          testSelector='collectionDate'
        />

        <Field
          component={Dropdown}
          placeholder={t({
            id: 'forms.recycle.placeholder.collection.time',
            message: `Collection Time`,
          })}
          name='collectionTimeSlotCode'
          clearable
          options={collectionTimeSlotOptions}
          variant='underline'
          size='medium'
          validate={required()}
          testSelector='collectionTimeSlotCode'
        />
      </div>
    </Fragment>
  );
};

const CollectionsForm = ({
  invalid,
  submitting,
  handleSubmit,
  formValues,
  showCollectionTimeFields = false,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const country = useSelector(({ whitelabel }) => whitelabel.country);
  const collector = useSelector(
    (state) => getFormValues('collections-organise')(state)?.collector,
  );
  const whitelabelName = useSelector(({ whitelabel }) => whitelabel.shortName);

  const submitCollection = async (data) => {
    const response = await dispatch(
      upsertCollectionsAction({ ...data, collector }),
    );

    if (response.status === 'SUCCESS') {
      !isEmpty(response.url) ||
      (whitelabelName === eplehuset && collector === techDataNorway)
        ? dispatch(openModalAction('courier-details'))
        : navigate(adminCollectionsUrl);

      initialize('admin-collections');

      dispatch(addNotificationAction(createCollectionSuccess()));
    }
  };

  return (
    <>
      <form className='admin-collections'>
        <Field
          name='boxCount'
          type='text'
          component={RenderField}
          validate={[
            ...(country === 'ES'
              ? [
                  numericality({
                    int: true,
                    greaterThan: 0,
                    allowBlank: true,
                  }),
                ]
              : [required(), numericality({ int: true, greaterThan: 0 })]),
          ]}
          label={t({
            id: 'collection.form.boxes.placeholder',
            message: `Number of boxes`,
          })}
        />

        <Field
          name='totalWeight'
          type='text'
          component={RenderField}
          validate={[
            ...(country === 'ES'
              ? [
                  numericality({
                    int: true,
                    greaterThan: 0,
                    allowBlank: true,
                  }),
                ]
              : [required(), numericality({ int: true, greaterThan: 0 })]),
          ]}
          label={t({
            id: 'collection.form.weight.placeholder',
            message: `Weight of the collection (kg)`,
          })}
        />

        <Field
          name='contactPerson'
          type='text'
          component={RenderField}
          validate={[required(), length({ max: 255 })]}
          label={t({
            id: 'collection.form.contact.placeholder',
            message: `Contact person`,
          })}
        />

        <Field
          name='storeName'
          type='text'
          component={RenderField}
          validate={[required(), length({ max: 255 })]}
          label={t({
            id: 'collection.form.store.placeholder',
            message: `Store Name`,
          })}
        />

        <Field
          name='email'
          type='email'
          component={RenderField}
          validate={[required(), email(), length({ max: 255 })]}
          label={t({
            id: 'collection.form.email.placeholder',
            message: `Email`,
          })}
        />

        <Field
          name='phone'
          type='phone'
          component={RenderField}
          validate={[
            required(),
            format({ with: phoneRegex }),
            length({ max: 45 }),
          ]}
          label={t({
            id: 'collection.form.phone.placeholder',
            message: `Customer Phone`,
          })}
        />

        <Field
          name='streetAddress'
          type='text'
          component={RenderField}
          validate={[required(), length({ max: 255 })]}
          label={t({
            id: 'collection.form.address.placeholder',
            message: `Street address`,
          })}
        />

        <Field
          name='postalCode'
          type='text'
          component={RenderField}
          validate={[required(), length({ max: 45 })]}
          label={t({
            id: 'collection.form.zipcode.placeholder',
            message: `Postal code`,
          })}
        />

        <Field
          name='city'
          type='text'
          component={RenderField}
          validate={[required(), length({ max: 255 })]}
          label={t({ id: 'collection.form.city.placeholder', message: `City` })}
        />

        <Field
          name='country'
          type='text'
          component={RenderField}
          validate={[required(), length({ max: 255 })]}
          label={t({
            id: 'collection.form.country.placeholder',
            message: `Country`,
          })}
        />

        {showCollectionTimeFields && (
          <CollectionTimeFields
            countryCode={country}
            postalCode={formValues?.postalCode}
          />
        )}

        <Button
          //type='submit'
          disabled={invalid || submitting}
          onClick={handleSubmit(submitCollection)}
        >
          <Trans id='collection.form.submit.button'>Create Collection</Trans>
        </Button>
      </form>
    </>
  );
};

const ReduxCollectionsForm = reduxForm({
  form: 'admin-collections',
  destroyOnUnmount: false,
  enableReinitialize: true,
})(CollectionsForm);

export default connect((state) => ({
  country: state.whitelabel.country,
  formValues: getFormValues('admin-collections')(state),
}))(ReduxCollectionsForm);
