import { addNotificationAction } from 'actions/ui';
import { adminPost as post } from 'apis/v2';

import {
  changeTestContractStageSuccess,
  createTestContractSuccess,
  deleteTestContractSuccess,
  expireTestContractDelaySuccess,
} from 'constants/notifications';

export const GET_CRM_TEST_CONTRACTS = 'GET_CRM_TEST_CONTRACTS';
export function getCrmTestContractsAction() {
  return async function (dispatch) {
    const response = await post(GET_CRM_TEST_CONTRACTS);

    if (response.data.status === 'SUCCESS') {
      dispatch({
        type: GET_CRM_TEST_CONTRACTS,
        data: response.data.data.testContracts,
      });
    }
  };
}

export const GET_TEST_CONTRACT = 'GET_TEST_CONTRACT';
export function getTestContractAction(id) {
  return async function (dispatch) {
    const response = await post(GET_TEST_CONTRACT, { id });

    if (response.data.status === 'SUCCESS') {
      dispatch({
        type: GET_TEST_CONTRACT,
        testContract: response.data.data.testContract,
        messages: response.data.data.messages,
        availableStages: response.data.data.availableStages,
      });
    }
  };
}

export const GET_TEST_CONTRACT_FORM_DATA = 'GET_TEST_CONTRACT_FORM_DATA';
export function getTestContractFormDataAction() {
  return async function (dispatch) {
    const response = await post(GET_TEST_CONTRACT_FORM_DATA);

    if (response.data.status === 'SUCCESS') {
      const { campaignCodes, deviceFamilies, paymentPlans, triggerEvents } =
        response.data.data;
      dispatch({
        type: GET_TEST_CONTRACT_FORM_DATA,
        data: {
          campaignCodes: campaignCodes.map((campaignCode) => {
            return { value: campaignCode, label: campaignCode };
          }),
          deviceFamilies: deviceFamilies.reduce((acc, deviceFamily) => {
            acc.push({
              label: deviceFamily.name,
              value: deviceFamily.code,
            });
            return acc;
          }, []),
          paymentPlans: paymentPlans.reduce((acc, paymentPlan) => {
            acc.push({
              label: paymentPlan.name,
              value: paymentPlan.code,
            });
            return acc;
          }, []),
          triggerEvents: triggerEvents.map((triggerEvent) => {
            return { value: triggerEvent, label: triggerEvent };
          }),
        },
      });
    }
    return;
  };
}

export const CREATE_TEST_CONTRACT = 'CREATE_TEST_CONTRACT';
export function createTestContractAction({ contractID, ...testContract }) {
  return async function (dispatch) {
    const response = await post(CREATE_TEST_CONTRACT, {
      contractID,
      testContract,
    });

    if (response.data.status === 'SUCCESS') {
      dispatch(addNotificationAction(createTestContractSuccess()));
      dispatch({
        type: CREATE_TEST_CONTRACT,
        data: response.data.data.testContracts,
      });
    }
  };
}

export const DELETE_TEST_CONTRACT = 'DELETE_TEST_CONTRACT';
export function deleteTestContractAction(id) {
  return async function (dispatch) {
    const response = await post(DELETE_TEST_CONTRACT, { id });

    if (response.data.status === 'SUCCESS') {
      dispatch(addNotificationAction(deleteTestContractSuccess()));
    }
  };
}

export const CHANGE_TEST_CONTRACT_STAGE = 'CHANGE_TEST_CONTRACT_STAGE';
export function changeTestContractStageAction(id, { stage }) {
  return async function (dispatch) {
    const response = await post(CHANGE_TEST_CONTRACT_STAGE, { id, stage });

    if (response.data.status === 'SUCCESS') {
      dispatch(addNotificationAction(changeTestContractStageSuccess()));
      dispatch({
        type: GET_TEST_CONTRACT,
        testContract: response.data.data.testContract,
        messages: response.data.data.messages,
        availableStages: response.data.data.availableStages,
      });
    }
  };
}

export const EXPIRE_TEST_CONTRACT_DELAY = 'EXPIRE_TEST_CONTRACT_DELAY';
export function expireTestContractDelayAction(id) {
  return async function (dispatch) {
    const response = await post(EXPIRE_TEST_CONTRACT_DELAY, { id });

    if (response.data.status === 'SUCCESS') {
      dispatch(addNotificationAction(expireTestContractDelaySuccess()));
    }
  };
}
