import {
  addDiscountAction,
  addToCartAction,
  getPricedExtrasAction,
  removeDiscountAction,
  removeExtraAction,
  removeProductAction,
  saveSelectedPPAction,
  selectOptionAction,
} from 'actions/carts';
import { currencyFormatter } from 'helpers/formatters';
import isEmpty from 'lodash/isEmpty';
import { getCartFromOrder } from 'utils';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Trans } from '@lingui/macro';

import Section from 'components/Section';

import { cartPageUrl } from 'constants/routes';

import './Cart.styl';
import CartDiscountForm from './CartDiscountForm';
import CartItems from './CartItems';
import CartOptions from './CartOptions';
import PaymentDisclaimers from './PaymentDisclaimers';

const Cart = ({
  options,
  cart,
  currency,
  language,
  actionable,
  updateOptions,
  updateExtras,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isUpgrade = 'UPGRADE' === cart?.financingType;
  const isStandardFinance = 'STANDARD' === cart?.financingType;
  const showPaymentInfo = isUpgrade || isStandardFinance;
  const selectedService = cart?.selectedService;

  const availableDiscounts = useSelector(
    ({ whitelabel }) => whitelabel.configs.availableDiscounts,
  );
  const wlName = useSelector(({ whitelabel }) => whitelabel.name);
  const wlAddress = useSelector(({ whitelabel }) => whitelabel.wlAddress);
  const wlRegisteredName = useSelector(
    ({ whitelabel }) => whitelabel.wlRegisteredName,
  );
  const fcaNumber = useSelector(({ whitelabel }) => whitelabel.fcaNumber);

  const { cartUUID } = useParams();
  const { selected } = cart || {};
  const orders = cart.orders;
  const currentOrder = { isCurrentOrder: true, ...cart.current_order };
  const allOrders = [currentOrder, ...orders];
  const swapOrderUUID = allOrders.find((o) => o.swapOrderUUID)?.swapOrderUUID;
  const selectedPaymentPlan = options?.filter((p) => p.selected)[0] || [];
  const {
    discounts,
    totalPrincipal,
    totalMonthlyPayment,
    maxDiscounts,
    openingFeeAmount,
  } =
    allOrders?.reduce(
      (accumulator, current) => ({
        accumulator,
        discounts: [
          ...accumulator.discounts,
          ...(current.discounts?.items || []),
        ],
        totalPrincipal:
          accumulator.totalPrincipal +
          (current.financing?.deviceContractFinance?.principal || 0),
        totalMonthlyPayment:
          accumulator.totalMonthlyPayment +
          (current.financing?.deviceContractFinance?.monthlyPayment || 0),
        maxDiscounts:
          accumulator.maxDiscounts + (current.discounts?.max_discounts || 0),
        openingFeeAmount:
          accumulator.openingFeeAmount +
          (current.financing?.openingFeeAmount || 0),
      }),
      {
        discounts: [],
        totalPrincipal: 0,
        totalMonthlyPayment: 0,
        maxDiscounts: 0,
        openingFeeAmount: 0,
      },
    ) || {};

  const { deviceCode } = selected || {};

  const totalDiscounts = discounts.length;

  const orderWithUpfront = allOrders.find(
    (order) => order.financing?.openingFeePercentage > 0,
  );

  const openingFeePercentage =
    orderWithUpfront?.financing?.openingFeePercentage;

  const paymentPlanName =
    selectedPaymentPlan?.name || orderWithUpfront?.financing?.payment_plan_name;

  const hasOptions = options?.length > 1;

  const pricingIncludesTax = cart?.pricingIncludesTax;

  const formatPrice = (price) => currencyFormatter(price, currency, language);

  const changeOptions = async (option) => {
    await dispatch(saveSelectedPPAction(cartUUID, option.id));
    updateExtras &&
      (await dispatch(
        getPricedExtrasAction(cartUUID, deviceCode, {
          updateExtrasPrice: true,
        }),
      ));
  };

  const addDiscount = async (orderUUID, discount) => {
    await dispatch(addDiscountAction(orderUUID, discount));
    updateOptions && dispatch(selectOptionAction(cartUUID));
    updateExtras &&
      dispatch(
        getPricedExtrasAction(cartUUID, deviceCode, {
          updateExtrasPrice: true,
        }),
      );
  };

  const removeCallback = () => {
    updateOptions && dispatch(selectOptionAction(cartUUID));
    updateExtras &&
      dispatch(
        getPricedExtrasAction(cartUUID, deviceCode, {
          updateExtrasPrice: true,
        }),
      );
  };

  const removeCartItem = async (item) => {
    const { type, id, partNumber } = item;
    if (type === 'product') {
      await dispatch(removeProductAction(cartUUID, id, false, removeCallback));
    }
    if (type === 'extra') {
      await dispatch(
        removeExtraAction(cartUUID, id, removeCallback, partNumber),
      );
    }
    if (type === 'discount') {
      await dispatch(removeDiscountAction(cartUUID, id, removeCallback));
    }
  };

  const goToCartDetails = async () => {
    await dispatch(addToCartAction(cartUUID, true));
    navigate(cartPageUrl(cartUUID));
  };

  const gotToSwapOrder = async () => {
    window.open(`/posale/sales/${getCartFromOrder(swapOrderUUID)}`, '_blank');
  };

  return (
    <div className='cart__container'>
      <div>
        <h3>
          <Trans id='cart.container.title'>{`Your order ${cartUUID} summary`}</Trans>
        </h3>
        {swapOrderUUID && (
          <div className='cart__container__summary__swap__link'>
            <Trans id='cart.container.swap.link'>Swap</Trans>
            <button onClick={gotToSwapOrder}>{swapOrderUUID}</button>
          </div>
        )}
      </div>
      {selectedService && (
        <div>
          <h4>{selectedService.name}</h4>
          {selectedService.description && (
            <Trans id={selectedService.description}></Trans>
          )}
        </div>
      )}
      {paymentPlanName && (
        <div>
          <Section
            className='cart__container__options'
            hideById={hasOptions ? ['options'] : []}
            expanded={hasOptions}
          >
            <h4>
              <Trans id='cart.payment.options.header'>Payment options</Trans>
            </h4>
            <span>
              <Trans id='cart.payment.option.selected'>Selected</Trans>
              {`: ${paymentPlanName}`}
            </span>
            {hasOptions && (
              <CartOptions
                type='options'
                options={options}
                p
                formatPrice={formatPrice}
                onClick={changeOptions}
                actionable={actionable}
              />
            )}
          </Section>
        </div>
      )}
      <div className='cart__container__sections'>
        {[currentOrder, ...orders].map((order) => {
          const uuid = order.uuid;
          const financing = order.financing;
          const isCurrentOrder = order.isCurrentOrder;
          const totalCartItems =
            (order.products?.items.reduce((acc, product) => {
              return acc + product.quantity;
            }, 0) || 0) +
            (order.extras?.items.reduce((acc, extra) => {
              return acc + extra.quantity;
            }, 0) || 0);
          const type = isCurrentOrder
            ? 'CurrentProductsAndExtras'
            : 'productsAndExtras';
          return (
            totalCartItems > 0 && (
              <Section
                className='cart__container__sections__items'
                hideById={totalCartItems > 0 ? [type] : []}
                expanded={totalCartItems > 0}
                key={uuid}
              >
                <h4>
                  {`${uuid} / ${financing.payment_plan_name} `}
                  {!isCurrentOrder && (
                    <p>
                      <Trans id='cart.number.of.items'>{`${totalCartItems} Item(s) in cart`}</Trans>
                    </p>
                  )}
                </h4>

                <CartItems
                  type={type}
                  cartItems={{
                    products: order.products?.items || [],
                    extras: order.extras?.items || [],
                  }}
                  formatPrice={formatPrice}
                  actionable={actionable}
                  remove={removeCartItem}
                />
              </Section>
            )
          );
        })}
        <Section
          className='cart__container__sections__items'
          hideById={['discounts']}
          expanded={true}
        >
          <h4>
            <Trans id='cart.number.of.discounts'>{`${totalDiscounts} Discount(s)`}</Trans>
          </h4>
          <CartItems
            type='discounts'
            cartItems={{
              discounts,
            }}
            formatPrice={formatPrice}
            actionable={actionable}
            remove={removeCartItem}
            discountForm={
              actionable &&
              !isEmpty(availableDiscounts) && (
                <CartDiscountForm
                  availableDiscounts={availableDiscounts}
                  addDiscount={addDiscount}
                  max_discounts={maxDiscounts}
                  cartUUID={cartUUID}
                />
              )
            }
          />
        </Section>
      </div>
      <div className='cart__container__summary'>
        <div>
          <div>
            <span className='cart__container__summary__large'>
              <Trans id='cart.container.summary.total.per.month'>
                Total per month
              </Trans>
            </span>
          </div>

          <div className='cart__container__summary__details'>
            {!isUpgrade && actionable && (
              <button
                onClick={goToCartDetails}
                className='cart__container__summary__details__link'
              >
                <Trans id='cart.container.summary.view.edit.cart'>
                  View and edit Cart
                </Trans>
              </button>
            )}
          </div>
        </div>
        <div>
          <div>
            <span className='cart__container__summary__large'>
              {formatPrice(totalMonthlyPayment)}
            </span>
            {pricingIncludesTax ? (
              <span>&nbsp;</span>
            ) : (
              <Trans id='cart.summary.excl.vat'>(Excl. VAT)</Trans>
            )}
          </div>
          {openingFeePercentage >= 0 && (
            <div>
              <span className='cart__container__summary__medium'>
                {formatPrice(openingFeeAmount)}
              </span>
              <span>
                <Trans id='product.upfront'>{`${openingFeePercentage}% Upfront payment`}</Trans>
              </span>
            </div>
          )}
          <div>
            <span className='cart__container__summary__medium'>
              {formatPrice(totalPrincipal)}
            </span>
            <span>
              {!pricingIncludesTax ? (
                <Trans id='cart.container.summary.total.price.excl.vat'>
                  total price (Excl. VAT)
                </Trans>
              ) : (
                <Trans id='cart.container.summary.total.price.incl.vat'>
                  total price (Incl. VAT)
                </Trans>
              )}
            </span>
          </div>
        </div>
      </div>
      {showPaymentInfo && (
        <>
          <hr
            style={{
              margin: '0px',
              flexShrink: 0,
              borderWidth: '0px 0px thin',
              borderStyle: 'solid',
              borderColor: 'rgba(0, 0, 0, 0.12)',
              alignSelf: 'stretch',
              height: 'auto',
            }}
          />
          <PaymentDisclaimers
            language={language}
            order={currentOrder?.financing ? currentOrder : orders?.[0]}
            wlAddress={wlAddress}
            wlName={wlName}
            wlRegisteredName={wlRegisteredName}
            fcaNumber={fcaNumber}
            hasMultipleOrders={cart?.orders?.length > 1}
          />
        </>
      )}
    </div>
  );
};

Cart.propTypes = {};

export default Cart;
