import { t } from '@lingui/macro';

import { LOADER_TYPE_DEFAULT } from 'constants/ui';

const initalState = {
  app: {
    buildHash: null,
  },
  adminAcessories: {
    accessories: [],
    uploadReport: {
      errors: {},
    },
  },
  adminUser: {
    users: [],
  },
  adminStore: {
    stores: [],
  },
  adminCampaign: {
    campaigns: [],
    campaign: {},
  },
  adminCollection: {
    collection: {},
    collections: [],
    returnedDevices: [],
  },
  adminDevice: {
    device: {},
    devices: [],
    plans: [],
    prices: [],
  },
  adminTemplates: {
    templateParams: {},
    templateList: [],
    draftTemplate: {},
    liveTemplate: {},
    templateData: {},
  },
  adminAttachments: {
    attachments: [],
  },
  adminTestContracts: {
    testContracts: [],
    testContractMessages: [],
  },
  adminVoucher: {
    vouchers: [],
  },
  carts: {
    currentCart: undefined,
    list: [],
  },
  catalog: {
    categories: [],
    families: [],
    insuranceOptions: [],
    allProductsFetched: false,
  },
  contract: {
    customer: { firstName: '', lastName: '' },
    steps: {
      termsAndConditions: 'PENDING',
      creditSign: 'PENDING',
      deviceReference: 'PENDING',
      handOverDevice: 'PENDING',
    },
    deviceReference: null,
  },
  customer: {
    deviceReference: '',
    email: '',
    firstName: '',
    lastName: '',
    options: [
      {
        enabled: true,
        id: 'sms',
        name: t({ id: 'bank.link.delivery.sms', message: `Send SMS` }),
        selected: true,
        type: 'phone',
      },
      {
        enabled: true,
        id: 'email',
        name: t({ id: 'bank.link.delivery.email', message: `Send Email` }),
        selected: false,
        type: 'email',
      },
    ],
    mobilePhone: '',
    phone: '',
    sequence: '',
    applyForFinance: true,
    orderProductsFromTD: true,
    isNewCustomer: true,
    company: {},
  },
  dashboard: {
    activeUsers: [],
    apiCalls: [],
    apiErrors: [],
    counters: [],
    dailySales: [],
    date: undefined,
    monthlySales: [],
    sales: [],
    socketStatus: 'Disconnected',
    storeLogins: [],
    today: undefined,
    unfinishedCarts: [],
    userErrors: [],
    uid: '',
    wls: [],
  },
  reports: {
    list: [],
    settlements: {
      payouts: [],
    },
  },
  sales: {
    list: [],
    sale: {},
    filters: {},
    byId: {},
    lastAction: 'filter',
  },
  ui: {
    button: {
      loading: false,
    },
    loading: false,
    loaderType: LOADER_TYPE_DEFAULT,
    notifications: [],
    openModal: null,
    pageName: 'Home',
    prevLocation: '',
  },
  user: {
    selectedStore: '',
    stores: [],
  },
  whitelabel: {
    configs: {
      availableLocales: ['en', 'nb'],
      canAddExtras: 'CATALOG',
      canChangeLanguage: false,
      canCustomize: 'CATALOG',
      canFinance: 'ACTIVE',
      canTradeIn: 'ACTIVE',
      canUpgrade: 'ACTIVE',
      canUploadAccessoriesByCSV: true,
      canUploadDevicesByCSV: true,
      giftCardImgUrl: '',
      hasSingleSignOn: false,
      returnPaymentTypes: [],
      showAccessoriesAdminPage: true,
      standardName: 'Financing',
      swapName: 'Swap',
      swapPaymentTypes: [],
      tradeinName: 'Trade In',
      upgradeName: 'Easy Upgrade',
    },
    language: 'en',
    plans: [],
    subscriptions: [],
    translations: [],
  },
  recycle: {
    cart: undefined,
    checkmendOk: undefined,
    deviceReference: undefined,
    devices: [],
    fmi: undefined,
    orderUUID: undefined,
    selectedDevice: {
      evaluation: {},
      deviceID: undefined,
      deviceCode: undefined,
      questionsTree: {},
      diagnostic: {},
    },
    selectedDevices: [],
    selectedOutcome: undefined,
    type: undefined,
    receipt: {},
    tradecartStatus: {
      isCustomerApproved: 'PENDING',
      hasIdPhoto: 'PENDING',
    },
  },
};

export default initalState;
