import { openModalAction } from 'actions/ui';

import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import Button from 'components/Button';
import Modal from 'components/Modal';
import DeviceReferenceForm from 'components/device_reference/DeviceReferenceForm';

const DeviceReferenceModal = ({
  buttonText,
  testSelector,
  dataTest,
  name = 'deviceReferenceModal',
  onSubmit,
  openModal,
  useAppleValidation,
  ...formProps
}) => {
  const openDeviceReferenceForm = () => openModal(name);

  return (
    <Fragment>
      <Button onClick={openDeviceReferenceForm} testSelector={testSelector}>
        {buttonText}
      </Button>

      <Modal name={name}>
        <h2>
          <Trans id='amend.device.reference.form.title'>
            Insert new IMEI or Serial Number
          </Trans>
        </h2>

        <DeviceReferenceForm
          destroyOnUnmount={false}
          onSubmit={onSubmit}
          customIMEILabel={t({
            id: 'amend.device.reference.form.imei.placeholder',
            message: `Insert new IMEI`,
          })}
          customSerialLabel={t({
            id: 'amend.device.reference.form.serial.placeholder',
            message: `Insert new Serial Number`,
          })}
          useAppleValidation={useAppleValidation}
          {...formProps}
        />
      </Modal>
    </Fragment>
  );
};

export default connect(null, {
  openModal: openModalAction,
})(DeviceReferenceModal);
