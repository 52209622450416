import { openModalAction } from 'actions/ui';

import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';

import { t } from '@lingui/macro';

import EmailPreview from 'components/EmailPreview';
import Table from 'components/table/Table';

export const Messages = ({ messages = [], openModal }) => {
  const [emailBody, setEmailBody] = useState(null);

  const columns = [
    {
      label: t({ id: 'table.column.campaign.name', message: `Campaign Name` }),
      accessor: 'campaignName',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.campaign.stage',
        message: `Campaign Stage`,
      }),
      accessor: 'stage',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.message.type', message: `Type` }),
      accessor: 'type',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.send.status', message: `Send Status` }),
      accessor: 'sendStatus',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.delivery.status',
        message: `Delivery Status`,
      }),
      accessor: 'deliveryStatus',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.sent.at', message: `Sent At` }),
      accessor: 'sentAt',
      type: 'date',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.actions', message: `Actions` }),
      accessor: 'actions',
      type: 'actions',
      filterable: false,
      sortable: false,
      align: 'right',
      wrap: false,
    },
  ];

  const actions = [
    {
      label: t({ id: 'table.column.actions.view', message: `View` }),
      handler: (_, { body }) => {
        setEmailBody(body);
        openModal('email-preview');
      },
    },
  ];

  const rows = messages?.map((message) => ({
    ...message,
    actions,
  }));

  return (
    <Fragment>
      <EmailPreview body={emailBody} />

      <Table
        columns={columns}
        data={rows}
        keyColumn='id'
        defaultOrder='sentAt'
        defaultDir='desc'
        filterable={false}
        testSelector='posale-contract'
      />
    </Fragment>
  );
};

export default connect(null, { openModal: openModalAction })(Messages);
