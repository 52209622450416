import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

export const usePolling = ({
  callback,
  interval,
  hasDispatch = true,
  args = {},
}) => {
  let intervalRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    const performCallback = () => {
      if (hasDispatch) {
        dispatch(callback({ ...args }));
      } else {
        callback({ ...args });
      }
    };

    intervalRef.current = setInterval(performCallback, interval);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [interval, callback, hasDispatch, dispatch, args]);

  const stopPolling = () => {
    clearInterval(intervalRef.current);
  };

  return { stopPolling };
};

export default usePolling;
