import {
  deleteCampaignAction,
  getAllCampaignsAction,
} from 'actions/admin/campaign';
import { openModalAction } from 'actions/ui';
import { formatDateForDatepicker } from 'helpers/formatters';
import { getFormValues } from 'redux-form';

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';

import Modal from 'components/Modal';
import CampaignForm from 'components/campaign_form/CampaignForm';
import Table from 'components/table/Table';

import { adminCampaignDetailsUrl } from 'constants/routes';
import { campaignStatus } from 'constants/status';

export const Campaigns = ({
  campaigns = [],
  getAllCampaigns,
  deleteCampaign,
  openModal,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [campaignCode, setCampaignCode] = useState(null);

  useEffect(() => {
    getAllCampaigns();
  }, [getAllCampaigns, setSearchParams]);

  const performDeleteCampaign = async () => {
    const response = await deleteCampaign(campaignCode);
    response && getAllCampaigns();
  };

  const columns = [
    {
      label: t({ id: 'table.column.campaign.id', message: `Campaign ID` }),
      accessor: 'campaignCode',
      type: 'text',
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.campaign.description',
        message: `Description`,
      }),
      accessor: 'description',
      type: 'text',
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.campaign.status', message: `Status` }),
      accessor: 'status',
      type: 'status',
      filterable: true,
      list: campaignStatus(),
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.campaign.start.date',
        message: `Start Date`,
      }),
      accessor: 'startDate',
      type: 'date',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.campaign.end.date', message: `End Date` }),
      accessor: 'endDate',
      type: 'date',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.campaign.actions', message: `Actions` }),
      accessor: 'actions',
      type: 'actions',
      filterable: false,
      sortable: false,
      align: 'right',
      wrap: false,
    },
  ];

  const actions = [
    {
      label: t({ id: 'table.column.actions.details', message: `Details` }),
      handler: (_, { campaignCode }) => {
        navigate(adminCampaignDetailsUrl(campaignCode));
      },
    },
    {
      label: t({ id: 'table.column.actions.delete', message: `Delete` }),
      handler: (_, { campaignCode }) => {
        setCampaignCode(campaignCode);
        openModal('admin-campaign-delete');
      },
    },
  ];

  const rows = campaigns?.map((campaign) => ({ ...campaign, actions }));

  return (
    <Fragment>
      <h1 className='admin-page-title'>
        <Trans id='campaigns.title'>Campaigns</Trans>
      </h1>

      <CampaignForm
        onOpen={() => setSearchParams({ form: 'open' })}
        onClose={() =>
          searchParams.get('form') === 'open' &&
          setSearchParams({ form: 'close' })
        }
        toggleShowForm={searchParams.get('form') === 'open'}
        initialValues={{
          status: 'DISABLED',
          endStageSuccess: 'SUCCESS',
          endStageFail: 'FAIL',
          startDate: formatDateForDatepicker(new Date().toString()),
          channel: 'BOTH',
          marketing: 'ALL',
          emailFrom: 'noreply@financetech365.com',
          smsSubject: 'FinanceTechnology',
        }}
      />

      <Table
        columns={columns}
        data={rows}
        keyColumn='id'
        defaultOrder='startDate'
        defaultDir='desc'
      />

      <Modal name='admin-campaign-delete' onConfirm={performDeleteCampaign}>
        <Trans id='blocks.delete.campaign.confirmation'>
          Are you sure you want to delete this campaign ?
        </Trans>
      </Modal>
    </Fragment>
  );
};

export default connect(
  (state) => ({
    currency: state.whitelabel.currency,
    campaigns: state.adminCampaign.campaigns,
    language: state.whitelabel.language,
    formValues: getFormValues('campaignForm')(state),
  }),
  {
    getAllCampaigns: getAllCampaignsAction,
    deleteCampaign: deleteCampaignAction,
    openModal: openModalAction,
  },
)(Campaigns);
