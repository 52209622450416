import React from 'react';

import WLIcon from './WLIcon';
import WLList from './WLList';

const SalesTable = ({ title, wls, items, emptyText, className }) => {
  const renderExtras = (item, index, stripe) => {
    return (
      <tr key={index + 'x'} className={stripe}>
        <td>
          <span className='StatsTable_smallText'>{item.extras}</span>
        </td>
        <td>
          {item.extrasCashPrice}
          <br />
          <span className='StatsTable_smallTextI'>{item.currencyCode}</span>
        </td>
        <td className='StatsTable_smallText StatsTable_wrap'>
          <span className='StatsTable_smallText'>
            {item.accCreditApproved === 'false'
              ? '\nCredit Refused'
              : item.extrasStatus}
          </span>
        </td>
      </tr>
    );
  };
  const renderDiscounts = (item, index, stripe) => {
    return (
      <tr key={index + 'd'} className={stripe}>
        <td>
          <span className='StatsTable_smallTextI'>Discounts</span>
          <br />
          <span className='StatsTable_smallText'>{item.discounts}</span>
        </td>
        <td>
          {item.discountsAmount}
          <br />
          <span className='StatsTable_smallTextI'>{item.currencyCode}</span>
        </td>
        <td className='StatsTable_smallText StatsTable_wrap' />
      </tr>
    );
  };
  const renderSalesRow = (item, index, stripe, wl) => {
    return (
      <tr key={index} className={stripe}>
        <td rowSpan={item.rows}>{item.time}</td>
        <td colSpan='3' rowSpan={item.rows}>
          {item.cartID}
          <br />
          <WLIcon key={'WLI' + index} item={wl} index={index} />{' '}
          {item.storeName}
          <br />
          <span className='StatsTable_smallTextI'>{item.salesPerson}</span>
          <br />
          <span className='StatsTable_smallTextI'>
            Created: {item.createdStr} ({item.phoneNumber})
          </span>
        </td>
        <td>
          <span className='StatsTable_smallText'>{item.product}</span>
        </td>
        <td>
          {item.productCashPrice}
          <br />
          <span className='StatsTable_smallTextI'>{item.currencyCode}</span>
        </td>
        <td className='StatsTable_smallText StatsTable_wrap'>
          <span className='StatsTable_smallTextI'>{item.paymentPlan}</span>
          <br />
          <span className='StatsTable_smallText'>
            {item.deviceCreditApproved === 'false'
              ? '\nCredit Refused'
              : item.status}
          </span>
        </td>
      </tr>
    );
  };

  return (
    <table className={`StatsTable ${className}`}>
      <colgroup>
        <col width='50px;' />
        <col width='30px;' />
        <col width='140px;' />
        <col width='50px;' />
        <col width='200px;' />
        <col width='70px;' />
        <col width='80px;' />
      </colgroup>
      <tbody>
        <tr>
          <th colSpan='7'>
            <span className='StatsTable_caption'>{title}</span>{' '}
            <WLList items={wls} />
          </th>
        </tr>
        <tr className='StatsTable_innerHeader'>
          <td>Time</td>
          <td colSpan='3'>Store / Cart</td>
          <td>Product</td>
          <td>Amount</td>
          <td>Finance</td>
        </tr>
        {items.map((item, index) => {
          var wl = wls.find(function (element) {
            return element.wlID === item.wlID;
          }, item.wlID);

          var stripe = index % 2 === 1 ? 'stripe_even' : 'stripe_odd';
          var rows = [];
          rows.push(renderSalesRow(item, index, stripe, wl));
          if (item.extras.length > 0)
            rows.push(renderExtras(item, index, stripe));
          if (item.discounts.length > 0)
            rows.push(renderDiscounts(item, index, stripe));
          return rows;
        })}
        {items.length === 0 && (
          <tr>
            <td colSpan='7' className='StatsTable_none'>
              {emptyText}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
export default SalesTable;
