import compareAsc from 'date-fns/compareAsc';
import { formatDateForDatepicker } from 'helpers/formatters';
import IBAN from 'iban';
import { parsePhoneNumber } from 'libphonenumber-js';
import postalCodes from 'postal-codes-js';
import {
  addValidator,
  acceptance as reduxAcceptanceValidator,
  date as reduxDateValidator,
  email as reduxEmailValidator,
  file as reduxFileValidator,
  format as reduxFormatValidator,
  length as reduxLengthValidator,
  numericality as reduxNumericalityValidator,
  required as reduxRequiredValidator,
} from 'redux-form-validators';

import { t } from '@lingui/macro';

import { currencyRegex } from 'constants/forms';

const IMEI_LENGTH = 15;

// Really good performance luhn algorithm implementation
// taken from https://gist.github.com/ShirtlessKirk/2134376
export const validIMEICheckDigit = ((arr) => {
  return (value) => {
    if (!isDigitOnlyString(value)) return false;

    let len = value.length;
    let bit = 1;
    let sum = 0;
    let val;

    while (len) {
      val = parseInt(value.charAt(--len), 10);
      // eslint-disable-next-line no-cond-assign
      sum += (bit ^= 1) ? arr[val] : val;
    }

    return sum && sum % 10 === 0;
  };
})([0, 2, 4, 6, 8, 1, 3, 5, 7, 9]);

export const isDigitOnlyString = (value = '') => String(value).match(/^\d+$/);

export const isValidIMEI = (value) =>
  value?.length === IMEI_LENGTH && validIMEICheckDigit(value);

export const isValidDate = (date) => date instanceof Date && !isNaN(date);

export const hasDiscounts = (orders) => {
  return orders.some(
    (order) =>
      order.discounts &&
      order.discounts.items &&
      order.discounts.items.length > 0,
  );
};

export const isCartUUID = (value = '') =>
  value.match(/(\w{2}-){2}\d{4}-\d{7}/g);

export const phoneValidator = addValidator({
  validator: (options, value) => {
    try {
      if (options.allowBlank && !value.trim()) {
        return;
      }

      const phoneNumber = parsePhoneNumber(value, options.country);
      const additionalCountries = options?.countryPhoneCodes || [];

      if (!phoneNumber.isValid()) {
        return {
          defaultMessage:
            options.errorMsg ||
            t({ id: 'validator.invalid.phone', message: `isn't valid` }),
        };
      }

      if (
        !['PT', options.country, ...additionalCountries].includes(
          phoneNumber.country,
        ) &&
        options.validateWLCountry
      ) {
        return {
          defaultMessage:
            options.errorMsg ||
            t({
              id: 'validator.missing.phone.country',
              message: `is from a different country`,
            }),
        };
      }
    } catch (error) {
      return {
        defaultMessage: t({
          id: 'validator.invalid.phone',
          message: `isn't valid`,
        }),
      };
    }
  },
});

export const postcodeValidator = addValidator({
  validator: (options, value) => {
    return postalCodes.validate(options.country, value) === true
      ? true
      : {
          defaultMessage: t({
            id: 'validator.invalid.postcode',
            message: `is not valid`,
          }),
        };
  },
});

export const idNumberValidator = addValidator({
  validator: (options, value) => {
    let isValid = true;
    if (options?.country === 'ES') {
      isValid = validateSpanishID(value);
    }
    return (
      isValid || {
        defaultMessage: t({
          id: 'validator.invalid.idnumber',
          message: `is not valid`,
        }),
      }
    );
  },
});

const validateSpanishID = (str) => {
  const DNI_REGEX = /^(([KLM]\d{7})|(\d{8}))([A-Z])$/;
  const CIF_REGEX = /^([ABCDEFGHJNPQRSUVW])(\d{7})([0-9A-J])$/;
  const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;

  const spainIdType = (str) => {
    if (str.match(DNI_REGEX)) {
      return 'dni';
    }
    if (str.match(CIF_REGEX)) {
      return 'cif';
    }
    if (str.match(NIE_REGEX)) {
      return 'nie';
    }
  };

  const validDNI = (dni) => {
    const dni_letters = 'TRWAGMYFPDXBNJZSQVHLCKE';
    if (dni[0].match(/[KLM]/)) {
      dni = dni.substr(1);
    }
    const letter = dni_letters.charAt(parseInt(dni, 10) % 23);

    return letter === dni.charAt(dni.length - 1);
  };

  const validNIE = (nie) => {
    let nie_prefix = nie.charAt(0);

    switch (nie_prefix) {
      case 'X':
        nie_prefix = 0;
        break;
      case 'Y':
        nie_prefix = 1;
        break;
      case 'Z':
        nie_prefix = 2;
        break;
      default:
        break;
    }

    return validDNI(nie_prefix + nie.substr(1));
  };

  const validCIF = (cif) => {
    const match = cif.match(CIF_REGEX);
    const letter = match[1];
    const number = match[2];
    const control = match[3];

    let even_sum = 0;
    let odd_sum = 0;
    let last_digit = 0;
    let n;

    for (let i = 0; i < number.length; i++) {
      n = parseInt(number.charAt(i), 10);

      if (i % 2 === 0) {
        n *= 2;
        odd_sum += n < 10 ? n : n - 9;
      } else {
        even_sum += n;
      }
    }

    last_digit = parseInt((even_sum + odd_sum).toString().slice(-1), 10);
    const control_digit = last_digit !== 0 ? 10 - last_digit : last_digit;
    const control_letter = 'JABCDEFGHI'.charAt(control_digit);

    if (letter.match(/[ABEH]/)) {
      return control === control_digit.toString();
    } else if (letter.match(/[PQSW]/)) {
      return control === control_letter;
    } else {
      return control === control_digit.toString() || control === control_letter;
    }
  };

  // Ensure upcase and remove whitespace
  str = str.toUpperCase().replace(/\s/g, '');

  const type = spainIdType(str);
  let valid = false;

  switch (type) {
    case 'dni':
      valid = validDNI(str);
      break;
    case 'nie':
      valid = validNIE(str);
      break;
    case 'cif':
      valid = validCIF(str);
      break;
    default:
      break;
  }

  return valid;
};

export const ibanValidator = addValidator({
  validator: (options, value) => {
    return (
      IBAN.isValid(value) || {
        defaultMessage: t({
          id: 'validator.invalid.iban',
          message: `is not valid`,
        }),
      }
    );
  },
});

export const isValidAppleSerial = (value) =>
  value && value.length === (value.toLowerCase().charAt(0) === 's' ? 13 : 12);

export const validateDeviceReference = (
  deviceReference,
  isIMEI,
  useAppleDeviceReferenceValidation,
) => {
  if (isIMEI && deviceReference && !isValidIMEI(deviceReference))
    return {
      deviceReference: t({
        id: 'device.reference.form.invalid.imei',
        message: `This is not a valid IMEI!`,
      }),
    };

  if (
    !isIMEI &&
    deviceReference &&
    useAppleDeviceReferenceValidation &&
    !isValidAppleSerial(deviceReference)
  )
    return {
      deviceReference: t({
        id: 'device.reference.form.invalid.serial',
        message: `This is not a valid Apple Serial Number!`,
      }),
    };
};

export const acceptance = (options) =>
  reduxAcceptanceValidator({
    ...options,
    msg:
      options?.msg ||
      t({ id: 'validator.invalid.acceptance', message: `must be accepted` }),
  });

export const date = (options) =>
  reduxDateValidator({
    ...options,
    msg: options['<']
      ? t({
          id: 'validator.lower.date',
          message: `should be lower than ${formatDateForDatepicker(
            options['<'],
          )}`,
        })
      : t({
          id: 'validator.higher.date',
          message: `should be higher than ${formatDateForDatepicker(
            options['>'],
          )}`,
        }),
  });

export const required = (options) =>
  reduxRequiredValidator({
    ...options,
    msg:
      options?.msg ||
      t({ id: 'validator.invalid.required', message: `is required` }),
  });

export const email = (options) =>
  reduxEmailValidator({
    ...options,
    msg:
      options?.msg ||
      t({ id: 'validator.invalid.email', message: `is not a valid email` }),
  });

export const file = (options) =>
  reduxFileValidator({
    ...options,
    msg: options?.msg || {
      defaultMessage: t({
        id: 'validator.invalid.file',
        message: 'is not a file',
      }),
      fileAccept: t({
        id: 'validator.invalid.file.accept',
        message: 'invalid file type',
      }),
      fileTooBig: t({
        id: 'validator.invalid.file.tooBig',
        message: 'is too big',
      }),
      fileTooMany: t({
        id: 'validator.invalid.file.tooMany',
        message: 'invalid number of files (maximum is 1)',
      }),
    },
  });

export const format = (options) =>
  reduxFormatValidator({
    ...options,
    msg:
      options?.msg ||
      t({
        id: 'validator.invalid.format',
        message: `is not in a valid format`,
      }),
  });

export const numericality = (options = {}) =>
  reduxNumericalityValidator({
    ...options,
    msg:
      options?.msg ||
      (options.lessThan &&
        t({
          id: 'validator.invalid.numericality.below',
          message: `should be below ${options.lessThan}`,
        })) ||
      t({
        id: 'validator.invalid.numericality',
        message: `is not a valid number`,
      }),
  });

export const length = (options) =>
  reduxLengthValidator({
    ...options,
    msg:
      options?.msg ||
      // TODO change this message to be generic (to make sense for min and max values)
      t({
        id: 'validator.max.string.size',
        message: `exceeds ${options.max} characters`,
      }),
  });

export const isDate = (field, date) => {
  const errors = {};
  if (!isValidDate(date)) {
    errors[field] = t({
      id: 'validator.invalid.date',
      message: `is not a valid date`,
    });
  }
  return errors;
};

export const validStartToEndDate = (startDate, endDate) => {
  const errors = {};
  if (compareAsc(startDate, endDate) === 1) {
    errors.endDate = t({
      id: 'validator.bigger.start.date',
      message: `should be after the starting date`,
    });
  }
  return errors;
};

export const validCurrencyValue = (value) => {
  const regex = new RegExp(currencyRegex);
  let error = null;
  if (!regex.test(value)) {
    error = t({
      id: 'validator.currency.invalid.value',
      message: `value not valid`,
    });
  }
  return error;
};

const BNPValidator = (regexString, errorMsg) =>
  addValidator({
    validator: (_options, value) => {
      const regex = new RegExp(regexString);

      return (
        regex.test(value) || {
          defaultMessage:
            errorMsg ||
            t({
              id: 'validator.bnp.invalid.value',
              message: `value not valid`,
            }),
        }
      );
    },
  });

export const BNPstring = BNPValidator(
  /^(?=.*)[a-zA-Z0-9\./,\s-]*(?:"[a-zA-Z0-9\./,\s-]*)?$/,
);
export const BNPnumber = BNPValidator(
  /^(?=.*)[a-zA-Z0-9/,\s-]*(?:"[a-zA-Z0-9/,\s-]*)?$/,
);
export const BNPpostalCode = BNPValidator(
  /^(?=.*)[a-zA-Z0-9/,\s-]*(?:"[a-zA-Z0-9/,\s-]*)?$/,
);
export const BNPphoneNumber = BNPValidator(
  /(\+351|07)\d{9}/ /*/^(07\d{9})$/*/,
  t({
    id: 'customer.info.phone.error',
    message: `is not a valid phone number`,
  }),
);
