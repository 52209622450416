import { currencyFormatter, formatDateForDatepicker } from 'helpers/formatters';
import isEmpty from 'lodash/isEmpty';

import { t } from '@lingui/macro';

export const ACTIVE = 'ACTIVE';
export const ACTIVE_WITHOUT_DEVICE_REFERENCE =
  'ACTIVE_WITHOUT_DEVICE_REFERENCE';
export const CANCELLED = 'CANCELLED';
export const CLOSED = 'CLOSED';
export const NEW = 'NEW';
export const PENDING = 'PENDING';
export const PENDING_ACTIVATION = 'PENDING_ACTIVATION';
export const REFUSED = 'REFUSED';
export const WITHDRAWN = 'WITHDRAWN';
export const CREDIT_APPROVED = 'CREDIT_APPROVED';
export const PENDING_CREDIT_CHECK = 'PENDING_CREDIT_CHECK';

export const ACTIVE_STATUS = 1;
export const FINISHED_STATUS = 2;
export const WITHDRAW_STATUS = 3;
export const REFUSED_STATUS = 4;
export const PENDING_STATUS = 5;
export const SWAP_STATUS = 6;
export const HISTORIC_STATUS = 7;
export const CANCELLED_STATUS = 8;
export const RETURNED_STATUS = 9;

export const APPROVED = 'APPROVED';
export const REFERRED = 'REFERRED';
export const PRE_APPROVED = 'PRE_APPROVED';
export const PARTIALLY_APPROVED = 'PARTIAL_APPROVED';
export const UPGRADE_PENDING = 'UPGRADE_PENDING';

export const RETURN_REPLACE = 'RETURN';
export const RETURN_CANCEL = 'CANCEL';
export const FULFILLMENT_REPLACE = 'REPLACE';
export const FULFILLMENT_CANCEL = 'CANCEL';

export const returnTypeItems = [
  {
    value: FULFILLMENT_REPLACE,
    key: FULFILLMENT_REPLACE,
    label: t({
      id: 'return.type.replace.label',
      message: `Return and ship replacement device`,
    }),
    helper: t({
      id: 'return.type.replace.helper',
      message: `Select this option if device need to be returned and contract remains active.`,
    }),
  },
  {
    value: FULFILLMENT_CANCEL,
    key: FULFILLMENT_CANCEL,
    label: t({
      id: 'return.type.cancel.label',
      message: `Return device and cancel contract`,
    }),
    helper: t({
      id: 'return.type.cancel.helper',
      message: `Select this option if device need to be returned and contract canceled.`,
    }),
  },
];

export const RETURN_APPROVED = 'RETURN_APPROVED';
export const RETURN_REQUESTED = 'RETURN_REQUESTED';
export const RETURNED = 'RETURNED';

export const returnProductStatus = [
  RETURN_APPROVED,
  RETURN_REQUESTED,
  RETURNED,
];

export const DAMAGED_IN_TRANSIT = 'DAMAGED_IN_TRANSIT';
export const DEFECTIVE_PRODUCT = 'DEFECTIVE_PRODUCT';
export const DEAD_ON_ARRIVAL = 'DEAD_ON_ARRIVAL';
export const MISSING_ACCESSORIES = 'MISSING_ACCESSORIES';
export const WRONG_PRODUCT = 'WRONG_PRODUCT';
export const CUSTOMER_DECISION = 'CUSTOMER_DECISION';
export const OTHER = 'OTHER';

export const returnOptions = [
  {
    value: DAMAGED_IN_TRANSIT,
    label: t({
      id: 'return.option.damaged.transit',
      message: `Damaged in transit`,
    }),
  },
  {
    value: DEFECTIVE_PRODUCT,
    label: t({ id: 'return.option.defective', message: `Defective  product` }),
  },
  {
    value: DEAD_ON_ARRIVAL,
    label: t({
      id: 'return.option.dead.on.arrival',
      message: `Dead on arrival`,
    }),
  },
  {
    value: MISSING_ACCESSORIES,
    label: t({
      id: 'return.option.missing.accessories',
      message: `Accessories missing`,
    }),
  },
  {
    value: WRONG_PRODUCT,
    label: t({
      id: 'return.option.wrong.product',
      message: `Wrong product delivered`,
    }),
  },
  {
    value: CUSTOMER_DECISION,
    label: t({
      id: 'return.option.customer.decision',
      message: `Customer changed mind`,
    }),
  },
  {
    value: OTHER,
    label: t({ id: 'return.option.other', message: `Other` }),
  },
];

export const labels = ({ firstName, lastName }) => ({
  get termsAndConditions() {
    return {
      titles: {
        APPROVED: t({
          id: 'contract.swap.terms',
          message: `Upgrade program terms & conditions`,
        }),
        PENDING: t({
          id: 'contract.swap.terms',
          message: `Upgrade program terms & conditions`,
        }),
        REFUSED: t({
          id: 'contract.swap.terms',
          message: `Upgrade program terms & conditions`,
        }),
      },
      subtitles: {
        APPROVED: t({
          id: 'contract.terms.ok',
          message: `approved by customer`,
        }),
        PENDING: t({
          id: 'contract.terms.loading',
          message: `awaiting approval (on customer's phone)`,
        }),
        REFUSED: t({
          id: 'contract.terms.declined',
          message: `not approved by customer`,
        }),
      },
    };
  },
  get creditSign() {
    return {
      titles: {
        APPROVED: t({
          id: 'contract.credit.signup',
          message: `Credit sign up process`,
        }),
        PRE_APPROVED: t({
          id: 'contract.credit.signup',
          message: `Credit sign up process`,
        }),
        PARTIAL_APPROVED: t({
          id: 'contract.credit.signup',
          message: `Credit sign up process`,
        }),
        PENDING: t({
          id: 'contract.credit.signup',
          message: `Credit sign up process`,
        }),
        REFERRED: t({
          id: 'contract.credit.signup',
          message: `Credit sign up process`,
        }),
        REFUSED: t({
          id: 'contract.credit.signup',
          message: `Credit sign up process`,
        }),
        WITHDRAWN: t({
          id: 'contract.credit.signup',
          message: `Credit sign up process`,
        }),
      },
      subtitles: {
        APPROVED: t({ id: 'contract.signup.approved', message: `Approved` }),
        PARTIAL_APPROVED: t({
          id: 'contract.signup.partial',
          message: `Partially approved`,
        }),
        PENDING: t({
          id: 'contract.signup.loading',
          message: `In progress (on customer's phone)`,
        }),
        REFERRED: t({
          id: 'contract.signup.referred',
          message: `pending approval`,
        }),
        REFUSED: t({ id: 'contract.signup.declined', message: `Declined` }),
        WITHDRAWN: t({ id: 'contract.signup.withdrawn', message: `Withdrawn` }),
      },
    };
  },
  get verifyID() {
    return {
      titles: {
        APPROVED: t({
          id: 'contract.verify.id.title',
          message: `I confirm I've checked the customer ID and their`,
        }),
        PENDING: t({
          id: 'contract.verify.id.title',
          message: `I confirm I've checked the customer ID and their`,
        }),
      },
      subtitles: {
        APPROVED: t({
          id: 'contract.verify.id.subtitle',
          message: `name matches ${firstName} ${lastName}`,
        }),
        PENDING: t({
          id: 'contract.verify.id.subtitle',
          message: `name matches ${firstName} ${lastName}`,
        }),
      },
    };
  },
  get deviceReference() {
    return {
      titles: {
        APPROVED: t({
          id: 'contract.deviceReference.title',
          message: `IMEI or Serial Number`,
        }),
        PENDING: t({
          id: 'contract.deviceReference.title',
          message: `IMEI or Serial Number`,
        }),
        REFUSED: t({
          id: 'contract.deviceReference.title',
          message: `IMEI or Serial Number`,
        }),
      },
      subtitles: {
        APPROVED: t({ id: 'contract.deviceReference.ok', message: `Entered` }),
        PENDING: t({
          id: 'contract.deviceReference.loading',
          message: `must be entered (after credit approval)`,
        }),
        REFUSED: t({
          id: 'contract.deviceReference.alert',
          message: `Credit not approved for accessories`,
        }),
      },
    };
  },
  get activateContract() {
    return {
      titles: {
        APPROVED: t({
          id: 'contract.activation.title',
          message: `Contract Activation`,
        }),
        PENDING: t({
          id: 'contract.activation.title',
          message: `Contract Activation`,
        }),
      },
      subtitles: {
        APPROVED: t({
          id: 'contract.activation.information',
          message: `Contract must be signed in partner's application before activation`,
        }),
        PENDING: t({
          id: 'contract.activation.information',
          message: `Contract must be signed in partner's application before activation`,
        }),
      },
    };
  },
  get handOver() {
    return {
      titles: {
        APPROVED: t({
          id: 'contract.handover.ok.title',
          message: `Contract Activation Complete. You can now hand over`,
        }),
        PENDING: t({
          id: 'contract.handover.loading.title',
          message: `Do not hand over the device to the customer`,
        }),
        REFUSED: t({
          id: 'contract.handover.declined.title',
          message: `Sale process not complete.`,
        }),
      },
      subtitles: {
        APPROVED: t({
          id: 'contract.handover.ok.subtitle',
          message: `the device to the customer`,
        }),
        PENDING: t({
          id: 'contract.handover.loading.subtitle',
          message: `until all steps are complete`,
        }),
        REFUSED: t({
          id: 'contract.handover.declined.subtitle',
          message: `Do not hand over the device to the customer`,
        }),
      },
    };
  },
  get swap() {
    return {
      titles: {
        APPROVED: t({
          id: 'contract.cancelSwap.ok',
          message: `The bank has canceled your old contract.`,
        }),
        PENDING: t({
          id: 'contrac.cancelSwap.pending',
          message: `The contract cancellation request has been sent. The customer should confirm this with their bank.`,
        }),
      },
    };
  },
  get tradeReview() {
    return {
      titles: {
        APPROVED: t({
          id: 'tradein.review.accepted',
          message: `Customer accepted Trade In offer`,
        }),
        REFUSED: t({
          id: 'tradein.review.rejected',
          message: `Customer rejected Trade In offer`,
        }),
        PENDING: t({
          id: 'tradein.review.pending',
          message: `Customer reviewing Trade In offer`,
        }),
      },
    };
  },
  get tradeIDUpload() {
    return {
      titles: {
        APPROVED: t({
          id: 'tradein.id.upload.accepted',
          message: `Customer uploaded ID photo`,
        }),
        PENDING: t({
          id: 'tradein.id.upload.pending',
          message: `Waiting for customer to upload ID photo`,
        }),
      },
    };
  },
  get tradeRefused() {
    return {
      titles: {
        REFUSED: t({
          id: 'tradein.review.rejected',
          message: `Customer rejected Trade In offer`,
        }),
      },
    };
  },
  get recycle() {
    return {
      titles: {
        APPROVED: t({
          id: 'recycle.status.accepted',
          message: `Trade in complete`,
        }),
        PENDING: t({
          id: 'recycle.status.pending',
          message: `Waiting to complete the trade in`,
        }),
      },
    };
  },
});

export const contractInfo = (
  funder,
  discounts,
  financing,
  products,
  extras,
  currency,
  language,
) => {
  const contractsInfo = {
    bnp_uk: BNPUK(discounts, financing, products, extras, currency, language),
    klarna: Klarna(discounts, financing, products, extras, currency, language),
    oneyfr: OneyFR(discounts, financing, products, extras, currency, language),
    instabank: Instabank(
      discounts,
      financing,
      products,
      extras,
      currency,
      language,
    ),
    expressbank: ExpressBank(
      discounts,
      financing,
      products,
      extras,
      currency,
      language,
    ),
  };
  return contractsInfo[funder.toLowerCase()] || [];
};

export const defaultContractStatus = [
  ACTIVE,
  CANCELLED,
  CLOSED,
  NEW,
  PENDING,
  PENDING_ACTIVATION,
  REFUSED,
  WITHDRAWN,
];

export const defaultCartStatus = [
  ACTIVE,
  CANCELLED,
  CLOSED,
  REFUSED,
  WITHDRAWN,
];

export const pendingCartStatus = [ACTIVE, PENDING_ACTIVATION, PENDING];

export const defaultSalesFilters = {
  contractPeriod: 'UPDATED',
  contractStatus: [ACTIVE],
  dateRange: `${formatDateForDatepicker(
    new Date(new Date().setDate(new Date().getDate() - 15)),
  )} - ${formatDateForDatepicker(new Date())}`,
};

export const defaultPendingsFilters = {
  contractPeriod: 'UPDATED',
  contractStatus: [PENDING, PENDING_ACTIVATION],
  dateRange: `${formatDateForDatepicker(
    new Date(new Date().setDate(new Date().getDate() - 15)),
  )} - ${formatDateForDatepicker(new Date())}`,
};

export const upfrontInfoText = (value) =>
  t({
    id: 'loan.information.upfront',
    message: `You will pay ${value} initially as an upfront payment.`,
  });

const BNPUK = (discounts, financing, products, extras, currency, language) => {
  const totalExtras = Math.abs(extras.total_extras);
  const totalDiscounts = Math.abs(discounts.total_discounts);
  const isUpgradeFinancing =
    financing?.deviceContractFinance?.financeType === 'UPGRADE';
  const isSingleLoan = isEmpty(financing?.extrasContractFinance);
  let textOption = 'product';
  let bankName = 'BNP';
  let bankNameLong = 'BNP';

  if (products) {
    if (isEmpty(extras.items) && !isEmpty(discounts.items)) {
      textOption = 'productWithDiscountsNoExtras';
    } else if (isEmpty(discounts.items) && totalExtras > 0) {
      textOption = 'productWithExtras';
    } else if (!isEmpty(extras.items) && !isEmpty(discounts.items)) {
      if (totalDiscounts >= totalExtras) {
        textOption = 'productWithDiscountsHigherEqualExtras';
      } else {
        textOption = 'productWithDiscountsLowerExtras';
      }
    } else if (totalExtras === 0) {
      textOption = 'product';
    }
  } else {
    if (isEmpty(discounts.items)) {
      textOption = 'extrasNoDiscounts';
    } else {
      textOption = 'extrasHigherThanDiscounts';
    }
  }

  // the contractInfoText is created for all calls and product.name is required and not always present
  const productName = products?.map((p) => p.name).join(', ') || 'Device';

  const paymentExtrasInfo = (monthlyPrice) => {
    return !isSingleLoan
      ? t({
          id: 'loan.information.payment.list.item',
          message: `You will pay ${currencyFormatter(
            monthlyPrice,
            currency,
            language,
          )} a month for the ${productName} over ${
            financing.flex_period_end_month
          } months.`,
        })
      : t({
          id: 'loan.information.payment.extras.list.item',
          message: `You will pay ${currencyFormatter(
            monthlyPrice,
            currency,
            language,
          )} a month for the ${productName} and extras over ${
            financing.flex_period_end_month
          } months.`,
        });
  };

  const contractInfoText = {
    get product() {
      return [
        t({
          id: 'loan.information.payment.list.item',
          message: `You will pay ${currencyFormatter(
            financing.flex_period_monthly_price,
            currency,
            language,
          )} a month for the ${productName} over ${
            financing.flex_period_end_month
          } months.`,
        }),
        ...(isUpgradeFinancing
          ? [
              t({
                id: 'loan.information.after.payment.list.item',
                message: `After ${
                  financing.fixed_period_end_month
                } payments, the amount paid will be ${currencyFormatter(
                  financing.total_amount_fixed_period,
                  currency,
                  language,
                )}, at which point you will be eligible for upgrade the ${productName}.`,
              }),
              t({
                id: 'loan.information.not.upgrade.list.item',
                message: `If you choose not to upgrade, the estimated total over ${
                  financing.flex_period_end_month
                } months, is ${currencyFormatter(
                  financing.total_amount,
                  currency,
                  language,
                )}, after which the device is yours to keep.`,
              }),
              t({
                id: 'loan.information.upgrade.list.item',
                message: `To upgrade, return the device in a pre-agreed condition and the balance of the agreement will be settled.`,
              }),
              t({
                id: 'loan.information.device.conditions.list.item.generic',
                message: `If the device is not as per the pre-agreed condition, then we will advise of repair cost or a reduced trade in value. We will credit to your ${bankName} account accordingly. You will need to make additional payment/s to ${bankName} to settle the remaining balance.`,
              }),
            ]
          : []),
        t({
          id: 'loan.information.agreement.list.item.generic',
          message: `This finance agreement is subject to status and provided by ${bankNameLong}. If you already have a ${bankName} Credit account, this purchase will be added to your balance.`,
        }),
      ];
    },

    get productWithDiscountsNoExtras() {
      return [
        t({
          id: 'loan.information.applied.discount.list.item',
          message: `An upfront discount of ${currencyFormatter(
            totalDiscounts,
            currency,
            language,
          )} has been applied.`,
        }),
        t({
          id: 'loan.information.payment.list.item',
          message: `You will pay ${currencyFormatter(
            financing.flex_period_monthly_price,
            currency,
            language,
          )} a month for the ${productName} over ${
            financing.flex_period_end_month
          } months.`,
        }),
        ...(isUpgradeFinancing
          ? [
              t({
                id: 'loan.information.after.payment.list.item',
                message: `After ${
                  financing.fixed_period_end_month
                } payments, the amount paid will be ${currencyFormatter(
                  financing.total_amount_fixed_period,
                  currency,
                  language,
                )}, at which point you will be eligible for upgrade the ${productName}.`,
              }),
              t({
                id: 'loan.information.not.upgrade.list.item',
                message: `If you choose not to upgrade, the estimated total over ${
                  financing.flex_period_end_month
                } months, is ${currencyFormatter(
                  financing.total_amount,
                  currency,
                  language,
                )}, after which the device is yours to keep.`,
              }),
              t({
                id: 'loan.information.upgrade.list.item',
                message: `To upgrade, return the device in a pre-agreed condition and the balance of the agreement will be settled.`,
              }),
              t({
                id: 'loan.information.device.conditions.list.item.generic',
                message: `If the device is not as per the pre-agreed condition, then we will advise of repair cost or a reduced trade in value. We will credit to your ${bankName} account accordingly. You will need to make additional payment/s to ${bankName} to settle the remaining balance.`,
              }),
            ]
          : []),
        t({
          id: 'loan.information.agreement.list.item.generic',
          message: `This finance agreement is subject to status and provided by ${bankNameLong}. If you already have a ${bankName} Credit account, this purchase will be added to your balance.`,
        }),
      ];
    },

    get productWithExtras() {
      return [
        paymentExtrasInfo(financing.flex_period_monthly_price),
        ...(isUpgradeFinancing
          ? [
              ...(!isSingleLoan
                ? [
                    t({
                      id: 'loan.information.extras.contract.list.item.generic',
                      message: `The extras purchased with the device will have a separate ${bankName} agreement for ${currencyFormatter(
                        extras.monthly_total_extras,
                        currency,
                        language,
                      )} over ${financing.fixed_period_end_month} months`,
                    }),
                  ]
                : []),
              t({
                id: 'loan.information.after.payment.list.item',
                message: `After ${
                  financing.fixed_period_end_month
                } payments, the amount paid will be ${currencyFormatter(
                  financing.total_amount_fixed_period,
                  currency,
                  language,
                )}, at which point you will be eligible for upgrade the ${productName}.`,
              }),
              t({
                id: 'loan.information.not.upgrade.list.item',
                message: `If you choose not to upgrade, the estimated total over ${
                  financing.flex_period_end_month
                } months, is ${currencyFormatter(
                  financing.total_amount,
                  currency,
                  language,
                )}, after which the device is yours to keep.`,
              }),
              t({
                id: 'loan.information.upgrade.list.item',
                message: `To upgrade, return the device in a pre-agreed condition and the balance of the agreement will be settled.`,
              }),
              t({
                id: 'loan.information.device.conditions.list.item.generic',
                message: `If the device is not as per the pre-agreed condition, then we will advise of repair cost or a reduced trade in value. We will credit to your ${bankName} account accordingly. You will need to make additional payment/s to ${bankName} to settle the remaining balance.`,
              }),
              t({
                id: 'loan.information.extras.list.item',
                message: `Any extras purchased as part of this finance agreement do not need to be returned as part of the upgrade.`,
              }),
            ]
          : []),
        t({
          id: 'loan.information.agreement.list.item.generic',
          message: `This finance agreement is subject to status and provided by ${bankNameLong}. If you already have a ${bankName} Credit account, this purchase will be added to your balance.`,
        }),
      ];
    },

    get productWithDiscountsLowerExtras() {
      return [
        t({
          id: 'loan.information.applied.discount.list.item',
          message: `An upfront discount of ${currencyFormatter(
            totalDiscounts,
            currency,
            language,
          )} has been applied.`,
        }),
        paymentExtrasInfo(financing.fixed_period_monthly_price),
        ...(isUpgradeFinancing
          ? [
              ...(!isSingleLoan
                ? [
                    t({
                      id: 'loan.information.extras.contract.list.item.generic',
                      message: `The extras purchased with the device will have a separate ${bankName} agreement for ${currencyFormatter(
                        extras.monthly_total_extras,
                        currency,
                        language,
                      )} over ${financing.fixed_period_end_month} months`,
                    }),
                  ]
                : []),
              t({
                id: 'loan.information.after.payment.list.item',
                message: `After ${
                  financing.fixed_period_end_month
                } payments, the amount paid will be ${currencyFormatter(
                  financing.total_amount_fixed_period,
                  currency,
                  language,
                )}, at which point you will be eligible for upgrade the ${productName}.`,
              }),
              t({
                id: 'loan.information.not.upgrade.list.item',
                message: `If you choose not to upgrade, the estimated total over ${
                  financing.flex_period_end_month
                } months, is ${currencyFormatter(
                  financing.total_amount,
                  currency,
                  language,
                )}, after which the device is yours to keep.`,
              }),
              t({
                id: 'loan.information.upgrade.list.item',
                message: `To upgrade, return the device in a pre-agreed condition and the balance of the agreement will be settled.`,
              }),
              t({
                id: 'loan.information.device.conditions.list.item.generic',
                message: `If the device is not as per the pre-agreed condition, then we will advise of repair cost or a reduced trade in value. We will credit to your ${bankName} account accordingly. You will need to make additional payment/s to ${bankName} to settle the remaining balance.`,
              }),
              t({
                id: 'loan.information.extras.list.item',
                message: `Any extras purchased as part of this finance agreement do not need to be returned as part of the upgrade.`,
              }),
            ]
          : []),
        t({
          id: 'loan.information.agreement.list.item.generic',
          message: `This finance agreement is subject to status and provided by ${bankNameLong}. If you already have a ${bankName} Credit account, this purchase will be added to your balance.`,
        }),
      ];
    },

    get productWithDiscountsHigherEqualExtras() {
      return [
        t({
          id: 'loan.information.applied.discount.list.item',
          message: `An upfront discount of ${currencyFormatter(
            totalDiscounts,
            currency,
            language,
          )} has been applied.`,
        }),
        paymentExtrasInfo(financing.fixed_period_monthly_price),
        t({
          id: 'loan.information.extras.payment.list.item',
          message: `Payment for your extras is covered by your upfront discount of ${currencyFormatter(
            totalDiscounts,
            currency,
            language,
          )} (the remainder of which has been applied to your main device).`,
        }),
        ...(isUpgradeFinancing
          ? [
              t({
                id: 'loan.information.after.payment.list.item',
                message: `After ${
                  financing.fixed_period_end_month
                } payments, the amount paid will be ${currencyFormatter(
                  financing.total_amount_fixed_period,
                  currency,
                  language,
                )}, at which point you will be eligible for upgrade the ${productName}.`,
              }),
              t({
                id: 'loan.information.not.upgrade.list.item',
                message: `If you choose not to upgrade, the estimated total over ${
                  financing.flex_period_end_month
                } months, is ${currencyFormatter(
                  financing.total_amount,
                  currency,
                  language,
                )}, after which the device is yours to keep.`,
              }),
              t({
                id: 'loan.information.upgrade.list.item',
                message: `To upgrade, return the device in a pre-agreed condition and the balance of the agreement will be settled.`,
              }),
              t({
                id: 'loan.information.device.conditions.list.item.generic',
                message: `If the device is not as per the pre-agreed condition, then we will advise of repair cost or a reduced trade in value. We will credit to your ${bankName} account accordingly. You will need to make additional payment/s to ${bankName} to settle the remaining balance.`,
              }),
              t({
                id: 'loan.information.extras.list.item',
                message: `Any extras purchased as part of this finance agreement do not need to be returned as part of the upgrade.`,
              }),
            ]
          : []),
        t({
          id: 'loan.information.agreement.list.item.generic',
          message: `This finance agreement is subject to status and provided by ${bankNameLong}. If you already have a ${bankName} Credit account, this purchase will be added to your balance.`,
        }),
      ];
    },

    get extrasNoDiscounts() {
      return [
        ...(!isSingleLoan
          ? [
              t({
                id: 'loan.extras.information.extras.contract.list.item.generic',
                message: `The extras purchased with the device will have a separate ${bankName} agreement for ${currencyFormatter(
                  financing.fixed_period_monthly_price,
                  currency,
                  language,
                )} over ${financing.fixed_period_end_month} months`,
              }),
            ]
          : []),
        t({
          id: 'loan.extras.information.after.payment.list.item',
          message: `After ${
            financing.fixed_period_end_month
          } payments, the amount paid will be ${currencyFormatter(
            financing.total_amount_fixed_period,
            currency,
            language,
          )}.`,
        }),
        t({
          id: 'loan.information.extras.list.item',
          message: `Any extras purchased as part of this finance agreement do not need to be returned as part of the upgrade.`,
        }),
      ];
    },

    get extrasHigherThanDiscounts() {
      return [
        t({
          id: 'loan.information.applied.discount.list.item',
          message: `An upfront discount of ${currencyFormatter(
            totalDiscounts,
            currency,
            language,
          )} has been applied.`,
        }),
        ...(!isSingleLoan
          ? [
              t({
                id: 'loan.extras.information.extras.contract.list.item.generic',
                message: `The extras purchased with the device will have a separate ${bankName} agreement for ${currencyFormatter(
                  financing.fixed_period_monthly_price,
                  currency,
                  language,
                )} over ${financing.fixed_period_end_month} months`,
              }),
            ]
          : []),
        t({
          id: 'loan.extras.information.after.payment.list.item',
          message: `After ${
            financing.fixed_period_end_month
          } payments, the amount paid will be ${currencyFormatter(
            financing.total_amount_fixed_period,
            currency,
            language,
          )}.`,
        }),
        t({
          id: 'loan.information.extras.list.item',
          message: `Any extras purchased as part of this finance agreement do not need to be returned as part of the upgrade.`,
        }),
      ];
    },
  };
  return contractInfoText[textOption];
};

const Klarna = (discounts, financing, products, extras, currency, language) => {
  const totalExtras = Math.abs(extras.total_extras);
  const totalDiscounts = Math.abs(discounts.total_discounts);
  const isUpgradeFinancing =
    financing?.deviceContractFinance?.financeType === 'UPGRADE';
  const isKlarnaSingleLoan = isEmpty(financing?.extrasContractFinance);
  let textOption = 'product';
  let bankName = 'Klarna';
  let bankNameLong = 'Klarna Bank AB (publ)';

  if (products) {
    if (isEmpty(extras.items) && !isEmpty(discounts.items)) {
      textOption = 'productWithDiscountsNoExtras';
    } else if (isEmpty(discounts.items) && totalExtras > 0) {
      textOption = 'productWithExtras';
    } else if (!isEmpty(extras.items) && !isEmpty(discounts.items)) {
      if (totalDiscounts >= totalExtras) {
        textOption = 'productWithDiscountsHigherEqualExtras';
      } else {
        textOption = 'productWithDiscountsLowerExtras';
      }
    } else if (totalExtras === 0) {
      textOption = 'product';
    }
  } else {
    if (isEmpty(discounts.items)) {
      textOption = 'extrasNoDiscounts';
    } else {
      textOption = 'extrasHigherThanDiscounts';
    }
  }

  // the contractInfoText is created for all calls and product.name is required and not always present
  const productName = products?.map((p) => p.name).join(', ') || 'Device';

  const paymentExtrasInfo = (monthlyPrice) => {
    return !isKlarnaSingleLoan
      ? t({
          id: 'loan.information.payment.list.item',
          message: `You will pay ${currencyFormatter(
            monthlyPrice,
            currency,
            language,
          )} a month for the ${productName} over ${
            financing.flex_period_end_month
          } months.`,
        })
      : t({
          id: 'loan.information.payment.extras.list.item',
          message: `You will pay ${currencyFormatter(
            monthlyPrice,
            currency,
            language,
          )} a month for the ${productName} and extras over ${
            financing.flex_period_end_month
          } months.`,
        });
  };

  const contractInfoText = {
    get product() {
      return [
        t({
          id: 'loan.information.payment.list.item',
          message: `You will pay ${currencyFormatter(
            financing.flex_period_monthly_price,
            currency,
            language,
          )} a month for the ${productName} over ${
            financing.flex_period_end_month
          } months.`,
        }),
        ...(isUpgradeFinancing
          ? [
              t({
                id: 'loan.information.after.payment.list.item',
                message: `After ${
                  financing.fixed_period_end_month
                } payments, the amount paid will be ${currencyFormatter(
                  financing.total_amount_fixed_period,
                  currency,
                  language,
                )}, at which point you will be eligible for upgrade the ${productName}.`,
              }),
              t({
                id: 'loan.information.not.upgrade.list.item',
                message: `If you choose not to upgrade, the estimated total over ${
                  financing.flex_period_end_month
                } months, is ${currencyFormatter(
                  financing.total_amount,
                  currency,
                  language,
                )}, after which the device is yours to keep.`,
              }),
              t({
                id: 'loan.information.upgrade.list.item',
                message: `To upgrade, return the device in a pre-agreed condition and the balance of the agreement will be settled.`,
              }),
              t({
                id: 'loan.information.device.conditions.list.item.generic',
                message: `If the device is not as per the pre-agreed condition, then we will advise of repair cost or a reduced trade in value. We will credit to your ${bankName} account accordingly. You will need to make additional payment/s to ${bankName} to settle the remaining balance.`,
              }),
            ]
          : []),
        t({
          id: 'loan.information.agreement.list.item.generic',
          message: `This finance agreement is subject to status and provided by ${bankNameLong}. If you already have a ${bankName} Credit account, this purchase will be added to your balance.`,
        }),
      ];
    },

    get productWithDiscountsNoExtras() {
      return [
        t({
          id: 'loan.information.applied.discount.list.item',
          message: `An upfront discount of ${currencyFormatter(
            totalDiscounts,
            currency,
            language,
          )} has been applied.`,
        }),
        t({
          id: 'loan.information.payment.list.item',
          message: `You will pay ${currencyFormatter(
            financing.flex_period_monthly_price,
            currency,
            language,
          )} a month for the ${productName} over ${
            financing.flex_period_end_month
          } months.`,
        }),
        ...(isUpgradeFinancing
          ? [
              t({
                id: 'loan.information.after.payment.list.item',
                message: `After ${
                  financing.fixed_period_end_month
                } payments, the amount paid will be ${currencyFormatter(
                  financing.total_amount_fixed_period,
                  currency,
                  language,
                )}, at which point you will be eligible for upgrade the ${productName}.`,
              }),
              t({
                id: 'loan.information.not.upgrade.list.item',
                message: `If you choose not to upgrade, the estimated total over ${
                  financing.flex_period_end_month
                } months, is ${currencyFormatter(
                  financing.total_amount,
                  currency,
                  language,
                )}, after which the device is yours to keep.`,
              }),
              t({
                id: 'loan.information.upgrade.list.item',
                message: `To upgrade, return the device in a pre-agreed condition and the balance of the agreement will be settled.`,
              }),
              t({
                id: 'loan.information.device.conditions.list.item.generic',
                message: `If the device is not as per the pre-agreed condition, then we will advise of repair cost or a reduced trade in value. We will credit to your ${bankName} account accordingly. You will need to make additional payment/s to ${bankName} to settle the remaining balance.`,
              }),
            ]
          : []),
        t({
          id: 'loan.information.agreement.list.item.generic',
          message: `This finance agreement is subject to status and provided by ${bankNameLong}. If you already have a ${bankName} Credit account, this purchase will be added to your balance.`,
        }),
      ];
    },

    get productWithExtras() {
      return [
        paymentExtrasInfo(financing.flex_period_monthly_price),
        ...(isUpgradeFinancing
          ? [
              ...(!isKlarnaSingleLoan
                ? [
                    t({
                      id: 'loan.information.extras.contract.list.item.generic',
                      message: `The extras purchased with the device will have a separate ${bankName} agreement for ${currencyFormatter(
                        extras.monthly_total_extras,
                        currency,
                        language,
                      )} over ${financing.fixed_period_end_month} months`,
                    }),
                  ]
                : []),
              t({
                id: 'loan.information.after.payment.list.item',
                message: `After ${
                  financing.fixed_period_end_month
                } payments, the amount paid will be ${currencyFormatter(
                  financing.total_amount_fixed_period,
                  currency,
                  language,
                )}, at which point you will be eligible for upgrade the ${productName}.`,
              }),
              t({
                id: 'loan.information.not.upgrade.list.item',
                message: `If you choose not to upgrade, the estimated total over ${
                  financing.flex_period_end_month
                } months, is ${currencyFormatter(
                  financing.total_amount,
                  currency,
                  language,
                )}, after which the device is yours to keep.`,
              }),
              t({
                id: 'loan.information.upgrade.list.item',
                message: `To upgrade, return the device in a pre-agreed condition and the balance of the agreement will be settled.`,
              }),
              t({
                id: 'loan.information.device.conditions.list.item.generic',
                message: `If the device is not as per the pre-agreed condition, then we will advise of repair cost or a reduced trade in value. We will credit to your ${bankName} account accordingly. You will need to make additional payment/s to ${bankName} to settle the remaining balance.`,
              }),
              t({
                id: 'loan.information.extras.list.item',
                message: `Any extras purchased as part of this finance agreement do not need to be returned as part of the upgrade.`,
              }),
            ]
          : []),
        t({
          id: 'loan.information.agreement.list.item.generic',
          message: `This finance agreement is subject to status and provided by ${bankNameLong}. If you already have a ${bankName} Credit account, this purchase will be added to your balance.`,
        }),
      ];
    },

    get productWithDiscountsLowerExtras() {
      return [
        t({
          id: 'loan.information.applied.discount.list.item',
          message: `An upfront discount of ${currencyFormatter(
            totalDiscounts,
            currency,
            language,
          )} has been applied.`,
        }),
        paymentExtrasInfo(financing.fixed_period_monthly_price),
        ...(isUpgradeFinancing
          ? [
              ...(!isKlarnaSingleLoan
                ? [
                    t({
                      id: 'loan.information.extras.contract.list.item.generic',
                      message: `The extras purchased with the device will have a separate ${bankName} agreement for ${currencyFormatter(
                        extras.monthly_total_extras,
                        currency,
                        language,
                      )} over ${financing.fixed_period_end_month} months`,
                    }),
                  ]
                : []),
              t({
                id: 'loan.information.after.payment.list.item',
                message: `After ${
                  financing.fixed_period_end_month
                } payments, the amount paid will be ${currencyFormatter(
                  financing.total_amount_fixed_period,
                  currency,
                  language,
                )}, at which point you will be eligible for upgrade the ${productName}.`,
              }),
              t({
                id: 'loan.information.not.upgrade.list.item',
                message: `If you choose not to upgrade, the estimated total over ${
                  financing.flex_period_end_month
                } months, is ${currencyFormatter(
                  financing.total_amount,
                  currency,
                  language,
                )}, after which the device is yours to keep.`,
              }),
              t({
                id: 'loan.information.upgrade.list.item',
                message: `To upgrade, return the device in a pre-agreed condition and the balance of the agreement will be settled.`,
              }),
              t({
                id: 'loan.information.device.conditions.list.item.generic',
                message: `If the device is not as per the pre-agreed condition, then we will advise of repair cost or a reduced trade in value. We will credit to your ${bankName} account accordingly. You will need to make additional payment/s to ${bankName} to settle the remaining balance.`,
              }),
              t({
                id: 'loan.information.extras.list.item',
                message: `Any extras purchased as part of this finance agreement do not need to be returned as part of the upgrade.`,
              }),
            ]
          : []),
        t({
          id: 'loan.information.agreement.list.item.generic',
          message: `This finance agreement is subject to status and provided by ${bankNameLong}. If you already have a ${bankName} Credit account, this purchase will be added to your balance.`,
        }),
      ];
    },

    get productWithDiscountsHigherEqualExtras() {
      return [
        t({
          id: 'loan.information.applied.discount.list.item',
          message: `An upfront discount of ${currencyFormatter(
            totalDiscounts,
            currency,
            language,
          )} has been applied.`,
        }),
        paymentExtrasInfo(financing.fixed_period_monthly_price),
        t({
          id: 'loan.information.extras.payment.list.item',
          message: `Payment for your extras is covered by your upfront discount of ${currencyFormatter(
            totalDiscounts,
            currency,
            language,
          )} (the remainder of which has been applied to your main device).`,
        }),
        ...(isUpgradeFinancing
          ? [
              t({
                id: 'loan.information.after.payment.list.item',
                message: `After ${
                  financing.fixed_period_end_month
                } payments, the amount paid will be ${currencyFormatter(
                  financing.total_amount_fixed_period,
                  currency,
                  language,
                )}, at which point you will be eligible for upgrade the ${productName}.`,
              }),
              t({
                id: 'loan.information.not.upgrade.list.item',
                message: `If you choose not to upgrade, the estimated total over ${
                  financing.flex_period_end_month
                } months, is ${currencyFormatter(
                  financing.total_amount,
                  currency,
                  language,
                )}, after which the device is yours to keep.`,
              }),
              t({
                id: 'loan.information.upgrade.list.item',
                message: `To upgrade, return the device in a pre-agreed condition and the balance of the agreement will be settled.`,
              }),
              t({
                id: 'loan.information.device.conditions.list.item.generic',
                message: `If the device is not as per the pre-agreed condition, then we will advise of repair cost or a reduced trade in value. We will credit to your ${bankName} account accordingly. You will need to make additional payment/s to ${bankName} to settle the remaining balance.`,
              }),
              t({
                id: 'loan.information.extras.list.item',
                message: `Any extras purchased as part of this finance agreement do not need to be returned as part of the upgrade.`,
              }),
            ]
          : []),
        t({
          id: 'loan.information.agreement.list.item.generic',
          message: `This finance agreement is subject to status and provided by ${bankNameLong}. If you already have a ${bankName} Credit account, this purchase will be added to your balance.`,
        }),
      ];
    },

    get extrasNoDiscounts() {
      return [
        ...(!isKlarnaSingleLoan
          ? [
              t({
                id: 'loan.extras.information.extras.contract.list.item.generic',
                message: `The extras purchased with the device will have a separate ${bankName} agreement for ${currencyFormatter(
                  financing.fixed_period_monthly_price,
                  currency,
                  language,
                )} over ${financing.fixed_period_end_month} months`,
              }),
            ]
          : []),
        t({
          id: 'loan.extras.information.after.payment.list.item',
          message: `After ${
            financing.fixed_period_end_month
          } payments, the amount paid will be ${currencyFormatter(
            financing.total_amount_fixed_period,
            currency,
            language,
          )}.`,
        }),
        t({
          id: 'loan.information.extras.list.item',
          message: `Any extras purchased as part of this finance agreement do not need to be returned as part of the upgrade.`,
        }),
      ];
    },

    get extrasHigherThanDiscounts() {
      return [
        t({
          id: 'loan.information.applied.discount.list.item',
          message: `An upfront discount of ${currencyFormatter(
            totalDiscounts,
            currency,
            language,
          )} has been applied.`,
        }),
        ...(!isKlarnaSingleLoan
          ? [
              t({
                id: 'loan.extras.information.extras.contract.list.item.generic',
                message: `The extras purchased with the device will have a separate ${bankName} agreement for ${currencyFormatter(
                  financing.fixed_period_monthly_price,
                  currency,
                  language,
                )} over ${financing.fixed_period_end_month} months`,
              }),
            ]
          : []),
        t({
          id: 'loan.extras.information.after.payment.list.item',
          message: `After ${
            financing.fixed_period_end_month
          } payments, the amount paid will be ${currencyFormatter(
            financing.total_amount_fixed_period,
            currency,
            language,
          )}.`,
        }),
        t({
          id: 'loan.information.extras.list.item',
          message: `Any extras purchased as part of this finance agreement do not need to be returned as part of the upgrade.`,
        }),
      ];
    },
  };
  return contractInfoText[textOption];
};

const OneyFR = (discounts, financing, products, extras, currency, language) => {
  const totalExtras = Math.abs(extras.total_extras);
  const totalDiscounts = Math.abs(discounts.total_discounts);
  const isUpgradeFinancing =
    financing?.deviceContractFinance?.financeType === 'UPGRADE';
  const isSingleLoan = isEmpty(financing?.extrasContractFinance);
  let textOption = 'product';
  let bankName = 'Oney';
  let bankNameLong = 'Oney';
  if (products) {
    if (isEmpty(extras.items) && !isEmpty(discounts.items)) {
      textOption = 'productWithDiscountsNoExtras';
    } else if (isEmpty(discounts.items) && totalExtras > 0) {
      textOption = 'productWithExtras';
    } else if (!isEmpty(extras.items) && !isEmpty(discounts.items)) {
      if (totalDiscounts >= totalExtras) {
        textOption = 'productWithDiscountsHigherEqualExtras';
      } else {
        textOption = 'productWithDiscountsLowerExtras';
      }
    } else if (totalExtras === 0) {
      textOption = 'product';
    }
  } else {
    if (isEmpty(discounts.items)) {
      textOption = 'extrasNoDiscounts';
    } else {
      textOption = 'extrasHigherThanDiscounts';
    }
  }
  // the contractInfoText is created for all calls and product.name is required and not always present
  const productName = products?.map((p) => p.name).join(', ') || 'Device';
  const paymentExtrasInfo = (monthlyPrice) => {
    return !isSingleLoan
      ? t({
          id: 'loan.information.payment.list.item',
          message: `You will pay ${currencyFormatter(
            monthlyPrice,
            currency,
            language,
          )} a month for the ${productName} over ${
            financing.flex_period_end_month
          } months.`,
        })
      : t({
          id: 'loan.information.payment.extras.list.item',
          message: `You will pay ${currencyFormatter(
            monthlyPrice,
            currency,
            language,
          )} a month for the ${productName} and extras over ${
            financing.flex_period_end_month
          } months.`,
        });
  };
  const contractInfoText = {
    get product() {
      return [
        t({
          id: 'loan.information.payment.list.item',
          message: `You will pay ${currencyFormatter(
            financing.flex_period_monthly_price,
            currency,
            language,
          )} a month for the ${productName} over ${
            financing.flex_period_end_month
          } months.`,
        }),
        ...(isUpgradeFinancing
          ? [
              t({
                id: 'loan.information.after.payment.list.item',
                message: `After ${
                  financing.fixed_period_end_month
                } payments, the amount paid will be ${currencyFormatter(
                  financing.total_amount_fixed_period,
                  currency,
                  language,
                )}, at which point you will be eligible for upgrade the ${productName}.`,
              }),
              t({
                id: 'loan.information.not.upgrade.list.item',
                message: `If you choose not to upgrade, the estimated total over ${
                  financing.flex_period_end_month
                } months, is ${currencyFormatter(
                  financing.total_amount,
                  currency,
                  language,
                )}, after which the device is yours to keep.`,
              }),
              t({
                id: 'loan.information.upgrade.list.item',
                message: `To upgrade, return the device in a pre-agreed condition and the balance of the agreement will be settled.`,
              }),
              t({
                id: 'loan.information.device.conditions.list.item.generic',
                message: `If the device is not as per the pre-agreed condition, then we will advise of repair cost or a reduced trade in value. We will credit to your ${bankName} account accordingly. You will need to make additional payment/s to ${bankName} to settle the remaining balance.`,
              }),
            ]
          : []),
        t({
          id: 'loan.information.agreement.list.item.generic',
          message: `This finance agreement is subject to status and provided by ${bankNameLong}. If you already have a ${bankName} Credit account, this purchase will be added to your balance.`,
        }),
      ];
    },
    get productWithDiscountsNoExtras() {
      return [
        t({
          id: 'loan.information.applied.discount.list.item',
          message: `An upfront discount of ${currencyFormatter(
            totalDiscounts,
            currency,
            language,
          )} has been applied.`,
        }),
        t({
          id: 'loan.information.payment.list.item',
          message: `You will pay ${currencyFormatter(
            financing.flex_period_monthly_price,
            currency,
            language,
          )} a month for the ${productName} over ${
            financing.flex_period_end_month
          } months.`,
        }),
        ...(isUpgradeFinancing
          ? [
              t({
                id: 'loan.information.after.payment.list.item',
                message: `After ${
                  financing.fixed_period_end_month
                } payments, the amount paid will be ${currencyFormatter(
                  financing.total_amount_fixed_period,
                  currency,
                  language,
                )}, at which point you will be eligible for upgrade the ${productName}.`,
              }),
              t({
                id: 'loan.information.not.upgrade.list.item',
                message: `If you choose not to upgrade, the estimated total over ${
                  financing.flex_period_end_month
                } months, is ${currencyFormatter(
                  financing.total_amount,
                  currency,
                  language,
                )}, after which the device is yours to keep.`,
              }),
              t({
                id: 'loan.information.upgrade.list.item',
                message: `To upgrade, return the device in a pre-agreed condition and the balance of the agreement will be settled.`,
              }),
              t({
                id: 'loan.information.device.conditions.list.item.generic',
                message: `If the device is not as per the pre-agreed condition, then we will advise of repair cost or a reduced trade in value. We will credit to your ${bankName} account accordingly. You will need to make additional payment/s to ${bankName} to settle the remaining balance.`,
              }),
            ]
          : []),
        t({
          id: 'loan.information.agreement.list.item.generic',
          message: `This finance agreement is subject to status and provided by ${bankNameLong}. If you already have a ${bankName} Credit account, this purchase will be added to your balance.`,
        }),
      ];
    },
    get productWithExtras() {
      return [
        paymentExtrasInfo(financing.flex_period_monthly_price),
        ...(isUpgradeFinancing
          ? [
              ...(!isSingleLoan
                ? [
                    t({
                      id: 'loan.information.extras.contract.list.item.generic',
                      message: `The extras purchased with the device will have a separate ${bankName} agreement for ${currencyFormatter(
                        extras.monthly_total_extras,
                        currency,
                        language,
                      )} over ${financing.fixed_period_end_month} months`,
                    }),
                  ]
                : []),
              t({
                id: 'loan.information.after.payment.list.item',
                message: `After ${
                  financing.fixed_period_end_month
                } payments, the amount paid will be ${currencyFormatter(
                  financing.total_amount_fixed_period,
                  currency,
                  language,
                )}, at which point you will be eligible for upgrade the ${productName}.`,
              }),
              t({
                id: 'loan.information.not.upgrade.list.item',
                message: `If you choose not to upgrade, the estimated total over ${
                  financing.flex_period_end_month
                } months, is ${currencyFormatter(
                  financing.total_amount,
                  currency,
                  language,
                )}, after which the device is yours to keep.`,
              }),
              t({
                id: 'loan.information.upgrade.list.item',
                message: `To upgrade, return the device in a pre-agreed condition and the balance of the agreement will be settled.`,
              }),
              t({
                id: 'loan.information.device.conditions.list.item.generic',
                message: `If the device is not as per the pre-agreed condition, then we will advise of repair cost or a reduced trade in value. We will credit to your ${bankName} account accordingly. You will need to make additional payment/s to ${bankName} to settle the remaining balance.`,
              }),
              t({
                id: 'loan.information.extras.list.item',
                message: `Any extras purchased as part of this finance agreement do not need to be returned as part of the upgrade.`,
              }),
            ]
          : []),
        t({
          id: 'loan.information.agreement.list.item.generic',
          message: `This finance agreement is subject to status and provided by ${bankNameLong}. If you already have a ${bankName} Credit account, this purchase will be added to your balance.`,
        }),
      ];
    },
    get productWithDiscountsLowerExtras() {
      return [
        t({
          id: 'loan.information.applied.discount.list.item',
          message: `An upfront discount of ${currencyFormatter(
            totalDiscounts,
            currency,
            language,
          )} has been applied.`,
        }),
        paymentExtrasInfo(financing.fixed_period_monthly_price),
        ...(isUpgradeFinancing
          ? [
              ...(!isSingleLoan
                ? [
                    t({
                      id: 'loan.information.extras.contract.list.item.generic',
                      message: `The extras purchased with the device will have a separate ${bankName} agreement for ${currencyFormatter(
                        extras.monthly_total_extras,
                        currency,
                        language,
                      )} over ${financing.fixed_period_end_month} months`,
                    }),
                  ]
                : []),
              t({
                id: 'loan.information.after.payment.list.item',
                message: `After ${
                  financing.fixed_period_end_month
                } payments, the amount paid will be ${currencyFormatter(
                  financing.total_amount_fixed_period,
                  currency,
                  language,
                )}, at which point you will be eligible for upgrade the ${productName}.`,
              }),
              t({
                id: 'loan.information.not.upgrade.list.item',
                message: `If you choose not to upgrade, the estimated total over ${
                  financing.flex_period_end_month
                } months, is ${currencyFormatter(
                  financing.total_amount,
                  currency,
                  language,
                )}, after which the device is yours to keep.`,
              }),
              t({
                id: 'loan.information.upgrade.list.item',
                message: `To upgrade, return the device in a pre-agreed condition and the balance of the agreement will be settled.`,
              }),
              t({
                id: 'loan.information.device.conditions.list.item.generic',
                message: `If the device is not as per the pre-agreed condition, then we will advise of repair cost or a reduced trade in value. We will credit to your ${bankName} account accordingly. You will need to make additional payment/s to ${bankName} to settle the remaining balance.`,
              }),
              t({
                id: 'loan.information.extras.list.item',
                message: `Any extras purchased as part of this finance agreement do not need to be returned as part of the upgrade.`,
              }),
            ]
          : []),
        t({
          id: 'loan.information.agreement.list.item.generic',
          message: `This finance agreement is subject to status and provided by ${bankNameLong}. If you already have a ${bankName} Credit account, this purchase will be added to your balance.`,
        }),
      ];
    },
    get productWithDiscountsHigherEqualExtras() {
      return [
        t({
          id: 'loan.information.applied.discount.list.item',
          message: `An upfront discount of ${currencyFormatter(
            totalDiscounts,
            currency,
            language,
          )} has been applied.`,
        }),
        paymentExtrasInfo(financing.fixed_period_monthly_price),
        t({
          id: 'loan.information.extras.payment.list.item',
          message: `Payment for your extras is covered by your upfront discount of ${currencyFormatter(
            totalDiscounts,
            currency,
            language,
          )} (the remainder of which has been applied to your main device).`,
        }),
        ...(isUpgradeFinancing
          ? [
              t({
                id: 'loan.information.after.payment.list.item',
                message: `After ${
                  financing.fixed_period_end_month
                } payments, the amount paid will be ${currencyFormatter(
                  financing.total_amount_fixed_period,
                  currency,
                  language,
                )}, at which point you will be eligible for upgrade the ${productName}.`,
              }),
              t({
                id: 'loan.information.not.upgrade.list.item',
                message: `If you choose not to upgrade, the estimated total over ${
                  financing.flex_period_end_month
                } months, is ${currencyFormatter(
                  financing.total_amount,
                  currency,
                  language,
                )}, after which the device is yours to keep.`,
              }),
              t({
                id: 'loan.information.upgrade.list.item',
                message: `To upgrade, return the device in a pre-agreed condition and the balance of the agreement will be settled.`,
              }),
              t({
                id: 'loan.information.device.conditions.list.item.generic',
                message: `If the device is not as per the pre-agreed condition, then we will advise of repair cost or a reduced trade in value. We will credit to your ${bankName} account accordingly. You will need to make additional payment/s to ${bankName} to settle the remaining balance.`,
              }),
              t({
                id: 'loan.information.extras.list.item',
                message: `Any extras purchased as part of this finance agreement do not need to be returned as part of the upgrade.`,
              }),
            ]
          : []),
        t({
          id: 'loan.information.agreement.list.item.generic',
          message: `This finance agreement is subject to status and provided by ${bankNameLong}. If you already have a ${bankName} Credit account, this purchase will be added to your balance.`,
        }),
      ];
    },
    get extrasNoDiscounts() {
      return [
        ...(!isSingleLoan
          ? [
              t({
                id: 'loan.extras.information.extras.contract.list.item.generic',
                message: `The extras purchased with the device will have a separate ${bankName} agreement for ${currencyFormatter(
                  financing.fixed_period_monthly_price,
                  currency,
                  language,
                )} over ${financing.fixed_period_end_month} months`,
              }),
            ]
          : []),
        t({
          id: 'loan.extras.information.after.payment.list.item',
          message: `After ${
            financing.fixed_period_end_month
          } payments, the amount paid will be ${currencyFormatter(
            financing.total_amount_fixed_period,
            currency,
            language,
          )}.`,
        }),
        t({
          id: 'loan.information.extras.list.item',
          message: `Any extras purchased as part of this finance agreement do not need to be returned as part of the upgrade.`,
        }),
      ];
    },
    get extrasHigherThanDiscounts() {
      return [
        t({
          id: 'loan.information.applied.discount.list.item',
          message: `An upfront discount of ${currencyFormatter(
            totalDiscounts,
            currency,
            language,
          )} has been applied.`,
        }),
        ...(!isSingleLoan
          ? [
              t({
                id: 'loan.extras.information.extras.contract.list.item.generic',
                message: `The extras purchased with the device will have a separate ${bankName} agreement for ${currencyFormatter(
                  financing.fixed_period_monthly_price,
                  currency,
                  language,
                )} over ${financing.fixed_period_end_month} months`,
              }),
            ]
          : []),
        t({
          id: 'loan.extras.information.after.payment.list.item',
          message: `After ${
            financing.fixed_period_end_month
          } payments, the amount paid will be ${currencyFormatter(
            financing.total_amount_fixed_period,
            currency,
            language,
          )}.`,
        }),
        t({
          id: 'loan.information.extras.list.item',
          message: `Any extras purchased as part of this finance agreement do not need to be returned as part of the upgrade.`,
        }),
      ];
    },
  };
  return contractInfoText[textOption];
};

const Instabank = (
  discounts,
  financing,
  products,
  extras,
  currency,
  language,
) => {
  const totalExtras = Math.abs(extras.total_extras);
  const totalDiscounts = Math.abs(discounts.total_discounts);
  const isUpgradeFinancing =
    financing?.deviceContractFinance?.financeType === 'UPGRADE';
  let textOption = 'product';

  if (products) {
    if (isEmpty(extras.items) && !isEmpty(discounts.items)) {
      textOption = 'productWithDiscountsNoExtras';
    } else if (isEmpty(discounts.items) && !isEmpty(extras.items)) {
      textOption = 'productWithExtras';
    } else if (!isEmpty(extras.items) && !isEmpty(discounts.items)) {
      if (totalDiscounts >= totalExtras) {
        textOption = 'productWithDiscountsHigherEqualExtras';
      } else {
        textOption = 'productWithDiscountsLowerExtras';
      }
    }
  }

  const productName = products?.map((p) => p.name).join(', ') || 'Device'; // the contractInfoText is created for all calls and product.name is required and not always present

  const contractInfoText = {
    get product() {
      return [
        t({
          id: 'loan.information.payment.list.item',
          message: `You will pay ${currencyFormatter(
            financing.flex_period_monthly_price,
            currency,
            language,
          )} a month for the ${productName} over ${
            financing.flex_period_end_month
          } months.`,
        }),
        ...(isUpgradeFinancing
          ? [
              t({
                id: 'loan.information.after.payment.list.item',
                message: `After ${
                  financing.fixed_period_end_month
                } payments, the amount paid will be ${currencyFormatter(
                  financing.total_amount_fixed_period,
                  currency,
                  language,
                )}, at which point you will be eligible for upgrade the ${productName}.`,
              }),
              t({
                id: 'loan.information.not.upgrade.list.item',
                message: `If you choose not to upgrade, the estimated total over ${
                  financing.flex_period_end_month
                } months, is ${currencyFormatter(
                  financing.total_amount,
                  currency,
                  language,
                )}, after which the device is yours to keep.`,
              }),
              t({
                id: 'loan.information.upgrade.list.item',
                message: `To upgrade, return the device in a pre-agreed condition and the balance of the agreement will be settled.`,
              }),
              t({
                id: 'loan.information.device.conditions.list.instabank',
                message: `If the device is not as per the pre-agreed condition, then we will advise of repair cost or a reduced trade in value. A lower device value than expected could result in additional payments to settle the remaining balance.`,
              }),
              t({
                id: 'loan.information.return.extras',
                message: `Any extras purchased as part of this finance agreement do not need to be returned as part of the upgrade.`,
              }),
            ]
          : []),
        t({
          id: 'loan.information.agreement.info',
          message: `This finance agreement is subject to status and provided by Instabank ASA.`,
        }),
        ...(isUpgradeFinancing
          ? [
              t({
                id: 'loan.information.effective.interest.rate.fixed',
                message: `Effective interest rate for the contract fixed period month ${financing.fixed_period_end_month} is ${financing.fixed_period_effective_interest_rate}.`,
              }),
            ]
          : []),
        t({
          id: 'loan.information.effective.interest.rate.total',
          message: `Effective interest rate for the total contract period is ${financing.final_period_effective_interest_rate}.`,
        }),
      ];
    },
    get productWithDiscountsNoExtras() {
      return [
        t({
          id: 'loan.information.applied.discount.list.item',
          message: `An upfront discount of ${currencyFormatter(
            totalDiscounts,
            currency,
            language,
          )} has been applied.`,
        }),
        t({
          id: 'loan.information.payment.list.item',
          message: `You will pay ${currencyFormatter(
            financing.flex_period_monthly_price,
            currency,
            language,
          )} a month for the ${productName} over ${
            financing.flex_period_end_month
          } months.`,
        }),
        ...(isUpgradeFinancing
          ? [
              t({
                id: 'loan.information.after.payment.list.item',
                message: `After ${
                  financing.fixed_period_end_month
                } payments, the amount paid will be ${currencyFormatter(
                  financing.total_amount_fixed_period,
                  currency,
                  language,
                )}, at which point you will be eligible for upgrade the ${productName}.`,
              }),
              t({
                id: 'loan.information.not.upgrade.list.item',
                message: `If you choose not to upgrade, the estimated total over ${
                  financing.flex_period_end_month
                } months, is ${currencyFormatter(
                  financing.total_amount,
                  currency,
                  language,
                )}, after which the device is yours to keep.`,
              }),
              t({
                id: 'loan.information.upgrade.list.item',
                message: `To upgrade, return the device in a pre-agreed condition and the balance of the agreement will be settled.`,
              }),
              t({
                id: 'loan.information.device.conditions.list.instabank',
                message: `If the device is not as per the pre-agreed condition, then we will advise of repair cost or a reduced trade in value. A lower device value than expected could result in additional payments to settle the remaining balance.`,
              }),
              t({
                id: 'loan.information.return.extras',
                message: `Any extras purchased as part of this finance agreement do not need to be returned as part of the upgrade.`,
              }),
            ]
          : []),
        t({
          id: 'loan.information.agreement.info',
          message: `This finance agreement is subject to status and provided by Instabank ASA.`,
        }),
        ...(isUpgradeFinancing
          ? [
              t({
                id: 'loan.information.effective.interest.rate.fixed',
                message: `Effective interest rate for the contract fixed period month ${financing.fixed_period_end_month} is ${financing.fixed_period_effective_interest_rate}.`,
              }),
            ]
          : []),
        t({
          id: 'loan.information.effective.interest.rate.total',
          message: `Effective interest rate for the total contract period is ${financing.final_period_effective_interest_rate}.`,
        }),
      ];
    },
    get productWithExtras() {
      return [
        t({
          id: 'loan.information.extras.contract.list.item.instabank',
          message: `The extras purchased with the device will be invoiced along with the main device. Total extras are ${currencyFormatter(
            extras.monthly_total_extras,
            currency,
            language,
          )} over ${financing.fixed_period_end_month} months.`,
        }),
        t({
          id: 'loan.information.payment.fixed.list.item.instabank',
          message: `You will pay ${currencyFormatter(
            financing.fixed_period_monthly_price,
            currency,
            language,
          )} a month for the ${productName} over ${
            financing.fixed_period_end_month
          } months.`,
        }),
        ...(isUpgradeFinancing
          ? [
              t({
                id: 'loan.information.payment.flex.list.instabank',
                message: `You will pay ${currencyFormatter(
                  financing.flex_period_monthly_price,
                  currency,
                  language,
                )} a month for the ${productName} between ${
                  financing.fixed_period_end_month
                } - ${financing.flex_period_end_month} months.`,
              }),
              t({
                id: 'loan.information.after.payment.list.item.instabank',
                message: `After ${
                  financing.fixed_period_end_month
                } payments, the amount paid will be ${currencyFormatter(
                  financing.total_amount_fixed_period,
                  currency,
                  language,
                )}, at which point you will be eligible for upgrade the ${productName}.`,
              }),
              t({
                id: 'loan.information.not.upgrade.list.item',
                message: `If you choose not to upgrade, the estimated total over ${
                  financing.flex_period_end_month
                } months, is ${currencyFormatter(
                  financing.total_amount,
                  currency,
                  language,
                )}, after which the device is yours to keep.`,
              }),
              t({
                id: 'loan.information.upgrade.list.item',
                message: `To upgrade, return the device in a pre-agreed condition and the balance of the agreement will be settled.`,
              }),
              t({
                id: 'loan.information.device.conditions.list.instabank',
                message: `If the device is not as per the pre-agreed condition, then we will advise of repair cost or a reduced trade in value. A lower device value than expected could result in additional payments to settle the remaining balance.`,
              }),
              t({
                id: 'loan.information.return.extras',
                message: `Any extras purchased as part of this finance agreement do not need to be returned as part of the upgrade.`,
              }),
            ]
          : []),
        t({
          id: 'loan.information.agreement.info',
          message: `This finance agreement is subject to status and provided by Instabank ASA.`,
        }),
        ...(isUpgradeFinancing
          ? [
              t({
                id: 'loan.information.effective.interest.rate.fixed',
                message: `Effective interest rate for the contract fixed period month ${financing.fixed_period_end_month} is ${financing.fixed_period_effective_interest_rate}.`,
              }),
            ]
          : []),
        t({
          id: 'loan.information.effective.interest.rate.total',
          message: `Effective interest rate for the total contract period is ${financing.final_period_effective_interest_rate}.`,
        }),
      ];
    },
    get productWithDiscountsLowerExtras() {
      return [
        t({
          id: 'loan.information.applied.discount.list.item',
          message: `An upfront discount of ${currencyFormatter(
            totalDiscounts,
            currency,
            language,
          )} has been applied.`,
        }),
        t({
          id: 'loan.information.extras.contract.list.item.instabank',
          message: `The extras purchased with the device will be invoiced along with the main device. Total extras are ${currencyFormatter(
            extras.monthly_total_extras,
            currency,
            language,
          )} over ${financing.fixed_period_end_month} months.`,
        }),
        t({
          id: 'loan.information.payment.fixed.list.item.instabank',
          message: `You will pay ${currencyFormatter(
            financing.fixed_period_monthly_price,
            currency,
            language,
          )} a month for the ${productName} over ${
            financing.fixed_period_end_month
          } months.`,
        }),
        ...(isUpgradeFinancing
          ? [
              t({
                id: 'loan.information.payment.flex.list.instabank',
                message: `You will pay ${currencyFormatter(
                  financing.flex_period_monthly_price,
                  currency,
                  language,
                )} a month for the ${productName} between ${
                  financing.fixed_period_end_month
                } - ${financing.flex_period_end_month} months.`,
              }),
              t({
                id: 'loan.information.after.payment.list.item.instabank',
                message: `After ${
                  financing.fixed_period_end_month
                } payments, the amount paid will be ${currencyFormatter(
                  financing.total_amount_fixed_period,
                  currency,
                  language,
                )}, at which point you will be eligible for upgrade the ${productName}.`,
              }),
              t({
                id: 'loan.information.not.upgrade.list.item',
                message: `If you choose not to upgrade, the estimated total over ${
                  financing.flex_period_end_month
                } months, is ${currencyFormatter(
                  financing.total_amount,
                  currency,
                  language,
                )}, after which the device is yours to keep.`,
              }),
              t({
                id: 'loan.information.upgrade.list.item',
                message: `To upgrade, return the device in a pre-agreed condition and the balance of the agreement will be settled.`,
              }),
              t({
                id: 'loan.information.device.conditions.list.instabank',
                message: `If the device is not as per the pre-agreed condition, then we will advise of repair cost or a reduced trade in value. A lower device value than expected could result in additional payments to settle the remaining balance.`,
              }),
              t({
                id: 'loan.information.return.extras',
                message: `Any extras purchased as part of this finance agreement do not need to be returned as part of the upgrade.`,
              }),
            ]
          : []),
        t({
          id: 'loan.information.agreement.info',
          message: `This finance agreement is subject to status and provided by Instabank ASA.`,
        }),
        ...(isUpgradeFinancing
          ? [
              t({
                id: 'loan.information.effective.interest.rate.fixed',
                message: `Effective interest rate for the contract fixed period month ${financing.fixed_period_end_month} is ${financing.fixed_period_effective_interest_rate}.`,
              }),
            ]
          : []),
        t({
          id: 'loan.information.effective.interest.rate.total',
          message: `Effective interest rate for the total contract period is ${financing.final_period_effective_interest_rate}.`,
        }),
      ];
    },
    get productWithDiscountsHigherEqualExtras() {
      return [
        t({
          id: 'loan.information.applied.discount.list.item',
          message: `An upfront discount of ${currencyFormatter(
            totalDiscounts,
            currency,
            language,
          )} has been applied.`,
        }),
        t({
          id: 'loan.information.payment.list.item',
          message: `You will pay ${currencyFormatter(
            financing.flex_period_monthly_price,
            currency,
            language,
          )} a month for the ${productName} over ${
            financing.flex_period_end_month
          } months.`,
        }),
        ...(isUpgradeFinancing
          ? [
              t({
                id: 'loan.information.after.payment.list.item',
                message: `After ${
                  financing.fixed_period_end_month
                } payments, the amount paid will be ${currencyFormatter(
                  financing.total_amount_fixed_period,
                  currency,
                  language,
                )}, at which point you will be eligible for upgrade the ${productName}.`,
              }),
              t({
                id: 'loan.information.not.upgrade.list.item',
                message: `If you choose not to upgrade, the estimated total over ${
                  financing.flex_period_end_month
                } months, is ${currencyFormatter(
                  financing.total_amount,
                  currency,
                  language,
                )}, after which the device is yours to keep.`,
              }),
              t({
                id: 'loan.information.upgrade.list.item',
                message: `To upgrade, return the device in a pre-agreed condition and the balance of the agreement will be settled.`,
              }),
              t({
                id: 'loan.information.device.conditions.list.instabank',
                message: `If the device is not as per the pre-agreed condition, then we will advise of repair cost or a reduced trade in value. A lower device value than expected could result in additional payments to settle the remaining balance.`,
              }),
              t({
                id: 'loan.information.return.extras',
                message: `Any extras purchased as part of this finance agreement do not need to be returned as part of the upgrade.`,
              }),
            ]
          : []),
        t({
          id: 'loan.information.agreement.info',
          message: `This finance agreement is subject to status and provided by Instabank ASA.`,
        }),
        ...(isUpgradeFinancing
          ? [
              t({
                id: 'loan.information.effective.interest.rate.fixed',
                message: `Effective interest rate for the contract fixed period month ${financing.fixed_period_end_month} is ${financing.fixed_period_effective_interest_rate}.`,
              }),
            ]
          : []),
        t({
          id: 'loan.information.effective.interest.rate.total',
          message: `Effective interest rate for the total contract period is ${financing.final_period_effective_interest_rate}.`,
        }),
      ];
    },
  };
  return contractInfoText[textOption];
};

const ExpressBank = (
  discounts,
  financing,
  products,
  extras,
  currency,
  language,
) => {
  const totalExtras = Math.abs(extras.total_extras);
  const totalDiscounts = Math.abs(discounts.total_discounts);
  const isUpgradeFinancing =
    financing?.deviceContractFinance?.financeType === 'UPGRADE';
  let textOption = 'product';

  if (products) {
    if (isEmpty(extras.items) && !isEmpty(discounts.items)) {
      textOption = 'productWithDiscountsNoExtras';
    } else if (isEmpty(discounts.items) && totalExtras > 0) {
      textOption = 'productWithExtras';
    } else if (!isEmpty(extras.items) && !isEmpty(discounts.items)) {
      if (totalDiscounts >= totalExtras) {
        textOption = 'productWithDiscountsHigherEqualExtras';
      } else {
        textOption = 'productWithDiscountsLowerExtras';
      }
    } else if (totalExtras === 0) {
      textOption = 'product';
    }
  } else {
    if (isEmpty(discounts.items)) {
      textOption = 'extrasNoDiscounts';
    } else {
      textOption = 'extrasHigherThanDiscounts';
    }
  }

  const productName = products?.map((p) => p.name).join(', ') || 'Device'; // the contractInfoText is created for all calls and product.name is required and not always present

  const contractInfoText = {
    get product() {
      return [
        t({
          id: 'loan.information.payment.list.item',
          message: `You will pay ${currencyFormatter(
            financing.flex_period_monthly_price,
            currency,
            language,
          )} a month for the ${productName} over ${
            financing.flex_period_end_month
          } months.`,
        }),
        ...(isUpgradeFinancing
          ? [
              t({
                id: 'loan.information.after.payment.list.item',
                message: `After ${
                  financing.fixed_period_end_month
                } payments, the amount paid will be ${currencyFormatter(
                  financing.total_amount_fixed_period,
                  currency,
                  language,
                )}, at which point you will be eligible for upgrade the ${productName}.`,
              }),
              t({
                id: 'loan.information.not.upgrade.list.item',
                message: `If you choose not to upgrade, the estimated total over ${
                  financing.flex_period_end_month
                } months, is ${currencyFormatter(
                  financing.total_amount,
                  currency,
                  language,
                )}, after which the device is yours to keep.`,
              }),
              t({
                id: 'loan.information.upgrade.list.item',
                message: `To upgrade, return the device in a pre-agreed condition and the balance of the agreement will be settled.`,
              }),
              t({
                id: 'loan.express.information.device.conditions.list.item',
                message: `If the device is not as per the pre-agreed condition, then we will advise of repair cost or a reduced trade in value. We will credit to your Express Bank account accordingly. You will need to make additional payment/s to Express Bank to settle the remaining balance.`,
              }),
            ]
          : []),
        t({
          id: 'loan.express.information.agreement.list.item',
          message: `This finance agreement is subject to status and provided by Express Bank Bank AB (publ). If you already have a Express Bank Credit account, this purchase will be added to your balance.`,
        }),
      ];
    },

    get productWithDiscountsNoExtras() {
      return [
        t({
          id: 'loan.information.applied.discount.list.item',
          message: `An upfront discount of ${currencyFormatter(
            totalDiscounts,
            currency,
            language,
          )} has been applied.`,
        }),
        t({
          id: 'loan.information.payment.list.item',
          message: `You will pay ${currencyFormatter(
            financing.flex_period_monthly_price,
            currency,
            language,
          )} a month for the ${productName} over ${
            financing.flex_period_end_month
          } months.`,
        }),
        ...(isUpgradeFinancing
          ? [
              t({
                id: 'loan.information.after.payment.list.item',
                message: `After ${
                  financing.fixed_period_end_month
                } payments, the amount paid will be ${currencyFormatter(
                  financing.total_amount_fixed_period,
                  currency,
                  language,
                )}, at which point you will be eligible for upgrade the ${productName}.`,
              }),
              t({
                id: 'loan.information.not.upgrade.list.item',
                message: `If you choose not to upgrade, the estimated total over ${
                  financing.flex_period_end_month
                } months, is ${currencyFormatter(
                  financing.total_amount,
                  currency,
                  language,
                )}, after which the device is yours to keep.`,
              }),
              t({
                id: 'loan.information.upgrade.list.item',
                message: `To upgrade, return the device in a pre-agreed condition and the balance of the agreement will be settled.`,
              }),
              t({
                id: 'loan.express.information.device.conditions.list.item',
                message: `If the device is not as per the pre-agreed condition, then we will advise of repair cost or a reduced trade in value. We will credit to your Express Bank account accordingly. You will need to make additional payment/s to Express Bank to settle the remaining balance.`,
              }),
            ]
          : []),
        t({
          id: 'loan.express.information.agreement.list.item',
          message: `This finance agreement is subject to status and provided by Express Bank Bank AB (publ). If you already have a Express Bank Credit account, this purchase will be added to your balance.`,
        }),
      ];
    },

    get productWithExtras() {
      return [
        t({
          id: 'loan.information.payment.list.item',
          message: `You will pay ${currencyFormatter(
            financing.flex_period_monthly_price,
            currency,
            language,
          )} a month for the ${productName} over ${
            financing.flex_period_end_month
          } months.`,
        }),
        ...(isUpgradeFinancing
          ? [
              t({
                id: 'loan.express.information.extras.contract.list.item',
                message: `The extras purchased with the device will have a separate Express Bank agreement for ${currencyFormatter(
                  extras.monthly_total_extras,
                  currency,
                  language,
                )} over ${financing.fixed_period_end_month} months`,
              }),
              t({
                id: 'loan.information.after.payment.list.item',
                message: `After ${
                  financing.fixed_period_end_month
                } payments, the amount paid will be ${currencyFormatter(
                  financing.total_amount_fixed_period,
                  currency,
                  language,
                )}, at which point you will be eligible for upgrade the ${productName}.`,
              }),
              t({
                id: 'loan.information.not.upgrade.list.item',
                message: `If you choose not to upgrade, the estimated total over ${
                  financing.flex_period_end_month
                } months, is ${currencyFormatter(
                  financing.total_amount,
                  currency,
                  language,
                )}, after which the device is yours to keep.`,
              }),
              t({
                id: 'loan.information.upgrade.list.item',
                message: `To upgrade, return the device in a pre-agreed condition and the balance of the agreement will be settled.`,
              }),
              t({
                id: 'loan.express.information.device.conditions.list.item',
                message: `If the device is not as per the pre-agreed condition, then we will advise of repair cost or a reduced trade in value. We will credit to your Express Bank account accordingly. You will need to make additional payment/s to Express Bank to settle the remaining balance.`,
              }),
              t({
                id: 'loan.information.extras.list.item',
                message: `Any extras purchased as part of this finance agreement do not need to be returned as part of the upgrade.`,
              }),
            ]
          : []),
        t({
          id: 'loan.express.information.agreement.list.item',
          message: `This finance agreement is subject to status and provided by Express Bank Bank AB (publ). If you already have a Express Bank Credit account, this purchase will be added to your balance.`,
        }),
      ];
    },

    get productWithDiscountsLowerExtras() {
      return [
        t({
          id: 'loan.information.applied.discount.list.item',
          message: `An upfront discount of ${currencyFormatter(
            totalDiscounts,
            currency,
            language,
          )} has been applied.`,
        }),
        t({
          id: 'loan.information.payment.list.item',
          message: `You will pay ${currencyFormatter(
            financing.fixed_period_monthly_price,
            currency,
            language,
          )} a month for the ${productName} over ${
            financing.flex_period_end_month
          } months.`,
        }),
        ...(isUpgradeFinancing
          ? [
              t({
                id: 'loan.express.information.extras.contract.list.item',
                message: `The extras purchased with the device will have a separate Express Bank agreement for ${currencyFormatter(
                  extras.monthly_total_extras,
                  currency,
                  language,
                )} over ${financing.fixed_period_end_month} months`,
              }),
              t({
                id: 'loan.information.after.payment.list.item',
                message: `After ${
                  financing.fixed_period_end_month
                } payments, the amount paid will be ${currencyFormatter(
                  financing.total_amount_fixed_period,
                  currency,
                  language,
                )}, at which point you will be eligible for upgrade the ${productName}.`,
              }),
              t({
                id: 'loan.information.not.upgrade.list.item',
                message: `If you choose not to upgrade, the estimated total over ${
                  financing.flex_period_end_month
                } months, is ${currencyFormatter(
                  financing.total_amount,
                  currency,
                  language,
                )}, after which the device is yours to keep.`,
              }),
              t({
                id: 'loan.information.upgrade.list.item',
                message: `To upgrade, return the device in a pre-agreed condition and the balance of the agreement will be settled.`,
              }),
              t({
                id: 'loan.express.information.device.conditions.list.item',
                message: `If the device is not as per the pre-agreed condition, then we will advise of repair cost or a reduced trade in value. We will credit to your Express Bank account accordingly. You will need to make additional payment/s to Express Bank to settle the remaining balance.`,
              }),
              t({
                id: 'loan.information.extras.list.item',
                message: `Any extras purchased as part of this finance agreement do not need to be returned as part of the upgrade.`,
              }),
            ]
          : []),
        t({
          id: 'loan.express.information.agreement.list.item',
          message: `This finance agreement is subject to status and provided by Express Bank Bank AB (publ). If you already have a Express Bank Credit account, this purchase will be added to your balance.`,
        }),
      ];
    },

    get productWithDiscountsHigherEqualExtras() {
      return [
        t({
          id: 'loan.information.applied.discount.list.item',
          message: `An upfront discount of ${currencyFormatter(
            totalDiscounts,
            currency,
            language,
          )} has been applied.`,
        }),
        t({
          id: 'loan.information.payment.list.item',
          message: `You will pay ${currencyFormatter(
            financing.flex_period_monthly_price,
            currency,
            language,
          )} a month for the ${productName} over ${
            financing.flex_period_end_month
          } months.`,
        }),
        t({
          id: 'loan.information.extras.payment.list.item',
          message: `Payment for your extras is covered by your upfront discount of ${currencyFormatter(
            totalDiscounts,
            currency,
            language,
          )} (the remainder of which has been applied to your main device).`,
        }),
        ...(isUpgradeFinancing
          ? [
              t({
                id: 'loan.information.after.payment.list.item',
                message: `After ${
                  financing.fixed_period_end_month
                } payments, the amount paid will be ${currencyFormatter(
                  financing.total_amount_fixed_period,
                  currency,
                  language,
                )}, at which point you will be eligible for upgrade the ${productName}.`,
              }),
              t({
                id: 'loan.information.not.upgrade.list.item',
                message: `If you choose not to upgrade, the estimated total over ${
                  financing.flex_period_end_month
                } months, is ${currencyFormatter(
                  financing.total_amount,
                  currency,
                  language,
                )}, after which the device is yours to keep.`,
              }),
              t({
                id: 'loan.information.upgrade.list.item',
                message: `To upgrade, return the device in a pre-agreed condition and the balance of the agreement will be settled.`,
              }),
              t({
                id: 'loan.express.information.device.conditions.list.item',
                message: `If the device is not as per the pre-agreed condition, then we will advise of repair cost or a reduced trade in value. We will credit to your Express Bank account accordingly. You will need to make additional payment/s to Express Bank to settle the remaining balance.`,
              }),
              t({
                id: 'loan.information.extras.list.item',
                message: `Any extras purchased as part of this finance agreement do not need to be returned as part of the upgrade.`,
              }),
            ]
          : []),
        t({
          id: 'loan.express.information.agreement.list.item',
          message: `This finance agreement is subject to status and provided by Express Bank Bank AB (publ). If you already have a Express Bank Credit account, this purchase will be added to your balance.`,
        }),
      ];
    },

    get extrasNoDiscounts() {
      return [
        t({
          id: 'loan.express.extras.information.extras.contract.list.item',
          message: `The extras purchased with the device will have a separate Express Bank agreement for ${currencyFormatter(
            financing.fixed_period_monthly_price,
            currency,
            language,
          )} over ${financing.fixed_period_end_month} months`,
        }),
        t({
          id: 'loan.extras.information.after.payment.list.item',
          message: `After ${
            financing.fixed_period_end_month
          } payments, the amount paid will be ${currencyFormatter(
            financing.total_amount_fixed_period,
            currency,
            language,
          )}.`,
        }),
        t({
          id: 'loan.information.extras.list.item',
          message: `Any extras purchased as part of this finance agreement do not need to be returned as part of the upgrade.`,
        }),
      ];
    },

    get extrasHigherThanDiscounts() {
      return [
        t({
          id: 'loan.information.applied.discount.list.item',
          message: `An upfront discount of ${currencyFormatter(
            totalDiscounts,
            currency,
            language,
          )} has been applied.`,
        }),
        t({
          id: 'loan.express.extras.information.extras.contract.list.item',
          message: `The extras purchased with the device will have a separate Express Bank agreement for ${currencyFormatter(
            financing.fixed_period_monthly_price,
            currency,
            language,
          )} over ${financing.fixed_period_end_month} months`,
        }),
        t({
          id: 'loan.extras.information.after.payment.list.item',
          message: `After ${
            financing.fixed_period_end_month
          } payments, the amount paid will be ${currencyFormatter(
            financing.total_amount_fixed_period,
            currency,
            language,
          )}.`,
        }),
        t({
          id: 'loan.information.extras.list.item',
          message: `Any extras purchased as part of this finance agreement do not need to be returned as part of the upgrade.`,
        }),
      ];
    },
  };

  return contractInfoText[textOption];
};

export const MAX_CONTRACTS_DISPLAYED = 100;
