import {
  CLEAR_DASHBOARD_DATA,
  SAVE_SOCKET_CONFIG,
  SAVE_SOCKET_MESSAGE,
  SAVE_SOCKET_STATUS,
} from 'actions/dashboard';

import initialState from 'reducers/initialState';

export default function (state = initialState.dashboard, action) {
  switch (action.type) {
    case SAVE_SOCKET_CONFIG:
      return { ...state, uid: action.uid, date: action.date };
    case SAVE_SOCKET_MESSAGE:
      return { ...state, ...action.data };
    case SAVE_SOCKET_STATUS:
      return { ...state, socketStatus: action.status };
    case CLEAR_DASHBOARD_DATA:
      return { ...initialState.dashboard, uid: state.uid, date: state.date };
    default:
      return state;
  }
}
