import { deleteOfferRule } from 'actions/admin/campaign';
import { openModalAction } from 'actions/ui';

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import Modal from 'components/Modal';
import Table from 'components/table/Table';

export const OfferRules = ({ selectOfferRule, offerRules = [] }) => {
  const dispatch = useDispatch();
  const [offerRule, setOfferRule] = useState(null);

  const performDeleteOfferRule = async () => {
    await dispatch(deleteOfferRule(offerRule));
  };

  const columns = [
    {
      label: t({
        id: 'table.column.offer.manufacturer',
        message: `Manufacturer`,
      }),
      accessor: 'manufacturerName',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.offer.category', message: `Category` }),
      accessor: 'categoryName',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.offer.family', message: `Family` }),
      accessor: 'familyName',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.offer.device', message: `Device` }),
      accessor: 'deviceName',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.offer.financing.type',
        message: `Financing Type`,
      }),
      accessor: 'financingType',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.offer.number.months',
        message: `Financing Duration`,
      }),
      accessor: 'numberOfMonths',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.offer.group', message: `Offer Group` }),
      accessor: 'offerGroup',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.start.date', message: `Start Date` }),
      accessor: 'startDate',
      type: 'date',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.end.date', message: `End Date` }),
      accessor: 'endDate',
      type: 'date',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.campaign.actions', message: `Actions` }),
      accessor: 'actions',
      type: 'actions',
      filterable: false,
      sortable: false,
      align: 'right',
      wrap: false,
    },
  ];

  const actions = [
    {
      label: t({ id: 'table.column.actions.edit', message: `Edit` }),
      handler: (_, { actions, ...values }) => {
        selectOfferRule(values);
        document.getElementById('offerRulesForm').scrollIntoView({
          block: 'start',
          behavior: 'smooth',
          inline: 'start',
        });
      },
    },
    {
      label: t({ id: 'table.column.actions.delete', message: `Delete` }),
      handler: (_, { id, campaignID }) => {
        setOfferRule({ id, campaignID });
        dispatch(openModalAction('admin-offer-rule-delete'));
      },
    },
  ];

  const rows = offerRules?.map((offerRule) => ({ ...offerRule, actions }));

  return (
    <>
      <Table columns={columns} data={rows} keyColumn='id' filterable={false} />
      <Modal name='admin-offer-rule-delete' onConfirm={performDeleteOfferRule}>
        <Trans id='blocks.delete.offer.rule.confirmation'>
          Are you sure you want to delete this offer rule ?
        </Trans>
      </Modal>
    </>
  );
};

export default OfferRules;
