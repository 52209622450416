import {
  addDeviceReferenceToCartAction,
  clearContractAction,
  finalizeCartAction,
  getContractStatusAction,
} from 'actions/contract';
import { addNotificationAction } from 'actions/ui';
import omit from 'lodash/omit';
import { getCurrentCart } from 'utils';

import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Trans } from '@lingui/macro';

import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import CreditStep from 'components/CreditStep';
import Cart from 'components/cart/Cart';
import DeviceReferenceForm from 'components/device_reference/DeviceReferenceForm';

import {
  APPROVED,
  PARTIALLY_APPROVED,
  PENDING,
  REFUSED,
  WITHDRAWN,
} from 'constants/contracts';
import { IMEI } from 'constants/deviceReference';
import { upgradeFlow as upgrade } from 'constants/flows';
import { SMSDeliveryError } from 'constants/notifications';
import { GIFT_CARD, TRADE_IN } from 'constants/recycle';

import usePolling from 'hooks/usePolling';
import useTrackPage from 'hooks/useTrackPage';

import './CreditSteps.styl';
import CreditSignActions from './components/CreditSignActions';
import PostponeContract from './components/PostponeContract';
import RetryCreditCheck from './components/RetryCreditCheck';

export const CreditSteps = () => {
  useTrackPage('ContractStatus');

  const dispatch = useDispatch();
  const [verifiedID, setVerifiedID] = useState(false);
  const { cartUUID } = useParams();

  const cart = useSelector(({ carts }) => getCurrentCart(carts, cartUUID));
  const funder = useSelector(() => cart?.funder.toLowerCase());
  const financingType = useSelector(() => cart?.financingType);

  const language = useSelector(({ whitelabel }) => whitelabel.language);
  const country = useSelector(({ whitelabel }) => whitelabel.country);
  const currency = useSelector(({ whitelabel }) => whitelabel.currency);
  const hasPosaleMultiDeviceTradein = useSelector(
    ({ whitelabel }) => whitelabel.configs.hasPosaleMultiDeviceTradein,
  );
  const useAppleValidation = useSelector(
    ({ whitelabel }) => whitelabel.configs.useAppleValidation,
  );
  const showCustomerIDCheck = useSelector(
    ({ whitelabel }) => whitelabel.configs.showCustomerIDCheck,
  );
  const recycleOptions = useSelector(
    ({ whitelabel }) => whitelabel.configs.recycleOptions,
  );

  const contractCustomer = useSelector(
    ({ contract }) => contract.contractCustomer,
  );
  const deviceReference = useSelector(
    ({ contract }) => contract.deviceReference,
  );
  const deviceFunderReference = useSelector(
    ({ contract }) => contract.deviceFunderReference,
  );
  const lastMessageStatus = useSelector(
    ({ contract }) => contract.lastMessageStatus,
  );
  const steps = useSelector(({ contract }) => contract.steps);

  const tradecart = useSelector(({ recycle }) => recycle?.tradecart);

  const { stopPolling } = usePolling({
    callback: getContractStatusAction,
    interval: 10000,
    hasDispatch: true,
    args: { cartUUID },
  });

  useEffect(() => {
    const allStepsApproved = Object.values(omit(steps, ['swap'])).every(
      (step) => step === APPROVED,
    );

    allStepsApproved && stopPolling();

    if (steps.deviceReference === APPROVED && !verifiedID) {
      setVerifiedID(true);
    }
  }, [steps, verifiedID, setVerifiedID, stopPolling]);

  useEffect(() => {
    if (lastMessageStatus === 'ERROR') {
      dispatch(addNotificationAction(SMSDeliveryError()));
    }
  }, [lastMessageStatus, dispatch]);

  useEffect(() => {
    return () => dispatch(clearContractAction());
  }, [dispatch]);

  const isContractInvalid = [REFUSED, WITHDRAWN].includes(steps.creditSign);

  const showDeviceReferenceStep =
    financingType === upgrade && !isContractInvalid;

  const showDeviceReferenceForm =
    steps.termsAndConditions === APPROVED &&
    [APPROVED, PARTIALLY_APPROVED].includes(steps.creditSign) &&
    deviceFunderReference &&
    (showCustomerIDCheck ? verifiedID : true);

  const showTradeinStep = tradecart?.id && !isContractInvalid;

  const showHandoverDevice = !isContractInvalid;

  const showActivateContract =
    Object.values(omit(steps, ['recycleStatus', 'handOverDevice'])).every(
      (step) => step === APPROVED || step === PARTIALLY_APPROVED,
    ) &&
    steps.handOverDevice === PENDING &&
    (showCustomerIDCheck ? verifiedID : true);

  const verifyIDStepStatus =
    verifiedID || steps.deviceReference === APPROVED ? APPROVED : PENDING;

  const endCart = () => dispatch(finalizeCartAction(cartUUID));

  return (
    <Fragment>
      <main className='content-left'>
        <CreditStep
          type='termsAndConditions'
          status={steps.termsAndConditions}
        />

        <CreditStep
          type='creditSign'
          status={steps.creditSign}
          visibleExtraContent={isContractInvalid}
          renderExtraContent={
            <CreditSignActions
              cart={cart}
              status={steps.creditSign}
              allowGiftCardOption={
                hasPosaleMultiDeviceTradein &&
                tradecart?.source === TRADE_IN &&
                recycleOptions[TRADE_IN].includes(GIFT_CARD)
              }
              currency={currency}
              language={language}
            />
          }
        />

        <CreditStep
          type='verifyID'
          status={verifyIDStepStatus}
          visible={showCustomerIDCheck}
          data={{
            firstName: contractCustomer?.firstName,
            lastName: contractCustomer?.lastName,
          }}
          renderAside={
            <Checkbox
              id='customer'
              // eslint-disable-next-line react/jsx-no-bind
              onChange={(ev) => setVerifiedID(ev.target.checked)}
              disabled={
                steps.creditSign !== APPROVED ||
                steps.deviceReference === APPROVED
              }
              checked={verifiedID || steps.deviceReference === APPROVED}
            />
          }
        />

        <CreditStep
          type='deviceReference'
          status={steps.deviceReference}
          visible={showDeviceReferenceStep}
          visibleExtraContent={showDeviceReferenceForm}
          renderExtraContent={
            <DeviceReferenceForm
              deviceReference={deviceReference}
              disabled={steps.deviceReference === APPROVED}
              // eslint-disable-next-line react/jsx-no-bind
              onSubmit={({ deviceReference, referenceType }) =>
                dispatch(
                  addDeviceReferenceToCartAction(
                    cartUUID,
                    deviceReference,
                    referenceType === IMEI,
                  ),
                )
              }
              submitted={Boolean(deviceReference)}
              useAppleValidation={useAppleValidation}
            />
          }
        />

        {showActivateContract && (
          <Button onClick={endCart} classes='activateContract'>
            <Trans id='activate.contract.title'>Activate Contract</Trans>
          </Button>
        )}

        <CreditStep
          type={tradecart?.source === TRADE_IN ? 'recycle' : 'swap'}
          status={steps.recycleStatus}
          visible={showTradeinStep}
        />

        <CreditStep
          type='handOver'
          status={steps.handOverDevice}
          visible={showHandoverDevice}
        />

        <CreditStep type='swap' status={steps.swap} visible={steps.swap} />

        {steps.creditSign === PENDING && (
          <RetryCreditCheck
            cartUUID={cartUUID}
            funder={funder}
            country={country}
          />
        )}

        {Object.values(steps).some(
          (step) => step !== APPROVED && step !== PARTIALLY_APPROVED,
        ) &&
          Object.values(steps).every(
            (step) => step !== REFUSED && step !== WITHDRAWN,
          ) && (
            <PostponeContract
              financingType={financingType}
              cartUUID={cartUUID}
            />
          )}
      </main>

      <aside className='content-right'>
        <Cart cart={cart} currency={currency} language={language} />
      </aside>
    </Fragment>
  );
};

export default CreditSteps;
