import { setDiagnosticAction } from 'actions/recycle';
import { openModalAction } from 'actions/ui';

import React from 'react';
import { useDispatch } from 'react-redux';

import { Trans } from '@lingui/macro';

import Button from 'components/Button';
import Modal from 'components/Modal';
import IconChecked from 'components/icons/IconChecked';
import IconExclamation from 'components/icons/IconExclamation';

import './DeviceDiagnosticTest.styl';

const DeviceDiagnosticTest = ({ device }) => {
  return device.diagnostic ? (
    <DiagnosticTestDone device={device} />
  ) : (
    <DiagnosticTestPending device={device} />
  );
};

const DiagnosticTestDone = ({ device }) => {
  const openDiagnosticReport = () =>
    window.open(device?.diagnostic?.reportLink, '_blank');

  return (
    <div className='tradecart-device__diagnostic-test'>
      <div className='tradecart-device__diagnostic-success'>
        <IconChecked
          className='tradecart-device__diagnostic-icon tradecart-device__diagnostic-icon__success'
          data-test='icon-exclamation'
        />
        <span>
          <strong>Software diagnostic successfully finished!</strong>
        </span>
      </div>

      <Button
        classes='tradecart-device__diagnostic-button'
        onClick={openDiagnosticReport}
      >
        <Trans id='device.diagnostic.button.download'>Show Report</Trans>
      </Button>
    </div>
  );
};

const DiagnosticTestPending = ({ device }) => {
  const dispatch = useDispatch();

  const addEventListener = () => {
    if (window) {
      window.addEventListener('message', iframeListener);
    }
  };

  const removeEventListener = () => {
    if (window) {
      window.removeEventListener('message', iframeListener);
    }
  };

  const iframeListener = (event) => {
    const completedRegExp = new RegExp(/(\w*~\w{1,3}~.*-.*-.*-.*-.*)|(\w*~)/g);

    if (
      typeof event.data === 'string' &&
      (completedRegExp.test(event.data) || event.data === 'Skipped~~')
    ) {
      dispatch(
        setDiagnosticAction({
          diagnosticString: event.data,
          selectedDevice: device,
        }),
      );

      removeEventListener();
    }
  };

  const openDiagnosticModal = () => {
    addEventListener();
    dispatch(openModalAction(`diagnostic-modal-${device?.id}`));
  };

  const closeDiagnosticModal = () => removeEventListener();

  return (
    <div className='tradecart-device__diagnostic-test'>
      <div className='tradecart-device__diagnostic-warning'>
        <IconExclamation
          className='tradecart-device__diagnostic-icon tradecart-device__diagnostic-icon__warning'
          data-test='icon-exclamation'
        />
        <span>
          <strong>
            Manual evaluation do not fulfil recommended criteria.{' '}
          </strong>
          Please scan QR code and follow steps in downloaded application to test
          device and finish evaluation.
        </span>
      </div>

      <Button
        classes='tradecart-device__diagnostic-button'
        onClick={openDiagnosticModal}
      >
        <Trans id='device.diagnostic.button.start'>Show QR code</Trans>
      </Button>

      <Modal
        classes='tradecart-device__diagnostic-modal'
        name={`diagnostic-modal-${device?.id}`}
        closeModal={closeDiagnosticModal}
      >
        <iframe
          id='ifdiagnostics_run'
          title='Device diagnostic'
          className='tradecart-device__diagnostic-iframe'
          src={device?.diagnosticURL}
        />
      </Modal>
    </div>
  );
};

export default DeviceDiagnosticTest;
