import {
  cleanSettlementsAction,
  exportEntityAction,
  getSettlementsAction,
} from 'actions/reports';
import { currencyFormatter } from 'helpers/formatters';
import isEmpty from 'lodash/isEmpty';
import { Field, getFormValues, reduxForm, submit } from 'redux-form';

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import Button from 'components/Button';
import Footer from 'components/Footer';
import RenderDatePicker, { formatDate } from 'components/RenderDatePicker';
import Section from 'components/Section';
import Table from 'components/table/Table';

import { weekInMillis } from 'constants/dates';
import { ADMIN, VENDOR_MANAGER } from 'constants/user';

import usePermission from 'hooks/usePermission';
import useTrackPage from 'hooks/useTrackPage';

import './Settlements.styl';

const Settlements = ({ handleSubmit }) => {
  useTrackPage('Settlements');

  const dispatch = useDispatch();

  const { hasAuthorization, renderUnauthorized } = usePermission([
    ADMIN,
    VENDOR_MANAGER,
  ]);
  const [isLoadingSettlements, setIsLoadingSettlements] = useState(false);

  const formValues = useSelector((state) =>
    getFormValues('settlementsFilters')(state),
  );
  const currency = useSelector(({ whitelabel }) => whitelabel.currency);
  const language = useSelector(({ whitelabel }) => whitelabel.language);
  const summary = useSelector(({ reports }) => reports.settlements.summary);
  const payouts = useSelector(({ reports }) => reports.settlements.payouts);
  const pagination = useSelector(
    ({ reports }) => reports.settlements.pagination,
  );

  const getSettlementsWithLoading = useCallback(
    async (offset, size) => {
      const { dateFrom, dateTo } = formValues;
      setIsLoadingSettlements(true);

      await dispatch(getSettlementsAction(dateFrom, dateTo, offset, size));

      setIsLoadingSettlements(false);
    },
    [formValues, dispatch],
  );

  useEffect(() => {
    return () => {
      dispatch(cleanSettlementsAction());
    };
  }, [dispatch, getSettlementsWithLoading]);

  const getPaginatedData = async (offset, size) => {
    getSettlementsWithLoading(offset, size);
  };

  const exportFile = (type, paymentRef) => {
    const { dateFrom, dateTo } = formValues;

    return dispatch(
      exportEntityAction('SETTLEMENTS', type, {
        from: dateFrom,
        to: dateTo,
        payment_reference: paymentRef,
      }),
    );
  };

  const columns = [
    {
      label: t({ id: 'table.column.payout.date', message: `Payout Date` }),
      accessor: 'payout_date',
      type: 'date',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.payment.ref',
        message: `Payment Reference`,
      }),
      accessor: 'payment_reference',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.sale', message: `Sale` }),
      accessor: 'sale_amount',
      type: 'currency',
      filterable: false,
      sortable: true,
      align: 'right',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.fees', message: `Fees` }),
      accessor: 'fee_amount',
      type: 'currency',
      filterable: false,
      sortable: true,
      align: 'right',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.taxes', message: `Taxes` }),
      accessor: 'tax_amount',
      type: 'currency',
      filterable: false,
      sortable: true,
      align: 'right',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.return', message: `Return` }),
      accessor: 'return_amount',
      type: 'currency',
      filterable: false,
      sortable: true,
      align: 'right',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.settlement', message: `Settlement` }),
      accessor: 'settlement_amount',
      type: 'currency',
      filterable: false,
      sortable: true,
      align: 'right',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.download', message: `Download` }),
      accessor: 'actions',
      type: 'actions',
      filterable: false,
      sortable: false,
      align: 'right',
      wrap: false,
    },
  ];

  const actions = [
    {
      label: 'CSV',
      handler: (_ev, row) => {
        exportFile('CSV', row.payment_reference);
      },
    },
    {
      label: 'PDF',
      handler: (_ev, row) => {
        exportFile('PDF', row.payment_reference);
      },
    },
  ];

  const rows = payouts?.map((payout) => ({ ...payout, actions }));

  if (!hasAuthorization) return renderUnauthorized();

  return (
    <>
      <h1 className='admin-page-title'>
        <Trans id='settlements.title'>Settlements Reports</Trans>
      </h1>

      <Section>
        <form
          className='datepicker-container'
          autoComplete='off'
          onSubmit={handleSubmit}
        >
          <Trans id='settlements.dates.title'>Payout</Trans>

          <Field
            name='dateFrom'
            component={RenderDatePicker}
            label={t({
              id: 'settlements.dates.label.from',
              message: `Payout from`,
            })}
            format={formatDate}
            parse={formatDate}
          />

          <Field
            name='dateTo'
            component={RenderDatePicker}
            label={t({ id: 'settlements.dates.label.to', message: `to` })}
            format={formatDate}
            parse={formatDate}
          />
        </form>
      </Section>

      <Section>
        <div className='settlements-summary'>
          <h2>
            {pagination?.total}{' '}
            <Trans id='settlements.summary.title'>Payouts</Trans>
          </h2>
          <hr />

          <ul className='settlements-stats'>
            <li>
              <h3>
                <Trans id='settlements.stats.payouts'>Total Payouts</Trans>
              </h3>
              {currencyFormatter(
                summary?.summary_total_settlement_amount,
                currency,
                language,
              )}
            </li>
            <li>
              <h3>
                <Trans id='settlements.stats.sales'>Total Sales</Trans>
              </h3>
              {currencyFormatter(
                summary?.summary_total_sale_amount,
                currency,
                language,
              )}
            </li>
            <li>
              <h3>
                <Trans id='settlements.stats.fees'>Total Fees</Trans>
              </h3>
              {currencyFormatter(
                summary?.summary_total_fee_amount,
                currency,
                language,
              )}
            </li>
            <li>
              <h3>
                <Trans id='settlements.stats.taxes'>Total Taxes</Trans>
              </h3>
              {currencyFormatter(
                summary?.summary_total_tax_amount,
                currency,
                language,
              )}
            </li>
            <li>
              <h3>
                <Trans id='settlements.stats.returns'>Total Returns</Trans>
              </h3>
              {currencyFormatter(
                summary?.summary_total_return_amount,
                currency,
                language,
              )}
            </li>
          </ul>

          <Button
            classes='export-settlements'
            onClick={() => exportFile('PDF')}
            disabled={isEmpty(rows)}
          >
            <Trans id='settlements.stats.export.pdf'>Export PDF</Trans>
          </Button>
          <Button
            classes='export-settlements'
            onClick={() => exportFile('CSV')}
            disabled={isEmpty(rows)}
          >
            <Trans id='settlements.stats.export.csv'>Export CSV</Trans>
          </Button>
        </div>

        <Table
          columns={columns}
          data={rows}
          filterable={false}
          keyColumn='payment_reference'
          defaultOrder='payout_date'
          defaultDir='desc'
          pagination={{
            ...pagination,
            getPaginatedData: getPaginatedData,
          }}
          loading={isLoadingSettlements}
        />
      </Section>

      <Footer classes='main' copyright={false} />
    </>
  );
};

export default reduxForm({
  form: 'settlementsFilters',
  onChange: (values, dispatch) => {
    dispatch(getSettlementsAction(values.dateFrom, values.dateTo, 0));
    dispatch(submit('settlementsFilters'));
  },
  onSubmit: (_values, dispatch) => {
    dispatch(submit('settlementsFilters'));
  },
  initialValues: {
    dateFrom: formatDate(new Date(new Date().getTime() - weekInMillis)),
    dateTo: formatDate(new Date()),
  },
  destroyOnUnmount: false,
})(Settlements);
