import {
  createUpgradeOfferAction,
  getOffersFormDataAction,
  updateUpgradeOfferAction,
} from 'actions/admin/campaign';
import { numericality, required } from 'helpers/validators';
import isEmpty from 'lodash/isEmpty';
import { clearFields, getFormValues } from 'redux-form';

import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';

import ButtonForm from 'components/ButtonForm';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';

const OfferForm = ({
  campaignID,
  manufacturers,
  deviceCategories,
  deviceFamilies,
  devices,
  financingTypes,
  getOffersFormData,
  createUpgradeOffer,
  updateUpgradeOffer,
  clearFormFields,
  onSubmit,
  onClose,
  formValues,
  initialValues,
}) => {
  const fields = [
    {
      component: Input,
      placeholder: t({
        id: 'forms.offer.placeholder.group',
        message: `Offer Group`,
      }),
      name: 'offerGroup',
      size: 'medium',
      variant: 'underline',
      validate: [required()],
    },
    {
      component: Input,
      placeholder: t({
        id: 'forms.offer.placeholder.sequence',
        message: `Sequence Number`,
      }),
      name: 'sequenceNum',
      size: 'medium',
      variant: 'underline',
      validate: [numericality()],
    },
    {
      component: Dropdown,
      clearable: true,
      options: manufacturers,
      placeholder: t({
        id: 'forms.offer.placeholder.manufacturer',
        message: `Manufacturer`,
      }),
      name: 'manufacturer',
      size: 'medium',
      testSelector: 'addOfferManufacturerDropdown',
      variant: 'underline',
      disabled: isEmpty(manufacturers),
      onChange: (value) => {
        getOffersFormData({ manufacturer: value });
        clearFormFields(
          'offerForm',
          false,
          false,
          'category',
          'family',
          'deviceCode',
        );
      },
    },
    {
      component: Dropdown,
      clearable: true,
      options: deviceCategories,
      placeholder: t({
        id: 'forms.offer.placeholder.device.category',
        message: `Device Category`,
      }),

      name: 'category',
      size: 'medium',
      testSelector: 'addOfferDeviceCategoryDropdown',
      variant: 'underline',
      disabled: isEmpty(deviceCategories),
      onChange: (value) => {
        getOffersFormData({
          manufacturer: formValues.manufacturer,
          category: value,
        });
        clearFormFields('offerForm', false, false, 'family', 'deviceCode');
      },
    },
    {
      component: Dropdown,
      clearable: true,
      options: deviceFamilies,
      placeholder: t({
        id: 'forms.offer.placeholder.device.family',
        message: `Device Family`,
      }),

      name: 'family',
      size: 'medium',
      testSelector: 'addOfferDeviceFamilyDropdown',
      variant: 'underline',
      disabled: isEmpty(deviceFamilies),
      onChange: (value) => {
        getOffersFormData({
          manufacturer: formValues.manufacturer,
          category: formValues.category,
          family: value,
        });
        clearFormFields('offerForm', false, false, 'deviceCode');
      },
    },
    {
      component: Dropdown,
      clearable: true,
      options: devices,
      disabled: isEmpty(devices),
      placeholder: t({
        id: 'forms.offer.placeholder.device.code',
        message: `Device Code`,
      }),

      name: 'deviceCode',
      size: 'medium',
      testSelector: 'addOfferDeviceCodeDropdown',
      variant: 'underline',
    },
    {
      component: Input,
      placeholder: t({
        id: 'forms.offer.placeholder.image.url',
        message: `Image URL`,
      }),
      helpText: t({
        id: 'forms.offer.placeholder.external.image.link.text',
        message: `Specify an URL here for a custom image`,
      }),
      name: 'image',
      size: 'medium',
      variant: 'underline',
    },
    {
      component: Dropdown,
      placeholder: t({
        id: 'forms.offer.placeholder.offer.type',
        message: `Offer Type`,
      }),

      options: [
        { label: 'Accessory', value: 'ACCESSORY' },
        { label: 'Device', value: 'DEVICE' },
      ],
      name: 'type',
      size: 'medium',
      testSelector: 'addOfferTypeDropdown',
      variant: 'underline',
      validate: [required()],
    },
    {
      component: Dropdown,
      clearable: true,
      placeholder: t({
        id: 'forms.offer.placeholder.financing.type',
        message: `Financing Type`,
      }),

      options: financingTypes,
      name: 'financingType',
      size: 'medium',
      testSelector: 'addOfferFinancingTypeDropdown',
      variant: 'underline',
      validate: [required()],
    },
    {
      component: Dropdown,
      clearable: true,
      placeholder: t({
        id: 'forms.offer.placeholder.duration',
        message: `Number of Months`,
      }),
      options: [
        { label: '12', value: 12 },
        { label: '24', value: 24 },
        { label: '36', value: 36 },
      ],
      helpText: t({
        id: 'forms.offer.duration.text',
        message: `Number of months that applies to the offer`,
      }),
      name: 'numberOfMonths',
      size: 'medium',
      testSelector: 'addOfferMonthsDropdown',
      variant: 'underline',
      validate: [numericality({ greaterThan: 0, allowBlank: true })],
    },
    {
      component: Input,
      placeholder: t({
        id: 'forms.offer.placeholder.external.url',
        message: `External URL`,
      }),
      helpText: t({
        id: 'forms.offer.placeholder.external.link.text',
        message: `Specify an URL here to config an offer from your website`,
      }),
      name: 'url',
      size: 'medium',
      variant: 'underline',
    },
    {
      component: Dropdown,
      clearable: true,
      placeholder: t({
        id: 'forms.campaign.placeholder.status',
        message: `Status`,
      }),

      options: [
        { label: 'Enabled', value: 'ENABLED' },
        { label: 'Disabled', value: 'DISABLED' },
      ],
      name: 'status',
      size: 'medium',
      testSelector: 'addOfferStatusDropdown',
      variant: 'underline',
      validate: [required()],
    },
    {
      component: Input,
      placeholder: t({
        id: 'forms.offer.placeholder.title',
        message: `Title`,
      }),
      helpText: t({
        id: 'forms.offer.placeholder.title.text',
        message: `Specify a title to be used on the Landing Page offers`,
      }),
      name: 'title',
      size: 'medium',
      variant: 'underline',
    },
    {
      component: Input,
      placeholder: t({
        id: 'forms.offer.placeholder.paragraph',
        message: `Paragraph`,
      }),
      helpText: t({
        id: 'forms.offer.placeholder.paragraph.text',
        message: `Lading Page offer paragraph content`,
      }),
      name: 'paragraph',
      size: 'medium',
      variant: 'underline',
    },
    {
      component: Input,
      placeholder: t({
        id: 'forms.offer.placeholder.button.label',
        message: `Button Label`,
      }),
      helpText: t({
        id: 'forms.offer.placeholder.button.label.text',
        message: `Button label for Landing Page offers`,
      }),
      name: 'buttonLabel',
      size: 'medium',
      variant: 'underline',
    },
  ];

  const getFormDataCallBack = useCallback(() => {
    const data = formValues || initialValues;

    getOffersFormData({
      manufacturer: data?.manufacturer,
      category: data?.category,
      family: data?.family,
    });
  }, [formValues, initialValues, getOffersFormData]);

  const handleSubmit = async (values) => {
    const request = isEmpty(initialValues)
      ? createUpgradeOffer
      : updateUpgradeOffer;

    return await request({
      campaignID,
      ...values,
      manufacturerName: manufacturers?.find(
        ({ value }) => values?.manufacturer === value,
      )?.label,
      deviceName: devices?.find(({ value }) => values?.deviceCode === value)
        ?.label,
      familyName: deviceFamilies?.find(({ value }) => values?.family === value)
        ?.label,
      categoryName: deviceCategories?.find(
        ({ value }) => values?.category === value,
      )?.label,
    });
  };

  return (
    <ButtonForm
      name='offerForm'
      getFormData={getFormDataCallBack}
      fields={fields}
      title={
        isEmpty(initialValues) ? (
          <Trans id='offer.form.add.title'>Add Offer</Trans>
        ) : (
          <Trans id='offer.form.edit.title'>Edit Offer</Trans>
        )
      }
      openBtnLabel={<Trans id='admin.offer.add.button'>Add Offer</Trans>}
      handleSubmit={handleSubmit}
      onSubmitSuccess={onSubmit}
      onClose={onClose}
      values={initialValues}
      toggleShowForm={!isEmpty(initialValues)}
    />
  );
};

export default connect(
  (state) => ({
    formValues: getFormValues('offerForm')(state),
    campaignID: state.adminCampaign.campaign.campaignCode,
    deviceCategories: state.adminCampaign.deviceCategories,
    deviceFamilies: state.adminCampaign.deviceFamilies,
    devices: state.adminCampaign.devices,
    financingTypes: state.adminCampaign.financingTypes,
    manufacturers: state.adminCampaign.manufacturers,
  }),
  {
    getOffersFormData: getOffersFormDataAction,
    createUpgradeOffer: createUpgradeOfferAction,
    updateUpgradeOffer: updateUpgradeOfferAction,
    clearFormFields: clearFields,
  },
)(OfferForm);
