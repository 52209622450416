import { isToday } from 'date-fns';

import { t } from '@lingui/macro';

export const dateFormatter = (value, language, formatToday = true) => {
  if (typeof value !== 'undefined' && value !== '' && value !== null) {
    const date =
      isNaN(value) && /:+/.test(value)
        ? new Date(`${value}${'Z'}`)
        : new Date(value);

    const formattingOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    if (formatToday && isToday(date)) {
      const todayString = t({ id: 'format.date.today', message: `Today, at` });

      const options = {
        hour: 'numeric',
        minute: 'numeric',
      };

      return `${todayString} ${new Intl.DateTimeFormat(
        language,
        options,
      ).format(date)}`;
    } else {
      return new Intl.DateTimeFormat(language, formattingOptions).format(date);
    }
  }

  return '-';
};

export const dateTimeFormatter = (value, language, formatToday = true) => {
  if (typeof value !== 'undefined' && value !== '' && value !== null) {
    const date =
      isNaN(value) && /:+/.test(value)
        ? new Date(`${value}${'Z'}`)
        : new Date(value);

    const formattingOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    if (formatToday && isToday(date)) {
      const todayString = t({ id: 'format.date.today', message: `Today, at` });

      const options = {
        hour: 'numeric',
        minute: 'numeric',
      };

      return `${todayString} ${new Intl.DateTimeFormat(
        language,
        options,
      ).format(date)}`;
    } else {
      return new Intl.DateTimeFormat(language, formattingOptions).format(date);
    }
  }

  return '-';
};

export const unixToDateString = (date) => {
  const month = addZeroToDateDigit(date.getMonth() + 1);
  const day = addZeroToDateDigit(date.getDate());

  return `${date.getFullYear()}-${month}-${day}`;
};

export const addZeroToDateDigit = (value) => {
  return `${value.toString().length === 1 ? '0' : ''}${value}`;
};

export const currencyFormatter = (value, currency, language, options = {}) =>
  value !== null &&
  typeof value !== 'undefined' &&
  currency !== 'null' &&
  typeof currency !== 'undefined'
    ? new Intl.NumberFormat(language, {
        style: 'currency',
        currency,
        ...options,
      }).format(value)
    : '';

export const numberFormatter = (language, value, options) =>
  Intl.NumberFormat(language, options).format(value);

export const stringFormatter = (str = '', values, symbol = '%') => {
  if (values) {
    values.forEach((value, i) => {
      const regex = new RegExp(`${symbol}${i}`, 'g');
      str = str.replace(regex, value);
    });
  }

  const regex = new RegExp(`${symbol}\\d`, 'g');
  str = str.replace(regex, 'null');

  return str;
};

export const stringWithCommasFormatter = (str) => {
  return str.replace(/,/g, '⧇');
};

export const stringWithCommasParser = (str) => {
  return str.replace(/⧇/g, ',');
};

export const stringToDate = (date) => {
  return date ? new Date(date) : null;
};

export const formatDateForDatepicker = (date) => {
  if (date === undefined || date === null) {
    return '';
  }

  try {
    return new Date(date)?.toISOString()?.substring(0, 10);
  } catch (e) {
    // IGNORE
  }
};

export const optionsToArrayObjs = (obj) =>
  Object.entries(obj).reduce(
    (all, [value, label]) => [...all, { value, label }],
    [],
  );
