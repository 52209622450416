import { t } from '@lingui/macro';

import { errorCodes } from 'constants/errors';

export const changePasswordSuccess = () => ({
  title: t({
    id: 'notifications.pwd.change.success.title',
    message: `Success`,
  }),
  message: t({
    id: 'notifications.pwd.change.success.message',
    message: `Password changed successfully.`,
  }),
  type: 'success',
});

export const recoverPasswordSuccess = () => ({
  title: t({
    id: 'notifications.recover.pwd.success.title',
    message: `Recover Password`,
  }),
  message: t({
    id: 'notifications.recover.pwd.success.message',
    message: `An email with a recovering link has been sent. Please check you inbox in a few moments.`,
  }),
  type: 'success',
});

export const adminResetPasswordSuccess = () => ({
  title: t({
    id: 'notifications.pwd.reset.success.title',
    message: `Password reset success`,
  }),
  message: t({
    id: 'notifications.pwd.reset.success.message',
    message: `A reset password email has been sent to this user.`,
  }),
  type: 'success',
});

export const customerUpdateSuccess = () => ({
  title: t({
    id: 'notifications.customer.update.success.title',
    message: `Customer data updated with success!`,
  }),
  message: t({
    id: 'notifications.customer.update.success.message',
    message: `You can now finalise the Trade In.`,
  }),
  type: 'success',
});

export const amendDeviceReferenceSuccessful = () => ({
  title: t({
    id: 'notifications.amend.device.reference.success.title',
    message: `Update IMEI/SN successful`,
  }),
  message: t({
    id: 'notifications.amend.device.reference.success.message',
    message: `Your device was successfully updated.`,
  }),
  type: 'success',
});

export const amendDeviceCodeSuccessful = () => ({
  title: t({
    id: 'notifications.amend.device.code.success.title',
    message: `Update Part Number successful`,
  }),
  message: t({
    id: 'notifications.amend.device.code.success.message',
    message: `Your device was successfully updated.`,
  }),
  type: 'success',
});

export const contractCancelSuccessful = () => ({
  title: t({
    id: 'notifications.cancel.contract.success.title',
    message: `Cancel Contract`,
  }),
  message: t({
    id: 'notifications.cancel.contract.success.message',
    message: `This contract was cancelled successfully.`,
  }),
  type: 'success',
});

export const contractsCancelSuccessful = () => ({
  title: t({
    id: 'notifications.cancel.contracts.success.title',
    message: `Cancel Contracts`,
  }),
  message: t({
    id: 'notifications.cancel.contracts.success.message',
    message: `These contracts were cancelled successfully.`,
  }),
  type: 'success',
});

export const upgradeCancelSuccessful = () => ({
  title: t({
    id: 'notifications.cancel.upgrade.success.title',
    message: `Cancel Upgrade`,
  }),
  message: t({
    id: 'notifications.cancel.upgrade.success.message',
    message: `This upgrade was cancelled successfully.`,
  }),
  type: 'success',
});

export const addToCartSuccess = () => ({
  title: t({
    id: 'notifications.add.product.success.title',
    message: `Product added to cart.`,
  }),
  message: t({
    id: 'notifications.add.product.success.message',
    message: `The cart has been successfully updated.`,
  }),
  type: 'success',
});

export const storeAddSuccess = () => ({
  title: t({
    id: 'notifications.add.store.success.title',
    message: `Add Store`,
  }),
  message: t({
    id: 'notifications.add.store.success.message',
    message: `Store added successfully.`,
  }),
  type: 'success',
});

export const storeEditSuccess = () => ({
  title: t({
    id: 'notifications.edit.store.success.title',
    message: `Edit Store`,
  }),
  message: t({
    id: 'notifications.edit.store.success.message',
    message: `Store updated successfully.`,
  }),
  type: 'success',
});

export const userAddSuccess = () => ({
  title: t({ id: 'notifications.add.user.success.title', message: `Add User` }),
  message: t({
    id: 'notifications.add.user.success.message',
    message: `User added successfully.`,
  }),
  type: 'success',
});

export const userEditSuccess = () => ({
  title: t({
    id: 'notifications.edit.user.success.title',
    message: `Edit User`,
  }),
  message: t({
    id: 'notifications.edit.user.success.message',
    message: `User updated successfully.`,
  }),
  type: 'success',
});

export const deviceAddSuccess = () => ({
  title: t({
    id: 'notifications.add.device.success.title',
    message: `Add Device`,
  }),
  message: t({
    id: 'notifications.add.device.success.message',
    message: `Device added successfully.`,
  }),
  type: 'success',
});

export const deviceRemoveSuccess = () => ({
  title: t({
    id: 'notifications.remove.device.success.title',
    message: `Remove Device`,
  }),
  message: t({
    id: 'notifications.remove.device.success.message',
    message: `Device removed successfully.`,
  }),
  type: 'success',
});

export const deviceEditSuccess = () => ({
  title: t({
    id: 'notifications.edit.device.success.title',
    message: `Edit Device`,
  }),
  message: t({
    id: 'notifications.edit.device.success.message',
    message: `Device updated successfully.`,
  }),
  type: 'success',
});

export const blockedFeature = () => ({
  title: t({
    id: 'notifications.blocked.feature.title',
    message: `Blocked Feature`,
  }),
  message: t({
    id: 'notifications.blocked.feature.message',
    message: `This feature is currently blocked for this retailer.`,
  }),
  type: 'error',
});

export const createCollectionSuccess = () => ({
  title: t({
    id: 'notifications.create.collection.success.title',
    message: `Create Collection`,
  }),
  message: t({
    id: 'notifications.create.collection.success.message',
    message: `The collection was successfully created.`,
  }),
  type: 'success',
});

export const retryCreditCheckSuccess = () => ({
  title: t({
    id: 'notifications.retry.creditcheck.success.title',
    message: `Resend credit application`,
  }),
  message: t({
    id: 'notifications.retry.creditcheck.success.message',
    message: `The credit application URL was successfully sent.`,
  }),
  type: 'success',
});

export const postponeContractSuccess = () => ({
  title: t({
    id: 'notifications.postpone.contract.success.title',
    message: `Postpone contract`,
  }),
  message: t({
    id: 'notifications.postpone.contract.success.message',
    message: `Contract successfully postponed. You can find it in the Pending Contracts list.`,
  }),
  type: 'success',
});

export const swapEvaluationSuccess = () => ({
  title: t({
    id: 'notifications.swap.evaluation.success.title',
    message: `Evaluation confirmed`,
  }),
  message: t({
    id: 'notifications.swap.evaluation.success.message',
    message: `The device has been evaluated. You can start adding a new device.`,
  }),
  type: 'success',
});

export const returnDeviceSuccess = () => ({
  title: t({
    id: 'notifications.return.device.success.title',
    message: `Return confirmed`,
  }),
  message: t({
    id: 'notifications.return.device.success.message',
    message: `The device has been returned.`,
  }),
  type: 'success',
});

export const noDevicesToRecycle = () => ({
  title: t({
    id: 'notifications.no.devices.warning.title',
    message: `No Devices found`,
  }),
  message: t({
    id: 'notifications.no.devices.warning.message',
    message: `There are no devices that correspond to the device reference provided.`,
  }),
  type: 'warning',
});

export const emptyCartError = () => ({
  title: t({ id: 'error.title', message: `Error` }),
  message: t({
    id: 'empty.cart.error',
    message: `Please add product(s) to continue with the process`,
  }),
  type: 'error',
});

export const exportJSONError = (code, values, message) => ({
  title: t({ id: 'notifications.export.JSON.error.title', message: `Error` }),
  message: errorCodes()[code] || message,
  type: 'error',
  values,
});

export const uploadFileSuccess = () => ({
  title: t({
    id: 'notifications.upload.csv.success.title',
    message: `Upload successful`,
  }),
  message: t({
    id: 'notifications.upload.csv.success',
    message: `All devices were imported successfully.`,
  }),
  type: 'success',
});

export const uploadFilePartialSuccess = () => ({
  title: t({
    id: 'notifications.upload.csv.partial.success.title',
    message: `Upload partially successful`,
  }),
  message: t({
    id: 'notifications.upload.csv.partial.success',
    message: `Some devices were imported successfully. Check the upload report for more info on the ones that failed.`,
  }),
  type: 'warning',
});

export const uploadFileFailure = () => ({
  title: t({
    id: 'notifications.upload.csv.failure.title',
    message: `Upload Failed`,
  }),
  message: t({
    id: 'notifications.upload.csv.failure',
    message: `No devices were imported. Check the upload report for more info.`,
  }),
  type: 'error',
});

export const blockedCartWarning = () => ({
  title: t({ id: 'notifications.blocked.cart.title', message: `Warning` }),
  message: t({
    id: 'notifications.blocked.cart.warning',
    message: `You cannot go back and modify the content of this cart.`,
  }),
  type: 'warning',
});

export const blockedOrderWarning = () => ({
  title: t({ id: 'notifications.blocked.order.title', message: `Warning` }),
  message: t({
    id: 'notifications.blocked.order.warning',
    message: `You cannot go back and modify the content of this order.`,
  }),
  type: 'warning',
});

export const contractCantUpgrade = () => ({
  title: t({ id: 'notifications.cant.upgrade.title', message: `Warning` }),
  message: t({
    id: 'notifications.cant.upgrade.warning',
    message: `You were trying to upgrade an invalid old contract. You can proceed with a new purchase.`,
  }),
  type: 'warning',
});

export const saveBlockSuccess = () => ({
  title: t({
    id: 'notifications.save.block.success.title',
    message: `Success`,
  }),
  message: t({
    id: 'notifications.save.block.success',
    message: `HTML block was saved successfully.`,
  }),
  type: 'success',
});

export const saveTemplateSuccess = () => ({
  title: t({
    id: 'notifications.save.template.success.title',
    message: `Success`,
  }),
  message: t({
    id: 'notifications.save.template.success',
    message: `The template was saved successfully.`,
  }),
  type: 'success',
});

export const missingNextEvaluationQuestion = () => ({
  title: t({ id: 'error.title', message: `Error` }),
  message: t({
    id: 'notifications.missing.evaluation.question.error',
    message: `There was a problem with the selection of the answer. Couldn't retrieve the next question. Please restart the process or contact our support email support@financetechnology.com`,
  }),
  type: 'error',
});

export const missingProductOptions = () => ({
  title: t({
    id: 'notifications.missing.product.options.title',
    message: `Missing data`,
  }),
  message: t({
    id: 'notifications.missing.product.options.',
    message: `We failed to retrieve some information regarding the product options. Please refresh the page. If this problem persists contact our support email support@financetechnology.com`,
  }),
  type: 'error',
});

export const invalidPricingToolData = () => ({
  title: t({
    id: 'notifications.invalid.pricing.tool.data.title',
    message: `Invalid data`,
  }),
  message: t({
    id: 'notificationsinvalid.pricing.tool.data',
    message: `The data retrived is not valid to show this page correctly. Please try again or contact our support email support@financetechnology.com`,
  }),
  type: 'error',
});

export const orderCancelSuccess = () => ({
  title: t({
    id: 'notifications.order.cancel.success.title',
    message: `Quote Canceled`,
  }),
  message: t({
    id: 'notifications.order.cancel.success',
    message: `This quote has been successfully canceled.`,
  }),
  type: 'success',
});

export const orderCancelError = () => ({
  title: t({
    id: 'notifications.order.cancel.error.title',
    message: `Quote Cancellation Error`,
  }),
  message: t({
    id: 'notifications.order.cancel.error',
    message: `There was an error trying to cancel this quote. Please try again later. If it persists please contact support our support email support@financetechnology.com.`,
  }),
  type: 'error',
});

export const createTestContractSuccess = () => ({
  title: t({
    id: 'notifications.create.test.contract.success.title',
    message: `Success`,
  }),
  message: t({
    id: 'notifications.create.test.contract.success',
    message: `Test contract was saved successfully.`,
  }),
  type: 'success',
});

export const deleteTestContractSuccess = () => ({
  title: t({
    id: 'notifications.delete.test.contract.success.title',
    message: `Success`,
  }),
  message: t({
    id: 'notifications.delete.test.contract.success',
    message: `Test contract was deleted successfully.`,
  }),
  type: 'success',
});

export const changeTestContractStageSuccess = () => ({
  title: t({
    id: 'notifications.change.test.contract.stage.success.title',
    message: `Success`,
  }),
  message: t({
    id: 'notifications.change.test.contract.stage.success',
    message: `Test contract stage was updated successfully.`,
  }),
  type: 'success',
});

export const publishTemplateSuccess = () => ({
  title: t({
    id: 'notifications.publish.template.success.title',
    message: `Success`,
  }),
  message: t({
    id: 'notifications.publish.template.success',
    message: `Template draft sucessfully published.`,
  }),
  type: 'success',
});

export const readTemplateFail = () => ({
  title: t({
    id: 'notifications.read.template.fail.title',
    message: `Error retrieving the template`,
  }),

  message: t({
    id: 'notifications.read.template.fail',
    message: `We were unable to retrieve the template. Please try again later. If the problem persists please contact Support.`,
  }),

  type: 'error',
});

export const rollbackTemplateSuccess = () => ({
  title: t({
    id: 'notifications.rollback.template.success.title',
    message: `Success`,
  }),
  message: t({
    id: 'notifications.rollback.template.success',
    message: `Template rollback was performed successfully.`,
  }),
  type: 'success',
});

export const createCampaignSuccess = () => ({
  title: t({
    id: 'notifications.create.campaign.success.title',
    message: `Success`,
  }),
  message: t({
    id: 'notifications.create.campaign.success',
    message: `The campaign was successfully created.`,
  }),
  type: 'success',
});

export const updateCampaignSuccess = () => ({
  title: t({
    id: 'notifications.update.campaign.success.title',
    message: `Success`,
  }),
  message: t({
    id: 'notifications.update.campaign.success',
    message: `The campaign was successfully edited.`,
  }),
  type: 'success',
});

export const createCohortRuleSuccess = () => ({
  title: t({
    id: 'notifications.create.cohort.success.title',
    message: `Success`,
  }),
  message: t({
    id: 'notifications.create.cohort.success',
    message: `The cohort rule was successfully created.`,
  }),
  type: 'success',
});

export const updateCohortRuleSuccess = () => ({
  title: t({
    id: 'notifications.update.cohort.success.title',
    message: `Success`,
  }),
  message: t({
    id: 'notifications.update.cohort.success',
    message: `The cohort rule was successfully edited.`,
  }),
  type: 'success',
});

export const createCommunicationSuccess = () => ({
  title: t({
    id: 'notifications.create.communication.success.title',
    message: `Success`,
  }),
  message: t({
    id: 'notifications.create.communication.success',
    message: `The communication was successfully created.`,
  }),
  type: 'success',
});

export const updateCommunicationSuccess = () => ({
  title: t({
    id: 'notifications.update.communication.success.title',
    message: `Success`,
  }),
  message: t({
    id: 'notifications.update.communication.success',
    message: `The communication was successfully edited.`,
  }),
  type: 'success',
});

export const createOfferSuccess = () => ({
  title: t({
    id: 'notifications.create.offer.success.title',
    message: `Success`,
  }),
  message: t({
    id: 'notifications.create.offer.success',
    message: `The offer was successfully created.`,
  }),
  type: 'success',
});

export const updateOfferSuccess = () => ({
  title: t({
    id: 'notifications.update.offer.success.title',
    message: `Success`,
  }),
  message: t({
    id: 'notifications.update.offer.success',
    message: `The offer was successfully edited.`,
  }),
  type: 'success',
});

export const createOfferRuleSuccess = () => ({
  title: t({
    id: 'notifications.create.offer.rule.success.title',
    message: `Success`,
  }),
  message: t({
    id: 'notifications.create.offer.rule.success',
    message: `The offer rule was successfully created.`,
  }),
  type: 'success',
});

export const updateOfferRuleSuccess = () => ({
  title: t({
    id: 'notifications.update.offer.rule.success.title',
    message: `Success`,
  }),
  message: t({
    id: 'notifications.update.offer.rule.success',
    message: `The offer rule was successfully edited.`,
  }),
  type: 'success',
});

export const expireTestContractDelaySuccess = () => ({
  title: t({
    id: 'notifications.expire.test.contract.delay.success.title',
    message: `Success`,
  }),
  message: t({
    id: 'notifications.expire.test.contract.delay.success',
    message: `The delay for this test contract was successfully expired.`,
  }),
  type: 'success',
});

export const updateTradecartApprovedSuccess = () => ({
  title: t({
    id: 'notifications.update.tradecart.success.title',
    message: `Trade-in Accepted!`,
  }),
  message: t({
    id: 'notifications.update.tradecart.success',
    message: `Please go to the store to finalize your Trade-in process.`,
  }),
  type: 'success',
});

export const updateTradecartRejected = () => ({
  title: t({
    id: 'notifications.update.tradecart.rejected.title',
    message: `Trade-in Cancelled!`,
  }),
  message: t({
    id: 'notifications.update.tradecart.rejected',
    message: `Trade-in offer has been cancelled. If you wish to receive a new trade-in offer, please contact your local store`,
  }),
  type: 'error',
});

export const noResidualsFound = () => ({
  title: t({
    id: 'notifications.no.residuals.found.title',
    message: `Warning`,
  }),
  message: t({
    id: 'notifications.no.residuals.found',
    message: `No residuals were found for the selected search parameters.`,
  }),
  type: 'warning',
});

export const onlineTradeInQuoteError = () => ({
  title: t({
    id: 'notifications.online.quote.search.title',
    message: `Quote not found!`,
  }),
  message: t({
    id: 'notifications.online.quote.search.error',
    message: `Couldn't find a quote with that matches the searched ID.`,
  }),
  type: 'error',
});

export const SMSDeliveryError = () => ({
  title: t({
    id: 'notifications.sms.delivery.error.title',
    message: `Couldn't deliver SMS`,
  }),
  message: t({
    id: 'notifications.sms.delivery.error',
    message: `The SMS was not received by the customer. Some Network Carriers do not allow customers to receive SMSs with links. Please use the link “Please select delivery method” and choose the email option to resend the message.`,
  }),
  type: 'error',
});

export const evaluationDeviceNotFinished = () => ({
  title: t({
    id: 'notifications.evaluation.device.warning.title',
    message: `Problem with device evaluation`,
  }),
  message: t({
    id: 'notifications.evaluation.device.warning',
    message: `There seems to be a problem with the evaluation of one or more devices. Please go back and make sure that all the devices are evaluated with a non working zero grade. `,
  }),
  type: 'warning',
});

export const deviceValueNotHighEnough = () => ({
  title: t({
    id: 'notifications.device.value.not.high.warning.title',
    message: `Problem with device evaluation`,
  }),
  message: t({
    id: 'notifications.device.value.not.high.warning',
    message: `Your device cannot be returned, as it is not on the agreed conditions.`,
  }),
  type: 'warning',
});

export const discountsWontApply = () => ({
  title: t({
    id: 'notifications.discounts.wont.apply.warning.title',
    message: `Order Review`,
  }),
  message: t({
    id: 'notifications.discounts.wont.apply.warning',
    message: `Please review your order. If you go back from this step, the discounts you may have won't be applied.`,
  }),
  type: 'warning',
});

export const incorrectDeviceReferenceWarning = () => ({
  title: t({
    id: 'notifications.incorrect.device.reference.warning.title',
    message: `Incorrect device reference`,
  }),
  message: t({
    id: 'notifications.incorrect.device.reference.warning',
    message: `The inserted device reference doesn't correspond to the contract's device reference. Please verify the device reference again.`,
  }),
  type: 'warning',
});

export const fulfillmentReplaceSuccessful = () => ({
  title: t({
    id: 'notifications.fulfillment.replace.success.title',
    message: `Return Device and Replace`,
  }),
  message: t({
    id: 'notifications.fulfillment.replace.success.message',
    message: `Your request to return and replace the shipped items is being processed. You will receive an email containing the RMA Number to include in the return shipping label.
  When the returned items are received and processed, the replacement items will be shipped.`,
  }),
  type: 'success',
});

export const addedToTradeCartSuccessful = () => ({
  title: '',
  message: t({
    id: 'notifications.added.trade.cart.success.message',
    message: `Device(s) added to the list.`,
  }),
  type: 'success',
});

export const replaceInTradeCartSuccessful = () => ({
  title: '',
  message: t({
    id: 'notifications.updated.trade.cart.success.message',
    message: `Device(s) updated on the list.`,
  }),
  type: 'success',
});

export const removedFromTradeCartSuccessful = () => ({
  title: '',
  message: t({
    id: 'notifications.removed.from.trade.cart.success.message',
    message: `Device(s) removed from the list.`,
  }),
  type: 'warning',
});

export const fulfillmentCancelSuccessful = () => ({
  title: t({
    id: 'notifications.fulfillment.cancel.success.title',
    message: `Return Device and Cancel Financing`,
  }),
  message: t({
    id: 'notifications.fulfillment.cancel.success.message',
    message: `Your request to return the shipped items is being processed. You will receive an email containing the RMA Number to include in the return shipping label.
    When the returned items are received and processed, the financing contract will be cancelled.`,
  }),
  type: 'success',
});

export const referenceAlreadyInTradeCartWarning = () => ({
  title: '',
  message: t({
    id: 'notifications.reference.already.in.trade.cart.warning.message',
    message: `Device is already on the list.`,
  }),
  type: 'warning',
});

export const uploadOffersFileFailure = () => ({
  title: '',
  message: t({
    id: 'notifications.reference.upload.offers.file.failure.warning.message',
    message: `No offers were uploaded. Check the report for more info.`,
  }),
  type: 'warning',
});

export const companyLookupWarning = () => ({
  title: '',
  message: t({
    id: 'notifications.customer.info.company.lookup.warning.message',
    message: `Company not found, please search again or enter the data manually in the form.`,
  }),
  type: 'warning',
});

export const serialNumbersAddSuccessful = () => ({
  title: t({
    id: 'notifications.add.serial.numbers.success.title',
    message: `Added Serial Numbers with success`,
  }),
  message: t({
    id: 'notifications.add.serial.numbers.success.message',
    message: `Your devices were successfully updated.`,
  }),
  type: 'success',
});

export const quotationOnlyNotification = () => ({
  title: t({
    id: 'notifications.quotation.only.title',
    message: `Quote created`,
  }),
  message: t({
    id: 'notifications.quotation.only.success.message',
    message: `Your quote was created successfully, an email was sent to the customer with the quotation you can revisit the quote on the pending contracts list.`,
  }),
  type: 'success',
});

export const resellerServicesUpdateSuccessful = () => ({
  title: t({
    id: 'notifications.reselle.sevices.update.success.title',
    message: `Reseller services updated`,
  }),
  message: t({
    id: 'notifications.reselle.sevices.update.success.message',
    message: `Your services were successfully updated.`,
  }),
  type: 'success',
});
