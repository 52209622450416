import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';

import Heading from 'components/Heading';
import Modal from 'components/Modal';

import { adminCollectionsUrl } from 'constants/routes';
import { eplehuset } from 'constants/whitelabels';

const CourierDetails = ({ collectionUrl, whitelabel }) => {
  const navigate = useNavigate();

  return (
    <Modal
      name='courier-details'
      title={t({
        id: 'courier.details.modal.title',
        message: `Courier order details page`,
      })}
      // TODO make this component reliant only on hooks
      // in order to remove arrow functions and enforce this rule
      // eslint-disable-next-line react/jsx-no-bind
      onClose={() => navigate(adminCollectionsUrl)}
    >
      {whitelabel !== eplehuset && (
        <Fragment>
          <p>
            <Trans id='courier.details.link.instructions'>
              Please click on link below to proceed on courier page with order
              details
            </Trans>
          </p>

          <a
            className='collection-url'
            target='_blank'
            href={collectionUrl}
            rel='noreferrer'
          >
            {collectionUrl}
          </a>
        </Fragment>
      )}

      {whitelabel === eplehuset && (
        <Fragment>
          <Heading size='small'>
            <Trans id='courier.details.packing.title'>
              Packing Instructions
            </Trans>
          </Heading>
          <ul>
            <li>
              <Trans id='courier.details.packing.p1'>
                Send an e-mail with the name of the store, as well as the size
                and weight of the box (kg) to no.freight.innlandbooking@dhl.com
              </Trans>
            </li>
            <li>
              <Trans id='courier.details.packing.p2'>
                DHL will book a pickup and respond with a PDF shipping label
              </Trans>
            </li>
            <li>
              <Trans id='courier.details.packing.p3'>
                Print and attach the shipping label to the box of devices
              </Trans>
            </li>
            <li>
              <Trans id='courier.details.packing.p4'>
                The devices will be picked up by DHL the next business day
              </Trans>
            </li>
            <li>
              <Trans id='courier.details.packing.p5'>
                If the devices are not picked up as planned within two business
                days, send an email to no.freight.innlandbooking@dhl.com as a
                reply to the previous booking request and ask for the status
              </Trans>
            </li>
          </ul>
        </Fragment>
      )}
    </Modal>
  );
};

export default CourierDetails;
