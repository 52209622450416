import { currencyFormatter, numberFormatter } from 'helpers/formatters';

import React from 'react';

import { Trans } from '@lingui/macro';

const RepresentativeExample = ({
  language,
  order,
  hasMultipleOrders = false,
}) => {
  let customDisclaimers = {
    BNP_UK: <BNPRepresentativeExample language={language} order={order} />,
    ONEYFR: <OneyRepresentativeExample language={language} order={order} />,
  };

  return (
    customDisclaimers[order?.financing?.funder] || (
      <DefaultRepresentativeExample
        language={language}
        order={order}
        hasMultipleOrders={hasMultipleOrders}
      />
    )
  );
};

const BNPRepresentativeExample = ({ language, order }) => {
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
        <span data-testid='representative-example-cashprice'>
          <Trans id='payment.disclaimer.representative.bnp.cashprice'>
            Cash Price:
          </Trans>{' '}
          {currencyFormatter(
            order?.financing?.deviceContractFinance?.totalSaleAmount,
            order?.financing?.currencyCode,
            language,
          )}
          {'. '}
        </span>

        <span data-testid='representative-example-amountcredit'>
          <Trans id='payment.disclaimer.representative.bnp.amountcredit'>
            Amount of Credit:
          </Trans>{' '}
          {currencyFormatter(
            order?.financing?.deviceContractFinance?.principal,
            order?.financing?.currencyCode,
            language,
          )}
          {'. '}
        </span>

        {order?.financing?.openingFeeAmount > 0 && (
          <span data-testid='representative-example-deposit'>
            <Trans id='payment.disclaimer.representative.bnp.deposit'>
              Deposit:
            </Trans>{' '}
            {currencyFormatter(
              order?.financing?.openingFeeAmount,
              order?.financing?.currencyCode,
              language,
            )}
            {'. '}
          </span>
        )}
      </div>

      <span data-testid='representative-example'>
        <Trans id='payment.disclaimer.representative.bnp'>
          Settle the loan by paying {order?.financing?.fixed_period_end_month}{' '}
          monthly instalments of{' '}
          {currencyFormatter(
            order?.financing?.fixed_period_monthly_price,
            order?.financing?.currencyCode,
            language,
          )}
          . Total amount payable is{' '}
          {currencyFormatter(
            order?.financing?.deviceContractFinance?.totalPayments,
            order?.financing?.currencyCode,
            language,
          )}
          . Rate of interest{' '}
          {numberFormatter(
            language,
            order?.financing?.deviceContractFinance?.interestRate,
          )}
          %.{' '}
          {numberFormatter(
            language,
            order?.financing?.deviceContractFinance?.annualisedInterestRate,
          )}
          % APR representative.
        </Trans>
      </span>
    </>
  );
};

const OneyRepresentativeExample = ({ language, order }) => {
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <span
          data-testid='representative-example-binding'
          style={{ fontWeight: 600 }}
        >
          <Trans id='payment.disclaimer.representative.oney.binding'>
            A CREDIT IS BINDING TO YOU AND MUST BE REFUNDED. CHECK YOUR
            REIMBURSEMENT CAPABILITY BEFORE COMMITTING.
          </Trans>
        </span>

        <span data-testid='representative-example-amountcredit'>
          <Trans id='payment.disclaimer.representative.oney.cashprice'>
            For your purchase of{' '}
            {currencyFormatter(
              order?.financing?.deviceContractFinance?.totalSaleAmount,
              order?.financing?.currencyCode,
              language,
            )}
            , you reimburse:
          </Trans>{' '}
          {order?.financing.flex_period_end_month} X{' '}
          {currencyFormatter(
            order?.financing?.fixed_period_monthly_price,
            order?.financing?.currencyCode,
            language,
          )}
          {'. '}
          <Trans id='payment.disclaimer.representative.oney.amount.credit'>
            Funding amount:
          </Trans>{' '}
          {currencyFormatter(
            order?.financing?.deviceContractFinance?.principal,
            order?.financing?.currencyCode,
            language,
          )}
          {'. '}
          <Trans id='payment.disclaimer.representative.oney.amount.due'>
            Total amount due:
          </Trans>{' '}
          {currencyFormatter(
            order?.financing?.deviceContractFinance?.totalPayments,
            order?.financing?.currencyCode,
            language,
          )}
          {'. '}
          <Trans id='payment.disclaimer.representative.oney.credit.cost'>
            Cost of financing:
          </Trans>{' '}
          {currencyFormatter(
            order?.financing?.deviceContractFinance?.costOfCredit,
            order?.financing?.currencyCode,
            language,
          )}
          {'. '}
          <Trans id='payment.disclaimer.representative.oney.interests'>
            Credit allocated over {order?.financing.flex_period_end_month}{' '}
            months at the FIXED APR of{' '}
            {
              order?.financing.annualisedInterestRateToDisplay
                .upgradeFinancePercent
            }
            % and at the fixed lending rate of{' '}
            {order?.financing.deviceContractFinance.interestRate}%.
          </Trans>
        </span>
      </div>
    </>
  );
};

const DefaultRepresentativeExample = ({
  language,
  order,
  hasMultipleOrders = false,
}) => {
  const upfrontText = (
    <>
      <Trans id='online.options.planInfo.upfront.text'>
        Based on your order of{' '}
        {currencyFormatter(
          order?.financing?.deviceContractFinance?.principal &&
            order?.financing?.openingFeeAmount
            ? order?.financing?.deviceContractFinance?.principal +
                order?.financing?.openingFeeAmount
            : undefined,
          order?.financing?.currencyCode,
          language,
        )}
        an initial deposit of{' '}
        {currencyFormatter(
          order?.financing?.openingFeeAmount,
          order?.financing?.currencyCode,
          language,
        )}{' '}
        will be required.
      </Trans>{' '}
    </>
  );

  const productText = (
    <>
      {hasMultipleOrders ? `${order?.products?.items?.[0]?.short_name} - ` : ''}
      {order?.financing?.openingFeePercentage > 0 && upfrontText}
      <Trans id='online.planInfo.payment.text'>
        Total credit amount{' '}
        {currencyFormatter(
          order?.financing?.deviceContractFinance?.principal,
          order?.financing?.currencyCode,
          language,
        )}{' '}
        with {order?.financing?.fixed_period_end_month} monthly payments of{' '}
        {currencyFormatter(
          order?.financing?.fixed_period_monthly_price,
          order?.financing?.currencyCode,
          language,
        )}{' '}
        at{' '}
        {numberFormatter(
          language,
          order?.financing?.deviceContractFinance?.financeType === 'UPGRADE'
            ? order?.financing?.annualisedInterestRateToDisplay
                ?.upgradeFinancePercent
            : order?.financing?.annualisedInterestRateToDisplay
                ?.standardFinancePercent,
        )}
        {'% '}
        {order?.financing?.annualisedInterestRateToDisplay?.displayType}.
      </Trans>
      {order?.financing?.deviceContractFinance?.financeType === 'UPGRADE' && (
        <>
          {' '}
          <Trans id='online.planInfo.upgrade.text'>
            Return the {order?.products?.items?.[0]?.short_name} after{' '}
            {order?.financing?.fixed_period_end_month} payments to settle the
            outstanding loan (total amount payable{' '}
            {currencyFormatter(
              order?.financing?.deviceContractFinance?.upgradeTotalPayments,
              order?.financing?.currencyCode,
              language,
            )}
            ) or keep the device and make {order?.financing?.flexPeriodPayments}{' '}
            further monthly payments (total amount{' '}
            {currencyFormatter(
              order?.financing?.deviceContractFinance?.totalPayments,
              order?.financing?.currencyCode,
              language,
            )}
            ).
          </Trans>
        </>
      )}
    </>
  );

  const otherProductsText = (
    <Trans id='online.planInfo.payment.other.products.text'>
      Other Products - Total credit amount{' '}
      {currencyFormatter(
        order?.financing?.deviceContractFinance?.principal,
        order?.financing?.currencyCode,
        language,
      )}{' '}
      with {order?.financing?.fixed_period_end_month} monthly payments of{' '}
      {currencyFormatter(
        order?.financing?.fixed_period_monthly_price,
        order?.financing?.currencyCode,
        language,
      )}{' '}
      at{' '}
      {numberFormatter(
        language,
        order?.financing?.annualisedInterestRateToDisplay
          ?.standardFinancePercent,
      )}
      {'% '}
      {order?.financing?.annualisedInterestRateToDisplay?.displayType} (total
      amount payable{' '}
      {currencyFormatter(
        order?.financing?.deviceContractFinance?.totalPayments,
        order?.financing?.currencyCode,
        language,
      )}
      ).
    </Trans>
  );

  return (
    <span data-testid='representative-example'>
      {order?.products?.items?.length > 0 && productText}
      {order?.extras?.items?.length > 0 &&
        order?.products?.items?.length === 0 &&
        otherProductsText}
    </span>
  );
};

const RepresentativeLegal = ({
  fcaNumber = '#',
  funder = 'Bank',
  order,
  wlAddress = 'Retailer Address',
  wlName = 'Retailer',
  wlRegisteredName = 'Retailer',
}) => {
  let customLegals = {
    BNP_UK: (
      <BNPRepresentativeLegal
        wlRegisteredName={wlRegisteredName}
        fcaNumber={fcaNumber}
      />
    ),
    ONEYFR: (
      <OneyRepresentativeLegal
        wlRegisteredName={wlRegisteredName}
        fcaNumber={fcaNumber}
        order={order}
        wlAddress={wlAddress}
        wlName={wlName}
      />
    ),
  };

  return (
    customLegals[funder] || (
      <DefaultRepresentativeLegal wlName={wlName} funder={funder} />
    )
  );
};

const DefaultRepresentativeLegal = ({
  wlName = 'Retailer',
  funder = 'Bank',
}) => {
  return (
    <span data-testid='representative-legal' style={{ fontStyle: 'italic' }}>
      <Trans id='options.disclaimers.body'>
        {wlName} acts as credit broker / intermediary and not as a lender.
        Credit products are provided exclusively by
        {funder}, which is authorised and regulated by the Financial Services
        Authority, with limited supervision by the Financial Conduct Authority.
      </Trans>
    </span>
  );
};

const BNPRepresentativeLegal = ({
  wlRegisteredName = 'Retailer',
  fcaNumber = '#',
}) => {
  return (
    <span data-testid='representative-legal' style={{ fontStyle: 'italic' }}>
      <Trans id='options.disclaimers.body.bnp'>
        {wlRegisteredName} is authorised by the Financial Conduct Authority as a
        credit broker and not as a lender. Financial Conduct Association
        registration number {fcaNumber}. Credit is exclusively provided by
        Creation Consumer Finance Limited. Registered Office: 4th - 6th floor
        Wellington Buildings, 2-4 Wellington Street, Belfast, BT1 6HT (Northern
        Ireland - company number NI032565). Authorised and regulated by the
        Financial Conduct Authority. Registration Number 311518. To access their
        privacy policy go to{' '}
        <a
          target='_blank'
          href='https://www.creation.co.uk/about-us/data-protection-notice'
          rel='noreferrer'
        >
          https://www.creation.co.uk/about-us/data-protection-notice
        </a>
      </Trans>
    </span>
  );
};

const OneyRepresentativeLegal = ({
  fcaNumber = '#',
  order,
  wlAddress,
  wlName,
  wlRegisteredName = 'Retailer',
}) => {
  return (
    <>
      <span data-testid='representative-legal' style={{ fontStyle: 'italic' }}>
        <Trans id='options.disclaimers.body.oney'>
          Credit assigned without insurance valid from €200 to €4,600 valid at{' '}
          {wlName}, over {order?.financing.flex_period_end_month} months at the
          fixed APR of{' '}
          {
            order?.financing.annualisedInterestRateToDisplay
              .upgradeFinancePercent
          }
          % and at the fixed lending rate of{' '}
          {order?.financing.deviceContractFinance.interestRate}%, depending on
          the amount of the purchase. You have a withdrawal period of 14 days to
          waive your credit. Conditions as of 09/11/2024. Oney Bank SA with
          capital of €71,801,205 - Head office: 34 avenue de Flandre 59170 CROIX
          - RCS Lille Métropole 546 380 197 - NºORIAS 07 023 261 - www.orias.fr
          - Correspondence: CS 60006 - 59895 Lille Cedex 9 - www.oney.fr.
        </Trans>{' '}
      </span>

      <span style={{ fontStyle: 'italic', fontWeight: 600 }}>
        <Trans id='options.disclaimers.body.oney.suffix'>
          {wlRegisteredName} acts as exclusive agent for banking operations and
          payment services - ORIAS number: {fcaNumber} - Head office:{' '}
          {wlAddress}
        </Trans>
      </span>
    </>
  );
};

export default function PaymentDisclaimers({
  language,
  order,
  wlAddress,
  wlName,
  wlRegisteredName,
  fcaNumber,
  hasMultipleOrders = false,
}) {
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
      data-testid='representative-block'
    >
      <span style={{ fontWeight: 600 }}>
        <Trans id='payment.disclaimer.representative.title'>
          Interest Bearing Representative Example:
        </Trans>
      </span>

      <RepresentativeExample
        language={language}
        order={order}
        wlName={wlName}
        hasMultipleOrders={hasMultipleOrders}
      />

      <RepresentativeLegal
        fcaNumber={fcaNumber}
        funder={order?.financing?.funder}
        order={order}
        wlAddress={wlAddress}
        wlName={wlName}
        wlRegisteredName={wlRegisteredName}
      />
    </div>
  );
}
