import React from 'react';

import DatabaseTable from './DatabaseTable';

const ServiceTable = ({ items, className }) => {
  const renderErrorCounterRow = (item, index, stripe) => {
    return (
      <tr key={'ec' + index} className={stripe}>
        <td>{item.method}</td>
        <td>
          <span className='ServiceTable_smallText'>{item.error}</span>
        </td>
        <td>{item.count}</td>
      </tr>
    );
  };

  const renderErrorListRow = (item, index, stripe) => {
    return (
      <tr key={'el' + index} className={stripe}>
        <td>{item.datetime}</td>
        <td>{item.sender}</td>
        <td>{item.function}</td>
        <td>
          <span className='ServiceTable_smallText'>{item.code}</span>
        </td>
      </tr>
    );
  };

  const renderErrorListRow2 = (item, index, stripe) => {
    return (
      <tr key={'el2' + index} className={stripe}>
        <td colSpan='4' align='left' className='ServiceTable_stackTrace'>
          {item.stacktrace}
        </td>
      </tr>
    );
  };

  const renderMethodRow = (item, index, stripe) => {
    return (
      <tr key={'m' + index} className={stripe}>
        <td>{item.methodName}</td>
        <td>
          <span className='ServiceTable_smallText'>{item.responseType}</span>
        </td>
        <td>
          <span className='ServiceTable_smallText'>
            {item.params.join(', ')}
          </span>
        </td>
        <td>{item.isAPIRequest === false ? 'N' : 'Y'}</td>
        <td>{item.counter[0]}</td>
        <td>{item.counter[1]}</td>
        <td>{item.counter[2]}</td>
        <td>{item.counter[3]}</td>
        <td>{item.counter[4]}</td>
        <td>{item.counter[5]}</td>
        <td>{item.counter[6]}</td>
        <td>{item.counter[7]}</td>
      </tr>
    );
  };

  const renderScheduledMethodRow = (item, index, stripe) => {
    var background = item.enabled === 'ON' ? stripe : 'background_gray';
    return (
      <tr key={'m' + index} className={background}>
        <td>
          {item.scheduledMethodName} ({item.enabled})
        </td>
        <td>
          <span className='ServiceTable_smallText'>{item.descriptor}</span>
        </td>
        <td>{item.totalRuns}</td>
        <td>{item.totalErrors}</td>
        {item.apiRunList.map((item, index) => {
          var status = item.response.status === 'SUCCESS' ? '\u2713' : '\u2715';
          var cssClass =
            item.response.status === 'SUCCESS'
              ? 'background_green'
              : 'background_red';
          return (
            <td key={'sm' + index} class={cssClass}>
              {item.time} {status}
              <br />
              {item.response.description}
            </td>
          );
        })}
      </tr>
    );
  };

  const renderServiceTable = (items) => {
    return (
      <table className='MethodTable'>
        <colgroup>
          <col width='10%;' />
          <col width='3%;' />
          <col width='3%;' />
          <col width='3%;' />
          <col width='3%;' />
          <col width='3%;' />
          <col width='10%;' />
          <col width='3%;' />
          <col width='3%;' />
          <col width='3%;' />
          <col width='3%;' />
          <col width='3%;' />
          <col width='10%;' />
          <col width='3%;' />
          <col width='3%;' />
          <col width='3%;' />
          <col width='3%;' />
          <col width='3%;' />
          <col width='10%;' />
          <col width='3%;' />
          <col width='3%;' />
          <col width='3%;' />
          <col width='3%;' />
          <col width='3%;' />
        </colgroup>
        <thead>
          <tr>
            <th>Service</th>
            <th>Total Messages</th>
            <th>Total Errors</th>
            <th>Slow Responses</th>
            <th>Slow DB Queries</th>
            <th>Total Memory</th>
            <th>Service</th>
            <th>Total Messages</th>
            <th>Total Errors</th>
            <th>Slow Responses</th>
            <th>Slow DB Queries</th>
            <th>Total Memory</th>
            <th>Service</th>
            <th>Total Messages</th>
            <th>Total Errors</th>
            <th>Slow Responses</th>
            <th>Slow DB Queries</th>
            <th>Total Memory</th>
            <th>Service</th>
            <th>Total Messages</th>
            <th>Total Errors</th>
            <th>Slow Responses</th>
            <th>Slow DB Queries</th>
            <th>Total Memory</th>
          </tr>
        </thead>
        <tbody>{renderRow(items)}</tbody>
      </table>
    );
  };

  const renderRow = (items) => {
    const rows = [];
    for (let i = 0; i < items.length; i += 4) {
      var stripe = (i / 4) % 2 === 1 ? 'stripe_even' : 'stripe_odd';
      rows.push(
        <tr key={i} className={stripe}>
          {renderServiceRow(items.slice(i, i + 4))}
        </tr>,
      );
    }
    return rows;
  };

  const renderServiceRow = (row, stripe) => {
    const cols = [];
    row.forEach((item) => {
      var background = item.totalMessages > 0 ? stripe : 'background_gray';
      var singleton = item.serviceType === 'SINGLETON' ? '\u2680' : '';
      cols.push(
        <td className={background}>
          <a href={'#' + item.serviceName}>{item.serviceName}</a> {singleton}
        </td>,
      );
      cols.push(<td className={background}>{item.totalMessages}</td>);
      if (!item.totalMessages === 0) {
        if (item.totalErrors === 0) background = 'background_green';
        else if (item.totalErrors < 10) background = 'background_yellow';
        else background = 'background_red';
      }
      cols.push(<td className={background}>{item.totalErrors}</td>);
      if (!item.totalMessages === 0) {
        if (item.slowResponses === 0) background = 'background_green';
        else if (item.slowResponses > 0) background = 'background_yellow';
        else background = 'background_red';
      }
      cols.push(<td className={background}>{item.slowResponses}</td>);
      if (!item.totalMessages === 0) {
        if (item.slowDBQueries === 0) background = 'background_green';
        else if (item.slowDBQueries > 0) background = 'background_yellow';
        else background = 'background_red';
      }
      cols.push(<td className={background}>{item.slowDBQueries}</td>);
      if (item.totalMemory <= 1048576) {
        if (item.totalMessages === 0) background = 'background_gray';
        else background = 'background_green';
      } else if (item.slowDBQueries < 10485760)
        background = 'background_yellow';
      else background = 'background_red';
      cols.push(<td className={background}>{item.totalMemoryStr}</td>);
    });
    return cols;
  };

  const renderCacheRow = (item) => {
    var errorCount = item.totalErrors === 0 ? 1 : item.totalErrors;

    return (
      <tr>
        <td rowspan={errorCount}>{item.updated}</td>
        <td rowspan={errorCount}>{item.header.apiStatus}</td>
        <td rowspan={errorCount}>{item.description}</td>
        <td>
          {item.totalErrors > 0 && (
            <table className='MethodTable'>
              <colgroup>
                <col width='10%;' />
                <col width='20%;' />
                <col width='20%;' />
                <col width='50%;' />
              </colgroup>
              <tbody>
                <tr className='ServiceTable_innerHeader'>
                  <th>DateTime</th>
                  <th>Sender</th>
                  <th>Method</th>
                  <th>Error</th>
                </tr>
                {item.header.errors?.map((item, index) => {
                  var stripe = index % 2 === 1 ? 'stripe_even' : 'stripe_odd';
                  var rows = [];
                  rows.push(renderErrorListRow(item, index, stripe));
                  rows.push(renderErrorListRow2(item, index, stripe));
                  return rows;
                })}
              </tbody>
            </table>
          )}
        </td>
      </tr>
    );
  };

  function renderService(item, index) {
    return (
      <table
        className={`ServiceTable ${className}`}
        key={'s' + index}
        id={item.serviceName}
      >
        <caption className='ServiceTable_caption'>
          {item.serviceName}{' '}
          <span className='ServiceTable_captionsmallText'>
            ({item.serviceClassName}.class / {item.serviceInterfaceName}.class)
          </span>{' '}
        </caption>
        <colgroup>
          <col width='50%;' />
          <col width='50%;' />
        </colgroup>
        <tbody>
          <tr>
            <td colSpan='2'>
              <table className='MethodTable'>
                <colgroup>
                  <col width='12%;' />
                  <col width='12%;' />
                  <col width='22%;' />
                  <col width='6%;' />
                  <col width='6%;' />
                  <col width='6%;' />
                  <col width='6%;' />
                  <col width='6%;' />
                  <col width='6%;' />
                  <col width='6%;' />
                  <col width='6%;' />
                  <col width='6%;' />
                </colgroup>
                <caption className='ServiceTable_innercaption'>Methods</caption>
                <tbody>
                  <tr className='ServiceTable_innerHeader'>
                    <th>Method</th>
                    <th>Response Type</th>
                    <th>Params</th>
                    <th>API</th>
                    <th>Total</th>
                    <th>Errors</th>
                    {item.responseTimeRanges.map((item, index) => {
                      return (
                        <th key={'r' + index}>
                          {item.minMs}-{item.maxMs === -1 ? '   ' : item.maxMs}
                        </th>
                      );
                    })}
                  </tr>

                  {item.methods.map((item, index) => {
                    var stripe = index % 2 === 1 ? 'stripe_even' : 'stripe_odd';
                    var rows = [];
                    rows.push(renderMethodRow(item, index, stripe));
                    return rows;
                  })}
                </tbody>
              </table>
            </td>
          </tr>

          {item.cacheUpdateResponse && (
            <tr>
              <td colSpan='2'>
                <table className='MethodTable'>
                  <colgroup>
                    <col width='15%;' />
                    <col width='15%;' />
                    <col width='20%;' />
                    <col width='50%;' />
                  </colgroup>
                  <caption className='ServiceTable_innercaption'>
                    Cache Update
                  </caption>
                  <tbody>
                    <tr className='ServiceTable_innerHeader'>
                      <th>Time</th>
                      <th>Result</th>
                      <th>description</th>
                      <th>Errors</th>
                    </tr>
                    {renderCacheRow(item.cacheUpdateResponse, index)}
                  </tbody>
                </table>
              </td>
            </tr>
          )}

          {item.scheduledMethods?.length > 0 && (
            <tr>
              <td colSpan='2'>
                <table className='MethodTable'>
                  <colgroup>
                    <col width='14%;' />
                    <col width='14%;' />
                    <col width='6%;' />
                    <col width='6%;' />
                    <col width='6%;' />
                    <col width='6%;' />
                    <col width='6%;' />
                    <col width='6%;' />
                    <col width='6%;' />
                    <col width='6%;' />
                    <col width='6%;' />
                    <col width='6%;' />
                    <col width='6%;' />
                    <col width='6%;' />
                  </colgroup>
                  <caption className='ServiceTable_innercaption'>
                    Scheduled Methods
                  </caption>
                  <tbody>
                    <tr className='ServiceTable_innerHeader'>
                      <th>Method</th>
                      <th>Runs</th>
                      <th>Total</th>
                      <th>Errors</th>
                      <th>1</th>
                      <th>2</th>
                      <th>3</th>
                      <th>4</th>
                      <th>5</th>
                      <th>6</th>
                      <th>7</th>
                      <th>8</th>
                      <th>9</th>
                      <th>10</th>
                    </tr>

                    {item.scheduledMethods.map((item, index) => {
                      var stripe =
                        index % 2 === 1 ? 'stripe_even' : 'stripe_odd';
                      var rows = [];
                      rows.push(renderScheduledMethodRow(item, index, stripe));
                      return rows;
                    })}
                  </tbody>
                </table>
              </td>
            </tr>
          )}

          {item.errors.totalErrors > 0 && (
            <tr>
              <td className='ServiceTable_none'>
                <table className='MethodTable'>
                  <colgroup>
                    <col width='30%;' />
                    <col width='50%;' />
                    <col width='20%;' />
                  </colgroup>
                  <caption className='ServiceTable_innercaption'>
                    Error Counters
                  </caption>
                  <tbody>
                    <tr className='ServiceTable_innerHeader'>
                      <th>Method</th>
                      <th>Error</th>
                      <th>Count</th>
                    </tr>
                    {item.errors.errorCounters.map((item, index) => {
                      var stripe =
                        index % 2 === 1 ? 'stripe_even' : 'stripe_odd';
                      var rows = [];
                      rows.push(renderErrorCounterRow(item, index, stripe));
                      return rows;
                    })}
                  </tbody>
                </table>
              </td>
              <td className='ServiceTable_none'>
                <table className='MethodTable'>
                  <colgroup>
                    <col width='10%;' />
                    <col width='20%;' />
                    <col width='20%;' />
                    <col width='50%;' />
                  </colgroup>
                  <caption className='ServiceTable_innercaption'>
                    Error List
                  </caption>
                  <tbody>
                    <tr className='ServiceTable_innerHeader'>
                      <th>DateTime</th>
                      <th>Sender</th>
                      <th>Method</th>
                      <th>Error</th>
                    </tr>
                    {item.errors.errorList.map((item, index) => {
                      var stripe =
                        index % 2 === 1 ? 'stripe_even' : 'stripe_odd';
                      var rows = [];
                      rows.push(renderErrorListRow(item, index, stripe));
                      if (item?.stacktrace?.length > 0) {
                        //alert('error length' + item.error.length);
                        rows.push(renderErrorListRow2(item, index, stripe));
                      }
                      return rows;
                    })}
                  </tbody>
                </table>
              </td>
            </tr>
          )}

          {item.databaseMonitor.classes.length > 0 && (
            <tr>
              <td colSpan='2'>
                <DatabaseTable
                  item={item.databaseMonitor}
                  title='Database'
                  emptyText='No Database'
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  return (
    <div>
      <h1>Services</h1>
      {renderServiceTable(items)}
      <br />
      {items.map((item, index) => {
        return renderService(item, index);
      })}
    </div>
  );
};

export default ServiceTable;
