import { changePasswordAction } from 'actions/user';
import { format } from 'helpers/validators';
import { required } from 'helpers/validators';
import { Field, reduxForm } from 'redux-form';

import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';

import Button from 'components/Button';
import PasswordInput from 'components/PasswordInput';

import { servicesUrl } from 'constants/routes';

import useTrackPage from 'hooks/useTrackPage';

import './PasswordChange.styl';

const PasswordChange = ({ invalid, submitting, handleSubmit }) => {
  useTrackPage('PasswordChange');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPasswordInfo, setShowPasswordInfo] = useState(false);

  const submitChangePassword = handleSubmit(async (values) => {
    const response = await dispatch(changePasswordAction(values));
    response && navigate(servicesUrl);
  });

  const hidePasswordInfo = () => setShowPasswordInfo(false);

  const displayPasswordInfo = () => setShowPasswordInfo(true);

  return (
    <>
      <h2 className='password-change-title'>
        <Trans id='pwd.change.title'>Change Password</Trans>
      </h2>

      <form id={'password-change-form'} className='password-change-form'>
        <Field
          name='oldPassword'
          type='password'
          component={PasswordInput}
          validate={required()}
          label={t({
            id: 'pwd.change.old.pwd.placeholder',
            message: `Old Password`,
          })}
          onFocus={hidePasswordInfo}
          testSelector='old-password'
        />

        <Field
          name='newPassword'
          type='password'
          component={PasswordInput}
          validate={[
            required(),
            format({
              with: /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{6,32}$/,
              msg: t({
                id: 'error.message.wrong.pwd.format',
                message: `Wrong password format`,
              }),
            }),
          ]}
          label={t({
            id: 'pwd.change.new.pwd.placeholder',
            message: `New Password`,
          })}
          onFocus={displayPasswordInfo}
          testSelector='new-password'
        />

        {showPasswordInfo && (
          <Fragment>
            <div className='password-rules-title'>
              <Trans id='pwd.rules.title'>New password must contain:</Trans>
            </div>

            <ul>
              <li>
                <Trans id='pwd.rules.uppercase'>1 uppercase letters</Trans>
              </li>
              <li>
                <Trans id='pwd.rules.lowercase'>1 letters in lower case</Trans>
              </li>
              <li>
                <Trans id='pwd.rules.numerals'>1 numeral</Trans>
              </li>
              <li>
                <Trans id='pwd.rules.special'>
                  1 special character (!@#$&*)
                </Trans>
              </li>
              <li>
                <Trans id='pwd.rules.min.chars'>6 characters minimum</Trans>
              </li>
            </ul>
          </Fragment>
        )}

        <Field
          name='repeatNewPassword'
          type='password'
          component={PasswordInput}
          validate={[
            required(),
            format({
              with: /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{6,32}$/,
              msg: t({
                id: 'error.message.wrong.pwd.format',
                message: `Wrong password format`,
              }),
            }),
          ]}
          label={t({
            id: 'pwd.change.password.confirmation.placeholder',
            message: `Confirm new password`,
          })}
          onFocus={hidePasswordInfo}
          testSelector='repeat-new-password'
        />

        <Button
          type='submit'
          disabled={invalid || submitting}
          onClick={submitChangePassword}
          testSelector='change-password-confirm-button'
        >
          <Trans id='pwd.change.submit.button'>Submit</Trans>
        </Button>
      </form>
    </>
  );
};

export default reduxForm({
  form: 'password-change-form',
  enableReinitialize: true,
})(PasswordChange);
