import { addNotificationAction } from 'actions/ui';
import { adminPost as post } from 'apis/v2';

import {
  publishTemplateSuccess,
  readTemplateFail,
  rollbackTemplateSuccess,
  saveBlockSuccess,
  saveTemplateSuccess,
} from 'constants/notifications';

export const READ_TEMPLATE = 'READ_TEMPLATE';
export function readTemplateAction(campaignCode, templateName) {
  return async function (dispatch) {
    const response = await post(READ_TEMPLATE, {
      campaignCode,
      templateName,
    });

    const { draftTemplate, liveTemplate, ...templateData } = response.data.data;

    if (response.data.status !== 'ERROR') {
      dispatch({
        type: READ_TEMPLATE,
        data: { draftTemplate, liveTemplate, templateData },
      });

      return draftTemplate;
    } else {
      dispatch(addNotificationAction(readTemplateFail()));
    }
  };
}

export const SAVE_TEMPLATE = 'SAVE_TEMPLATE';
export function saveTemplateAction(template) {
  return async function (dispatch) {
    const response = await post(SAVE_TEMPLATE, { template });

    if (response.data.status === 'SUCCESS') {
      const { draftTemplate, liveTemplate, ...templateData } =
        response.data.data;

      dispatch({
        type: SAVE_TEMPLATE,
        data: { draftTemplate, liveTemplate, templateData },
      });

      dispatch(addNotificationAction(saveTemplateSuccess()));
    }
  };
}

export const SEND_EMAIL = 'SEND_EMAIL';
export function sendEmailAction(
  templateKey,
  { emailAddress, contractReference },
) {
  return async function (dispatch) {
    const response = await post(SEND_EMAIL, {
      from: 'noreply@financetech365.com',
      to: emailAddress,
      contractReference: contractReference,
      templateKey,
    });

    if (response.status === 'SUCCESS') {
      dispatch({ type: SEND_EMAIL });
    }
  };
}

export const SEND_SMS = 'SEND_SMS';
export function sendSMSAction(templateKey, { phone, contractReference }) {
  return async function (dispatch) {
    const response = await post(SEND_SMS, {
      phone,
      contractReference,
      templateKey,
    });

    if (response.status === 'SUCCESS') {
      dispatch({ type: SEND_SMS });
    }
  };
}

export const LIST_HTML_BLOCKS = 'LIST_HTML_BLOCKS';
export function listBlocksAction() {
  return async function (dispatch) {
    const response = await post(LIST_HTML_BLOCKS);

    dispatch({
      type: LIST_HTML_BLOCKS,
      data: response.data.data.htmlBlocks,
    });
  };
}

export const READ_HTML_BLOCK = 'READ_HTML_BLOCK';
export function readBlockAction(uuid) {
  return async function (dispatch) {
    const response = await post(READ_HTML_BLOCK, {
      uuid,
    });
    const block = response.data.data.htmlBlock;

    dispatch({
      type: READ_HTML_BLOCK,
      data: block,
    });

    return block;
  };
}

export const CLEAR_HTML_BLOCK = 'CLEAR_HTML_BLOCK';
export function clearBlockAction() {
  return function (dispatch) {
    dispatch({ type: CLEAR_HTML_BLOCK });
  };
}

export const DELETE_HTML_BLOCK = 'DELETE_HTML_BLOCK';
export function deleteBlockAction(uuid) {
  return async function (dispatch) {
    const response = await post(DELETE_HTML_BLOCK, {
      uuid,
    });

    if (response.data.status === 'SUCCESS') {
      dispatch({
        type: DELETE_HTML_BLOCK,
        data: uuid,
      });
    }
  };
}

export const SAVE_HTML_BLOCK = 'SAVE_HTML_BLOCK';
export function upsertBlockAction(payload) {
  return async function (dispatch) {
    const response = await post(SAVE_HTML_BLOCK, {
      htmlBlock: {
        ...payload,
      },
    });

    if (response.data.status === 'SUCCESS') {
      dispatch(addNotificationAction(saveBlockSuccess()));
      return response.data.data;
    }
  };
}

export const RENDER_EMAIL = 'RENDER_EMAIL';
export function renderEmailAction(payload) {
  return async function (dispatch) {
    const response = await post(RENDER_EMAIL, { ...payload });

    dispatch({ type: RENDER_EMAIL, data: response.data.data });
  };
}

export const PUBLISH_TEMPLATE = 'PUBLISH_TEMPLATE';
export function publishTemplateAction(campaignCode, templateName) {
  return async function (dispatch) {
    const response = await post(PUBLISH_TEMPLATE, {
      campaignCode,
      templateName,
    });

    if (response.data.status === 'SUCCESS') {
      dispatch(addNotificationAction(publishTemplateSuccess()));

      const { draftTemplate, liveTemplate, ...templateData } =
        response.data.data;

      dispatch({
        type: PUBLISH_TEMPLATE,
        data: { draftTemplate, liveTemplate, templateData },
      });

      return liveTemplate;
    }
  };
}
