import classNames from 'classnames';
import { currencyFormatter } from 'helpers/formatters';

import React from 'react';

import { Trans } from '@lingui/macro';

import { ImageThumbnail } from 'components/Image';

import './Item.styl';

const ExtraItem = ({
  item,
  type,
  currency,
  language,
  selected,
  highlight,
  handleClick,
}) => {
  const {
    img_url,
    description,
    price,
    monthly_price,
    reference_number,
    strikethroughPrice,
  } = item;

  return (
    <li
      className={`item ${type} ${selected ? 'selected' : ''}`}
      // TODO make this component reliant only on hooks in order
      // to remove arrow functions and enforce this rule
      // eslint-disable-next-line react/jsx-no-bind
      onClick={() => handleClick(reference_number)}
    >
      <div className='item-image-wrapper'>
        <ImageThumbnail src={img_url} alt={description} />
      </div>

      <section className='details'>
        <p className='item-name'>{description}</p>

        <p className='item-id'>{reference_number}</p>

        <p className={classNames('item-price-difference', { highlight })}>
          {`+${currencyFormatter(monthly_price, currency, language)}`}
          <span>
            <Trans id='extras.month'> / month</Trans>
          </span>
        </p>

        {price > 0 && (
          <p className='item-price'>
            ({currencyFormatter(price, currency, language)})
            {strikethroughPrice > 0 && (
              <span className='item-price--strikethrough'>
                ({currencyFormatter(strikethroughPrice, currency, language)})
              </span>
            )}
          </p>
        )}
      </section>
    </li>
  );
};

ExtraItem.defaultProps = {
  highlight: false,
};

export default ExtraItem;
