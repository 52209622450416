import React from 'react';

import WLIcon from './WLIcon';

const StoreCountersTable = ({ items, wls, className }) => {
  const renderSalesBlock = (colour, count, index, multiplier) => {
    var divStyle = {
      width: `${count * multiplier}px`,
      backgroundColor: colour,
    };

    return (
      <div
        key={`d${index}`}
        style={divStyle}
        className='StatsTable_salesCounter'
        title={`${count}`}
      />
    );
  };

  const renderStoreRow = (item, index, stripe, wl) => {
    return (
      <tr key={index}>
        <td>{item.time}</td>
        <td colSpan='2'>
          <WLIcon item={wl} index={index} /> {item.storeName}
        </td>
        <td>{renderSalesBlock('gray', item.carts, index, 2)}</td>
        <td>{renderSalesBlock('firebrick', item.refusals, index, 2)}</td>
        <td>{renderSalesBlock('goldenrod', item.pendings, index, 2)}</td>
        <td>{renderSalesBlock('green', item.sales, index, 2)}</td>
      </tr>
    );
  };

  return (
    <table className={`StatsTable ${className}`}>
      <colgroup>
        <col width='50px;' />
        <col width='30px;' />
        <col width='260px;' />
        <col width='100px;' />
        <col width='60px;' />
        <col width='60px;' />
        <col width='60px;' />
      </colgroup>
      <tbody>
        <tr>
          <th colSpan='7'>
            <span className='StatsTable_caption'>Store Activity</span>
          </th>
        </tr>
        <tr className='StatsTable_innerHeader'>
          <td>Last Activity</td>
          <td colSpan='2'>Store</td>
          <td>Carts</td>
          <td>Refusals</td>
          <td>Pendings</td>
          <td>Sales</td>
        </tr>
        {items.map((item, index) => {
          var wl = wls.find(function (element) {
            return element.wlID === item.wlID;
          }, item.wlID);

          var stripe = index % 2 === 1 ? 'stripe_even' : 'stripe_odd';
          var rows = [];
          rows.push(renderStoreRow(item, index, stripe, wl));
          return rows;
        })}
        {items.length === 0 && (
          <tr>
            <td colSpan='7' className='StatsTable_none'>
              No Logins
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default StoreCountersTable;
