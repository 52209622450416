import { Chart as ChartJS, Legend } from 'chart.js';

import React from 'react';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(Legend);

const doughnutOptions = {
  radius: '80%',
  plugins: {
    legend: {
      display: true,
      align: 'start',
      position: 'bottom',
      labels: {
        usePointStyle: true,
        textAlign: 'left',
      },
    },
    tooltip: { enabled: false },
  },
};

const NoDataDoughnutGraph = () => (
  <Doughnut
    data={{
      labels: ['No data'],
      datasets: [
        {
          data: [1],
          backgroundColor: ['#bfbfbf'],
        },
      ],
    }}
    options={doughnutOptions}
  />
);

export const DoughnutGraph = ({ labels, data, backgroundColor }) => {
  const isEmptyData = data.every((item) => item === 0);

  return !isEmptyData ? (
    <Doughnut
      data={{
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: backgroundColor,
          },
        ],
      }}
      options={doughnutOptions}
    />
  ) : (
    <NoDataDoughnutGraph />
  );
};

export default DoughnutGraph;
