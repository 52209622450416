import {
  addNotificationAction,
  closeModalAction,
  hideLoaderAction,
  showLoaderAction,
} from 'actions/ui';
import { posalePost as post } from 'apis/v2';
import { uploadPost as upload } from 'apis/v2';

import {
  amendDeviceCodeSuccessful,
  amendDeviceReferenceSuccessful,
  contractCancelSuccessful,
  contractsCancelSuccessful,
  resellerServicesUpdateSuccessful,
  serialNumbersAddSuccessful,
  upgradeCancelSuccessful,
} from 'constants/notifications';

export const GET_SALES = 'GET_SALES';

export const SEARCH_SALES = 'SEARCH_SALES';
export function searchSalesAction(data) {
  return async function (dispatch) {
    dispatch(showLoaderAction());

    const response = await post(GET_SALES, {
      ...data,
      timezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
    });

    if (response.data.status === 'SUCCESS') {
      dispatch({
        type: SEARCH_SALES,
        data: {
          list: response.data.data.sales,
        },
      });
    }

    dispatch(hideLoaderAction());
  };
}

export const GET_SALE = 'GET_SALE';
export function getSaleAction(cartUUID) {
  return async function (dispatch) {
    dispatch(showLoaderAction());

    const response = await post(GET_SALE, {
      cartUUID,
    });

    if (response.data.status === 'SUCCESS') {
      dispatch({
        type: GET_SALE,
        sale: response.data.data.sale,
      });
    } else {
      dispatch(clearSaleAction());
    }

    dispatch(hideLoaderAction());
  };
}

export const CLEAR_SALE = 'CLEAR_SALE';
export function clearSaleAction() {
  return function (dispatch) {
    dispatch({ type: CLEAR_SALE });
  };
}

export const CANCEL_SALE = 'CANCEL_SALE';
export function cancelSaleAction(cartUUID) {
  return async function (dispatch) {
    const response = await post(CANCEL_SALE, {
      cartUUID,
    });

    if (response.data.status !== 'ERROR') {
      if (response.data.status === 'SUCCESS') {
        dispatch(addNotificationAction(contractsCancelSuccessful()));
      }

      dispatch({ type: CANCEL_SALE, sale: response.data.data.sale });
    }
  };
}

export const CANCEL_ORDER = 'CANCEL_ORDER';
export function cancelOrderAction(orderUUID) {
  return async function (dispatch) {
    const response = await post(CANCEL_ORDER, {
      orderUUID,
    });

    if (response.data.status !== 'ERROR') {
      if (response.data.status === 'SUCCESS') {
        dispatch(addNotificationAction(contractCancelSuccessful()));
      }

      dispatch({ type: CANCEL_ORDER, sale: response.data.data.sale });
    }
  };
}

export const FULFILLMENT_RETURN = 'FULFILLMENT_RETURN';
export function fulfillmentReturnAction({
  fulfillmentReturnType,
  cartUUID,
  returnReasonCode,
  conditionDescription,
  successNotification,
}) {
  return async function (dispatch) {
    const response = await post(FULFILLMENT_RETURN, {
      fulfillmentReturnType,
      cartUUID,
      returnReasonCode,
      conditionDescription,
    });

    if (response.data.status === 'ERROR') {
      return;
    }

    dispatch({ type: CANCEL_ORDER, sale: response.data.data.sale });

    if (response.data.status === 'SUCCESS') {
      dispatch(addNotificationAction(successNotification()));
    }
  };
}

export const AMEND_DEVICE_REFERENCE = 'AMEND_DEVICE_REFERENCE';
export function amendDeviceReferenceAction(orderUUID, newReference, isIMEI) {
  return async function (dispatch) {
    const response = await post(AMEND_DEVICE_REFERENCE, {
      orderUUID,
      newReference,
      isIMEI,
    });

    if (response.data.status === 'SUCCESS') {
      dispatch(addNotificationAction(amendDeviceReferenceSuccessful()));
      dispatch(closeModalAction());
      dispatch({ type: AMEND_DEVICE_REFERENCE, sale: response.data.data.sale });
    }
  };
}

export const GET_AMEND_DEVICE_CODE_LIST = 'GET_AMEND_DEVICE_CODE_LIST';
export function getAmendDeviceCodeListAction(orderUUID) {
  return async function (dispatch) {
    dispatch({ type: GET_AMEND_DEVICE_CODE_LIST, data: [] });
    const response = await post(GET_AMEND_DEVICE_CODE_LIST, {
      orderUUID,
    });

    if (response.data.status === 'SUCCESS') {
      dispatch({
        type: GET_AMEND_DEVICE_CODE_LIST,
        data: response.data.data.devices,
      });
    }
  };
}

export const AMEND_DEVICE_CODE = 'AMEND_DEVICE_CODE';
export function amendDeviceCodeAction(
  orderUUID,
  newDeviceCode,
  newReference,
  isIMEI,
) {
  return async function (dispatch) {
    const response = await post(AMEND_DEVICE_CODE, {
      orderUUID,
      newDeviceCode,
      newReference,
      isIMEI,
    });

    if (response.data.status === 'SUCCESS') {
      dispatch(addNotificationAction(amendDeviceCodeSuccessful()));
      dispatch(closeModalAction());
      dispatch({ type: AMEND_DEVICE_CODE, sale: response.data.data.sale });
    }
  };
}

export const CANCEL_UPGRADE = 'CANCEL_UPGRADE';
export function cancelUpgradeAction(orderUUID) {
  return async function (dispatch) {
    const response = await post(CANCEL_UPGRADE, {
      orderUUID,
    });

    if (response.data.status !== 'ERROR') {
      if (response.data.status === 'SUCCESS') {
        dispatch(addNotificationAction(upgradeCancelSuccessful()));
      }

      dispatch({ type: CANCEL_UPGRADE, sale: response.data.data.sale });
    }
  };
}

const UPLOAD_FILE = 'UPLOAD_FILE';
const RV_CONTRACT = 'RV_CONTRACT';
export const UPLOAD_SALES = 'UPLOAD_SALES';
export function uploadSales(file) {
  return async function (dispatch) {
    dispatch(showLoaderAction());

    const response = await upload(UPLOAD_FILE, { file, type: RV_CONTRACT });

    if (response.data.status !== 'ERROR') {
      const report = response?.data?.data;
      dispatch(hideLoaderAction());

      return report;
    }

    dispatch(hideLoaderAction());
  };
}

export const GET_CART_INVOICES = 'GET_CART_INVOICES';
export function getCartInvoices(cartUUID) {
  return async function (dispatch) {
    const response = await post(GET_CART_INVOICES, {
      cartUUID,
    });
    if (response.data.status === 'SUCCESS') {
      dispatch({
        type: GET_CART_INVOICES,
        data: response.data.data.cartInvoices,
      });
    }
  };
}

export const GET_CART_ACTIONS = 'GET_CART_ACTIONS';
export function getCartActions({ cartUUID, wlID }) {
  return async function (dispatch) {
    const response = await post(GET_CART_ACTIONS, {
      cartUUID,
      wlID,
    });

    if (response.data.status !== 'ERROR') {
      dispatch({
        type: GET_CART_ACTIONS,
        saleActions: response.data?.data.cartActions,
      });
    }
  };
}

export const ADD_SERIAL_NUMBERS = 'ADD_SERIAL_NUMBERS';
export function addSerialNumbers(cartUUID, serialNumbers) {
  return async function (dispatch) {
    const response = await post(ADD_SERIAL_NUMBERS, {
      cartUUID,
      serialNumbers,
    });

    if (response.data.status === 'SUCCESS') {
      dispatch(addNotificationAction(serialNumbersAddSuccessful()));
      dispatch(closeModalAction());
    }
  };
}

export const UPDATE_RESELLER_SERVICES = 'UPDATE_RESELLER_SERVICES';
export function updateResellerServices(cartUUID) {
  return async function (dispatch) {
    const response = await post(UPDATE_RESELLER_SERVICES, {
      cartUUID,
    });

    if (response.data.status === 'SUCCESS') {
      await dispatch(addNotificationAction(resellerServicesUpdateSuccessful()));
    }
  };
}
