import { file as fileValidator } from 'helpers/validators';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import React, { useState } from 'react';

import Button from 'components/Button';

import FileInput from './FileInput';
import './FileUploadForm.styl';

const FileUploadForm = ({
  accept,
  cancelAction,
  cancelButtonLabel = '',
  fieldLabel,
  fieldVariant = '',
  form,
  helpText,
  invalid,
  maxFiles = 1,
  maxSize = '10 MB',
  reset,
  submitted,
  submitting,
  testSelector = form,
  uploadAction,
  uploadButtonLabel = '',
  vertical = false,
}) => {
  const [file, setFile] = useState({});
  const [inputKey, setInputKey] = useState(`${form}-file-${Date.now()}`);

  const handleFileUpload = (event) => {
    event.preventDefault();
    uploadAction(file);
    setInputKey(`${form}-file-${Date.now()}`);
    reset();
  };

  const handleFileChange = (newFile) => {
    setFile(newFile);
  };

  return (
    <form
      className={`file-upload-form ${
        vertical ? 'file-upload-form--vertical' : ''
      } ${form !== 'file-upload-form' ? form : ''}`}
      encType='multipart/form-data'
      name={form}
    >
      <Field
        component={FileInput}
        helpText={helpText}
        name={`${form}-file`}
        onChange={handleFileChange}
        placeholder={fieldLabel}
        key={inputKey}
        testSelector={`${testSelector}-field`}
        validate={[
          fileValidator({
            accept,
            maxSize,
            maxFiles,
          }),
        ]}
        variant={fieldVariant}
      />

      <div className='file-upload-form__buttons'>
        <Button
          disabled={invalid || submitting || submitted}
          testSelector={`${testSelector}-upload-button`}
          onClick={handleFileUpload}
        >
          {uploadButtonLabel}
        </Button>
        {typeof cancelAction === 'function' && (
          <Button
            classes='inverted'
            disabled={submitting}
            onClick={cancelAction}
            testSelector={`${testSelector}-cancel-button`}
          >
            {cancelButtonLabel}
          </Button>
        )}
      </div>
    </form>
  );
};

FileUploadForm.propTypes = {
  accept: PropTypes.string,
  cancelAction: PropTypes.func,
  cancelButtonLabel: PropTypes.string,
  fieldLabel: PropTypes.string,
  fieldVariant: PropTypes.string,
  form: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  invalid: PropTypes.bool,
  maxFiles: PropTypes.number,
  maxSize: PropTypes.string,
  reset: PropTypes.func.isRequired,
  submitted: PropTypes.bool,
  submitting: PropTypes.bool,
  testSelector: PropTypes.string,
  uploadAction: PropTypes.func.isRequired,
  uploadButtonLabel: PropTypes.string,
  vertical: PropTypes.bool,
};

export default reduxForm({
  enableReinitialize: true,
  destroyOnUnmount: true,
})(FileUploadForm);
