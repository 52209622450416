import classNames from 'classnames';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

import React, { Fragment, useEffect, useState } from 'react';

import Button from 'components/Button';
import Image, { ImageThumbnail } from 'components/Image';
import IconCaret from 'components/icons/IconCaret';

import './Slideshow.styl';

const Slideshow = ({
  classes,
  images = [],
  interval = 0,
  showThumbnails = true,
  showCaptions = false,
}) => {
  let [selectedSlideIdx, setSelectedSlideIdx] = useState(0);

  const sliderContainerRef = React.createRef();
  let slideRefs = [];

  const thumbsContainerRef = React.createRef();
  let thumbRefs = [];

  useEffect(() => {
    if (interval > 0) {
      let autoplayTimer = setTimeout(nextSlide, interval);
      return () => clearTimeout(autoplayTimer);
    }
  });

  useEffect(() => {
    let slideObserver = new IntersectionObserver(slideIntersected, {
      root: sliderContainerRef.current,
      rootMargin: '0px',
      threshold: 1,
    });

    slideRefs.forEach((s) => {
      slideObserver.observe(s);
    });

    return () => {
      slideObserver.disconnect();
      slideObserver = null;
    };
  });

  const slideIntersected = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && entry.intersectionRatio === 1) {
        let idx = slideRefs.indexOf(entry.target);
        setSelectedSlideIdx(idx);
        scrollToThumb(idx);
      }
    });
  };

  const addSlideRef = (ref) => {
    slideRefs = [...slideRefs, ref];
  };

  const addThumbRef = (ref) => {
    thumbRefs = [...thumbRefs, ref];
  };

  const scrollToSlide = (idx) => {
    if (sliderContainerRef.current) {
      sliderContainerRef.current.scrollLeft = slideRefs[idx]?.offsetLeft;
    }
  };

  const scrollToThumb = (idx) => {
    if (thumbsContainerRef.current) {
      thumbsContainerRef.current.scrollLeft = thumbRefs[idx]?.offsetLeft;
    }
  };

  const thumbSelect = (e, idx) => {
    e.preventDefault();
    scrollToSlide(idx);
  };

  const prevSlide = debounce((e) => {
    let idx = selectedSlideIdx === 0 ? slides.length - 1 : selectedSlideIdx - 1;
    scrollToSlide(idx);
  }, 300);

  const nextSlide = debounce((e) => {
    let idx = selectedSlideIdx === slides.length - 1 ? 0 : selectedSlideIdx + 1;
    scrollToSlide(idx);
  }, 300);

  const buildFigure = (img, idx) => (
    <figure
      className={classNames('slideshow__slide', {
        'slideshow__slide--selected': idx === selectedSlideIdx,
      })}
      id={`slide-${idx}`}
      key={`slide-${idx}`}
      ref={addSlideRef}
    >
      <picture>
        <Image
          className='slideshow__slide-image'
          src={img.imageUrl}
          alt={img.caption || ''}
        />
      </picture>
      {showCaptions && img.caption && (
        <figcaption className='slideshow__slide-caption'>
          {img.caption}
        </figcaption>
      )}
    </figure>
  );

  const buildThumb = (img, idx) => {
    return (
      <li
        className={classNames('slideshow__thumb', {
          'slideshow__thumb--selected': idx === selectedSlideIdx,
        })}
        key={`thumb-${idx}`}
        ref={addThumbRef}
      >
        <a
          role='button'
          href={`#slide-${idx}`}
          className='slideshow__thumb-btn'
          // TODO make this component reliant only on hooks in order
          // to remove arrow functions and enforce this rule
          // eslint-disable-next-line react/jsx-no-bind
          onClick={(e) => thumbSelect(e, idx)}
        >
          <picture>
            <ImageThumbnail
              className='slideshow__thumb-image'
              src={img.thumbnailUrl}
              alt={img.caption || ''}
            />
          </picture>
        </a>
      </li>
    );
  };

  const [slides, thumbnails] = images.reduce(
    ([slides, thumbnails], img, idx) => {
      return [
        [...slides, buildFigure(img, idx)],
        showThumbnails ? [...thumbnails, buildThumb(img, idx)] : [],
      ];
    },
    [[], []],
  );

  return (
    <div
      className={`slideshow ${
        thumbnails.length > 1 ? 'slideshow--with-thumbnails' : ''
      } ${classes}`}
    >
      {slides.length > 0 && (
        <div className='slideshow__content'>
          {slides.length > 1 && (
            <Fragment>
              <Button classes='slideshow__prev-btn' onClick={prevSlide}>
                <IconCaret />
              </Button>
              <Button classes='slideshow__next-btn' onClick={nextSlide}>
                <IconCaret />
              </Button>
            </Fragment>
          )}
          <div ref={sliderContainerRef} className='slideshow__slider'>
            {slides}
          </div>
          {thumbnails.length > 1 && (
            <ul ref={thumbsContainerRef} className='slideshow__thumbs-list'>
              {thumbnails}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

Slideshow.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string.isRequired,
      caption: PropTypes.string,
      thumbnailUrl: PropTypes.string,
    }),
  ).isRequired,
  interval: PropTypes.number,
  showThumbnails: PropTypes.bool,
  showCaptions: PropTypes.bool,
};

export default Slideshow;
