import { posalePost as post } from 'apis/v2';

export const GET_CUSTOMER = 'GET_CUSTOMER';
export function getCustomerAction(customerUUID) {
  return async function (dispatch) {
    const response = await post(GET_CUSTOMER, {
      customerUUID,
    });

    const { status, data } = response.data;

    if (status !== 'ERROR' && data.customer) {
      const customer = data.customer;

      dispatch(
        setCustomerAction({
          email: customer.email,
          firstName: customer.firstName,
          lastName: customer.lastName,
          name:
            customer.name ||
            (customer.firstName || '') + ' ' + (customer.lastName || ''),
          phone: customer.mobilePhone,
        }),
      );
    }
  };
}

export const CLEAR_CUSTOMER = 'CLEAR_CUSTOMER';
export function clearCustomerAction() {
  return { type: CLEAR_CUSTOMER };
}

export const SET_CUSTOMER = 'SET_CUSTOMER';
export function setCustomerAction(values) {
  return { type: SET_CUSTOMER, values };
}

export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export function updateCustomerAction(key, value) {
  return { type: UPDATE_CUSTOMER, key, value };
}

export const UPDATE_CUSTOMER_OPTION = 'UPDATE_CUSTOMER_OPTION';
export function updateCustomerOptionAction(value) {
  return async function (dispatch, getState) {
    dispatch({
      type: UPDATE_CUSTOMER_OPTION,
      options: getState().customer.options.map((option) => ({
        ...option,
        selected: option.id === value,
      })),
    });
  };
}

export const COMPANY_LOOKUP = 'COMPANY_LOOKUP';
export function companyLookup(cartUUID, companyName = '', location = '') {
  return async function () {
    const response = await post(COMPANY_LOOKUP, { cartUUID, companyName });
    return response.data;
  };
}

export const UPDATE_COMPANY_DETAILS = 'UPDATE_COMPANY_DETAILS';
export function updateCompanyDetailsAction(key, value) {
  return { type: UPDATE_COMPANY_DETAILS, key, value };
}

export const SET_COMPANY_DETAILS = 'SET_COMPANY_DETAILS';
export function setCompanyDetailAction(value) {
  return { type: SET_COMPANY_DETAILS, value };
}

export const GET_COMPANY_DETAILS_FORM_DATA = 'GET_COMPANY_DETAILS_FORM_DATA';
export function getCompanyDetailsFormData(cartUUID) {
  return async function () {
    const response = await post(GET_COMPANY_DETAILS_FORM_DATA, { cartUUID });
    return response.data.data;
  };
}
