import { t } from '@lingui/macro';

export const triggerEventsDict = () => ({
  UPGRADED_DATE: t({
    id: 'trigger.events.upgraded.date',
    message: `Upgraded Date`,
  }),
  CART_CREATE_DATE: t({
    id: 'trigger.events.cart.created.date',
    message: `Cart Created Date`,
  }),
  CREDIT_APPLICATION_DATE: t({
    id: 'trigger.events.credit.application.date',
    message: `Credit Application Date`,
  }),
  CREDIT_APPROVED_DATE: t({
    id: 'trigger.events.credit.approved.date',
    message: `Credit Approved Date`,
  }),
  ACTIVATED_DATE: t({
    id: 'trigger.events.activated.date',
    message: `Contract Activation Date`,
  }),
  DELIVERED_DATE: t({
    id: 'trigger.events.delivered.date',
    message: `Device Delivery Date`,
  }),
  END_WITHDRAWAL_DATE: t({
    id: 'trigger.events.end.withdrawal.date',
    message: `Withdrawal Period End Date`,
  }),
  EARLY_UPGRADE_START_DATE: t({
    id: 'trigger.events.early.upgrade.start',
    message: `Early Upgrade Start Date`,
  }),
  FREE_UPGRADE_START_DATE: t({
    id: 'trigger.events.free.upgrade.start',
    message: `Free Upgrade Start Date`,
  }),
  CONTRACT_END_DATE: t({
    id: 'trigger.events.contract.end.date',
    message: `Contract End (Final Payment) Date`,
  }),
  CANCELLED_DATE: t({
    id: 'trigger.events.cancelled.date',
    message: `Contract Cancelled Date`,
  }),
});
