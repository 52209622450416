import classNames from 'classnames';
import PropTypes from 'prop-types';

import React, { Fragment, useEffect, useState } from 'react';

import IconCaret from 'components/icons/IconCaret';

import './Section.styl';

const Section = ({
  className = '',
  expanded = false,
  children,
  inline = false,
  hideById = [],
  ...props
}) => {
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    hideById.map((id) => {
      const elem = document.getElementById(id);
      if (elem) {
        expand
          ? elem.classList.remove('collapsed')
          : elem.classList.add('collapsed');
      }
    });
  }, [expand, hideById]);

  useEffect(() => {
    setExpand(expanded);
  }, [expanded]);

  const hasCollapsableElements = () => hideById.length > 0;

  const toggleExpand = () => setExpand(!expand);

  return (
    <section
      className={classNames('section', className, {
        'section--inline': inline || hasCollapsableElements(),
      })}
      {...props}
    >
      {!hasCollapsableElements() ? (
        children
      ) : (
        <Fragment>
          <div
            className={classNames('section-content', {
              'section-content--inline': inline,
            })}
          >
            {children}
          </div>
          <div className='section-icon-wrapper'>
            <div
              onClick={toggleExpand}
              className={classNames(`collapse__icon`, {
                [`collapse__icon--expanded`]: expand,
              })}
            >
              <IconCaret />
            </div>
          </div>
        </Fragment>
      )}
    </section>
  );
};

Section.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  inline: PropTypes.bool,
  hideById: PropTypes.array,
};

export default Section;
