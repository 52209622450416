import errorFace from 'assets/images/error-face.svg';

import React, { Fragment, useState } from 'react';

import { t } from '@lingui/macro';

import Footer from 'components/Footer';

import './SomethingWentWrong.styl';

const SomethingWentWrong = () => {
  const [sendReport] = useState('notSent');

  return (
    <div className='something-wrong-wrapper'>
      {<ToBeSent sendReport={sendReport} />}

      <Footer classes='main' copyright={false} />
    </div>
  );
};

const ToBeSent = () => {
  return (
    <Fragment>
      <img src={errorFace} alt='something went wrong' />
      <div className='something-wrong'>
        <h1>{t({ id: 'something.wrong.initial.title', message: `Ooops` })}</h1>

        <h2>
          {t({
            id: 'something.wrong.initial.subtitle',
            message: `Something went wrong`,
          })}
        </h2>

        <p>
          {t({
            id: 'something.wrong.initial.paragraph.one',
            message: `Don't Panic!`,
          })}
        </p>

        <p>
          {t({
            id: 'something.wrong.initial.paragraph.two',
            message: `Our support team is here to help.`,
          })}
        </p>
      </div>
    </Fragment>
  );
};

export default SomethingWentWrong;
