import { DeviceDTO, WLDeviceDTO } from 'DTOs/admin';
import {
  addNotificationAction,
  closeModalAction,
  hideLoaderAction,
  showLoaderAction,
} from 'actions/ui';
import { adminPost, posalePost } from 'apis/v2';
import isEmpty from 'lodash/isEmpty';

import {
  deviceAddSuccess,
  deviceEditSuccess,
  deviceRemoveSuccess,
  noResidualsFound,
} from 'constants/notifications';

export const LIST_DEVICES = 'LIST_DEVICES';
export function listDevicesAction() {
  return async function (dispatch) {
    dispatch(showLoaderAction());

    // TODO move this destructuring to api layer?
    const {
      data: { status, data },
    } = await adminPost(LIST_DEVICES);

    if (status === 'SUCCESS') {
      dispatch({
        type: LIST_DEVICES,
        data: data.devices.map((deviceRaw) => {
          return DeviceDTO.parse(deviceRaw).toModel();
        }),
      });
    }

    dispatch(hideLoaderAction());
  };
}

export const CREATE_WL_DEVICE = 'CREATE_W_L_DEVICE';
export function createWlDeviceAction(device) {
  return async function (dispatch) {
    const dto = WLDeviceDTO.fromModel(device);
    // TODO move this destructuring to api layer?
    const {
      data: { status },
    } = await adminPost(CREATE_WL_DEVICE, { wlDevice: dto.toJSON() });

    if (status === 'SUCCESS') {
      await dispatch(listDevicesAction());
      dispatch(addNotificationAction(deviceAddSuccess()));
    }
  };
}

export const DISABLE_WL_DEVICE = 'DISABLE_W_L_DEVICE';
export function disableWlDeviceAction(device) {
  return async function (dispatch) {
    const dto = WLDeviceDTO.fromModel(device);
    // TODO move this destructuring to api layer?
    const {
      data: { status },
    } = await adminPost(DISABLE_WL_DEVICE, {
      wlDeviceID: dto.toJSON().id,
    });

    if (status === 'SUCCESS') {
      await dispatch(listDevicesAction());
      dispatch(addNotificationAction(deviceRemoveSuccess()));
    }
  };
}

export const UPDATE_WL_DEVICE = 'UPDATE_W_L_DEVICE';
export function updateWlDeviceAction(device) {
  return async function (dispatch) {
    const dto = WLDeviceDTO.fromModel(device);
    // TODO move this destructuring to api layer?
    const {
      data: { status },
    } = await adminPost(UPDATE_WL_DEVICE, {
      wlDevice: dto.toJSON(),
    });

    if (status === 'SUCCESS') {
      dispatch(closeModalAction());
      await dispatch(listDevicesAction());
      dispatch(addNotificationAction(deviceEditSuccess()));
    }
  };
}

export const GET_LOAN_DATA = 'GET_LOAN_DATA';
export function getLoanDataAction(device, startDate, discount) {
  return async function (dispatch) {
    dispatch(showLoaderAction());

    if (device) {
      // TODO move this destructuring to api layer?
      const {
        data: { status, data },
      } = await posalePost(GET_LOAN_DATA, {
        deviceID: device.id,
        startDate,
        discount,
      });

      if (status === 'SUCCESS' && data?.loanDataCalendar) {
        dispatch({
          type: GET_LOAN_DATA,
          loanData: {
            device,
            calendar: data.loanDataCalendar[startDate],
            discount,
          },
        });
      }
    }

    dispatch(hideLoaderAction());
  };
}

export const CLEAR_LOAN_DATA = 'CLEAR_LOAN_DATA';
export function clearLoanDataAction() {
  return function (dispatch) {
    dispatch({ type: CLEAR_LOAN_DATA });
  };
}

export const GET_RESIDUAL_VALUES = 'GET_UPGRADE_MONTH_RESIDUALS_FOR_DEVICES';
export function getResidualValuesAction({
  selectedManufacturer,
  selectedCategory,
  selectedFamily,
  selectedDevice,
  selectedDate,
}) {
  return async function (dispatch) {
    // TODO move this destructuring to api layer?
    const {
      data: { status, data },
    } = await posalePost(GET_RESIDUAL_VALUES, {
      manufacturerCode: selectedManufacturer,
      categoryCode: selectedCategory,
      familyCode: selectedFamily,
      deviceCode: selectedDevice,
      date: selectedDate,
    });

    if (status === 'SUCCESS' && data.residuals) {
      let residualCols = [];

      isEmpty(data.residuals) &&
        dispatch(addNotificationAction(noResidualsFound()));

      const residuals = data.residuals.map((residual) => {
        residualCols = [
          ...new Set(
            residualCols.concat(Object.keys(residual.residualsPerMonth)),
          ),
        ];
        return {
          ...residual,
          ...residual.residualsPerMonth,
        };
      });
      dispatch({
        type: GET_RESIDUAL_VALUES,
        ...{ residuals, residualCols, selectedDate },
      });
    }
  };
}

export const CLEAR_RESIDUAL_VALUES = 'CLEAR_RESIDUAL_VALUES';
export function clearResidualValuesAction() {
  return function (dispatch) {
    dispatch({ type: CLEAR_RESIDUAL_VALUES });
  };
}
