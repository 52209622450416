import { searchSalesAction } from 'actions/sales';
import { email, length, numericality } from 'helpers/validators';
import isEmpty from 'lodash/isEmpty';
import {
  change,
  clearFields,
  getFormSyncErrors,
  getFormValues,
  isDirty,
} from 'redux-form';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';

import Form from 'components/Form';
import Heading from 'components/Heading';
import Input from 'components/Input';

import { FILTER_FORM_NAME } from './Filter';
import './Search.styl';

export const SEARCH_FORM_NAME = 'SalesForm-Search';
const INPUT_REFERENCE = 'searchText';
const INPUT_EMAIL = 'customerEmail';
const INPUT_PHONE = 'customerPhone';

const Search = ({ isSales }) => {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const formValues = useSelector((state) =>
    getFormValues(SEARCH_FORM_NAME)(state),
  );

  const formErrors = useSelector((state) =>
    getFormSyncErrors(SEARCH_FORM_NAME)(state),
  );
  const isFilterFormDirty = useSelector((state) =>
    isDirty(FILTER_FORM_NAME)(state),
  );

  const isFormLocked = () => isFilterFormDirty;

  const isFieldDisabled = (field) =>
    isFormLocked() ||
    (!isEmpty(formValues) && !formValues?.hasOwnProperty(field));

  // use effect to perform request on params change
  useEffect(() => {
    if (searchParams.get('activeForm') === SEARCH_FORM_NAME) {
      let paramFormValues = {};

      searchParams.forEach((value, key) => {
        if (value === SEARCH_FORM_NAME || key === 'timestamp') {
          return;
        }
        paramFormValues[key] = value;
        dispatch(change(SEARCH_FORM_NAME, key, value));
      });

      dispatch(
        searchSalesAction({
          search: paramFormValues,
          onlySalesWithPendingActions: !isSales,
        }),
      );
    }
  }, [dispatch, searchParams, isSales]);

  const fields = [
    {
      component: Input,
      placeholder: t({
        id: 'forms.sales.search.contract.or.reference',
        message: `Contract ID or IMEI/SN`,
      }),
      name: INPUT_REFERENCE,
      validate: !isFieldDisabled(INPUT_REFERENCE)
        ? [length({ max: 255 })]
        : undefined,
      classes: 'sales-form__reference',
      autoComplete: 'device-reference-search',
      testSelector: 'sales-search-id-reference',
      disabled: isFieldDisabled(INPUT_REFERENCE),
      showInputNameError: false,
      normalize: (value) => value?.trim(),
    },
    {
      component: Input,
      placeholder: t({
        id: 'forms.sales.search.customer.email',
        message: `Customer email`,
      }),
      name: INPUT_EMAIL,
      value: searchParams.get(INPUT_EMAIL),
      validate: !isFieldDisabled(INPUT_EMAIL)
        ? [email({ allowBlank: true }), length({ max: 255 })]
        : undefined,
      classes: 'sales-form__email',
      autoComplete: 'customer-email-search',
      testSelector: 'sales-search-customer-email',
      disabled: isFieldDisabled(INPUT_EMAIL),
      showInputNameError: false,
      normalize: (value) => value?.trim(),
    },
    {
      component: Input,
      placeholder: t({
        id: 'forms.sales.search.customer.phone',
        message: `Customer phone number`,
      }),
      name: INPUT_PHONE,
      value: searchParams.get(INPUT_PHONE),
      validate: !isFieldDisabled(INPUT_PHONE)
        ? [
            numericality({ allowBlank: true }),
            length({
              allowBlank: true,
              min: 7,
              msg: t({
                id: 'validator.min.phone.size',
                message: `Please insert the last ${7} digits of the number`,
              }),
            }),
          ]
        : undefined,
      classes: 'sales-form__phone',
      autoComplete: 'customer-phone-search',
      testSelector: 'sales-search-customer-phone',
      disabled: isFieldDisabled(INPUT_PHONE),
      showInputNameError: false,
      normalize: (value) => value?.trim(),
    },
  ];

  const isClearDisabled = () => isFormLocked() || isEmpty(formValues);
  const cleanActionHandler = () => {
    setSearchParams({ activeForm: FILTER_FORM_NAME });
    dispatch(
      clearFields(
        SEARCH_FORM_NAME,
        false,
        false,
        INPUT_REFERENCE,
        INPUT_EMAIL,
        INPUT_PHONE,
      ),
    );
  };

  const isSubmitDisabled = () => {
    if (isFormLocked()) return true;

    let currentActiveField = Object.keys(formValues || {})[0];
    return currentActiveField && formErrors?.hasOwnProperty(currentActiveField);
  };

  const submitActionHandler = () => {
    setSearchParams({
      activeForm: SEARCH_FORM_NAME,
      ...formValues,
      timestamp: Date.now(),
    });
  };

  const actionButtons = [
    {
      disabled: isClearDisabled(),
      handleClick: cleanActionHandler,
      label: t({ id: 'reports.form.clear.button', message: 'Clear' }),
      name: 'form-clear-btn',
      testSelector: 'sales-search-clear-btn',
    },
    {
      disabled: isSubmitDisabled(),
      handleClick: submitActionHandler,
      type: 'submit',
      label: t({ id: 'reports.form.search.button', message: 'Search' }),
      name: 'form-search-btn',
      testSelector: 'sales-search-submit-btn',
    },
  ];

  return (
    <div className='sales-form__search-container'>
      <Heading size='medium' data-test='sales-search-title'>
        <Trans id='reports.form.search.title'>Search</Trans>
      </Heading>
      <p data-test='sales-search-description'>
        <Trans id='reports.form.search.subtitle'>
          Please choose only one of the following options to search for the
          contract
        </Trans>
      </p>

      <Form
        onSubmit={submitActionHandler}
        form={SEARCH_FORM_NAME}
        classes='sales-form__search'
        customFieldValidation
        destroyOnUnmount
        fields={fields}
        actionButtons={actionButtons}
      />
    </div>
  );
};

export default Search;
