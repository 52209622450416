import arrow from 'assets/images/icon-arrow-back.svg';
import classNames from 'classnames';

import React, { Fragment } from 'react';

import { Trans } from '@lingui/macro';

import Button from 'components/Button';

import './Pagination.styl';

const Pagination = ({
  id,
  from,
  current,
  total,
  prevPage,
  nextPage,
  loadMore = false,
  perPage,
  name,
}) => {
  const remainingElems = total - current;

  return (
    <div
      id={id}
      className={classNames({
        pagination: !loadMore,
        'pagination-load-more': loadMore,
      })}
    >
      {loadMore ? (
        <Fragment>
          {remainingElems > 0 && (
            <Button onClick={nextPage}>
              <Trans id='table.next.of'>
                {`Next ${
                  perPage < remainingElems ? perPage : remainingElems
                } (of ${total}) ${name}`}
              </Trans>
              <img src={arrow} alt='loadMore arrow' />
            </Button>
          )}
        </Fragment>
      ) : (
        <Fragment>
          <Button
            key='prev-page'
            classes='prev'
            onClick={prevPage}
            disabled={from === 0 || total === 1}
          >
            ‹
          </Button>

          <span className='page-indicator'>
            <Trans id='table.page.of'>{`Page ${current} of ${total}`}</Trans>
          </span>

          <Button
            key='next-page'
            classes='next'
            onClick={nextPage}
            disabled={current === total}
          >
            ›
          </Button>
        </Fragment>
      )}
    </div>
  );
};

export default Pagination;
