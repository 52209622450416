import { currencyFormatter } from 'helpers/formatters';

import React from 'react';

import { Trans, t } from '@lingui/macro';

import Table from 'components/table/Table';

import {
  SWAP,
  evaluationGradeTitlesList,
  simStatusLabelsList,
} from 'constants/recycle';

import './DevicesSummary.styl';

const DevicesSummary = ({
  language,
  currency,
  devices,
  totalValue,
  totalSwapCost,
  source,
}) => {
  const columns = [
    {
      label: '',
      accessor: 'imgURL',
      type: 'image',
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.name', message: `Name` }),
      accessor: 'name',
      type: 'text',
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.device.reference',
        message: `Device Reference`,
      }),
      accessor: 'deviceReference',
      type: 'text',
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.network', message: `SIM Status` }),
      accessor: 'network',
      type: 'dict',
      list: simStatusLabelsList(),
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.condition', message: `Condition` }),
      accessor: 'grade',
      type: 'dict',
      list: evaluationGradeTitlesList(),
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.value', message: `Value` }),
      accessor: 'value',
      type: 'currency',
      align: 'center',
      wrap: false,
    },
  ];

  const hasSwapCosts = () => source === SWAP && !(totalSwapCost === 0);

  return (
    <div className='recycle-devices-summary'>
      <Table
        columns={columns}
        data={devices}
        keyColumn='deviceReference'
        defaultOrder='name'
        defaultDir='desc'
        filterable={false}
        pageable={false}
      />
      <div className='recycle-devices-summary-values'>
        <div>
          <div className='total-label'>
            <Trans id='devices.summary.total.value'>Total value:</Trans>
          </div>
          <div className='total-value'>
            {currencyFormatter(totalValue, currency, language)}
          </div>
        </div>
        {hasSwapCosts() && (
          <div>
            <div className='total-label'>
              <Trans id='devices.summary.total.swap.cost'>
                Total Upgrade cost:
              </Trans>
            </div>
            <div className='total-value'>
              {currencyFormatter(totalSwapCost, currency, language)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DevicesSummary;
