import classNames from 'classnames';

import React from 'react';

import './DateSelector.styl';

const renderButton = (date, uid, isNext) => {
  let qsDate = new Date(date);
  let isDisabled = false;
  qsDate.setHours(2); // Avoid issues with Winter / Summer Time.

  if (isNext) {
    qsDate.setDate(qsDate.getDate() + 1);
  } else {
    qsDate.setDate(qsDate.getDate() - 1);
  }

  if (qsDate > new Date()) isDisabled = true;

  let dateStr = qsDate.toISOString().substring(0, 10);

  return (
    <a
      href={`/dashboard?uid=${uid}&date=${dateStr}`}
      className={classNames('date-button', { 'not-active': isDisabled })}
    >
      {isNext ? '›' : '‹'}
    </a>
  );
};

const DateSelector = ({ date, uid }) => {
  let options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  var activeDate = new Date(date);

  return (
    <div className='date-selector'>
      {renderButton(date, uid, false)}

      <div className='date-text'>
        {activeDate.toLocaleDateString('en-UK', options)}
      </div>

      {renderButton(date, uid, true)}
    </div>
  );
};

export default DateSelector;
