import PropTypes from 'prop-types';
import { isFromCDN, isImg } from 'utils';

import React from 'react';

import { LARGE, MEDIUM, THUMBNAIL, TINY } from 'constants/images';

const noPhoto = 'https://cdn.financetech365.com/svg/no_photo.svg';

const ImageTiny = ({ alt, hasFallback, loading, src, ...props }) => {
  return (
    <Image
      alt={alt}
      hasFallback={hasFallback}
      loading={loading}
      src={isFromCDN(src) ? `${src}${TINY}` : src}
      {...props}
    />
  );
};

const ImageLarge = ({ alt, hasFallback, loading, src, ...props }) => {
  return (
    <Image
      alt={alt}
      hasFallback={hasFallback}
      loading={loading}
      src={isFromCDN(src) ? `${src}${LARGE}` : src}
      {...props}
    />
  );
};

const ImageMedium = ({ alt, hasFallback, loading, src, ...props }) => {
  return (
    <Image
      alt={alt}
      hasFallback={hasFallback}
      loading={loading}
      src={isFromCDN(src) ? `${src}${MEDIUM}` : src}
      {...props}
    />
  );
};

const ImageThumbnail = ({ alt, hasFallback, loading, src, ...props }) => {
  return (
    <Image
      alt={alt}
      hasFallback={hasFallback}
      loading={loading}
      src={isFromCDN(src) ? `${src}${THUMBNAIL}` : src}
      {...props}
    />
  );
};

// original size
const Image = ({
  alt,
  hasFallback = true,
  loading = 'lazy',
  src,
  ...props
}) => {
  return (
    <img
      alt={typeof alt !== 'string' && hasFallback ? '' : alt}
      loading={loading}
      src={!isImg(src) && hasFallback ? noPhoto : src}
      {...props}
    />
  );
};

const propTypes = {
  alt: PropTypes.string,
  hasFallback: PropTypes.bool,
  loading: PropTypes.oneOf(['auto', 'eager', 'lazy']),
  src: PropTypes.string,
};

ImageTiny.propTypes = { ...propTypes };
ImageLarge.propTypes = { ...propTypes };
ImageMedium.propTypes = { ...propTypes };
ImageLarge.propTypes = { ...propTypes };
Image.propTypes = { ...propTypes };

export default Image;

export { ImageTiny, ImageLarge, ImageMedium, ImageThumbnail };
