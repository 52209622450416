import {
  clearBlockAction,
  readBlockAction,
  upsertBlockAction,
} from 'actions/admin/templates';
import { dateFormatter } from 'helpers/formatters';
import { getFormValues } from 'redux-form';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Trans } from '@lingui/macro';

import Heading from 'components/Heading';
import Section from 'components/Section';
import IconApproved from 'components/icons/IconApproved';

import { adminTemplateBlockEditorURL } from 'constants/routes';

import './BlockEditor.styl';
import BlockForm from './components/BlockForm';
import BlockPreview from './components/BlockPreview';

export const BlockEditor = () => {
  const { blockUUID } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const block = useSelector(({ adminTemplates }) => adminTemplates?.block);
  const body = useSelector(
    (state) => getFormValues('block-editor')(state)?.body,
  );
  const description = useSelector(
    (state) => getFormValues('block-editor')(state)?.description,
  );
  const key = useSelector((state) => getFormValues('block-editor')(state)?.key);
  const language = useSelector(({ whitelabel }) => whitelabel.language);

  useEffect(() => {
    async function fetchBlock() {
      await dispatch(readBlockAction(blockUUID));
    }

    blockUUID !== 'new' && fetchBlock();

    return () => dispatch(clearBlockAction());
  }, [blockUUID, dispatch]);

  const saveBlockChanges = async (values) => {
    const response = await dispatch(
      upsertBlockAction({
        id: block?.id ?? null,
        uuid: block?.uuid ?? null,
        key: values?.key,
        description: values?.description,
        body: values?.body,
      }),
    );

    if (response)
      blockUUID === 'new' &&
        navigate(adminTemplateBlockEditorURL(response.htmlBlock.uuid));
  };

  return (
    <div className='blockEditor'>
      <Heading classes='blockTitle' size='big'>
        <Trans id='block.editor.title'>Block Editor</Trans>
      </Heading>

      {blockUUID !== 'new' && (
        <div className='blockIndicator'>
          <IconApproved className='blockIndicatorIcon' />

          <Trans id='block.last.update'>
            Last update on {dateFormatter(block?.updatedAt, language)} by{' '}
            {block?.updatedByName}
          </Trans>
        </div>
      )}

      <Section className='blockEditionArea'>
        <BlockForm block={block} onSubmit={saveBlockChanges} />
      </Section>

      <Section className='blockPreviewArea'>
        <BlockPreview
          block={{
            key,
            description,
            body,
          }}
        />
      </Section>
    </div>
  );
};

export default BlockEditor;
