import { closeModalAction } from 'actions/ui';
import closeIco from 'assets/images/close.svg';
import classNames from 'classnames';

import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';

import { Trans } from '@lingui/macro';

import Button from 'components/Button';

import './Modal.styl';

export const Modal = ({
  classes,
  open,
  name,
  onClose,
  closeModal,
  children,
  buttons,
  scrollable = false,
  title,
  onConfirm,
}) => {
  useEffect(() => {
    return () => {
      closeModal();
    };
  }, [closeModal]);

  const closeHandler = async (ev) => {
    ev.preventDefault();

    await onClose();
    closeModal();
  };

  const confirm = () => {
    onConfirm();
    closeModal();
  };

  return (
    <Fragment>
      {open === name && (
        <div
          className={classNames(
            'modal-background',
            {
              scrollable,
            },
            classes,
          )}
        >
          <div className='modal'>
            <img
              src={closeIco}
              alt='Close'
              onClick={closeHandler}
              data-test='modal-close-button'
              className='modal-close-button'
            />

            <div className='modal-content'>
              {title && <h2 className='title'>{title}</h2>}

              {children}

              {onConfirm && (
                <div className='modal-buttons confirmation'>
                  <Button data-test='modal-confirmation-yes' onClick={confirm}>
                    <Trans id='modal.confirmation.yes'>Yes</Trans>
                  </Button>
                  <Button
                    data-test='modal-confirmation-no'
                    classes='inverted'
                    onClick={closeHandler}
                  >
                    <Trans id='modal.confirmation.no'>No</Trans>
                  </Button>
                </div>
              )}

              {buttons.length > 0 && (
                <div className='modal-buttons list'>
                  {buttons.map(({ label, action, ...buttonProps }) => (
                    <Button
                      data-test={`modal-button-${label}`}
                      key={label}
                      onClick={action}
                      {...buttonProps}
                    >
                      {label}
                    </Button>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

Modal.defaultProps = {
  buttons: [],
  onClose: () => {},
  open: null,
};

export default connect(({ ui }) => ({ open: ui.openModal }), {
  closeModal: closeModalAction,
})(Modal);
