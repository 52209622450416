import React from 'react';

import { Trans } from '@lingui/macro';

import Heading from 'components/Heading';
import Modal from 'components/Modal';

export const RecycleBlockedDevice = ({ checkmendOk, fmi }) => {
  return (
    <Modal name='recycleBlockedDevice'>
      <Heading size='medium'>
        <Trans id='evaluation.recycle.blocked.device.title'>
          This device can't be traded due to:
        </Trans>
      </Heading>

      <ul>
        {!checkmendOk && (
          <li>
            <Trans id='evaluation.trade.device.stolen'>
              It was reported as lost or stolen by Checkmend! Customer can
              contact Checkmend for further information and get the block
              removed.
            </Trans>
          </li>
        )}
        {fmi?.isLocked && (
          <li>
            <Trans id='evaluation.trade.device.locked'>
              It was found to be locked by iCloud. Please remove the lock and
              the device from the customer's iCloud to continue with the Trade
              In.
            </Trans>
          </li>
        )}
        {fmi?.isLost && (
          <li>
            <Trans id='evaluation.trade.device.lost'>
              It was reported to be lost mode by iCloud. Please deactivate lost
              mode and remove device from the customer's iCloud to continue.
            </Trans>
          </li>
        )}
        {fmi?.isActivated && (
          <li>
            <Trans id='evaluation.trade.device.found'>
              It was found to be connected to the customer's iCloud. Please
              disconnect it to continue the Trade In process.
            </Trans>
          </li>
        )}
      </ul>
    </Modal>
  );
};

export default RecycleBlockedDevice;
