import React from 'react';

import { Trans } from '@lingui/macro';

import Heading from 'components/Heading';
import Modal from 'components/Modal';

export const RecycleCTOContent = () => {
  return (
    <div className='recycleCTOInfo'>
      <Heading size='medium'>
        <Trans id='search.device.info.title'>Can't find your model?</Trans>
      </Heading>

      <p>
        <Trans id='search-device.info.text'>
          The device may be a CTO (Configure to Order) model. This is when Apple
          produces a customised machine by adding more/less RAM, Hard Drive
          Space/Speed or Software. Please choose the model closest to yours
          prioritising: Mac type, screen size, year and processor, then select
          'CTO' where prompted.
        </Trans>
      </p>
    </div>
  );
};

export const RecycleCTO = () => {
  return (
    <Modal name='cannotFindDevice'>
      <RecycleCTOContent />
    </Modal>
  );
};

export default RecycleCTO;
