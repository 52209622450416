import {
  CLEAR_HTML_BLOCK,
  DELETE_HTML_BLOCK,
  LIST_HTML_BLOCKS,
  PUBLISH_TEMPLATE,
  READ_HTML_BLOCK,
  READ_TEMPLATE,
  RENDER_EMAIL,
  SAVE_TEMPLATE,
} from 'actions/admin/templates';

import initialState from 'reducers/initialState';

export default function admin(state = initialState.adminTemplates, action) {
  switch (action.type) {
    case READ_TEMPLATE:
      return {
        ...state,
        ...action.data,
      };
    case LIST_HTML_BLOCKS:
      return {
        ...state,
        blocks: action.data,
      };
    case READ_HTML_BLOCK:
      return {
        ...state,
        block: action.data,
      };
    case CLEAR_HTML_BLOCK:
      return {
        ...state,
        block: {},
      };
    case DELETE_HTML_BLOCK:
      return {
        ...state,
        blocks: state.blocks.filter(({ uuid }) => uuid !== action.data),
      };
    case RENDER_EMAIL:
      return {
        ...state,
        parsedTemplate: { ...action.data },
      };
    case PUBLISH_TEMPLATE:
      return {
        ...state,
        ...action.data,
      };
    case SAVE_TEMPLATE:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
}
