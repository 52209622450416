import { cancelCmrOrderAction } from 'actions/recycle';
import { closeModalAction, openModalAction } from 'actions/ui';
import print from 'assets/images/print.svg';
import trackPage from 'enhancers/trackPage';
import { currencyFormatter } from 'helpers/formatters';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Modal from 'components/Modal';
import RenderDetails from 'components/RenderDetails';
import Section from 'components/Section';
import Stakeholders from 'components/Stakeholders';
import DevicesSummary from 'components/recycle/DevicesSummary';

import { ACTIVE_STATUS } from 'constants/contracts';
import { config } from 'constants/dl';
import { TRADE_IN } from 'constants/recycle';

import OutcomesHeader from './OutcomesHeader';
import './Receipt.styl';

class Receipt extends Component {
  state = { cancelAmountReceived: false };

  openCancelModal = () => this.props.openModal('cancelTradeInQuote');

  toggleAmountReceived = () =>
    this.setState({
      cancelAmountReceived: !this.state.cancelAmountReceived,
    });

  cancelReceipt = () => {
    const { cancelCmrOrder, tradecartID, closeModal } = this.props;

    cancelCmrOrder(tradecartID);
    closeModal();
  };

  render() {
    const {
      currency,
      customer,
      devices,
      expiredDate,
      language,
      orderDate,
      status,
      source,
      store,
      toggleReceiptPrint,
      quoteID,
      tradecartID,
      totalValue,
      totalSwapCost,
    } = this.props;

    const isNoDeviceInCollection = () =>
      !devices.some((device) => device.inCollection);

    const isCancelable = () =>
      status === ACTIVE_STATUS &&
      quoteID &&
      source === TRADE_IN &&
      isNoDeviceInCollection();

    return (
      <div className='receipt'>
        <Section>
          <div className='header'>
            <h1>
              <Trans id='receipt.title'>Quote</Trans>{' '}
              {`${quoteID ? quoteID : tradecartID}`}
            </h1>

            <RenderDetails
              name='receipt-dates'
              classes='inline dates'
              config={config.datesTradeIn}
              data={{
                orderDate: orderDate,
                expiredDate: expiredDate,
              }}
            />

            <OutcomesHeader />
          </div>

          <Stakeholders customer={customer} store={store} />

          <div className='body'>
            {isCancelable() && (
              <Button classes='btn-cancel red' onClick={this.openCancelModal}>
                <Trans id='receipt.cancel.quote'>Cancel return</Trans>
              </Button>
            )}
            <Button classes='btn-print' onClick={toggleReceiptPrint}>
              <img src={print} alt='print' />
            </Button>

            <DevicesSummary
              currency={currency}
              language={language}
              totalValue={totalValue}
              totalSwapCost={totalSwapCost}
              devices={devices}
              source={source}
            />
          </div>
        </Section>

        <Modal
          name='cancelTradeInQuote'
          classes=''
          title={
            <Trans id='modal.title.cancel.quote'>
              Quote {quoteID} cancellation
            </Trans>
          }
        >
          <p className='receipt__cancel-modal-body'>
            <Trans id='modal.body.cancel.quote'>
              To cancel this quote, the client must return the quote value of
              {currencyFormatter(totalValue, currency, language)}{' '}
            </Trans>
            <br />
            <Trans id='modal.body.disclaimer.cancel.quote'>
              Please make sure you receive the correct amount before ticking the
              checkbox
            </Trans>
          </p>
          <div className='receipt__cancel-modal-actions'>
            <Checkbox
              id='quoteCancellationToggle'
              checked={this.state.cancelAmountReceived}
              classes='deliveryAddressToggle'
              onChange={this.toggleAmountReceived}
              label={t({
                id: 'modal.cancel.quote.toggle',
                message: `I have received the correct amount of the quote value`,
              })}
            />

            <Button
              classes='btn-cancel red'
              disabled={!this.state.cancelAmountReceived}
              onClick={this.cancelReceipt}
            >
              <Trans id='receipt.cancel.quote'>Cancel return</Trans>
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default connect(
  ({ recycle, whitelabel }) => ({
    customer: recycle.receipt?.customer,
    devices: recycle.receipt?.devices,
    expiredDate: recycle.receipt?.expiredDate,
    orderDate: recycle.receipt?.orderDate,
    quoteID: recycle.receipt?.quoteID,
    status: recycle.receipt?.status,
    store: recycle.receipt?.store,
    source: recycle.receipt?.source,
    totalValue: recycle.receipt?.totalValue,
    totalSwapCost: recycle.receipt?.totalSwapCost,
    currency: whitelabel.currency,
    language: whitelabel.language,
  }),
  {
    cancelCmrOrder: cancelCmrOrderAction,
    openModal: openModalAction,
    closeModal: closeModalAction,
  },
)(trackPage(Receipt), 'RecycleReceipt');
