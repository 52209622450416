import React from 'react';

import { Trans } from '@lingui/macro';

import Modal from 'components/Modal';

const UpgradeUnavailable = () => {
  return (
    <Modal name='upgradeUnavailable'>
      <h2>
        <Trans id='sale.no.upgrade'>No Upgrade available</Trans>
      </h2>

      <p>
        <Trans id='sale.no.upgrade.on.date'>
          The contract cannot be upgraded at this current date.
        </Trans>
      </p>
    </Modal>
  );
};

export default UpgradeUnavailable;
