import { deleteBlockAction, listBlocksAction } from 'actions/admin/templates';
import { openModalAction } from 'actions/ui';

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';

import Button from 'components/Button';
import Modal from 'components/Modal';
import Table from 'components/table/Table';

import { adminTemplateBlockEditorURL } from 'constants/routes';

export const Blocks = ({ blocks = [], openModal, deleteBlock, listBlocks }) => {
  const navigate = useNavigate();

  const [blockUUID, setBlockUUID] = useState();

  useEffect(() => {
    listBlocks();
  }, [listBlocks]);

  const columns = [
    {
      label: t({ id: 'table.column.blocks.key', message: `Block Key` }),
      accessor: 'key',
      type: 'text',
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.blocks.desc',
        message: `Block Description`,
      }),
      accessor: 'description',
      type: 'text',
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.campaign.actions', message: `Actions` }),
      accessor: 'actions',
      type: 'actions',
      filterable: false,
      sortable: false,
      align: 'right',
      wrap: false,
    },
  ];

  const actions = [
    {
      label: t({ id: 'table.column.actions.edit', message: `Edit` }),
      handler: (_, { uuid }) => {
        navigate(adminTemplateBlockEditorURL(uuid));
      },
    },
    {
      label: t({ id: 'table.column.actions.delete', message: `Delete` }),
      handler: (_, { uuid }) => {
        setBlockUUID(uuid);
        openModal('delete-block');
      },
    },
  ];

  const rows = blocks?.map((block) => ({ ...block, actions }));

  return (
    <Fragment>
      <h1 className='admin-page-title'>
        <Trans id='blocks.title'>Available HTML template blocks</Trans>
      </h1>

      <Button
        // TODO make this component reliant only on hooks
        // in order to remove arrow functions and enforce this rule
        // eslint-disable-next-line react/jsx-no-bind
        onClick={() => navigate(adminTemplateBlockEditorURL('new'))}
        testSelector='new-block-button'
      >
        <Trans id='blocks.button.new'>New Block</Trans>
      </Button>

      <Table
        columns={columns}
        data={rows}
        keyColumn='id'
        defaultOrder='id'
        defaultDir='desc'
      />

      <Modal
        name='delete-block'
        // eslint-disable-next-line react/jsx-no-bind
        onConfirm={() => {
          deleteBlock(blockUUID);
        }}
      >
        <Trans id='blocks.delete.block.confirmation'>
          Are you sure you want to delete this HTML block?
        </Trans>
      </Modal>
    </Fragment>
  );
};

export default connect(
  ({ whitelabel, adminTemplates }) => ({
    currency: whitelabel.currency,
    blocks: adminTemplates.blocks,
    language: whitelabel.language,
  }),
  {
    deleteBlock: deleteBlockAction,
    listBlocks: listBlocksAction,
    openModal: openModalAction,
  },
)(Blocks);
