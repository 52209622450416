import classNames from 'classnames';

import React from 'react';

const RenderTextAreaField = ({
  disabled,
  input,
  fieldName = '',
  placeholder = '...',
  className,
  rows,
  cols,
  meta: { touched, error, warning },
}) => {
  return (
    <div className={classNames('input__wrapper', className)}>
      <textarea
        {...input}
        placeholder={placeholder}
        rows={rows}
        cols={cols}
        disabled={disabled}
      />

      {touched &&
        ((error && (
          <p className='input__input-helper input__input-helper--error'>
            <span>
              {fieldName} {error}
            </span>
          </p>
        )) ||
          (warning && <p className='warning'>{warning}</p>))}
    </div>
  );
};

RenderTextAreaField.defaultProps = {
  rows: '10',
  cols: '40',
};

export default RenderTextAreaField;
