import classNames from 'classnames';
import PropTypes from 'prop-types';
import useOnClickOutside from 'use-onclickoutside';

import React, { useEffect, useRef, useState } from 'react';

import InputHelperText from 'components/InputHelperText';
import IconCaret from 'components/icons/IconCaret';
import IconChecked from 'components/icons/IconChecked';
import IconClose from 'components/icons/IconClose';

import {
  DOWNCASE_A_KEYCODE,
  DOWNCASE_Z_KEYCODE,
  DOWN_ARROW_KEYCODE,
  ENTER_KEYCODE,
  ESCAPE_KEYCODE,
  SPACEBAR_KEYCODE,
  TAB_KEYCODE,
  UP_ARROW_KEYCODE,
} from 'constants/keyboard';

import './Dropdown.styl';

const WRAPPER_CLASS = 'input__wrapper';
const DROPDOWN_CLASS = 'dropdown';
const LIST_CLASS = 'dropdown-list';
const LIST_ITEM_CLASS = 'dropdown-list__item';
const LIST_ITEM_ACTIVE_CLASS = `${LIST_ITEM_CLASS}--active`;
const LABEL_CLASS = 'dropdown__floating-label';
const INPUT_CLASS = 'dropdown-input';

const variants = {
  'no-radius': `${DROPDOWN_CLASS}--no-radius`,
  underline: `${DROPDOWN_CLASS}--underline`,
  rounded: `${DROPDOWN_CLASS}--rounded`,
};
const sizes = {
  medium: `${DROPDOWN_CLASS}--medium-height`,
  large: `${DROPDOWN_CLASS}--large-height`,
};
const widths = {
  half: `${DROPDOWN_CLASS}--half-width`,
  third: `${DROPDOWN_CLASS}--third-width`,
};
const anchors = {
  left: `${DROPDOWN_CLASS}--left-anchor`,
  right: `${DROPDOWN_CLASS}--right-anchor`,
};

export const Dropdown = ({
  classes = '',
  clearable = false,
  disabled = false,
  helpText = '',
  input = {},
  labelType = '',
  meta: { active, error, touched } = {},
  noOptionsLabel = '',
  notificationPos = 'bottom',
  options = [],
  placeholder = '',
  placeholderExtra = '',
  size = 'medium',
  anchor = 'auto',
  testSelector = '',
  type = '',
  multiplePlaceholder = '',
  variant = '',
  width = 'full',
}) => {
  const dropRef = useRef(null);
  const listRef = useRef(null);
  const [filter, setFilter] = useState([]);
  const [elapsed, setElapsed] = useState(null);
  const [listOpen, setListOpen] = useState(false);
  const showError = !active && touched && error;

  useEffect(() => {
    const activeIndex = Array.from(listRef.current.children).findIndex((item) =>
      item.dataset.label
        ?.toLowerCase()
        .startsWith(String.fromCharCode(...filter).toLowerCase()),
    );
    const length = Array.from(listRef.current.children).length;

    if (activeIndex >= 0 && activeIndex < length) {
      clearActiveItems();
      listRef.current.children[activeIndex].classList.add(
        LIST_ITEM_ACTIVE_CLASS,
      );
    }
    setElapsed(Date.now());
  }, [filter]);

  useEffect(() => {
    const listEl = listRef.current;
    const handleScroll = (evt) => {
      if (
        (listEl.scrollTop <= 0 && evt.deltaY < 0) ||
        (Math.ceil(listEl.scrollTop) >=
          listEl.scrollHeight - listEl.clientHeight - 1 &&
          evt.deltaY > 0)
      ) {
        evt.preventDefault();
      }
    };

    listEl.addEventListener('wheel', handleScroll);

    return () => {
      listEl.removeEventListener('wheel', handleScroll);
    };
  }, []);

  const openList = () => {
    if (!disabled) {
      input.onFocus();
    }
  };

  const closeList = () => {
    if (!disabled) {
      enableMouse();
      setListOpen(false);
      input.onBlur();
    }
  };

  useOnClickOutside(dropRef, closeList);

  const toggleVisibility = () => {
    if (!disabled) setListOpen(!listOpen);
  };

  const isListOpenTop = () => {
    const dropEl = dropRef.current?.getBoundingClientRect() || { bottom: 0 };
    const listEl = listRef.current?.getBoundingClientRect() || { height: 0 };
    return dropEl.bottom + listEl.height > window.innerHeight;
  };

  const getAnchorKey = (anchor) => {
    if (anchor !== 'auto') {
      return anchor;
    }

    const dropEl = dropRef.current?.getBoundingClientRect() || { left: 0 };
    const listEl = listRef.current?.getBoundingClientRect() || { width: 0 };
    return dropEl.left + listEl.width > window.innerWidth ? 'right' : 'left';
  };

  const disableMouse = () => {
    listRef.current.classList.add(`${LIST_CLASS}--mouse-disabled`);
  };

  const enableMouse = () => {
    listRef.current.classList.remove(`${LIST_CLASS}--mouse-disabled`);
  };

  const isMouseDisabled = () => {
    return listRef.current.classList.contains(`${LIST_CLASS}--mouse-disabled`);
  };

  const isMultiple = () => {
    return type === 'select-multiple';
  };

  const isSelected = (option) => {
    if (isMultiple()) {
      return (
        input.value.length > 0 &&
        input.value.findIndex((it) => it === option.value) > -1
      );
    } else {
      return input.value === option.value;
    }
  };

  const handleListClick = (evt) => {
    if (isMouseDisabled()) {
      enableMouse();
    } else {
      toggleVisibility();
    }
  };

  const handleItemClick = (item) => {
    if (!disabled) {
      change(item.value);
      !isMultiple() && closeList();
    }
  };

  const hasValue = () => {
    return isMultiple()
      ? input.value?.length > 0
      : ![undefined, null, ''].includes(input.value);
  };

  const change = (newVal) => {
    if (isMultiple()) {
      input.onChange(
        input.value.includes(newVal)
          ? input.value.filter((it) => it !== newVal)
          : [...input.value, newVal],
      );
    } else {
      input.onChange(newVal);
    }
  };

  const clearActiveItems = () => {
    Array.from(listRef.current.children).map((item) =>
      item.classList.remove(LIST_ITEM_ACTIVE_CLASS),
    );
  };

  const clearSelected = (e) => {
    e.preventDefault();
    e.stopPropagation();
    input.onChange(isMultiple() ? [] : null);
  };

  const changeActiveItemToNext = (direction) => {
    const activeIndex = Array.from(listRef.current.children).findIndex((item) =>
      item.classList.contains(LIST_ITEM_ACTIVE_CLASS),
    );
    const length = Array.from(listRef.current.children).length;

    if (activeIndex === -1) {
      changeActiveItem(0, false);
    } else if (activeIndex === 0 && direction === UP_ARROW_KEYCODE) {
      changeActiveItem(length - 1, true);
    } else if (activeIndex >= 0) {
      const nextIndex =
        direction === DOWN_ARROW_KEYCODE
          ? (activeIndex + 1) % length
          : (activeIndex - 1) % length;

      changeActiveItem(nextIndex, true);
    }
  };

  const changeActiveItem = (index, clear = true) => {
    const parent = listRef.current;
    const item = parent.children[index];

    if (item) {
      if (clear) clearActiveItems();
      item.classList.add(LIST_ITEM_ACTIVE_CLASS);

      if (typeof item.scrollIntoView === 'function') {
        try {
          item.scrollIntoView({ block: 'nearest' });
        } catch {
          item.scrollIntoView(false);
        }
      }
    }
  };

  const handleKeyPresses = (evt) => {
    if (!disabled) {
      if (
        evt.keyCode === UP_ARROW_KEYCODE ||
        evt.keyCode === DOWN_ARROW_KEYCODE
      ) {
        evt.preventDefault();
        disableMouse();

        changeActiveItemToNext(evt.keyCode);
      }

      if (evt.keyCode === ENTER_KEYCODE) {
        const item = Array.from(listRef.current.children).find((item) =>
          item.classList.contains(LIST_ITEM_ACTIVE_CLASS),
        );

        item &&
          listOpen &&
          change(
            options.find(({ label }) => label === item.dataset.label)?.value,
          );

        toggleVisibility();
      }

      if (evt.keyCode === ESCAPE_KEYCODE) {
        toggleVisibility();
      }

      if (evt.keyCode === TAB_KEYCODE) {
        closeList();
      }

      if (SPACEBAR_KEYCODE.includes(evt.keyCode)) {
        evt.preventDefault();

        toggleVisibility();
      }

      if (
        evt.keyCode >= DOWNCASE_A_KEYCODE &&
        evt.keyCode <= DOWNCASE_Z_KEYCODE
      ) {
        Date.now() - elapsed < 500
          ? setFilter([...filter, evt.keyCode])
          : setFilter([evt.keyCode]);
      }
    }
  };

  return (
    <div className={classNames(WRAPPER_CLASS, classes)}>
      <div
        ref={dropRef}
        // TODO: refactor component to be a class component or solely
        // dependent on hooks in order to be able to enforce this rule
        // eslint-disable-next-line react/jsx-no-bind
        onFocus={openList}
        // eslint-disable-next-line react/jsx-no-bind
        onClick={handleListClick}
        // eslint-disable-next-line react/jsx-no-bind
        onKeyDown={handleKeyPresses}
        // eslint-disable-next-line react/jsx-no-bind
        onMouseEnter={enableMouse}
        className={classNames(
          DROPDOWN_CLASS,
          widths[width],
          sizes[size],
          variants[variant],
          anchors[getAnchorKey(anchor)],
          {
            [`${DROPDOWN_CLASS}--disabled`]: disabled,
            [`${DROPDOWN_CLASS}--clearable`]: clearable,
            [`${DROPDOWN_CLASS}--multiple`]: isMultiple(),
          },
        )}
        tabIndex='0'
        data-test={testSelector}
      >
        <label
          className={classNames(LABEL_CLASS, {
            [`${LABEL_CLASS}--active`]: active,
            [`${LABEL_CLASS}--has-value`]: hasValue(),
            [`${LABEL_CLASS}--hidden`]: labelType === 'hidden',
            [`${LABEL_CLASS}--outside`]: labelType === 'outside',
            [`${LABEL_CLASS}--error`]: showError,
            [`${LABEL_CLASS}--disabled`]: disabled,
          })}
        >
          {placeholder}
          {placeholderExtra}
        </label>

        <div
          className={classNames(INPUT_CLASS, {
            [`${INPUT_CLASS}--focus`]: active,
            [`${INPUT_CLASS}--fixed`]: ['outside', 'hidden'].includes(
              labelType,
            ),
            [`${INPUT_CLASS}--error`]: showError,
            [`${INPUT_CLASS}--disabled`]: disabled,
          })}
        >
          {hasValue() &&
            (isMultiple()
              ? multiplePlaceholder
                  .replace('XX', input.value.length)
                  .replace('YY', options.length)
              : options.find(({ value }) => value === input.value)?.label)}

          {clearable && hasValue() && (
            <IconClose
              className={`${INPUT_CLASS}__icon ${INPUT_CLASS}__icon-clear`}
              // eslint-disable-next-line react/jsx-no-bind
              onClick={clearSelected}
            />
          )}
          <IconCaret
            className={classNames(`${INPUT_CLASS}__icon`, {
              [`${INPUT_CLASS}__icon--expanded`]: listOpen,
            })}
          />
        </div>

        <ul
          className={classNames(LIST_CLASS, {
            [`${LIST_CLASS}--open`]: listOpen,
            [`${LIST_CLASS}--open-top`]: isListOpenTop(),
            [`${LIST_CLASS}--disabled`]: disabled,
          })}
          ref={listRef}
          tabIndex='-1'
        >
          {options.length === 0 ? (
            <li className={LIST_ITEM_CLASS}>{noOptionsLabel || '-'}</li>
          ) : (
            options.map((option, index) => {
              return (
                <li
                  tabIndex='-1'
                  key={option.label}
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={(evt) => {
                    handleItemClick(option);
                    evt.stopPropagation();
                  }}
                  // eslint-disable-next-line react/jsx-no-bind
                  onMouseEnter={() => changeActiveItem(index, true)}
                  className={classNames(LIST_ITEM_CLASS, {
                    [`${LIST_ITEM_CLASS}--selected`]: isSelected(option),
                  })}
                  data-test-val={option.value}
                  data-label={option.label}
                >
                  {option.label}
                  {isSelected(option) && <IconChecked />}
                </li>
              );
            })
          )}
        </ul>
      </div>
      <InputHelperText
        showError={showError}
        helpText={helpText}
        errorText={error}
        notificationPos={notificationPos}
        inputName={placeholder}
      />
    </div>
  );
};

Dropdown.propTypes = {
  classes: PropTypes.string,
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  helpText: PropTypes.string,
  input: PropTypes.object.isRequired,
  labelType: PropTypes.oneOf(['', 'hidden', 'outside']),
  meta: PropTypes.shape({
    active: PropTypes.bool,
    error: PropTypes.string,
    touched: PropTypes.bool,
  }).isRequired,
  multiplePlaceholder: PropTypes.string,
  noOptionsLabel: PropTypes.string,
  notificationPos: PropTypes.oneOf(['bottom', 'side']),
  options: PropTypes.array,
  placeholder: PropTypes.string,
  placeholderExtra: PropTypes.string,
  size: PropTypes.oneOf(['medium', 'large']),
  testSelector: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.oneOf(['', 'no-radius', 'underline', 'rounded']),
  width: PropTypes.oneOf(['full', 'half', 'third']),
  anchor: PropTypes.oneOf(['auto', 'left', 'right']),
};

export default Dropdown;
