import { t } from '@lingui/macro';

export const customizationLabels = (ctoType = '') => {
  const rules = {
    memory: t({ id: 'customization.label.memory', message: `Memory` }),
    color: t({ id: 'customization.label.color', message: `Colour` }),
    screen: t({ id: 'customization.label.screen', message: `Screen` }),
    cpu: t({ id: 'customization.label.cpu', message: `Cpu` }),
    storage: t({ id: 'customization.label.storage', message: `Storage` }),
    connectivity: t({
      id: 'customization.label.connectivity',
      message: `Connectivity`,
    }),
    graphics: t({ id: 'customization.label.graphics', message: `Graphics` }),
    keyboard: t({ id: 'customization.label.keyboard', message: `Keyboard` }),
    power: t({ id: 'customization.label.power', message: `Power` }),
    touchbar: t({ id: 'customization.label.touchbar', message: `Touchbar` }),
    mouse: t({ id: 'customization.label.mouse', message: `Mouse` }),
    vesamount: t({ id: 'customization.label.vesamount', message: `Vesamount` }),
    ethernet: t({ id: 'customization.label.ethernet', message: `Ethernet` }),
    spec: t({ id: 'customization.label.spec', message: `Spec` }),
    glass: t({ id: 'customization.label.glass', message: `Glass` }),
    movability: t({
      id: 'customization.label.movability',
      message: `Movability`,
    }),
    afterburner: t({
      id: 'customization.label.afterburner',
      message: `Afterburner`,
    }),
    racktower: t({ id: 'customization.label.racktower', message: `Racktower` }),
    neural_engine: t({
      id: 'customization.label.neural.engine',
      message: `Neural engine`,
    }),
    gigabit: t({ id: 'customization.label.gigabit', message: `Gigabit` }),
    adaptor: t({ id: 'customization.label.adaptor', message: `Power adapter` }),
  };

  return rules[`${ctoType}`.toLowerCase()] || ctoType;
};
