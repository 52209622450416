import classNames from 'classnames';
import { required } from 'helpers/validators';
import { validateDeviceReference } from 'helpers/validators';
import { format, length } from 'helpers/validators';
import { Field, getFormValues, reduxForm } from 'redux-form';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import Badges from 'components/Badges';
import Button from 'components/Button';
import RadioButtons from 'components/RadioButtons';
import RenderField from 'components/RenderField';

import { IMEI, SERIAL } from 'constants/deviceReference';

import './DeviceReferenceForm.styl';
import HowToFindIMEI from './HowToFindIMEI';

class DeviceReferenceForm extends Component {
  referenceTypeFieldRender = ({ input, disabled, referenceType }) => {
    const { onChange } = input;
    const clickHandler = ({ value }) => onChange(value);

    return (
      <RadioButtons
        disabled={disabled}
        options={[
          {
            key: 'imei',
            value: IMEI,
            label: t({
              id: 'button.label.reference.type.imei',
              message: `IMEI`,
            }),
          },
          {
            key: 'serial',
            value: SERIAL,
            label: t({
              id: 'button.label.reference.type.serial',
              message: `Serial Number`,
            }),
          },
        ]}
        selectedValue={referenceType}
        onClick={clickHandler}
      />
    );
  };

  existsDeviceReference = (value) =>
    this.props.existingDeviceReferences?.includes(value) ? (
      <Trans id='error.message.exists.device.reference'>
        The inserted device reference is duplicate. Please use another one.
      </Trans>
    ) : undefined;

  render() {
    const {
      id,
      disabled = false,
      disableReferenceTypeField = false,
      deviceReference,
      initialreferenceType = IMEI,
      invalid,
      formValues: { referenceType } = { referenceType: initialreferenceType },
      handleSubmit,
      customIMEILabel = null,
      customSerialLabel = null,
      submitting,
      showRefHelper = false,
      submitted = false,
    } = this.props;

    return (
      <form
        id={id}
        className={classNames('deviceReferenceForm', {
          'deviceReferenceForm--has-helper': showRefHelper,
        })}
      >
        {!submitted && (
          <Field
            disabled={disableReferenceTypeField}
            name='referenceType'
            component={this.referenceTypeFieldRender}
            props={{
              disabled: disableReferenceTypeField
                ? disableReferenceTypeField
                : disabled,
              referenceType,
            }}
          />
        )}

        <Field
          name='deviceReference'
          type='text'
          className={classNames('deviceReferenceForm__input', {
            'deviceReferenceForm__input--disabled': disabled,
          })}
          component={RenderField}
          disabled={disabled}
          initialValue={deviceReference}
          validate={[
            required(),
            format({
              without: /#/g,
              message: t({
                id: 'form.hashtag.error',
                message: `# isn't allowed.`,
              }),
            }),
            length({ max: 50 }),
            this.existsDeviceReference,
          ]}
          animateLabel={false}
          label={
            referenceType === IMEI
              ? customIMEILabel ||
                t({
                  id: 'device.reference.form.imei.placeholder',
                  message: `Insert IMEI`,
                })
              : customSerialLabel ||
                t({
                  id: 'device.reference.form.serial.placeholder',
                  message: `Insert Serial Number`,
                })
          }
          testSelector='posalePhoneSerialNumField'
        />

        {submitted ? (
          <Badges
            badges={{
              classes: 'device-reference__badge--valid',
              label: t({
                id: 'deviceReference.form.submitted.badge',
                message: `OK`,
              }),
            }}
          />
        ) : (
          <Button
            classes='device-reference__button'
            type='submit'
            disabled={invalid || submitting || disabled}
            loading={submitting}
            testSelector='submit-button'
            onClick={handleSubmit}
          >
            <Trans id='deviceReference.form.submit.button'>Submit</Trans>
          </Button>
        )}

        {showRefHelper && <HowToFindIMEI />}
      </form>
    );
  }
}

DeviceReferenceForm = reduxForm({
  enableReinitialize: true,
  validate: (
    { referenceType, deviceReference },
    { useAppleValidation = false },
  ) =>
    validateDeviceReference(
      deviceReference,
      referenceType === IMEI,
      useAppleValidation,
    ),
})(DeviceReferenceForm);

export default connect(
  (
    state,
    {
      form = 'deviceReferenceForm',
      initialreferenceType = IMEI,
      deviceReference = '',
      referenceType = initialreferenceType,
    },
  ) => ({
    form: form,
    initialValues: { referenceType, deviceReference },
    formValues: getFormValues(form)(state),
  }),
  null,
)(DeviceReferenceForm);
