import * as React from 'react';

function SvgIconExclamation({ title, titleId, ...props }) {
  return (
    <svg
      fillRule='evenodd'
      strokeLinejoin='round'
      strokeMiterlimit={1.414}
      clipRule='evenodd'
      viewBox='0 0 48 48'
      width='1em'
      height='1em'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill='currentColor'
        fillRule='nonzero'
        d='M24.184 32.936c1.6.127 2.867 1.349 2.902 3.08v.054c-.055 2.738-4.483 4.297-5.881 1.307a3.222 3.222 0 01-.291-1.307v-.054c.034-1.692 1.33-3.085 3.147-3.085l.123.005zm0-23.931c1.6.127 2.867 1.35 2.902 3.08v12.343c-.055 2.738-4.483 4.297-5.881 1.307a3.222 3.222 0 01-.291-1.307V12.085C20.948 10.393 22.244 9 24.061 9l.123.005z'
      />
    </svg>
  );
}

export default SvgIconExclamation;
