import { UserDTO } from 'DTOs/admin';
import {
  addNotificationAction,
  closeModalAction,
  hideLoaderAction,
  showLoaderAction,
} from 'actions/ui';
import { logoutAction } from 'actions/user';
import { adminPost as post } from 'apis/v2';

import {
  adminResetPasswordSuccess,
  recoverPasswordSuccess,
  userAddSuccess,
  userEditSuccess,
} from 'constants/notifications';

export const GET_USER = 'GET_USER';
export function getUserAction(userID) {
  return async function (dispatch) {
    dispatch(showLoaderAction());

    const {
      data: { status, data },
    } = await post(GET_USER, { userID });

    if (status === 'SUCCESS') {
      dispatch(hideLoaderAction());

      return UserDTO.parse(data.user).toModel();
    }

    dispatch(hideLoaderAction());
  };
}

export const LIST_USERS = 'LIST_USERS';
export function listUsersAction() {
  return async function (dispatch) {
    dispatch(showLoaderAction());

    // TODO move this destructuring to api layer?
    const {
      data: { status, data },
    } = await post(LIST_USERS);

    if (status === 'SUCCESS') {
      dispatch({
        type: LIST_USERS,
        data: data.users.map((userRaw) => UserDTO.parse(userRaw).toModel()),
      });
    }

    dispatch(hideLoaderAction());
  };
}

export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export function upsertUserAction(user) {
  return async function (dispatch) {
    dispatch(showLoaderAction());

    const dto = UserDTO.fromModel(user);

    // TODO move this destructuring to api layer?
    const {
      data: { status },
    } = dto.id
      ? await post(UPDATE_USER, { user: dto.toJSON() })
      : await post(CREATE_USER, { user: dto.toJSON() });

    if (status === 'SUCCESS') {
      dispatch(closeModalAction());
      await dispatch(listUsersAction());
      dispatch(
        addNotificationAction(dto.id ? userEditSuccess() : userAddSuccess()),
      );
    }

    dispatch(hideLoaderAction());
  };
}

export const RESET_USER_PASSWORD = 'RESET_USER_PASSWORD';
export function resetUserPasswordAction(id, email) {
  return async function (dispatch, getState) {
    dispatch(showLoaderAction());

    // TODO move this destructuring to api layer?
    const {
      data: { status },
    } = await post(RESET_USER_PASSWORD, { userID: id });

    if (status === 'SUCCESS') {
      if (email === getState().user.email) {
        dispatch(logoutAction());
        dispatch(addNotificationAction(recoverPasswordSuccess()));
      } else {
        await dispatch(listUsersAction());
        dispatch(addNotificationAction(adminResetPasswordSuccess()));
      }
    }

    dispatch(hideLoaderAction());
  };
}

export const BLOCK_USER = 'BLOCK_USER';
export function blockUserAction(id) {
  return async function (dispatch) {
    dispatch(showLoaderAction());

    // TODO move this destructuring to api layer?
    const {
      data: { status },
    } = await post(BLOCK_USER, { userID: id });

    if (status === 'SUCCESS') {
      await dispatch(listUsersAction());
    }

    dispatch(hideLoaderAction());
  };
}

export const UNBLOCK_USER = 'UNBLOCK_USER';
export function unblockUserAction(id) {
  return async function (dispatch) {
    dispatch(showLoaderAction());

    // TODO move this destructuring to api layer?
    const {
      data: { status },
    } = await post(UNBLOCK_USER, { userID: id });

    if (status === 'SUCCESS') {
      await dispatch(listUsersAction());
    }

    dispatch(hideLoaderAction());
  };
}
