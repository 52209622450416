import { addDays } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import { capitalize, snakeToText } from 'utils';

import React from 'react';

import { Trans, t } from '@lingui/macro';

import Badges from 'components/Badges';
import RenderDetails from 'components/RenderDetails';
import IconAlert from 'components/icons/IconAlert';
import IconApproved from 'components/icons/IconApproved';

import { ACTIVE_STATUS } from 'constants/contracts';
import { config } from 'constants/dl';
import { contractStatus } from 'constants/status';

import ContractActions from './ContractActions';
import './ContractDetails.styl';
import Timeline from './Timeline';

const ContractDetails = ({
  actionable,
  cartUUID,
  contract,
  funder,
  language,
}) => {
  const {
    startDate: contractStartDate,
    earlySwapStartDate: contractEarlySwapStartDate,
    freeSwapStartDate: contractFreeSwapStartDate,
  } = contract.dates;
  const startDate = contractStartDate && new Date(contractStartDate);
  const earlySwapStartDate =
    contractEarlySwapStartDate && new Date(contractEarlySwapStartDate);
  const freeSwapStartDate =
    contractFreeSwapStartDate && new Date(contractFreeSwapStartDate);

  const orderStatus = contractStatus()[contract.status];

  const standardFinancingConfig = () =>
    contract.financing?.openingFeePercentage > 0
      ? config.financingWithUpfront(contract.financing)
      : config.financing(contract.financing);

  const b2bFinancingConfig = () => config.b2bfinancing(contract.financing);

  return (
    <div className='contract-details'>
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
      <a name={contract.funderReference} />

      <h2 className='contract-title' data-test='posale-contract-details-title'>
        <Trans id='sale.contract.details.title'>
          {`Contract ${contract.funderReference} - ${contract.financing.payment_plan_name}`}
        </Trans>

        <span
          className='contract-status'
          data-test='posale-contract-details-orderStatus'
        >
          <Badges
            badges={{
              classes: `sale-page__badge sale-page__badge--${orderStatus.status}`,
              icon:
                contract.status === ACTIVE_STATUS ? (
                  <IconApproved />
                ) : (
                  <IconAlert />
                ),
              label: capitalize(orderStatus.label),
            }}
          />
        </span>

        {contract.status === ACTIVE_STATUS &&
          contract.statusLabel !== 'ACTIVE' && (
            <span
              className='contract-status'
              data-test='posale-contract-details-contractStatus'
            >
              <Badges
                badges={{
                  classes: 'sale-page__badge',
                  icon: <IconAlert />,
                  label:
                    contractStatus()[contract.statusLabel]?.label ||
                    snakeToText(contract.statusLabel),
                }}
              />
            </span>
          )}
      </h2>

      {actionable && (
        <ContractActions
          cartUUID={cartUUID}
          funder={funder}
          contract={contract}
        />
      )}

      {contract.status === ACTIVE_STATUS &&
        freeSwapStartDate &&
        contract.financing.fixed_period_end_month !==
          contract.financing.flex_period_end_month && (
          <Timeline
            start={startDate}
            withdrawPeriod={contract.withdrawPeriod}
            withdraw={addDays(startDate, contract.withdrawPeriod)}
            earlyUpgrade={earlySwapStartDate}
            hasEarlyUpgrade={contract.earlySwapMonth !== -1}
            freeUpgrade={freeSwapStartDate}
            end={new Date(contract.dates.endDate)}
            language={language}
          />
        )}

      <div className='contract-specs'>
        <div className='specs-block'>
          {!isEmpty(contract.products) && (
            <h3
              className='details-title'
              data-test='posale-contract-product-details-title'
            >
              <Trans id='sale.contract.details.products'>Product Details</Trans>
            </h3>
          )}

          {contract.products.map((product, index) => (
            <RenderDetails
              config={config.products(
                contract.financing.fixed_period_end_month,
                product.fulfillmentStatus === 'RETURNED',
              )}
              data={product}
              imgURL={product.imgURL}
              key={product.uuid}
              name={`details-${product.partNumber}`}
              testSelector={`posale-contract-product-details-${index}-`}
            />
          ))}

          {!isEmpty(contract.extras) && (
            <h3
              className='details-title'
              data-test='posale-contract-extras-details-title'
            >
              <Trans id='sale.contract.details.extras'>Extras' Details</Trans>
            </h3>
          )}

          {contract.extras.map((extra, index) => (
            <RenderDetails
              config={config.extras(extra.fulfillmentStatus === 'RETURNED')}
              data={extra}
              imgURL={extra.imgURL}
              key={extra.uuid}
              name={`details-${extra.partNumber}`}
              testSelector={`posale-contract-extra-details-${index}-`}
            />
          ))}

          {!isEmpty(contract.discounts) && (
            <h3
              className='details-title'
              data-test='posale-contract-discounts-details-title'
            >
              <Trans id='sale.contract.details.discounts'>Discounts</Trans>
            </h3>
          )}

          <RenderDetails
            config={config.discounts}
            data={contract.discounts.reduce(
              (acc, cur) => ({
                ...acc,
                [cur.type]: (acc[cur.type] || 0) + cur.value,
              }),
              {},
            )}
            name={`details-${contract.uuid}`}
            testSelector='posale-contract-discounts-details-'
          />
        </div>

        <div className='specs-block'>
          <RenderDetails
            config={
              contract.financingType === 'CARD_PAYMENT'
                ? config.financingFullPayment()
                : contract.financingType === 'B2B_LEASE'
                ? b2bFinancingConfig()
                : standardFinancingConfig()
            }
            data={contract.financing}
            name={'details-financing'}
            title={t({
              id: 'sale.contract.details.financing',
              message: `Financing Details`,
            })}
            testSelector='posale-contract-financing-details-'
          />
        </div>
      </div>
    </div>
  );
};

export default ContractDetails;
