import {
  markNotificationAsSentAction,
  removeNotificationAction,
} from 'actions/ui';
import { stringFormatter } from 'helpers/formatters';

import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import Notification from './Notification';
import './Notifications.styl';
import TrackingPixel from './TrackingPixel';

const Notifications = ({
  markNotificationAsSent,
  notifications,
  onlineStoreID,
  pageName,
  posStoreID,
  removeNotification,
  wlID,
}) => {
  const isError = (type) => type === 'error';

  return (
    notifications.length > 0 && (
      <div className='notification-wrapper'>
        {notifications.map(({ id, message, sent, title, type, values }) => {
          return (
            <Fragment>
              <Notification
                key={id}
                message={stringFormatter(message, values)}
                // TODO make this component reliant only on hooks in order
                // to remove arrow functions and enforce this rule
                // eslint-disable-next-line react/jsx-no-bind
                onClose={() => removeNotification(id)}
                // eslint-disable-next-line react/jsx-no-bind
                onOpen={() => markNotificationAsSent(id)}
                title={title}
                type={type}
              />

              {!sent && (
                <TrackingPixel
                  type='errortrack'
                  pageName={pageName}
                  display={isError(type)}
                  wlID={wlID}
                  storeID={onlineStoreID || posStoreID}
                  message={stringFormatter(message, values)}
                />
              )}
            </Fragment>
          );
        })}
      </div>
    )
  );
};

Notifications.defaultProps = {
  notifications: {},
};

export default connect(
  ({ user, ui, whitelabel }) => ({
    posStoreID: user?.selectedStore,
    onlineStoreID: whitelabel?.storeId,
    notifications: ui.notifications,
    pageName: ui.pageName,
    wlID: whitelabel?.wlId,
  }),
  {
    markNotificationAsSent: markNotificationAsSentAction,
    removeNotification: removeNotificationAction,
  },
)(Notifications);
