import unauthFace from 'assets/images/unauth-face.svg';

import React from 'react';

import { Trans } from '@lingui/macro';

import SystemPage from './SystemPage';

const Unauthorized = () => {
  return (
    <SystemPage
      withHeader='true'
      image={unauthFace}
      subtitle={
        <Trans id='unauthorized.title'>
          It looks like you don't have enough permissions to visit this page.
        </Trans>
      }
      backLinkText={
        <Trans id='unauthorized.back.navigation'>Back to previous page</Trans>
      }
    />
  );
};

export default Unauthorized;
