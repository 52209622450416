import { uploadFileAction } from 'actions/admin/accessories';
import {
  createWlDeviceAction,
  disableWlDeviceAction,
  listDevicesAction,
} from 'actions/admin/device';
import { closeModalAction, openModalAction } from 'actions/ui';
import trackPage from 'enhancers/trackPage';

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import Button from 'components/Button';
import Loader from 'components/Loader';
import Modal from 'components/Modal';
import FileUploadModal from 'components/file_upload/FileUploadModal';
import UploadReport from 'components/file_upload/UploadReport';
import Table from 'components/table/Table';

import './Devices.styl';
import DeviceForm from './components/DeviceForm';

class Devices extends Component {
  state = { device: {} };

  componentDidMount() {
    this.props.listDevices();
  }

  addDevice = (_, chosenDevice) =>
    this.setState(
      { device: chosenDevice },
      this.props.openModal('admin-device-add'),
    );

  editDevice = (_, chosenDevice) =>
    this.setState(
      { device: chosenDevice },
      this.props.openModal('admin-device'),
    );

  removeDevice = (_, chosenDevice) =>
    this.setState(
      { device: chosenDevice },
      this.props.openModal('admin-device-delete'),
    );

  render() {
    const {
      createWlDevice,
      canUploadDevicesByCSV,
      closeModal,
      devices,
      loading,
      openModal,
      disableWlDevice,
      uploadFile,
      uploadReport,
    } = this.props;

    const { device } = this.state;

    if (loading) return <Loader type='processing' />;

    const columns = [
      {
        label: t({ id: 'table.column.part.number', message: `Part Number` }),
        accessor: 'deviceCode',
        type: 'text',
        filterable: true,
        sortable: true,
        align: 'center',
        wrap: false,
      },
      {
        label: t({ id: 'table.column.family', message: `Family` }),
        accessor: 'familyName',
        type: 'text',
        filterable: true,
        sortable: true,
        align: 'center',
        wrap: false,
      },
      {
        label: t({ id: 'table.column.color', message: `Colour` }),
        accessor: 'colorName',
        type: 'text',
        filterable: true,
        sortable: true,
        align: 'center',
        wrap: false,
      },
      {
        label: t({ id: 'table.column.storage.name', message: `Storage` }),
        accessor: 'storageName',
        type: 'text',
        filterable: true,
        sortable: true,
        align: 'center',
        wrap: false,
      },
      {
        label: t({ id: 'table.column.screen.size', message: `Screen Size` }),
        accessor: 'screenName',
        type: 'text',
        filterable: true,
        sortable: true,
        align: 'center',
        wrap: false,
      },
      {
        label: t({ id: 'table.column.connectivity', message: `Connectivity` }),
        accessor: 'connectivity',
        type: 'text',
        filterable: true,
        sortable: true,
        align: 'center',
        wrap: false,
      },
      {
        label: t({ id: 'table.column.searchtags', message: `Search Tags` }),
        accessor: 'wlTags',
        type: 'taglist',
        filterable: true,
        sortable: true,
        align: 'center',
        wrap: false,
      },
      {
        label: t({
          id: 'table.column.cash.sale.price',
          message: `Cash Sale Price`,
        }),
        accessor: 'cashPrice',
        type: 'currency',
        filterable: true,
        sortable: true,
        align: 'right',
        wrap: false,
      },
      {
        label: t({ id: 'table.column.msrp', message: `MSRP` }),
        accessor: 'msrp',
        type: 'currency',
        filterable: true,
        sortable: true,
        align: 'right',
        wrap: false,
      },
      {
        label: t({ id: 'table.column.updated', message: `Updated on` }),
        accessor: 'updatedAt',
        type: 'date',
        filterable: true,
        condition: 'after',
        sortable: true,
        align: 'center',
        wrap: false,
      },
      {
        label: t({ id: 'table.column.associated', message: `Associated` }),
        accessor: 'isAssociated',
        type: 'boolean',
        sortable: true,
        align: 'center',
        wrap: false,
      },
      {
        label: t({ id: 'table.column.actions', message: `Actions` }),
        accessor: 'actions',
        type: 'actions',
        filterable: false,
        sortable: false,
        align: 'right',
        wrap: false,
      },
    ];

    const actions = [
      {
        label: t({ id: 'devices.actions.edit', message: `Edit` }),
        handler: this.editDevice,
        testSelector: 'edit-device',
      },
      {
        label: t({ id: 'devices.actions.remove', message: `Remove` }),
        className: 'red',
        handler: this.removeDevice,
        testSelector: 'remove-device',
      },
    ];

    const rows = devices?.map((device) =>
      device.isAssociated
        ? { ...device, actions }
        : {
            ...device,
            actions: [
              {
                label: t({ id: 'devices.actions.add', message: `Add` }),
                handler: this.addDevice,
                testSelector: 'add-device',
              },
            ],
          },
    );

    return (
      <Fragment>
        <h1 className='admin-page-title'>
          <Trans id='devices.title'>Devices</Trans>
        </h1>

        <div className='actions-bar'>
          {canUploadDevicesByCSV && (
            <Fragment>
              <Button
                // TODO make this component reliant only on class functions
                // in order to remove arrow functions and enforce this rule
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => openModal('fileUpload')}
                classes='admin-main-action'
                testSelector='upload-csv'
              >
                <Trans id='admin.upload.csv'>Upload CSV</Trans>
              </Button>

              <FileUploadModal
                type='DEVICES'
                handleUpload={uploadFile}
                closeModal={closeModal}
                accept='.csv'
              />

              <UploadReport report={uploadReport} />
            </Fragment>
          )}
        </div>

        <Table
          columns={columns}
          data={rows}
          keyColumn='deviceCode'
          resetPaginationOnUpdate={true}
        />

        <Modal name='admin-device'>
          <DeviceForm initialValues={device} />
        </Modal>

        <Modal
          name='admin-device-add'
          // eslint-disable-next-line react/jsx-no-bind
          onConfirm={() => {
            createWlDevice(device);
          }}
        >
          <Trans id='device.add.modal.confirmation'>
            Are you sure you want to add the device?
          </Trans>
        </Modal>

        <Modal
          name='admin-device-delete'
          // eslint-disable-next-line react/jsx-no-bind
          onConfirm={() => {
            disableWlDevice(device);
          }}
        >
          <Trans id='device.remove.modal.confirmation'>
            Are you sure you want to remove the device?
          </Trans>
        </Modal>
      </Fragment>
    );
  }
}

export default connect(
  (state) => ({
    canUploadDevicesByCSV: state.whitelabel.configs.canUploadDevicesByCSV,
    currency: state.whitelabel.currency,
    devices: state.adminDevice.devices,
    language: state.whitelabel.language,
    loading: state.ui.loading,
    uploadReport: state.adminAcessories.uploadReport,
  }),
  {
    closeModal: closeModalAction,
    createWlDevice: createWlDeviceAction,
    disableWlDevice: disableWlDeviceAction,
    listDevices: listDevicesAction,
    openModal: openModalAction,
    uploadFile: uploadFileAction,
  },
)(trackPage(Devices, 'Devices'));
