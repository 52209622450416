import { retryCreditCheckAction } from 'actions/contract';
import { openModalAction } from 'actions/ui';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Trans } from '@lingui/macro';

import Modal from 'components/Modal';

import { phoneFormats } from 'constants/locales';

import CustomerInfoForm from './CustomerInfoForm';

export const RetryCreditCheck = ({ funder, cartUUID, country }) => {
  const dispatch = useDispatch();
  const options = useSelector(({ customer }) => customer.options);
  const customerOptions =
    funder === 'expressbank'
      ? [options.find((option) => option.id === 'sms')]
      : options;

  return (
    <div className='resend-button'>
      <p>
        <Trans id='resend.credit'>To resend credit message</Trans>
        {', '}
        <span
          className='resend-button-selector'
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => dispatch(openModalAction('customer-info'))}
        >
          <Trans id='resend.credit.delivery.method'>
            please select delivery method
          </Trans>
        </span>
      </p>

      <Modal name='customer-info'>
        <CustomerInfoForm
          cartUUID={cartUUID}
          // eslint-disable-next-line react/jsx-no-bind
          onSubmit={(values) =>
            dispatch(retryCreditCheckAction({ cartUUID, ...values }))
          }
          initialValues={{
            phone: phoneFormats[country].prefix,
            cartUUID,
            creditCheckOptions: customerOptions,
          }}
        />
      </Modal>
    </div>
  );
};

export default RetryCreditCheck;
