import { applyMiddleware, compose, createStore } from 'redux';
import persistState from 'redux-localstorage';
import thunk from 'redux-thunk';

import root from 'reducers/root';

const enhancer = compose(
  applyMiddleware(thunk),
  persistState([
    'app',
    'adminDevice',
    'carts',
    'catalog',
    'contract',
    'customer',
    'flow',
    'recycle',
    'root',
    'sales',
    'user',
    'whitelabel',
    'form',
  ]),
  ...(window.__REDUX_DEVTOOLS_EXTENSION__
    ? [window.__REDUX_DEVTOOLS_EXTENSION__()]
    : []),
);

const store = createStore(root, {}, enhancer);

export default store;
