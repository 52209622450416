import classNames from 'classnames';

import React from 'react';
import { connect } from 'react-redux';

import Footer from 'components/Footer';
import LanguageSwitcher from 'components/LanguageSwitcher';
import Logo from 'components/Logo';

import './LoginLayout.styl';

const LoginLayout = ({ canChangeLanguage, children, classes }) => (
  <div className='login-wrapper'>
    <div className='login-content'>
      {canChangeLanguage && <LanguageSwitcher />}

      <Logo version='secondary' />

      <div className={classNames('container', classes)}>{children}</div>

      <Footer copyright={true} />
    </div>
    <div className='login-gradient' />
  </div>
);

export default connect(
  ({ whitelabel }) => ({
    canChangeLanguage: whitelabel.configs.canChangeLanguage,
  }),
  null,
)(LoginLayout);
