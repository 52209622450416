import { LIST_ACCESSORIES, UPLOAD_FILE } from 'actions/admin/accessories';

import initialState from 'reducers/initialState';

export default function admin(state = initialState.adminAcessories, action) {
  switch (action.type) {
    case LIST_ACCESSORIES:
      return { ...state, accessories: action.accessories };
    case UPLOAD_FILE:
      return { ...state, uploadReport: action.data };
    default:
      return state;
  }
}
