import {
  addParentDeviceAction,
  addToCartAction,
  clearSelectedProductAction,
  removeCurrentCartAction,
  removeParentDeviceAction,
  saveCurrentCartAction,
  saveFinancingTypeAction,
  saveSelectedDeviceAction,
  selectOptionAction,
} from 'actions/carts';
import { performCreditCheckAction } from 'actions/contract';
import { clearCustomerAction } from 'actions/customer';
import { addNotificationAction } from 'actions/ui';
import flow from 'enhancers/flow';
import { stringFormatter } from 'helpers/formatters';
import { parsePhoneNumber } from 'libphonenumber-js';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { getCurrentCart } from 'utils';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import { t } from '@lingui/macro';

import Footer from 'components/Footer';

import { upgrade } from 'constants/flows';
import {
  blockedCartWarning,
  missingProductOptions,
} from 'constants/notifications';
import {
  contractPageUrl,
  customerDetailsUpgradeUrl,
  upgradeFlowUrl,
} from 'constants/routes';

import Breadcrumbs from './components/Breadcrumbs';

const Upgrade = ({
  steps = [],
  currentStep = 0,
  setSteps,
  next,
  prev,
  currentStepName,
  getStepName,
  syncFlow,
}) => {
  const [locationState, setLocationState] = useState();
  const [cartState, setCartState] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const previousLocation = useRef();
  const oldCart = useRef();

  const { cartUUID } = useParams();
  const canCustomize = useSelector(
    ({ whitelabel }) => whitelabel.configs.canCustomize,
  );
  const cart = useSelector(({ carts }) => getCurrentCart(carts, cartUUID));
  const contractSteps = useSelector(({ contract }) => contract.steps);
  const country = useSelector(({ whitelabel }) => whitelabel.country);
  const customerInfo = useSelector(({ form }) => form.customerInfo);
  const email = useSelector(({ customer }) => customer.email);
  const deviceReference = useSelector(
    ({ customer }) => customer.deviceReference,
  );
  const firstName = useSelector(({ customer }) => customer.firstName);
  const lastName = useSelector(({ customer }) => customer.lastName);
  const idNumber = useSelector(({ customer }) => customer.idNumber);
  const options = useSelector(({ customer }) => customer.options);
  const phone = useSelector(
    ({ customer }) => customer.bnpPhone || customer.phone,
  );
  const title = useSelector(({ customer }) => customer.title);
  const sequence = useSelector(({ customer }) => customer.sequence);
  const ssn = useSelector(({ customer }) => customer.ssn);
  const customerReference = useSelector(
    ({ customer }) => customer.customerReference,
  );
  const name = useSelector(({ customer }) => customer.name);
  const number = useSelector(({ customer }) => customer.number);
  const street = useSelector(({ customer }) => customer.street);
  const district = useSelector(({ customer }) => customer.district);
  const county = useSelector(({ customer }) => customer.county);
  const postCode = useSelector(({ customer }) => customer.postCode);
  const town = useSelector(({ customer }) => customer.town);
  const city = useSelector(({ customer }) => customer.city);
  const sellerEmployeeNumber = useSelector(
    ({ customer }) => customer.sellerEmployeeNumber,
  );

  const manualCTOCategories = useSelector(
    ({ whitelabel }) => whitelabel.configs.manualCTOCategories,
  );
  const productOptions = useSelector(({ catalog }) => catalog.productOptions);
  const selectedService = cart?.selectedService;

  const getCorrectFlow = useCallback(() => {
    let steps = [];
    if (
      (canCustomize === 'CATALOG' && cart?.selected?.hasCTOs) ||
      (canCustomize === 'MANUAL' &&
        manualCTOCategories.includes(cart?.category))
    ) {
      steps = upgrade.customizable;
    } else {
      steps = upgrade.default;
    }
    if (selectedService?.serviceType === 'CATEGORIES') {
      steps.shift();
    }
    return steps;
  }, [canCustomize, cart, manualCTOCategories, selectedService]);

  useEffect(() => {
    const { type: financingType } = selectedService || {};
    if (financingType) {
      dispatch(saveFinancingTypeAction(cartUUID, financingType));
      const currentFlow = getCorrectFlow();

      setSteps(currentFlow);

      syncFlow(upgradeFlowUrl(cartUUID), currentFlow);

      dispatch(saveCurrentCartAction(cartUUID));
    }
  }, [cartUUID, selectedService]);

  useEffect(() => {
    previousLocation.current = locationState;
    oldCart.current = cartState;

    setCartState(cart);
    setLocationState(location);

    if (
      oldCart.current?.category !== cart?.category ||
      oldCart.current?.selected?.deviceCode !== cart?.selected?.deviceCode ||
      oldCart.current?.selected?.hasCTOs !== cart?.selected?.hasCTOs
    ) {
      const flow = getCorrectFlow();

      setSteps(flow);
    }

    previousLocation.current && initializePage(previousLocation.current);
    return () => {
      dispatch(removeCurrentCartAction());
    };
  }, [cart, location, dispatch]);

  const initializePage = async (oldLocation) => {
    if (oldLocation.pathname !== location.pathname) {
      syncFlow(upgradeFlowUrl(cartUUID));
      const oldPage = getStepName(
        oldLocation.pathname,
        upgradeFlowUrl(cartUUID),
      );
      const currentPage = getStepName(
        location.pathname,
        upgradeFlowUrl(cartUUID),
      );

      if (
        oldPage === 'customerInfo' &&
        ['extras', 'catalog', 'categories', 'options'].includes(currentPage)
      ) {
        dispatch(addNotificationAction(blockedCartWarning()));
        navigate(customerDetailsUpgradeUrl(cartUUID));
      }

      if (
        oldPage === 'options' &&
        currentPage === 'customize' &&
        canCustomize === 'CATALOG' &&
        cart.selected?.hasCTOs
      )
        dispatch(addParentDeviceAction(cartUUID));

      if (
        oldPage === 'catalog' &&
        currentPage === 'options' &&
        canCustomize === 'CATALOG'
      ) {
        if (!productOptions) {
          dispatch(addNotificationAction(missingProductOptions()));
        } else {
          const defaultHasCTOs = productOptions
            .reduce((acc, { options }) => {
              return [
                ...acc,
                options.find(({ selected }) => selected)?.hasCTOs,
              ];
            }, [])
            .filter(Boolean)
            .some((bool) => true);

          if (defaultHasCTOs !== cart.selected.hasCTOs) {
            dispatch(
              saveSelectedDeviceAction(cartUUID, {
                hasCTOs: defaultHasCTOs,
              }),
            );
          }
        }
      }

      if (
        oldPage === 'customize' &&
        currentPage === 'options' &&
        canCustomize === 'CATALOG' &&
        cart.selected?.hasCTOs
      )
        dispatch(removeParentDeviceAction(cartUUID));

      if (
        (oldPage === 'extras' && currentPage === 'options') ||
        (oldPage === 'customize' &&
          currentPage === 'options' &&
          canCustomize === 'MANUAL') ||
        (oldPage === 'extras' &&
          currentPage === 'customize' &&
          canCustomize === 'CATALOG' &&
          cart.selected?.hasCTOs)
      )
        dispatch(selectOptionAction(cartUUID));
    }
  };

  const disabledNextButtons = () => {
    switch (currentStepName) {
      case 'categories':
        return typeof cart?.category === 'undefined';
      case 'catalog':
        return typeof cart?.selected?.deviceCode === 'undefined';
      case 'customerInfo':
        return customerInfo && !isEmpty(customerInfo.syncErrors);
      case 'contractStatus':
        return Object.values(omit(contractSteps, ['swap'])).some(
          (step) => step !== 'APPROVED',
        );
      default:
        return false;
    }
  };

  const disabledBackButtons = () => {
    return currentStepName === 'contractStatus'
      ? Object.values(omit(contractSteps, ['swap'])).some(
          (step) => step !== 'APPROVED',
        )
      : false;
  };

  const getNextBtnLabel = (steps, currentStep) =>
    !isEmpty(steps)
      ? steps[currentStep]?.nextBtnLabel
      : upgrade.default[currentStep].nextBtnLabel;

  const hiddenBackButtons = () => {
    if (currentStep < 1) {
      return true;
    }
    switch (currentStepName) {
      case 'categories':
        return true;
      case 'customerInfo':
        return true;
      case 'contractStatus':
        return true;
      default:
        return false;
    }
  };

  const hiddenNextButtons = () => {
    switch (currentStepName) {
      case 'categories':
        return true;
      case 'catalog':
        return true;
      default:
        return false;
    }
  };

  const nextPage = async () => {
    let allowNavigation = true;

    switch (currentStepName) {
      case 'extras':
        dispatch(addToCartAction(cartUUID));
        break;
      case 'customerInfo':
        const phoneNumber = parsePhoneNumber(phone.trim(), country);

        const { creditStatus } = await dispatch(
          performCreditCheckAction(cartUUID, {
            email,
            phone: phoneNumber.format('E.164'),
            sequence,
            deviceReference,
            title,
            firstName,
            lastName,
            city,
            idNumber,
            customerReference,
            distributionMethod: options.find(
              ({ selected }) => selected === true,
            )?.id,
            ssn,
            name,
            number,
            street,
            district,
            county,
            town,
            postCode,
            employeeNumber: sellerEmployeeNumber,
          }),
        );

        if (creditStatus !== 'SUCCESS') allowNavigation = false;
        else dispatch(clearCustomerAction());
        break;
      case 'contractStatus':
        return navigate(contractPageUrl(cartUUID));
      default:
        break;
    }

    allowNavigation && (await next());
    return (
      steps?.[currentStep + 1]?.path &&
      allowNavigation &&
      navigate(stringFormatter(steps[currentStep + 1].path, [cartUUID], '#'), {
        replace: false,
      })
    );
  };

  const prevPage = async () => {
    await prev();
    if (currentStep < 2) {
      await dispatch(clearSelectedProductAction(cartUUID));
    }
    navigate(stringFormatter(steps[currentStep - 1].path, [cartUUID], '#'));
  };

  return (
    <>
      <Breadcrumbs
        currentStep={currentStep}
        steps={steps}
        cartUUID={cartUUID}
        navigate={navigate}
        syncFlow={syncFlow}
        category={cartState?.categoryName}
        family={cartState?.family}
      />

      <div id='upgrade-flow'>
        <Outlet context={[nextPage]} />
      </div>

      <Footer
        classes='main'
        copyright={false}
        backBtnLabel={t({ id: 'navigation.back.button', message: `Back` })}
        backBtnHidden={hiddenBackButtons()}
        backBtnDisabled={disabledBackButtons()}
        backBtnAction={prevPage}
        nextBtnLabel={getNextBtnLabel(steps, currentStep)}
        nextBtnHidden={hiddenNextButtons()}
        nextBtnDisabled={disabledNextButtons()}
        nextBtnAction={nextPage}
      />
    </>
  );
};

export default flow({
  name: 'upgrade',
  total: upgrade.default.length,
  steps: upgrade.default,
})(Upgrade);
