import { t } from '@lingui/macro';

export const TYPE_ID_MOBILE_RECYCLE = '1';
export const TYPE_ID_GIFT_CARD = '2';
export const TYPE_ID_CASH = '3';
export const TYPE_ID_OTHER = '4';
export const TYPE_ID_DISCOUNT = '5';
export const TYPE_ID_STUDENT = '6';
export const TYPE_ID_SALE = '7';
export const TYPE_ID_EMPLOYEE = '8';
export const STRING_MOBILE_RECYCLE = 'MOBILE_RECYCLE';
export const STRING_GIFT_CARD = 'GIFT_CARD';
export const STRING_CASH = 'CASH';
export const STRING_OTHER = 'OTHER';
export const STRING_DISCOUNT = 'DISCOUNT';
export const STRING_STUDENT = 'STUDENT';
export const STRING_SALE = 'SALE';
export const STRING_EMPLOYEE = 'EMPLOYEE';

export const discountLabels = () => ({
  [TYPE_ID_MOBILE_RECYCLE]: t({
    id: 'discount.type.mobile.recycle',
    message: `Mobile Recycle`,
  }),
  [TYPE_ID_GIFT_CARD]: t({
    id: 'discount.type.gift.card',
    message: `Gift card`,
  }),
  [TYPE_ID_CASH]: t({ id: 'discount.type.cash', message: `Cash` }),
  [TYPE_ID_OTHER]: t({ id: 'discount.type.other', message: `Other` }),
  [TYPE_ID_DISCOUNT]: t({ id: 'discount.type.discount', message: `Discount` }),
  [TYPE_ID_STUDENT]: t({ id: 'discount.type.student', message: `Student` }),
  [TYPE_ID_SALE]: t({ id: 'discount.type.male', message: `Sale` }),
  [TYPE_ID_EMPLOYEE]: t({ id: 'discount.type.employee', message: `Employee` }),
});

export const discountList = () => [
  {
    value: TYPE_ID_MOBILE_RECYCLE,
    label: discountLabels()[TYPE_ID_MOBILE_RECYCLE],
    configString: STRING_MOBILE_RECYCLE,
  },
  {
    value: TYPE_ID_GIFT_CARD,
    label: discountLabels()[TYPE_ID_GIFT_CARD],
    configString: STRING_GIFT_CARD,
  },
  {
    value: TYPE_ID_CASH,
    label: discountLabels()[TYPE_ID_CASH],
    configString: STRING_CASH,
  },
  {
    value: TYPE_ID_OTHER,
    label: discountLabels()[TYPE_ID_OTHER],
    configString: STRING_OTHER,
  },
  {
    value: TYPE_ID_DISCOUNT,
    label: discountLabels()[TYPE_ID_DISCOUNT],
    configString: STRING_DISCOUNT,
  },
  {
    value: TYPE_ID_STUDENT,
    label: discountLabels()[TYPE_ID_STUDENT],
    configString: STRING_STUDENT,
  },
  {
    value: TYPE_ID_SALE,
    label: discountLabels()[TYPE_ID_SALE],
    configString: STRING_SALE,
  },
  {
    value: TYPE_ID_EMPLOYEE,
    label: discountLabels()[TYPE_ID_EMPLOYEE],
    configString: STRING_EMPLOYEE,
  },
];
