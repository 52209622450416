import { getPlanNameByOutcome } from 'utils';

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { Trans } from '@lingui/macro';

import { recycleLabels } from 'constants/recycle';

import './OutcomesHeader.styl';

class OutcomesHeader extends Component {
  render() {
    const { outcome, payment, sourceName, upgradePlan, standardPlan } =
      this.props;

    const planName = getPlanNameByOutcome(outcome, upgradePlan, standardPlan);

    return (
      <div className='receipt-outcomes'>
        <h3>
          <Trans id='receipt.source'>Source</Trans>:
        </h3>
        <span>{sourceName}</span>

        {outcome && (
          <Fragment>
            <span>/</span>
            <h3>
              <Trans id='receipt.outcome'>Outcome</Trans>:
            </h3>
            <span>{recycleLabels(outcome, planName)}</span>
          </Fragment>
        )}

        {payment && payment !== 'NONE' && (
          <Fragment>
            <span>/</span>
            <h3>
              <Trans id='receipt.payment'>Payment</Trans>:
            </h3>
            <span>{recycleLabels(payment, planName)}</span>
          </Fragment>
        )}
      </div>
    );
  }
}

OutcomesHeader = connect(({ recycle, whitelabel }) => ({
  outcome: recycle.receipt.outcome,
  payment: recycle.receipt.payment,
  sourceName:
    recycle.receipt.source === 'TRADE_IN'
      ? whitelabel.configs.tradeinName
      : whitelabel.configs.swapName,
  standardPlan: whitelabel.configs.standardName,
  upgradePlan: whitelabel.configs.upgradeName,
}))(OutcomesHeader);

export default OutcomesHeader;
