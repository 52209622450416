export const phoneFormats = {
  GB: {
    prefix: '+44',
    pattern: '+99 9999 999999',
    length: 8,
  },
  DK: {
    prefix: '+45',
    pattern: '+99 99 99 99 99',
    length: 8,
  },
  SE: {
    prefix: '+46',
    pattern: '+99 999 999 999',
    length: 8,
  },
  NO: {
    prefix: '+47',
    pattern: '+99 999 99 9999999',
    length: 8,
  },
  PT: {
    prefix: '+351',
    pattern: '+999 99 999 99 99',
    length: 8,
  },
  FI: {
    prefix: '+358',
    pattern: '+99 999 99 9999999',
    length: 8,
  },
  FR: {
    prefix: '+33',
    pattern: '+99 99 99 99 99 99',
    length: 8,
  },
  DE: {
    prefix: '+49',
    pattern: '+99 99 99 99 99 99',
    length: 8,
  },
  IT: {
    prefix: '+39',
    pattern: '+99 99 99 99 99 99',
    length: 8,
  },
};
