import { cleanStoreAction } from 'actions/app';
import { createCartAction } from 'actions/carts';
import {
  saveFinancingTypeAction,
  saveSelectedCategoryAction,
  setSelectedServiceAction,
} from 'actions/carts';
import {
  clearProductCategoriesAction,
  getServicesAction,
} from 'actions/catalog';
import { addNotificationAction } from 'actions/ui';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import { getCurrentCart, safeHTMLParser, textToKebab } from 'utils';

import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';

import CartBadge from 'components/CartBadge';
import Footer from 'components/Footer';
import Loader from 'components/Loader';
import Multistep from 'components/Multistep';
import Spinner from 'components/Spinner';

import { blockedFeature } from 'constants/notifications';

import useTrackPage from 'hooks/useTrackPage';

import './Services.styl';

const ServicesGroup = ({ name, title, services, type, handleClick }) => {
  const [loading, setLoading] = useState(isEmpty(services));

  const onClick = (item) => {
    handleClick(type, item);
  };

  useEffect(() => {
    setLoading(isEmpty(services));
  }, [services]);

  return (
    <div className='services-block'>
      {title && (
        <h3>
          {name && `${name} - `}
          <Trans id={title}></Trans>
        </h3>
      )}
      {loading ? (
        <div className='service-spinner-container'>
          <Spinner classes={'categories-spinner'} />
        </div>
      ) : (
        <ul>
          {services?.map((service, idx) => (
            <li
              data-test={`posale-services-${textToKebab(service.name)}-btn`}
              key={`${service.id}-${idx}`}
              onClick={() => onClick(service)}
            >
              {service.image && (
                <img
                  className={`${
                    service.image.endsWith('.svg') && 'services-img-filter'
                  }`}
                  src={service.image}
                  alt={service.name}
                />
              )}
              <div>
                <span>{service.name}</span>
                {service.description && (
                  <p>
                    <Trans id={service.description}></Trans>
                  </p>
                )}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const MarketingBanner = ({ type, images }) => (
  <div className={`marketing-banner-${type}`}>
    {images.map((image) => (
      <img src={image} alt={type} key={`${type}-${image}`} />
    ))}
  </div>
);

const Services = () => {
  useTrackPage('Services');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [cartUUID, setCartUUID] = useState(useParams()?.cartUUID);
  const cart = useSelector(({ carts }) => getCurrentCart(carts, cartUUID));
  const loading = useSelector(({ ui }) => ui.loading);

  const marketingBanners = useSelector(
    ({ whitelabel }) => whitelabel.configs.marketingBanners,
  );

  const [services, setServices] = useState();
  const [title, setTitle] = useState();
  const [text, setText] = useState();

  const fetchServices = useCallback(async () => {
    await dispatch(clearProductCategoriesAction());
    const result = await dispatch(getServicesAction(cart?.tradecartID));
    const { servicesConfig } = result || {};
    const { title, text, services } = servicesConfig || {};
    setServices(orderBy(services, ['displayOrder'], ['asc']));
    setTitle(title);
    setText(text);
  }, [dispatch, cart?.tradecartID]);

  useEffect(() => {
    if (!cartUUID) {
      dispatch(cleanStoreAction());
      dispatch(createCartAction()).then((cartUUID) => setCartUUID(cartUUID));
    } else {
      fetchServices();
    }
  }, [dispatch, cartUUID, fetchServices]);

  const handleClick = (type, option) => {
    if (option && option.active) {
      dispatch(
        setSelectedServiceAction(cartUUID, { ...option, serviceType: type }),
      );
      dispatch(
        saveFinancingTypeAction(
          cartUUID,
          option?.type,
          option?.tags,
          option?.tagFilterType,
        ),
      );
      if (type === 'CATEGORIES') {
        dispatch(
          saveSelectedCategoryAction(cartUUID, {
            id: option.categoryID,
            name: option.name,
          }),
        );
        return navigate(`${option.path}/${cartUUID}/catalog`);
      }
      if (option.type === 'TRADE_IN' || option.type === 'UPLOAD_SALES') {
        return navigate(option.path);
      }
      return navigate(`${option.path}/${cartUUID}`);
    } else {
      dispatch(addNotificationAction(blockedFeature()));
    }
  };

  if (loading) return <Loader />;

  return (
    <Fragment>
      <div className='services__steps__badge'>
        <Multistep title={t({ id: title ? title : 'services.title' })} />
        <CartBadge cart={cart} />
      </div>

      {text && (
        <div className='services_intro'>
          {safeHTMLParser(t({ id: text, message: text }))}
        </div>
      )}

      {marketingBanners && (
        <MarketingBanner
          type={'horizontal'}
          images={marketingBanners.horizontal}
        />
      )}

      <div className='services-main'>
        {services && (
          <div className='services'>
            {services?.map((service, idx) => {
              const { name, type, title, group } = service || {};
              const last = services.length - 1 === idx;
              const showSeparator = last && services.length > 3;
              return (
                <React.Fragment key={`${type}-${idx}`}>
                  {showSeparator && <div className='services-separator'></div>}
                  <ServicesGroup
                    name={name}
                    title={title}
                    services={group}
                    type={type}
                    handleClick={handleClick}
                  />
                </React.Fragment>
              );
            })}
          </div>
        )}
      </div>

      {marketingBanners && (
        <MarketingBanner type={'vertical'} images={marketingBanners.vertical} />
      )}
      <Footer classes='main' copyright={false} />
    </Fragment>
  );
};

export default Services;
