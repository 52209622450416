import { setSelectedServiceAction } from 'actions/carts';
import { getServicesAction } from 'actions/catalog';
import { setCustomerAction } from 'actions/customer';
import {
  createCart,
  createTradecartAction,
  returnDeviceAction,
  selectRecycleOutcomeAction,
  sendQuoteApprovalMessageAction,
} from 'actions/recycle';
import {
  addNotificationAction,
  hideLoaderAction,
  showLoaderAction,
} from 'actions/ui';
import classNames from 'classnames';
import { currencyFormatter } from 'helpers/formatters';
import { parsePhoneNumber } from 'libphonenumber-js';
import { getPlanNameByOutcome } from 'utils';

import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';

import Checkbox from 'components/Checkbox';
import Footer from 'components/Footer';
import List from 'components/List';
import Loader from 'components/Loader';
import Summary from 'components/recycle/Summary';
import SwapLegalText from 'components/recycle/SwapLegalText';
import TradeSteps from 'components/recycle/TradeSteps';

import { customerUpdateSuccess } from 'constants/notifications';
import {
  GIFT_CARD,
  STANDARD,
  SWAP,
  TRADE_IN,
  UPGRADE,
  recycleLabels,
} from 'constants/recycle';
import { RETURN_DEVICE } from 'constants/recycle';
import {
  recycleDetailsUrl,
  recycleEvaluateOrderUrl,
  recycleEvaluateUrl,
  standardFinanceFlowUrl,
  upgradeFlowUrl,
} from 'constants/routes';

import useTrackPage from 'hooks/useTrackPage';

import OptionSelector from './OptionSelector';
import './Outcomes.styl';
import RecycleCustomerInfo from './RecycleCustomerInfo';

const Outcomes = () => {
  useTrackPage('RecycleOutcomes');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [paymentConfirmed, setPaymentConfirmed] = useState(false);
  const [idConfirmed, setIdConfirmed] = useState(false);
  const [enableTradeSteps, setEnableTradeSteps] = useState(false);

  const loading = useSelector(({ ui }) => ui.loading);
  const currency = useSelector(({ whitelabel }) => whitelabel.currency);
  const country = useSelector(({ whitelabel }) => whitelabel.country);
  const recycleCustomerExtraFields = useSelector(
    ({ whitelabel }) => whitelabel.configs.recycleCustomerExtraFields,
  );
  const countryPhoneCodes = useSelector(
    ({ whitelabel }) => whitelabel.configs.countryPhoneCodes,
  );
  const language = useSelector(({ whitelabel }) => whitelabel.language);
  const showAddressFields = useSelector(
    ({ whitelabel }) => whitelabel.configs.showAddressFields,
  );

  const customer = useSelector(({ customer }) => customer);
  // TODO: this is only used for MM Spain and its missing the WL Config property. Change once its been fixed
  const confirmationOptions = useSelector(
    ({ whitelabel }) => ['SMS'] /*whitelabel.configs.confirmationOptions*/,
  );
  const deviceValue = useSelector(
    ({ recycle }) => recycle.selectedDevice.evaluation.deviceValue,
  );
  const funder = useSelector(({ recycle }) => recycle.funder)?.toLowerCase();

  const customerTradecartApproval = useSelector(
    ({ whitelabel }) => whitelabel.configs.customerTradecartApproval,
  );
  const recycleOptions = useSelector(
    ({ whitelabel }) => whitelabel.configs.recycleOptions,
  );
  const upgradePlan = useSelector(
    ({ whitelabel }) => whitelabel.configs.upgradeName,
  );
  const standardPlan = useSelector(
    ({ whitelabel }) => whitelabel.configs.standardPlan,
  );

  const orderUUID = useSelector(({ recycle }) => recycle.orderUUID);
  const recycleType = useSelector(({ recycle }) => recycle.type);
  const selectedDevice = useSelector(({ recycle }) => recycle.selectedDevice);
  const selectedOutcome = useSelector(({ recycle }) => recycle.selectedOutcome);
  const tradecart = useSelector(({ recycle }) => recycle.tradecart);
  const tradecartStatus = useSelector(({ recycle }) => recycle.tradecartStatus);
  const showSapCustomerForm = useSelector(
    ({ recycle, whitelabel }) =>
      (recycle.type === SWAP || recycle.type === TRADE_IN) &&
      whitelabel.configs.showSapCustomerForm,
  );

  const salesPerson = useSelector(({ user }) => user.name);
  const [confirmationMethod, setConfirmationMethod] = useState(
    confirmationOptions?.length === 1 && confirmationOptions[0],
  );

  const finalizeRecycle = useCallback(
    async (recycleCart) => {
      dispatch(showLoaderAction());

      if (!recycleCart) recycleCart = await dispatch(createTradecartAction());

      if (recycleCart) {
        if (
          selectedOutcome === RETURN_DEVICE ||
          selectedOutcome === GIFT_CARD
        ) {
          const response = await dispatch(returnDeviceAction(recycleCart));
          response && navigate(recycleDetailsUrl(recycleCart.id));
        } else {
          const cart = await dispatch(
            createCart({ recycleCart, selectedOutcome, recycleType }),
          );

          if (cart) {
            const { selectedOutcomeServiceGroup } = await dispatch(
              getServicesAction(cart?.tradecartID, selectedOutcome),
            );
            await dispatch(
              setSelectedServiceAction(cart.uuid, selectedOutcomeServiceGroup),
            );
            if (selectedOutcome === UPGRADE) {
              await navigate(upgradeFlowUrl(cart.uuid));
            }
            if (selectedOutcome === STANDARD) {
              await navigate(standardFinanceFlowUrl(cart.uuid));
            }
          }
        }
      }

      dispatch(hideLoaderAction());
    },
    [dispatch, selectedOutcome, navigate, recycleType],
  );

  useEffect(() => {
    if (recycleOptions[recycleType]?.length === 1) {
      dispatch(selectRecycleOutcomeAction(recycleOptions[recycleType][0]));

      if (recycleOptions[recycleType][0] === RETURN_DEVICE) {
        finalizeRecycle(tradecart);
      }
    }
  }, [tradecart, dispatch, recycleType, recycleOptions, finalizeRecycle]);

  const updateConfirmationOption = (confirmationMethod) =>
    setConfirmationMethod(confirmationMethod);

  const validCustomer = () => {
    const { name, phone, email } = customer;
    return name !== '' && phone !== '' && email !== '';
  };

  const hasStorePayment = () => {
    const { evaluation } = selectedDevice;
    return funder.toLowerCase() === 'instabank' && evaluation.swapCost > 0;
  };

  const handleCustomerFormSubmit = async ({ customer }) => {
    setIdConfirmed(false);
    await dispatch(
      setCustomerAction({
        ...customer,
        phone: parsePhoneNumber(customer?.phone, country).number,
      }),
    );
    const tradeCart = await dispatch(createTradecartAction());

    !customerTradecartApproval &&
      dispatch(addNotificationAction(customerUpdateSuccess()));

    if (customerTradecartApproval && tradeCart.salesChannel !== 'ONLINE')
      dispatch(
        sendQuoteApprovalMessageAction(tradeCart.id, confirmationMethod),
      );

    setEnableTradeSteps(true);
  };

  const toggleIDConfirmation = () => setIdConfirmed(!idConfirmed);

  const togglePaymentConfirmation = () =>
    setPaymentConfirmed(!paymentConfirmed);

  const canProceed = () => {
    if (recycleType === SWAP) {
      return selectedOutcome && (!hasStorePayment() || paymentConfirmed);
    }

    if (recycleType === TRADE_IN) {
      const isOutcomeSelected =
        selectedOutcome === UPGRADE ||
        selectedOutcome === STANDARD ||
        (selectedOutcome === GIFT_CARD && validCustomer());

      return isOutcomeSelected && (!customerTradecartApproval || idConfirmed);
    }

    return false;
  };

  const navigateBack = () => {
    if (recycleType === SWAP) {
      navigate(recycleEvaluateOrderUrl(orderUUID));
    } else {
      navigate(recycleEvaluateUrl);
    }
  };

  const navigateForward = () => finalizeRecycle(tradecart);

  const generateRecycleOptionsClasses = (item) =>
    classNames('item circle', {
      selected: item === selectedOutcome,
    });

  const renderRecycleOptionsItem = (item) => {
    return (
      <OptionSelector
        item={recycleLabels(
          item,
          getPlanNameByOutcome(item, upgradePlan, standardPlan),
        )}
      />
    );
  };

  if (!recycleType) {
    return (
      <div className='outcomes__unavailable'>
        <h1>
          <Trans id='data.unavailable.title'>Data unavailable</Trans>
        </h1>
        <p>
          <Trans id='data.unavailable.text'>
            The data you are trying to access is no longer available.
          </Trans>
        </p>
      </div>
    );
  }

  return (
    <div className='outcomes'>
      {loading && <Loader />}

      <main className='content-left'>
        <h1>
          <Trans id='evaluation.swap.outcomes.title'>Outcome</Trans>
        </h1>

        {recycleOptions[recycleType]?.length > 1 && (
          <List
            data={recycleOptions[recycleType]}
            // TODO: remove onClick in favor of using rendered items to control behavior
            onClick={(outcome) => dispatch(selectRecycleOutcomeAction(outcome))}
            // TODO make this component reliant only on class functions
            // in order to remove arrow functions and enforce this rule
            // eslint-disable-next-line react/jsx-no-bind
            keyGenerator={(item) => `outcome-${item}`}
            listClasses={'list'}
            itemClasses={generateRecycleOptionsClasses}
            render={renderRecycleOptionsItem}
          />
        )}

        {recycleType === SWAP && hasStorePayment() && (
          <div className='recycleDisclaimer'>
            <Checkbox
              id='store-payment'
              onChange={togglePaymentConfirmation}
              checked={paymentConfirmed}
            />
            <Trans id='recycle.IB.swap.disclaimer.'>
              I confirm I've received the customer SWAP value
            </Trans>
          </div>
        )}

        {selectedOutcome === 'GIFT_CARD' && (
          <Fragment>
            {!customerTradecartApproval && (
              <div className='gift-card-container'>
                <span className='gift-card-price'>
                  {currencyFormatter(deviceValue, currency, language)}
                </span>
              </div>
            )}

            <RecycleCustomerInfo
              recycleCustomerExtraFields={recycleCustomerExtraFields}
              country={country}
              countryPhoneCodes={countryPhoneCodes}
              initialValues={customer}
              mode='vendor'
              submitFunction={handleCustomerFormSubmit}
              showSapCustomerForm={showSapCustomerForm}
              showAddressFields={showAddressFields}
            />

            {customerTradecartApproval && (
              <Fragment>
                {confirmationOptions?.length > 1 && (
                  <List
                    type='circle'
                    items={confirmationOptions}
                    handleClick={updateConfirmationOption}
                  />
                )}

                {(tradecartStatus.hasIdPhoto === 'APPROVED' ||
                  enableTradeSteps) && <TradeSteps steps={tradecartStatus} />}

                {tradecartStatus?.isCustomerApproved === 'APPROVED' &&
                  tradecartStatus?.hasIdPhoto === 'APPROVED' && (
                    <Checkbox
                      id='id-confirmation'
                      onChange={toggleIDConfirmation}
                      checked={idConfirmed}
                      label={t({
                        id: 'recycle.id.confirmation.checkbox',
                        message: `Customer ID photo clear, and all ID data easy to read. Confirmed by ${salesPerson}`,
                      })}
                    />
                  )}
              </Fragment>
            )}
          </Fragment>
        )}
      </main>

      <aside className='content-right'>
        <Summary device={selectedDevice} source={recycleType} />
        {recycleType === SWAP && funder === 'klarna' && (
          <SwapLegalText evaluation={selectedDevice.evaluation} />
        )}
      </aside>

      <Footer
        classes='main'
        copyright={false}
        backBtnLabel={t({ id: 'navigation.back.button', message: `Back` })}
        backBtnAction={navigateBack}
        nextBtnLabel={t({
          id: 'navigation.swap.button',
          message: `Recycle Device`,
        })}
        nextBtnAction={navigateForward}
        nextBtnDisabled={!canProceed()}
      />
    </div>
  );
};

export default Outcomes;
