import { saveSelectedFamilyAction } from 'actions/carts';
import {
  clearProductFamiliesAction,
  getProductFamiliesAction,
} from 'actions/catalog';
import trackPage from 'enhancers/trackPage';
import { getFormValues, reduxForm, submit } from 'redux-form';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import Heading from 'components/Heading';
import InfiniteScroll from 'components/InfiniteScroll';
import Loader from 'components/Loader';
import SearchBar from 'components/SearchBar';
import OutcomeIndicator from 'components/recycle/OutcomeIndicator';

import ProductItems from './ProductItems';

class ProductListCatalog extends Component {
  componentDidMount() {
    this.props.getProductFamilies();
  }

  componentWillUnmount() {
    this.props.clearProductFamilies();
  }

  selectProduct = async (family) => {
    const { cart, nextPage, saveSelectedFamily } = this.props;

    await saveSelectedFamily(cart.uuid, family);
    nextPage();
  };

  render() {
    const {
      allProductsFetched,
      cart,
      change,
      getProductFamilies,
      families,
      loading,
      productListFormValues,
    } = this.props;

    const { page } = productListFormValues;

    if (loading && page === 0) return <Loader />;

    return (
      <div className='product-list-wrapper'>
        <div>
          <Heading size='big' classes={'product-options-page-title'}>
            <Trans id='product.options.page.title'>
              {`Choose your ${cart?.categoryName}`}
            </Trans>
          </Heading>
          <OutcomeIndicator />
        </div>
        <SearchBar
          // TODO make this component reliant only on class functions
          // in order to remove arrow functions and enforce this rule
          // eslint-disable-next-line react/jsx-no-bind
          handleChange={(ev) => {
            this.props.change('page', 0) &&
              getProductFamilies({
                page: 0,
                searchText: ev.target.value,
              });
          }}
          label={t({
            id: 'product.catalog.search.label',
            message: `Search products`,
          })}
        />
        <ul className='product-list'>
          <InfiniteScroll
            items={families}
            // eslint-disable-next-line react/jsx-no-bind
            fetchItems={(page) => !allProductsFetched && change('page', page)}
            page={page}
          >
            <ProductItems
              selectedDeviceCode={cart?.selected?.deviceCode}
              category={cart.category}
              selectProduct={this.selectProduct}
            />
          </InfiniteScroll>
        </ul>
      </div>
    );
  }
}

ProductListCatalog.defaultProps = {
  cartExtras: {
    items: [],
  },
  productListFormValues: {},
};

ProductListCatalog = trackPage(ProductListCatalog, 'ProductListCatalog');

ProductListCatalog = reduxForm({
  form: 'productListForm',
  destroyOnUnmount: true,
  onSubmit: (_values, _dispatch, props) => {
    const { page = 0 } = props.productListFormValues || {};
    const { searchText } = props.searchFormValues || {};
    !props.allProductsFetched && props.getProductFamilies({ page, searchText });
  },
  onChange: (_values, _dispatch, props, prevValues) => {
    if (typeof prevValues.page !== 'undefined') props.submit('productListForm');
  },
})(ProductListCatalog);

ProductListCatalog = connect(
  (state) => ({
    currency: state.whitelabel.currency,
    canAddExtras: state.whitelabel.configs.canAddExtras,
    canCustomize: state.whitelabel.configs.canCustomize,
    manualCTOCategories: state.whitelabel.configs.manualCTOCategories,
    families: state.catalog.families,
    initialValues: { page: 0 },
    productListFormValues: getFormValues('productListForm')(state),
    searchFormValues: getFormValues('searchForm')(state),
    allProductsFetched: state.catalog.allProductsFetched,
    language: state.whitelabel.language,
    loading: state.ui.loading,
  }),
  (dispatch, props) => ({
    clearProductFamilies: () => dispatch(clearProductFamiliesAction()),
    saveSelectedFamily: (cartUUID, family) =>
      dispatch(saveSelectedFamilyAction(cartUUID, family)),
    getProductFamilies: (options) => {
      const { financingType, category, tags, tagFilterType, selected } =
        props.cart;
      dispatch(
        getProductFamiliesAction(
          financingType,
          selected?.paymentplanID,
          category,
          tags,
          tagFilterType,
          options,
        ),
      );
    },
    submit: (values) => dispatch(submit(values)),
  }),
)(ProductListCatalog);

export default ProductListCatalog;
