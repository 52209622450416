import {
  getRecyclePoliceReportAction,
  listRecycleOrdersAction,
} from 'actions/reports';
import classNames from 'classnames';
import { convertHTMLTableCSV, fileDownload } from 'utils';

import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import Button from 'components/Button';
import Footer from 'components/Footer';
import Heading from 'components/Heading';
import Loader from 'components/Loader';
import { formatDate } from 'components/RenderDatePicker';
import Section from 'components/Section';
import TabbedContent from 'components/TabbedContent.js';
import Table from 'components/table/Table';

import { weekInMillis } from 'constants/dates';
import { REPORTS_STATUS_ALL } from 'constants/recycle';
import { recycleStatus } from 'constants/status';
import { ADMIN_WHITELIST } from 'constants/user';

import usePermission from 'hooks/usePermission';

import './RecycleReports.styl';
import RecycleReportsForm from './components/RecycleReportsForm';

const clickHandlerExportTable = () => {
  const recycleSummaryCSV = convertHTMLTableCSV(
    ...document.querySelectorAll('.recycle-report__summary-table table'),
  );

  const recycleDevicesCSV = convertHTMLTableCSV(
    ...document.querySelectorAll('.recycle-report__devices-table table'),
  );

  fileDownload(recycleSummaryCSV, 'recycle-report-summary.csv', 'text/csv');
  fileDownload(recycleDevicesCSV, 'recycle-report-device-list.csv', 'text/csv');
};

export const RecycleReports = () => {
  const dispatch = useDispatch();
  const { hasAuthorization, renderUnauthorized } =
    usePermission(ADMIN_WHITELIST);

  const devices = useSelector(({ reports }) => reports.recycle?.devices);
  const summary = useSelector(({ reports }) => reports.recycle?.summary);

  const recycleFilterForm = useSelector(({ form }) => form.recycleFilterForm);

  const hasRecyclePoliceReport = useSelector(
    ({ whitelabel }) => whitelabel.configs.hasRecyclePoliceReport,
  );

  const loading = useSelector(({ ui }) => ui.loading);

  const generateColumn = (
    label,
    accessor,
    {
      type = 'text',
      align = 'center',
      filterable = true,
      sortable = true,
      list,
    } = {},
  ) => ({
    label,
    accessor,
    type,
    align,
    filterable,
    sortable,
    list,
  });

  const summaryColumns = [
    generateColumn(
      t({
        id: 'recycle.report.table.column.status',
        message: `Transaction status`,
      }),
      'status',
    ),
    generateColumn(
      t({
        id: 'recycle.report.table.column.paid.customer',
        message: `Paid to customer`,
      }),
      'paidCustomer',
      { type: 'currency', align: 'right' },
    ),
    generateColumn(
      t({
        id: 'recycle.report.table.column.guarantors',
        message: `Guarantors`,
      }),
      'guarantors',
    ),
    generateColumn(
      t({ id: 'recycle.report.table.column.quantity', message: `Quantity` }),
      'quantity',
    ),
    generateColumn(
      t({
        id: 'recycle.report.table.column.order.count',
        message: `Quotes count`,
      }),
      'orderCount',
    ),
  ];

  const deviceListColumns = [
    generateColumn(
      t({
        id: 'recycle.report.table.column.device.name',
        message: `Device Name`,
      }),
      'deviceName',
      { align: 'left' },
    ),
    generateColumn(
      t({
        id: 'recycle.report.table.column.device.reference',
        message: `Device reference`,
      }),
      'deviceReference',
    ),
    generateColumn(
      t({ id: 'recycle.report.table.column.order.id', message: `Quote ID` }),
      'orderID',
    ),
    generateColumn(
      t({ id: 'recycle.report.table.column.source', message: `Source` }),
      'source',
      { list: recycleStatus() },
    ),
    generateColumn(
      t({
        id: 'recycle.report.table.column.paid.customer',
        message: `Paid to customer`,
      }),
      'paidCustomer',
      { type: 'currency', align: 'right' },
    ),
    generateColumn(
      t({ id: 'recycle.report.table.column.guarantor', message: `Guarantor` }),
      'guarantor',
    ),
    generateColumn(
      t({
        id: 'recycle.report.table.column.original.condition',
        message: `Original condition`,
      }),
      'storeGrade',
    ),
    generateColumn(
      t({
        id: 'recycle.report.table.column.tested.condition',
        message: `Tested condition`,
      }),
      'collectorGrade',
    ),
    generateColumn(
      t({
        id: 'recycle.report.table.column.downgrade.reason',
        message: `Downgrade reason`,
      }),
      'downgradeReason',
    ),
    generateColumn(
      t({
        id: 'recycle.report.table.column.customer',
        message: `Customer name`,
      }),
      'customer',
    ),
    generateColumn(
      t({
        id: 'recycle.report.table.column.customer.email',
        message: `Customer email`,
      }),
      'customerEmail',
    ),
    generateColumn(
      t({
        id: 'recycle.report.table.column.customer.id',
        message: `Customer ID`,
      }),
      'customerID',
    ),
    generateColumn(
      t({
        id: 'recycle.report.table.column.store.name',
        message: `Store name`,
      }),
      'storeName',
    ),
    generateColumn(
      t({ id: 'recycle.report.table.column.store.id', message: `FT store ID` }),
      'storeID',
    ),
    generateColumn(
      t({
        id: 'recycle.report.table.column.store.external.id',
        message: `Partner store ID`,
      }),
      'storeRetailerSID',
    ),
    generateColumn(
      t({
        id: 'recycle.report.table.column.order.date',
        message: `Order date`,
      }),
      'orderDate',
    ),
    generateColumn(
      t({
        id: 'recycle.report.table.column.collection.status',
        message: `Collection transaction status`,
      }),
      'status',
    ),
    generateColumn(
      t({
        id: 'recycle.report.table.column.collection.name',
        message: `Collection transaction name`,
      }),
      'collectionName',
    ),
    generateColumn(
      t({
        id: 'recycle.report.table.column.collection.number',
        message: `Collection transaction number`,
      }),
      'collectionNumber',
    ),
  ];

  const policeReportColumns = [
    {
      key: 'storeName',
      header: t({
        id: 'recycle.report.table.column.store.name',
        message: `Store name`,
      }),
    },
    {
      key: 'orderID',
      header: t({
        id: 'recycle.report.table.column.order.id',
        message: `Quote ID`,
      }),
    },
    {
      key: 'customerName',
      header: t({
        id: 'recycle.report.table.column.customer',
        message: `Customer name`,
      }),
    },
    {
      key: 'idNumber',
      header: t({
        id: 'recycle.report.table.column.idNumber',
        message: `ID Number`,
      }),
    },
    {
      key: 'address',
      header: t({
        id: 'recycle.report.table.column.address',
        message: `Address`,
      }),
    },
    {
      key: 'city',
      header: t({ id: 'recycle.report.table.column.city', message: `City` }),
    },
    {
      key: 'province',
      header: t({
        id: 'recycle.report.table.column.province',
        message: `Province`,
      }),
    },
    {
      key: 'deviceName',
      header: t({
        id: 'recycle.report.table.column.device.name',
        message: `Device Name`,
      }),
    },
    {
      key: 'deviceReference',
      header: t({
        id: 'recycle.report.table.column.device.reference',
        message: `Device reference`,
      }),
    },
    {
      key: 'value',
      header: t({
        id: 'recycle.report.table.column.paid.customer',
        message: `Paid to customer`,
      }),
    },
    {
      key: 'dateTime',
      header: t({
        id: 'recycle.report.table.column.order.date',
        message: `Order date`,
      }),
    },
  ];

  const generateInitialValues = () => ({
    orderStatus: REPORTS_STATUS_ALL,
    startDate: formatDate(new Date(new Date().getTime() - weekInMillis)),
    endDate: formatDate(new Date()),
  });

  const searchRecycleReports = (values) =>
    dispatch(listRecycleOrdersAction(values));

  const exportPoliceReports = (values) =>
    dispatch(getRecyclePoliceReportAction(values, policeReportColumns));

  useEffect(() => {
    dispatch(listRecycleOrdersAction(generateInitialValues()));
  }, [dispatch]);

  //TODO: Refactor this when https://trello.com/c/kE0wxJ4Q/87-add-onclick-behavior-to-tabbedcontent is deployed
  const filterFormIsUnfocused = () => typeof recycleFilterForm === 'undefined';

  if (!hasAuthorization) return renderUnauthorized();
  return (
    <Fragment>
      {loading && <Loader />}
      <Heading classes='recycle-report__title'>
        <Trans id='recycle.report.title'>Recycle Reports</Trans>
      </Heading>

      <Section className='recycle-report__filter-form-wrapper'>
        <div className='recycle-report__filter-form'>
          <Heading size='medium'>
            <Trans id='recycle.report.filter.reports'>Search Reports</Trans>
          </Heading>
          {hasRecyclePoliceReport && (
            <TabbedContent
              content={[
                {
                  tab: (
                    <Trans id='recycle.report.tab.reports'>
                      Recycle Reports
                    </Trans>
                  ),
                  content: (
                    <RecycleReportsForm
                      formName='recycleFilterForm'
                      formValues={recycleFilterForm?.values}
                      activeFields={[
                        'orderStatus',
                        'orderID',
                        'deviceReference',
                        'startDate',
                        'endDate',
                      ]}
                      onSubmit={searchRecycleReports}
                      initialValues={generateInitialValues()}
                      submitLabel={t({
                        id: 'recycle.report.filter.submit.button',
                        message: `Search`,
                      })}
                    />
                  ),
                },
                {
                  tab: (
                    <Trans id='recycle.report.tab.police'>Police Reports</Trans>
                  ),
                  content: (
                    <RecycleReportsForm
                      formName='recyclePoliceForm'
                      formValues={hasRecyclePoliceReport?.values}
                      activeFields={['policeStatus', 'startDate', 'endDate']}
                      onSubmit={exportPoliceReports}
                      initialValues={{
                        policeStatus: REPORTS_STATUS_ALL,
                        startDate: formatDate(
                          new Date(new Date().getTime() - weekInMillis),
                        ),
                        endDate: formatDate(new Date()),
                      }}
                      submitLabel={t({
                        id: 'recycle.report.police.submit.button',
                        message: `Export`,
                      })}
                    />
                  ),
                },
              ]}
            />
          )}

          {!hasRecyclePoliceReport && (
            <RecycleReportsForm
              formName='recycleFilterForm'
              formValues={recycleFilterForm?.values}
              activeFields={[
                'orderStatus',
                'orderID',
                'deviceReference',
                'startDate',
                'endDate',
              ]}
              onSubmit={searchRecycleReports}
              initialValues={generateInitialValues()}
              submitLabel={t({
                id: 'recycle.report.filter.submit.button',
                message: `Search`,
              })}
            />
          )}
        </div>
      </Section>

      <Section
        className={classNames('recycle-report__summary-table-wrapper', {
          'recycle-report__summary-table-wrapper--disabled':
            filterFormIsUnfocused(),
        })}
      >
        <div className='recycle-report__summary-table'>
          <Heading size='medium'>
            <Trans id='recycle.report.table.summary'>Report summary</Trans>
          </Heading>
          <Table
            name={'summary'}
            columns={summaryColumns}
            data={summary}
            keyColumn='status'
            defaultOrder='status'
            defaultDir='desc'
            pageable={false}
            filterable={false}
          />
          <Button
            onClick={clickHandlerExportTable}
            classes='recycle-report__export-button'
          >
            <Trans id='recycle.report.export.summary'>Export to Excel</Trans>
          </Button>
        </div>
      </Section>

      <Section
        className={classNames('recycle-report__details-table-wrapper', {
          'recycle-report__details-table-wrapper--disabled':
            filterFormIsUnfocused(),
        })}
      >
        <div className='recycle-report__devices-table'>
          <Heading size='medium'>
            <Trans id='recycle.report.table.devices'>Device list</Trans>
          </Heading>
          <Table
            columns={deviceListColumns}
            data={devices}
            keyColumn='keyID'
            defaultOrder='deviceName'
            defaultDir='desc'
            stickyCol='deviceName'
            withOverflow
            filterable={false}
            pageable={false}
          />
        </div>
      </Section>

      <Footer classes='main' copyright={false} />
    </Fragment>
  );
};

export default RecycleReports;
