import classNames from 'classnames';

import React from 'react';

import Footer from 'components/Footer';
import Heading from 'components/Heading';

import './SystemPage.styl';

const SystemPage = ({
  image,
  title,
  subtitle,
  body,
  backLinkText,
  withHeader,
}) => {
  const goBack = () => window.history.back();
  return (
    <div
      className={classNames('system-page__wrapper', {
        'system-page__wrapper--with-header': withHeader,
      })}
    >
      <div className='system-page__img'>
        <img src={image} alt='System Page' />
      </div>
      <div className='system-page__info-box'>
        <Heading size='big'>{title}</Heading>
        <Heading size='medium'>{subtitle}</Heading>

        <p>{body}</p>

        <p>
          <span onClick={goBack}>{backLinkText}</span>
        </p>
      </div>

      <Footer classes='main system-page__footer' copyright={false} />
    </div>
  );
};

export default SystemPage;
