import { getCampaignOffersAction } from 'actions/admin/campaign';
import { listDevicesAction } from 'actions/admin/device';
import {
  addNotificationAction,
  closeModalAction,
  hideLoaderAction,
  openModalAction,
  showLoaderAction,
} from 'actions/ui';
import { adminPost as post } from 'apis/v2';
import { uploadPost as upload } from 'apis/v2';

import {
  uploadFileFailure,
  uploadFilePartialSuccess,
  uploadFileSuccess,
  uploadOffersFileFailure,
} from 'constants/notifications';
import { LOADER_TYPE_PROCESSING } from 'constants/ui';

export const LIST_ACCESSORIES = 'LIST_ACCESSORIES';
export function getAccessoriesAction() {
  return async function (dispatch) {
    dispatch(showLoaderAction());

    const response = await post(LIST_ACCESSORIES);

    if (response.data.status === 'SUCCESS') {
      dispatch({
        type: LIST_ACCESSORIES,
        accessories: response?.data?.data?.accessories,
      });
    }

    dispatch(hideLoaderAction());
  };
}

export const UPLOAD_FILE = 'UPLOAD_FILE';
export function uploadFileAction({ file, type, campaignCode }) {
  return async function (dispatch) {
    dispatch(showLoaderAction(LOADER_TYPE_PROCESSING));

    const response = await upload(UPLOAD_FILE, { file, type, campaignCode });

    const report = response?.data?.data;

    if (response.data.status !== 'ERROR') {
      if (report?.errorCount === 0 && report?.insertSuccessCount > 0) {
        dispatch(addNotificationAction(uploadFileSuccess()));
      } else if (report?.errorCount > 0 && report?.insertSuccessCount > 0) {
        dispatch(addNotificationAction(uploadFilePartialSuccess()));
      } else if (report?.errorCount > 0 && report?.insertSuccessCount === 0) {
        if (type === 'CRM_OFFERS') {
          dispatch(addNotificationAction(uploadOffersFileFailure()));
        } else {
          dispatch(addNotificationAction(uploadFileFailure()));
        }
      }

      if (report?.insertSuccessCount > 0 || report?.updateSuccessCount > 0) {
        if (type === 'ACCESSORIES') {
          await dispatch(getAccessoriesAction());
        } else if (type === 'DEVICES') {
          await dispatch(listDevicesAction());
        } else if (type === 'CRM_OFFERS') {
          await dispatch(getCampaignOffersAction(campaignCode));
        }
      }

      dispatch({
        type: UPLOAD_FILE,
        data: response.data.data,
      });

      dispatch(closeModalAction());

      response?.data?.data && dispatch(openModalAction('uploadReport'));
    }

    dispatch(hideLoaderAction());
  };
}
