import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import adminAcessories from 'reducers/admin/acessories';
import adminAttachments from 'reducers/admin/attachments';
import adminCampaign from 'reducers/admin/campaign';
import adminCollection from 'reducers/admin/collection';
import adminDevice from 'reducers/admin/device';
import adminStore from 'reducers/admin/store';
import adminTemplates from 'reducers/admin/templates';
import adminTestContracts from 'reducers/admin/testContracts';
import adminUser from 'reducers/admin/user';
import adminVoucher from 'reducers/admin/voucher';
import app from 'reducers/app';
import carts from 'reducers/carts';
import catalog from 'reducers/catalog';
import contract from 'reducers/contract';
import customer from 'reducers/customer';
import dashboard from 'reducers/dashboard';
import flow from 'reducers/flow';
import initialState from 'reducers/initialState';
import recycle from 'reducers/recycle';
import reports from 'reducers/reports';
import sales from 'reducers/sales';
import ui from 'reducers/ui';
import user from 'reducers/user';
import whitelabel from 'reducers/whitelabel';

const store = combineReducers({
  adminUser,
  adminStore,
  adminDevice,
  adminAcessories,
  adminCollection,
  adminCampaign,
  adminTemplates,
  adminAttachments,
  adminTestContracts,
  adminVoucher,
  app,
  carts,
  catalog,
  contract,
  customer,
  dashboard,
  form,
  flow,
  recycle,
  reports,
  sales,
  ui,
  user,
  whitelabel,
});

const root = (state, action) => {
  if (action.type === 'CLEAN_STORE') {
    return store(
      {
        ...initialState,
        whitelabel: state.whitelabel,
        user: state.user,
        carts: state.carts,
        ui: state.ui,
        form: {
          selectStore: state.form.selectStore,
          language: state.form.language,
        },
      },
      action,
    );
  }

  return store(state, action);
};

export default root;
