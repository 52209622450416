// Taken from https://github.com/mehmetkose/react-websocket/blob/master/index.jsx
import PropTypes from 'prop-types';

import React, { Component, Fragment } from 'react';

class Websocket extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ws: new WebSocket(this.props.url, this.props.protocol),
      attempts: 1,
    };
  }

  componentDidUpdate(prevProps) {
    const { url } = this.props;
    if (prevProps.url !== url) {
      this.shouldReconnect = false;
      let websocket = this.state.ws;
      websocket.close();

      this.setState({
        ws: new WebSocket(url, this.props.protocol),
      });
      this.shouldReconnect = true;
    }
  }

  logging(logline) {
    if (this.props.debug === true) {
      // eslint-disable-next-line no-console
      console.log(logline);
    }
  }

  generateInterval(k) {
    if (this.props.reconnectIntervalInMilliSeconds > 0) {
      return this.props.reconnectIntervalInMilliSeconds;
    }

    return Math.min(30, Math.pow(2, k) - 1) * 1000;
  }

  setupWebsocket = () => {
    let websocket = this.state.ws;

    websocket.onopen = () => {
      this.logging('Websocket connected');
      if (typeof this.props.onOpen === 'function') {
        this.props.onOpen(websocket);
      }
    };

    websocket.onmessage = (evt) => {
      this.props.onMessage(evt.data);
    };

    this.shouldReconnect = this.props.reconnect;
    websocket.onclose = () => {
      this.logging('Websocket disconnected');
      if (typeof this.props.onClose === 'function') this.props.onClose();
      if (this.shouldReconnect) {
        let time = this.generateInterval(this.state.attempts);

        this.timeoutID = setTimeout(() => {
          this.setState({ attempts: this.state.attempts + 1 });

          this.setState({
            ws: new WebSocket(this.props.url, this.props.protocol),
          });

          this.setupWebsocket();
        }, time);
      }
    };
  };

  componentDidMount() {
    this.setupWebsocket();
  }

  componentWillUnmount() {
    this.shouldReconnect = false;

    clearTimeout(this.timeoutID);

    let websocket = this.state.ws;

    websocket.close();
  }

  sendMessage(message) {
    let websocket = this.state.ws;
    websocket.send(message);
  }

  render() {
    return <Fragment />;
  }
}

Websocket.defaultProps = {
  debug: false,
  reconnect: true,
};

Websocket.propTypes = {
  url: PropTypes.string.isRequired,
  onMessage: PropTypes.func.isRequired,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  send: PropTypes.func,
  debug: PropTypes.bool,
  reconnect: PropTypes.bool,
  protocol: PropTypes.string,
  reconnectIntervalInMilliSeconds: PropTypes.number,
};

export default Websocket;
