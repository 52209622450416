import { uploadFileAction } from 'actions/admin/accessories';
import {
  getCampaignAction,
  getCampaignOffersAction,
  updateCampaignAction,
} from 'actions/admin/campaign';
import { exportEntityAction } from 'actions/reports';
import { closeModalAction, openModalAction } from 'actions/ui';
import { ArcElement, Chart } from 'chart.js';

import React, { Fragment, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';

import Button from 'components/Button';
import Heading from 'components/Heading';
import RenderDetails from 'components/RenderDetails';
import TabbedContent from 'components/TabbedContent.js';
import CampaignForm from 'components/campaign_form/CampaignForm';
import FileUploadModal from 'components/file_upload/FileUploadModal';
import UploadReport from 'components/file_upload/UploadReport';

import { triggerEventsDict } from 'constants/dictionaries';
import { campaignStatus } from 'constants/status';
import { ADMIN } from 'constants/user';

import './CampaignDetails.styl';
import CampaignReports from './components/CampaignReports';
import CohortForm from './components/CohortForm';
import CohortRules from './components/CohortRules';
import CommunicationForm from './components/CommunicationForm';
import Communications from './components/Communications';
import OfferForm from './components/OfferForm';
import OfferRules from './components/OfferRules';
import OfferRulesForm from './components/OfferRulesForm';
import Offers from './components/Offers';

Chart.register(ArcElement);

export const CampaignDetails = ({
  campaign = {},
  communications = [],
  currency,
  language,
  getCampaign,
  getCampaignOffers,
  offers = [],
  offerRules = [],
  updateCampaign,
  closeModal,
  openModal,
  uploadFile,
  uploadReport,
  exportEntity,
}) => {
  const isAdmin = ADMIN === useSelector(({ user }) => user.role);

  const { campaignCode } = useParams();

  const [requestCampaignData, setRequestCampaignData] = useState(true);

  const [campaignCodeData, setCampaignCodeData] = useState(null);
  const resetCampaignCode = () => setCampaignCodeData(null);

  const [cohortData, setCohortData] = useState({});
  const resetCohortData = () => setCohortData({});

  const [offerData, setOfferData] = useState({});
  const resetOfferData = () => setOfferData({});

  const [offerRuleData, setOfferRuleData] = useState({});
  const resetOfferRuleData = () => setOfferRuleData({});

  const [communicationData, setCommunicationData] = useState({});
  const resetCommunicationData = () => setCommunicationData({});

  const selectCommunicationData = (values) => {
    setCommunicationData({
      ...values,
    });
  };

  useEffect(() => {
    if (requestCampaignData) {
      getCampaign(campaignCode);
      getCampaignOffers(campaignCode);
      setRequestCampaignData(false);
    }
  }, [requestCampaignData, campaignCode, getCampaign, getCampaignOffers]);

  const setRequestCampaignDataTrue = () => setRequestCampaignData(true);
  const setCampaignCode = () => setCampaignCodeData(campaignCode);

  const enableCampaign = () =>
    updateCampaign({ ...campaign, status: 'ENABLED' });

  const disableCampaign = () =>
    updateCampaign({ ...campaign, status: 'DISABLED' });

  const config = [
    {
      label: t({ id: 'campaign.details.code', message: `Campaign code` }),
      accessor: 'campaignCode',
      type: 'text',
    },
    {
      label: t({ id: 'campaign.details.description', message: `Description` }),
      accessor: 'description',
      type: 'text',
    },
    {
      label: t({
        id: 'campaign.details.marketing',
        message: `Marketing opt in`,
      }),
      accessor: 'marketing',
      type: 'text',
    },
    {
      label: t({ id: 'campaign.details.status', message: `Campaign status` }),
      accessor: 'status',
      type: 'text',
      list: campaignStatus(),
    },
    {
      label: t({
        id: 'campaign.details.start',
        message: `Campaign start date`,
      }),
      accessor: 'startDate',
      type: 'date',
    },
    {
      label: t({ id: 'campaign.details.end', message: `Campaign end date` }),
      accessor: 'endDate',
      type: 'date',
    },
    {
      label: t({
        id: 'campaign.details.sales.channel',
        message: `Sales channel`,
      }),
      accessor: 'channel',
      type: 'text',
    },
    {
      label: t({
        id: 'campaign.details.trigger.event',
        message: `Trigger event`,
      }),
      accessor: 'triggerEvent',
      type: 'dict',
      list: triggerEventsDict(),
    },
    {
      label: t({
        id: 'campaign.details.trigger.offset',
        message: `Trigger days offset`,
      }),
      accessor: 'triggerDaysOffset',
      type: 'text',
    },
    {
      label: t({
        id: 'campaign.details.trigger.start.end',
        message: `Trigger start date`,
      }),
      accessor: 'triggerDateRangeStart',
      type: 'date',
    },
    {
      label: t({
        id: 'campaign.details.trigger.end.date',
        message: `Trigger end date`,
      }),
      accessor: 'triggerDateRangeEnd',
      type: 'date',
    },
  ];

  return (
    <Fragment>
      <div className='campaign-settings'>
        <Heading size='big'>
          {campaign?.description} ({campaignCode})
        </Heading>

        <RenderDetails
          config={config}
          data={campaign}
          name={'campaign-details'}
          size='small'
        />

        <CampaignForm
          update={true}
          onOpen={setCampaignCode}
          onClose={resetCampaignCode}
          campaignCode={campaignCodeData}
        />

        {!campaignCodeData && (
          <Fragment>
            {campaign?.status === 'ENABLED' ? (
              <Button
                classes='red'
                onClick={disableCampaign}
                testSelector='disable-campaign'
              >
                <Trans id='campaign.settings.disable.campaign'>
                  Disable Campaign
                </Trans>
              </Button>
            ) : (
              <Button onClick={enableCampaign} testSelector='enable-campaign'>
                <Trans id='campaign.settings.enable.campaign'>
                  Enable Campaign
                </Trans>
              </Button>
            )}
          </Fragment>
        )}
        <TabbedContent
          theme='filled'
          content={[
            {
              tab: <Trans id='campaign.reports.title'>Reports</Trans>,
              content: <CampaignReports campaignCode={campaignCode} />,
            },
            {
              tab: <Trans id='campaign.cohort.title'>Cohort Rules</Trans>,
              content: (
                <Fragment>
                  <CohortForm
                    onSubmit={setRequestCampaignDataTrue}
                    initialValues={cohortData}
                    onClose={resetCohortData}
                  />
                  <CohortRules
                    rules={campaign?.cohortRules}
                    selectCohort={setCohortData}
                    currency={currency}
                    language={language}
                  />
                </Fragment>
              ),
            },
            {
              tab: (
                <Trans id='campaign.communications.title'>Communications</Trans>
              ),
              content: (
                <Fragment>
                  <CommunicationForm
                    onSubmit={setRequestCampaignDataTrue}
                    initialValues={{ stopOutcome: 'SUCCESS' }}
                    values={communicationData}
                    onClose={resetCommunicationData}
                  />
                  <Communications
                    selectCommunication={selectCommunicationData}
                    communications={Object.values(communications)?.filter(
                      (communication) =>
                        communication.commsType !== 'LANDING_PAGE',
                    )}
                    campaignCode={campaignCode}
                    commsType={campaign?.commsType}
                    currency={currency}
                    language={language}
                  />
                </Fragment>
              ),
            },
            {
              tab: <Trans id='campaign.offer.title'>Offers</Trans>,
              showTitle: false,
              content: (
                <Fragment>
                  <div className='campaign-offer-actions'>
                    <Heading size='medium'>
                      <Trans id='campaign.offer.rules.title'>Offer Rules</Trans>
                    </Heading>
                    <Button
                      onClick={() => openModal('fileUpload')}
                      classes='admin-main-action'
                      testSelector='upload-offers'
                      disabled={!isAdmin}
                    >
                      <Trans id='admin.upload.offers'>Upload</Trans>
                    </Button>
                    <FileUploadModal
                      type='CRM_OFFERS'
                      handleUpload={uploadFile}
                      title='Upload File'
                      closeModal={closeModal}
                      accept='.xlsx'
                      campaignCode={campaignCode}
                    />
                    <UploadReport report={uploadReport} />
                    <Button
                      onClick={() =>
                        exportEntity('CRM_OFFERS', 'XLSX', {
                          campaignID: campaignCode,
                        })
                      }
                      classes='admin-main-action'
                      testSelector='export-offers'
                      disabled={!isAdmin}
                    >
                      <Trans id='admin.export.offers'>Export</Trans>
                    </Button>
                  </div>
                  <OfferRulesForm
                    onSubmit={setRequestCampaignDataTrue}
                    initialValues={offerRuleData}
                    onClose={resetOfferRuleData}
                  />
                  <OfferRules
                    offerRules={offerRules}
                    selectOfferRule={setOfferRuleData}
                    currency={currency}
                    language={language}
                  />
                  <Heading size='medium'>
                    <Trans id='campaign.offer.title'>Offers</Trans>
                  </Heading>
                  <OfferForm
                    onSubmit={setRequestCampaignDataTrue}
                    initialValues={offerData}
                    onClose={resetOfferData}
                  />
                  <Offers
                    offers={offers}
                    selectOffer={setOfferData}
                    currency={currency}
                    language={language}
                  />
                </Fragment>
              ),
            },
            campaign?.commsType !== 'MARKETING' && {
              tab: (
                <Trans id='campaign.landing.pages.title'>Landing Pages</Trans>
              ),
              content: (
                <>
                  <CommunicationForm
                    name='landingPageForm'
                    onSubmit={setRequestCampaignDataTrue}
                    initialValues={communicationData}
                    onClose={resetCommunicationData}
                  />
                  <Communications
                    formName='landingPageForm'
                    selectCommunication={selectCommunicationData}
                    communications={Object.values(communications)?.filter(
                      (communication) =>
                        communication?.commsType === 'LANDING_PAGE',
                    )}
                    commsType={campaign?.commsType}
                    currency={currency}
                    language={language}
                  />
                </>
              ),
            },
          ]}
        />
      </div>
    </Fragment>
  );
};

export default connect(
  ({ adminCampaign, whitelabel, adminAcessories }) => ({
    campaign: adminCampaign?.campaign,
    offers: adminCampaign?.offers,
    offerRules: adminCampaign?.offerRules,
    communications: adminCampaign?.campaign?.campaignActions,
    currency: whitelabel.currency,
    language: whitelabel.language,
    uploadReport: adminAcessories?.uploadReport,
  }),
  {
    getCampaign: getCampaignAction,
    getCampaignOffers: getCampaignOffersAction,
    openModal: openModalAction,
    closeModal: closeModalAction,
    updateCampaign: updateCampaignAction,
    uploadFile: uploadFileAction,
    exportEntity: exportEntityAction,
  },
)(CampaignDetails);
