import React from 'react';

import { Trans } from '@lingui/macro';

const SaleEmpty = () => {
  return (
    <div className='sale-not-found'>
      <h2>
        <Trans id='sale.not.found.title'>Sale Not Found</Trans>
      </h2>

      <p className='sale-not-found-desc'>
        <Trans id='sale.not.found.desc'>
          We couldn't retrieve the sale you've requested. Please make sure
          you're trying to retrieve a valid sale ID.
        </Trans>
      </p>
    </div>
  );
};

export default SaleEmpty;
