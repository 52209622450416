import { isDate, numericality, validStartToEndDate } from 'helpers/validators';

import React from 'react';

import { t } from '@lingui/macro';

import Dropdown from 'components/Dropdown';
import Form from 'components/Form';
import Input from 'components/Input';
import RenderDatePicker from 'components/RenderDatePicker';

import {
  REPORTS_STATUS_ALL,
  REPORTS_STATUS_CANCELLED,
  REPORTS_STATUS_ORDER_SUCCESSFUL,
  REPORTS_STATUS_PENDING,
} from 'constants/recycle';

const validateDates = ({ startDate: formStateDate, endDate: formEndDate }) => {
  const startDate = new Date(formStateDate);
  const endDate = new Date(formEndDate);

  return {
    ...isDate('startDate', startDate),
    ...isDate('endDate', endDate),
    ...validStartToEndDate(startDate, endDate),
  };
};

export const RecycleReportsForm = ({
  activeFields,
  formName,
  initialValues,
  onSubmit,
  submitLabel,
  destroyOnUnmount = true,
  enableReinitialize = true,
}) => {
  const fields = [
    {
      component: Dropdown,
      placeholder: t({
        id: 'recycle.report.filter.form.orderstatus',
        message: `Order Status`,
      }),
      name: 'orderStatus',
      options: [
        {
          value: REPORTS_STATUS_ALL,
          label: t({ id: 'recycle.reports.filter.status.all', message: `All` }),
        },
        {
          value: REPORTS_STATUS_ORDER_SUCCESSFUL,
          label: t({
            id: 'recycle.reports.filter.status.successful',
            message: `Order Successful`,
          }),
        },
        {
          value: REPORTS_STATUS_PENDING,
          label: t({
            id: 'recycle.reports.filter.status.pending',
            message: `Pending`,
          }),
        },
        {
          value: REPORTS_STATUS_CANCELLED,
          label: t({
            id: 'recycle.reports.filter.status.cancelled',
            message: `Cancelled`,
          }),
        },
      ],
      variant: 'underline',
      testSelector: 'orderStatusFilter',
    },
    {
      component: Dropdown,
      placeholder: t({
        id: 'recycle.report.filter.form.orderstatus',
        message: `Order Status`,
      }),
      name: 'policeStatus',
      options: [
        {
          value: REPORTS_STATUS_ALL,
          label: t({ id: 'recycle.reports.filter.status.all', message: `All` }),
        },
      ],
      disabled: true,
      variant: 'underline',
      testSelector: 'orderStatusFilter',
    },
    {
      component: Input,
      placeholder: t({
        id: 'recycle.report.filter.form.orderid',
        message: `Quote ID`,
      }),
      name: 'orderID',
      variant: 'underline',
      testSelector: 'orderIDFilter',
      validate: [numericality({ greaterThan: 0, allowBlank: true })],
    },
    {
      component: Input,
      placeholder: t({
        id: 'recycle.report.filter.form.deviceref',
        message: `Device Reference`,
      }),
      name: 'deviceReference',
      variant: 'underline',
      testSelector: 'deviceReferenceFilter',
      maxStringSize: 60,
    },
    {
      component: RenderDatePicker,
      label: t({ id: 'recycle.report.filter.form.startDate', message: `From` }),
      name: 'startDate',
      testSelector: 'startDateFilter',
      classes: 'react-datepicker-recycle-reports',
      maxDate: new Date().toString(),
    },
    {
      component: RenderDatePicker,
      label: t({ id: 'recycle.report.filter.form.endDate', message: `To` }),
      name: 'endDate',
      testSelector: 'endDateFilter',
      classes: 'react-datepicker-recycle-reports',
      maxDate: new Date().toString(),
    },
  ].filter((field) => activeFields.includes(field.name));

  return (
    <Form
      destroyOnUnmount={destroyOnUnmount}
      enableReinitialize={enableReinitialize}
      form={formName}
      fields={fields}
      initialValues={{ ...initialValues }}
      key={`form-${formName}`}
      onSubmit={onSubmit}
      submitLabel={submitLabel}
      validate={validateDates}
    />
  );
};

export default RecycleReportsForm;
