import * as React from 'react';

function SvgIconLocked({ title, titleId, ...props }) {
  return (
    <svg
      viewBox='0 0 24 24'
      width='1em'
      height='1em'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <clipPath id='icon-locked_svg__b'>
          <path fill='none' d='M0 0h12v15H0z' />
        </clipPath>
        <clipPath id='icon-locked_svg__a'>
          <path d='M0 0h24v24H0z' />
        </clipPath>
      </defs>
      <g clipPath='url(#icon-locked_svg__a)'>
        <g clipPath='url(#icon-locked_svg__b)' transform='translate(6 4)'>
          <g fill='currentColor' strokeLinecap='round' strokeLinejoin='round'>
            <path d='M10.5 14.5h-9c-.551 0-1-.393-1-.875v-6.25c0-.482.449-.875 1-.875h1.25V3.75A3.254 3.254 0 016 .5a3.254 3.254 0 013.25 3.25V6.5h1.25c.551 0 1 .393 1 .875v6.25c0 .482-.449.875-1 .875zM1 7v7h10V7H1zm5-6a2.753 2.753 0 00-2.75 2.75V6.5h5.5V3.75A2.753 2.753 0 006 1zm0 9.75a.25.25 0 110-.5.25.25 0 010 .5z' />
            <path d='M10.5 15h-9C.673 15 0 14.383 0 13.625v-6.25C0 6.617.673 6 1.5 6h.75V3.75a3.75 3.75 0 117.5 0V6h.75c.827 0 1.5.617 1.5 1.375v6.25c0 .758-.673 1.375-1.5 1.375zm-9-7.5v6h9v-6h-9zm4.5-6a2.25 2.25 0 00-2.25 2.25V6h4.5V3.75A2.25 2.25 0 006 1.5zm0 9.75a.75.75 0 110-1.5.75.75 0 010 1.5z' />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgIconLocked;
