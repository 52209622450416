import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';

import { t } from '@lingui/macro';

import {
  adminAccessoriesUrl,
  adminAttachmentsURL,
  adminCampaignsUrl,
  adminCollectionsUrl,
  adminContractDateAdjustUrl,
  adminDevicesUrl,
  adminPricingToolUrl,
  adminResidualValuesSearchUrl,
  adminRulesUrl,
  adminStoresUrl,
  adminTemplateBlocksURL,
  adminTestContractsUrl,
  adminUserUrl,
  adminVouchersUrl,
  contractPendingUrl,
  recycleQuoteSearchUrl,
  recycleQuotesUrl,
  recycleReportsUrl,
  reportSettlementUrl,
  salesUrl,
  servicesUrl,
} from 'constants/routes';
import {
  ADMIN,
  STORE_MANAGER,
  STORE_PERSON,
  VENDOR_MANAGER,
} from 'constants/user';

export const mainNav = (menuConfigs, documentation = {}) => {
  const newCart = {
    id: 'new-cart',
    label: t({ id: 'navigation.home.title', message: `New Cart` }),
    minRole: STORE_PERSON,
    path: servicesUrl,
    options: [],
  };

  const contracts = {
    id: 'contracts',
    label: t({ id: 'navigation.contracts.title', message: `Contracts` }),
    minRole: STORE_PERSON,
    options: {
      pendings: {
        id: 'pendings',
        label: t({
          id: 'navigation.contracts.pendings.title',
          message: `Pendings`,
        }),
        path: contractPendingUrl,
        minRole: STORE_PERSON,
      },
      sales: {
        id: 'sales',
        label: t({ id: 'navigation.contracts.sales.tilte', message: `Sales` }),
        path: salesUrl,
        minRole: STORE_PERSON,
      },
      recycled: {
        id: 'recycled',
        label: t({
          id: 'navigation.contracts.recycle.title',
          message: `Recycled`,
        }),
        path: recycleQuotesUrl,
        minRole: STORE_PERSON,
      },
    },
  };

  const support = {
    id: 'support',
    isActive: true,
    label: t({ id: 'navigation.support.title', message: `Support` }),
    minRole: STORE_PERSON,
    options: Object.entries(documentation).reduce((acc, [label, url]) => {
      return {
        ...acc,
        [label]: {
          id: label,
          isActive: true,
          label: label,
          onClick: () => window.open(url, '_blank'),
          minRole: STORE_PERSON,
        },
      };
    }, {}),
  };

  const reports = {
    id: 'reports',
    label: t({ id: 'navigation.contracts.reports.title', message: `Reports` }),
    minRole: STORE_MANAGER,
    options: {
      recycle: {
        id: 'recycle',
        label: t({
          id: 'navigation.reports.recycle.title',
          message: `Recycle`,
        }),
        path: recycleReportsUrl,
        minRole: STORE_MANAGER,
      },
      settlements: {
        id: 'settlements',
        label: t({
          id: 'navigation.reports.settlements.title',
          message: `Settlements`,
        }),
        path: reportSettlementUrl,
        minRole: VENDOR_MANAGER,
      },
    },
  };

  const tradein = {
    id: 'tradein',
    label: t({ id: 'navigation.tradein.title', message: `Trade-in` }),
    minRole: STORE_PERSON,
    options: {
      onlineQuotesSearch: {
        id: 'online-quote-search',
        label: t({
          id: 'navigation.tradein.online.quotes.title',
          message: `Online quote search`,
        }),
        path: recycleQuoteSearchUrl,
        minRole: STORE_PERSON,
      },
    },
  };

  const admin = {
    id: 'admin',
    label: t({ id: 'navigation.admin.title', message: `Admin` }),
    minRole: STORE_PERSON,
    options: {
      stores: {
        id: 'stores',
        label: t({ id: 'navigation.stores.title', message: `Stores` }),
        path: adminStoresUrl,
        minRole: VENDOR_MANAGER,
      },
      users: {
        id: 'users',
        label: t({ id: 'navigation.users.title', message: `Users` }),
        path: adminUserUrl,
        minRole: STORE_MANAGER,
      },
      devices: {
        id: 'devices',
        label: t({ id: 'navigation.devices.title', message: `Devices` }),
        path: adminDevicesUrl,
        minRole: VENDOR_MANAGER,
      },
      accessories: {
        id: 'accessories',
        label: t({
          id: 'navigation.accessories.title',
          message: `Accessories`,
        }),
        path: adminAccessoriesUrl,
        minRole: VENDOR_MANAGER,
      },
      collections: {
        id: 'collections',
        label: t({
          id: 'navigation.collections.title',
          message: `Collections`,
        }),
        path: adminCollectionsUrl,
        minRole: STORE_PERSON,
      },
      contractDateAdjust: {
        id: 'contract-date-adjust',
        label: t({
          id: 'navigation.delorean.title',
          message: `Contract Date Adjust`,
        }),
        path: adminContractDateAdjustUrl,
        minRole: ADMIN,
      },
      pricingTool: {
        id: 'pricing-tool',
        label: t({ id: 'navigation.pricing.tool', message: `Pricing Tool` }),
        path: adminPricingToolUrl,
        minRole: VENDOR_MANAGER,
      },
      residualValues: {
        id: 'residual-values',
        label: t({
          id: 'navigation.residual.values',
          message: `Residual Values`,
        }),
        path: adminResidualValuesSearchUrl,
        minRole: VENDOR_MANAGER,
      },
      rules: {
        id: 'residual-values',
        label: t({ id: 'navigation.rules', message: 'Rules' }),
        path: adminRulesUrl,
        minRole: VENDOR_MANAGER,
        isMigrated: true,
      },
      vouchers: {
        id: 'vouchers',
        label: t({ id: 'navigation.vouchers.title', message: `Vouchers` }),
        path: adminVouchersUrl,
        minRole: VENDOR_MANAGER,
      },
    },
  };

  const crm = {
    id: 'crm',
    label: t({ id: 'navigation.crm.title', message: `CRM` }),
    minRole: VENDOR_MANAGER,
    options: {
      campaigns: {
        id: 'campaigns',
        label: t({ id: 'navigation.campaigns.title', message: `Campaigns` }),
        path: adminCampaignsUrl,
        minRole: VENDOR_MANAGER,
      },
      templateBlocks: {
        id: 'template-blocks',
        label: t({
          id: 'navigation.template.blocks.title',
          message: `Template Blocks`,
        }),
        path: adminTemplateBlocksURL,
        minRole: VENDOR_MANAGER,
      },
      testContracts: {
        id: 'test-contracts',
        label: t({ id: 'navigation.test.contract', message: `Test Contracts` }),
        path: adminTestContractsUrl,
        minRole: VENDOR_MANAGER,
      },
      attachments: {
        id: 'attachments',
        label: t({ id: 'navigation.crm.attachments', message: `Attachments` }),
        path: adminAttachmentsURL,
        minRole: ADMIN,
      },
    },
  };

  return merge(
    {
      newCart,
      contracts,
      reports,
      tradein,
      admin,
      crm,
      support: isEmpty(documentation) ? {} : support,
    },
    menuConfigs,
  );
};
