import React from 'react';

import { Trans } from '@lingui/macro';

import Heading from 'components/Heading';

import { availableParams } from 'constants/templates';

import IconCopy from './IconCopy';

const flattenObject = (ob) => {
  let toReturn = {};

  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if (typeof ob[i] === 'object') {
      let flatObject = flattenObject(ob[i]);

      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + '.' + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};

export const ParamDocs = ({ blocks = [], isData = false, setOpenDocs }) => {
  const copyAndClose = (text) => {
    navigator.clipboard.writeText(text);
    setOpenDocs('');
  };

  return (
    <div className='templateDocs'>
      <Heading size='small'>
        <Trans id='template.editor.docs.title'>
          Click to copy to clipboard
        </Trans>
      </Heading>

      {isData ? (
        <ul>
          {Object.entries(flattenObject(availableParams)).map(
            ([key, value]) => (
              // eslint-disable-next-line react/jsx-no-bind
              <li onClick={() => copyAndClose(`{{${key}}}`)}>
                {value}
                <IconCopy className='icon' />
              </li>
            ),
          )}
        </ul>
      ) : (
        <ul>
          {blocks?.map((block) => (
            // eslint-disable-next-line react/jsx-no-bind
            <li onClick={() => copyAndClose(`[[${block.key}]]`)}>
              {block.description ? block.description : block.key}
              <IconCopy className='icon' />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ParamDocs;
