import stringify from 'csv-stringify/lib/browser/sync';
import { sanitize } from 'dompurify';
import ReactHtmlParser from 'html-react-parser';
import { parse, toSeconds } from 'iso8601-duration';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import transform from 'lodash/transform';

import { secondInMillis } from 'constants/dates';
import { countries } from 'constants/forms';
import { STANDARD, UPGRADE } from 'constants/recycle';

// --- Text Utils ---------

export const snakeToText = (value) => {
  return value.split('').includes('_')
    ? value.toLowerCase().replace(/_/g, ' ')
    : value;
};

export const textToKebab = (value) => {
  return value.split('').includes(' ')
    ? value.toLowerCase().replace(/\b \b/g, '-')
    : value.toLowerCase();
};

export const snakeToCamel = (value) => {
  return value.toLowerCase().replace(/_\w/g, function (m) {
    return m[1].toUpperCase();
  });
};

export const capitalize = (string) => {
  if (typeof string === 'string')
    return string.toLowerCase().replace(/^\w/, (c) => c.toUpperCase());
  else return string;
};

export const pluralize = (count, noun, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`;

// // --- User Utils ---------

export const getStatusKey = (obj, value) =>
  Number(Object.keys(obj).find((key) => obj[key].status === value));

// // --- Data Converters ---------

export const convertArrayToCSV = (
  array,
  columns = [],
  quote = '"',
  delimiter = ',',
  escape_formulas = true,
) => {
  return stringify(array, {
    quoted: true,
    header: true,
    escape: quote,
    quote,
    columns,
    delimiter,
    escape_formulas,
  });
};

export const convertHTMLTableCSV = (table) => {
  const rows = table.querySelectorAll('tr');
  const csv = [];

  rows.forEach((row) => {
    const csvRow = [];
    const cols = row.querySelectorAll('td, th');

    cols.forEach((col) => {
      let string = col.textContent.replace('"', '""');
      csvRow.push(
        col.className === 'sortable'
          ? `"${string.slice(0, -2)}"`
          : `"${string}"`,
      );
    });
    csv.push(csvRow.join(','));
  });

  return csv.join('\n');
};

export const convertArrayToOptions = (array) => {
  return array?.map((elem) => {
    return { value: elem, label: capitalize(elem.replace(/_/g, ' ')) };
  });
};

export const convertParamsToObj = (params, arrayParams = []) =>
  [...params.keys()].reduce((acc, key) => {
    let param = params.getAll(key);
    acc[key] =
      param.length > 1 || arrayParams.includes(key) ? param : params.get(key);
    return acc;
  }, {});

// // --- Business Logic Utils ---------

export const getCartFromOrder = (orderUUID) =>
  orderUUID.match(/(\w+-){3}(\d+)/g)[0];

export const hasDevicesInOrders = (orders) => {
  return orders.some(
    (order) =>
      order?.products?.items.length > 0 || order?.extras?.items.length > 0,
  );
};

export const getCurrentCart = (carts, cartUUID) => {
  return carts.list.find((cart) => cart?.uuid === cartUUID);
};

export const createDevicesFilter = (valueAccessor = '', labelAccessor) => ({
  valueAccessor,
  labelAccessor: labelAccessor || valueAccessor,
});

export const generateDevicesFilterHierarchy = (dataArray = [], filters = []) =>
  dataArray.reduce((filterHierarchy, dataElement) => {
    filters.forEach((filter, index) => {
      const isLastFilter = index === filters.length - 1;

      let _filterHierarchy = filterHierarchy;

      for (let i = 0; i < index; i++) {
        const previousFilter = filters[i];

        _filterHierarchy =
          _filterHierarchy[dataElement[previousFilter.valueAccessor]].children;
      }

      if (!_filterHierarchy[dataElement[filter.valueAccessor]]) {
        _filterHierarchy[dataElement[filter.valueAccessor]] = {
          label: dataElement[filter.labelAccessor]
            ? dataElement[filter.labelAccessor]
            : dataElement[filter.valueAccessor],
          value: dataElement[filter.valueAccessor],
        };
        if (!isLastFilter)
          _filterHierarchy[dataElement[filter.valueAccessor]].children = {};
      }

      if (isLastFilter) {
        _filterHierarchy[dataElement[filter.valueAccessor]].device =
          dataElement;
      }
    });

    return filterHierarchy;
  }, {});

// // --- Date/Location Utils ---------

export const secondsToMillis = (seconds) => seconds * secondInMillis;

export const currencySymbolToISO3 = (symbol) => {
  switch (symbol) {
    case '£':
      return 'GBP';
    default:
      return symbol;
  }
};

export const getCountryLabel = (countryCode) => {
  return countries().find((country) => country.value === countryCode).label;
};

export const getDurationSeconds = (date) => {
  return date && toSeconds(parse(date));
};

export const getLanguageName = (locale) =>
  capitalize(new Intl.DisplayNames([locale], { type: 'language' }).of(locale));

// // --- Trade-in/Swap Utils ---------

export const getPlanNameByOutcome = (outcome, upgradePlan, standardPlan) =>
  outcome === UPGRADE ? upgradePlan : outcome === STANDARD ? standardPlan : '';

export const allGradingQuestionsAnswered = (questions) =>
  isEmpty(questions)
    ? true
    : questions.every((question) => typeof question.selected === 'string');

export const anyCheckWasPerformed = (checkmendOk, fmi) =>
  typeof checkmendOk !== 'undefined' || typeof fmi !== 'undefined';

export const anyCheckFailed = (checkmendOk, fmi) =>
  anyCheckWasPerformed(checkmendOk, fmi) &&
  (!checkmendOk || includes(fmi, true));

export const transformCriteria = (criteria = []) =>
  criteria.reduce(
    (acc, option) => ({
      ...acc,
      [option.code]:
        typeof option.selected !== undefined && option.selected === 'yes',
    }),
    {},
  );

// // --- Request Utils ---------

export const getSubOrFullDomain = () =>
  window.location.hostname.match(/.*financetech(demo|test|365).com/g)
    ? window.location.hostname.split('.')[0]
    : window.location.hostname;

export const sanitizeRequestData = (data) => {
  return transform(
    data,
    (result, value, key) => {
      if (
        value === undefined ||
        value === null ||
        (typeof value === 'string' && value.length <= 0)
      ) {
        return;
      }
      result[key] = value;
    },
    {},
  );
};

export const safeHTMLParser = (html = '', config = {}) =>
  ReactHtmlParser(
    sanitize(html, {
      KEEP_CONTENT: false,
      ...config,
    }),
  );

export const fileDownload = (data, filename, mimeType) => {
  let blob = new Blob([data], { type: mimeType || 'application/octet-stream' });

  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
  } else {
    let blobURL = window.URL.createObjectURL(blob);
    let tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
  }
};

export const b64toBlob = (url) => fetch(url).then((r) => r.blob());

export const reduceCartOrders = (orders) =>
  orders?.reduce(
    (accumulator, currentValue) => ({
      ...accumulator,
      products: [
        ...accumulator.products,
        ...(currentValue.products?.items || []),
      ],
      extras: [...accumulator.extras, ...(currentValue.extras?.items || [])],
      discounts: [
        ...accumulator.discounts,
        ...(currentValue.discounts?.items || []),
      ],
      totalPrincipal:
        accumulator.totalPrincipal +
        (currentValue.financing?.deviceContractFinance?.principal || 0),
      totalMonthlyPayment:
        accumulator.totalMonthlyPayment +
        (currentValue.financing?.deviceContractFinance?.monthlyPayment || 0),
      maxDiscounts:
        accumulator.maxDiscounts + (currentValue.discounts?.max_discounts || 0),
      openingFeeAmount:
        accumulator.openingFeeAmount +
        (currentValue.financing?.openingFeeAmount || 0),
      totalDiscounts:
        accumulator.totalDiscounts +
        (currentValue.discounts?.total_discounts || 0),
      totalMonthlyDiscounts:
        accumulator.totalMonthlyDiscounts +
        (currentValue.discounts?.monthly_total_discounts || 0),
    }),
    {
      products: [],
      extras: [],
      discounts: [],
      totalPrincipal: 0,
      totalMonthlyPayment: 0,
      maxDiscounts: 0,
      openingFeeAmount: 0,
      totalDiscounts: 0,
      totalMonthlyDiscounts: 0,
    },
  ) || {};

export const isImg = (path) =>
  path?.match(/\.(jpe?g|webp|gif|png|svg|bmp)(\?d=([0-9]+)x([0-9]+))?$/i) !=
  null;

export const isFromCDN = (path) =>
  path?.match(/https?:\/\/cdn.(financetech365|financetechtest).com\/.+/i) !=
  null;
