import {
  createUpgradeOfferRuleAction,
  getOffersFormDataAction,
  updateUpgradeOfferRuleAction,
} from 'actions/admin/campaign';
import { required } from 'helpers/validators';
import isEmpty from 'lodash/isEmpty';
import { clearFields, getFormValues } from 'redux-form';

import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import ButtonForm from 'components/ButtonForm';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import RenderDatePicker from 'components/RenderDatePicker';

const OfferRulesForm = ({
  campaignID,
  manufacturers,
  deviceCategories,
  deviceFamilies,
  devices,
  financingTypes,
  statuses,
  getOffersFormData,
  createUpgradeOfferRule,
  updateUpgradeOfferRule,
  clearFormFields,
  onSubmit,
  onClose,
  formValues,
  initialValues,
}) => {
  const fields = [
    {
      component: Dropdown,
      clearable: true,
      options: manufacturers,
      placeholder: t({
        id: 'forms.offer.placeholder.manufacturer',
        message: `Manufacturer`,
      }),
      name: 'manufacturer',
      size: 'medium',
      testSelector: 'addOfferRuleManufacturerDropdown',
      variant: 'underline',
      disabled: isEmpty(manufacturers),
      onChange: (value) => {
        getOffersFormData({ manufacturer: value });
        clearFormFields(
          'offerForm',
          false,
          false,
          'category',
          'family',
          'deviceCode',
        );
      },
    },
    {
      component: Dropdown,
      clearable: true,
      options: deviceCategories,
      placeholder: t({
        id: 'forms.offer.placeholder.device.category',
        message: `Device Category`,
      }),
      name: 'category',
      size: 'medium',
      testSelector: 'addOfferRuleDeviceCategoryDropdown',
      variant: 'underline',
      disabled: isEmpty(deviceCategories),
      onChange: (value) => {
        getOffersFormData({
          manufacturer: formValues.manufacturer,
          category: value,
        });
        clearFormFields('offerForm', false, false, 'family', 'deviceCode');
      },
    },
    {
      component: Dropdown,
      clearable: true,
      options: deviceFamilies,
      placeholder: t({
        id: 'forms.offer.placeholder.device.family',
        message: `Device Family`,
      }),
      name: 'family',
      size: 'medium',
      testSelector: 'addOfferRuleDeviceFamilyDropdown',
      variant: 'underline',
      disabled: isEmpty(deviceFamilies),
      onChange: (value) => {
        getOffersFormData({
          manufacturer: formValues.manufacturer,
          category: formValues.category,
          family: value,
        });
        clearFormFields('offerForm', false, false, 'deviceCode');
      },
    },
    {
      component: Dropdown,
      clearable: true,
      options: devices,
      disabled: isEmpty(devices),
      placeholder: t({
        id: 'forms.offer.placeholder.device.code',
        message: `Device Code`,
      }),
      name: 'deviceCode',
      size: 'medium',
      testSelector: 'addOfferRuleDeviceCodeDropdown',
      variant: 'underline',
    },
    {
      component: Dropdown,
      clearable: true,
      placeholder: t({
        id: 'forms.offer.placeholder.financing.type',
        message: `Financing Type`,
      }),
      options: financingTypes,
      name: 'financingType',
      size: 'medium',
      testSelector: 'addOfferRuleFinancingTypeDropdown',
      variant: 'underline',
      validate: [required()],
    },
    {
      component: Dropdown,
      clearable: true,
      placeholder: t({
        id: 'forms.offer.placeholder.duration',
        message: `Number of Months`,
      }),
      options: [
        { label: '12', value: 12 },
        { label: '24', value: 24 },
        { label: '36', value: 36 },
      ],
      helpText: t({
        id: 'forms.offer.rule.duration.text',
        message: `Number of months that applies to the offer`,
      }),
      name: 'numberOfMonths',
      size: 'medium',
      testSelector: 'addOfferRuleMonthsDropdown',
      variant: 'underline',
    },
    {
      component: Input,
      placeholder: t({
        id: 'forms.offer.placeholder.group',
        message: `Offer Group`,
      }),
      name: 'offerGroup',
      size: 'medium',
      variant: 'underline',
      validate: [required()],
    },
    {
      component: RenderDatePicker,
      label: t({
        id: 'forms.offer.placeholder.start.date',
        message: `Start Date`,
      }),
      name: 'startDate',
      validate: [required()],
      maxDate: formValues?.endDate,
    },
    {
      component: RenderDatePicker,
      label: t({ id: 'forms.offer.placeholder.end.date', message: `End Date` }),
      name: 'endDate',
      minDate: formValues?.startDate,
    },
    {
      component: Dropdown,
      clearable: true,
      placeholder: t({
        id: 'forms.offer.placeholder.action',
        message: `Action`,
      }),
      options: [
        { label: 'Exclude', value: 'EXCLUDE' },
        { label: 'Include', value: 'INCLUDE' },
      ],
      name: 'action',
      size: 'medium',
      testSelector: 'addOfferRuleActionDropdown',
      variant: 'underline',
      validate: [required()],
    },
    {
      component: Dropdown,
      clearable: true,
      placeholder: t({
        id: 'forms.offer.placeholder.status',
        message: `Status`,
      }),
      options: statuses,
      name: 'status',
      size: 'medium',
      testSelector: 'addOfferRuleStatusDropdown',
      variant: 'underline',
      validate: [required()],
    },
  ];

  const getFormDataCallBack = useCallback(() => {
    const data = formValues || initialValues;

    getOffersFormData({
      manufacturer: data?.manufacturer,
      category: data?.category,
      family: data?.family,
    });
  }, [formValues, initialValues, getOffersFormData]);

  const handleSubmit = async (values) => {
    const request = isEmpty(initialValues)
      ? createUpgradeOfferRule
      : updateUpgradeOfferRule;

    return await request({
      id: initialValues?.id,
      campaignID,
      ...values,
      manufacturerName: manufacturers?.find(
        ({ value }) => values?.manufacturer === value,
      )?.label,
      deviceName: devices?.find(({ value }) => values?.deviceCode === value)
        ?.label,
      familyName: deviceFamilies?.find(({ value }) => values?.family === value)
        ?.label,
      categoryName: deviceCategories?.find(
        ({ value }) => values?.category === value,
      )?.label,
    });
  };

  return (
    <ButtonForm
      name='offerRulesForm'
      getFormData={getFormDataCallBack}
      fields={fields}
      title={<Trans id='offer.rule.form.add.title'>Add Offer Rule</Trans>}
      openBtnLabel={
        <Trans id='admin.offer.rule.add.button'>Add Offer Rule</Trans>
      }
      toggleShowForm={!isEmpty(initialValues)}
      handleSubmit={handleSubmit}
      onSubmitSuccess={onSubmit}
      onClose={onClose}
      values={initialValues}
    />
  );
};

export default connect(
  (state) => ({
    formValues: getFormValues('offerRulesForm')(state),
    campaignID: state.adminCampaign.campaign.campaignCode,
    deviceCategories: state.adminCampaign.deviceCategories,
    deviceFamilies: state.adminCampaign.deviceFamilies,
    devices: state.adminCampaign.devices,
    financingTypes: state.adminCampaign.financingTypes,
    manufacturers: state.adminCampaign.manufacturers,
    statuses: state.adminCampaign.statuses,
  }),
  {
    getOffersFormData: getOffersFormDataAction,
    createUpgradeOfferRule: createUpgradeOfferRuleAction,
    updateUpgradeOfferRule: updateUpgradeOfferRuleAction,
    clearFormFields: clearFields,
  },
)(OfferRulesForm);
