import { addNotificationAction } from 'actions/ui';
import {
  getUserInfoAction,
  loginAction,
  singleSignOnAction,
} from 'actions/user';
import { changeLoginMethodAction } from 'actions/whitelabel';
import { required } from 'helpers/validators';
import Mousetrap from 'mousetrap';
import { Field, reduxForm } from 'redux-form';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';

import Button from 'components/Button';
import LoginLayout from 'components/LoginLayout';
import PasswordInput from 'components/PasswordInput';
import RenderField from 'components/RenderField';

import { errorCodes, notificationTitle } from 'constants/errors';
import {
  adminStoresUrl,
  changePasswordUrl,
  loginRecoverUrl,
  servicesUrl,
} from 'constants/routes';
import { ADMIN_WHITELIST } from 'constants/user';
import { userStatus } from 'constants/user';

import useQueryParams from 'hooks/useQueryParams';

const Login = ({ handleSubmit }) => {
  const [loading, setLoading] = useState(false);
  const { SSOToken } = useParams();
  const {
    queryParamsObject: { error },
  } = useQueryParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const hasSingleSignOn = useSelector(
    ({ whitelabel }) => whitelabel.configs.hasSingleSignOn,
  );
  const sessionToken = useSelector(({ user }) => user.session_token);
  const status = useSelector(({ user }) => user.status);

  const performLogin = async (formData) => {
    setLoading(true);

    const response = await dispatch(loginAction(formData));

    if (response) {
      const { role, wlHasStores } = response;

      if (!wlHasStores) {
        if (ADMIN_WHITELIST.includes(role)) {
          navigate(adminStoresUrl);
        } else {
          dispatch(
            addNotificationAction({
              title: notificationTitle().ERROR,
              message: errorCodes().NO_STORES,
              type: 'error',
            }),
          );
        }
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    Mousetrap.bind(['alt+l'], () => dispatch(changeLoginMethodAction()));

    if (SSOToken) dispatch(getUserInfoAction(SSOToken));

    return () => {
      Mousetrap.unbind(['alt+l']);
    };
  }, [SSOToken, dispatch]);

  useEffect(() => {
    if (error && error.length > 0) {
      let errorObj = JSON.parse(window.atob(error));
      dispatch(
        addNotificationAction({
          title: notificationTitle()[errorObj.apiStatus],
          message: `${errorCodes()[errorObj.code] || errorObj.message}`,
          type: errorObj.apiStatus.toLowerCase(),
          values: errorObj.values,
        }),
      );
    }
  }, [dispatch, error]);

  if (status === userStatus.pending) {
    navigate(changePasswordUrl);
  }
  if (sessionToken) {
    navigate(servicesUrl);
  }

  return (
    <LoginLayout>
      <h1 data-test='posale-login-page-title'>
        <Trans id='login.title'>Log In</Trans>
      </h1>

      {hasSingleSignOn ? (
        <>
          <p>
            <Trans id='login.sso.desc'>Use your company login solution.</Trans>
          </p>

          <Button
            classes='sso-button'
            onClick={() => dispatch(singleSignOnAction())}
          >
            <Trans id='login.sso'>Log in with your employee account</Trans>
          </Button>
        </>
      ) : (
        <>
          <p>
            <Trans id='login.manual.desc'>
              Use your company email address / username to log in.
            </Trans>
          </p>

          <form id={'login-form'} className={'auth-form'}>
            <Field
              name='login'
              type='text'
              validate={required()}
              component={RenderField}
              animateLabel={false}
              label={t({
                id: 'login.form.email',
                message: `Email Address / Username`,
              })}
              testSelector='posale-login-page-username'
            />

            <Field
              name='password'
              type='password'
              validate={required()}
              component={PasswordInput}
              animateLabel={false}
              label={t({ id: 'login.form.pwd', message: `Password` })}
              testSelector='posale-login-page-password'
            />

            <Link
              className='login-link'
              to={loginRecoverUrl}
              data-test='posale-login-page-forgot-link'
            >
              <Trans id='login.forgot.pwd'>Forgot Password?</Trans>
            </Link>
            <Button
              loading={loading}
              type='submit'
              disabled={loading}
              onClick={handleSubmit((formData) => performLogin(formData))}
              testSelector='posale-login-page-login-btn'
            >
              <Trans id='login.button'>Login</Trans>
            </Button>
          </form>
        </>
      )}
    </LoginLayout>
  );
};

export default reduxForm({
  form: 'login-form',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(Login);
