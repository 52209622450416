import {
  BNPnumber,
  BNPphoneNumber,
  BNPpostalCode,
  BNPstring,
  length,
  phoneValidator,
} from 'helpers/validators';
import { email, numericality, required } from 'helpers/validators';

import { t } from '@lingui/macro';

import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import RenderDatePicker from 'components/RenderDatePicker';

import { phoneFormats } from 'constants/locales';

export const currencyRegex = /^[0-9]+(\.[0-9]{1,2})?$/;
export const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/i;
export const fileExtensionRegex = /\.[0-9a-z]+$/i;

export const contractWarn = () =>
  t({ id: 'form.contract.warning', message: `will appear in contracts` });

const customerTitles = (country) => {
  const defaultTitles = [
    { value: 'Mr', label: t({ id: 'form.customer.title.mr', message: `Mr` }) },
    {
      value: 'Mrs',
      label: t({ id: 'form.customer.title.mrs', message: `Mrs` }),
    },
    { value: 'Ms', label: t({ id: 'form.customer.title.ms', message: `Ms` }) },
  ];

  return (
    {
      GB: [
        { value: 'Mr', label: 'Mr' },
        { value: 'Mrs', label: 'Mrs' },
        { value: 'Ms', label: 'Ms' },
        { value: 'Dr', label: 'Dr' },
        { value: 'Prof', label: 'Prof' },
        { value: 'Lord', label: 'Lord' },
        { value: 'Lady', label: 'Lady' },
      ],
      FR: [
        {
          value: 'Mr',
          label: t({ id: 'form.customer.title.mr', message: `Mr` }),
        },
        {
          value: 'Ms',
          label: t({ id: 'form.customer.title.ms', message: `Ms` }),
        },
      ],
    }[country] || defaultTitles
  );
};

// prettier-ignore
export const countries = () => [
  { value: 'AF', label: t({id: 'form.countries.af', message: `Afghanistan`}) },
  { value: 'AX', label: t({id: 'form.countries.ax', message: `\u00c5land Islands`}) },
  { value: 'AL', label: t({id: 'form.countries.al', message: `Albania`}) },
  { value: 'DZ', label: t({id: 'form.countries.dz', message: `Algeria`}) },
  { value: 'AS', label: t({id: 'form.countries.as', message: `American Samoa`}) },
  { value: 'AD', label: t({id: 'form.countries.ad', message: `Andorra`}) },
  { value: 'AO', label: t({id: 'form.countries.ao', message: `Angola`}) },
  { value: 'AI', label: t({id: 'form.countries.ai', message: `Anguilla`}) },
  { value: 'AQ', label: t({id: 'form.countries.aq', message: `Antarctica`}) },
  { value: 'AG', label: t({id: 'form.countries.ag', message: `Antigua and Barbuda`}) },
  { value: 'AR', label: t({id: 'form.countries.ar', message: `Argentina`}) },
  { value: 'AM', label: t({id: 'form.countries.am', message: `Armenia`}) },
  { value: 'AW', label: t({id: 'form.countries.aw', message: `Aruba`}) },
  { value: 'AU', label: t({id: 'form.countries.au', message: `Australia`}) },
  { value: 'AT', label: t({id: 'form.countries.at', message: `Austria`}) },
  { value: 'AZ', label: t({id: 'form.countries.az', message: `Azerbaijan`}) },
  { value: 'BS', label: t({id: 'form.countries.bs', message: `Bahamas`}) },
  { value: 'BH', label: t({id: 'form.countries.bh', message: `Bahrain`}) },
  { value: 'BD', label: t({id: 'form.countries.bd', message: `Bangladesh`}) },
  { value: 'BB', label: t({id: 'form.countries.bb', message: `Barbados`}) },
  { value: 'BY', label: t({id: 'form.countries.by', message: `Belarus`}) },
  { value: 'BE', label: t({id: 'form.countries.be', message: `Belgium`}) },
  { value: 'BZ', label: t({id: 'form.countries.bz', message: `Belize`}) },
  { value: 'BJ', label: t({id: 'form.countries.bj', message: `Benin`}) },
  { value: 'BM', label: t({id: 'form.countries.bm', message: `Bermuda`}) },
  { value: 'BT', label: t({id: 'form.countries.bt', message: `Bhutan`}) },
  { value: 'BO', label: t({id: 'form.countries.bo', message: `Bolivia, Plurinational State of`}) },
  { value: 'BQ', label: t({id: 'form.countries.bq', message: `Bonaire, Sint Eustatius and Saba`}) },
  { value: 'BA', label: t({id: 'form.countries.ba', message: `Bosnia and Herzegovina`}) },
  { value: 'BW', label: t({id: 'form.countries.bw', message: `Botswana`}) },
  { value: 'BV', label: t({id: 'form.countries.bv', message: `Bouvet Island`}) },
  { value: 'BR', label: t({id: 'form.countries.br', message: `Brazil`}) },
  { value: 'IO', label: t({id: 'form.countries.io', message: `British Indian Ocean Territory`}) },
  { value: 'BN', label: t({id: 'form.countries.bn', message: `Brunei Darussalam`}) },
  { value: 'BG', label: t({id: 'form.countries.bg', message: `Bulgaria`}) },
  { value: 'BF', label: t({id: 'form.countries.bf', message: `Burkina Faso`}) },
  { value: 'BI', label: t({id: 'form.countries.bi', message: `Burundi`}) },
  { value: 'KH', label: t({id: 'form.countries.kh', message: `Cambodia`}) },
  { value: 'CM', label: t({id: 'form.countries.cm', message: `Cameroon`}) },
  { value: 'CA', label: t({id: 'form.countries.ca', message: `Canada`}) },
  { value: 'CV', label: t({id: 'form.countries.cv', message: `Cape Verde`}) },
  { value: 'KY', label: t({id: 'form.countries.ky', message: `Cayman Islands`}) },
  { value: 'CF', label: t({id: 'form.countries.cf', message: `Central African Republic`}) },
  { value: 'TD', label: t({id: 'form.countries.td', message: `Chad`}) },
  { value: 'CL', label: t({id: 'form.countries.cl', message: `Chile`}) },
  { value: 'CN', label: t({id: 'form.countries.cn', message: `China`}) },
  { value: 'CX', label: t({id: 'form.countries.cx', message: `Christmas Island`}) },
  { value: 'CC', label: t({id: 'form.countries.cc', message: `Cocos (Keeling) Islands`}) },
  { value: 'CO', label: t({id: 'form.countries.co', message: `Colombia`}) },
  { value: 'KM', label: t({id: 'form.countries.km', message: `Comoros`}) },
  { value: 'CG', label: t({id: 'form.countries.cg', message: `Congo`}) },
  { value: 'CD', label: t({id: 'form.countries.cd', message: `Congo, the Democratic Republic of the`}) },
  { value: 'CK', label: t({id: 'form.countries.ck', message: `Cook Islands`}) },
  { value: 'CR', label: t({id: 'form.countries.cr', message: `Costa Rica`}) },
  { value: 'CI', label: t({id: 'form.countries.ci', message: `C\u00f4te d'Ivoire`}) },
  { value: 'HR', label: t({id: 'form.countries.hr', message: `Croatia`}) },
  { value: 'CU', label: t({id: 'form.countries.cu', message: `Cuba`}) },
  { value: 'CW', label: t({id: 'form.countries.cw', message: `Cura\u00e7ao`}) },
  { value: 'CY', label: t({id: 'form.countries.cy', message: `Cyprus`}) },
  { value: 'CZ', label: t({id: 'form.countries.cz', message: `Czech Republic`}) },
  { value: 'DK', label: t({id: 'form.countries.dk', message: `Denmark`}) },
  { value: 'DJ', label: t({id: 'form.countries.dj', message: `Djibouti`}) },
  { value: 'DM', label: t({id: 'form.countries.dm', message: `Dominica`}) },
  { value: 'DO', label: t({id: 'form.countries.do', message: `Dominican Republic`}) },
  { value: 'EC', label: t({id: 'form.countries.ec', message: `Ecuador`}) },
  { value: 'EG', label: t({id: 'form.countries.eg', message: `Egypt`}) },
  { value: 'SV', label: t({id: 'form.countries.sv', message: `El Salvador`}) },
  { value: 'GQ', label: t({id: 'form.countries.gq', message: `Equatorial Guinea`}) },
  { value: 'ER', label: t({id: 'form.countries.er', message: `Eritrea`}) },
  { value: 'EE', label: t({id: 'form.countries.ee', message: `Estonia`}) },
  { value: 'ET', label: t({id: 'form.countries.et', message: `Ethiopia`}) },
  { value: 'FK', label: t({id: 'form.countries.fk', message: `Falkland Islands (Malvinas)`}) },
  { value: 'FO', label: t({id: 'form.countries.fo', message: `Faroe Islands`}) },
  { value: 'FJ', label: t({id: 'form.countries.fj', message: `Fiji`}) },
  { value: 'FI', label: t({id: 'form.countries.fi', message: `Finland`}) },
  { value: 'FR', label: t({id: 'form.countries.fr', message: `France`}) },
  { value: 'GF', label: t({id: 'form.countries.gf', message: `French Guiana`}) },
  { value: 'PF', label: t({id: 'form.countries.pf', message: `French Polynesia`}) },
  { value: 'TF', label: t({id: 'form.countries.tf', message: `French Southern Territories`}) },
  { value: 'GA', label: t({id: 'form.countries.ga', message: `Gabon`}) },
  { value: 'GM', label: t({id: 'form.countries.gm', message: `Gambia`}) },
  { value: 'GE', label: t({id: 'form.countries.ge', message: `Georgia`}) },
  { value: 'DE', label: t({id: 'form.countries.de', message: `Germany`}) },
  { value: 'GH', label: t({id: 'form.countries.gh', message: `Ghana`}) },
  { value: 'GI', label: t({id: 'form.countries.gi', message: `Gibraltar`}) },
  { value: 'GR', label: t({id: 'form.countries.gr', message: `Greece`}) },
  { value: 'GL', label: t({id: 'form.countries.gl', message: `Greenland`}) },
  { value: 'GD', label: t({id: 'form.countries.gd', message: `Grenada`}) },
  { value: 'GP', label: t({id: 'form.countries.gp', message: `Guadeloupe`}) },
  { value: 'GU', label: t({id: 'form.countries.gu', message: `Guam`}) },
  { value: 'GT', label: t({id: 'form.countries.gt', message: `Guatemala`}) },
  { value: 'GG', label: t({id: 'form.countries.gg', message: `Guernsey`}) },
  { value: 'GN', label: t({id: 'form.countries.gn', message: `Guinea`}) },
  { value: 'GW', label: t({id: 'form.countries.gw', message: `Guinea-Bissau`}) },
  { value: 'GY', label: t({id: 'form.countries.gy', message: `Guyana`}) },
  { value: 'HT', label: t({id: 'form.countries.ht', message: `Haiti`}) },
  { value: 'HM', label: t({id: 'form.countries.hm', message: `Heard Island and McDonald Islands`}) },
  { value: 'VA', label: t({id: 'form.countries.va', message: `Holy See (Vatican City State)`}) },
  { value: 'HN', label: t({id: 'form.countries.hn', message: `Honduras`}) },
  { value: 'HK', label: t({id: 'form.countries.hk', message: `Hong Kong`}) },
  { value: 'HU', label: t({id: 'form.countries.hu', message: `Hungary`}) },
  { value: 'IS', label: t({id: 'form.countries.is', message: `Iceland`}) },
  { value: 'IN', label: t({id: 'form.countries.in', message: `India`}) },
  { value: 'ID', label: t({id: 'form.countries.id', message: `Indonesia`}) },
  { value: 'IR', label: t({id: 'form.countries.ir', message: `Iran, Islamic Republic of`}) },
  { value: 'IQ', label: t({id: 'form.countries.iq', message: `Iraq`}) },
  { value: 'IE', label: t({id: 'form.countries.ie', message: `Ireland`}) },
  { value: 'IM', label: t({id: 'form.countries.im', message: `Isle of Man`}) },
  { value: 'IL', label: t({id: 'form.countries.il', message: `Israel`}) },
  { value: 'IT', label: t({id: 'form.countries.it', message: `Italy`}) },
  { value: 'JM', label: t({id: 'form.countries.jm', message: `Jamaica`}) },
  { value: 'JP', label: t({id: 'form.countries.jp', message: `Japan`}) },
  { value: 'JE', label: t({id: 'form.countries.je', message: `Jersey`}) },
  { value: 'JO', label: t({id: 'form.countries.jo', message: `Jordan`}) },
  { value: 'KZ', label: t({id: 'form.countries.kz', message: `Kazakhstan`}) },
  { value: 'KE', label: t({id: 'form.countries.ke', message: `Kenya`}) },
  { value: 'KI', label: t({id: 'form.countries.ki', message: `Kiribati`}) },
  { value: 'KP', label: t({id: 'form.countries.kp', message: `Korea, Democratic People's Republic of`}) },
  { value: 'KR', label: t({id: 'form.countries.kr', message: `Korea, Republic of`}) },
  { value: 'KW', label: t({id: 'form.countries.kw', message: `Kuwait`}) },
  { value: 'KG', label: t({id: 'form.countries.kg', message: `Kyrgyzstan`}) },
  { value: 'LA', label: t({id: 'form.countries.la', message: `Lao People's Democratic Republic`}) },
  { value: 'LV', label: t({id: 'form.countries.lv', message: `Latvia`}) },
  { value: 'LB', label: t({id: 'form.countries.lb', message: `Lebanon`}) },
  { value: 'LS', label: t({id: 'form.countries.ls', message: `Lesotho`}) },
  { value: 'LR', label: t({id: 'form.countries.lr', message: `Liberia`}) },
  { value: 'LY', label: t({id: 'form.countries.ly', message: `Libya`}) },
  { value: 'LI', label: t({id: 'form.countries.li', message: `Liechtenstein`}) },
  { value: 'LT', label: t({id: 'form.countries.lt', message: `Lithuania`}) },
  { value: 'LU', label: t({id: 'form.countries.lu', message: `Luxembourg`}) },
  { value: 'MO', label: t({id: 'form.countries.mo', message: `Macao`}) },
  { value: 'MK', label: t({id: 'form.countries.mk', message: `Macedonia, the Former Yugoslav Republic of`}) },
  { value: 'MG', label: t({id: 'form.countries.mg', message: `Madagascar`}) },
  { value: 'MW', label: t({id: 'form.countries.mw', message: `Malawi`}) },
  { value: 'MY', label: t({id: 'form.countries.my', message: `Malaysia`}) },
  { value: 'MV', label: t({id: 'form.countries.mv', message: `Maldives`}) },
  { value: 'ML', label: t({id: 'form.countries.ml', message: `Mali`}) },
  { value: 'MT', label: t({id: 'form.countries.mt', message: `Malta`}) },
  { value: 'MH', label: t({id: 'form.countries.mh', message: `Marshall Islands`}) },
  { value: 'MQ', label: t({id: 'form.countries.mq', message: `Martinique`}) },
  { value: 'MR', label: t({id: 'form.countries.mr', message: `Mauritania`}) },
  { value: 'MU', label: t({id: 'form.countries.mu', message: `Mauritius`}) },
  { value: 'YT', label: t({id: 'form.countries.yt', message: `Mayotte`}) },
  { value: 'MX', label: t({id: 'form.countries.mx', message: `Mexico`}) },
  { value: 'FM', label: t({id: 'form.countries.fm', message: `Micronesia, Federated States of`}) },
  { value: 'MD', label: t({id: 'form.countries.md', message: `Moldova, Republic of`}) },
  { value: 'MC', label: t({id: 'form.countries.mc', message: `Monaco`}) },
  { value: 'MN', label: t({id: 'form.countries.mn', message: `Mongolia`}) },
  { value: 'ME', label: t({id: 'form.countries.me', message: `Montenegro`}) },
  { value: 'MS', label: t({id: 'form.countries.ms', message: `Montserrat`}) },
  { value: 'MA', label: t({id: 'form.countries.ma', message: `Morocco`}) },
  { value: 'MZ', label: t({id: 'form.countries.mz', message: `Mozambique`}) },
  { value: 'MM', label: t({id: 'form.countries.mm', message: `Myanmar`}) },
  { value: 'NA', label: t({id: 'form.countries.na', message: `Namibia`}) },
  { value: 'NR', label: t({id: 'form.countries.nr', message: `Nauru`}) },
  { value: 'NP', label: t({id: 'form.countries.np', message: `Nepal`}) },
  { value: 'NL', label: t({id: 'form.countries.nl', message: `Netherlands`}) },
  { value: 'NC', label: t({id: 'form.countries.nc', message: `New Caledonia`}) },
  { value: 'NZ', label: t({id: 'form.countries.nz', message: `New Zealand`}) },
  { value: 'NI', label: t({id: 'form.countries.ni', message: `Nicaragua`}) },
  { value: 'NE', label: t({id: 'form.countries.ne', message: `Niger`}) },
  { value: 'NG', label: t({id: 'form.countries.ng', message: `Nigeria`}) },
  { value: 'NU', label: t({id: 'form.countries.nu', message: `Niue`}) },
  { value: 'NF', label: t({id: 'form.countries.nf', message: `Norfolk Island`}) },
  { value: 'MP', label: t({id: 'form.countries.mp', message: `Northern Mariana Islands`}) },
  { value: 'NO', label: t({id: 'form.countries.no', message: `Norway`}) },
  { value: 'OM', label: t({id: 'form.countries.om', message: `Oman`}) },
  { value: 'PK', label: t({id: 'form.countries.pk', message: `Pakistan`}) },
  { value: 'PW', label: t({id: 'form.countries.pw', message: `Palau`}) },
  { value: 'PS', label: t({id: 'form.countries.ps', message: `Palestine, State of`}) },
  { value: 'PA', label: t({id: 'form.countries.pa', message: `Panama`}) },
  { value: 'PG', label: t({id: 'form.countries.pg', message: `Papua New Guinea`}) },
  { value: 'PY', label: t({id: 'form.countries.py', message: `Paraguay`}) },
  { value: 'PE', label: t({id: 'form.countries.pe', message: `Peru`}) },
  { value: 'PH', label: t({id: 'form.countries.ph', message: `Philippines`}) },
  { value: 'PN', label: t({id: 'form.countries.pn', message: `Pitcairn`}) },
  { value: 'PL', label: t({id: 'form.countries.pl', message: `Poland`}) },
  { value: 'PT', label: t({id: 'form.countries.pt', message: `Portugal`}) },
  { value: 'PR', label: t({id: 'form.countries.pr', message: `Puerto Rico`}) },
  { value: 'QA', label: t({id: 'form.countries.qa', message: `Qatar`}) },
  { value: 'RE', label: t({id: 'form.countries.re', message: `R\u00e9union`}) },
  { value: 'RO', label: t({id: 'form.countries.ro', message: `Romania`}) },
  { value: 'RU', label: t({id: 'form.countries.ru', message: `Russian Federation`}) },
  { value: 'RW', label: t({id: 'form.countries.rw', message: `Rwanda`}) },
  { value: 'BL', label: t({id: 'form.countries.bl', message: `Saint Barth\u00e9lemy`}) },
  { value: 'SH', label: t({id: 'form.countries.sh', message: `Saint Helena, Ascension and Tristan da Cunha`}) },
  { value: 'KN', label: t({id: 'form.countries.kn', message: `Saint Kitts and Nevis`}) },
  { value: 'LC', label: t({id: 'form.countries.lc', message: `Saint Lucia`}) },
  { value: 'MF', label: t({id: 'form.countries.mf', message: `Saint Martin (French part)`}) },
  { value: 'PM', label: t({id: 'form.countries.pm', message: `Saint Pierre and Miquelon`}) },
  { value: 'VC', label: t({id: 'form.countries.vc', message: `Saint Vincent and the Grenadines`}) },
  { value: 'WS', label: t({id: 'form.countries.ws', message: `Samoa`}) },
  { value: 'SM', label: t({id: 'form.countries.sm', message: `San Marino`}) },
  { value: 'ST', label: t({id: 'form.countries.st', message: `Sao Tome and Principe`}) },
  { value: 'SA', label: t({id: 'form.countries.sa', message: `Saudi Arabia`}) },
  { value: 'SN', label: t({id: 'form.countries.sn', message: `Senegal`}) },
  { value: 'RS', label: t({id: 'form.countries.rs', message: `Serbia`}) },
  { value: 'SC', label: t({id: 'form.countries.sc', message: `Seychelles`}) },
  { value: 'SL', label: t({id: 'form.countries.sl', message: `Sierra Leone`}) },
  { value: 'SG', label: t({id: 'form.countries.sg', message: `Singapore`}) },
  { value: 'SX', label: t({id: 'form.countries.sx', message: `Sint Maarten (Dutch part)`}) },
  { value: 'SK', label: t({id: 'form.countries.sk', message: `Slovakia`}) },
  { value: 'SI', label: t({id: 'form.countries.si', message: `Slovenia`}) },
  { value: 'SB', label: t({id: 'form.countries.sb', message: `Solomon Islands`}) },
  { value: 'SO', label: t({id: 'form.countries.so', message: `Somalia`}) },
  { value: 'ZA', label: t({id: 'form.countries.za', message: `South Africa`}) },
  { value: 'GS', label: t({id: 'form.countries.gs', message: `South Georgia and the South Sandwich Islands`}) },
  { value: 'SS', label: t({id: 'form.countries.ss', message: `South Sudan`}) },
  { value: 'ES', label: t({id: 'form.countries.es', message: `Spain`}) },
  { value: 'LK', label: t({id: 'form.countries.lk', message: `Sri Lanka`}) },
  { value: 'SD', label: t({id: 'form.countries.sd', message: `Sudan`}) },
  { value: 'SR', label: t({id: 'form.countries.sr', message: `Suriname`}) },
  { value: 'SJ', label: t({id: 'form.countries.sj', message: `Svalbard and Jan Mayen`}) },
  { value: 'SZ', label: t({id: 'form.countries.sz', message: `Swaziland`}) },
  { value: 'SE', label: t({id: 'form.countries.se', message: `Sweden`}) },
  { value: 'CH', label: t({id: 'form.countries.ch', message: `Switzerland`}) },
  { value: 'SY', label: t({id: 'form.countries.sy', message: `Syrian Arab Republic`}) },
  { value: 'TW', label: t({id: 'form.countries.tw', message: `Taiwan, Province of China`}) },
  { value: 'TJ', label: t({id: 'form.countries.tj', message: `Tajikistan`}) },
  { value: 'TZ', label: t({id: 'form.countries.tz', message: `Tanzania, United Republic of`}) },
  { value: 'TH', label: t({id: 'form.countries.th', message: `Thailand`}) },
  { value: 'TL', label: t({id: 'form.countries.tl', message: `Timor-Leste`}) },
  { value: 'TG', label: t({id: 'form.countries.tg', message: `Togo`}) },
  { value: 'TK', label: t({id: 'form.countries.tk', message: `Tokelau`}) },
  { value: 'TO', label: t({id: 'form.countries.to', message: `Tonga`}) },
  { value: 'TT', label: t({id: 'form.countries.tt', message: `Trinidad and Tobago`}) },
  { value: 'TN', label: t({id: 'form.countries.tn', message: `Tunisia`}) },
  { value: 'TR', label: t({id: 'form.countries.tr', message: `Turkey`}) },
  { value: 'TM', label: t({id: 'form.countries.tm', message: `Turkmenistan`}) },
  { value: 'TC', label: t({id: 'form.countries.tc', message: `Turks and Caicos Islands`}) },
  { value: 'TV', label: t({id: 'form.countries.tv', message: `Tuvalu`}) },
  { value: 'UG', label: t({id: 'form.countries.ug', message: `Uganda`}) },
  { value: 'UA', label: t({id: 'form.countries.ua', message: `Ukraine`}) },
  { value: 'AE', label: t({id: 'form.countries.ae', message: `United Arab Emirates`}) },
  { value: 'GB', label: t({id: 'form.countries.gb', message: `United Kingdom`}) },
  { value: 'US', label: t({id: 'form.countries.us', message: `United States`}) },
  { value: 'UM', label: t({id: 'form.countries.um', message: `United States Minor Outlying Islands`}) },
  { value: 'UY', label: t({id: 'form.countries.uy', message: `Uruguay`}) },
  { value: 'UZ', label: t({id: 'form.countries.uz', message: `Uzbekistan`}) },
  { value: 'VU', label: t({id: 'form.countries.vu', message: `Vanuatu`}) },
  { value: 'VE', label: t({id: 'form.countries.ve', message: `Venezuela, Bolivarian Republic of`}) },
  { value: 'VN', label: t({id: 'form.countries.vn', message: `Viet Nam`}) },
  { value: 'VG', label: t({id: 'form.countries.vg', message: `Virgin Islands, British`}) },
  { value: 'VI', label: t({id: 'form.countries.vi', message: `Virgin Islands, U.S.`}) },
  { value: 'WF', label: t({id: 'form.countries.wf', message: `Wallis and Futuna`}) },
  { value: 'EH', label: t({id: 'form.countries.eh', message: `Western Sahara`}) },
  { value: 'YE', label: t({id: 'form.countries.ye', message: `Yemen`}) },
  { value: 'ZM', label: t({id: 'form.countries.zm', message: `Zambia`}) },
  { value: 'ZW', label: t({id: 'form.countries.zw', message: `Zimbabwe`}) },
];

export const posCustomerInfoFields = ({
  country,
  countryPhoneCodes,
  handleChange = () => {},
}) => [
  {
    component: Dropdown,
    placeholder: t({
      id: 'forms.contact.placeholder.title',
      message: `Title`,
    }),
    name: 'title',
    size: 'medium',
    testSelector: 'customer-title',
    validate: [required()],
    onChange: (value) => {
      handleChange({ target: { name: 'title' } }, value);
    },
    variant: 'underline',
    options: customerTitles(country),
  },
  {
    component: Input,
    placeholder: t({ id: 'customer.info.first.name', message: `First Name` }),
    name: 'firstName',
    size: 'medium',
    testSelector: 'customer-first-name',
    validate: [required(), length({ max: 30 })],
    onChange: handleChange,
    variant: 'underline',
  },
  {
    component: Input,
    placeholder: t({ id: 'customer.info.last.name', message: `Last Name` }),
    name: 'lastName',
    size: 'medium',
    testSelector: 'customer-last-name',
    validate: [required(), length({ max: 30 })],
    onChange: handleChange,
    variant: 'underline',
  },
  {
    component: Input,
    placeholder: t({
      id: 'customer.info.phone.placeholder',
      message: `Phone Number`,
    }),
    name: 'phone',
    size: 'medium',
    testSelector: 'customer-phone-number',
    validate: [
      required(),
      length({
        min: phoneFormats[country].length,
        max: 45,
        msg: {
          tooLong: t({
            id: 'customer.info.phone.error.max',
            message: `exceeds 45 characters`,
          }),
          tooShort: t({
            id: 'customer.info.phone.error.min',
            message: `is below ${phoneFormats[country].length} characters`,
          }),
        },
      }),
      phoneValidator({
        errorMsg: t({
          id: 'customer.info.phone.error',
          message: `is not a valid phone number`,
        }),
        country,
        countryPhoneCodes,
      }),
    ],
    onChange: handleChange,
    variant: 'underline',
  },
  {
    component: Input,
    placeholder: t({
      id: 'customer.info.phone.placeholder',
      message: `Phone Number`,
    }),
    name: 'bnpPhone',
    size: 'medium',
    testSelector: 'customer-phone-number',
    validate: [
      BNPphoneNumber(),
      required(),
      length({
        min: 2,
        max: 13,
        msg: {
          tooLong: t({
            id: 'customer.info.bnp.phone.error.max',
            message: `exceeds 11 characters`,
          }),
          tooShort: t({
            id: 'customer.info.bnp.phone.error.min',
            message: `is below 2 characters`,
          }),
        },
      }),
    ],
    onChange: handleChange,
    variant: 'underline',
  },
  {
    component: Input,
    placeholder: t({ id: 'customer.info.email.placeholder', message: `Email` }),
    name: 'email',
    size: 'medium',
    testSelector: 'customer-email',
    validate: [required(), email(), length({ max: 255 })],
    onChange: handleChange,
    variant: 'underline',
  },
  {
    component: Input,
    placeholder: t({
      id: 'customer.info.address.street',
      message: `Address street`,
    }),
    name: 'street',
    id: 'street-oney',
    size: 'medium',
    testSelector: 'customer-address-street',
    validate: [required(), length({ max: 255 })],
    onChange: handleChange,
    variant: 'underline',
  },
  {
    component: Input,
    placeholder: t({ id: 'forms.contact.placeholder.city', message: `City` }),
    name: 'city',
    id: 'city-oney',
    size: 'medium',
    testSelector: 'customer-address-city',
    validate: [required(), length({ max: 100 })],
    onChange: handleChange,
    variant: 'underline',
  },
  {
    component: Input,
    placeholder: t({
      id: 'forms.contact.placeholder.postalcode',
      message: `Postal Code`,
    }),
    name: 'postCode',
    id: 'postCode-oney',
    size: 'medium',
    testSelector: 'customer-address-postcode',
    validate: [required(), length({ max: 25 })],
    onChange: handleChange,
    variant: 'underline',
  },
  {
    component: Input,
    placeholder: t({
      id: 'forms.contact.placeholder.country',
      message: `Country`,
    }),
    name: 'country',
    id: 'country-oney',
    size: 'medium',
    testSelector: 'customer-address-country',
    validate: [required(), length({ max: 50 })],
    onChange: handleChange,
    variant: 'underline',
    disabled: country !== undefined,
  },
  {
    component: Input,
    placeholder: t({
      id: 'seller.employee.number',
      message: `Seller Employee Number`,
    }),
    name: 'sellerEmployeeNumber',
    id: 'sellerEmployeeNumber',
    size: 'medium',
    testSelector: 'seller-employee-number',
    validate: [required(), length({ max: 45 })],
    onChange: handleChange,
    variant: 'underline',
  },
  {
    component: Input,
    placeholder: t({
      id: 'customer.info.imei.placeholder',
      message: `IMEI or Serial Number`,
    }),
    name: 'deviceReference',
    size: 'medium',
    testSelector: 'customer-imei-serial',
    validate: [required(), length({ min: 1, max: 50 })],
    onChange: handleChange,
    variant: 'underline',
  },
  {
    component: Input,
    placeholder: t({
      id: 'customer.info.sequence.placeholder',
      message: `Sequence Number`,
    }),
    name: 'sequence',
    size: 'medium',
    testSelector: 'customer-sequence-number',
    validate: [
      required(),
      numericality({
        int: true,
        greaterThan: 0,
      }),
      length({ max: 9 }),
    ],
    onChange: handleChange,
    variant: 'underline',
  },
  {
    component: Input,
    placeholder: t({ id: 'customer.info.ssn.placeholder', message: `SSN` }),
    name: 'ssn',
    size: 'medium',
    testSelector: 'customer-ssn',
    validate: [required()],
    onChange: handleChange,
    variant: 'underline',
  },
  {
    component: Input,
    placeholder: t({ id: 'customer.info.customerId', message: `Customer ID` }),
    name: 'idNumber',
    size: 'medium',
    testSelector: 'customer-id',
    onChange: handleChange,
    variant: 'underline',
  },
  {
    component: Input,
    placeholder: t({
      id: 'customer.info.customerReference',
      message: `SAP Customer Reference`,
    }),
    name: 'customerReference',
    size: 'medium',
    testSelector: 'customer-sap-customer-reference',
    onChange: handleChange,
    variant: 'underline',
  },
  {
    component: Input,
    placeholder: t({
      id: 'customer.info.address.name',
      message: `Address name`,
    }),
    name: 'name',
    size: 'medium',
    testSelector: 'customer-address-name',
    validate: [required(), BNPstring(), length({ max: 35 })],
    onChange: handleChange,
    variant: 'underline',
  },
  {
    component: Input,
    placeholder: t({
      id: 'customer.info.address.number',
      message: `Address number`,
    }),
    name: 'number',
    size: 'medium',
    testSelector: 'customer-address-number',
    validate: [required(), BNPnumber(), length({ max: 35 })],
    onChange: handleChange,
    variant: 'underline',
  },
  {
    component: Input,
    placeholder: t({
      id: 'customer.info.address.street',
      message: `Address street`,
    }),
    name: 'street',
    size: 'medium',
    testSelector: 'customer-address-street',
    validate: [required(), BNPstring(), length({ max: 45 })],
    onChange: handleChange,
    variant: 'underline',
  },
  {
    component: Input,
    placeholder: t({
      id: 'customer.info.address.district',
      message: `Address district`,
    }),
    name: 'district',
    size: 'medium',
    testSelector: 'customer-address-district',
    validate: [required(), BNPstring(), length({ max: 35 })],
    onChange: handleChange,
    variant: 'underline',
  },
  {
    component: Input,
    placeholder: t({
      id: 'customer.info.address.county',
      message: `Address county`,
    }),
    name: 'county',
    size: 'medium',
    testSelector: 'customer-address-town',
    validate: [required(), BNPstring(), length({ max: 35 })],
    onChange: handleChange,
    variant: 'underline',
  },
  {
    component: Input,
    placeholder: t({
      id: 'customer.info.address.town',
      message: `Address town`,
    }),
    name: 'town',
    size: 'medium',
    testSelector: 'customer-address-town',
    validate: [required(), BNPstring(), length({ max: 35 })],
    onChange: handleChange,
    variant: 'underline',
  },
  {
    component: Input,
    placeholder: t({
      id: 'customer.info.address.postCode',
      message: `Address postal code`,
    }),
    name: 'postCode',
    size: 'medium',
    testSelector: 'customer-address-postCode',
    validate: [required(), BNPpostalCode(), length({ min: 1, max: 10 })],
    onChange: handleChange,
    variant: 'underline',
  },
];

export const posCompanyInfoFields = ({
  handleChange = () => {},
  supportedBusinessTypes,
}) => {
  return [
    {
      component: Input,
      placeholder: t({
        id: 'customer.info.company.name',
        message: `Company Name`,
      }),
      name: 'name',
      size: 'medium',
      testSelector: 'company-name',
      validate: [required(), length({ max: 255 })],
      onChange: handleChange,
      variant: 'underline',
    },
    {
      component: Dropdown,
      placeholder: t({
        id: 'customer.info.company.type',
        message: `Company Type`,
      }),
      name: 'type',
      size: 'medium',
      testSelector: 'company-type',
      validate: [required()],
      onChange: (value) => {
        handleChange({ target: { name: 'type' } }, value);
      },
      variant: 'underline',
      options: supportedBusinessTypes,
    },
    {
      component: Input,
      placeholder: t({
        id: 'customer.info.company.number',
        message: `Company Number`,
      }),
      name: 'number',
      size: 'medium',
      testSelector: 'company-number',
      validate: [required(), length({ max: 100 })],
      onChange: handleChange,
      variant: 'underline',
    },
    {
      component: Input,
      placeholder: t({
        id: 'customer.info.company.postcode',
        message: `Postal code`,
      }),
      name: 'postalCode',
      size: 'medium',
      testSelector: 'company-postcode',
      validate: [required(), length({ max: 25 })],
      onChange: handleChange,
      variant: 'underline',
    },
    {
      component: Input,
      placeholder: t({
        id: 'customer.info.company.address.line1',
        message: `Address Line 1`,
      }),
      name: 'addressLine1',
      size: 'medium',
      testSelector: 'company-address-line1',
      validate: [required(), length({ max: 255 })],
      onChange: handleChange,
      variant: 'underline',
    },
    {
      component: Input,
      placeholder: t({
        id: 'customer.info.company.address.line2',
        message: `Address Line 2`,
      }),
      name: 'addressLine2',
      size: 'medium',
      testSelector: 'company-address-line2',
      validate: [length({ max: 255 })],
      onChange: handleChange,
      variant: 'underline',
    },
    {
      component: Input,
      placeholder: t({
        id: 'customer.info.company.address.line3',
        message: `Address Line 3`,
      }),
      name: 'addressLine3',
      size: 'medium',
      testSelector: 'company-address-line3',
      validate: [length({ max: 255 })],
      onChange: handleChange,
      variant: 'underline',
    },
    {
      component: Input,
      placeholder: t({ id: 'customer.info.company.city', message: `City` }),
      name: 'city',
      size: 'medium',
      testSelector: 'company-city',
      validate: [required(), length({ max: 100 })],
      onChange: handleChange,
      variant: 'underline',
    },
    {
      component: Input,
      placeholder: t({
        id: 'customer.info.company.country.incorporation',
        message: `Country`,
      }),
      name: 'country',
      size: 'medium',
      testSelector: 'company-country-incorporation',
      validate: [required(), length({ max: 100 })],
      onChange: handleChange,
      variant: 'underline',
    },
    {
      component: RenderDatePicker,
      label: t({
        id: 'customer.info.company.incorporation.date',
        message: `Date of Creation`,
      }),
      name: 'dateOfCreation',
      helpText: t({
        id: 'customer.info.company.incorporation.date.text',
        message: `yyyy-MM-dd`,
      }),
      size: 'medium',
      testSelector: 'company-incorporation-date',
      validate: [required(), length({ max: 25 })],
      onChange: (value) =>
        handleChange({ target: { name: 'dateOfCreation' } }, value),
      variant: 'underline',
    },
    {
      component: Input,
      placeholder: t({
        id: 'customer.info.b2b.auhtorised.signatory.name',
        message: `Authorised Signatory Name`,
      }),
      name: 'authorisedSignatoryName',
      size: 'medium',
      testSelector: 'customer-b2b-authorised-signatory-name',
      validate: [required(), length({ max: 255 })],
      onChange: handleChange,
      variant: 'underline',
    },
    {
      component: Input,
      placeholder: t({
        id: 'customer.info.b2b.auhtorised.signatory.position',
        message: `Authorised Signatory Position`,
      }),
      name: 'authorisedSignatoryPosition',
      size: 'medium',
      testSelector: 'customer-b2b-authorised-signatory-position',
      validate: [required(), length({ max: 100 })],
      onChange: handleChange,
      variant: 'underline',
    },
  ];
};
