import { CREATE_CART } from 'actions/carts';
import { getCustomerAction, setCustomerAction } from 'actions/customer';
import {
  addNotificationAction,
  hideLoaderAction,
  showLoaderAction,
} from 'actions/ui';
import { post } from 'apis/v2';
import isEmpty from 'lodash/isEmpty';
import { isImg } from 'utils';
import {
  allGradingQuestionsAnswered,
  capitalize,
  transformCriteria,
} from 'utils';

import { API_POSALE_SERVICE } from 'constants/api';
import { IMEI } from 'constants/deviceReference';
import {
  missingNextEvaluationQuestion,
  noDevicesToRecycle,
  onlineTradeInQuoteError,
  orderCancelError,
  orderCancelSuccess,
  returnDeviceSuccess,
  swapEvaluationSuccess,
} from 'constants/notifications';
import {
  SWAP,
  TRADE_IN,
  evaluationGradeTitles,
  networkStatus,
} from 'constants/recycle';

export const CLEAR_RECYCLE = 'CLEAR_RECYCLE';
export function clearRecycleAction() {
  return { type: CLEAR_RECYCLE };
}

export const SET_RECYCLE_TYPE = 'SET_RECYCLE_TYPE';
export function setRecycleTypeAction({ recycleType, orderUUID, funder }) {
  return { type: SET_RECYCLE_TYPE, recycleType, orderUUID, funder };
}

export const SAVE_DEVICE_REFERENCE = 'SAVE_DEVICE_REFERENCE';
export function saveDeviceReferenceAction({ deviceReference, referenceType }) {
  return { type: SAVE_DEVICE_REFERENCE, deviceReference, referenceType };
}

export const VALIDATE_DEVICE_REFERENCE = 'VALIDATE_DEVICE_REFERENCE';
export function validateDeviceReferenceAction(
  { deviceReference, referenceType, orderGUID, orderUUID, searchText },
  service,
  fetchModelData = true,
) {
  return async function (dispatch) {
    dispatch(showLoaderAction());

    const response = await post(service, [
      VALIDATE_DEVICE_REFERENCE,
      {
        orderUUID,
        orderGUID,
        isIMEI: referenceType === IMEI,
        deviceReference,
        searchText,
        fetchModelData,
      },
    ]);

    const { data, status } = response.data;

    if (status !== 'ERROR') {
      if (data.devices?.length === 0) {
        dispatch(addNotificationAction(noDevicesToRecycle()));

        data.checkmendOk = false;

        if (data.fmi)
          data.fmi = {
            ...data.fmi,
            isLocked: false,
            isLost: false,
            isActivated: false,
          };
      } else {
        await dispatch({
          type: VALIDATE_DEVICE_REFERENCE,
          deviceReference,
          referenceType,
          searchText,
          ...data,
          orderUUID: data.orderUUID || orderUUID,
        });

        if (data.devices?.length === 1) {
          return dispatch(
            selectRecycleDeviceAction(
              {
                selectedDevice: data.devices[0],
                networkLockStatus:
                  data.fmi?.networkLockStatus || data.networkLockStatus,
                orderGUID,
                orderUUID: data.orderUUID || orderUUID,
              },
              service,
            ),
          );
        }
      }
    }

    dispatch(hideLoaderAction());
  };
}

export const SEARCH_DEVICES = 'SEARCH_DEVICES';
export function searchDevices({
  deviceReference,
  referenceType,
  searchText,
  orderUUID,
}) {
  return async function (dispatch) {
    const response = await post(API_POSALE_SERVICE, [
      VALIDATE_DEVICE_REFERENCE,
      {
        isIMEI: referenceType === IMEI,
        deviceReference,
        searchText,
        fetchModelData: true,
        orderUUID,
      },
    ]);

    const { data, status } = response.data;

    if (status !== 'ERROR') {
      if (data.devices?.length === 0) {
        dispatch(addNotificationAction(noDevicesToRecycle()));
      } else {
        dispatch({
          type: SEARCH_DEVICES,
          deviceReference,
          referenceType,
          searchText,
          ...data,
        });
      }
    }

    return response.data;
  };
}

export const REVALIDATE_DEVICE_REFERENCE = 'REVALIDATE_DEVICE_REFERENCE';
export function revalidateDeviceReferenceAction(
  {
    deviceReference,
    referenceType,
    orderGUID,
    orderUUID,
    searchText,
    deviceID,
    device,
    isMultiTrade,
  },
  service,
) {
  return async function (dispatch) {
    dispatch(showLoaderAction());

    const response = await post(service, [
      VALIDATE_DEVICE_REFERENCE,
      {
        orderUUID,
        orderGUID,
        isIMEI: referenceType === IMEI,
        deviceReference,
        searchText: device ? device.searchText : searchText,
        fetchModelData: false,
      },
    ]);

    const { data, status } = response.data;

    if (status !== 'ERROR') {
      if (isMultiTrade) {
        dispatch({
          type: REVALIDATE_DEVICE_REFERENCE,
          ...data,
          id: device.id,
        });
      }

      dispatch(
        selectRecycleDeviceAction(
          {
            selectedDevice: data.devices.find(
              (device) => device.deviceID === deviceID,
            ),
            networkLockStatus:
              data.fmi?.networkLockStatus || data.networkLockStatus,
            orderUUID,
            orderGUID,
          },
          service,
        ),
      );
    }

    dispatch(hideLoaderAction());
  };
}

export const CLEAR_SELECTIONS = 'CLEAR_SELECTIONS';
export function clearSelectionsAction() {
  return function (dispatch) {
    dispatch({ type: CLEAR_SELECTIONS });
  };
}

export const SELECT_RECYCLE_DEVICE = 'SELECT_RECYCLE_DEVICE';
export function selectRecycleDeviceAction(
  {
    selectedDevice,
    networkLockStatus,
    selectedSIMStatus,
    orderGUID,
    orderUUID,
  },
  service,
) {
  return async function (dispatch) {
    dispatch({
      type: SELECT_RECYCLE_DEVICE,
      selectedDevice,
    });

    if (
      selectedDevice?.hasNetwork &&
      networkLockStatus &&
      networkStatus.includes(networkLockStatus)
    ) {
      await dispatch(selectRecycleNetworkStatusAction(networkLockStatus));
    }
    selectedDevice &&
      (await dispatch(
        getCriteriaAction({ selectedDevice, orderGUID }, service),
      ));

    if (service === API_POSALE_SERVICE)
      return await dispatch(
        evaluateDeviceAction({
          selectedDevice: {
            ...selectedDevice,
            questionsTree: {
              criteriaAnswers: [],
              additionAnswers: [],
              deductionAnswers: [],
            },
            selectedSIMStatus,
          },
          orderUUID,
          service,
        }),
      );
  };
}

export const GET_CRITERIA = 'GET_CRITERIA';
export function getCriteriaAction({ selectedDevice, orderGUID }, service) {
  return async function (dispatch, getState) {
    const { orderUUID } = getState().recycle;
    const { loading } = getState().ui;

    !loading && dispatch(showLoaderAction());

    const response = await post(service, [
      GET_CRITERIA,
      {
        ...selectedDevice,
        orderUUID,
        orderGUID,
      },
    ]);

    const questionsTree = {
      ...response.data.data,
      criteria: response.data.data.criteria
        ? response.data.data.criteria.map((item) => ({
            ...item,
            images: item.images
              ? item.images.filter(
                  (img) => isImg(img.imageUrl) && isImg(img.thumbnailUrl),
                )
              : [],
          }))
        : [],
      criteriaAnswers: response.data.data.criteria
        ? [{ ...response.data.data.criteria[0], selected: undefined }]
        : [],
      deductionAnswers:
        response.data.data.deduction?.length > 0
          ? [{ ...response.data.data.deduction[0], selected: undefined }]
          : [],
      additionAnswers:
        response.data.data.addition?.length > 0
          ? [{ ...response.data.data.addition[0], selected: undefined }]
          : [],
    };

    dispatch({
      type: GET_CRITERIA,
      questionsTree,
    });

    dispatch(hideLoaderAction());

    return questionsTree;
  };
}

export const SELECT_RECYCLE_NETWORK_STATUS = 'SELECT_RECYCLE_NETWORK_STATUS';
export function selectRecycleNetworkStatusAction(selectedSIMStatus) {
  return function (dispatch) {
    dispatch({
      type: SELECT_RECYCLE_NETWORK_STATUS,
      selectedSIMStatus,
    });
  };
}

/**
 * type *must* be one of: ['criteria', 'deduction', 'addition']
 */
export const SAVE_EVALUATION_ANSWER = 'SAVE_EVALUATION_ANSWER';
export const CLEAR_EVALUATION = 'CLEAR_EVALUATION';
export function saveEvaluationAnswerAction({
  question,
  answer,
  type,
  orderUUID,
  orderGUID,
  selectedDevice,
  selectedSIMStatus,
  service,
}) {
  return async function (dispatch) {
    const { questionsTree } = selectedDevice;

    let newAnswers = [...questionsTree[`${type}Answers`]];

    const updatedQuestionIndex = newAnswers.findIndex(
      (answer) => answer?.code === question?.code,
    );

    if (updatedQuestionIndex !== -1) {
      newAnswers[updatedQuestionIndex].selected = answer;

      // In case of a question in the middle changes, remove subsequent questions from the list.
      newAnswers = newAnswers.slice(0, updatedQuestionIndex + 1);

      // If there is a next question, add it to the question list.
      if (newAnswers[updatedQuestionIndex][answer]) {
        const nextQuestion = questionsTree[type].find(
          (question) =>
            question.code === newAnswers[updatedQuestionIndex][answer],
        );

        if (!nextQuestion) {
          dispatch(addNotificationAction(missingNextEvaluationQuestion()));
        }

        newAnswers = [...newAnswers, { ...nextQuestion }];

        dispatch({ type: CLEAR_EVALUATION, id: selectedDevice.id });
      }
      // If all questions are answered ask for an evaluation
      else {
        const { criteriaAnswers, deductionAnswers, additionAnswers } = {
          ...questionsTree,
          [`${type}Answers`]: newAnswers,
        };

        if (
          [criteriaAnswers, deductionAnswers, additionAnswers].every(
            allGradingQuestionsAnswered,
          )
        ) {
          return dispatch(
            evaluateDeviceAction({
              selectedDevice: {
                ...selectedDevice,
                questionsTree: {
                  ...selectedDevice.questionsTree,
                  [`${type}Answers`]: newAnswers,
                },
              },
              selectedSIMStatus,
              orderUUID,
              orderGUID,
              service,
            }),
          );
        }
      }

      dispatch({
        type: SAVE_EVALUATION_ANSWER,
        questionsTree: {
          ...questionsTree,
          [`${type}Answers`]: newAnswers,
        },
        id: selectedDevice.id,
      });
    }
  };
}

export const EVALUATE_DEVICE = 'EVALUATE_DEVICE';
export function evaluateDeviceAction({
  onlineQuote,
  orderGUID,
  orderUUID,
  selectedDevice,
  selectedPriceID,
  selectedSIMStatus,
  service,
}) {
  return async function (dispatch) {
    const {
      categoryCode,
      deviceCode,
      deviceID,
      manufacturerCode,
      hasNetwork,
      diagnostic,
      questionsTree,
      promotionID,
    } = selectedDevice;

    dispatch(showLoaderAction());

    const response = await post(service, [
      EVALUATE_DEVICE,
      {
        categoryCode,
        criteriaAnswers: transformCriteria(questionsTree.criteriaAnswers),
        additionAnswers: transformCriteria(questionsTree.additionAnswers),
        deductionAnswers: transformCriteria(questionsTree.deductionAnswers),
        deviceCode,
        deviceID,
        priceID: selectedPriceID,
        manufacturerCode,
        networkStatus:
          hasNetwork && selectedSIMStatus
            ? capitalize(selectedSIMStatus)
            : undefined,
        orderUUID,
        orderGUID,
        grade: diagnostic?.reportGrade ?? undefined,
        tradecartID: onlineQuote?.tradecartID ?? undefined,
        promotionID,
      },
    ]);

    dispatch({
      type: EVALUATE_DEVICE,
      evaluation: {
        ...response.data.data,
        gradeTitle: evaluationGradeTitles(response.data.data.grade),
      },
      id: selectedDevice.id,
    });

    dispatch(hideLoaderAction());

    return response.data.data;
  };
}

export const SELECT_RECYCLE_OUTCOME = 'SELECT_RECYCLE_OUTCOME';
export function selectRecycleOutcomeAction(newOutcome) {
  return function (dispatch) {
    dispatch({
      type: SELECT_RECYCLE_OUTCOME,
      selectedOutcome: newOutcome,
    });
  };
}

export const RETURN_DEVICE = 'RETURN_DEVICE';
export const CREATE_TRADECART = 'CREATE_TRADECART';
export function createTradecartAction(orderGUID, service) {
  return async function (dispatch, getState) {
    const { customer, recycle } = getState();
    const {
      deviceReference,
      searchText,
      orderUUID,
      selectedDevice,
      selectedOutcome = 'NONE',
      selectedSIMStatus,
      onlineQuote,
    } = recycle;
    const {
      hasNetwork,
      deviceID,
      deviceCode,
      questionsTree,
      diagnostic,
      name,
      promotionID,
    } = selectedDevice;

    dispatch(showLoaderAction());

    const recycleCart = await post(service, [
      CREATE_TRADECART,
      {
        customer: recycle.type === TRADE_IN ? { ...customer } : undefined,
        orderUUID,
        tradecartID: onlineQuote?.tradecartID ?? undefined,
        devices: [
          {
            criteriaAnswers: transformCriteria(questionsTree.criteriaAnswers),
            additionAnswers: transformCriteria(questionsTree.additionAnswers),
            deductionAnswers: transformCriteria(questionsTree.deductionAnswers),
            deviceReference,
            searchText: !isEmpty(onlineQuote) ? name : searchText,
            networkStatus: hasNetwork
              ? capitalize(selectedSIMStatus)
              : undefined,
            deviceID,
            deviceCode,
            outcome: selectedOutcome,
            uid: diagnostic?.reportUid ?? undefined,
            grade: diagnostic?.reportGrade ?? undefined,
            promotionID,
          },
        ],
      },
    ]);
    if (recycleCart?.data?.status === 'SUCCESS')
      dispatch({
        type: CREATE_TRADECART,
        tradecart: recycleCart.data.data.tradecart,
      });

    dispatch(hideLoaderAction());

    return recycleCart.data.data.tradecart;
  };
}

export const CREATE_MULTI_TRADECART = 'CREATE_MULTI_TRADECART';
export function createMultiTradecart({
  customer,
  selectedDevices,
  selectedOutcome,
  orderUUID,
  service,
}) {
  return async function (dispatch) {
    const recycleCart = await post(service, [
      CREATE_TRADECART,
      {
        customer: orderUUID ? undefined : { ...customer },
        orderUUID,
        devices: selectedDevices.map((selectedDevice) => {
          const {
            name,
            hasNetwork,
            deviceID,
            deviceCode,
            questionsTree,
            diagnostic,
            searchText,
            deviceReference,
            networkLockStatus,
            promotionID,
          } = selectedDevice;
          return {
            deviceReference,
            criteriaAnswers: transformCriteria(questionsTree.criteriaAnswers),
            additionAnswers: transformCriteria(questionsTree.additionAnswers),
            deductionAnswers: transformCriteria(questionsTree.deductionAnswers),
            deviceID,
            deviceCode,
            networkStatus: hasNetwork
              ? capitalize(networkLockStatus)
              : undefined,
            outcome: selectedOutcome,
            uid: diagnostic?.reportUid ?? undefined,
            grade: diagnostic?.reportGrade ?? undefined,
            searchText: searchText ? searchText : name,
            promotionID,
          };
        }),
      },
    ]);
    if (recycleCart?.data?.status === 'SUCCESS')
      dispatch({
        type: CREATE_MULTI_TRADECART,
        tradecart: recycleCart.data.data.tradecart,
      });

    return recycleCart.data.data.tradecart;
  };
}

export function finalizeMultiRecycle({
  recycleCart,
  selectedDevices,
  selectedOutcome,
  orderUUID,
  customer,
}) {
  return async function (dispatch) {
    if (!recycleCart)
      return (recycleCart = await dispatch(
        createMultiTradecart({
          customer,
          selectedDevices,
          selectedOutcome,
          orderUUID,
        }),
      ));
  };
}

export function createCart({ recycleCart, recycleType, service }) {
  return async function (dispatch) {
    if (recycleCart) {
      const response = await post(service, [
        CREATE_CART,
        {
          tradecartID: recycleCart.id,
        },
      ]);

      const { cart, tradecartID, totalRecycleAmount, recycledDeviceNames } =
        response.data.data;

      if (response.data.status === 'SUCCESS') {
        dispatch({
          type: CREATE_CART,
          cart: {
            ...cart,
            tradecartID,
            totalRecycleAmount,
            recycledDeviceNames,
          },
        });

        recycleType === SWAP && dispatch(getCustomerAction(cart.customerUuid));

        dispatch(addNotificationAction(swapEvaluationSuccess()));
        return cart;
      }
    }
  };
}

export function returnDeviceAction(recycleCart, service) {
  return async function (dispatch, getState) {
    const { customer, recycle } = getState();
    if (recycleCart) {
      const response = await post(service, [
        RETURN_DEVICE,
        {
          customer: recycle.type === TRADE_IN ? customer : undefined,
          tradecartID: recycleCart.id,
        },
      ]);

      if (response.data.status === 'SUCCESS') {
        if (recycle.type === SWAP)
          dispatch(addNotificationAction(returnDeviceSuccess()));

        return response.data.status === 'SUCCESS';
      }
    }
    dispatch(hideLoaderAction());
  };
}

export function returnDevice({ recycleCart, type, customer, service }) {
  return async function () {
    if (recycleCart) {
      const response = await post(service, [
        RETURN_DEVICE,
        {
          customer:
            type === TRADE_IN
              ? {
                  ...customer,
                  name: `${customer.firstName} ${customer.lastName}`,
                }
              : undefined,
          tradecartID: recycleCart.id,
        },
      ]);

      return response.data.status === 'SUCCESS';
    }
  };
}

export const GET_RECYCLE_RECEIPT = 'GET_RECYCLE_RECEIPT';
export function getRecycleReceiptAction(tradecartID, service) {
  return async function (dispatch) {
    dispatch(showLoaderAction());

    const response = await post(service, [
      GET_RECYCLE_RECEIPT,
      {
        id: tradecartID,
      },
    ]);

    const { data, status } = response.data;

    if (status === 'SUCCESS') {
      dispatch({
        type: GET_RECYCLE_RECEIPT,
        receiptData: data,
      });
    }

    dispatch(hideLoaderAction());
  };
}

export const CANCEL_CMR_ORDER = 'CANCEL_CMR_ORDER';
export function cancelCmrOrderAction(tradecartID, service) {
  return async function (dispatch) {
    dispatch(showLoaderAction());

    const response = await post(service, [CANCEL_CMR_ORDER, { tradecartID }]);

    if (response?.data?.status === 'SUCCESS') {
      dispatch(addNotificationAction(orderCancelSuccess()));

      dispatch(getRecycleReceiptAction(tradecartID, service));
    } else {
      dispatch(addNotificationAction(orderCancelError()));

      dispatch(hideLoaderAction());
    }
  };
}

export const GET_TRADECART_STATUS = 'GET_TRADECART_STATUS';
export function getTradecartStatusAction() {
  return async function (dispatch, getState) {
    const { tradecart } = getState().recycle;

    if (tradecart) {
      const response = await post(undefined, [
        GET_TRADECART_STATUS,
        { tradecartId: tradecart.id },
      ]);

      if (response?.data?.status !== 'ERROR') {
        const { isCustomerApproved, idPhoto } = response.data.data;
        const tradecartStatus = {
          ...response.data.data,
          isCustomerApproved:
            isCustomerApproved === undefined
              ? 'PENDING'
              : isCustomerApproved === true
              ? 'APPROVED'
              : 'REFUSED',
          hasIdPhoto: idPhoto ? 'APPROVED' : 'PENDING',
        };

        dispatch({ type: GET_TRADECART_STATUS, tradecartStatus });
      }
    }
  };
}

export const SEND_QUOTE_APPROVAL_MESSAGE = 'SEND_QUOTE_APPROVAL_MESSAGE';
export function sendQuoteApprovalMessageAction(
  tradecartId,
  distributionMethod,
  service,
) {
  return async function (dispatch) {
    const response = await post(service, [
      SEND_QUOTE_APPROVAL_MESSAGE,
      { tradecartId, distributionMethod },
    ]);
    dispatch({ type: SEND_QUOTE_APPROVAL_MESSAGE });

    return response;
  };
}

export const SET_DIAGNOSTIC = 'SET_DIAGNOSTIC';
export function setDiagnosticAction({
  diagnosticString,
  selectedDevice,
  selectedSIMStatus,
  orderUUID,
}) {
  return function (dispatch) {
    let reportLink = null;
    let [reportStatus, reportGrade, reportUid] = diagnosticString.split('~');

    if (reportStatus.toLowerCase() === 'completed') {
      // If grade is 'A' change to 'W'
      if (reportGrade.toLowerCase() === 'a') {
        reportGrade = 'W';
      }
      reportLink = `${selectedDevice.diagnosticReportURL}&uid=${reportUid}`;
    }

    dispatch({
      type: SET_DIAGNOSTIC,
      selectedDevice,
      diagnostic: {
        reportStatus: reportStatus.toLowerCase(),
        reportGrade,
        reportUid,
        reportLink,
      },
    });

    return dispatch(
      evaluateDeviceAction({
        selectedSIMStatus,
        selectedDevice: {
          ...selectedDevice,
          diagnostic: {
            reportGrade,
          },
        },
        orderUUID,
      }),
    );
  };
}

export const CLEAR_DIAGNOSTIC = 'CLEAR_DIAGNOSTIC';
export function clearDiagnosticAction() {
  return function (dispatch) {
    dispatch({ type: CLEAR_DIAGNOSTIC });
  };
}

export const GET_ONLINE_TRADEIN_QUOTE = 'GET_ONLINE_TRADEIN_QUOTE';
export function getOnlineTradeinQuote(tradecartSID = '') {
  return async function (dispatch) {
    const response = await post(API_POSALE_SERVICE, [
      GET_ONLINE_TRADEIN_QUOTE,
      { tradecartSID },
    ]);

    if (response.data.status === 'SUCCESS') {
      const { customer } = response.data.data;

      if (!isEmpty(response.data.data)) {
        dispatch({
          type: GET_ONLINE_TRADEIN_QUOTE,
          data: {
            ...response.data.data,
          },
        });

        dispatch(setCustomerAction(customer));
      } else {
        dispatch(addNotificationAction(onlineTradeInQuoteError()));
      }
    }

    return response.data;
  };
}

export const SEARCH_TYPE_NAME = 'CUSTOMER_NAME';
export const SEARCH_TYPE_EMAIL = 'CUSTOMER_EMAIL';
export const SEARCH_TYPE_TRADECART = 'TRADECART_SID';
export const SEARCH_ONLINE_TRADEIN_QUOTES = 'SEARCH_ONLINE_TRADEIN_QUOTES';
export function searchOnlineTradeinQuotes(
  searchText = '',
  searchType = SEARCH_TYPE_NAME,
) {
  return async function (dispatch) {
    const response = await post(API_POSALE_SERVICE, [
      SEARCH_ONLINE_TRADEIN_QUOTES,
      { searchText, searchType },
    ]);

    if (response.data.status !== 'SUCCESS' || isEmpty(response.data.data)) {
      dispatch(addNotificationAction(onlineTradeInQuoteError()));
    }

    return response.data;
  };
}

export const ADD_DEVICE = 'ADD_DEVICE';
export function addDevice(device) {
  return function (dispatch) {
    dispatch({ type: ADD_DEVICE, device });
  };
}

export const REMOVE_DEVICE = 'REMOVE_DEVICE';
export function removeDevice(id) {
  return async function (dispatch) {
    dispatch({ type: REMOVE_DEVICE, id });
  };
}

export const SET_DEVICE_REFERENCE = 'SET_DEVICE_REFERENCE';
export function setDeviceReference({ deviceReference, referenceType }, id) {
  return {
    type: SET_DEVICE_REFERENCE,
    deviceReference,
    referenceType,
    id,
  };
}

export const SET_DEVICE_SIM_STATUS = 'SET_DEVICE_SIM_STATUS';
export function setDeviceSimStatus(id, selectedNetworkLockStatus) {
  return async function (dispatch) {
    dispatch({
      type: SET_DEVICE_SIM_STATUS,
      id,
      networkLockStatus: selectedNetworkLockStatus,
    });
  };
}

export const SET_SEARCH_DEVICE_REFERENCE = 'SET_SEARCH_DEVICE_REFERENCE';
export function setSearchDeviceReferenceAction({
  deviceReference,
  referenceType,
}) {
  return {
    type: SET_SEARCH_DEVICE_REFERENCE,
    deviceReference,
    referenceType,
  };
}

export const SELECT_PROMOTION = 'SELECT_PROMOTION';
export function selectPromotion(promotionID) {
  return {
    type: SELECT_PROMOTION,
    promotionID,
  };
}

export const SELECT_DEVICE_PROMOTION = 'SELECT_DEVICE_PROMOTION';
export function selectDevicePromotion(deviceID, promotionID) {
  return {
    type: SELECT_DEVICE_PROMOTION,
    deviceID,
    promotionID,
  };
}

export const GET_COLLECTION_TIMES = 'GET_COLLECTION_TIMES';
export function getCollectionTimes({ countryCode, postalCode }) {
  return async function (dispatch) {
    const response = await post(API_POSALE_SERVICE, [
      GET_COLLECTION_TIMES,
      { countryCode, postalCode },
    ]);

    dispatch({
      type: GET_COLLECTION_TIMES,
      payload: response.data.data,
    });
  };
}

export const CLEAR_COLLECTION_TIMES = 'CLEAR_COLLECTION_TIMES';
export function clearCollectionTimes() {
  return function (dispatch) {
    dispatch({
      type: CLEAR_COLLECTION_TIMES,
    });
  };
}
