import ReactHtmlParser from 'html-react-parser';

import { useSelector } from 'react-redux';

export const TransOverride = ({ translation, id }) => {
  const translations = useSelector(({ whitelabel }) => whitelabel.translations);

  const entry = translations?.find((t) => t.key === id);

  return entry ? ReactHtmlParser(entry.message) : translation;
};

export default TransOverride;
