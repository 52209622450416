import React from 'react';

import WLIcon from './WLIcon';
import './WLList.styl';

const WLList = ({ items }) => {
  return (
    <div className='legendDiv'>
      {items.map((item, index) => {
        return <WLIcon key={`WLI${index}`} item={item} index={index} />;
      })}
    </div>
  );
};

export default WLList;
