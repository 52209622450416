import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

export const ScrollToTop = () => {
  let location = useLocation();
  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    if (searchParams.get('noscroll') !== 'true') window.scrollTo(0, 0);
  }, [location.pathname, searchParams]);

  React.useEffect(() => {
    const id = location.hash.replace('#', '');

    const element = document.getElementById(id);

    if (element) element.scrollIntoView({ behavior: 'smooth' });
  }, [location.hash]);

  return <Outlet />;
};

export default ScrollToTop;
