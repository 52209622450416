import { addNotificationAction } from 'actions/ui';
import { adminPost as post } from 'apis/v2';
import {
  convertArrayToOptions,
  getLanguageName,
  sanitizeRequestData,
} from 'utils';

import {
  createCampaignSuccess,
  createCohortRuleSuccess,
  createCommunicationSuccess,
  createOfferRuleSuccess,
  createOfferSuccess,
  updateCampaignSuccess,
  updateCohortRuleSuccess,
  updateCommunicationSuccess,
  updateOfferRuleSuccess,
  updateOfferSuccess,
} from 'constants/notifications';

export const GET_ALL_CAMPAIGNS = 'GET_ALL_CAMPAIGNS';
export function getAllCampaignsAction() {
  return async function (dispatch) {
    const response = await post(GET_ALL_CAMPAIGNS);

    dispatch({
      type: GET_ALL_CAMPAIGNS,
      data: response.data.data.campaigns,
    });
  };
}

export const GET_CAMPAIGN = 'GET_CAMPAIGN';
export function getCampaignAction(campaignCode) {
  return async function (dispatch) {
    const response = await post(GET_CAMPAIGN, {
      campaignCode,
    });

    dispatch({
      type: GET_CAMPAIGN,
      data: response.data.data.campaign,
    });
  };
}

export const GET_CAMPAIGN_OFFERS = 'GET_CAMPAIGN_OFFERS';
export function getCampaignOffersAction(campaignCode) {
  return async function (dispatch) {
    const response = await post(GET_CAMPAIGN_OFFERS, {
      campaignCode,
    });

    dispatch({
      type: GET_CAMPAIGN_OFFERS,
      data: {
        offers: response.data.data.offers,
        offerRules: response.data.data.rules,
      },
    });
  };
}

export const GET_CAMPAIGN_COHORTS = 'GET_CAMPAIGN_COHORTS';
export function getCampaignCohortsAction(campaignCode) {
  return async function (dispatch) {
    const response = await post(GET_CAMPAIGN_COHORTS, {
      campaignCode,
    });

    dispatch({
      type: GET_CAMPAIGN_COHORTS,
      data: response.data.data,
    });
  };
}

export const GET_CAMPAIGN_MESSAGES = 'GET_CAMPAIGN_MESSAGES';
export function getCampaignMessagesAction(campaignCode) {
  return async function (dispatch) {
    const response = await post(GET_CAMPAIGN_MESSAGES, {
      campaignCode,
    });

    dispatch({
      type: GET_CAMPAIGN_MESSAGES,
      data: response.data.data,
    });
  };
}

export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN';
export function createCampaignAction(campaign) {
  return async function (dispatch) {
    const response = await post(CREATE_CAMPAIGN, {
      campaign: {
        ...sanitizeRequestData({
          ...campaign,
          replyTo: campaign.replyTo ? [campaign.replyTo] : [],
        }),
        commsType: 'MARKETING',
      },
    });

    if (response.data.status === 'SUCCESS') {
      dispatch(addNotificationAction(createCampaignSuccess()));
      dispatch({
        type: CREATE_CAMPAIGN,
        data: response.data.data.campaigns,
      });
    }

    return response.data.status === 'SUCCESS';
  };
}

export const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN';
export function updateCampaignAction(campaign) {
  return async function (dispatch) {
    const response = await post(UPDATE_CAMPAIGN, {
      campaign: sanitizeRequestData({
        ...campaign,
        replyTo: campaign.replyTo ? [campaign.replyTo] : [],
      }),
    });

    if (response.data.status === 'SUCCESS') {
      dispatch(addNotificationAction(updateCampaignSuccess()));
      dispatch({
        type: UPDATE_CAMPAIGN,
        data: response.data.data.campaign,
      });
    }

    return response.data.status === 'SUCCESS';
  };
}

export const CREATE_COHORT_RULE = 'CREATE_COHORT_RULE';
export function createCohortRuleAction(cohortRule) {
  return async function (dispatch) {
    const response = await post(CREATE_COHORT_RULE, {
      cohortRule,
    });

    if (response.data.status === 'SUCCESS') {
      dispatch(addNotificationAction(createCohortRuleSuccess()));
    }

    return response.data.status === 'SUCCESS';
  };
}

export const UPDATE_COHORT_RULE = 'UPDATE_COHORT_RULE';
export function updateCohortRuleAction(cohortRule) {
  return async function (dispatch) {
    const response = await post(UPDATE_COHORT_RULE, {
      cohortRule,
    });

    if (response.data.status === 'SUCCESS') {
      dispatch(addNotificationAction(updateCohortRuleSuccess()));
    }

    return response.data.status === 'SUCCESS';
  };
}

export const CREATE_CAMPAIGN_ACTION = 'CREATE_CAMPAIGN_ACTION';
export function createCommunicationAction({
  notifyList,
  bccList,
  ...campaignAction
}) {
  return async function (dispatch) {
    const response = await post(CREATE_CAMPAIGN_ACTION, {
      campaignAction: {
        ...campaignAction,
        notifyList: notifyList ? JSON.parse(notifyList) : undefined,
        bccList: bccList?.trim().split(','),
      },
    });

    if (response.data.status === 'SUCCESS') {
      dispatch(addNotificationAction(createCommunicationSuccess()));
    }

    return response.data.status === 'SUCCESS';
  };
}

export const UPDATE_CAMPAIGN_ACTION = 'UPDATE_CAMPAIGN_ACTION';
export function updateCommunicationAction({
  notifyList,
  bccList,
  ...campaignAction
}) {
  return async function (dispatch) {
    const response = await post(UPDATE_CAMPAIGN_ACTION, {
      campaignAction: {
        ...campaignAction,
        notifyList: notifyList ? JSON.parse(notifyList) : undefined,
        bccList: bccList?.trim().split(','),
      },
    });

    if (response.data.status === 'SUCCESS') {
      dispatch(addNotificationAction(updateCommunicationSuccess()));
      return true;
    }

    return false;
  };
}

export const CREATE_UPGRADE_OFFER = 'CREATE_UPGRADE_OFFER';
export function createUpgradeOfferAction(offer) {
  return async function (dispatch) {
    const response = await post(CREATE_UPGRADE_OFFER, { offer });

    if (response.data.status === 'SUCCESS') {
      dispatch(addNotificationAction(createOfferSuccess()));
    }

    return response.data.status === 'SUCCESS';
  };
}

export const UPDATE_UPGRADE_OFFER = 'UPDATE_UPGRADE_OFFER';
export function updateUpgradeOfferAction(offer) {
  return async function (dispatch) {
    const response = await post(UPDATE_UPGRADE_OFFER, { offer });

    if (response.data.status === 'SUCCESS') {
      dispatch(addNotificationAction(updateOfferSuccess()));
    }

    return response.data.status === 'SUCCESS';
  };
}

export const CREATE_UPGRADE_OFFER_RULE = 'CREATE_UPGRADE_OFFER_RULE';
export function createUpgradeOfferRuleAction(offerRule) {
  return async function (dispatch) {
    const response = await post(CREATE_UPGRADE_OFFER_RULE, {
      offerRule: sanitizeRequestData(offerRule),
    });

    if (response.data.status === 'SUCCESS') {
      dispatch(addNotificationAction(createOfferRuleSuccess()));
    }

    return response.data.status === 'SUCCESS';
  };
}

export const UPDATE_UPGRADE_OFFER_RULE = 'UPDATE_UPGRADE_OFFER_RULE';
export function updateUpgradeOfferRuleAction(offerRule) {
  return async function (dispatch) {
    const response = await post(UPDATE_UPGRADE_OFFER_RULE, {
      offerRule: sanitizeRequestData(offerRule),
    });

    if (response.data.status === 'SUCCESS') {
      dispatch(addNotificationAction(updateOfferRuleSuccess()));
    }

    return response.data.status === 'SUCCESS';
  };
}

export const GET_CAMPAIGN_FORM_DATA = 'GET_CAMPAIGN_FORM_DATA';
export function getCampaignFormDataAction(params) {
  return async function (dispatch) {
    const response = await post(GET_CAMPAIGN_FORM_DATA, params);

    if (response?.data?.data) {
      const {
        campaignCodes,
        contractStatuses,
        crmCommsCategories,
        communicationsTypes,
        financingTypes,
        triggerEvents,
        salesChannels,
        stages,
        marketingOptIns,
        cohortDelayType,
        cohortTypes,
        stopOutcomes,
        languages,
        paymentPlans,
        categories,
        families,
        devices,
        manufacturers,
      } = response.data.data;

      dispatch({
        type: GET_CAMPAIGN_FORM_DATA,
        data: {
          campaignCodes: campaignCodes?.map((campaignCode) => ({
            value: campaignCode,
            label: campaignCode,
          })),
          contractStatuses: convertArrayToOptions(contractStatuses),
          crmCommsCategories: convertArrayToOptions(crmCommsCategories),
          communicationsTypes: convertArrayToOptions(communicationsTypes),
          financingTypes: convertArrayToOptions(financingTypes),
          triggerEvents: convertArrayToOptions(triggerEvents),
          salesChannels: convertArrayToOptions(salesChannels),
          stages: convertArrayToOptions(stages),
          marketingOptIns: convertArrayToOptions(marketingOptIns),
          cohortDelayTypes: convertArrayToOptions(cohortDelayType),
          cohortTypes: convertArrayToOptions(cohortTypes),
          stopOutcomes: convertArrayToOptions(stopOutcomes),
          languages: languages?.map((language) => ({
            value: language,
            label: getLanguageName(language) || language,
          })),
          paymentPlans: paymentPlans?.map((paymentPlan) => ({
            label: paymentPlan.name,
            value: paymentPlan.code,
          })),
          deviceCategories: categories?.map((category) => ({
            label: category.name,
            value: category.code,
          })),
          deviceFamilies: families?.map((deviceFamily) => ({
            label: deviceFamily.name,
            value: deviceFamily.code,
          })),
          devices: devices?.map((device) => ({
            label: device.name,
            value: device.code,
          })),
          manufacturers: manufacturers?.map((manufacturer) => ({
            label: manufacturer.name,
            value: manufacturer.code,
          })),
        },
      });
    }
  };
}

export const GET_OFFERS_FORM_DATA = 'GET_OFFERS_FORM_DATA';
export function getOffersFormDataAction(params) {
  return async function (dispatch) {
    const response = await post(GET_OFFERS_FORM_DATA, params);

    if (response?.data?.data) {
      const {
        financingTypes,
        enabledDisabledStatuses,
        rules,
        paymentPlans,
        categories,
        families,
        devices,
        manufacturers,
        cmapaigns,
      } = response.data.data;
      dispatch({
        type: GET_OFFERS_FORM_DATA,
        data: {
          financingTypes: convertArrayToOptions(financingTypes),
          statuses: convertArrayToOptions(enabledDisabledStatuses),
          rules: convertArrayToOptions(rules),
          paymentPlans: paymentPlans?.map((paymentPlan) => ({
            label: paymentPlan.name,
            value: paymentPlan.code,
          })),
          deviceCategories: categories?.map((category) => ({
            label: category.name,
            value: category.code,
          })),
          deviceFamilies: families?.map((deviceFamily) => ({
            label: deviceFamily.name,
            value: deviceFamily.code,
          })),
          devices: devices?.map((device) => ({
            label: device.name,
            value: device.code,
          })),
          manufacturers: manufacturers?.map((manufacturer) => ({
            label: manufacturer.name,
            value: manufacturer.code,
          })),
          cmapaigns: cmapaigns?.map((campaign) => ({
            label: campaign.name,
            value: campaign.code,
          })),
        },
      });
    }
  };
}

export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN';
export function deleteCampaignAction(campaignCode) {
  return async function () {
    const response = await post(DELETE_CAMPAIGN, { campaignCode });

    return response.data.status === 'SUCCESS';
  };
}

export const DELETE_UPGRADE_OFFER = 'DELETE_UPGRADE_OFFER';
export function deleteOffer(offer) {
  return async function (dispatch) {
    const response = await post(DELETE_UPGRADE_OFFER, { offer });

    if (response.data.status === 'SUCCESS')
      dispatch({
        type: DELETE_UPGRADE_OFFER,
        data: response.data.data.offers,
      });
  };
}

export const DELETE_UPGRADE_OFFER_RULE = 'DELETE_UPGRADE_OFFER_RULE';
export function deleteOfferRule(offerRule) {
  return async function (dispatch) {
    const response = await post(DELETE_UPGRADE_OFFER_RULE, { offerRule });

    if (response.data.status === 'SUCCESS')
      dispatch({
        type: DELETE_UPGRADE_OFFER_RULE,
        data: response.data.data.rules,
      });
  };
}

export const DELETE_COMMUNICATION = 'DELETE_COMMUNICATION';
export function deleteCommunicationAction({ campaignCode, id }) {
  return async function (dispatch) {
    const response = await post(DELETE_COMMUNICATION, { campaignCode, id });

    if (response.data.status === 'SUCCESS')
      dispatch({
        type: DELETE_COMMUNICATION,
        data: response.data.data.campaign,
      });
  };
}
