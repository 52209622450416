import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { parse, stringify } from 'query-string';

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useQueryParams = () => {
  let location = useLocation();
  const { search: queryParamsString } = useLocation();
  const [queryParamsObject, setQueryParamsObject] = useState(
    parse(queryParamsString),
  );

  useEffect(() => {
    let currentQueryParamsObject = parse(location.search);

    if (!isEqual(currentQueryParamsObject, queryParamsObject)) {
      setQueryParamsObject(parse(location.search));
    }
  }, [location, queryParamsObject]);

  const updateQueryParams = async (newParams) =>
    await setQueryParamsObject({
      ...queryParamsObject,
      ...newParams,
    });

  const appendQueryParamsToUrl = (url, newParams = {}) =>
    !isEmpty(queryParamsObject)
      ? `${url}?${stringify({ ...queryParamsObject, ...newParams })}`
      : url;

  const updateAndAppendQueryParamsToUrl = (url, newParams) => {
    updateQueryParams(newParams);
    return appendQueryParamsToUrl(url, newParams);
  };

  return {
    queryParamsObject,
    appendQueryParamsToUrl,
    updateQueryParams,
    updateAndAppendQueryParamsToUrl,
  };
};

export default useQueryParams;
