import { currencyFormatter } from 'helpers/formatters';

import React from 'react';

import { Trans } from '@lingui/macro';

import { ImageThumbnail } from 'components/Image';

import './SaleSummary.styl';

const SaleSummary = ({
  contracts,
  currency,
  language,
  summary,
  isFullPayment,
}) => {
  const generateContractSummary = ({
    extras,
    financing,
    financingType,
    funderReference,
    products,
  }) =>
    [...products, ...extras].map((item, index) => {
      const isFirstItem = index === 0;

      const rowStyle = !isFirstItem ? { border: 'none' } : {};

      return (
        <tr key={`details-${funderReference}-${item.uuid}`} style={rowStyle}>
          <td
            align='center'
            data-test='posale-contract-saleSummary-funderReference'
          >
            {isFirstItem && (
              <a href={`#${funderReference}`}>{funderReference}</a>
            )}
          </td>

          <td
            className='sale-summary__products'
            data-test='posale-contract-saleSummary-funderReference'
          >
            <ImageThumbnail src={item.imgURL} alt={item.description} />
            <span>{item.description}</span>
          </td>

          <td
            align='center'
            data-test='posale-contract-saleSummary-paymentPlan'
          >
            {isFirstItem && financing.payment_plan_name}
          </td>

          <td
            align='right'
            data-test='posale-contract-saleSummary-discounts'
          ></td>

          <td
            className='sale-summary__prices'
            align='right'
            data-test='posale-contract-saleSummary-price'
          >
            {currencyFormatter(
              financingType === 'B2B_LEASE' ? item.priceExclTax : item.value,
              currency,
              language,
            )}
          </td>

          <td align='right' data-test='posale-contract-saleSummary-quantity'>
            {item.quantity || 1}
          </td>

          <td
            align='right'
            data-test='posale-contract-saleSummary-financedAmount'
          >
            {currencyFormatter(
              (financingType === 'B2B_LEASE' ? item.priceExclTax : item.value) *
                (item.quantity || 1),
              currency,
              language,
            )}
          </td>
        </tr>
      );
    });

  let totalItems = 0;

  contracts.forEach((contract) => {
    [...contract.products, ...contract.extras].forEach((item) => {
      totalItems += item.quantity || 1;
    });
  });

  return (
    <table className='sale-summary__table'>
      <thead>
        <tr>
          <th align='center'>
            <Trans id='sale.summary.funder.reference'>Funder Reference</Trans>
          </th>
          <th align='left'>
            <Trans id='sale.summary.devices'>Products & Extras</Trans>
          </th>
          <th align='center'>
            <Trans id='sale.summary.payment.plan'>Payment Plan</Trans>
          </th>
          <th align='right'>
            <Trans id='sale.summary.discounts'>Discounts</Trans>
          </th>
          <th align='right'>
            <Trans id='sale.summary.price'>Price</Trans>
          </th>
          <th align='right'>
            <Trans id='sale.summary.quantity'>Quantity</Trans>
          </th>
          <th align='right'>
            {isFullPayment ? (
              <Trans id='sale.summary.total.amount'>Total Amount</Trans>
            ) : (
              <Trans id='sale.summary.financed.amount'>Financed Amount</Trans>
            )}
          </th>
        </tr>
      </thead>
      <tbody>
        {contracts.map(generateContractSummary)}
        <tr key='details-totals'>
          <td colSpan='2' />

          <td className='bold' align='right'>
            <Trans id='sale.summary.total'>Total</Trans>
          </td>

          <td
            align='right'
            data-test='posale-contract-saleSummary-totalDiscounts'
          >
            {currencyFormatter(summary?.totalDiscounts, currency, language)}
          </td>

          <td align='right' data-test='posale-contract-saleSummary-total'></td>

          <td
            align='right'
            data-test='posale-contract-saleSummary-total-quantity'
          >
            {totalItems}
          </td>

          <td
            align='right'
            data-test='posale-contract-saleSummary-totalFinancedAmout'
          >
            {currencyFormatter(
              summary?.totalFinancedAmount,
              currency,
              language,
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default SaleSummary;
