import {
  addExtraAction,
  addParentDeviceAction,
  addToCartAction,
  clearSelectedProductAction,
  getPricedExtrasAction,
  removeCurrentCartAction,
  removeOrderAction,
  removeParentDeviceAction,
  saveCurrentCartAction,
  selectOptionAction,
  setSelectedPaymentPlanAction,
  updateSelectedProductAction,
} from 'actions/carts';
import { addNotificationAction } from 'actions/ui';
import flow from 'enhancers/flow';
import { stringFormatter } from 'helpers/formatters';
import isEmpty from 'lodash/isEmpty';
import { getCurrentCart } from 'utils';

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import { t } from '@lingui/macro';

import CartBadge from 'components/CartBadge';
import Footer from 'components/Footer';

import { financing } from 'constants/flows';
import { blockedOrderWarning } from 'constants/notifications';
import { cartPageUrl, standardFinanceFlowUrl } from 'constants/routes';

import Breadcrumbs from './components/Breadcrumbs';

const StandardFinancing = ({
  steps = [],
  currentStep = 0,
  setSteps,
  next,
  prev,
  currentStepName,
  getStepName,
  syncFlow,
  discardFlow,
}) => {
  const [locationState, setLocationState] = useState();
  const [cartState, setCartState] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const previousLocation = useRef();
  const oldCart = useRef();

  const { cartUUID } = useParams();

  const canCustomize = useSelector(
    ({ whitelabel }) => whitelabel.configs.canCustomize,
  );
  const canAddExtras = useSelector(
    ({ whitelabel }) => whitelabel.configs.canAddExtras,
  );
  const cart = useSelector(({ carts }) => getCurrentCart(carts, cartUUID));
  const manualCTOCategories = useSelector(
    ({ whitelabel }) => whitelabel.configs.manualCTOCategories,
  );

  const cartFinancingType = cart?.financingType;
  const selectedService = cart?.selectedService;
  const [flowURL, setFlowURL] = useState('');

  const setCorrectFlow = () => {
    let steps = [];
    if (
      (canCustomize === 'CATALOG' && cart?.selected?.hasCTOs) ||
      (canCustomize === 'MANUAL' &&
        manualCTOCategories.includes(cart?.category))
    ) {
      steps = financing.customizable;
    } else if (cart?.category === 'ACCESSORIES') {
      dispatch(getPricedExtrasAction(cart?.uuid, cart?.selected?.deviceCode));
      steps = financing.accessories;
    } else {
      steps = financing.default;
    }
    if (selectedService?.serviceType === 'CATEGORIES') {
      steps.shift();
    }
    setSteps(steps);
  };

  // This use effect makes sure we have the correct flow URL set up
  useEffect(() => {
    setFlowURL(standardFinanceFlowUrl(cartUUID));
  }, [cartUUID, cartFinancingType]);

  useEffect(() => {
    setCorrectFlow();

    syncFlow(flowURL);
    dispatch(saveCurrentCartAction(cartUUID));
  }, [dispatch, cartUUID, flowURL]);

  useEffect(() => {
    previousLocation && initializePage(oldCart, previousLocation);
  }, [oldCart, previousLocation]);

  useEffect(() => {
    previousLocation.current = locationState;
    oldCart.current = cartState;

    setLocationState(location);
    setCartState(cart);

    previousLocation.current &&
      initializePage(oldCart.current, previousLocation.current);
    return () => {
      dispatch(removeCurrentCartAction());
      discardFlow();
    };
  }, [cart, location, dispatch]);

  const initializePage = (oldCart, oldLocation) => {
    if (
      oldCart.category !== cart.category ||
      oldCart.selected?.deviceCode !== cart.selected?.deviceCode ||
      oldCart?.selected?.hasCTOs !== cart?.selected?.hasCTOs
    ) {
      setCorrectFlow();
    }

    if (oldLocation.pathname !== location.pathname) {
      syncFlow(flowURL);

      const oldPage = getStepName(oldLocation.pathname, flowURL);
      const currentPage = getStepName(location.pathname, flowURL);

      if (
        oldPage === 'options' &&
        currentPage === 'customize' &&
        canCustomize === 'CATALOG' &&
        cart.selected?.hasCTOs
      )
        dispatch(addParentDeviceAction(cartUUID));

      if (
        canCustomize === 'CATALOG' &&
        cart.selected?.hasCTOs &&
        oldPage === 'customize' &&
        currentPage === 'options'
      )
        dispatch(removeParentDeviceAction(cartUUID));

      if (
        (oldPage === 'extras' && currentPage === 'options') ||
        (oldPage === 'customize' &&
          currentPage === 'options' &&
          canCustomize === 'MANUAL') ||
        (oldPage === 'extras' &&
          currentPage === 'customize' &&
          canCustomize === 'CATALOG' &&
          cart.selected?.hasCTOs)
      )
        dispatch(selectOptionAction(cartUUID));

      if (oldPage === 'categories' && currentPage === 'extras') {
        dispatch(addNotificationAction(blockedOrderWarning()));
        navigate(flowURL);
      }
    }
  };

  const blockedButtons = () => {
    switch (currentStepName) {
      case 'categories':
        return typeof cart.category === 'undefined';
      case 'catalog':
        return typeof cart.selected?.deviceCode === 'undefined';
      default:
        return false;
    }
  };

  const getNextBtnLabel = (steps, currentStep) => {
    return !isEmpty(steps)
      ? steps[currentStep]?.nextBtnLabel
      : financing.default[currentStep]?.nextBtnLabel;
  };

  const hiddenBackButtons = () => {
    return currentStep < 1;
  };

  const hiddenNextButtons = () => {
    switch (currentStepName) {
      case 'categories':
        return true;
      case 'catalog':
        return true;
      default:
        return false;
    }
  };

  const nextPage = async () => {
    let allowNavigation = true;

    switch (currentStepName) {
      case 'accessoriesCatalog':
        if (canAddExtras === 'CATALOG')
          await dispatch(addExtraAction(cartUUID, cart?.selected?.deviceCode));
        await dispatch(addToCartAction(cartUUID, true));

        allowNavigation = false;
        navigate(flowURL);
        syncFlow(flowURL);

        break;
      case 'customize':
        await dispatch(updateSelectedProductAction(cartUUID));
        break;
      case 'extras':
        await dispatch(addToCartAction(cartUUID, true));
        await dispatch(
          setSelectedPaymentPlanAction(cartUUID, cart?.selected?.paymentplanID),
        );
        await dispatch(clearSelectedProductAction(cartUUID));
        allowNavigation = false;

        await navigate(cartPageUrl(cartUUID));

        syncFlow(flowURL);
        dispatch(removeOrderAction(cartUUID));
        break;
      default:
        break;
    }

    allowNavigation && (await next());

    return (
      steps &&
      allowNavigation &&
      navigate(stringFormatter(steps[currentStep + 1].path, [cartUUID], '#'))
    );
  };

  const prevPage = async () => {
    await prev();
    if (currentStep === 1) {
      await dispatch(clearSelectedProductAction(cartUUID));
      await dispatch(
        setSelectedPaymentPlanAction(cartUUID, cart?.selected?.paymentplanID),
      );
    }
    navigate(stringFormatter(steps[currentStep - 1].path, [cartUUID], '#'));
  };

  return (
    <>
      <Breadcrumbs
        currentStep={currentStep}
        steps={steps}
        cartUUID={cartUUID}
        navigate={navigate}
        syncFlow={syncFlow}
        category={cartState?.categoryName}
        family={cartState?.family}
      />

      <CartBadge cart={cart} />

      <div id='standard-flow'>
        <Outlet context={[nextPage]} />
      </div>

      <Footer
        classes='main'
        copyright={false}
        backBtnLabel={t({ id: 'navigation.back.button', message: `Back` })}
        backBtnHidden={hiddenBackButtons()}
        backBtnAction={prevPage}
        nextBtnLabel={getNextBtnLabel(steps, currentStep)}
        nextBtnHidden={hiddenNextButtons()}
        nextBtnDisabled={blockedButtons()}
        nextBtnAction={nextPage}
      />
    </>
  );
};

export default flow({
  name: 'financing',
  total: financing.default.length,
  steps: financing.default,
})(StandardFinancing);
