import { GET_ATTACHMENTS } from 'actions/admin/attachments';

import initialState from 'reducers/initialState';

export default function admin(state = initialState.adminAttachments, action) {
  switch (action.type) {
    case GET_ATTACHMENTS:
      return { ...state, attachments: action.data };
    default:
      return state;
  }
}
