import React from 'react';

const SvgIconOkSmall = ({ title, ...props }) => (
  <svg width='24' height='24' {...props}>
    {title ? <title>{title}</title> : null}
    <defs>
      <clipPath id='a'>
        <path d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
    <g clip-path='url(#a)'>
      <path fill='none' d='M0 0h24v24H0z' />
      <g data-name='Group 1193'>
        <path
          data-name='Path 150'
          d='M12 4a8 8 0 018 8 8 8 0 01-8 8 8 8 0 01-8-8 8 8 0 018-8z'
          fill='#b3d44f'
          fill-rule='evenodd'
        />
        <path
          data-name='Path 151'
          d='M15.754 8.667a.926.926 0 01.323.108.951.951 0 01.451 1.021.926.926 0 01-.137.311c-.032.047-.073.088-.109.131l-4.822 4.829a.926.926 0 01-.263.184.937.937 0 01-.783 0 .926.926 0 01-.263-.184L7.714 12.63c-.036-.044-.077-.084-.109-.131a.955.955 0 01.172-1.236.951.951 0 011.115-.052c.047.032.088.073.131.109l1.783 1.783 4.166-4.166.127-.113a.925.925 0 01.311-.137 1.234 1.234 0 01.344-.02z'
          fill='#fff'
        />
      </g>
    </g>
  </svg>
);

export default SvgIconOkSmall;
