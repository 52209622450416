import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';

import Contracts from 'components/sales/Contracts';

import { defaultSalesFilters } from 'constants/contracts';
import { viewSaleUrl } from 'constants/routes';
import { contractStatus, swapStatus } from 'constants/status';

import useTrackPage from 'hooks/useTrackPage';

const Sales = () => {
  useTrackPage('Sales');

  const navigate = useNavigate();

  const sales = useSelector(({ sales }) => sales.list);

  const columns = [
    {
      label: t({ id: 'table.column.created', message: `Created on` }),
      accessor: 'createdDate',
      type: 'date',
      filterable: true,
      condition: 'after',
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.activated', message: `Activated on` }),
      accessor: 'activatedDate',
      type: 'date',
      filterable: true,
      condition: 'after',
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.updated', message: `Updated on` }),
      accessor: 'updatedDate',
      type: 'date',
      filterable: true,
      condition: 'after',
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.vendor', message: `Vendor` }),
      accessor: 'vendorName',
      type: 'text',
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.store', message: `Store` }),
      accessor: 'storeName',
      type: 'text',
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.cart', message: `Cart` }),
      accessor: 'cartUUID',
      type: 'text',
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.customer', message: `Customer` }),
      accessor: 'customer',
      type: 'multiLine',
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.device', message: `Device` }),
      accessor: 'descriptions',
      type: 'multiLine',
      filterable: true,
      sortable: true,
      align: 'left',
      wrap: true,
    },
    {
      label: t({
        id: 'table.column.delivery.type',
        message: `Delivery Type`,
      }),
      filterable: true,
      sortable: true,
      align: 'center',
      accessor: 'deliveryType',
      type: 'text',
    },
    {
      label: t({
        id: 'table.column.financing.type',
        message: `Financing Type`,
      }),
      accessor: 'financingType',
      type: 'text',
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.contract.status',
        message: `Contract Status`,
      }),
      accessor: 'status',
      type: 'status',
      list: contractStatus(),
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: true,
    },
    {
      label: t({ id: 'table.column.swap.status', message: `Swap Status` }),
      accessor: 'swapStatus',
      type: 'status',
      list: swapStatus(),
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: true,
    },
    {
      label: t({ id: 'table.column.actions', message: `Actions` }),
      accessor: 'actions',
      type: 'actions',
      filterable: false,
      sortable: false,
      align: 'right',
      wrap: false,
    },
  ];

  const actions = [
    {
      label: t({ id: 'contract.reports.actions.view', message: `View` }),
      handler: (_, { cartUUID }) => navigate(viewSaleUrl(cartUUID)),
      testSelector: 'posale-sales-view-button',
    },
  ];

  const rows = sales.map((sale) => ({
    ...sale,
    descriptions: [
      sale.productDescriptions,
      sale.numberOfExtras <= 0
        ? ''
        : t({
            id: 'pendings.table.description.extras',
            message: `${sale.numberOfExtras} Extra(s)`,
          }),
    ],
    customer: [sale.customerName, sale.customerPhone, sale.customerEmail],
    actions,
  }));

  return (
    <Contracts
      key='sales'
      name='Sales'
      title={<Trans id='sales.contracts.title'>Sales</Trans>}
      defaultOrder={'lastUpdateDatetime'}
      rows={rows}
      columns={columns}
      noWordBreak={true}
      isSales
      initialFilterValues={defaultSalesFilters}
    />
  );
};

export default Sales;
