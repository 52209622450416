import { uploadSales } from 'actions/sales';

import React from 'react';
import { useDispatch } from 'react-redux';

import { t } from '@lingui/macro';

import FileUploadReport from 'components/file_upload/FileUploadReport';

import { contractStatus } from 'constants/status';

import './SalesUpload.styl';

const SalesUpload = () => {
  const dispatch = useDispatch();

  const handleSubmit = (file) => dispatch(uploadSales(file));

  const successCols = [
    {
      label: t({ id: 'sales.upload.report.table.id', message: `Sale ID` }),
      accessor: 'cartUUID',
      align: 'left',
    },
    {
      label: t({
        id: 'sales.upload.report.table.customer',
        message: `Customer Name`,
      }),
      accessor: 'customerName',
      align: 'left',
    },
    {
      label: t({
        id: 'sales.upload.report.table.store',
        message: `Store Name`,
      }),
      accessor: 'storeName',
      align: 'left',
    },
    {
      label: t({ id: 'sales.upload.report.table.device', message: `Device` }),
      accessor: 'deviceDescription',
      align: 'left',
    },
    {
      label: t({ id: 'sales.upload.report.table.status', message: `Status` }),
      accessor: 'contractStatus',
      type: 'status',
      align: 'center',
      list: contractStatus(),
    },
  ];

  return (
    <FileUploadReport
      title={t({ id: 'sales.upload.title', message: `Upload Sales contracts` })}
      fileFormat='.csv, .xlsx'
      formFieldLabel={t({ id: 'sales.upload.field.label', message: `Upload` })}
      formName='salesUploadForm'
      resultName='contract'
      reportAcessor='contracts'
      formHelpText={t({
        id: 'sales.upload.field.help',
        message: `Click inside input field to add file. Max size 10MB.`,
      })}
      successCols={successCols}
      uploadFile={handleSubmit}
    />
  );
};

export default SalesUpload;
