import { unregister } from 'registerServiceWorker';
import store from 'store';

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import AppRoot from 'components/core/AppRoot';
import ErrorBoundary from 'components/core/ErrorBoundary';

render(
  <ErrorBoundary>
    <Provider store={store}>
      <AppRoot />
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root'),
);

unregister();
