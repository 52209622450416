import {
  clearResidualValuesAction,
  getResidualValuesAction,
  listDevicesAction,
} from 'actions/admin/device';
import { dateFormatter } from 'helpers/formatters';
import isEmpty from 'lodash/isEmpty';
import { reset } from 'redux-form';
import { convertHTMLTableCSV, fileDownload } from 'utils';

import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import DeviceFiltersForm from 'components/DeviceFiltersForm';
import Footer from 'components/Footer';
import Heading from 'components/Heading';
import Loader from 'components/Loader';
import Section from 'components/Section';
import Table from 'components/table/Table';

import './ResidualValuesSearch.styl';

const ResidualValuesSearch = ({
  devices,
  residuals,
  residualCols,
  salesDate,
  getResidualValues,
  listDevices,
  clearResidualValues,
  resetForm,
  loading,
  language,
}) => {
  useEffect(() => {
    listDevices();
    return undefined;
  }, [listDevices]);

  const searchResidualValues = ({ filterValues }) => {
    getResidualValues(filterValues);
  };

  const clickHandlerExportTable = () => {
    const csvTable = convertHTMLTableCSV(
      document.querySelectorAll('table.device-residual-values-table')[0],
    );
    fileDownload(csvTable, `residuals-${salesDate}.csv`, 'text/csv');
  };

  const columns = [
    {
      label: t({
        id: 'table.column.manufacturer.name',
        message: `Manufacturer(s)`,
      }),
      accessor: 'manufacturerName',
      type: 'text',
      filterable: true,
      sortable: true,
      align: 'left',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.categoy.name', message: `Categories` }),
      accessor: 'categoryName',
      type: 'text',
      filterable: true,
      sortable: true,
      align: 'left',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.family.name', message: `Families` }),
      accessor: 'familyName',
      type: 'text',
      filterable: true,
      sortable: true,
      align: 'left',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.device.name', message: `Device(s)` }),
      accessor: 'deviceName',
      type: 'text',
      filterable: true,
      sortable: true,
      align: 'left',
      wrap: false,
    },
  ];

  const columnsData = columns.concat(
    residualCols?.map((monthNum) => {
      return {
        label: t({
          id: 'table.column.n.months',
          message: `${monthNum} months`,
        }),
        accessor: monthNum,
        type: 'currency',
        filterable: true,
        sortable: true,
        align: 'right',
        wrap: false,
      };
    }),
  );

  return (
    <div className='residual-values-search'>
      {loading && <Loader />}

      <Heading size='big'>
        <Trans id='residual.values.search.title'>Residual values search</Trans>
      </Heading>
      <Section>
        <Heading size='medium'>
          <Trans id='residual.values.search.filters.title'>
            Show devices residual devices
          </Trans>
        </Heading>
        <DeviceFiltersForm
          actionButtons={[
            {
              name: 'clear',
              label: t({
                id: 'residual.values.search.filters.clear.label',
                message: `Clear`,
              }),
              handleClick: () => {
                clearResidualValues();
                resetForm('deviceFilters');
              },
            },
            {
              name: 'export',
              label: t({
                id: 'residual.values.search.filters.export.label',
                message: `Export`,
              }),
              handleClick: clickHandlerExportTable,
            },
          ]}
          requireDevice={false}
          devices={devices}
          submitData={searchResidualValues}
        />
      </Section>
      {!isEmpty(residuals) && (
        <Section>
          <Heading size='medium'>
            <Trans id='residual.values.table.title'>{`Sales date ${dateFormatter(
              salesDate,
              language,
              false,
            )}`}</Trans>
          </Heading>
          <Table
            classes='device-residual-values-table'
            columns={columnsData}
            data={residuals}
            filterable={false}
            pageable={false}
          />
        </Section>
      )}
      <Footer classes='main' copyright={false} />
    </div>
  );
};

export default connect(
  (state) => ({
    currency: state.whitelabel.currency,
    salesDate: state.adminDevice.salesDate,
    devices: state.adminDevice.devices,
    residuals: state.adminDevice.residuals,
    residualCols: state.adminDevice.residualCols,
    language: state.whitelabel.language,
    loading: state.ui.loading,
  }),
  {
    getResidualValues: getResidualValuesAction,
    clearResidualValues: clearResidualValuesAction,
    listDevices: listDevicesAction,
    resetForm: reset,
  },
)(ResidualValuesSearch);
