import { saveAttachment } from 'actions/admin/attachments';
import { closeModalAction } from 'actions/ui';
import { format, required } from 'helpers/validators';
import { Field, getFormValues, reduxForm, reset } from 'redux-form';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/Button';
import Heading from 'components/Heading';
import Modal from 'components/Modal';
import RenderField from 'components/RenderField';
import RenderTextAreaField from 'components/RenderTextAreaField';
import TabbedContent from 'components/TabbedContent.js';

import { fileExtensionRegex } from 'constants/forms';

import './AttachmentForm.styl';

const AttachmentForm = ({ handleSubmit, invalid, submitting, pristine }) => {
  const dispatch = useDispatch();
  const html = useSelector(
    (state) => getFormValues('attachment-editor-form')(state)?.html,
  );

  const [preview, setPreview] = useState();

  return (
    <Modal name='create-attachment'>
      <Heading classes='attachment__editor__header' size='big'>
        Attachment template editor
      </Heading>

      <form id='attachment-editor-form' className='attachment__editor__form'>
        <Field
          name='fileName'
          label='FileName.pdf'
          component={RenderField}
          validate={[
            required(),
            format({ with: fileExtensionRegex, msg: 'e.g file name.pdf' }),
          ]}
        />
        <TabbedContent
          content={[
            {
              tab: 'Edit',
              content: (
                <Field
                  name='html'
                  className='attachment__editor__area_text'
                  component={RenderTextAreaField}
                  placeholder={`...`}
                  rows={38}
                  validate={required()}
                />
              ),
              showTitle: false,
            },
            {
              tab: 'Preview',
              content: (
                <iframe
                  title='previewArea'
                  className='attachment__editor__area_preview'
                  srcDoc={preview}
                />
              ),
              onClick: () => {
                // TODO: backend call to render HTML with data
                setPreview(html);
              },
              showTitle: false,
            },
          ]}
        />
        <div className='attachment__editor__area__actions'>
          <Button
            disabled={pristine || invalid || submitting}
            onClick={handleSubmit}
          >
            Save changes
          </Button>
          <Button
            onClick={() => {
              dispatch(reset('attachment-editor-form'));
              dispatch(closeModalAction('create-attachment'));
            }}
          >
            Close
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default reduxForm({
  form: 'attachment-editor-form',
  destroyOnUnmount: true,
  onSubmit: (value, dispatch) => {
    dispatch(saveAttachment(value));
    dispatch(closeModalAction('create-attachment'));
    dispatch(reset('attachment-editor-form'));
  },
})(AttachmentForm);
