import classNames from 'classnames';
import PropTypes from 'prop-types';

import React from 'react';

import Heading from 'components/Heading';
import IconClose from 'components/icons/IconClose';

import './Notification.styl';

export const Notification = ({
  message,
  onClose = () => {},
  onOpen = () => {},
  title,
  type,
}) => {
  React.useEffect(() => {
    onOpen();
  }, []);

  return (
    <div className={classNames('notification', `notification--${type}`)}>
      <IconClose className={'notification__close-button'} onClick={onClose} />

      <div className='notification__body'>
        {title && <Heading size='medium'>{title}</Heading>}

        <p>{message}</p>
      </div>
    </div>
  );
};

Notification.propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.string.isRequired,
};

export default Notification;
