import { resetPasswordAction } from 'actions/user';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Trans } from '@lingui/macro';

import Button from 'components/Button';
import LoginLayout from 'components/LoginLayout';

import { loginPageUrl } from 'constants/routes';

import './PasswordReset.styl';

const PasswordReset = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const resetStatus = useSelector(({ user }) => user.reset_password_status);

  useEffect(() => {
    dispatch(resetPasswordAction(id));
  }, [id, dispatch]);

  if (resetStatus === 'ERROR') {
    return (
      <LoginLayout classes='reset-password'>
        <h1>
          <Trans id='reset.pwd.error.title'>Invalid Token</Trans>
        </h1>

        <p>
          <Trans id='reset.pwd.error.info'>
            There seems to be a problem with the token used to reset the
            password. Please try again.
          </Trans>
        </p>

        <Button
          classes='reset-password-login'
          // TODO make this component reliant only on class functions
          // in order to remove arrow functions and enforce this rule
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => {
            navigate(loginPageUrl);
          }}
          testSelector='reset-password-login'
        >
          <Trans id='reset.pwd.error.submit.button'>Login</Trans>
        </Button>
      </LoginLayout>
    );
  } else {
    return (
      <LoginLayout classes='reset-password'>
        <h1>
          <Trans id='reset.pwd.success.title'>Password reset successful</Trans>
        </h1>

        <p>
          <Trans id='reset.pwd.success.info'>
            A new temporary password was sent to your email. You will be
            requested to change the temporary password upon login.
          </Trans>
        </p>

        <Button
          classes='reset-password-login'
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => {
            navigate(loginPageUrl);
          }}
          testSelector='reset-password-login'
        >
          <Trans id='reset.pwd.success.submit.button'>Login</Trans>
        </Button>
      </LoginLayout>
    );
  }
};

export default PasswordReset;
