import classNames from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import omit from 'lodash/omit';
import orderBy from 'lodash/orderBy';
import { getDurationSeconds } from 'utils';

import React, { Fragment, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';

import { Trans } from '@lingui/macro';

import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Spinner from 'components/Spinner';

import Pagination from './Pagination';
import Row from './Row';
import './Table.styl';

const Table = ({
  id,
  classes,
  columns,
  data,
  defaultDir,
  defaultOrder,
  filterable,
  keyColumn,
  loading,
  multiSelect,
  multiSelectOptions: {
    isSelectableAccessor,
    multiSelectActions = [],
    onMultiSelectChange,
  },
  pageable,
  pagination,
  resetPaginationOnUpdate,
  stickyCol,
  noWordBreak,
  withOverflow,
  testSelector = 'table',
}) => {
  const [dir, setDir] = useState(defaultDir);
  const [filters, setFilters] = useState({});
  const [offset, setOffset] = useState(0);
  const [maxOffset, setMaxOffset] = useState(offset);
  const [multiSelectRows, setMultiSelectRows] = useState([]);
  const [order, setOrder] = useState(defaultOrder || keyColumn);
  const [perPage] = useState(20);
  const [total, setTotal] = useState();
  const paginationRef = useRef();

  useEffect(() => {
    const prevPagination = paginationRef;
    const totalPage = pagination.total ? pagination.total : data.length;
    if (
      isUndefined(pagination.offset) ||
      (pagination.offset === 0 && prevPagination.current?.offset !== 0) ||
      resetPaginationOnUpdate
    ) {
      resetPagination(totalPage);
    } else setTotal(totalPage);
  }, [pagination, resetPaginationOnUpdate, data]);

  useEffect(() => {
    // save previous values of pagination
    paginationRef.current = pagination;
  }, [pagination]);

  const updateFilters = (column, value) => {
    if (!isEmpty(value) || value?.toString()) {
      setFilters({ ...filters, [column]: value });
    } else {
      setFilters(omit(filters, column));
    }
  };

  const filterByRowType = (filtersArray, columns, row) => {
    return !filtersArray.some(([key, value]) => {
      const { type, accessor, condition, list } = columns.find(
        (c) => c.accessor === key,
      );

      const cell = get(row, accessor, null);

      if (cell === null) return true;
      switch (type) {
        case 'count':
          return !cell.length.toString().includes(value);
        case 'status':
          return !list[cell].label.toLowerCase().includes(value.toLowerCase());
        case 'dict':
          return !list[cell].toLowerCase().includes(value.toLowerCase());
        case 'currency':
          return !cell.toString().includes(value);
        case 'date':
          if (condition === 'before') {
            return value < new Date(cell).setHours(0, 0, 0, 0);
          }

          return (
            value.setHours(0, 0, 0, 0) > new Date(cell).setHours(0, 0, 0, 0)
          );
        default:
          return list
            ? !list[row[accessor]]?.label
                .toLowerCase()
                .includes(value.toLowerCase())
            : !cell.toString().toLowerCase().includes(value.toLowerCase());
      }
    });
  };

  const filter = (rows, columns) => {
    const filtersArray = Object.entries(filters);

    if (isEmpty(filtersArray)) return rows;

    const filtered = rows.filter((row) =>
      filterByRowType(filtersArray, columns, row),
    );

    if (filtered.length !== total) resetPagination(filtered.length);

    return filtered;
  };

  const resetPagination = (length) => {
    setMaxOffset(0);
    setTotal(length);
    setOffset(0);
  };

  const sortingIcons = (key, order, dir) => {
    if (order === key) return dir === 'asc' ? '▼' : '▲';

    return '-';
  };

  const setSortProperties = (column) => {
    if (order === column) {
      setDir(dir === 'asc' ? 'desc' : 'asc');
    } else {
      setOrder(column);
      setDir('asc');
    }
  };

  const selectAll = (checked, rows) => {
    let newMultiSelectRows;

    if (isSelectableAccessor) {
      newMultiSelectRows = checked
        ? rows.filter((row) => row[isSelectableAccessor])
        : [];
    } else {
      newMultiSelectRows = checked ? rows : [];
    }

    setMultiSelectRows(newMultiSelectRows);
    onMultiSelectChange && onMultiSelectChange(newMultiSelectRows);
  };

  const toggleMultiSelectRow = (ev, row) => {
    return ev.currentTarget.checked
      ? [...multiSelectRows, row]
      : multiSelectRows.filter((elem) => elem[keyColumn] !== row[keyColumn]);
  };

  const selectRow = (ev, row) => {
    const rows = toggleMultiSelectRow(ev, row);

    setMultiSelectRows(rows);
    onMultiSelectChange && onMultiSelectChange(rows);
  };

  const prevPage = () => offset !== 0 && setOffset(offset - perPage);

  const nextPage = async () => {
    setOffset(offset + perPage);
    await fetchData();
  };

  const fetchData = async () => {
    const nextOffset = offset + perPage;
    setOffset(nextOffset);

    if (nextOffset > maxOffset) {
      setMaxOffset(nextOffset);

      await pagination.getPaginatedData(nextOffset, perPage);
    }
  };

  const orderRows = (rows) => {
    const { list, type } =
      columns.find((column) => column.accessor === order) || {};
    switch (type) {
      case 'status':
        return orderBy(rows, (row) => list[row[order]].label.toLowerCase(), [
          dir,
        ]);
      case 'dict':
        return orderBy(rows, (row) => list[row[order]].toLowerCase(), [dir]);
      case 'duration':
        return orderBy(rows, (row) => getDurationSeconds(row[order]), [dir]);
      default:
        return orderBy(rows, [order], [dir]);
    }
  };

  const processedData = orderRows(filter(data, columns));

  const rows = pageable
    ? processedData.slice(offset, offset + perPage)
    : processedData;

  const currentPage = offset / perPage + 1;
  const totalPages = Math.ceil(total / perPage) || 1;
  const colSpan = multiSelect ? columns.length + 1 : columns.length;

  const renderTable = () => (
    <table
      id={id}
      className={classNames('table', classes, {
        'table--overflowed': withOverflow,
        'table--word-break-normal': noWordBreak,
        'no-data': isEmpty(rows),
      })}
      data-test={testSelector}
    >
      <thead>
        <tr>
          {multiSelect && (
            <th>
              <Checkbox
                id='TableMultiSelect'
                // TODO make this component reliant only on hooks in order
                // to remove arrow functions and enforce this rule
                // eslint-disable-next-line react/jsx-no-bind
                onChange={(event) => {
                  selectAll(event.target.checked, rows);
                }}
              />
            </th>
          )}

          {columns.map(({ accessor, label, sortable }) => (
            <th
              key={accessor}
              className={classNames({
                stickyCol: accessor === stickyCol,
                sortable,
              })}
            >
              {sortable ? (
                // eslint-disable-next-line react/jsx-no-bind
                <a onClick={() => setSortProperties(accessor)}>
                  {`${label} ${sortingIcons(accessor, order, dir)}`}
                </a>
              ) : (
                label
              )}
            </th>
          ))}
        </tr>

        {filterable && (
          <tr>
            {multiSelect && <th />}

            {columns.map(({ accessor, filterable, type }) => (
              <th
                key={`input-${accessor}`}
                className={classNames({
                  stickyCol: accessor === stickyCol,
                })}
              >
                {filterable && type === 'date' && (
                  <DatePicker
                    selected={filters[accessor]}
                    // eslint-disable-next-line react/jsx-no-bind
                    onChange={(value) => updateFilters(accessor, value)}
                  />
                )}

                {filterable && type !== 'date' && (
                  <input
                    type={type}
                    // eslint-disable-next-line react/jsx-no-bind
                    onChange={(ev) => updateFilters(accessor, ev.target.value)}
                  />
                )}
              </th>
            ))}
          </tr>
        )}
      </thead>
      <tbody>
        {!loading &&
          rows &&
          rows.map((row) => (
            <Row
              columns={columns}
              key={row[keyColumn]}
              keyColumn={keyColumn}
              hideCheckbox={
                !isSelectableAccessor ? false : !row[isSelectableAccessor]
              }
              multiSelect={multiSelect}
              multiSelectRows={multiSelectRows}
              onChange={selectRow}
              row={row}
              stickyCol={stickyCol}
              testSelector={testSelector}
            />
          ))}

        {!loading && isEmpty(rows) && (
          <tr key='no-data'>
            <td className='center no-data' colSpan={colSpan}>
              <Trans id='table.empty'>There are no records to display</Trans>
            </td>
          </tr>
        )}

        {loading && (
          <tr key='table-loader'>
            <td className='center no-data' colSpan={colSpan}>
              <Spinner />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );

  return (
    <Fragment>
      {multiSelectActions.length > 0 && (
        <div className='multiSelectActions__container'>
          {multiSelectActions.map(({ classes, disabled, handler, label }) => (
            <Button
              key={label}
              classes={`multiSelectActions__button ${classes}`}
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => handler(multiSelectRows)}
              disabled={disabled || isEmpty(multiSelectRows)}
            >
              {label}
            </Button>
          ))}
        </div>
      )}

      {withOverflow ? (
        <div className='scrolling-table__container'>
          <div className='scrolling-table__scroller'>{renderTable()}</div>
        </div>
      ) : (
        renderTable()
      )}

      {pageable && (
        <Pagination
          id={id ? `${id}-pagination` : undefined}
          from={offset}
          perPage={perPage}
          current={currentPage}
          total={totalPages}
          prevPage={prevPage}
          nextPage={nextPage}
        />
      )}
    </Fragment>
  );
};

Table.defaultProps = {
  data: [],
  defaultDir: 'asc',
  filterable: true,
  keyColumn: 'id',
  multiSelect: false,
  multiSelectOptions: {
    multiSelectActions: [],
    onMultiSelectChange: () => {},
  },
  pageable: true,
  pagination: {
    getPaginatedData: () => {},
  },
  total: 0,
};

export default Table;
