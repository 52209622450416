import { currencyFormatter } from 'helpers/formatters';
import { currencySymbolToISO3 } from 'utils';

import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { t } from '@lingui/macro';

import Heading from 'components/Heading';
import { ImageThumbnail } from 'components/Image';
import LineItem from 'components/LineItem';

import { evaluationGradeTitles, simStatusLabels } from 'constants/recycle';

import './Summary.styl';

const Summary = ({
  currency,
  device,
  language,
  source,
  selectedSIMStatus,
  orderGUID,
  minified = false,
}) => {
  const {
    deviceReference,
    evaluation = {},
    imageURL = '',
    name = t({
      id: 'recycle.summary.default.name',
      message: `Select the device`,
    }),
    promotions,
    promotionID,
  } = device;
  const { grade, deviceValue, swapCost, currencyCode = currency } = evaluation;

  const selectedPromotion = promotions?.find(
    (promotion) => promotion.id === promotionID,
  );

  return (
    <div className='trade-summary product-summary'>
      {minified && (
        <Heading size='medium'>
          {t({ id: 'recycle.outcome.return.device', message: `Return device` })}
        </Heading>
      )}
      <div className='summary-product'>
        {!minified && (
          <div className='product-img'>
            <ImageThumbnail src={imageURL} alt={name} />
          </div>
        )}

        <div className='item-details'>
          {orderGUID ? (
            <Fragment>
              <LineItem
                label={`${t({
                  id: 'recycle.swap.contract',
                  message: `Contract`,
                })}:`}
                value={orderGUID}
              />

              <LineItem
                label={`${t({
                  id: 'recycle.summary.device',
                  message: `Device`,
                })}:`}
                value={name}
              />
            </Fragment>
          ) : (
            <LineItem label={name} classes='title' />
          )}

          {deviceReference && (
            <LineItem
              label={`${t({
                id: 'recycle.device.reference',
                message: `Device Reference`,
              })}:`}
              value={deviceReference}
            />
          )}

          {selectedSIMStatus && (
            <LineItem
              label={`${t({
                id: 'recycle.sim.status',
                message: `SIM Status`,
              })}:`}
              value={simStatusLabels(selectedSIMStatus)}
            />
          )}

          {grade && (
            <LineItem
              label={`${t({
                id: 'recycle.condition.description',
                message: `Condition`,
              })}:`}
              value={evaluationGradeTitles(grade)}
            />
          )}

          {!minified && (deviceValue || deviceValue === 0) && (
            <LineItem
              label={`${t({
                id: 'recycle.device.value',
                message: `Device Value`,
              })}:`}
              value={currencyFormatter(
                deviceValue,
                currencySymbolToISO3(currencyCode),
                language,
              )}
              classes='filled'
            />
          )}

          {!minified && !isNaN(swapCost) && source === 'SWAP' && (
            <LineItem
              label={`${t({
                id: 'recycle.swap.cost',
                message: `Expected Upgrade cost`,
              })}:`}
              value={currencyFormatter(
                swapCost,
                currencySymbolToISO3(currencyCode),
                language,
              )}
              classes='filled'
            />
          )}
          {selectedPromotion?.value > 0 && (
            <LineItem
              showLabel={false}
              value={t({
                id: 'promotion.summary.text',
                message: `Promotional "${
                  selectedPromotion.title
                } of ${currencyFormatter(
                  selectedPromotion.value,
                  currencySymbolToISO3(currencyCode),
                  language,
                )}" added to the device value`,
              })}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export { Summary };

export default connect(
  ({ whitelabel, recycle }) => ({
    currency: whitelabel.currency,
    language: whitelabel.language,
    selectedSIMStatus: recycle.selectedSIMStatus,
  }),
  null,
)(Summary);
