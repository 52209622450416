import { getCurrentCart } from 'utils';

import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Trans } from '@lingui/macro';

import Heading from 'components/Heading';
import ProductExtrasManual from 'components/ProductExtrasManual';
import Cart from 'components/cart/Cart';

import useTrackPage from 'hooks/useTrackPage';

import './ProductExtras.styl';
import ProductExtrasCatalog from './components/ProductExtrasCatalog';

const ProductExtras = () => {
  useTrackPage('ProductExtras');

  const { cartUUID } = useParams();

  const cart = useSelector(({ carts }) => getCurrentCart(carts, cartUUID));
  const currency = useSelector(({ whitelabel }) => whitelabel.currency);
  const language = useSelector(({ whitelabel }) => whitelabel.language);
  const paymentPlanOptions = useSelector(
    ({ catalog }) => catalog?.paymentPlanOptions || [],
  )[0]?.options;

  const canAddExtras = useSelector(
    ({ whitelabel }) => whitelabel.configs.canAddExtras,
  );

  return (
    <Fragment>
      <main className='content-left'>
        <Heading
          size='big'
          classes={`extras-page-title ${
            canAddExtras === 'BOTH' ? 'extras--separator extras' : ''
          }`}
        >
          <Trans id='extras.page.header'>Accessories</Trans>
        </Heading>
        {canAddExtras === 'BOTH' && (
          <Heading size='medium'>
            <Trans id='manual.extras.title'> Add extra manually </Trans>
          </Heading>
        )}
        {canAddExtras !== 'CATALOG' && (
          <ProductExtrasManual
            data-test='product-extras-manual'
            cart={cart}
            current
            classes={canAddExtras === 'BOTH' ? 'extras--separator' : ''}
          />
        )}
        {canAddExtras !== 'MANUAL' && (
          <>
            <Heading size='medium'>
              <Trans> Search for Accessories and Services </Trans>
            </Heading>
            <ProductExtrasCatalog
              data-test='product-extras-catalog'
              cart={cart}
            />
          </>
        )}
      </main>

      <aside className='content-right'>
        <Cart
          cart={cart}
          options={paymentPlanOptions}
          currency={currency}
          language={language}
          updateExtras
          updateOptions
          actionable
        />
      </aside>
    </Fragment>
  );
};

export default ProductExtras;
