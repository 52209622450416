import React, { Fragment } from 'react';

import { Trans } from '@lingui/macro';

import Heading from 'components/Heading';
import List from 'components/deprecated_list/DeprecatedList';

import './PaymentPlanSelect.styl';

const PaymentPlanSelect = ({
  options,
  currency,
  language,
  classes,
  action,
}) => {
  return (
    <div className={`payment-plans-select ${classes}`}>
      <Heading size='medium'>
        <Trans id='payment.plan.select.title'>Payment Plans</Trans>
      </Heading>

      {options.map((plan) => (
        <Fragment key={plan.name}>
          <List
            type='button'
            classes='payment-plans-options'
            items={plan.options}
            currency={currency}
            language={language}
            handleClick={action}
          />
        </Fragment>
      ))}
    </div>
  );
};

PaymentPlanSelect.defaultProps = { options: [] };

export default PaymentPlanSelect;
