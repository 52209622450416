import { openModalAction } from 'actions/ui';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { safeHTMLParser } from 'utils';

import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import Button from 'components/Button';
import Modal from 'components/Modal';
import Slideshow from 'components/Slideshow';

const CriteriaQuestion = ({ data, handleClick = () => {}, name, showHelp }) => {
  const { selected, code, images, question, help } = data;
  const dispatch = useDispatch();

  const openGallery = () =>
    dispatch(openModalAction(`criteria-gallery${code}`));

  return (
    <Fragment>
      <span className='criteria-question__text'>
        <span className='criteria-question__gallery-btn'>
          <Button onClick={openGallery} disabled={isEmpty(images) && !help}>
            ?
          </Button>
        </span>
        {question}
      </span>

      {showHelp && <div className='criteria--help'>{safeHTMLParser(help)}</div>}

      <span
        className='criteria-question__answer-btns'
        data-test='criteria-answers'
      >
        {['yes', 'no'].map((value) => (
          <Button
            key={`${code}-${value}`}
            type='button'
            disabled={selected === value}
            classes={classNames({ selected: selected === value })}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => handleClick(data, value)}
          >
            <input type='hidden' name={`${name}-${code}`} value={value} />
            {value === 'yes' && <Trans id='evaluation.answers.yes'>Yes</Trans>}
            {value === 'no' && <Trans id='evaluation.anwsers.no'>No</Trans>}
          </Button>
        ))}
      </span>

      <Modal name={`criteria-gallery${code}`} title={question}>
        {!isEmpty(images) && (
          <Slideshow
            images={images.map((img) => ({
              ...img,
              caption:
                img.imageUrl &&
                img.imageAnswer &&
                t({
                  id: 'evaluation.gallery.question.title',
                  message: `Press ${img.imageAnswer} if the device is similar to the one in this picture`,
                }),
            }))}
            showCaptions={true}
          />
        )}
        {help && (
          <div className='criteria--help-text'>{safeHTMLParser(help)}</div>
        )}
      </Modal>
    </Fragment>
  );
};

export default CriteriaQuestion;
