import classNames from 'classnames';
import PropTypes from 'prop-types';

import React, { useCallback } from 'react';

import './Toggle.styl';

export const RenderToggle = ({ input, disabled, placeholder, classes }) => {
  const { name, onChange, value } = input;

  const clickHandler = useCallback(
    (event) => {
      onChange(event.currentTarget.checked);
    },
    [onChange],
  );

  return (
    <Toggle
      id={name}
      checked={value}
      classes={classes}
      label={placeholder}
      disabled={disabled}
      onChange={clickHandler}
    />
  );
};

const Toggle = ({
  checked,
  classes,
  disabled = false,
  id,
  label,
  onChange = () => {},
}) => {
  return (
    <label
      className={classNames('toggle', classes, {
        'toggle--disabled': disabled,
      })}
      htmlFor={id}
    >
      <div className='toggle__switch'>
        <input
          id={id}
          checked={checked}
          disabled={disabled}
          className='toggle__input'
          // eslint-disable-next-line react/jsx-no-bind
          onChange={onChange}
          type='checkbox'
        />

        <div className='toggle__slider' />
      </div>

      {label && <div className='toggle__label'>{label}</div>}
    </label>
  );
};

Toggle.propTypes = {
  checked: PropTypes.bool,
  classes: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default Toggle;
