import classNames from 'classnames';

import React from 'react';

import './Checkbox.styl';

export const renderCheckbox = ({ input, disabled, placeholder, classes }) => {
  const { name, onChange, value } = input;
  const clickHandler = (event) => {
    onChange(event.currentTarget.checked);
  };

  return (
    <Checkbox
      id={name}
      checked={value}
      classes={classes}
      label={placeholder}
      disabled={disabled}
      onChange={clickHandler}
    />
  );
};

const Checkbox = ({
  classes,
  checked,
  disabled = false,
  id,
  label,
  onChange,
  testSelector,
}) => {
  return (
    <div
      className={classNames('checkbox', classes, {
        'checkbox--disabled': disabled,
      })}
    >
      <input
        id={id}
        checked={checked}
        disabled={disabled}
        className='checkbox__input'
        onChange={onChange}
        type='checkbox'
      />

      <label
        data-test={testSelector}
        htmlFor={id}
        className='checkbox__square'
      />

      {label && <p className='checkbox__label'>{label}</p>}
    </div>
  );
};

export default Checkbox;
