import { saveSelectedDeviceAction } from 'actions/carts';
import { clearProductOptionsAction } from 'actions/catalog';
import { getCurrentCart } from 'utils';

import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Loader from 'components/Loader';
import ProductExtrasManual from 'components/ProductExtrasManual';
import Cart from 'components/cart/Cart';

import useTrackPage from 'hooks/useTrackPage';

import './ProductCustomization.styl';
import ProductCustomizationCatalog from './components/ProductCustomizationCatalog';

const ProductCustomization = () => {
  useTrackPage('ProductCustomization');

  const dispatch = useDispatch();
  const { cartUUID } = useParams();

  useEffect(() => {
    return () => dispatch(clearProductOptionsAction());
  }, [dispatch]);

  const cart = useSelector(({ carts }) => getCurrentCart(carts, cartUUID));
  const currency = useSelector(({ whitelabel }) => whitelabel.currency);
  const language = useSelector(({ whitelabel }) => whitelabel.language);
  const loading = useSelector(({ ui }) => ui.loading);
  const productOptions = useSelector(({ catalog }) => catalog.productOptions);
  const canCustomize = useSelector(
    ({ whitelabel }) => whitelabel.configs.canCustomize,
  );
  const paymentPlanOptions = useSelector(
    ({ catalog }) => catalog.paymentPlanOptions,
  )[0].options;

  const saveSelectedDevice = (cartUUID, device) =>
    dispatch(saveSelectedDeviceAction(cartUUID, device));

  if (loading) return <Loader />;

  return (
    <Fragment>
      <main className='content-left'>
        {canCustomize === 'CATALOG' ? (
          <ProductCustomizationCatalog
            cartUUID={cart.uuid}
            currency={currency}
            language={language}
            loading={loading}
            productOptions={productOptions}
            saveSelectedDevice={saveSelectedDevice}
          />
        ) : (
          <ProductExtrasManual current isCTO cart={cart} />
        )}
      </main>

      <aside className='content-right'>
        <Cart
          cart={cart}
          options={paymentPlanOptions}
          currency={currency}
          language={language}
          actionable
          updateOptions
        />
      </aside>
    </Fragment>
  );
};

export default ProductCustomization;
