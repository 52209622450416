import React from 'react';

const SvgIconAlertSmall = ({ title, ...props }) => (
  <svg width='24' height='24' {...props}>
    {title ? <title>{title}</title> : null}
    <defs>
      <clipPath id='a'>
        <path d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
    <g clip-path='url(#a)'>
      <path fill='none' d='M0 0h24v24H0z' />
      <g data-name='Group 1242' transform='translate(-16118 -2298)'>
        <path
          data-name='Subtraction 96'
          d='M16130 2318a8 8 0 118-8 8 8 0 01-8 8z'
          fill='#d90000'
        />
        <ellipse
          cx='.728'
          cy='.727'
          rx='.728'
          ry='.727'
          transform='translate(16129.272 2312.908)'
          fill='#f1f1f1'
        />
        <path d='M16129.275 2305.635h1.455v5.818h-1.455z' fill='#f1f1f1' />
      </g>
    </g>
  </svg>
);

export default SvgIconAlertSmall;
