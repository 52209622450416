import classNames from 'classnames';

import React from 'react';

const RenderField = ({
  input,
  label,
  type,
  className,
  inputClasses,
  testSelector,
  disabled,
  animateLabel = true,
  meta: { touched, error, warning, active },
}) => {
  return (
    <div className={className}>
      {animateLabel && (
        <label
          className={classNames('input__floating-label', {
            active: input.value.length > 0 || active,
          })}
        >
          {label}
        </label>
      )}

      <input
        {...input}
        type={type}
        className={inputClasses}
        placeholder={!animateLabel ? label : undefined}
        disabled={disabled}
        data-test={testSelector}
      />
      {(touched && error && <p className='error'>{error}</p>) ||
        (warning && <p className='warning'>{warning}</p>)}
    </div>
  );
};

export default RenderField;
