import { withdrawAction } from 'actions/contract';
import { getSaleAction } from 'actions/sales';
import { openModalAction } from 'actions/ui';

import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { Trans } from '@lingui/macro';

import Button from 'components/Button';
import Modal from 'components/Modal';

const WithdrawContract = ({ cartUUID, getSale, openModal, withdraw }) => {
  const withdrawCart = async () => {
    await withdraw(cartUUID, false);
    await getSale(cartUUID);
  };

  const openWithdrawModal = () => openModal('withdraw-cart');

  return (
    <Fragment>
      <Button
        onClick={openWithdrawModal}
        classes='red'
        testSelector='posale-contract-withdraw-btn'
      >
        <Trans id='pending.actions.withdraw.button'>
          Withdraw credit application
        </Trans>
      </Button>

      <Modal name='withdraw-cart' onConfirm={withdrawCart}>
        <span>
          <Trans id='withdraw.contract.question'>
            Are you sure you want to withdraw this contract?
          </Trans>
        </span>
      </Modal>
    </Fragment>
  );
};

export default connect(null, {
  getSale: getSaleAction,
  openModal: openModalAction,
  withdraw: withdrawAction,
})(WithdrawContract);
