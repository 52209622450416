import {
  addNotificationAction,
  hideLoaderAction,
  showLoaderAction,
} from 'actions/ui';
import { posalePost as post } from 'apis/v2';
import uniqBy from 'lodash/uniqBy';
import { convertArrayToCSV, fileDownload } from 'utils';

import { formatDate } from 'components/RenderDatePicker';

import { CONTRACT_ADJUST_PAGE_QUANTITY } from 'constants/admin';
import {
  defaultContractStatus,
  defaultSalesFilters,
} from 'constants/contracts';
import { errorCodes, notificationTitle } from 'constants/errors';
import { exportJSONError } from 'constants/notifications';
import { REPORTS_STATUS_ALL, evaluationGradeTitles } from 'constants/recycle';
import { cmrStatus, contractStatus } from 'constants/status';

export const GET_REPORTS = 'GET_REPORTS';
export function getReportsAction(
  financingType,
  contractStatus = [...defaultContractStatus],
  page = 0,
) {
  return async function (dispatch, getState) {
    dispatch(showLoaderAction());

    const response = await post(GET_REPORTS, {
      financingType,
      contractStatus,
      timezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
      page,
      quantity: CONTRACT_ADJUST_PAGE_QUANTITY,
    });

    const reports =
      page > 0
        ? uniqBy(
            [...getState().reports.list, ...response.data.data.reports],
            'order_uuid',
          )
        : response.data.data.reports;

    dispatch({
      type: GET_REPORTS,
      data: { totalCount: response.data.data.totalCount, list: reports },
    });

    dispatch(hideLoaderAction());
  };
}

export const FILTER_REPORTS = 'FILTER_REPORTS';
export function filterReportsAction(filters = { ...defaultSalesFilters }) {
  return async function (dispatch) {
    dispatch(showLoaderAction());

    if (filters.dateRange) {
      let [startDate, endDate] = filters.dateRange.split(' - ');
      filters.startDate = startDate;
      filters.endDate = endDate;
    }

    const response = await post(GET_REPORTS, {
      ...filters,
      timezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
      page: 0,
      quantity: 999999,
    });

    dispatch({ type: FILTER_REPORTS, data: response.data.data.reports });

    dispatch(hideLoaderAction());

    return response.data.data.reports;
  };
}

export const EXPORT_REPORTS = 'EXPORT_REPORTS';
export function exportReportsAction(filters, columns = []) {
  return async function (dispatch) {
    let data = await dispatch(filterReportsAction(filters));

    if (data.length > 0) {
      data = data.map((elem) => ({
        ...elem,
        status: contractStatus()[elem.status].status,
      }));
      fileDownload(
        convertArrayToCSV(data, columns),
        `contracts.csv`,
        'text/csv',
      );
      dispatch({ type: EXPORT_REPORTS });
    } else {
      dispatch(
        addNotificationAction({
          title: notificationTitle().WARNING,
          message: errorCodes().NO_CONTRACTS_IN_PERIOD,
          type: 'warning',
        }),
      );
    }
  };
}

export const GET_SETTLEMENTS = 'GET_SETTLEMENTS';
export function getSettlementsAction(startDate, endDate, offset, size) {
  return async function (dispatch, getState) {
    cleanSettlementsAction();

    const response = await post(GET_SETTLEMENTS, {
      startDate,
      endDate,
      offset,
      size,
    });

    const payouts = offset
      ? uniqBy(
          [
            ...getState().reports.settlements.payouts,
            ...response.data.data.payouts,
          ],
          'payment_reference',
        )
      : response.data.data.payouts;

    dispatch({
      type: GET_SETTLEMENTS,
      data: {
        summary: response.data.data.summary,
        payouts,
        pagination: response.data.data.pagination,
      },
    });
  };
}

export const CLEAN_SETTLEMENTS = 'CLEAN_SETTLEMENTS';
export function cleanSettlementsAction() {
  return async function (dispatch) {
    dispatch({ type: CLEAN_SETTLEMENTS });
  };
}

export const EXPORT = 'EXPORT';
export function exportEntityAction(entity, format, filters) {
  return async function (dispatch) {
    dispatch(showLoaderAction());

    const response = await post(
      EXPORT,
      {
        entity,
        format,
        filters,
      },
      { responseType: 'arraybuffer' },
    );

    try {
      if (
        [
          'application/pdf;charset=UTF-8',
          'text/csv;charset=UTF-8',
          'text/xlsx;charset=UTF-8',
        ].includes(response.headers['content-type'])
      ) {
        fileDownload(
          response.data,
          `${entity}.${format}`.toLowerCase(),
          response.headers['content-type'],
        );
      } else {
        JSON.parse(Buffer.from(response.data)).errors.map(
          ({ code, values, message }) =>
            dispatch(
              addNotificationAction(exportJSONError(code, values, message)),
            ),
        );
      }
      dispatch({ type: EXPORT });
    } catch (exception) {
      if (entity === 'CRM_OFFERS') {
        dispatch(
          addNotificationAction({
            title: notificationTitle().WARNING,
            message: errorCodes().NO_OFFERS_TO_EXPORT,
            type: 'warning',
          }),
        );
      } else {
        dispatch(
          addNotificationAction({
            title: notificationTitle().WARNING,
            message: errorCodes().NO_CONTRACTS_IN_PERIOD,
            type: 'warning',
          }),
        );
      }
    }

    dispatch(hideLoaderAction());
  };
}

export const LIST_RECYCLE_ORDERS = 'LIST_RECYCLE_ORDERS';
export function listRecycleOrdersAction({
  startDate,
  endDate,
  orderStatus,
  orderID,
  deviceReference,
}) {
  return async function (dispatch) {
    dispatch(showLoaderAction());

    const response = await post(LIST_RECYCLE_ORDERS, {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      orderStatus: orderStatus !== REPORTS_STATUS_ALL ? orderStatus : undefined,
      orderID,
      deviceReference,
    });

    if (response.data.status !== 'ERROR') {
      dispatch({
        type: LIST_RECYCLE_ORDERS,
        data: {
          summary: response.data.data.summaryList.map((summary) => ({
            ...summary,
            status: cmrStatus()[summary.status]?.label || summary.status,
          })),
          devices: response.data.data.deviceList.map((device) => ({
            ...device,
            keyID: `${device.orderID}-${device.deviceReference}`,
            status: cmrStatus()[device.status]?.label || device.status,
            storeGrade: evaluationGradeTitles(device.storeGrade),
            collectorGrade: evaluationGradeTitles(device.collectorGrade),
          })),
        },
      });
    }

    dispatch(hideLoaderAction());
  };
}

export const GET_RECYCLE_POLICE_REPORT = 'GET_RECYCLE_POLICE_REPORT';
export function getRecyclePoliceReportAction(
  { startDate, endDate, policeStatus },
  policeReportColumns,
) {
  return async function (dispatch) {
    dispatch(showLoaderAction());

    const response = await post(GET_RECYCLE_POLICE_REPORT, {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      orderStatus:
        policeStatus !== REPORTS_STATUS_ALL ? policeStatus : undefined,
    });

    dispatch(hideLoaderAction());

    if (response.data.status !== 'ERROR') {
      const rows = response.data.data.entries.reduce(
        (acc, entry) => [
          ...acc,
          ...entry.devices.map((device) => ({
            storeName: entry.storeName,
            orderID: entry.orderID,
            customerName: entry.customerName,
            address: entry.address,
            city: entry.city,
            idNumber: entry.idNumber,
            province: entry.province,
            dateTime: entry.dateTime,
            deviceName: device.deviceName,
            deviceReference: device.deviceReference,
            value: device.value,
          })),
        ],
        [],
      );

      fileDownload(
        convertArrayToCSV(rows, policeReportColumns),
        `recycle_police_reports.csv`,
        'text/csv',
      );
    }
  };
}
