import { useSelector } from 'react-redux';

import Unauthorized from 'components/core/Unauthorized';

export const usePermission = (allowedRoles) => {
  const role = useSelector(({ user }) => user?.role);
  const renderUnauthorized = () => {
    return <Unauthorized />;
  };

  const hasAuthorization = allowedRoles.includes(role);

  return { hasAuthorization, renderUnauthorized };
};

export default usePermission;
