import { currencyFormatter } from 'helpers/formatters';
import { getCurrentCart } from 'utils';

import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { t } from '@lingui/macro';

import './OutcomeIndicator.styl';

const OutcomeIndicator = () => {
  const { cartUUID } = useParams();
  const cart = useSelector(({ carts }) => getCurrentCart(carts, cartUUID));
  const currency = useSelector(({ whitelabel }) => whitelabel.currency);
  const language = useSelector(({ whitelabel }) => whitelabel.language);

  return (
    <>
      {cart?.tradecartID && (
        <div className='recycle_indicator'>
          {t({
            id: 'recycle.outcome.indicator',
            message: `${currencyFormatter(
              cart.totalRecycleAmount,
              currency,
              language,
            )} trade in for ${cart.recycledDeviceNames.join(
              ', ',
            )} added to the cart`,
          })}
        </div>
      )}
    </>
  );
};

export default OutcomeIndicator;
