import {
  ADD_DEVICE,
  CLEAR_COLLECTION_TIMES,
  CLEAR_DIAGNOSTIC,
  CLEAR_EVALUATION,
  CLEAR_RECYCLE,
  CLEAR_SELECTIONS,
  CREATE_MULTI_TRADECART,
  CREATE_TRADECART,
  EVALUATE_DEVICE,
  GET_COLLECTION_TIMES,
  GET_CRITERIA,
  GET_ONLINE_TRADEIN_QUOTE,
  GET_RECYCLE_RECEIPT,
  GET_TRADECART_STATUS,
  REMOVE_DEVICE,
  REVALIDATE_DEVICE_REFERENCE,
  SAVE_DEVICE_REFERENCE,
  SAVE_EVALUATION_ANSWER,
  SEARCH_DEVICES,
  SELECT_DEVICE_PROMOTION,
  SELECT_PROMOTION,
  SELECT_RECYCLE_DEVICE,
  SELECT_RECYCLE_NETWORK_STATUS,
  SELECT_RECYCLE_OUTCOME,
  SET_DEVICE_REFERENCE,
  SET_DEVICE_SIM_STATUS,
  SET_DIAGNOSTIC,
  SET_RECYCLE_TYPE,
  SET_SEARCH_DEVICE_REFERENCE,
  VALIDATE_DEVICE_REFERENCE,
} from 'actions/recycle';

import initialState from 'reducers/initialState';

export default function (state = initialState.recycle, action) {
  switch (action.type) {
    case CLEAR_RECYCLE:
      return { ...initialState.recycle };
    case SET_RECYCLE_TYPE:
      return {
        ...initialState.recycle,
        type: action.recycleType,
        orderUUID: action.orderUUID,
        funder: action.funder,
      };
    case SAVE_DEVICE_REFERENCE:
      return {
        ...state,
        selectedDevice: {
          ...state.selectedDevice,
          deviceReference: action.deviceReference,
          referenceType: action.referenceType,
        },
        deviceReference: action.deviceReference,
        referenceType: action.referenceType,
      };
    case VALIDATE_DEVICE_REFERENCE:
      const { type, ...rest } = action;

      return {
        ...state,
        ...rest,
        devices: action.devices.map((device) => {
          return {
            ...device,
            deviceReference: action.deviceReference,
          };
        }),
        selectedDevice: {
          ...initialState.recycle.selectedDevice,
          searchText: action.searchText,
        },
      };
    case SEARCH_DEVICES:
      return {
        ...state,
        referenceType: action.referenceType,
        deviceReference: action.deviceReference,
        devices: action.devices.map((device) => {
          return {
            ...device,
            deviceReference: action.deviceReference,
            referenceType: action.referenceType,
            searchText: action.searchText,
            checkmendOk: action.checkmendOk,
            fmi: action.fmi,
            useDiagnosticsMinval: action.useDiagnosticsMinval,
          };
        }),
      };
    case REVALIDATE_DEVICE_REFERENCE:
      return {
        ...state,
        selectedDevices: state.selectedDevices.map((device) =>
          device.id === action.id
            ? {
                ...device,
                checkmendOk: action.checkmendOk,
                fmi: action.fmi,
              }
            : { ...device },
        ),
      };
    case CLEAR_SELECTIONS:
      return {
        ...state,
        selectedSIMStatus: undefined,
        selectedOutcome: undefined,
      };
    case SELECT_RECYCLE_DEVICE:
      return {
        ...state,
        selectedDevice: {
          ...action.selectedDevice,
          deviceReference: state.deviceReference,
          evaluation: {},
          questionsTree: {
            criteriaAnswers: [],
            additionAnswers: [],
            deductionAnswers: [],
          },
          diagnostic: {},
        },
        selectedSIMStatus: undefined,
        selectedOutcome: undefined,
        searchText: action.selectedDevice.searchText || state.searchText,
      };
    case GET_CRITERIA:
      return {
        ...state,
        selectedDevice: {
          ...state.selectedDevice,
          questionsTree: { ...action.questionsTree },
        },
      };
    case SELECT_RECYCLE_NETWORK_STATUS:
      return {
        ...state,
        selectedSIMStatus: action.selectedSIMStatus,
        selectedDevice: {
          ...state.selectedDevice,
          evaluation: {},
        },
      };
    case CLEAR_EVALUATION:
      return {
        ...state,
        selectedDevice: {
          ...state.selectedDevice,
          evaluation: {},
        },
        selectedDevices: state.selectedDevices.map((device) =>
          device.id === action.id
            ? {
                ...device,
                evaluation: {},
              }
            : { ...device },
        ),
      };
    case SAVE_EVALUATION_ANSWER:
      return {
        ...state,
        selectedDevice: {
          ...state.selectedDevice,
          questionsTree: action.questionsTree,
        },
        selectedDevices: state.selectedDevices.map((device) =>
          device.id === action.id
            ? {
                ...device,
                questionsTree: action.questionsTree,
              }
            : { ...device },
        ),
      };
    case EVALUATE_DEVICE:
      return {
        ...state,
        selectedDevice: {
          ...state.selectedDevice,
          evaluation: action.evaluation,
          [action.answersType]: action.answers,
        },
        selectedDevices: state.selectedDevices.map((device) =>
          device.id === action.id
            ? {
                ...device,
                evaluation: action.evaluation,
              }
            : { ...device },
        ),
      };
    case SELECT_PROMOTION:
      return {
        ...state,
        selectedDevice: {
          ...state.selectedDevice,
          promotionID: action.promotionID,
        },
      };
    case SELECT_DEVICE_PROMOTION:
      return {
        ...state,
        selectedDevices: state.selectedDevices.map((selectedDevice) =>
          selectedDevice.id === action.deviceID
            ? {
                ...selectedDevice,
                promotionID: action.promotionID,
              }
            : selectedDevice,
        ),
      };
    case SELECT_RECYCLE_OUTCOME:
      return {
        ...state,
        selectedOutcome: action.selectedOutcome,
      };
    case GET_RECYCLE_RECEIPT:
      return {
        ...state,
        receipt: action.receiptData,
      };
    case CREATE_TRADECART:
      return {
        ...state,
        tradecart: action.tradecart,
        tradeCartStatus: {},
        courierURL: action.courierURL,
      };
    case CREATE_MULTI_TRADECART:
      return {
        ...state,
        tradecart: action.tradecart,
        tradeCartStatus: {},
        courierURL: action.courierURL,
      };
    case GET_TRADECART_STATUS:
      return { ...state, tradecartStatus: action.tradecartStatus };
    case SET_DIAGNOSTIC:
      return {
        ...state,
        selectedDevice: {
          ...state.selectedDevice,
          diagnostic: action.diagnostic,
        },
        selectedDevices: state.selectedDevices.map((device) =>
          device.id === action.selectedDevice.id
            ? {
                ...device,
                diagnostic: action.diagnostic,
              }
            : { ...device },
        ),
      };
    case CLEAR_DIAGNOSTIC:
      return {
        ...state,
        selectedDevice: {
          ...state.selectedDevice,
          diagnostic: {},
        },
      };
    case GET_ONLINE_TRADEIN_QUOTE:
      return {
        ...state,
        onlineQuote: action.data,
        deviceReference: action.data.devices[0].deviceReference,
        selectedDevice: {
          ...action.data.devices[0],
        },
        useDiagnosticsMinval: action.data.useDiagnosticsMinval,
      };
    case ADD_DEVICE:
      return {
        ...state,
        selectedDevices: [
          ...state.selectedDevices,
          {
            ...action.device,
            id:
              state.selectedDevices.length === 0
                ? 1
                : Math.max(
                    ...state.selectedDevices?.map(
                      (selectDevice) => selectDevice?.id,
                    ),
                  ) + 1,
          },
        ],
        devices:
          state.deviceReference && state?.devices?.length === 1
            ? []
            : state?.devices,
      };
    case REMOVE_DEVICE:
      return {
        ...state,
        selectedDevices: state?.selectedDevices.filter(
          (selectedDevice) => selectedDevice.id !== action.id,
        ),
      };
    case SET_DEVICE_REFERENCE:
      return {
        ...state,
        selectedDevices: state.selectedDevices.map((selectedDevice) =>
          selectedDevice.id === action.id
            ? {
                ...selectedDevice,
                deviceReference: action.deviceReference,
                referenceType: action.referenceType,
              }
            : selectedDevice,
        ),
      };
    case SET_DEVICE_SIM_STATUS:
      return {
        ...state,
        selectedDevices: state.selectedDevices.map((selectedDevice) =>
          selectedDevice.id === action.id
            ? { ...selectedDevice, networkLockStatus: action.networkLockStatus }
            : selectedDevice,
        ),
      };
    case SET_SEARCH_DEVICE_REFERENCE:
      return {
        ...state,
        deviceReference: action.deviceReference,
        referenceType: action.referenceType,
      };
    case GET_COLLECTION_TIMES:
      return {
        ...state,
        collectionDates: action.payload.collectionDates,
        collectionTimeSlots: action.payload.timeSlots,
      };
    case CLEAR_COLLECTION_TIMES:
      return {
        ...state,
        collectionDates: [],
        collectionTimeSlots: {},
      };
    default:
      return state;
  }
}
