import { forgotPasswordAction } from 'actions/user';
import trackPage from 'enhancers/trackPage';
import { Field, reduxForm } from 'redux-form';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';

import Button from 'components/Button';
import LoginLayout from 'components/LoginLayout';
import RenderField from 'components/RenderField';

import { errorCodes } from 'constants/errors';
import { loginPageUrl } from 'constants/routes';
import { userStatus } from 'constants/user';

class PasswordForgot extends Component {
  state = { login: '', isValid: false };

  handleChange = (login, isValid) => this.setState({ login, isValid });

  handleSubmit = (evt) => {
    const { status } = this.props;
    evt.preventDefault();

    if (status === userStatus.blocked) {
      return errorCodes().US_BLOCKED_ACCOUNT;
    }
    this.props.forgotPassword(this.state.login);
  };

  render() {
    const { handleSubmit, forgotPassword, invalid, submitting } = this.props;

    return (
      <LoginLayout>
        <div className='auth-title'>
          <h1>
            <Trans id='forgot.pwd.title'>Forgot password</Trans>
          </h1>

          <p>
            <Trans id='forgot.pwd.info'>
              Enter your email / username below, to receive reset password
              instructions
            </Trans>
          </p>
        </div>

        <form className={'auth-form'} id={'forgot-pass-form'}>
          <Field
            name='login'
            type='text'
            className='input-container'
            component={RenderField}
            label={t({
              id: 'forgot.pwd.login.placeholder',
              message: `Email Address / Username`,
            })}
            testSelector='login-username'
          />

          <Button
            type='submit'
            loading={submitting}
            disabled={invalid || submitting}
            onClick={handleSubmit(forgotPassword)}
            testSelector='recover-password-button'
          >
            <Trans id='forgot.pwd.submit.button'>Recover Password</Trans>
          </Button>
        </form>

        <Link className='login-link' to={loginPageUrl}>
          <Trans id='forgot.pwd.navigate.login'>Back to login page</Trans>
        </Link>
      </LoginLayout>
    );
  }
}

export default reduxForm({
  form: 'forgot-pass-form',
  enableReinitialize: true,
})(
  connect(null, { forgotPassword: forgotPasswordAction })(
    trackPage(PasswordForgot, 'PasswordForgot'),
  ),
);
