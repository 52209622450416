import { saveSocketStatus } from 'actions/dashboard';
import completeIcon from 'assets/images/Ico-complete.svg';
import inProgressIcon from 'assets/images/Ico-inprogress.svg';
import pendingIcon from 'assets/images/Ico-pending.svg';
import alertIcon from 'assets/images/alert-red.svg';
import declinedIcon from 'assets/images/declined.svg';
import { dateFormatter } from 'helpers/formatters';

import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { t } from '@lingui/macro';

import WebSocket from 'components/WebSocket';

import usePolling from 'hooks/usePolling';

const statusIconMap = {
  NOT_STARTED: pendingIcon,
  IN_PROGRESS: inProgressIcon,
  COMPLETE: completeIcon,
  REFUSED: declinedIcon,
  ERROR: alertIcon,
};

const FinancingStatus = ({ cartGUID, contract, language }) => {
  const dispatch = useDispatch();
  const sessionToken = useSelector(({ user }) => user.session_token);
  const ws = useRef(null);

  const [events, setEvents] = useState(contract?.funderData?.events || []);

  const { stopPolling } = usePolling({
    callback: async () => ws.current.sendMessage('OK'),
    interval: 30000,
    hasDispatch: false,
  });

  const handleData = async (message) => {
    try {
      const data = JSON.parse(message);
      setEvents(data.funderData.events);
      await ws.current.sendMessage('OK');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Malformed message received');
    }
  };

  const handleOpen = async () => {
    await dispatch(saveSocketStatus('Connected'));
    ws.current.sendMessage('FUNDER_DATA');
  };

  const handleClose = async () => {
    await stopPolling();
    await dispatch(saveSocketStatus('Disconnected'));
  };

  const url = `${process.env.REACT_APP_STATS_URL}/${sessionToken}/${new Date()
    .toISOString()
    .substring(0, 10)}`;

  return (
    <div id='financing-status'>
      <table className='states__list'>
        <thead>
          <tr>
            <th>
              {t({
                id: 'contract.details.financing.status.table.staus',
                message: 'Status',
              })}
            </th>
            <th>
              {t({
                id: 'contract.details.financing.status.table.event',
                message: 'Event',
              })}
            </th>
            <th>
              {t({
                id: 'contract.details.financing.status.table.start',
                message: 'Start',
              })}
            </th>
            <th>
              {t({
                id: 'contract.details.financing.status.table.completion',
                message: 'Completion',
              })}
            </th>
          </tr>
        </thead>
        <tbody>
          {events.map((event) => (
            <tr key={event.code}>
              <td>
                <img
                  src={statusIconMap[event.completionStatus]}
                  alt={event.completionStatus}
                />
              </td>
              <td>
                <span>
                  {t({ id: event.translationID, message: event.code })}
                </span>
              </td>
              <td>
                <span>{dateFormatter(event.startDateTime, language)}</span>
              </td>
              <td>
                <span>{dateFormatter(event.completionDateTime, language)}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {sessionToken && cartGUID && (
        <WebSocket
          url={`${url}?cartGUID=${cartGUID}`}
          onMessage={handleData}
          onOpen={handleOpen}
          onClose={handleClose}
          reconnect={true}
          debug={true}
          ref={ws}
        />
      )}
    </div>
  );
};

export default FinancingStatus;
