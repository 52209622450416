export const SAVE_SOCKET_STATUS = 'SAVE_SOCKET_STATUS';
export function saveSocketStatus(status) {
  return async function (dispatch) {
    dispatch({ type: SAVE_SOCKET_STATUS, status });
  };
}

export const SAVE_SOCKET_MESSAGE = 'SAVE_SOCKET_MESSAGE';
export function saveSocketMessage(message) {
  return async function (dispatch) {
    let data;

    if (
      message.data === 'DashboardData' ||
      message.messageName === 'DashboardData'
    ) {
      data = {
        sales: message.sales,
        onlinePendings: message.onlinePendings,
        instorePendings: message.instorePendings,
        pendingCreditChecks: message.pendingCreditChecks,
        withdrawn: message.withdrawn,
        refusals: message.refusals,
        cancels: message.cancels,
        dailySales: message.dailySales,
        monthlySales: message.monthlySales,
        dailyTradeIns: message.dailyTradeIns,
        monthlyTradeIns: message.monthlyTradeIns,
        wls: message.wls,
        creditData: message.creditData,
        counters: message.dashboardCounter,
        storeCounters: message.storeCounters,
        apiCalls: message.apiCounters,
        apiErrors: message.apiErrors,
        userErrors: message.feErrors,
        tradeIns: message.tradeIns,
        swaps: message.swaps,
      };
    } else if (
      message.data === 'ActiveUsers' ||
      message.messageName === 'ActiveUsers'
    ) {
      data = { activeUsers: message.users };
    }

    dispatch({ type: SAVE_SOCKET_MESSAGE, data });
  };
}

export const SAVE_SOCKET_CONFIG = 'SAVE_SOCKET_CONFIG';
export function saveSocketConfig(uid, date) {
  return async function (dispatch) {
    dispatch({ type: SAVE_SOCKET_CONFIG, uid, date });
  };
}

export const CLEAR_DASHBOARD_DATA = 'CLEAR_DASHBOARD_DATA';
export function clearDashboardData() {
  return async function (dispatch) {
    dispatch({ type: CLEAR_DASHBOARD_DATA });
  };
}
