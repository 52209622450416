import isEqual from 'lodash/isEqual';
import maxBy from 'lodash/maxBy';
import { secondsToMillis } from 'utils';

import { LOADER_TYPE_DEFAULT } from 'constants/ui';

export const SHOW_LOADER = 'SHOW_LOADER';
export function showLoaderAction(loaderType = LOADER_TYPE_DEFAULT) {
  return async function (dispatch, getState) {
    if (getState().ui.loading === true) return;

    dispatch({ type: SHOW_LOADER, loaderType });
  };
}

export const HIDE_LOADER = 'HIDE_LOADER';
export function hideLoaderAction() {
  return async function (dispatch, getState) {
    if (getState().ui.loading === false) return;

    dispatch({ type: HIDE_LOADER });
  };
}

export const OPEN_MODAL = 'OPEN_MODAL';
export function openModalAction(modal) {
  return function (dispatch) {
    dispatch({ type: OPEN_MODAL, modal });
  };
}

export const CLOSE_MODAL = 'CLOSE_MODAL';
export function closeModalAction() {
  return function (dispatch) {
    dispatch({ type: CLOSE_MODAL });
  };
}

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export function addNotificationAction({ message, type, title, values }) {
  return async function (dispatch, getState) {
    const {
      ui: { notifications },
      whitelabel: {
        configs: { warningDisplayTime },
      },
    } = getState();

    const needsNewNotification = () => {
      const curNotification = notifications.find(
        (notification) =>
          notification.message === message &&
          isEqual(notification.values, values),
      );
      return typeof curNotification === 'undefined';
    };

    if (needsNewNotification()) {
      const id =
        notifications.length === 0 ? 0 : maxBy(notifications, 'id').id + 1;

      let timer;

      try {
        dispatch({
          type: ADD_NOTIFICATION,
          notification: { id, message, values, type, title, sent: false },
        });

        if (warningDisplayTime > 0) {
          timer = setTimeout(
            () => dispatch(removeNotificationAction(id)),
            secondsToMillis(warningDisplayTime),
          );
        }
      } catch (err) {
        timer && clearTimeout(timer);
      }
    }
  };
}

export const MARK_NOTIFICATION_AS_SENT = 'MARK_NOTIFICATION_AS_SENT';
export function markNotificationAsSentAction(notificationId) {
  return function (dispatch) {
    dispatch({ type: MARK_NOTIFICATION_AS_SENT, notificationId });
  };
}

export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export function removeNotificationAction(notificationId) {
  return async function (dispatch) {
    dispatch({ type: REMOVE_NOTIFICATION, notificationId });
  };
}

export const SAVE_PAGE_NAME = 'SAVE_PAGE_NAME';
export function savePageNameAction(pageName) {
  return function (dispatch) {
    dispatch({ type: SAVE_PAGE_NAME, pageName });
  };
}

export const SAVE_PREVIOUS_LOCATION = 'SAVE_PREVIOUS_LOCATION';
export function savePreviousLocationAction(location) {
  return function (dispatch) {
    dispatch({ type: SAVE_PREVIOUS_LOCATION, location });
  };
}
