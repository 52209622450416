import * as React from 'react';

function SvgIconCaret({ title, titleId, ...props }) {
  return (
    <svg
      viewBox='0 0 76 76'
      width='1em'
      height='1em'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <clipPath id='icon-caret_svg__a'>
          <path d='M0 0h76v76H0z' />
        </clipPath>
      </defs>
      <g clipPath='url(#icon-caret_svg__a)'>
        <path
          fill='currentColor'
          d='M31.902 38l20.1 20.1-3.9 3.9-24-24 24-24 3.9 3.9z'
        />
      </g>
    </svg>
  );
}

export default SvgIconCaret;
