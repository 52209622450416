import isEmpty from 'lodash/isEmpty';

import React, { Component, Fragment } from 'react';

import Dropdown from 'components/Dropdown';
import Loader from 'components/Loader';
import List from 'components/deprecated_list/DeprecatedList';

import { customizationLabels } from 'constants/customizations';

class ProductCustomizationCatalog extends Component {
  selectCTO = (deviceCode) => {
    const { cartUUID, saveSelectedDevice } = this.props;
    saveSelectedDevice(cartUUID, { deviceCode });
  };

  render() {
    const { currency, language, loading, productOptions } = this.props;

    if (loading) return <Loader />;

    return (
      <Fragment>
        {productOptions.map(
          (variant) =>
            !isEmpty(variant.options) && (
              <Fragment key={variant.name}>
                <h2 className={`list-title ${variant.name.toLowerCase()}`}>
                  {customizationLabels(variant.name)}
                </h2>

                {variant.name !== 'KEYBOARD' ? (
                  <List
                    type={variant.name === 'COLOR' ? 'image' : 'circle'}
                    items={variant.options}
                    currency={currency}
                    language={language}
                    classes={variant.name.toLowerCase()}
                    handleClick={this.selectCTO}
                    highlight
                    showId
                  />
                ) : (
                  <Dropdown
                    name='deviceCode'
                    placeholder={customizationLabels('Keyboard')}
                    options={variant.options.reduce((acc, cur) => {
                      if (cur.enabled === true)
                        acc = [...acc, { value: cur.id, label: cur.name }];

                      return acc;
                    }, [])}
                    variant='underline'
                    // TODO: these parameters are a bit hacky. Improve Dropdown to allow for render without redux-form
                    input={{
                      onChange: this.selectCTO,
                      onFocus: () => {},
                      onBlur: () => {},
                      value: variant.options.find((option) => option.selected)
                        .id,
                    }}
                    meta={{
                      active: false,
                      error: '',
                      touched: false,
                    }}
                  />
                )}
              </Fragment>
            ),
        )}
      </Fragment>
    );
  }
}

ProductCustomizationCatalog.displayName = 'ProductCustomization';

export default ProductCustomizationCatalog;
