import { openModalAction } from 'actions/ui';
import classNames from 'classnames';
import {
  currencyFormatter,
  dateFormatter,
  dateTimeFormatter,
  stringWithCommasParser,
} from 'helpers/formatters';
import get from 'lodash/get';
import { capitalize } from 'utils';
import { safeHTMLParser } from 'utils';

import React, { Fragment } from 'react';
import Barcode from 'react-barcode';
import { useDispatch } from 'react-redux';

import Button from 'components/Button';
import Modal from 'components/Modal';
import IconAlert from 'components/icons/IconAlert';
import IconApproved from 'components/icons/IconApproved';

// TODO: maybe convert this into a <Cell /> component that deals itself with data conversion and/or format

export const CellFormatter = (
  data,
  { type, accessor, list = {}, format, formatToday },
  { currency, language },
) => {
  const dispatch = useDispatch();

  const toggleImageModal = (name) => {
    dispatch(openModalAction(name));
  };

  switch (type) {
    case 'text':
      return list[data[accessor]]?.label
        ? capitalize(list[data[accessor]]?.label)
        : get(data, accessor);
    case 'currency':
      if (data[accessor] === null) return '-';
      return currencyFormatter(data[accessor], currency, language);
    case 'date':
      return dateFormatter(data[accessor], language, formatToday);
    case 'dateTime':
      return dateTimeFormatter(data[accessor], language);
    case 'boolean':
      return data[accessor] ? (
        <span className='boolean-true' />
      ) : (
        <span className='boolean-false' />
      );
    case 'status':
      return (
        <span className={`status-${list[data[accessor]]?.status || ''}`}>
          {capitalize(list[data[accessor]]?.label || '')}
        </span>
      );
    case 'dict':
      return (
        <span className={`dict-${data[accessor] || ''}`}>
          {capitalize(list[data[accessor]] || '')}
        </span>
      );
    case 'duration':
      if (!data[accessor]) return;
      return (
        <span className={`duration-${data[accessor] || ''}`}>
          {data[accessor]}
        </span>
      );
    case 'percentage':
      return `${data[accessor]}%`;
    case 'encodedList':
      return (
        <ul>
          {data[accessor].map((elem, index) => (
            <li key={index}>{stringWithCommasParser(elem)}</li>
          ))}
        </ul>
      );
    case 'inlineDeviceReferenceList':
      return (
        <ul>
          {data[accessor].map(
            (s, idx) =>
              s.deviceReference && (
                <li key={`${s.deviceReference}-${idx}`}>{s.deviceReference}</li>
              ),
          )}
        </ul>
      );
    case 'count':
      return data[accessor] ? data[accessor].length : '0';
    case 'barcode':
      return (
        <Barcode
          height={25}
          width={1}
          value={data[accessor]}
          options={{ format: format, textMargin: 0 }}
        />
      );
    case 'image':
      return data[accessor] ? (
        <Fragment>
          <img
            className='expanded'
            src={data[accessor]}
            alt=''
            // TODO make this component reliant only on hooks in order
            // to remove arrow functions and enforce this rule
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => toggleImageModal(data[accessor])}
          />
          <Modal name={data[accessor]}>
            <img src={data[accessor]} alt='' />
          </Modal>
        </Fragment>
      ) : (
        ''
      );
    case 'boolIcon':
      return data[accessor] === true ? (
        <IconApproved className='table-bool-icon table-bool-icon--approved' />
      ) : data[accessor] === false ? (
        <IconAlert className='table-bool-icon table-bool-icon--alert' />
      ) : (
        data[accessor]
      );
    case 'taglist':
      return (
        <ul className='taglist'>
          {data[accessor]
            .split(';')
            .filter((s) => s)
            .map((tag) => (
              <li className='tag' key={tag}>
                {tag}
              </li>
            ))}
        </ul>
      );
    case 'multiLine':
      return (
        <Fragment>
          {data[accessor]?.map((line, idx, arr) => (
            <span className='multiLine' key={line}>
              {line}
            </span>
          ))}
        </Fragment>
      );
    case 'actions':
      return data[accessor].map(
        (action) =>
          action && (
            <Button
              classes={classNames('small', action.className)}
              key={action.label}
              // eslint-disable-next-line react/jsx-no-bind
              onClick={(e) => action.handler(e, data)}
              testSelector={action.testSelector || ''}
              // eslint-disable-next-line react/jsx-no-bind
              disabled={(action.disabled && action?.disabled(data)) || false}
            >
              {action.label}
            </Button>
          ),
      );
    case 'link':
      return (
        <a
          className={`a-${accessor}`}
          href={data[accessor]}
          target='_blank'
          rel='noreferrer'
        >
          {data[accessor]}
        </a>
      );
    case 'html':
      return <Fragment>{safeHTMLParser(data[accessor])}</Fragment>;
    case 'anchor':
      return (
        <a className={`a-${accessor}`} href={`#${data[accessor]}`}>
          {data[accessor]}
        </a>
      );
    default:
      return get(data, accessor);
  }
};
