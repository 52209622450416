import classNames from 'classnames';
import { CellFormatter } from 'helpers/tableFormatters';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { textToKebab } from 'utils';

import React from 'react';
import { useSelector } from 'react-redux';

import Checkbox from 'components/Checkbox';

const Row = ({
  columns,
  keyColumn = 'id',
  hideCheckbox = false,
  multiSelect = false,
  multiSelectRows = [],
  onChange = () => {},
  row,
  stickyCol,
  testSelector,
}) => {
  const currency = useSelector(({ whitelabel }) => whitelabel.currency);
  const language = useSelector(({ whitelabel }) => whitelabel.language);

  return (
    <tr key={`${row[keyColumn]}`}>
      {multiSelect && (
        <td key={`${row[keyColumn]}-checkbox`}>
          {!hideCheckbox && (
            <Checkbox
              id={`${row[keyColumn]}`}
              classes='center'
              // TODO make this component reliant only on hooks in order
              // to remove arrow functions and enforce this rule
              // eslint-disable-next-line react/jsx-no-bind
              onChange={(ev) => onChange(ev, row)}
              checked={multiSelectRows.some(
                (elem) => elem[keyColumn] === row[keyColumn],
              )}
            />
          )}
        </td>
      )}

      {columns.map((column) => (
        <td
          key={`${row[keyColumn]}-${column.accessor}`}
          className={classNames(column.align, {
            wrap: column.wrap,
            stickyCol: column.accessor === stickyCol,
          })}
          data-test={`${testSelector}-${textToKebab(column.label)}`}
        >
          {CellFormatter(row, column, { currency, language })}
        </td>
      ))}
    </tr>
  );
};

Row.propTypes = {
  columns: arrayOf(shape({})).isRequired,
  keyColumn: string.isRequired,
  hideCheckbox: bool,
  multiSelect: bool,
  multiSelectRows: arrayOf(shape({})),
  onChange: func,
  row: shape({}).isRequired,
};

export default Row;
