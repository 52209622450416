import { openModalAction } from 'actions/ui';
import { dateFormatter } from 'helpers/formatters';

import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';

import { t } from '@lingui/macro';

import Button from 'components/Button';
import Modal from 'components/Modal';

import { activationStatus } from 'constants/status';

export const Stakeholders = ({
  customer,
  dates,
  funder,
  language,
  store,
  pickupStore,
  orderActivationStatus,
  title,
  company,
}) => {
  const dispatch = useDispatch();

  const openStakeholderImage = () =>
    dispatch(openModalAction('stakeholder-client-id'));

  const drawStakeholder = (
    sectionTitle = '',
    labels = [],
    extraContent = undefined,
    section = undefined,
  ) => (
    <ul
      className='stakeholder-details'
      data-test={`stakeholder-${sectionTitle.toLowerCase()}`}
    >
      <li>
        <h3>{sectionTitle}</h3>
        {extraContent && extraContent}
      </li>
      {labels
        .filter((label) => label !== undefined)
        .map((label) => (
          <li>{label}</li>
        ))}
      {section && (
        <>
          <li>
            <h3>{section.title}</h3>
          </li>
          {section.labels &&
            section.labels
              .filter((label) => label !== undefined)
              .map((label) => <li>{label}</li>)}
        </>
      )}
    </ul>
  );

  return (
    <div className='stakeholders'>
      <h2>{title}</h2>

      {dates &&
        drawStakeholder(
          t({ id: 'stakeholders.dates', message: `Dates` }),
          [
            `${t({
              id: 'stakeholders.dates.createdAt',
              message: `Created`,
            })}: ${dateFormatter(dates.createdAt, language)}`,
            `${t({
              id: 'stakeholders.dates.approvedAt',
              message: `Approved`,
            })}: ${dateFormatter(dates.approvedDate, language)}`,
            `${t({
              id: 'stakeholders.dates.startDate',
              message: `Activated`,
            })}: ${dateFormatter(dates.startDate, language)}`,
          ],
          undefined,
          funder && {
            title: t({ id: 'stakeholders.bank', message: `Bank` }),
            labels: [funder.name],
          },
        )}

      {customer &&
        drawStakeholder(
          t({ id: 'stakeholders.customer', message: `Customer` }),
          [
            customer.name,
            customer.email,
            customer.phone,
            customer.idType,
            customer.idNumber,
            [
              customer?.address,
              customer?.postalCode,
              customer?.city,
              customer?.province,
            ]
              .filter(Boolean)
              .join(', '),
          ],
          customer.idPhoto && (
            <Fragment>
              <Button classes='inverted' onClick={openStakeholderImage}>
                {t({ id: 'stakeholder-client-id', message: `ID photo` })}
              </Button>
              <Modal name='stakeholder-client-id'>
                <img src={customer.idPhoto} alt='stakeholder-client-id' />
              </Modal>
            </Fragment>
          ),
          company && {
            title: t({ id: 'stakeholders.company', message: `Company` }),
            labels: [
              <p>
                <strong>{`${t({
                  id: 'stakeholders.company.name',
                  message: `Name`,
                })}`}</strong>
                {` : ${company.name}`}
              </p>,
              <p>
                <strong>{`${t({
                  id: 'stakeholders.company.number',
                  message: `Number`,
                })}`}</strong>
                {` : ${company.number}`}
              </p>,
              <p>
                <strong>{`${t({
                  id: 'stakeholders.company.signatory',
                  message: `Signatory`,
                })}`}</strong>
                {` : ${company.authorisedSignatoryName}, ${company.authorisedSignatoryPosition}`}
              </p>,
              <p>
                <strong>{`${t({
                  id: 'stakeholders.company.address.snippet',
                  message: `Address`,
                })}`}</strong>
                {` : ${company.address}`}
              </p>,
            ],
          },
        )}

      {store &&
        !pickupStore &&
        drawStakeholder(t({ id: 'stakeholders.store', message: `Store` }), [
          store.name,
          store.email,
          store.phone,
        ])}

      {store &&
        pickupStore &&
        drawStakeholder(t({ id: 'stakeholders.store', message: `Store` }), [
          `${t({
            id: 'stakeholders.store.purchase',
            message: `Purchase store`,
          })}: ${store.name}`,
          `${t({
            id: 'stakeholders.store.pickup',
            message: `Pickup store`,
          })}: ${pickupStore.name}`,
          pickupStore.email,
          pickupStore.phone,
        ])}

      {orderActivationStatus &&
        drawStakeholder(
          t({ id: 'stakeholders.activation', message: `Product Activation` }),
          [activationStatus()[orderActivationStatus]?.label],
        )}
    </div>
  );
};

export default Stakeholders;
