import {
  ADD_FLOW,
  FLOW_END,
  FLOW_GOTO,
  FLOW_NEXT_STEP,
  FLOW_PREV_STEP,
  FLOW_SET_STEPS,
  FLOW_START,
  REMOVE_FLOW,
} from 'actions/flow';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

const flow = function (state = {}, { type, payload }) {
  const flow = state[payload] || {};
  const { total, steps, currentStep } = flow;

  const changeStep = (newStep) => {
    return {
      [payload]: {
        total,
        steps,
        currentStep: newStep,
        currentStepName: steps[newStep].name,
      },
    };
  };

  switch (type) {
    case ADD_FLOW:
      if (!isEmpty(flow)) return state;

      return {
        ...state,
        ...{
          [payload.name]: {
            total: payload.total,
            steps: payload.steps,
            currentStep: payload.initialStep || 0,
            currentStepName: payload.steps[payload.initialStep || 0],
          },
        },
      };
    case REMOVE_FLOW:
      return omit(state, (name) => name === payload);
    case FLOW_NEXT_STEP:
      if (isEmpty(flow)) return state;

      return {
        ...state,
        ...changeStep(
          currentStep === total - 1 ? currentStep : currentStep + 1,
        ),
      };
    case FLOW_PREV_STEP:
      if (isEmpty(flow)) return state;

      return {
        ...state,
        ...changeStep(currentStep === 0 ? currentStep : currentStep - 1),
      };
    case FLOW_START:
      if (isEmpty(flow)) return state;

      return {
        ...state,
        ...changeStep(0),
      };
    case FLOW_END:
      if (isEmpty(flow)) return state;

      return { ...state, ...changeStep(total - 1) };
    case FLOW_GOTO:
      return {
        ...state,
        [payload.name]: {
          ...state[payload.name],
          currentStep: payload.newStep,
          currentStepName: state[payload.name].steps[payload.newStep].name,
        },
      };
    case FLOW_SET_STEPS:
      return {
        ...state,
        [payload.name]: {
          ...state[payload.name],
          steps: payload.steps,
          total: payload.steps.length,
        },
      };
    default:
      return state;
  }
};

export default flow;
