import removeIcon from 'assets/images/close.svg';
import classNames from 'classnames';

import React from 'react';

import Button from 'components/Button';

import './LineItem.styl';

const LineItem = ({
  classes,
  label,
  onRemove,
  removable,
  showLabel,
  value,
  testSelector,
}) => {
  return (
    <div
      data-test={testSelector}
      className={classNames('line-item', classes)}
      key={`${label}`}
    >
      {showLabel && (
        <span className='label' data-test={`${testSelector}-label`}>
          {label}
        </span>
      )}
      <span className='value' data-test={`${testSelector}-value`}>
        {value}
      </span>

      {removable ? (
        <Button
          classes='action-content'
          onClick={onRemove}
          data-test={`${testSelector}-removeBtn`}
        >
          <img className='remove' src={removeIcon} alt='remove' />
        </Button>
      ) : (
        <span className='action-content' />
      )}
    </div>
  );
};

LineItem.defaultProps = {
  classes: '',
  label: '',
  onRemove: () => {},
  removable: false,
  showLabel: true,
  value: '',
};

export default LineItem;
