import { t } from '@lingui/macro';

export const deviceStatus = () => ({
  0: {
    label: t({ id: 'status.inactive', message: `inactive` }),
    status: 'inactive',
  },
  1: {
    label: t({ id: 'status.active', message: `active` }),
    status: 'active',
  },
  2: {
    label: t({ id: 'status.archived', message: `archived` }),
    status: 'archived',
  },
  3: {
    label: t({ id: 'status.future', message: `future` }),
    status: 'future',
  },
  5: {
    label: t({ id: 'status.pending', message: `pending` }),
    status: 'pending',
  },
});

export const storeStatus = () => ({
  0: {
    label: t({ id: 'status.inactive', message: `inactive` }),
    status: 'inactive',
  },
  1: {
    label: t({ id: 'status.active', message: `active` }),
    status: 'active',
  },
  2: {
    label: t({ id: 'status.archived', message: `archived` }),
    status: 'archived',
  },
  3: {
    label: t({ id: 'status.future', message: `future` }),
    status: 'future',
  },
  5: {
    label: t({ id: 'status.pending', message: `pending` }),
    status: 'pending',
  },
});

export const contractStatus = () => ({
  0: {
    label: t({ id: 'contract.status.new', message: `new` }),
    status: 'new',
  },
  NEW: {
    label: t({ id: 'contract.status.new', message: `new` }),
    status: 'new',
  },
  1: {
    label: t({ id: 'contract.status.active', message: `active` }),
    status: 'active',
  },
  ACTIVE: {
    label: t({ id: 'contract.status.active', message: `active` }),
    status: 'active',
  },
  2: {
    label: t({ id: 'contract.status.finished', message: `finished` }),
    status: 'finished',
  },
  3: {
    label: t({ id: 'contract.status.withdrawn', message: `withdrawn` }),
    status: 'withdrawn',
  },
  WITHDRAWN: {
    label: t({ id: 'contract.status.withdrawn', message: `withdrawn` }),
    status: 'withdrawn',
  },
  4: {
    label: t({ id: 'contract.status.refused', message: `refused` }),
    status: 'refused',
  },
  REFUSED: {
    label: t({ id: 'contract.status.refused', message: `refused` }),
    status: 'refused',
  },
  5: {
    label: t({ id: 'contract.status.pending', message: `pending` }),
    status: 'pending',
  },
  PENDING: {
    label: t({ id: 'contract.status.pending', message: `pending` }),
    status: 'pending',
  },
  PENDING_ACTIVATION: {
    label: t({
      id: 'contract.status.pending.activation',
      message: `Pending Activation`,
    }),
    status: 'pending-activation',
  },
  6: {
    label: t({ id: 'contract.status.swap', message: `swap` }),
    status: 'swap',
  },
  7: {
    label: t({ id: 'contract.status.historic', message: `historic` }),
    status: 'historic',
  },
  8: {
    label: t({ id: 'contract.status.cancelled', message: `cancelled` }),
    status: 'cancelled',
  },
  9: {
    label: t({ id: 'contract.status.returned', message: `returned` }),
    status: 'returned',
  },
  CLOSED: {
    label: t({ id: 'contract.status.closed', message: `closed` }),
    status: 'closed',
  },
  CANCELLED: {
    label: t({ id: 'contract.status.cancelled', message: `cancelled` }),
    status: 'cancelled',
  },
  ACTIVE_WITHOUT_DEVICE_REFERENCE: {
    label: t({
      id: 'contract.status.active.without.reference',
      message: `Active w/o reference`,
    }),
    status: 'active-without-reference',
  },
  PENDING_FULFILL_REPLACE: {
    label: t({
      id: 'contract.status.active.pending.fulfill',
      message: `Pending replace fulfillment`,
    }),
    status: 'active-without-reference',
  },
});

export const cartStatus = () => ({
  ACTIVE: {
    label: t({ id: 'contract.status.active', message: `active` }),
    status: 'active',
  },
  CANCELLED: {
    label: t({ id: 'contract.status.cancelled', message: `cancelled` }),
    status: 'cancelled',
  },
  CLOSED: {
    label: t({ id: 'contract.status.closed', message: `closed` }),
    status: 'closed',
  },
  NEW: {
    label: t({ id: 'contract.status.new', message: `new` }),
    status: 'new',
  },
  PENDING: {
    label: t({ id: 'contract.status.pending', message: `pending` }),
    status: 'pending',
  },
  PENDING_ACTIVATION: {
    label: t({
      id: 'contract.status.pending.activation',
      message: `Pending Activation`,
    }),
    status: 'pending activation',
  },
  REFUSED: {
    label: t({ id: 'contract.status.refused', message: `refused` }),
    status: 'refused',
  },
  WITHDRAWN: {
    label: t({ id: 'contract.status.withdrawn', message: `withdrawn` }),
    status: 'withdrawn',
  },
});

export const userStatus = () => ({
  0: {
    label: t({ id: 'user.status.inactive', message: `inactive` }),
    status: 'inactive',
  },
  1: {
    label: t({ id: 'user.status.active', message: `active` }),
    status: 'active',
  },
  2: {
    label: t({ id: 'user.status.pending', message: `pending` }),
    status: 'pending',
  },
  3: {
    label: t({ id: 'user.status.blocked', message: `blocked` }),
    status: 'blocked',
  },
});

export const swapStatus = () => ({
  NO_SWAP: {
    label: t({ id: 'swap.status.no.swap', message: `No Swap` }),
    status: 'no-swap',
  },
  EARLY_SWAP: {
    label: t({ id: 'swap.status.early', message: `Early Swap` }),
    status: 'early-swap',
  },
  FREE_SWAP: {
    label: t({ id: 'swap.status.free', message: `Free Swap` }),
    status: 'free-swap',
  },
});

export const collectionStatus = () => ({
  NOT_SENT: {
    label: t({ id: 'collection.status.not.sent', message: `failed` }),
    status: 'failed',
  },
  SENT: {
    label: t({ id: 'collection.status.sent', message: `created` }),
    status: 'created',
  },
  CREATED: {
    label: t({ id: 'collection.status.created', message: `created` }),
    status: 'created',
  },
  BOOKED: {
    label: t({ id: 'collection.status.booked', message: `booked` }),
    status: 'booked',
  },
  COLLECTED: {
    label: t({ id: 'collection.status.collected', message: `collected` }),
    status: 'collected',
  },
  RECEIVED: {
    label: t({ id: 'collection.status.received', message: `received` }),
    status: 'received',
  },
});

export const recycleStatus = () => ({
  SWAP: {
    label: t({ id: 'recycle.status.swap', message: `Swap` }),
    status: 'swap',
  },
  TRADE_IN: {
    label: t({ id: 'recycle.status.tradein', message: `Trade In` }),
    status: 'trade-in',
  },
});

export const cmrStatus = () => ({
  PENDING: {
    label: t({ id: 'cmr.status.pending', message: `Pending` }),
    status: 'pending',
  },
  RECEIVED: {
    label: t({ id: 'cmr.status.received', message: `Received` }),
    status: 'received',
  },
  COUNTED: {
    label: t({ id: 'cmr.status.counted', message: `Counted` }),
    status: 'counted',
  },
  DATA_ERASED: {
    label: t({ id: 'cmr.status.data.erased', message: `Data erased` }),
    status: 'dataerased',
  },
  ACCEPTED: {
    label: t({ id: 'cmr.status.accepted', message: `Accepted` }),
    status: 'accepted',
  },
  REJECTED: {
    label: t({ id: 'cmr.status.rejected', message: `Rejected` }),
    status: 'rejected',
  },
  PAYMENT_PART_COMPLETED: {
    label: t({ id: 'cmr.status.part.completed', message: `Part Completed` }),
    status: 'partcompleted',
  },
  PAYMENT_COMPLETED: {
    label: t({ id: 'cmr.status.completed', message: `Completed` }),
    status: 'completed',
  },
  CUSTOMER_CANCELLED: {
    label: t({ id: 'cmr.status.cancelled', message: `Cancelled` }),
    status: 'cancelled',
  },
  RETURNED: {
    label: t({ id: 'cmr.status.returned', message: `Returned` }),
    status: 'returned',
  },
  UNKNOWN: {
    label: t({ id: 'cmr.status.unknown', message: `Unknown` }),
    status: 'unknown',
  },
});

export const campaignStatus = () => ({
  ENABLED: {
    label: t({ id: 'campaign.status.enabled', message: `Enabled` }),
    status: 'enabled',
  },
  DISABLED: {
    label: t({ id: 'campaign.status.disabled', message: `Disabled` }),
    status: 'disabled',
  },
});

export const contractPeriod = () => [
  {
    value: 'UPDATED',
    label: t({ id: 'contract.period.updated', message: `Updated` }),
  },
  {
    value: 'CREATED',
    label: t({ id: 'contract.period.created', message: `Created` }),
  },
  {
    value: 'ACTIVATED',
    label: t({ id: 'contract.period.activated', message: `Activated` }),
  },
];

export const orderDeliveryStatus = () => ({
  PENDING: {
    label: t({ id: 'delivery.status.pending', message: `Pending` }),
    status: 'pending',
  },
  AWAITING_PAYMENT: {
    label: t({
      id: 'delivery.status.awaiting.payment',
      message: `Awaiting Payment`,
    }),
    status: 'awaiting-payment',
  },
  AWAITING_FULFILLMENT: {
    label: t({
      id: 'delivery.status.awaiting.fulfillment',
      message: `Awaiting Fulfillment`,
    }),
    status: 'awaiting-fulfillment',
  },
  AWAITING_PICKUP: {
    label: t({
      id: 'delivery.status.awaiting.pickup',
      message: `Awaiting Pickup`,
    }),
    status: 'awaiting-pickup',
  },
  PARTIALLY_SHIPPED: {
    label: t({
      id: 'delivery.status.partially.shipped',
      message: `Partially Shipped`,
    }),
    status: 'partially-shipped',
  },
  COMPLETED: {
    label: t({ id: 'delivery.status.completed', message: `Completed` }),
    status: 'completed',
  },
  SHIPPED: {
    label: t({ id: 'delivery.status.shipped', message: `Shipped` }),
    status: 'shipped',
  },
  CANCELLED: {
    label: t({ id: 'delivery.status.cancelled', message: `Cancelled` }),
    status: 'cancelled',
  },
  DECLINED: {
    label: t({ id: 'delivery.status.declined', message: `Declined` }),
    status: 'declined',
  },
  REFUNDED: {
    label: t({ id: 'delivery.status.refunded', message: `Refunded` }),
    status: 'refunded',
  },
  DISPUTED: {
    label: t({ id: 'delivery.status.disputed', message: `Disputed` }),
    status: 'disputed',
  },
  MANUAL_VERIFICATION_REQUIRED: {
    label: t({
      id: 'delivery.status.manual.verification.required',
      message: `Manual Verification Required`,
    }),
    status: 'manual-verification-required',
  },
  PARTIALLY_REFUNDED: {
    label: t({
      id: 'delivery.status.manual.partially.refunded',
      message: `Partially Refunded`,
    }),
    status: 'partially-refunded',
  },
});

export const activationStatus = () => ({
  ACTIVATED: {
    label: t({ id: 'activation.status.activated', message: `Activated` }),
    status: 'activated',
  },
  CANCELED: {
    label: t({ id: 'activation.status.canceled', message: `Canceled` }),
    status: 'canceled',
  },
  PENDING: {
    label: t({ id: 'activation.status.pending', message: `Pending` }),
    status: 'pending',
  },
  EXCHANGED: {
    label: t({ id: 'activation.status.exchanged', message: `Exchanged` }),
    status: 'exchanged',
  },
  UNKNOWN: {
    label: t({ id: 'activation.status.uknown', message: `Unknown` }),
    status: 'unknown',
  },
});
