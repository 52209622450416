import { stringFormatter } from 'helpers/formatters';

import React, { Component, Fragment } from 'react';

import './Breadcrumbs.styl';

class Breadcrumbs extends Component {
  changePage = (evt, step) => {
    const { cartUUID, navigate, syncFlow } = this.props;

    evt.preventDefault();

    navigate(stringFormatter(step.path, [cartUUID], '#'));

    syncFlow(`/standard/${cartUUID}`);
  };

  render() {
    const { currentStep, steps, category, family } = this.props;

    return (
      <div className='breadcrumbs'>
        {steps.slice(0, currentStep).map((step) => {
          return (
            <Fragment key={step.name}>
              <a
                // TODO make this component reliant only on class functions
                // in order to remove arrow functions and enforce this rule
                // eslint-disable-next-line react/jsx-no-bind
                onClick={(evt) => this.changePage(evt, step)}
              >
                {(step.name === 'catalog' && (category ?? step.title)) ||
                  (step.name === 'options' && (family ?? step.title)) ||
                  step.title}
                {' \\ '}
              </a>
            </Fragment>
          );
        })}
        {currentStep <= steps.length - 1 &&
          steps.slice(currentStep, currentStep + 1).map((step) => (
            <span key={step.name} data-test='breadcrumb-title'>
              {(step.name === 'catalog' && (category ?? step.title)) ||
                (step.name === 'options' && (family ?? step.title)) ||
                step.title}
            </span>
          ))}
      </div>
    );
  }
}

Breadcrumbs.defaultProps = {
  steps: [],
  currentStep: 0,
};

export default Breadcrumbs;
