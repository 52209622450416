import React, { useEffect, useRef, useState } from 'react';

import './DeprecatedList.styl';
import ExtraItem from './ExtraItem';
import Item from './Item';

const DeprecatedList = ({
  items = [],
  idKey,
  nameKey,
  type,
  currency,
  classes,
  highlight,
  showId,
  language,
  handleClick,
}) => {
  const [performingAction, setPerformingAction] = useState(false);
  const abortControllerRef = useRef(null);

  useEffect(() => {
    abortControllerRef.current = new AbortController();

    return () => {
      abortControllerRef.current.abort();
    };
  }, []);

  const handleClickWrapper = async (ev) => {
    if (!performingAction) {
      setPerformingAction(true);
      try {
        const signal = abortControllerRef.current.signal;
        await handleClick(ev, signal);
      } finally {
        if (!abortControllerRef.current.signal.aborted) {
          setPerformingAction(false);
        }
      }
    }
  };

  return (
    <ul className={`list ${classes}`}>
      {items.map((item) =>
        type === 'extra' ? (
          <ExtraItem
            item={item}
            type={type}
            currency={currency}
            language={language}
            key={item.uuid}
            handleClick={handleClickWrapper}
            highlight={highlight}
            showId={
              showId &&
              !['NO_WARRANTY', 'NO_INSURANCE', 'NO_APPLE_CARE'].includes(
                item.id,
              )
            }
          />
        ) : (
          <Item
            type={type}
            idKey={idKey}
            nameKey={nameKey}
            currency={currency}
            language={language}
            item={item}
            key={item[idKey]}
            handleClick={handleClickWrapper}
            highlight={highlight}
            showId={
              showId &&
              !['NO_WARRANTY', 'NO_INSURANCE', 'NO_APPLE_CARE'].includes(
                item.id,
              )
            }
          />
        ),
      )}
    </ul>
  );
};

DeprecatedList.defaultProps = {
  classes: '',
  idKey: 'id',
  nameKey: 'name',
};

export default DeprecatedList;
