import {
  CLEAR_LOAN_DATA,
  CLEAR_RESIDUAL_VALUES,
  GET_LOAN_DATA,
  GET_RESIDUAL_VALUES,
  LIST_DEVICES,
} from 'actions/admin/device';

import initialState from 'reducers/initialState';

export default function admin(state = initialState.adminDevice, action) {
  switch (action.type) {
    case LIST_DEVICES:
      return { ...state, devices: action.data };
    case GET_LOAN_DATA:
      return { ...state, loanData: action.loanData };
    case CLEAR_LOAN_DATA:
      const { loanData, ...newState } = state;
      return { ...newState };
    case GET_RESIDUAL_VALUES:
      return {
        ...state,
        residuals: action.residuals,
        residualCols: action.residualCols,
        salesDate: action.selectedDate,
      };
    case CLEAR_RESIDUAL_VALUES:
      return { ...state, residuals: [] };
    default:
      return state;
  }
}
