import { listStoresAction } from 'actions/admin/store';
import { openModalAction } from 'actions/ui';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import Button from 'components/Button';
import Heading from 'components/Heading';
import Loader from 'components/Loader';
import Table from 'components/table/Table';

import { storeStatus } from 'constants/status';

import useTrackPage from 'hooks/useTrackPage';

import StoreForm from './components/StoreForm';

export const Stores = () => {
  useTrackPage('Stores');

  const dispatch = useDispatch();
  const stores = useSelector(({ adminStore }) => adminStore.stores);
  const loading = useSelector(({ ui }) => ui.loading);

  const [store, setStore] = useState({});

  useEffect(() => {
    dispatch(listStoresAction());
  }, [dispatch]);

  const openForm = async () => {
    setStore({});
    await dispatch(openModalAction('admin-store'));
  };

  const editStore = async (_, store) => {
    setStore(store);
    await dispatch(openModalAction('admin-store'));
  };

  const columns = [
    {
      label: t({ id: 'table.column.name', message: `Name` }),
      accessor: 'name',
      type: 'text',
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.email.address', message: `Email Address` }),
      accessor: 'email',
      type: 'text',
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.phone.number', message: `Phone Number` }),
      accessor: 'phone',
      type: 'text',
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.status', message: `Status` }),
      accessor: 'status',
      type: 'status',
      filterable: true,
      list: storeStatus(),
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.updated', message: `Updated on` }),
      accessor: 'updatedAt',
      type: 'date',
      filterable: true,
      condition: 'after',
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.actions', message: `Actions` }),
      accessor: 'actions',
      type: 'actions',
      filterable: false,
      sortable: false,
      align: 'right',
      wrap: false,
    },
  ];

  const actions = [
    {
      label: t({ id: 'admin.store.actions.edit', message: `Edit` }),
      handler: editStore,
    },
  ];

  const rows = stores.map((store) => ({ ...store, actions }));

  if (loading) return <Loader />;

  return (
    <>
      <Heading size='medium' classes='admin-page-title'>
        <Trans id='admin.stores.title'>Stores</Trans>
      </Heading>

      <Button
        onClick={openForm}
        classes='admin-main-action'
        testSelector='add-store'
      >
        <Trans id='admin.add.store.button'>Add Store</Trans>
      </Button>

      <Table columns={columns} data={rows} defaultOrder='name' />

      <StoreForm initialValues={store} />
    </>
  );
};

export default Stores;
