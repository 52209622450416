import {
  createCommunicationAction,
  getCampaignFormDataAction,
  updateCommunicationAction,
} from 'actions/admin/campaign';
import { numericality, required } from 'helpers/validators';
import isEmpty from 'lodash/isEmpty';
import { getFormValues } from 'redux-form';
import { sanitizeRequestData } from 'utils';

import React from 'react';
import { connect } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import ButtonForm from 'components/ButtonForm';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';

import { ADMIN } from 'constants/user';

import './CommunicationForm.styl';

const delayActionText = {
  MARKETING: (
    <Trans id='forms.communication.delay.action.operational.text'>
      The time milestone from today's date, that will set where this
      communication action is settled (e.g 7 will set as seven days from today)
    </Trans>
  ),
  OPERATIONAL: (
    <Trans id='forms.communication.delay.action.marketing.text'>
      The time milestone from previous communication action date, that will set
      where this communication action is settled (e.g 7 will set as seven days
      starting from the previous communication action)
    </Trans>
  ),
};

const CommunicationForm = ({
  name = 'communicationForm',
  campaignId,
  commsCategory,
  communicationsTypes,
  createCommunication,
  getCampaignFormData,
  initialValues,
  values,
  onClose,
  onSubmit,
  stopOutcomes,
  updateCommunication,
  isAdmin,
  onOpen = () => {},
}) => {
  const handleCreate = async (values) =>
    await createCommunication({
      campaignId,
      ...sanitizeRequestData(values),
    });

  const handleEdit = async (values) =>
    await updateCommunication({
      campaignId,
      ...sanitizeRequestData(values),
    });

  const fields = [
    {
      component: Input,
      placeholder: t({
        id: 'forms.communication.placeholder.communication.code',
        message: `Communication Code`,
      }),
      name: 'actionCode',
      size: 'medium',
      variant: 'underline',
      validate: [required()],
      disabled: !isEmpty(values),
    },
    {
      component: Input,
      placeholder: t({
        id: 'forms.communication.placeholder.description',
        message: `Description`,
      }),
      name: 'description',
      size: 'medium',
      variant: 'underline',
      validate: [required()],
    },
    {
      component: Dropdown,
      clearable: true,
      placeholder: t({
        id: 'forms.communication.placeholder.type',
        message: `Communication Type`,
      }),
      helpText: t({
        id: 'forms.communication.communication.type.text',
        message: `The Wait and Alert options are only for internal communications. Wait implies that the communication only waits for a status change, where Alert will wait for a response from the entity selected on Notify List`,
      }),
      options: isAdmin
        ? communicationsTypes
        : communicationsTypes?.filter(
            (data) => data.value !== 'WAIT' && data.value !== 'ALERT',
          ),
      name: 'commsType',
      size: 'medium',
      testSelector: 'addCommunicationTypeDropdown',
      variant: 'underline',
      validate: [required()],
    },
    {
      component: Input,
      placeholder: t({
        id: 'forms.communication.placeholder.template',
        message: `Template Name`,
      }),
      name: 'templateName',
      size: 'medium',
      variant: 'underline',
      validate: [
        required({
          if: (values) =>
            ['EMAIL', 'SMS', 'LANDING_PAGE'].includes(values.commsType),
        }),
      ],
    },
    {
      component: Dropdown,
      clearable: true,
      options: stopOutcomes,
      placeholder: t({
        id: 'forms.communication.placeholder.stop.outcome',
        message: `Stop Outcome`,
      }),
      helpText: t({
        id: 'forms.communication.stop.outcome.text',
        message: `The action that sets the communication as completed and triggers the next communication`,
      }),
      name: 'stopOutcome',
      size: 'medium',
      testSelector: 'addCommunicationStopOutcomeDropdown',
      variant: 'underline',
      validate: [required()],
    },
    {
      component: Input,
      type: 'number',
      placeholder: t({
        id: 'forms.communication.placeholder.delay',
        message: `Send Offset from Trigger Event`,
      }),
      helpText: !commsCategory
        ? t({
            id: 'forms.communication.delay.text',
            message: `The delay amount of time reference will depend on the communication category chosen above`,
          })
        : delayActionText[commsCategory],
      name: 'delay',
      size: 'medium',
      variant: 'underline',
      value: 0,
      validate: [numericality({ allowBlank: true })],
    },
    {
      component: Dropdown,
      clearable: true,
      options: [
        { label: 'Days', value: 'DAYS' },
        { label: 'Hours', value: 'HOURS' },
        { label: 'Minutes', value: 'MINUTES' },
      ],
      placeholder: t({
        id: 'forms.communication.placeholder.delay.unit',
        message: `Offset Time Unit`,
      }),
      name: 'delayUnit',
      size: 'medium',
      variant: 'underline',
    },
  ];

  const bccList = {
    component: Input,
    placeholder: t({
      id: 'forms.communication.placeholder.bcc.list',
      message: `BCC List`,
    }),
    helpText: t({
      id: 'forms.communication.notify.list.help',
      message: `List of all the emails to be added in CC. Emails must be separated by commas (,).`,
    }),
    name: 'bccList',
    size: 'medium',
    testSelector: 'addCommunicationBCCList',
    variant: 'underline',
  };

  return isEmpty(values) ? (
    <ButtonForm
      name={name}
      getFormData={getCampaignFormData}
      fields={isAdmin ? [...fields, bccList] : fields}
      title={<Trans id='communication.form.add.title'>Add Communication</Trans>}
      openBtnLabel={
        <Trans id='communication.form.add.button'>Add Communication</Trans>
      }
      handleSubmit={handleCreate}
      onSubmitSuccess={onSubmit}
      onOpen={onOpen}
      onClose={onClose}
      values={initialValues}
    />
  ) : (
    <ButtonForm
      name={name}
      getFormData={getCampaignFormData}
      fields={isAdmin ? [...fields, bccList] : fields}
      title={
        <Trans id='communication.form.edit.title'>Edit Communication</Trans>
      }
      openBtnLabel={
        <Trans id='communication.form.edit.button'>Edit Communication</Trans>
      }
      handleSubmit={handleEdit}
      onSubmitSuccess={onSubmit}
      onClose={onClose}
      values={values}
      toggleShowForm={true}
    />
  );
};

export default connect(
  (state, ownProps) => ({
    campaignId: state.adminCampaign.campaign.campaignCode,
    campaignCodes: state.adminCampaign.campaignCodes,
    stopOutcomes: state.adminCampaign.stopOutcomes,
    communicationsTypes: state.adminCampaign.communicationsTypes,
    commsCategory: getFormValues(ownProps?.name)(state)?.commsCategory,
    commsType: getFormValues(ownProps?.name)(state)?.commsType,
    isAdmin: ADMIN === state.user.role,
  }),
  {
    getCampaignFormData: getCampaignFormDataAction,
    createCommunication: createCommunicationAction,
    updateCommunication: updateCommunicationAction,
  },
)(CommunicationForm);
