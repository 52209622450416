import classNames from 'classnames';

import React from 'react';

import './Spinner.styl';

const Spinner = ({ classes }) => {
  const cx = classNames('spinner', classes);

  return (
    <div className={cx}>
      <div className='bar2' />
      <div className='bar1' />
      <div className='bar3' />
      <div className='bar4' />
      <div className='bar5' />
      <div className='bar6' />
      <div className='bar7' />
      <div className='bar8' />
      <div className='bar9' />
      <div className='bar10' />
      <div className='bar11' />
      <div className='bar12' />
    </div>
  );
};

export default Spinner;
