import {
  GET_CRM_TEST_CONTRACTS,
  GET_TEST_CONTRACT,
  GET_TEST_CONTRACT_FORM_DATA,
} from 'actions/admin/testContracts';

import initialState from 'reducers/initialState';

export default function admin(state = initialState.adminTestContracts, action) {
  switch (action.type) {
    case GET_CRM_TEST_CONTRACTS:
      return {
        ...state,
        testContracts: action.data,
      };
    case GET_TEST_CONTRACT:
      return {
        ...state,
        testContractMessages: action.messages,
        testContract: action.testContract,
        availableStages: action.availableStages,
      };
    case GET_TEST_CONTRACT_FORM_DATA:
      return {
        ...state,
        campaignCodes: action.data.campaignCodes,
        paymentPlans: action.data.paymentPlans,
        deviceFamilies: action.data.deviceFamilies,
        triggerEvents: action.data.triggerEvents,
      };
    default:
      return state;
  }
}
