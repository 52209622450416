import {
  createTestContractAction,
  deleteTestContractAction,
  getCrmTestContractsAction,
  getTestContractFormDataAction,
} from 'actions/admin/testContracts';
import { closeModalAction, openModalAction } from 'actions/ui';
import { email, required } from 'helpers/validators';
import { length } from 'helpers/validators';
import { getFormValues } from 'redux-form';

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';

import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import Footer from 'components/Footer';
import Form from 'components/Form';
import Input from 'components/Input';
import Modal from 'components/Modal';
import RenderDatePicker from 'components/RenderDatePicker';
import Table from 'components/table/Table';

import { triggerEventsDict } from 'constants/dictionaries';
import { phoneFormats } from 'constants/locales';
import { adminTestContractDetailsUrl } from 'constants/routes';

export const TestContracts = ({
  country,
  formValues,
  campaignCodes = [],
  deviceFamilies = [],
  paymentPlans = [],
  testContracts = [],
  triggerEvents = [],
  getTestContractFormData,
  createTestContract,
  deleteTestContract,
  getCrmTestContracts,
  openModal,
  closeModal,
}) => {
  const navigate = useNavigate();

  const [requestTestContracts, setRequestTestContracts] = useState(true);
  const [requestFormLists, setRequestFormLists] = useState(false);

  useEffect(() => {
    if (requestTestContracts) {
      getCrmTestContracts();
      setRequestTestContracts(false);
    }
  }, [requestTestContracts, getCrmTestContracts]);

  useEffect(() => {
    if (requestFormLists) {
      getTestContractFormData();
      setRequestFormLists(false);
    }
  }, [requestFormLists, getTestContractFormData]);

  const columns = [
    {
      label: t({ id: 'table.column.contract.id', message: `Contract ID` }),
      accessor: 'contractID',
      type: 'text',
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.campaign.id', message: `Campaign ID` }),
      accessor: 'campaignID',
      type: 'text',
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.payment.plan', message: `Payment Plan` }),
      accessor: 'paymentPlan',
      type: 'text',
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: false,
    },

    {
      label: t({ id: 'table.column.device', message: `Device` }),
      accessor: 'device',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.trigger.start.date',
        message: `Trigger Start Date`,
      }),
      accessor: 'triggerStartDate',
      type: 'date',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.trigger.end.date',
        message: `Trigger End Date`,
      }),
      accessor: 'triggerEndDate',
      type: 'date',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.trigger.event', message: `Trigger Event` }),
      accessor: 'triggerEvent',
      type: 'dict',
      list: triggerEventsDict(),
      filterable: false,
      sortable: false,
      align: 'center',
      wrap: true,
    },
    {
      label: t({ id: 'table.column.email.address', message: `Email Address` }),
      accessor: 'email',
      type: 'text',
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.phone.number', message: `Phone Number` }),
      accessor: 'phone',
      type: 'text',
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.stage', message: `Stage` }),
      accessor: 'stage',
      type: 'text',
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.actions', message: `Actions` }),
      accessor: 'actions',
      type: 'actions',
      filterable: false,
      sortable: false,
      align: 'right',
      wrap: false,
    },
  ];

  const actions = [
    {
      label: t({ id: 'table.column.actions.delete', message: `Delete` }),
      handler: async (_, { id }) => {
        await deleteTestContract(id);

        setRequestTestContracts(true);
      },
    },
    {
      label: t({ id: 'table.column.actions.view.messages', message: `View` }),
      handler: (_, { id }) => {
        navigate(adminTestContractDetailsUrl(id));
      },
    },
  ];

  const rows = testContracts?.map((testContract) => ({
    ...testContract,
    actions,
  }));

  return (
    <Fragment>
      <h1 className='admin-page-title'>
        <Trans id='test.contracts.title'>Test Contracts</Trans>
      </h1>

      <Button
        // TODO make this component reliant only on hooks
        // in order to remove arrow functions and enforce this rule
        // eslint-disable-next-line react/jsx-no-bind
        onClick={() => {
          setRequestFormLists(true);
          openModal('admin-test-contract');
        }}
        classes='admin-main-action'
      >
        <Trans id='admin.test.contracts.add.button'>Add Test Contract</Trans>
      </Button>

      <Table
        columns={columns}
        data={rows}
        keyColumn='id'
        defaultOrder='startDate'
        defaultDir='desc'
      />
      <Modal name='admin-test-contract'>
        <Form
          form='testContractForm'
          fields={[
            {
              component: Input,
              placeholder: t({
                id: 'forms.contract.placeholder.contract.id',
                message: `Contract ID`,
              }),
              name: 'contractID',
              size: 'medium',
              variant: 'underline',
              validate: [required()],
            },
            {
              component: Dropdown,
              options: campaignCodes,
              placeholder: t({
                id: 'forms.contract.placeholder.campaign.id',
                message: `Campaign ID`,
              }),

              name: 'campaignID',
              size: 'medium',
              variant: 'underline',
              validate: [required()],
            },
            {
              component: Dropdown,
              placeholder: t({
                id: 'forms.contract.placeholder.payment.plan',
                message: `Payment Plan`,
              }),
              options: paymentPlans,
              name: 'paymentPlanCode',
              size: 'medium',
              variant: 'underline',
              validate: [required()],
            },
            {
              component: Dropdown,
              options: deviceFamilies,
              placeholder: t({
                id: 'forms.contract.placeholder.device',
                message: `Device`,
              }),

              name: 'deviceCode',
              size: 'medium',
              variant: 'underline',
              validate: [required()],
            },
            {
              component: Dropdown,
              options: triggerEvents,
              placeholder: t({
                id: 'forms.contract.placeholder.trigger.event',
                message: `Trigger Event`,
              }),
              name: 'triggerEvent',
              size: 'medium',
              variant: 'underline',
              validate: [required()],
            },
            {
              component: RenderDatePicker,
              label: t({
                id: 'forms.contract.trigger.start.date',
                message: `Trigger Start Date`,
              }),
              name: 'triggerStartDate',
              validate: [required()],
              maxDate: formValues?.triggerEndDate,
            },
            {
              component: RenderDatePicker,
              label: t({
                id: 'forms.contract.trigger.end.date',
                message: `Trigger End Date`,
              }),
              name: 'triggerEndDate',
              minDate: formValues?.triggerStartDate,
            },
            {
              component: Input,
              placeholder: t({
                id: 'forms.contract.placeholder.phone',
                message: `Phone number`,
              }),
              name: 'phone',
              size: 'medium',
              variant: 'underline',
              validate: [
                required(),
                length({ min: phoneFormats[country].length }),
              ],
            },
            {
              component: Input,
              placeholder: t({
                id: 'forms.contract.placeholder.email',
                message: `Email address`,
              }),
              name: 'email',
              size: 'medium',
              variant: 'underline',
              validate: [required(), email()],
            },
          ]}
          submitLabel='Submit'
          // eslint-disable-next-line react/jsx-no-bind
          onSubmit={async (values) => {
            await createTestContract(values);

            setRequestTestContracts(true);
            closeModal('admin-test-contract');
          }}
        />
      </Modal>

      <Footer classes='main' copyright={false} />
    </Fragment>
  );
};

export default connect(
  (state) => ({
    country: state.whitelabel.country,
    currency: state.whitelabel.currency,
    formValues: getFormValues('testContractForm')(state),
    campaignCodes: state.adminTestContracts.campaignCodes,
    deviceFamilies: state.adminTestContracts.deviceFamilies,
    paymentPlans: state.adminTestContracts.paymentPlans,
    triggerEvents: state.adminTestContracts.triggerEvents,
    testContracts: state.adminTestContracts?.testContracts,
    language: state.whitelabel.language,
  }),
  {
    getCrmTestContracts: getCrmTestContractsAction,
    createTestContract: createTestContractAction,
    deleteTestContract: deleteTestContractAction,
    getTestContractFormData: getTestContractFormDataAction,
    openModal: openModalAction,
    closeModal: closeModalAction,
  },
)(TestContracts);
