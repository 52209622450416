import AbstractDTO from 'DTOs/AbstractDTO';

const deviceValidator = (raw) => {
  return {
    deviceID: raw.deviceID ?? raw.id ?? null,
    id: raw.wlDeviceID ?? null,
    wlTags: raw.wlTags ?? '',
    url: raw.wlUrl ?? '',
    status: raw.status ?? 1,
  };
};

export default AbstractDTO(deviceValidator);
