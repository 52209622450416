import { format, numericality, required } from 'helpers/validators';
import { Field, reduxForm } from 'redux-form';

import React from 'react';

import { Trans, t } from '@lingui/macro';

import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import Modal from 'components/Modal';
import RenderField from 'components/RenderField';

import { currencyRegex } from 'constants/forms';

import './ManualItemForm.styl';

const ManualItemForm = ({
  addItem,
  handleSubmit,
  invalid,
  onClose,
  pristine,
  submitting,
  cartUUID,
}) => {
  const handleItemSubmit = async (item) => {
    await addItem(cartUUID, item);
    onClose();
  };

  return (
    <Modal
      name='manualItemForm'
      title={t({ id: 'manual.item.form.title', message: `Add Item` })}
      onClose={onClose}
      classes='manualItemForm'
    >
      <form className='manual-item-form'>
        <Field
          name='description'
          type='text'
          component={RenderField}
          validate={[required()]}
          label={t({
            id: 'manual.item.form.description',
            message: `Description`,
          })}
          testSelector='manualItemForm-description'
        />
        <Field
          name='category'
          component={Dropdown}
          placeholder={t({
            id: 'manual.item.form.category.placeholder',
            message: `Select Category`,
          })}
          options={[
            {
              value: 'Hardware',
              label: t({
                id: 'manual.item.form.option.Hardware',
                message: 'Hardware',
              }),
            },
            {
              value: 'Software',
              label: t({
                id: 'manual.item.form.option.software',
                message: 'Software',
              }),
            },
            {
              value: 'Accessories',
              label: t({
                id: 'manual.item.form.option.accessories',
                message: 'Accessories',
              }),
            },
            {
              value: 'Service',
              label: t({
                id: 'manual.item.form.option.service',
                message: 'Service',
              }),
            },
          ]}
          validate={required()}
          testSelector='manual-item-form-type'
          variant='underline'
          labelType='outside'
        />

        <Field
          name='price'
          type='text'
          component={RenderField}
          validate={[
            required(),
            numericality({ greaterThan: 0 }),
            format({ with: currencyRegex }),
          ]}
          label={t({
            id: 'manual.item.form.unit.price',
            message: `Unit Price`,
          })}
          testSelector='manualItemForm-unitPrice'
        />

        <Field
          name='quantity'
          component={RenderField}
          validate={[required(), numericality({ greaterThan: 0 })]}
          label={t({ id: 'manual.item.form.quantity', message: `Quantity` })}
          testSelector='manualItemForm-qty'
        />

        <Button
          type='submit'
          disabled={pristine || submitting || invalid}
          onClick={handleSubmit((item) => handleItemSubmit(item))}
          testSelector='manualItemForm-submit'
        >
          <Trans id='manual.item.form.submit'>Add</Trans>
        </Button>
      </form>
    </Modal>
  );
};

export default reduxForm({
  form: 'manualItemForm',
  destroyOnUnmount: true,
})(ManualItemForm);
