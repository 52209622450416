import { setCustomerAction } from 'actions/customer';
import {
  createCart,
  createMultiTradecart,
  finalizeMultiRecycle,
  returnDevice,
  selectRecycleOutcomeAction,
  sendQuoteApprovalMessageAction,
} from 'actions/recycle';
import { addNotificationAction } from 'actions/ui';
import { parsePhoneNumber } from 'libphonenumber-js';
import isEmpty from 'lodash/isEmpty';
import { capitalize } from 'utils';

import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';

import Checkbox from 'components/Checkbox';
import Footer from 'components/Footer';
import Heading from 'components/Heading';
import List from 'components/List';
import Loader from 'components/Loader';
import RadioButtons from 'components/RadioButtons';
import Section from 'components/Section';
import DevicesSummary from 'components/recycle/DevicesSummary';
import TradeSteps from 'components/recycle/TradeSteps';

import {
  customerUpdateSuccess,
  evaluationDeviceNotFinished,
  returnDeviceSuccess,
} from 'constants/notifications';
import {
  GIFT_CARD,
  RETURN_DEVICE,
  SWAP,
  TRADE_IN,
  recycleLabels,
} from 'constants/recycle';
import {
  recycleDetailsUrl,
  recycleSearchOrderUrl,
  recycleSearchUrl,
  serviceUrlWithCart,
} from 'constants/routes';

import './RecycleOutcomes.styl';
import Outcomes from './components/Outcomes';
import RecycleCustomerInfo from './components/RecycleCustomerInfo';

export const RecycleOutcomes = () => {
  const { orderUUID } = useParams();

  const hasPosaleMultiDeviceTradein = useSelector(
    ({ whitelabel }) => whitelabel?.configs?.hasPosaleMultiDeviceTradein,
  );

  return (
    <>
      {hasPosaleMultiDeviceTradein ? (
        <MultiOutcomes
          recycleType={orderUUID ? SWAP : TRADE_IN}
          orderUUID={orderUUID}
        />
      ) : (
        <Outcomes orderUUID={orderUUID} />
      )}
    </>
  );
};

export const MultiOutcomes = ({ recycleType }) => {
  const [confirmationMethod, setConfirmationMethod] = useState(null);
  const [enableTradeSteps, setEnableTradeSteps] = useState(false);
  const [IDConfirmed, setIDConfirmed] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const dispatch = useDispatch();
  const { orderUUID } = useParams();
  const navigate = useNavigate();

  const loading = useSelector(({ ui }) => ui.loading);

  const selectedDevices = useSelector(({ recycle }) => recycle.selectedDevices);
  const selectedOutcome = useSelector(({ recycle }) => recycle.selectedOutcome);

  const showSapCustomerForm = useSelector(
    ({ whitelabel }) =>
      (recycleType === SWAP || recycleType === TRADE_IN) &&
      whitelabel.configs.showSapCustomerForm,
  );
  const tradecartStatus = useSelector(({ recycle }) => recycle.tradecartStatus);

  const customer = useSelector(({ customer }) => customer);

  const salesPerson = useSelector(({ user }) => user.name);

  const confirmationOptions = useSelector(
    ({ whitelabel }) => whitelabel.configs.confirmationOptions || ['SMS'],
  );

  const country = useSelector(({ whitelabel }) => whitelabel.country);
  const recycleCustomerExtraFields = useSelector(
    ({ whitelabel }) => whitelabel.configs.recycleCustomerExtraFields,
  );
  const countryPhoneCodes = useSelector(
    ({ whitelabel }) => whitelabel.configs.countryPhoneCodes,
  );
  const currency = useSelector(({ whitelabel }) => whitelabel.currency);
  const wlCode = useSelector(({ whitelabel }) => whitelabel.wlCode);
  const customerTradecartApproval = useSelector(
    ({ whitelabel }) => whitelabel.configs.customerTradecartApproval,
  );
  const language = useSelector(({ whitelabel }) => whitelabel.language);
  const recycleOptions = useSelector(
    ({ whitelabel }) => whitelabel.configs.recycleOptions,
  );

  const showAddressFields = useSelector(
    ({ whitelabel }) => whitelabel.configs.showAddressFields,
  );
  const recycleCart = useSelector(({ recycle }) => recycle.tradecart);

  const someDevicesNonTradable = useCallback(
    () =>
      selectedDevices.length === 0 ||
      selectedDevices.some(
        (selectedDevice) =>
          isEmpty(selectedDevice.evaluation) ||
          selectedDevice.evaluation?.grade === 'NWZ',
      ),
    [selectedDevices],
  );

  useEffect(() => {
    if (recycleOptions[recycleType]?.length === 1)
      dispatch(selectRecycleOutcomeAction(recycleOptions[recycleType][0]));

    if (confirmationOptions?.length === 1)
      setConfirmationMethod(confirmationOptions[0]);

    if (someDevicesNonTradable())
      dispatch(addNotificationAction(evaluationDeviceNotFinished()));
  }, [
    recycleType,
    recycleOptions,
    someDevicesNonTradable,
    selectedDevices,
    dispatch,
    confirmationOptions,
  ]);

  const toggleIDConfirmation = () => setIDConfirmed(!IDConfirmed);

  const handleRecycleOutcomeChange = ({ value }) =>
    dispatch(selectRecycleOutcomeAction(value));

  const handleConfirmationOptionUpdate = (newConfirmationMethod) =>
    setConfirmationMethod(newConfirmationMethod);

  const handleCustomerFormSubmit = async ({ customer }) => {
    setIDConfirmed(false);
    setSubmitting(true);

    await dispatch(setCustomerAction(customer));

    const tradeCart = await dispatch(
      createMultiTradecart({
        selectedDevices,
        selectedOutcome,
        orderUUID,
        customer: {
          ...customer,
          phone: parsePhoneNumber(customer.phone, country).number,
        },
      }),
    );

    if (customerTradecartApproval) {
      if (tradeCart.salesChannel !== 'ONLINE') {
        dispatch(
          sendQuoteApprovalMessageAction(tradeCart.id, confirmationMethod),
        );
      }
    } else {
      dispatch(addNotificationAction(customerUpdateSuccess()));
    }

    setEnableTradeSteps(true);
    setSubmitting(false);
  };

  const getRecycleFullValue = () =>
    selectedDevices.reduce(
      (acc, device) => (acc += device.evaluation.deviceValue),
      0,
    );

  const getRecycleFullSwapValue = () =>
    selectedDevices.reduce(
      (acc, device) => (acc += device.evaluation.swapCost),
      0,
    );

  const navigateToEvaluation = () =>
    orderUUID
      ? navigate(recycleSearchOrderUrl(orderUUID))
      : navigate(recycleSearchUrl);

  const disableContinueBtn = () =>
    submitting ||
    isEmpty(selectedOutcome) ||
    someDevicesNonTradable() ||
    (customerTradecartApproval && !IDConfirmed) ||
    (selectedOutcome === GIFT_CARD && isEmpty(customer.email));

  const finishRecycleProcess = async () => {
    let newRecycleCart = recycleCart;

    if (!newRecycleCart)
      newRecycleCart = await dispatch(
        finalizeMultiRecycle({
          customer,
          selectedDevices,
          selectedOutcome,
          orderUUID,
          recycleType,
        }),
      );

    if (newRecycleCart) {
      if (selectedOutcome === RETURN_DEVICE || selectedOutcome === GIFT_CARD) {
        const ResponseSuccess = await dispatch(
          returnDevice({ customer, recycleCart: newRecycleCart, recycleType }),
        );
        if (ResponseSuccess) {
          navigate(recycleDetailsUrl(newRecycleCart.id));

          if (recycleType === SWAP)
            dispatch(addNotificationAction(returnDeviceSuccess()));
        }
      } else {
        const cart = await dispatch(
          createCart({
            recycleCart: newRecycleCart,
            selectedOutcome,
            recycleType,
          }),
        );
        if (cart) {
          navigate(serviceUrlWithCart(cart.uuid));
        }
      }
    }
  };

  if (selectedDevices?.length === 0) {
    return (
      <div>
        <h1>
          <Trans id='data.unavailable.title'>Data unavailable</Trans>
        </h1>
        <p>
          <Trans id='data.unavailable.text'>
            The data you are trying to access is no longer available.
          </Trans>
        </p>
      </div>
    );
  }

  return (
    <div className='outcomes--multi'>
      {loading && <Loader />}

      <Section className='outcomes-section'>
        <Heading classes='outcomes-section__heading' size='big'>
          <Trans id='evaluation.swap.outcomes.title'>Outcome</Trans>
        </Heading>

        {recycleOptions[recycleType]?.length > 1 && (
          <RadioButtons
            options={recycleOptions[recycleType].map((option) => ({
              key: `outcome-${option}`,
              value: option,
              label: recycleLabels(option),
            }))}
            selectedValue={selectedOutcome}
            onClick={handleRecycleOutcomeChange}
          />
        )}

        {selectedOutcome === GIFT_CARD && (
          <Fragment>
            <RecycleCustomerInfo
              recycleCustomerExtraFields={recycleCustomerExtraFields}
              country={country}
              countryPhoneCodes={countryPhoneCodes}
              initialValues={customer}
              mode='vendor'
              submitFunction={handleCustomerFormSubmit}
              showSapCustomerForm={showSapCustomerForm}
              showAddressFields={showAddressFields}
              wlCode={wlCode}
            />

            {customerTradecartApproval && (
              <Fragment>
                {confirmationOptions?.length > 1 && (
                  <List
                    type='circle'
                    items={confirmationOptions}
                    handleClick={handleConfirmationOptionUpdate}
                  />
                )}

                {(tradecartStatus.hasIdPhoto === 'APPROVED' ||
                  enableTradeSteps) && <TradeSteps steps={tradecartStatus} />}

                {tradecartStatus?.isCustomerApproved === 'APPROVED' &&
                  tradecartStatus?.hasIdPhoto === 'APPROVED' && (
                    <Checkbox
                      id='id-confirmation'
                      onChange={toggleIDConfirmation}
                      checked={IDConfirmed}
                      label={t({
                        id: 'recycle.id.confirmation.checkbox',
                        message: `Customer ID photo clear, and all ID data easy to read. Confirmed by ${salesPerson}`,
                      })}
                    />
                  )}
              </Fragment>
            )}
          </Fragment>
        )}
      </Section>

      <Section className='outcomes-selection'>
        <DevicesSummary
          currency={currency}
          language={language}
          totalValue={getRecycleFullValue()}
          totalSwapCost={getRecycleFullSwapValue()}
          devices={selectedDevices.map((device) => ({
            ...device,
            imgURL: device.imageURL,
            network: capitalize(device.networkLockStatus),
            grade: device.evaluation.grade,
            value: device.evaluation.deviceValue,
          }))}
          source={recycleType}
        />
      </Section>
      <Footer
        classes='main'
        copyright={false}
        nextBtnLabel={
          <Trans id='recycle.outcomes.continue.button'>Continue</Trans>
        }
        backBtnAction={navigateToEvaluation}
        backBtnLabel={
          <Trans id='recycle.outcomes.back.button'>
            Back to evaluation page
          </Trans>
        }
        nextBtnAction={finishRecycleProcess}
        nextBtnDisabled={disableContinueBtn()}
        backBtnDisabled={recycleCart}
      />
    </div>
  );
};

export default RecycleOutcomes;
