import { changeOrderPPAction } from 'actions/carts';
import {
  addDeviceReferenceToCartAction,
  finalizeCartAction,
} from 'actions/contract';
import { getSaleAction, updateResellerServices } from 'actions/sales';
import { openModalAction } from 'actions/ui';
import { currencyFormatter } from 'helpers/formatters';
import isEmpty from 'lodash/isEmpty';

import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';

import Button from 'components/Button';
import Heading from 'components/Heading';
import Modal from 'components/Modal';
import IconAlert from 'components/icons/IconAlert';
import IconApproved from 'components/icons/IconApproved';

import {
  CREDIT_APPROVED,
  PENDING_CREDIT_CHECK,
  PENDING_STATUS,
  REFUSED_STATUS,
} from 'constants/contracts';
import { IMEI } from 'constants/deviceReference';
import { cartPageUrl } from 'constants/routes';

import DeviceReferenceModal from './DeviceReferenceModal';
import './PendingActions.styl';
import SerialNumbersModal from './SerialNumbersModal';
import WithdrawContract from './WithdrawContract';

const PendingActions = ({
  cartUUID,
  contracts,
  currency,
  funderName,
  language,
  salesChannel,
  useAppleValidation,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isMissingDevRef = contracts.some(
    (contract) => contract?.steps?.hasDeviceReference === false,
  );
  const isMissingActivation = contracts.some(
    (contract) => contract?.steps?.isActive === false,
  );

  const contractsHaveCreditApplicationErrors = contracts.some(
    (contract) => contract?.steps?.hasCreditApplicationErrors,
  );

  const requiresSerialNumbers = contracts.some(
    (contract) => contract?.steps?.requiresSerialNumbers,
  );

  const canApplyForCredit = contracts.some(
    (contract) => contract?.steps?.canApplyForCredit,
  );

  const requiresInstallation = contracts.some(
    (contract) => contract?.steps?.requiresInstallation,
  );

  const isReferred = contracts.some((contract) => contract?.steps?.isReferred);

  const findExtrasContract = () =>
    contracts.find((contract) => isEmpty(contract.products));

  const isMissingExtrasCreditCheck = () =>
    findExtrasContract()?.statusLabel === PENDING_CREDIT_CHECK;

  const getExtrasDiscountsValue = () =>
    findExtrasContract()?.discounts?.reduce((acc, { value }) => acc + value, 0);

  const submitDeviceReference = async ({ deviceReference, referenceType }) => {
    await dispatch(
      addDeviceReferenceToCartAction(
        cartUUID,
        deviceReference,
        referenceType === IMEI,
      ),
    );
    await dispatch(getSaleAction(cartUUID));
  };

  const proceedToActive = () => {
    if (isMissingExtrasCreditCheck()) {
      dispatch(openModalAction('missing-extras-credit'));
    } else {
      activateCart();
    }
  };

  const activateCart = async () => {
    await dispatch(finalizeCartAction(cartUUID));
    await dispatch(getSaleAction(cartUUID));
  };

  return (
    <div className='pending-actions__container'>
      <Heading size='medium' classes='pending-actions__heading'>
        <Trans id='pending.actions.title'>Actions required!</Trans>
      </Heading>

      {contracts.map((contract, index) => {
        const isMainContract = !isEmpty(contract.products);

        return (
          <ul className='pending-actions__contract'>
            <li>
              <Heading
                size='small'
                dataTest={`posale-contract-product-name-${index + 1}`}
              >{`${index + 1}. ${
                isMainContract
                  ? contract.products
                      .map((product) => product.description)
                      .join(', ')
                  : t({ id: 'sale.contract.extras', message: `Extras` })
              }`}</Heading>
            </li>
            {isMainContract &&
              contract?.steps?.hasDeviceReference !== undefined &&
              (contract?.steps?.hasDeviceReference ? (
                <li>
                  <IconApproved className='pending-actions__icon--approved' />
                  <Trans id='pending.actions.device.reference.added'>
                    Device reference added!
                  </Trans>
                </li>
              ) : (
                <li>
                  <IconAlert className='pending-actions__icon--alert' />
                  <Trans id='pending.actions.missing.device.reference'>
                    Please enter device reference (IMEI or Serial number)
                  </Trans>
                </li>
              ))}
            {contract?.status === REFUSED_STATUS ? (
              <li>
                <IconAlert className='pending-actions__icon--error' />
                <Trans id='pending.actions.contract.refused'>
                  Contract refused
                </Trans>
              </li>
            ) : null}
            {contract?.status === PENDING_STATUS &&
            contract?.statusLabel === PENDING_CREDIT_CHECK ? (
              <li>
                <IconAlert className='pending-actions__icon--error' />
                <Trans id='pending.actions.missing.credit.check'>
                  Missing credit check!
                </Trans>
              </li>
            ) : null}
            {contract?.status === PENDING_STATUS &&
            contract?.statusLabel === CREDIT_APPROVED ? (
              <li>
                <IconAlert className='pending-actions__icon--alert' />
                <Trans id='pending.actions.missing.contract.activation'>
                  Missing contract activation!
                </Trans>
              </li>
            ) : null}
            {contract?.steps?.hasCreditApplicationErrors ? (
              <li>
                <IconAlert className='pending-actions__icon--alert' />
                <Trans id='pending.actions.credit.application.error'>
                  Error during credit application! Please contact support.
                </Trans>
              </li>
            ) : null}
            {contract?.steps?.isActive ? (
              <li>
                <IconApproved className='pending-actions__icon--approved' />
                <Trans id='pending.actions.contract.activated'>
                  Contract activated with {funderName}
                </Trans>
              </li>
            ) : null}
            {contract?.steps?.requiresSerialNumbers ? (
              <li>
                <IconAlert className='pending-actions__icon--alert' />
                <Trans id='pending.actions.requires.serial.numbers'>
                  Please add missing serial numbers.
                </Trans>
              </li>
            ) : null}
            {contract?.steps?.canApplyForCredit ? (
              <li>
                <IconAlert className='pending-actions__icon--alert' />
                <Trans id='pending.actions.can.apply.for.credit'>
                  Your quote was created you can review or apply for finance.
                </Trans>
              </li>
            ) : null}
            {contract?.steps?.requiresInstallation ? (
              <>
                <li>
                  <IconAlert className='pending-actions__icon--alert' />
                  <Trans id='pending.actions.requires.services.installation'>
                    You have services in your quote that requires installation.
                  </Trans>
                </li>
                <li>
                  <p>
                    {contract.extras
                      .filter((e) => e.requiresInstallation)
                      .map((e) => e.description)
                      .join(', ')}
                  </p>
                </li>
              </>
            ) : null}
          </ul>
        );
      })}

      {
        <div className='pending-actions__buttons'>
          {isMissingDevRef && !isReferred && (
            <DeviceReferenceModal
              buttonText={
                <Trans id='pending.actions.missing.device.reference.button'>
                  Add device reference
                </Trans>
              }
              testSelector='posale-contract-device-reference-btn'
              name='submitContractDeviceReference'
              onSubmit={submitDeviceReference}
              useAppleValidation={useAppleValidation}
            />
          )}

          <>
            {isMissingActivation && (
              <Button
                onClick={proceedToActive}
                testSelector='posale-contract-activate-btn'
                disabled={
                  (salesChannel === 'INSTORE' && isMissingDevRef) ||
                  isReferred ||
                  contractsHaveCreditApplicationErrors
                }
              >
                <Trans id='pending.actions.missing.contract.activation.button'>
                  Activate contract
                </Trans>
              </Button>
            )}

            {isMissingActivation && <WithdrawContract cartUUID={cartUUID} />}

            {requiresSerialNumbers && (
              <Button
                classes='contract-actions--right'
                onClick={() => dispatch(openModalAction('serial-numbers'))}
                data-test='contract-action-serial-numbers'
              >
                <Trans id='contract.action.missing.serial.numbers'>
                  Serial Numbers
                </Trans>
              </Button>
            )}

            {canApplyForCredit && (
              <Button
                classes='contract-actions--right'
                onClick={async () => {
                  await dispatch(changeOrderPPAction(cartUUID));
                  navigate(cartPageUrl(cartUUID));
                }}
                data-test='contract-action-apply-for-finance'
              >
                <Trans id='contract.action.apply.for.credit'>
                  Apply for Finance
                </Trans>
              </Button>
            )}
            {requiresInstallation && (
              <Button
                classes='contract-actions--right'
                onClick={async () => {
                  await dispatch(updateResellerServices(cartUUID));
                  await dispatch(getSaleAction(cartUUID));
                }}
                data-test='contract-action-mark-installed'
              >
                <Trans id='contract.action.mark.installed'>
                  Mark services as installed
                </Trans>
              </Button>
            )}
          </>
        </div>
      }

      <Modal
        name='missing-extras-credit'
        title={t({
          id: 'pending.actions.extras.credit.check.modal.title',
          message: `Extras credit application is not complete!`,
        })}
        onConfirm={activateCart}
      >
        <p>
          <Trans id='pending.actions.extras.credit.check.modal'>
            The extras credit application was rejected and will not be
            activated. By proceeding you are only activating the main device
            contract.
          </Trans>
        </p>
        {getExtrasDiscountsValue() > 0 && (
          <p>
            <Trans id='pending.actions.extras.credit.check.discounts.modal'>
              The customer has a total of discounts value off
              {currencyFormatter(
                getExtrasDiscountsValue(),
                currency,
                language,
              )}{' '}
              applied to the Extras​, which will be lost if you proceed with
              activating the main device contract only.​ If applicable, this
              discount must be refunded to the customer or they ​should ​receive
              store credit for this amount. Do you want to proceed with the
              activation?​
            </Trans>
          </p>
        )}
        <p>
          <Trans id='pending.actions.extras.credit.check.activation.modal'>
            Do you want to proceed with the activation?
          </Trans>
        </p>
      </Modal>

      <SerialNumbersModal />
    </div>
  );
};

export default PendingActions;
