import { openModalAction } from 'actions/ui';
import { ReactComponent as Info } from 'assets/images/info.svg';
import classNames from 'classnames';

import React from 'react';
import { useDispatch } from 'react-redux';

import { Trans } from '@lingui/macro';

import Heading from 'components/Heading';
import Modal from 'components/Modal';

import './HowToFindIMEI.styl';

export const HowToFindIMEI = ({ disabled }) => {
  const dispatch = useDispatch();

  return (
    <div className={classNames('howToFindIMEI', { disabled })}>
      <span
        className='howToFindIMEI__link'
        // TODO make this component reliant only on class functions
        // in order to remove arrow functions and enforce this rule
        // eslint-disable-next-line react/jsx-no-bind
        onClick={() => !disabled && dispatch(openModalAction('howToFindIMEI'))}
      >
        <Info className='icon__info-icon' />
        <Trans id='find.imei.modal.title'>How to find IMEI?</Trans>
      </span>

      <Modal classes='full' name='howToFindIMEI'>
        <div className='howToFindIMEI__modal'>
          <Heading size='medium'>
            <Trans id='find.imei.title'>How do I find the IMEI number?</Trans>
          </Heading>
          <div className='description'>
            <Trans id='find.imei.body'>
              <p>
                The IMEI number is a 15 digit serial number unique to every
                mobile phone.
              </p>
              <p>
                Depending on your model it may be located on the back of your
                phone or on the SIM tray.
              </p>
              <p>
                You can make your mobile display it's IMEI number by typing the
                following on the keypad: *#06#.
              </p>
              <p>
                If your mobile displays more than 15 digits or is separated by
                symbols, please enter only the first 15 digits.
              </p>
            </Trans>
          </div>

          <div className='instructions'>
            <div className='instructions__title'>
              <Trans id='imei.modal.instructions.back.mobile.title'>
                Back of Mobile
              </Trans>
            </div>
            <img
              src='https://cdn.financetech365.com/ft_generic/modal/back-of-mobile.png'
              alt='Back of mobile device'
            />
            <div className='instructions__body'>
              <Trans id='imei.modal.instructions.back.mobile.body'>
                Check for a label or printed text on the back of the phone or
                under the cover.
              </Trans>
            </div>
          </div>

          <div className='instructions'>
            <div className='instructions__title'>
              <Trans id='imei.modal.instructions.sim.title'>SIM Tray</Trans>
            </div>
            <img
              src='https://cdn.financetech365.com/ft_generic/modal/sim-tray.png'
              alt='Sim tray'
            />
            <div className='instructions__body'>
              <Trans id='imei.modal.instructions.sim.body'>
                Eject the SIM and it should be printed on the SIM tray.
              </Trans>
            </div>
          </div>

          <div className='instructions'>
            <div className='instructions__title'>
              <Trans id='imei.modal.instructions.imei.title'>
                Display IMEI
              </Trans>
            </div>
            <img
              src='https://cdn.financetech365.com/ft_generic/modal/display-imei.png'
              alt='Display IMEI'
            />
            <div className='instructions__body'>
              <Trans id='imei.modal.instructions.imei.body'>
                Enter *#06# on the keypad to display the IMEI number.
              </Trans>
            </div>
          </div>
          <Heading size='medium'>
            <Trans id='find.serial.title'>How to find serial number?</Trans>
          </Heading>
          <div className='description'>
            <Trans id='find.serial.body'>
              <p>
                A serial number is a unique identifier assigned incrementally or
                sequentially to an item, to uniquely identify it.
              </p>
              <p>
                Serial numbers need not to be strictly numerical. They may
                contain letters and other typographical symbols, or may consist
                entirely of a character string.
              </p>
            </Trans>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default HowToFindIMEI;
