import React from 'react';

const SvgIconCopy = ({ title, ...props }) => (
  <svg viewBox='0 0 48 48' width='1em' height='1em' {...props}>
    {title ? <title>{title}</title> : null}
    <defs>
      <clipPath id='icon-copy'>
        <path d='M0 0h48v48H0z' />
      </clipPath>
    </defs>
    <g
      id='copy_to_clipboard'
      data-name='copy to clipboard'
      clip-path='url(#clip-copy_to_clipboard)'
    >
      <g id='Icon_Copy_Ghost' data-name='Icon / Copy / Ghost'>
        <rect
          id='Icon_container'
          data-name='Icon container'
          width='40'
          height='40'
          fill='#fff'
          opacity='0'
        />
        <path
          id='Union_1'
          data-name='Union 1'
          d='M2.4,24A2.33,2.33,0,0,1,0,21.6V9.6A2.331,2.331,0,0,1,2.4,7.2H7.2V2.4A2.331,2.331,0,0,1,9.6,0h12A2.331,2.331,0,0,1,24,2.4v12a2.33,2.33,0,0,1-2.4,2.4H16.8v4.8A2.33,2.33,0,0,1,14.4,24Z'
          transform='translate(8 8)'
          fill='#fff'
          stroke='rgba(0,0,0,0)'
          stroke-miterlimit='10'
          stroke-width='1'
        />
        <path
          id='icon'
          d='M2.4,24A2.33,2.33,0,0,1,0,21.6V9.6A2.33,2.33,0,0,1,2.4,7.2H7.2V2.4A2.32,2.32,0,0,1,9.6,0h12A2.32,2.32,0,0,1,24,2.4v12a2.321,2.321,0,0,1-2.4,2.4H16.8v4.8A2.331,2.331,0,0,1,14.4,24Zm0-2.4h12V16.8H9.6a2.321,2.321,0,0,1-2.4-2.4V9.6H2.4Zm7.2-7.2h12V2.4H9.6Z'
          transform='translate(8 8)'
          fill='#333'
        />
      </g>
    </g>
  </svg>
);

export default SvgIconCopy;
