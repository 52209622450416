import { UPDATE_BUILD_HASH } from 'actions/app';

import initialState from 'reducers/initialState';

export default function (state = initialState.carts, action) {
  switch (action.type) {
    case UPDATE_BUILD_HASH:
      return {
        ...state,
        buildHash: action.buildHash,
      };
    default:
      return state;
  }
}
