import { getCollectionDetailsAction } from 'actions/admin/collection';
import print from 'assets/images/print.svg';

import React, { useEffect } from 'react';
import Barcode from 'react-barcode';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';

import Button from 'components/Button';
import Loader from 'components/Loader';
import RenderDetails from 'components/RenderDetails';
import Table from 'components/table/Table';

import { config } from 'constants/dl';

import useTrackPage from 'hooks/useTrackPage';

import './CollectionDetails.styl';

const CollectionDetails = () => {
  useTrackPage('CollectionDetails');

  const { collectionUUID } = useParams();
  const dispatch = useDispatch();

  const loading = useSelector(({ ui }) => ui.loading);
  const collection = useSelector(
    ({ adminCollection }) => adminCollection.collection,
  );

  const recycleBarCodeFormat = useSelector(
    ({ whitelabel }) => whitelabel.configs?.recycleBarCodeFormat,
  );

  useEffect(() => {
    dispatch(getCollectionDetailsAction(collectionUUID));
  }, [collectionUUID, dispatch]);

  const {
    collectedDate,
    courierURL,
    devices,
    id,
    scheduleDate,
    barcodeReference,
  } = collection;

  const openCourierURL = () => window.open(courierURL, '_blank');
  const printCollectionDetails = () => window.print();

  if (loading) return <Loader />;

  const columns = [
    {
      label: t({ id: 'table.column.code', message: `Code` }),
      accessor: 'barcodeReference',
      type: 'barcode',
      format: recycleBarCodeFormat,
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.quote.id', message: `Quote ID` }),
      accessor: 'orderId',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.imei.or.serial',
        message: `IMEI / Serial No.`,
      }),
      accessor: 'imei',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.model', message: `Model` }),
      accessor: 'deviceName',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'left',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.condition', message: `Condition` }),
      accessor: 'condition',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'left',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.devide.offered.value',
        message: `Device offered value`,
      }),
      accessor: 'valueOffered',
      type: 'currency',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
  ];

  return (
    <div className='collection-details'>
      <h1 className='title'>
        {<Trans id='collection.title'>Collection</Trans>} {`${id}`}
      </h1>

      {courierURL && (
        <Button
          classes='collection-details__btn-courier'
          onClick={openCourierURL}
        >
          <Trans id='collection.courier.btn'>Collection courier</Trans>
        </Button>
      )}
      <Button
        classes='collection-details__btn-print'
        onClick={printCollectionDetails}
      >
        <img src={print} alt='print' />
      </Button>

      <Barcode
        height={40}
        value={barcodeReference}
        options={{ format: recycleBarCodeFormat, textMargin: 0 }}
      />

      <RenderDetails
        classes='inline date collection-details-dates'
        config={config.datesCollection}
        data={{ collectedDate, scheduleDate }}
      />

      <RenderDetails
        classes='no-border store-list'
        config={config.store}
        data={collection.store}
        title={t({
          id: 'collection.store.title',
          message: `Collection Store`,
        })}
      />

      <Table columns={columns} data={devices} />
    </div>
  );
};

CollectionDetails.defaultProps = { collection: {} };

export default CollectionDetails;
