import { clearCustomerAction } from 'actions/customer';
import { clearRecycleAction, getRecycleReceiptAction } from 'actions/recycle';
import isEmpty from 'lodash/isEmpty';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { t } from '@lingui/macro';

import Footer from 'components/Footer';
import Loader from 'components/Loader';

import useTrackPage from 'hooks/useTrackPage';

import Receipt from './components/Receipt';
import ReceiptPrint from './components/ReceiptPrint';

const RecycleDetails = () => {
  useTrackPage('RecycleDetails');
  const { tradecartID } = useParams();
  const dispatch = useDispatch();

  const [showReceiptPrint, setShowReceiptPrint] = useState(false);

  const receipt = useSelector(({ recycle }) => recycle.receipt);
  const loading = useSelector(({ ui }) => ui.loading);

  useEffect(() => {
    async function clearData() {
      await dispatch(clearRecycleAction());
      await dispatch(clearCustomerAction());
    }

    clearData();
    dispatch(getRecycleReceiptAction(tradecartID));
  }, [tradecartID, dispatch]);

  if (isEmpty(receipt) || loading) return <Loader />;

  return (
    <>
      {!showReceiptPrint ? (
        <Receipt
          tradecartID={tradecartID}
          toggleReceiptPrint={() => setShowReceiptPrint(true)}
        />
      ) : (
        <ReceiptPrint tradecartID={tradecartID} />
      )}
      <Footer
        classes='main'
        copyright={false}
        backBtnAction={
          showReceiptPrint ? () => setShowReceiptPrint(false) : null
        }
        backBtnLabel={t({ id: 'button.back', message: `Back` })}
      />
    </>
  );
};

export default RecycleDetails;
