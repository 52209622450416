import React from 'react';

const IconTradeinDevice = ({ title, ...props }) => (
  <svg width='32' height='32' viewBox='0 0 32 32' {...props}>
    <g id='tradein-ico' clipPath='url(#clip-tradein-ico)'>
      <rect width='32' height='32' fill='#fff' />
      <path
        id='Path'
        d='M6.924,3.787,4.293,1.138,5.431,0,9.218,3.787,5.431,7.575,4.293,6.437Z'
        transform='translate(20.782 17)'
        fill='#313131'
      />
      <g
        id='Mask_Group_1'
        data-name='Mask Group 1'
        transform='translate(7.552 16)'
        clipPath='url(#clip-path)'
      >
        <g
          id='_24_gadgets_iPhone'
          data-name='24 / gadgets / iPhone'
          transform='translate(2.816 -8.046)'
        >
          <path
            id='icon'
            d='M9.655,17.7H1.609A1.611,1.611,0,0,1,0,16.092V1.609A1.611,1.611,0,0,1,1.609,0H9.655a1.611,1.611,0,0,1,1.61,1.609V16.092A1.611,1.611,0,0,1,9.655,17.7ZM1.609,1.609V16.092H9.655V1.609H7.241a.806.806,0,0,1-.8.8H4.828a.805.805,0,0,1-.8-.8Z'
            transform='translate(0 0)'
            fill='#313131'
          />
        </g>
        <circle
          id='Path-2'
          data-name='Path'
          cx='1'
          cy='1'
          r='1'
          transform='translate(7.448 5)'
        />
      </g>
      <g
        id='Mask_Group_2'
        data-name='Mask Group 2'
        transform='translate(8.356 7)'
        clipPath='url(#clip-path-2)'
      >
        <g
          id='_24_gadgets_iPhone-2'
          data-name='24 / gadgets / iPhone'
          transform='translate(2.012 0)'
        >
          <path
            id='icon-2'
            data-name='icon'
            d='M9.655,17.7H1.609A1.611,1.611,0,0,1,0,16.092V1.609A1.611,1.611,0,0,1,1.609,0H9.655a1.611,1.611,0,0,1,1.61,1.609V16.092A1.611,1.611,0,0,1,9.655,17.7ZM1.609,1.609V16.092H9.655V1.609H7.241a.806.806,0,0,1-.8.8H4.828a.805.805,0,0,1-.8-.8Z'
            transform='translate(0 0)'
            fill='#313131'
          />
        </g>
      </g>
      <path
        id='Path-3'
        data-name='Path'
        d='M2.631,3.787,0,1.138,1.138,0,4.925,3.787,1.138,7.575,0,6.437Z'
        transform='translate(6.925 16.574) rotate(180)'
        fill='#313131'
      />
    </g>
  </svg>
);

export default IconTradeinDevice;
