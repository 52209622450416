import AbstractDTO from 'DTOs/AbstractDTO';

const deviceValidator = (raw) => {
  return {
    cashPrice: raw.cashPrice ?? null,
    category: raw.category ?? '',
    categoryName: raw.categoryName ?? '',
    color: raw.color ?? '',
    colorCode: raw.colorCode ?? '',
    colorName: raw.colorName ?? '',
    countryCode: raw.countryCode ?? '',
    createdAt: raw.createdAt ?? null,
    currency: raw.currency ?? '',
    deviceCode: raw.deviceCode ?? '',
    deviceTypeCode: raw.deviceTypeCode ?? '',
    deviceTypeName: raw.deviceTypeName ?? '',
    family: raw.family ?? '',
    familyName: raw.familyName ?? '',
    id: raw.id ?? raw.deviceID ?? null,
    isAssociated: raw.isAssociated ?? false,
    manufacturer: raw.manufacturer ?? '',
    manufacturerID: raw.manufacturerID ?? null,
    manufacturerName: raw.manufacturerName ?? '',
    memory: raw.memory ?? '',
    modelID: raw.modelID ?? null,
    msrp: raw.msrp ?? null,
    name: raw.name ?? '',
    screenName: raw.screenName ?? '',
    screenCode: raw.screenCode ?? '',
    status: raw.status ?? 0,
    storageCode: raw.storageCode ?? '',
    storageName: raw.storageName ?? '',
    tags: raw.tags ?? '',
    type: raw.type ?? null,
    updatedAt: raw.updatedAt ?? null,
    url: raw.url ?? '',
    wlDeviceID: raw.wlDeviceID ?? null,
    wlID: raw.wlID ?? null,
    wlTags: raw.wlTags ?? '',
    wlUrl: raw.wlUrl ?? '',
  };
};

export default AbstractDTO(deviceValidator);
