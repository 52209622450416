import React from 'react';

import Footer from 'components/Footer';

import { ALL_ROLES } from 'constants/user';

import usePermission from 'hooks/usePermission';

const AdminPage = ({
  component: Component,
  allowedRoles = ALL_ROLES,
  ...props
}) => {
  const { hasAuthorization, renderUnauthorized } = usePermission(allowedRoles);

  if (!hasAuthorization) return renderUnauthorized();

  return (
    <div id='admin'>
      <Component {...props} />

      <Footer classes='main' copyright={false} />
    </div>
  );
};

export default AdminPage;
