import { t } from '@lingui/macro';

export const ADMIN = 1;
export const VENDOR_MANAGER = 5;
export const STORE_MANAGER = 10;
export const STORE_PERSON = 11;

export const ADMIN_WHITELIST = [ADMIN, VENDOR_MANAGER, STORE_MANAGER];
export const ALL_ROLES = [ADMIN, VENDOR_MANAGER, STORE_MANAGER, STORE_PERSON];

export const roles = (role) => {
  switch (role) {
    case ADMIN:
      return [
        {
          value: VENDOR_MANAGER,
          label: t({ id: 'role.vendor.manager', message: `Vendor Manager` }),
        },
        {
          value: STORE_MANAGER,
          label: t({ id: 'role.store.manager', message: `Store Manager` }),
        },
        {
          value: STORE_PERSON,
          label: t({ id: 'role.store.person', message: `Store Person` }),
        },
      ];
    case VENDOR_MANAGER:
      return [
        {
          value: VENDOR_MANAGER,
          label: t({ id: 'role.vendor.manager', message: `Vendor Manager` }),
        },
        {
          value: STORE_MANAGER,
          label: t({ id: 'role.store.manager', message: `Store Manager` }),
        },
        {
          value: STORE_PERSON,
          label: t({ id: 'role.store.person', message: `Store Person` }),
        },
      ];
    case STORE_MANAGER:
      return [
        {
          value: STORE_MANAGER,
          label: t({ id: 'role.store.manager', message: `Store Manager` }),
        },
        {
          value: STORE_PERSON,
          label: t({ id: 'role.store.person', message: `Store Person` }),
        },
      ];
    default:
      return [
        {
          value: STORE_PERSON,
          label: t({ id: 'role.store.person', message: `Store Person` }),
        },
      ];
  }
};

// this is used for mapping roleName to roleID
export const allRolesList = roles(ADMIN).reduce(
  (acc, item) => ({ ...acc, [item.value]: { label: item.label } }),
  {},
);

export const userStatus = { inactive: 0, active: 1, pending: 2, blocked: 3 };
