import React from 'react';

const ThreadPoolTable = ({ item }) => {
  const renderThreadPoolTable = (item) => {
    return (
      <table className='MethodTable'>
        <colgroup>
          <col width='14%;' />
          <col width='14%;' />
          <col width='14%;' />
          <col width='14%;' />
          <col width='14%;' />
          <col width='14%;' />
          <col width='14%;' />
        </colgroup>
        <tbody>
          <tr className='stripe_even'>
            <th>Start Size</th>
            <th>Current Size</th>
            <th>Max Size</th>
            <th>Active Count</th>
            <th>Completed Count</th>
            <th>Task Count</th>
            <th>Terminated</th>
          </tr>
          <tr className='stripe_odd'>
            <td>{item.corePoolSize}</td>
            <td>{item.poolSize}</td>
            <td>{item.largestPoolSize}</td>
            <td>{item.activeCount}</td>
            <td>{item.completedTaskCount}</td>
            <td>{item.taskCount}</td>
            <td>{item.isTerminating}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <div>
      <h1>Thread Pool</h1>
      {renderThreadPoolTable(item)}
      <br />
    </div>
  );
};

export default ThreadPoolTable;
