import { getTradecartStatusAction } from 'actions/recycle';
import { openModalAction } from 'actions/ui';
import withPolling from 'enhancers/withPolling';
import store from 'store';

import React, { Fragment, useEffect, useState } from 'react';

import CreditStep from 'components/CreditStep';
import Modal from 'components/Modal';

export const TradeSteps = ({ steps = {}, stopPolling }) => {
  const [clientRefused, setClientRefused] = useState(false);

  useEffect(() => {
    // The polling keeps happening if both steps are approved to give the user the chance
    // to update their ID picture
    if (Object.values(steps).some((step) => step === 'REFUSED')) {
      stopPolling();
      setClientRefused(true);
    }
  }, [steps, stopPolling]);

  const openImageModal = () =>
    store.dispatch(openModalAction('recycle-client-id'));

  return (
    <div>
      {clientRefused ? (
        <CreditStep type='tradeRefused' status='REFUSED' />
      ) : (
        <Fragment>
          <CreditStep type='tradeReview' status={steps.isCustomerApproved} />

          <CreditStep type='tradeIDUpload' status={steps.hasIdPhoto} />

          {steps.idPhoto && (
            <img
              alt='id'
              className='trade-steps__id-photo'
              src={steps.idPhoto}
              onClick={openImageModal}
            />
          )}

          <Modal name='recycle-client-id'>
            <img src={steps.idPhoto} alt='recycle-client-id' />
          </Modal>
        </Fragment>
      )}
    </div>
  );
};

export default withPolling(TradeSteps, {
  callback: getTradecartStatusAction,
  interval: 10000,
  hasDispatch: true,
});
