import classNames from 'classnames';
import { formatDateForDatepicker, stringToDate } from 'helpers/formatters';
import { isValidDate } from 'helpers/validators';
import isEmpty from 'lodash/isEmpty';

import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import InputHelperText from 'components/InputHelperText';

const RenderDatePicker = ({
  className,
  classes,
  disabled = false,
  endDate,
  initialDate = null,
  input,
  helpText = '',
  notificationPos = 'bottom',
  label = 'yyyy-MM-dd',
  meta: { touched, error, active },
  maxDate,
  minDate,
  startDate,
  includeDates,
  selectsStart,
  selectsEnd,
  variant = 'underline',
  rangeOptions = {},
}) => {
  const getSelectedDate = () => {
    if (!isEmpty(rangeOptions)) {
      if (input.value) {
        let inputDates = input.value.split(' - ');
        let newDate = new Date(inputDates[0]);

        return isValidDate(newDate) ? newDate : !touched && initialDate;
      }
    } else {
      let newDate = new Date(input?.value);
      return isValidDate(newDate) ? newDate : !touched && initialDate;
    }
  };
  const selected = getSelectedDate();

  // '!!' added to avoid Warning: Failed prop type: Invalid prop `showError` of type `string`
  // supplied to `<<anonymous>>`, expected `boolean`.
  // TODO: Fix it in other uikit components, such as Dropdown
  const showError = !active && touched && !!error;

  const { value, ...inputProps } = input;

  const handleChange = (date) => {
    if (date) {
      if (!isEmpty(rangeOptions)) {
        const [start, end] = date;
        input.onChange(
          `${formatDateForDatepicker(start)} - ${formatDateForDatepicker(end)}`,
        );
      } else if (isValidDate(date)) {
        input.onChange(
          formatDate(
            date.setMinutes(date.getMinutes() - date.getTimezoneOffset()),
          ),
        );
      }
    }
  };

  return (
    <div className={classNames('input__wrapper', classes)}>
      <div
        className={classNames('datepicker', className, variant, {
          disabled,
        })}
      >
        <DatePicker
          {...inputProps}
          {...rangeOptions}
          utcOffset={0}
          selected={selected}
          onChange={handleChange}
          startDate={stringToDate(startDate)}
          endDate={stringToDate(endDate)}
          minDate={stringToDate(minDate)}
          maxDate={stringToDate(maxDate)}
          includeDates={includeDates?.map((dateString) => new Date(dateString))}
          dateFormat={'yyyy-MM-dd'}
          selectsStart={selectsStart}
          selectsEnd={selectsEnd}
          disabled={disabled}
          autoComplete='off'
        />
        <label
          className={classNames('input__floating-label', {
            active: selected || active,
          })}
        >
          {label}
        </label>
      </div>
      <InputHelperText
        showError={showError}
        helpText={helpText}
        errorText={error}
        notificationPos={notificationPos}
        inputName={label}
      />
    </div>
  );
};

export default RenderDatePicker;

export const formatDate = (date) => {
  return formatDateForDatepicker(date);
};
