import { changeStoreAction, logoutAction } from 'actions/user';
import { ReactComponent as UserMenu } from 'assets/images/user_menu.svg';
import { Field, reduxForm } from 'redux-form';

import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';

import Dropdown from 'components/Dropdown';
import LanguageSwitcher from 'components/LanguageSwitcher';
import Logo from 'components/Logo';

import { mainNav } from 'constants/navigation';
import { changePasswordUrl, loginPageUrl, rootUrl } from 'constants/routes';
import { STORE_PERSON } from 'constants/user';

import DropdownMenu from './DropdownMenu';
import './Header.styl';

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);

  const currentCart = useSelector(({ carts }) => carts.currentCart);
  const canChangeLanguage = useSelector(
    ({ whitelabel }) => whitelabel.configs.canChangeLanguage,
  );
  const documentation = useSelector(
    ({ whitelabel }) => whitelabel.configs.documentation,
  );
  const hasSingleSignOn = useSelector(
    ({ whitelabel }) => whitelabel.configs.hasSingleSignOn,
  );
  const menuConfigs = useSelector(
    ({ whitelabel }) => whitelabel.configs.menuConfigs,
  );
  const serviceLink = useSelector(
    ({ whitelabel }) => whitelabel.configs.serviceLink,
  );
  const wlShortName = useSelector(({ whitelabel }) => whitelabel.shortName);
  const user = useSelector(({ user }) => user);

  const showMenu = (event) => {
    event.preventDefault();

    setVisible(true);

    document.addEventListener('click', closeMenu);
  };

  const closeMenu = (event) => {
    event.preventDefault();
    setVisible(false);
    document.removeEventListener('click', closeMenu);
  };

  const logout = async (event) => {
    event.preventDefault();

    await dispatch(logoutAction());
    navigate(loginPageUrl);
  };

  const openServiceLink = (event, serviceLink) => {
    event.preventDefault();

    window.open(serviceLink);
  };

  const openServicePDF = (event) => {
    event.preventDefault();

    window.open('https://cdn.financetech365.com/ft/service_other.pdf');
  };

  const openUserManual = (event) => {
    event.preventDefault();

    window.open(`https://cdn.financetech365.com/ft/manual_${wlShortName}.pdf`);
  };

  const serviceMenu = [
    {
      label: t({
        id: 'navigation.service.links.phones',
        message: `Smartphones`,
      }),
      onClick: (event) => openServiceLink(event, serviceLink),
      minRole: STORE_PERSON,
    },
    {
      label: t({ id: 'navigation.service.links.other', message: `Other` }),
      onClick: openServicePDF,
      minRole: STORE_PERSON,
    },
  ];

  return (
    <header className='b2bv2'>
      <div className='header-inner-container'>
        <div data-test='posale-header-logo'>
          <Logo version='primary' linkTo={rootUrl} />

          <span className='header-inner-container-divider' />

          <form>
            <Field
              name='storeId'
              component={Dropdown}
              options={Object.entries(user.stores)
                .reduce(
                  (all, [value, label]) => [
                    ...all,
                    { value: parseInt(value, 10), label },
                  ],
                  [],
                )
                .sort((a, b) => (a.label > b.label ? 1 : -1))}
              onChange={(store) =>
                dispatch(changeStoreAction(store, currentCart))
              }
              classes='borderless'
              variant='underline'
              testSelector='store-id'
            />
          </form>
        </div>

        <div>
          {canChangeLanguage && (
            <Fragment>
              <LanguageSwitcher className='borderless' />
              <span className='header-inner-container-divider' />
            </Fragment>
          )}

          <div
            className='settings-menu'
            onClick={showMenu}
            data-test='posale-header-username'
          >
            <span>{`${user.name} / ${
              user.username ||
              t({ id: 'no.username.label', message: `No Username` })
            }`}</span>

            <UserMenu className='user-menu' />

            {visible && (
              <ul>
                <li
                  onClick={openUserManual}
                  data-test='posale-header-usermanual'
                >
                  <Trans id='settings.usermanual'>User manual</Trans>
                </li>
                {!hasSingleSignOn && (
                  <li>
                    <Link
                      to={changePasswordUrl}
                      data-test='posale-header-change-password'
                    >
                      <Trans id='settings.change.pwd'>Change Password</Trans>
                    </Link>
                  </li>
                )}
                <li onClick={logout} data-test='posale-header-logout'>
                  <Trans id='settings.logout'>Logout</Trans>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>

      <nav className='navigation'>
        <div className='header-inner-container'>
          {Object.values(mainNav(menuConfigs, documentation)).map((entry) => {
            if (entry.isActive) {
              if (user.role <= entry.minRole) {
                return entry.path ? (
                  entry.isMigrated ? (
                    <a
                      key={entry.id}
                      data-test={`posale-header-${entry.id}-link`}
                      onClick={() => (window.location.href = entry.path)}
                    >
                      {entry.label}
                    </a>
                  ) : (
                    <Link
                      key={entry.id}
                      to={entry.path}
                      data-test={`posale-header-${entry.id}-link`}
                    >
                      {entry.label}
                    </Link>
                  )
                ) : (
                  <DropdownMenu
                    title={entry.label}
                    key={entry.id}
                    testSelector={`posale-header-${entry.id}-dropdown`}
                    options={Object.values(entry.options).filter(
                      (entry) => entry.isActive === true,
                    )}
                    role={user.role}
                  />
                );
              } else return null;
            } else return null;
          })}
          {serviceLink && (
            <DropdownMenu
              testSelector='posale-header-service'
              title={t({
                id: 'navigation.service.links.title',
                message: `Service`,
              })}
              key='service'
              options={serviceMenu}
              role={user.role}
            />
          )}
        </div>
      </nav>
    </header>
  );
};

export default reduxForm({
  form: 'selectStore',
  enableReinitialize: true,
  destroyOnUnmount: false,
})(Header);
