import classNames from 'classnames';

import React from 'react';

import { Trans } from '@lingui/macro';

const CartOptions = ({ type, options, formatPrice, onClick, actionable }) => {
  return (
    <div id={type}>
      <ul>
        {options?.map((option) => (
          <li
            key={option.id}
            onClick={() =>
              !option.selected &&
              option.enabled &&
              actionable &&
              onClick &&
              onClick(option)
            }
            className={classNames(
              `${option.selected ? 'selected' : ''}`,
              `${option.enabled ? '' : 'disabled'}`,
            )}
          >
            <div>
              <p>{option.name}</p>
              <p>
                <span>{option.tagline}</span>
              </p>
              <p>
                {option.price_difference >= 0 ? '+ ' : ''}
                {`${formatPrice(option.price_difference)} `}
                <span>
                  <Trans id='extras.per.month'>per month</Trans>
                </span>
              </p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CartOptions;
