import React from 'react';

import WLIcon from './WLIcon';

const APICallsTable = ({ items, wls, className }) => {
  const renderAPICallsRow = (item, index, stripe, wl) => {
    return (
      <tr key={index} className={stripe}>
        <td>{item.time}</td>
        <td>
          <WLIcon item={wl} index={index} />
        </td>
        <td>{item.endpoint}</td>
        <td>{item.request}</td>
        <td>{item.numSuccess}</td>
        <td>{item.numRefused}</td>
        <td>{item.numFail}</td>
      </tr>
    );
  };

  return (
    <table className={`StatsTable ${className}`}>
      <colgroup>
        <col width='50px;' />
        <col width='30px;' />
        <col width='160px;' />
        <col width='170px;' />
        <col width='70px;' />
        <col width='70px;' />
        <col width='70px;' />
      </colgroup>
      <tbody>
        <tr>
          <th colSpan='7'>
            <span className='StatsTable_caption'>API Calls</span>
          </th>
        </tr>

        <tr className='StatsTable_innerHeader'>
          <td>Last</td>
          <td colSpan='1' />
          <td>Endpoint</td>
          <td>Message</td>
          <td>Success</td>
          <td>Refused</td>
          <td>Fail</td>
        </tr>

        {items.map((item, index) => {
          var wl = wls.find(function (element) {
            return element.wlID === item.wlID;
          }, item.wlID);
          var stripe = index % 2 === 1 ? 'stripe_even' : 'stripe_odd';
          var rows = [];
          rows.push(renderAPICallsRow(item, index, stripe, wl));
          return rows;
        })}

        {items.length === 0 && (
          <tr>
            <td colSpan='7' className='StatsTable_none'>
              No API Calls
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default APICallsTable;
