import { currencyFormatter } from 'helpers/formatters';
import PropTypes from 'prop-types';
import { getCurrentCart } from 'utils';

import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { t } from '@lingui/macro';

import Heading from 'components/Heading';
import IconChecked from 'components/icons/IconChecked';

import './Multistep.styl';

const Multistep = ({ step, title, total, showCompletedSteps = false }) => {
  const { cartUUID } = useParams();
  const cart = useSelector(({ carts }) => getCurrentCart(carts, cartUUID));
  const currency = useSelector(({ whitelabel }) => whitelabel.currency);
  const language = useSelector(({ whitelabel }) => whitelabel.language);

  const drawSpan = (step, cx, dataTest) => (
    <span className={`multistep__circle ${cx}`} data-test={dataTest} key={step}>
      {step}
    </span>
  );
  return (
    <div className='multistep' data-test='multistep'>
      <div>
        {showCompletedSteps &&
          step &&
          [...Array(1 + step).keys()]
            .slice(1, step)
            .map(() =>
              drawSpan(
                <IconChecked />,
                'multistep__currentStep',
                'current-step-number',
              ),
            )}
        {step &&
          drawSpan(step, 'multistep__currentStep', 'current-step-number')}

        {title && (
          <Heading size='big' data-test='current-step-title'>
            {title}
          </Heading>
        )}
        {total &&
          [...Array(total + 1).keys()]
            .slice(step + 1, total + 1)
            .reverse()
            .map((step) =>
              drawSpan(step, 'multistep__missingStep', 'missing-step'),
            )}
      </div>

      {/*
      This creates an array with total+1 items and fills it with the
      indexes. Then the array is sliced from step+1 to total+1 and that array is
      reversed since we're using float right in CSS which inverts the order by
      which the items are shown. Example:

      For total = 4 and step = 2
      [...Array(total+1).keys()] -> [0, 1, 2, 3, 4]
      .slice(step+1, total+1)    -> [3, 4]
      .reverse()                 -> [4, 3]
    */}
      {cart?.tradecartID && (
        <div className='recycle_indicator'>
          {t({
            id: 'recycle.outcome.indicator',
            message: `${currencyFormatter(
              cart.totalRecycleAmount,
              currency,
              language,
            )} trade in for ${cart.recycledDeviceNames.join(
              ', ',
            )} added to the cart`,
          })}
        </div>
      )}
    </div>
  );
};

Multistep.propTypes = {
  step: PropTypes.number,
  total: PropTypes.number,
  title: PropTypes.string,
};

export default Multistep;
