import {
  clearDashboardData,
  saveSocketConfig,
  saveSocketMessage,
  saveSocketStatus,
} from 'actions/dashboard';
import queryString from 'query-string';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import DashboardHeader from 'components/DashboardHeader';
import WebSocket from 'components/WebSocket';

import './Dashboard.styl';
import APICallsTable from './components/APICallsTable';
import APIErrorsTable from './components/APIErrorsTable';
import ActiveUsers from './components/ActiveUsers';
import Counters from './components/Counters';
import CreditDataTable from './components/CreditDataTable';
import CreditDataTableMobile from './components/CreditDataTableMobile';
import DateSelector from './components/DateSelector';
import SalesGraph from './components/SalesGraph';
import SalesTable from './components/SalesTable';
import StoreCountersTable from './components/StoreCountersTable';
import TradeInsTable from './components/TradeInsTable';
import UserErrors from './components/UserErrors';

class Dashboard extends Component {
  state = { date: '', uid: '' };

  componentDidMount() {
    const { saveSocketConfig, sessionToken } = this.props;
    const values = queryString.parse(window.location.search);
    const { date, uid } = this.state;

    this.setState({
      date: this.formatDate(values.date),
      uid: values.uid || `SESSION_${sessionToken}`,
    });

    if (uid && date) saveSocketConfig(uid, date);
  }

  componentDidUpdate(_, prevState) {
    const values = queryString.parse(window.location.search);
    const { date, uid } = this.state;

    if (
      values.date &&
      values.uid &&
      (prevState.date !== values.date || prevState.uid !== values.uid)
    ) {
      this.setState({
        date: this.formatDate(values.date),
        uid: values.uid,
      });
      this.props.saveSocketConfig(uid, date);
    }
  }

  formatDate = (date = '') => {
    if (date.length !== 10) {
      return new Date().toISOString().substring(0, 10);
    }

    return date;
  };

  handleData = (message) => {
    try {
      this.props.saveSocketMessage(JSON.parse(message));
      this.refWebSocket.sendMessage('OK');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Malformed message received');
    }
  };

  handleOpen = () => {
    this.props.clearDashboardData();
    this.props.saveSocketStatus('Connected');
    this.refWebSocket.sendMessage('DASHBOARD');
  };

  handleClose = () => {
    this.props.saveSocketStatus('Disconnected');
  };

  render() {
    const {
      socketStatus,
      today,
      counters,
      sales,
      onlinePendings,
      instorePendings,
      withdrawn,
      refusals,
      cancels,
      wls,
      creditData,
      activeUsers,
      userErrors,
      dailySales,
      monthlySales,
      dailyTradeIns,
      monthlyTradeIns,
      apiErrors,
      apiCalls,
      storeCounters,
      tradeIns,
      swaps,
    } = this.props;
    const { date, uid } = this.state;

    return (
      <div className='dashboard'>
        {uid && date && (
          <WebSocket
            url={`${process.env.REACT_APP_STATS_URL}/${uid}/${date}`}
            onMessage={this.handleData}
            onOpen={this.handleOpen}
            onClose={this.handleClose}
            reconnect={true}
            debug={true}
            // TODO make this component reliant only on class functions
            // in order to remove arrow functions and enforce this rule
            // eslint-disable-next-line react/jsx-no-bind
            ref={(Websocket) => {
              this.refWebSocket = Websocket;
            }}
          />
        )}

        <DashboardHeader socketStatus={socketStatus} />

        {date && <DateSelector uid={uid} date={date} today={today} />}

        <Counters item={counters} />
        <div id='creditData'>
          <CreditDataTable items={creditData} wls={wls} />
          <CreditDataTableMobile items={creditData} wls={wls} />
        </div>
        <div id='sales'>
          <SalesTable
            items={sales}
            wls={wls}
            title='Sales Completed Today'
            emptyText='No Sales'
          />
        </div>
        <div id='online'>
          <SalesTable
            items={onlinePendings}
            wls={wls}
            title='Online Sales Pending Activation Today'
            emptyText='No Online Sales Pending Activation'
          />
        </div>
        <div id='instorePendings'>
          <SalesTable
            items={instorePendings}
            wls={wls}
            title='In-Store Sales Pending Activation Today'
            emptyText='No In-Store Sales Pending Activation'
          />
        </div>
        <div id='refusals'>
          <SalesTable
            items={refusals}
            wls={wls}
            title='Credit Refusals Today'
            emptyText='No Credit Refusals'
          />
        </div>
        <div id='cancels'>
          <SalesTable
            items={cancels}
            wls={wls}
            title='Cancellations Today'
            emptyText='No Cancellations'
          />
        </div>
        <div id='withdrawns'>
          <SalesTable
            items={withdrawn}
            wls={wls}
            title='Withdrawn Today'
            emptyText='No Contracts Withdrawn Check'
          />
        </div>

        <ActiveUsers
          items={activeUsers}
          isToday={date === today}
          emptyText='No Active Users'
        />

        <div id='swaps'>
          <SalesTable
            items={swaps}
            wls={wls}
            title='Upgrades (Returned Devices)'
            emptyText='No Upgrades'
          />
        </div>
        <div id='tradeins'>
          <TradeInsTable
            items={tradeIns}
            wls={wls}
            title='Trade-ins'
            emptyText='No Trade-ins'
          />
        </div>
        <StoreCountersTable items={storeCounters} wls={wls} />

        <SalesGraph
          items={dailySales}
          wls={wls}
          title='Sales (Last 7 Days)'
          multiplier='10'
          emptyText='No Sales'
        />

        <SalesGraph
          items={monthlySales}
          wls={wls}
          title='Sales By Month'
          multiplier='0.5'
          emptyText='No Sales'
        />

        <SalesGraph
          items={dailyTradeIns}
          wls={wls}
          title='Trade-Ins (Last 7 Days)'
          multiplier='10'
          emptyText='No Trade-Ins'
        />

        <SalesGraph
          items={monthlyTradeIns}
          wls={wls}
          title='Trade-Ins By Month'
          multiplier='0.5'
          emptyText='No Trade-Ins'
        />
        <div id='frontenderrors'>
          <UserErrors items={userErrors} />
        </div>
        <APICallsTable items={apiCalls} wls={wls} />
        <div id='apierrors'>
          <APIErrorsTable items={apiErrors} />
        </div>
      </div>
    );
  }
}

Dashboard.defaultProps = {
  wls: [],
  creditData: [],
  sales: [],
  onlinePendings: [],
  instorePendings: [],
  pendingCreditChecks: [],
  withdrawn: [],
  refusals: [],
  cancels: [],
  activeUsers: [],
  userErrors: [],
  unfinishedCarts: [],
  dailySales: [],
  monthlySales: [],
  dailyTradeIns: [],
  monthlyTradeIns: [],
  response: '',
  socketStatus: 'Disconnected',
  items: [],
  counters: [],
  storeCounters: [],
  apiCalls: [],
  apiErrors: [],
  tradeIns: [],
  swaps: [],
  uid: '',
  date: new Date().toISOString().substring(0, 10),
  today: new Date().toISOString().substring(0, 10),
};

export default connect(
  ({ dashboard, user }) => ({
    ...dashboard,
    sessionToken: user.session_token,
  }),
  {
    saveSocketStatus,
    saveSocketMessage,
    saveSocketConfig,
    clearDashboardData,
  },
)(Dashboard);
