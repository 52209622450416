import React from 'react';

import './WLIcon.styl';

const WLIcon = ({ index, item }) => {
  return (
    <div
      key={index}
      style={{ backgroundColor: item?.wlColourCode || '#FFFFFF' }}
      className='wlIcon'
      title={item?.wlName || 'Unknown'}
    >
      {item?.wlCode || ''}
    </div>
  );
};

export default WLIcon;
