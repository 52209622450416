import {
  CHANGE_PASSWORD,
  CHANGE_SELECTED_STORE,
  GET_USER_INFO,
  INITIALIZE_USER,
  LOGIN,
  LOGOUT,
  RESET_PASSWORD,
} from 'actions/user';

import initialState from 'reducers/initialState';

export default function (state = initialState.user, action) {
  switch (action.type) {
    case CHANGE_SELECTED_STORE:
      return { ...state, selectedStore: action.storeID };
    case LOGIN:
      return {
        ...state,
        ...action.data,
        selectedStore: action.data.default_store,
      };
    case RESET_PASSWORD:
      return { ...state, reset_password_status: action.data };
    case LOGOUT:
      return initialState.user;
    case INITIALIZE_USER:
      return initialState.user;
    case CHANGE_PASSWORD:
      return { ...state, status: 1 };
    case GET_USER_INFO:
      return {
        ...state,
        ...action.data,
        selectedStore: action?.data?.default_store,
      };
    default:
      return state;
  }
}
