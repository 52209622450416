import {
  ADD_DISCOUNT,
  ADD_EXTRA,
  ADD_EXTRA_MANUALLY,
  ADD_TO_CART,
  CHANGE_EXTRA_QUANTITY,
  CHANGE_ORDER_PAYMENT_PLAN,
  CHANGE_PRODUCT_QUANTITY,
  CHANGE_SALE_PRICE,
  CLEAR_SELECTED_PRODUCT,
  CREATE_CART,
  GET_PRICED_EXTRAS,
  REMOVE_CURRENT_CART,
  REMOVE_DISCOUNT,
  REMOVE_EXTRA,
  REMOVE_ORDER,
  REMOVE_PRODUCT,
  SAVE_CART,
  SAVE_CURRENT_CART,
  SAVE_FINANCING_TYPE,
  SAVE_SELECTED_CATEGORY,
  SAVE_SELECTED_FAMILY,
  SELECT_OPTION,
  SET_SELECTED_SERVICE,
  UPDATE_SELECTED_PRODUCT,
} from 'actions/carts';
import { GET_PRODUCT_CATEGORIES } from 'actions/catalog';

import initialState from 'reducers/initialState';

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState.carts, action) {
  switch (action.type) {
    case ADD_TO_CART:
      return {
        ...state,
        list: state.list.map((cart) => {
          if (cart?.uuid !== action.cartUUID) return cart;

          return {
            ...cart,
            ...action.data,
          };
        }),
      };
    case GET_PRODUCT_CATEGORIES:
      return state;
    case ADD_DISCOUNT:
    case REMOVE_DISCOUNT:
    case ADD_EXTRA:
    case ADD_EXTRA_MANUALLY:
    case REMOVE_EXTRA:
    case REMOVE_ORDER:
    case REMOVE_PRODUCT:
    case GET_PRICED_EXTRAS:
    case SELECT_OPTION:
    case CHANGE_PRODUCT_QUANTITY:
    case CHANGE_EXTRA_QUANTITY:
    case CHANGE_SALE_PRICE:
      return {
        ...state,
        list: state.list.map((cart) => {
          if (cart?.uuid !== action.cartUUID) return cart;

          return {
            ...cart,
            ...action.data,
          };
        }),
      };
    case CHANGE_ORDER_PAYMENT_PLAN:
    case SAVE_CART:
      return {
        ...state,
        list: [
          ...state.list.filter((cart) => cart?.uuid !== action.cart.uuid),
          action.cart,
        ],
      };
    case CREATE_CART:
      return {
        ...state,
        list: state.list ? [...state.list, action.cart] : [action.cart],
      };
    case SAVE_FINANCING_TYPE:
      return {
        ...state,
        list: state.list.map((cart) => {
          if (cart?.uuid !== action.cartUUID) return cart;

          return {
            ...cart,
            financingType: action.financingType,
            tags: action.tags,
            tagFilterType: action.tagFilterType,
          };
        }),
      };
    case SAVE_SELECTED_CATEGORY:
      return {
        ...state,
        list: state.list.map((cart) => {
          const { cartUUID, id, name } = action;
          if (cart?.uuid !== cartUUID) return cart;

          return {
            ...cart,
            category: id,
            categoryName: name,
          };
        }),
      };
    case UPDATE_SELECTED_PRODUCT:
      return {
        ...state,
        list: state.list.map((cart) => {
          if (cart?.uuid !== action.cartUUID) return cart;

          return {
            ...cart,
            selected: {
              ...cart.selected,
              ...action.changes,
            },
          };
        }),
      };
    case CLEAR_SELECTED_PRODUCT:
      return {
        ...state,
        list: state.list.map((cart) => {
          if (cart?.uuid !== action.cartUUID) return cart;
          return {
            ...cart,
            selected: {},
          };
        }),
      };
    case SAVE_CURRENT_CART:
      return { ...state, currentCart: action.cartUUID };
    case REMOVE_CURRENT_CART:
      return { ...state, currentCart: undefined };
    case SAVE_SELECTED_FAMILY:
      return {
        ...state,
        list: state.list.map((cart) => {
          if (cart?.uuid !== action.cartUUID) return cart;

          return {
            ...cart,
            family: action.family?.device_name,
          };
        }),
      };
    case SET_SELECTED_SERVICE:
      return {
        ...state,
        list: state.list.map((cart) => {
          if (cart?.uuid !== action.cartUUID) return cart;
          return {
            ...cart,
            selectedService: action.service,
          };
        }),
      };
    default:
      return state;
  }
}
