import { t } from '@lingui/macro';

export const storeText = () => [
  t({
    id: 'customer.store.text',
    message: `Here a quick reminder of what to do next when making devices ready for collection`,
  }),
  t({
    id: 'customer.store.text1',
    message: `Place this order sheet neatly with the mobile device(s) (preferably in a padded envelope)`,
  }),
  t({
    id: 'customer.store.text2',
    message: `Place in the stores secure box ready for collection`,
  }),
  t({
    id: 'customer.store.text3',
    message: `When you have 5 or more devices please arrange a collection through the Admin section of the portal.`,
  }),
];

export const customerText = (wlName) => [
  t({
    id: 'customer.trade.text',
    message: `I have the right to perform this transaction and declare the following`,
  }),
  t({
    id: 'customer.trade.text1',
    message: `The goods exchanged are my property and no third parties have claim to them.`,
  }),
  t({
    id: 'customer.trade.text2',
    message: `I have ownership of the goods being exchanged and I can transfer full ownership to ${wlName}, free of obligations.`,
  }),
  t({
    id: 'customer.trade.text3',
    message: `I confirm that any information stored on the mobile phone, tablet, or PC that I have exchanged will be deleted and I agree that`,
  }),
  t({
    id: 'customer.trade.text4',
    message: `I do not have any claim against ${wlName} for loss or damage I may suffer as a result of the destruction or loss of any information stored on my device.`,
  }),
  t({
    id: 'customer.trade.text5',
    message: `Furthermore, I do not hold ${wlName} liable for any loss or damage to any third party as a result of the destruction or loss of the information that may be stored in the aforementioned mobile phone, tablet, PC, or storage device contained therein, including any costs I may incur.`,
  }),
];

export const additionalInfo = () => ({
  clientStoreCopy: t({
    id: 'customer.trade.text.for.store',
    message: `Store copy, please retain for your records`,
  }),
  recycleTerms: t({
    id: 'customer.trade.recycle.terms.url',
    message: `Full trade-in terms and conditions are available on our website at:`,
  }),
});
