import errorFace from 'assets/images/error-face.svg';

import React from 'react';

import { Trans, t } from '@lingui/macro';

import SystemPage from './SystemPage';

const PageNotFound = ({ withHeader }) => {
  return (
    <SystemPage
      withHeader={withHeader}
      image={errorFace}
      title={<Trans id='page.not.found.initial.title'>404</Trans>}
      subtitle={
        <Trans id='page.not.found.initial.subtitle'>Page not found</Trans>
      }
      body={t({
        id: 'page.not.found.initial.paragraph.one',
        message: `Whoops, looks like the page you are trying to access does not exist.`,
      })}
      backLinkText={t({
        id: 'page.not.found.initial.paragraph.two',
        message: `Don't worry, you can go back!`,
      })}
    />
  );
};

export default PageNotFound;
