import { createAction } from 'redux-actions';

export const ADD_FLOW = 'ADD_FLOW';
export const addFlow = createAction(ADD_FLOW);

export const REMOVE_FLOW = 'REMOVE_FLOW';
export const removeFlow = createAction(REMOVE_FLOW);

export const FLOW_NEXT_STEP = 'FLOW_NEXT_STEP';
export const flowNextStep = createAction(FLOW_NEXT_STEP);

export const FLOW_PREV_STEP = 'FLOW_PREV_STEP';
export const flowPrevStep = createAction(FLOW_PREV_STEP);

export const FLOW_SET_STEPS = 'FLOW_SET_STEPS';
export const flowSetSteps = createAction(FLOW_SET_STEPS);

export const FLOW_START = 'FLOW_START';
export const flowStart = createAction(FLOW_START);

export const FLOW_END = 'FLOW_END';
export const flowEnd = createAction(FLOW_END);

export const FLOW_GOTO = 'FLOW_GOTO';
export const flowGoto = createAction(FLOW_GOTO);
