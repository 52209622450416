import React from 'react';

import CreditAcceptanceRate from './CreditAcceptanceRate';
import WLIcon from './WLIcon';

const CreditDataTable = ({ items, wls, className }) => {
  const renderCreditInstoreDataRow = (item, index, stripe, wl, wlRowspan) => {
    return (
      <tr key={'cdta' + index} className={stripe}>
        <td rowSpan={wlRowspan}>
          <WLIcon item={wl} index={index} />
        </td>
        <td>Store</td>
        <td>{item.instore.TODAY.totalCustomers}</td>
        <td>{item.instore.TODAY.pendingCreditCheck}</td>
        <td>{item.instore.TODAY.refused}</td>
        <td>{item.instore.TODAY.approved}</td>
        <td>{item.instore.TODAY.active}</td>
        <td>{item.instore.TODAY.cancelled}</td>
        <td>{item.instore.TODAY.rawAcceptanceRate}%</td>
        <td>
          <CreditAcceptanceRate
            key={'todayInNet' + index}
            rate={item.instore.TODAY.netAcceptanceRate}
            colourCode={item.instore.TODAY.colour}
          />
        </td>

        <td>{item.instore.LAST7DAYS.totalCustomers}</td>
        <td>{item.instore.LAST7DAYS.pendingCreditCheck}</td>
        <td>{item.instore.LAST7DAYS.refused}</td>
        <td>{item.instore.LAST7DAYS.approved}</td>
        <td>{item.instore.LAST7DAYS.active}</td>
        <td>{item.instore.LAST7DAYS.cancelled}</td>
        <td>{item.instore.LAST7DAYS.rawAcceptanceRate}%</td>
        <td>
          <CreditAcceptanceRate
            key={'l7InNet' + index}
            rate={item.instore.LAST7DAYS.netAcceptanceRate}
            colourCode={item.instore.LAST7DAYS.colour}
          />
        </td>

        <td>{item.instore.LAST28DAYS.totalCustomers}</td>
        <td>{item.instore.LAST28DAYS.pendingCreditCheck}</td>
        <td>{item.instore.LAST28DAYS.refused}</td>
        <td>{item.instore.LAST28DAYS.approved}</td>
        <td>{item.instore.LAST28DAYS.active}</td>
        <td>{item.instore.LAST28DAYS.cancelled}</td>
        <td>{item.instore.LAST28DAYS.rawAcceptanceRate}%</td>
        <td>
          <CreditAcceptanceRate
            key={'l28InNet' + index}
            rate={item.instore.LAST28DAYS.netAcceptanceRate}
            colourCode={item.instore.LAST28DAYS.colour}
          />
        </td>
      </tr>
    );
  };
  const renderCreditOnlineDataRow = (item, index, stripe, wl) => {
    return (
      <tr key={'cdtb' + index} className={stripe}>
        <td>Online</td>
        <td>{item.online.TODAY.totalCustomers}</td>
        <td>{item.online.TODAY.pendingCreditCheck}</td>
        <td>{item.online.TODAY.refused}</td>
        <td>{item.online.TODAY.approved}</td>
        <td>{item.online.TODAY.active}</td>
        <td>{item.online.TODAY.cancelled}</td>
        <td>{item.online.TODAY.rawAcceptanceRate}%</td>
        <td>
          <CreditAcceptanceRate
            key={'todayOnNet' + index}
            rate={item.online.TODAY.netAcceptanceRate}
            colourCode={item.online.TODAY.colour}
          />
        </td>

        <td>{item.online.LAST7DAYS.totalCustomers}</td>
        <td>{item.online.LAST7DAYS.pendingCreditCheck}</td>
        <td>{item.online.LAST7DAYS.refused}</td>
        <td>{item.online.LAST7DAYS.approved}</td>
        <td>{item.online.LAST7DAYS.active}</td>
        <td>{item.online.LAST7DAYS.cancelled}</td>
        <td>{item.online.LAST7DAYS.rawAcceptanceRate}%</td>
        <td>
          <CreditAcceptanceRate
            key={'l7OnNet' + index}
            rate={item.online.LAST7DAYS.netAcceptanceRate}
            colourCode={item.online.LAST7DAYS.colour}
          />
        </td>

        <td>{item.online.LAST28DAYS.totalCustomers}</td>
        <td>{item.online.LAST28DAYS.pendingCreditCheck}</td>
        <td>{item.online.LAST28DAYS.refused}</td>
        <td>{item.online.LAST28DAYS.approved}</td>
        <td>{item.online.LAST28DAYS.active}</td>
        <td>{item.online.LAST28DAYS.cancelled}</td>
        <td>{item.online.LAST28DAYS.rawAcceptanceRate}%</td>
        <td>
          <CreditAcceptanceRate
            key={'l28OnNet' + index}
            rate={item.online.LAST28DAYS.netAcceptanceRate}
            colourCode={item.online.LAST28DAYS.colour}
          />
        </td>
      </tr>
    );
  };

  return (
    <table className={`StatsTable wideTable`}>
      <colgroup>
        <col width='3%' />
        <col width='6%' />
        <col width='4%' />
        <col width='3%' />
        <col width='3%' />
        <col width='3%' />
        <col width='3%' />
        <col width='3%' />
        <col width='6%' />
        <col width='6%' />
        <col width='4%' />
        <col width='3%' />
        <col width='3%' />
        <col width='3%' />
        <col width='3%' />
        <col width='3%' />
        <col width='6%' />
        <col width='6%' />
        <col width='4%' />
        <col width='3%' />
        <col width='3%' />
        <col width='3%' />
        <col width='3%' />
        <col width='3%' />
        <col width='6%' />
        <col width='6%' />
      </colgroup>
      <tbody>
        <tr>
          <th colSpan='26' className='fullwidth'>
            <span className='StatsTable_caption'>Credit Data</span>
          </th>
        </tr>

        <tr className='StatsTable_innerHeaderLarge'>
          <td rowSpan='2' />
          <td rowSpan='2'>Channel</td>
          <td colSpan='8'>Today</td>
          <td colSpan='8'>Last 7 Days</td>
          <td colSpan='8'>Last 28 Days</td>
        </tr>
        <tr className='StatsTable_innerHeader'>
          <td className='StatsTable_rotate'>
            <div>Customers</div>
          </td>
          <td className='StatsTable_rotate'>
            <div>Pending CC</div>
          </td>
          <td className='StatsTable_rotate'>
            <div>Refused</div>
          </td>
          <td className='StatsTable_rotate'>
            <div>Approved</div>
          </td>
          <td className='StatsTable_rotate'>
            <div>Active</div>
          </td>
          <td className='StatsTable_rotate'>
            <div>Cancelled</div>
          </td>
          <td className='StatsTable_rotate'>
            <div>Raw Acc Rate %</div>
          </td>
          <td className='StatsTable_rotate'>
            <div>Net Acc Rate %</div>
          </td>
          <td className='StatsTable_rotate'>
            <div>Customers</div>
          </td>
          <td className='StatsTable_rotate'>
            <div>Pending CC</div>
          </td>
          <td className='StatsTable_rotate'>
            <div>Refused</div>
          </td>
          <td className='StatsTable_rotate'>
            <div>Approved</div>
          </td>
          <td className='StatsTable_rotate'>
            <div>Active</div>
          </td>
          <td className='StatsTable_rotate'>
            <div>Cancelled</div>
          </td>
          <td className='StatsTable_rotate'>
            <div>Raw Acc Rate %</div>
          </td>
          <td className='StatsTable_rotate'>
            <div>Net Acc Rate %</div>
          </td>
          <td className='StatsTable_rotate'>
            <div>Customers</div>
          </td>
          <td className='StatsTable_rotate'>
            <div>Pending CC</div>
          </td>
          <td className='StatsTable_rotate'>
            <div>Refused</div>
          </td>
          <td className='StatsTable_rotate'>
            <div>Approved</div>
          </td>
          <td className='StatsTable_rotate'>
            <div>Active</div>
          </td>
          <td className='StatsTable_rotate'>
            <div>Cancelled</div>
          </td>
          <td className='StatsTable_rotate'>
            <div>Raw Acc Rate %</div>
          </td>
          <td className='StatsTable_rotate'>
            <div>Net Acc Rate %</div>
          </td>
        </tr>

        {items.map((item, index) => {
          var wl = wls.find(function (element) {
            return element.wlID === item.wlID;
          }, item.wlID);
          var stripe = index % 2 === 1 ? 'stripe_even' : 'stripe_odd';
          var wlRowspan = 1;
          if (item.online.TODAY) wlRowspan = 2;
          var rows = [];
          if (item.instore.TODAY)
            rows.push(
              renderCreditInstoreDataRow(item, index, stripe, wl, wlRowspan),
            );
          if (item.online.TODAY)
            rows.push(renderCreditOnlineDataRow(item, index, stripe, wl));
          return rows;
        })}

        {items.length === 0 && (
          <tr>
            <td colSpan='26' className='StatsTable_none'>
              No Credit Data
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default CreditDataTable;
