import { getCartFromOrder } from 'utils';

export const rootUrl = '/';

// --- Posale
export const posaleBeginUrl = '/posale';

// --- Posale / User ---------
export const loginPageUrl = `${posaleBeginUrl}/login`;
export const loginTokenRoute = `${posaleBeginUrl}/login/:SSOToken`;
export const loginRecoverUrl = `${posaleBeginUrl}/login/recover`;
export const changePasswordUrl = `${posaleBeginUrl}/password/change`;
export const passwordResetRoute = `${posaleBeginUrl}/login/resetpassword/:id`; // used?

// --- Posale / User ---------
export const dashboardUrl = '/dashboard'; // Not changing for now
export const monitorUrl = '/monitor'; // Not changing for now

// --- Posale / Home ---------
export const servicesUrl = `${posaleBeginUrl}/services`;
export const servicesUrlAny = `${servicesUrl}/:cartUUID`;
export const serviceUrlWithCart = (cartUUID) => `${servicesUrl}/${cartUUID}`;

// --- Posale / Upgrade ---------
export const upgradeUrl = `${posaleBeginUrl}/upgrade`;
export const upgradeAnyUrl = `${upgradeUrl}/*`;
export const upgradeFlowUrl = (id) => `${upgradeUrl}/${id}`;

export const upgradeFlowRoute = `${upgradeUrl}/:cartUUID`;

export const customerDetailsUpgradeUrl = (id) => `${upgradeUrl}/${id}/customer`;

// --- Posale / Standard ---------
export const standardUrl = `${posaleBeginUrl}/standard`;
export const standardAnyUrl = `${standardUrl}/*`;
export const standardFinanceFlowUrl = (id) => `${standardUrl}/${id}`;

export const standardFinanceRoute = `${standardUrl}/:cartUUID`;

// --- Posale / Cart ---------
export const cartDetailsRoute = `${posaleBeginUrl}/cart/:cartUUID`;
export const cartPageUrl = (id) => `${posaleBeginUrl}/cart/${id}`;

// --- Posale / Checkout ---------
export const checkoutCartRoute = `${posaleBeginUrl}/checkout/:cartUUID`;
export const checkoutFlowUrl = (id) => `${posaleBeginUrl}/checkout/${id}`;
export const customerDetailsUrl = (id) =>
  `${posaleBeginUrl}/checkout/${id}/customer`;

// --- Posale / Sale ---------
export const salesRoute = `${posaleBeginUrl}/sales`;
export const salesUrl = `${posaleBeginUrl}/sales?activeForm=SalesForm-Filter`;
export const cartContractRoute = `${posaleBeginUrl}/:cartUUID/contract`;

export const cartSaleRoute = ':cartUUID';
export const viewSaleUrl = (id) =>
  `${salesRoute}/${id && getCartFromOrder(id)}`;

export const uploadSalesRoute = 'upload';

export const contractPageUrl = (id) => `${posaleBeginUrl}/${id}/contract`;

// --- Posale / Sale / Pendings ---------
export const pendingContractsRoute = `${salesRoute}/contracts/pending`;
export const contractPendingUrl = `${salesRoute}/contracts/pending?activeForm=SalesForm-Filter`;

// --- Posale / Reports ---------
export const reportsRoute = '/reports';
// --- Posale / Reports / Recycle ---------
export const recycleReportsRoute = 'recycle';
export const recycleReportsUrl = `${reportsRoute}/${recycleReportsRoute}`;
// --- Posale / Reports / Settlements ---------
export const reportSettlementRoute = 'settlements';
export const reportSettlementUrl = `${reportsRoute}/${reportSettlementRoute}`;

// --- Posale / Sub-routes for Upgrade, Standard and Checkout ---------
export const productListRoute = 'catalog';
export const productOptionsRoute = 'options';
export const productCustomizeRoute = 'customize';
export const extrasRoute = 'extras';
export const customerRoute = 'customer';
export const creditRoute = 'credit';

// --- Posale / Recycle ---------
export const recycleRoute = `${posaleBeginUrl}/recycle`;
// --- Posale / Recycle / Details ---------
export const recycleDetailsUrl = (tradecartID) =>
  `${recycleRoute}/details/${tradecartID}`;
export const recycleDetailsRoute = 'details/:tradecartID';
// --- Posale / Recycle / Search ---------
export const recycleSearchUrl = `${recycleRoute}/search`;
export const recycleSearchRoute = `${recycleRoute}/search`;

export const recycleSearchOrderUrl = (orderUUID) =>
  `${recycleRoute}/${orderUUID}/search`;
export const recycleSearchOrderRoute = `${recycleRoute}/:orderUUID/search`;

export const recycleEvaluateUrl = `${recycleRoute}/evaluate`;
export const recycleEvaluateRoute = `${recycleRoute}/evaluate`;

export const recycleEvaluateOrderUrl = (orderUUID) =>
  `${recycleRoute}/${orderUUID}/evaluate`;
export const recycleEvaluateOrderRoute = `${recycleRoute}/:orderUUID/evaluate`;

export const recycleOutcomesUrl = `${recycleRoute}/outcomes`;
export const recycleOutcomesRoute = `${recycleRoute}/outcomes`;

export const recycleOutcomesOrderUrl = (orderUUID) =>
  `${recycleRoute}/${orderUUID}/outcomes`;
export const recycleOutcomesOrderRoute = `${recycleRoute}/:orderUUID/outcomes`;

export const recycleQuotesUrl = `${recycleRoute}/quotes`;
export const recycleQuotesRoute = 'quotes';

export const recycleQuoteSearchUrl = `${recycleRoute}/quotes/search`;
export const recycleQuoteSearchRoute = `${recycleRoute}/quotes/search`;

// --- Posale / Admin ---------
export const adminBeginUrl = `${posaleBeginUrl}/admin`;

// --- Posale / Admin / Accessories ---------
export const adminAccessoriesPageUrl = 'accessories';
export const adminAccessoriesUrl = `${adminBeginUrl}/accessories`;

// --- Posale / Admin / Campaign ---------
export const adminCampaignsRoute = 'campaigns';
export const adminCampaignDetailsRoute = `${adminCampaignsRoute}/:campaignCode/details`;
export const adminCampaignDetailsUrl = (id) =>
  `${adminBeginUrl}/${adminCampaignsRoute}/${id}/details`;
export const adminCampaignsUrl = `${adminBeginUrl}/${adminCampaignsRoute}?form=close`;

// --- Posale / Admin / Collections ---------
export const adminCollectionsRoute = 'collections';
export const adminCollectionsOrganiseRoute = `${adminCollectionsRoute}/organise`;
export const adminCollectionRoute = `${adminCollectionsRoute}/:collectionUUID`;

export const adminCollectionsUrl = `${adminBeginUrl}/${adminCollectionsRoute}`;
export const adminCollectionDetailsUrl = (id) =>
  `${adminBeginUrl}/${adminCollectionsRoute}/${id}`;
export const adminOrganiseCollectionUrl = `${adminBeginUrl}/${adminCollectionsOrganiseRoute}`;

// --- Posale / Admin / Devices ---------
export const adminDevicesPageUrl = 'devices';
export const adminDevicesUrl = `${adminBeginUrl}/devices`;

// --- Posale / Admin / Stores ---------
export const adminStoresPageUrl = 'stores';
export const adminStoresUrl = `${adminBeginUrl}/stores`;

// --- Posale / Admin / Users ---------
export const adminUserPageUrl = 'users';
export const adminUserUrl = `${adminBeginUrl}/users`;

// --- Posale / Admin / PriceTools ---------
export const adminPricingToolRoute = 'pricing_tool';
export const adminPricingToolUrl = `${adminBeginUrl}/${adminPricingToolRoute}`;

export const adminResidualValuesSearchRoute = `${adminBeginUrl}/residualvalues`;
export const adminResidualValuesSearchUrl = `${adminBeginUrl}/residualvalues`;

export const adminRulesUrl = `${adminBeginUrl}/rules`;

// --- Posale / Admin / DateAdjust ---------
export const adminContractDateAdjustRoute = 'contracts/adjust';
export const adminContractDateAdjustUrl = `${adminBeginUrl}/${adminContractDateAdjustRoute}`;

// --- Posale / Admin / Templates ---------
export const adminTemplateEditorRoute = 'emails/:campaignCode/:templateName';
export const adminTemplateEditorUrl = (campaignCode, templateName) =>
  `${adminBeginUrl}/emails/${campaignCode}/${templateName}`;

export const adminTemplateBlocksRoute = 'emails/blocks';
export const adminTemplateBlocksURL = `${adminBeginUrl}/${adminTemplateBlocksRoute}`;

export const adminTemplateBlockEditorRoute = 'emails/blocks/:blockUUID';
export const adminTemplateBlockEditorURL = (blockUUID) =>
  `${adminBeginUrl}/emails/blocks/${blockUUID}`;

export const adminAttachmentsRoute = 'email/attachments';
export const adminAttachmentsURL = `${adminBeginUrl}/${adminAttachmentsRoute}`;
export const adminTemplateAttachmentsURL = (campaignCode, templateName) =>
  `${adminAttachmentsURL}?campaignCode=${campaignCode}&templateName=${templateName}`;

// --- Posale / Admin / TestContracts ---------
export const adminTestContractsRoute = 'testcontracts';
export const adminTestContractsUrl = `${adminBeginUrl}/${adminTestContractsRoute}`;

export const adminTestContractMessagesRoute = 'testcontracts/:testContractID';
export const adminTestContractDetailsUrl = (id) =>
  `${adminBeginUrl}/testcontracts/${id}`;

// --- Posale / Admin / Vouchers ---------
export const adminVouchersRoute = `${adminBeginUrl}/vouchers`;
export const adminVouchersUrl = `${adminBeginUrl}/vouchers`;
