import {
  saveFinancingTypeAction,
  saveSelectedCategoryAction,
} from 'actions/carts';
import {
  clearProductCategoriesAction,
  getProductCategoriesAction,
} from 'actions/catalog';
import { getCurrentCart } from 'utils';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext, useParams } from 'react-router-dom';

import { Trans } from '@lingui/macro';

import Button from 'components/Button';
import Heading from 'components/Heading';
import List from 'components/List';
import Loader from 'components/Loader';
import OutcomeIndicator from 'components/recycle/OutcomeIndicator';

import useTrackPage from 'hooks/useTrackPage';

import './ProductCategories.styl';

const ProductCategories = () => {
  useTrackPage('ProductCategories');

  const dispatch = useDispatch();
  const [nextPage] = useOutletContext();

  const { cartUUID } = useParams();

  const categories = useSelector(({ catalog }) => catalog.categories);
  const loading = useSelector(({ ui }) => ui.loading);
  const cart = useSelector(({ carts }) => getCurrentCart(carts, cartUUID));
  const paymentplanID = cart?.selected?.paymentplanID;
  const selectedService = cart?.selectedService;

  useEffect(() => {
    // Adds financing type selected from services to current cart
    const { type: financingType, tags, tagFilterType } = selectedService || {};
    if (financingType) {
      dispatch(
        saveFinancingTypeAction(cartUUID, financingType, tags, tagFilterType),
      );
      dispatch(
        getProductCategoriesAction(
          financingType,
          paymentplanID,
          tags,
          tagFilterType,
        ),
      );
    }
  }, [selectedService, cartUUID, dispatch, paymentplanID]);

  useEffect(() => {
    dispatch(clearProductCategoriesAction());
  }, [dispatch]);

  const selectCategory = (category) => {
    dispatch(saveSelectedCategoryAction(cartUUID, category));
    nextPage();
  };

  if (loading) return <Loader />;

  return (
    <div className='product-categories-container'>
      <div>
        <Heading size='big'>
          <Trans id='product.categories.page.title'>Categories</Trans>
        </Heading>
        <OutcomeIndicator />
      </div>

      <List
        data={categories}
        // TODO make this component reliant only on class functions
        // in order to remove arrow functions and enforce this rule
        // eslint-disable-next-line react/jsx-no-bind
        keyGenerator={(item) => item.id}
        // eslint-disable-next-line react/jsx-no-bind
        render={(item) => (
          <Button
            classes='big-square'
            testSelector={`category-button-${item.name}`}
          >
            {item.svg && <img src={item.svg} alt={item.name} />}
            <span>{item.name}</span>
          </Button>
        )}
        // TODO: remove onClick in favor of using rendered items to control behavior
        // eslint-disable-next-line react/jsx-no-bind
        onClick={(item) => selectCategory(item)}
        listClasses='list--big-buttons'
      />
    </div>
  );
};

export default ProductCategories;
