import * as React from 'react';

function SvgIconClose({ title, titleId, ...props }) {
  return (
    <svg
      fillRule='evenodd'
      strokeLinejoin='round'
      strokeMiterlimit={1.414}
      clipRule='evenodd'
      viewBox='0 0 36 37'
      width='1em'
      height='1em'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill='currentColor'
        fillRule='nonzero'
        d='M14.36 18.008L.74 4.388C.442 4.02.33 3.943.158 3.493-.295 2.312.264.852 1.392.277c.43-.22.564-.203 1.033-.277l.537.028c.459.123.594.12 1 .384.15.097.278.225.417.338l13.619 13.619L31.617.75c.14-.113.267-.24.418-.338.302-.197.643-.328.999-.384.353-.056.713 0 1.068.056.443.17.578.182.954.486.825.668 1.177 1.895.782 2.922-.173.45-.284.528-.582.897l-13.62 13.619 13.62 13.619c.112.139.24.267.338.417.69 1.062.445 2.605-.538 3.402-.84.68-2.113.748-3.021.158-.15-.098-.279-.226-.418-.339L17.998 21.647 4.379 35.265c-.14.113-.268.241-.418.339-1.054.685-2.602.448-3.401-.539-.683-.843-.748-2.112-.158-3.02.097-.15.225-.28.338-.418l13.62-13.62z'
      />
    </svg>
  );
}

export default SvgIconClose;
