import isEmpty from 'lodash/isEmpty';

import React from 'react';

import { Trans } from '@lingui/macro';

import Modal from 'components/Modal';

import { errorCodes } from 'constants/errors';

import './UploadReport.styl';

const UploadReport = ({ report }) => {
  return (
    <Modal
      name='uploadReport'
      classes='upload-report'
      title={
        <Trans id='upload.report.modal.title'>
          {report?.fileName} upload report
        </Trans>
      }
    >
      <ul className='upload-stats'>
        <li>
          <Trans id='upload.report.modal.rows.total'>
            {report?.totalCount} rows found.
          </Trans>
        </li>

        <li>
          <Trans id='upload.report.modal.rows.parse.success'>
            {report?.parseSuccessCount} rows successfully parsed.
          </Trans>
        </li>

        <li>
          <Trans id='upload.report.modal.rows.insert.success'>
            {report?.insertSuccessCount} rows successfully inserted.
          </Trans>
        </li>

        <li>
          <Trans id='upload.report.modal.rows.failure'>
            {report?.errorCount} rows contained errors.
          </Trans>
        </li>
      </ul>

      {!isEmpty(report?.fileErrors) && (
        <ul className='error-list'>
          {Object.entries(report.fileErrors).map(([row, errors]) => (
            <li className='error' key={row}>
              <Trans id='upload.report.modal.error.row'>Row</Trans>
              {` ${row}`}
              {errors &&
                errors.map(({ column, error, value }) => (
                  <p>
                    {column} -{' '}
                    {error === 'INVALID_VALUE'
                      ? errorCodes()[error][value]
                      : errorCodes(value)[error]}
                  </p>
                ))}
            </li>
          ))}
        </ul>
      )}
    </Modal>
  );
};

export default UploadReport;
