import { deleteCommunicationAction } from 'actions/admin/campaign';
import { openModalAction } from 'actions/ui';

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { t } from '@lingui/macro';
import { Trans } from '@lingui/macro';

import Heading from 'components/Heading';
import Modal from 'components/Modal';
import Table from 'components/table/Table';

import { adminTemplateEditorUrl } from 'constants/routes';

export const Communications = ({
  selectCommunication,
  communications = [],
  commsType,
  campaignCode,
  formName = 'communicationForm',
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [communicationToDelete, setCommunicationToDelete] = useState(null);

  const columns = {
    MARKETING: [
      {
        label: t({
          id: 'table.column.communication.code',
          message: `Communication code`,
        }),
        accessor: 'actionCode',
        type: 'text',
        filterable: false,
        sortable: true,
        align: 'center',
        wrap: false,
      },
      {
        label: t({ id: 'table.column.description', message: `Description` }),
        accessor: 'description',
        type: 'text',
        filterable: false,
        sortable: true,
        align: 'center',
        wrap: false,
      },
      {
        label: t({
          id: 'table.column.communications.type',
          message: `Communication type`,
        }),
        accessor: 'commsType',
        type: 'text',
        filterable: false,
        sortable: true,
        align: 'center',
        wrap: false,
      },
      {
        label: t({
          id: 'table.column.template.name',
          message: `Template name`,
        }),
        accessor: 'templateName',
        type: 'text',
        filterable: false,
        sortable: true,
        align: 'center',
        wrap: false,
      },
      {
        label: t({
          id: 'table.column.communications.delay',
          message: `Communication Delay`,
        }),
        accessor: 'actionDelayConcat',
        type: 'text',
        filterable: false,
        sortable: true,
        align: 'center',
        wrap: false,
      },
      {
        label: t({ id: 'table.column.campaign.actions', message: `Actions` }),
        accessor: 'actions',
        type: 'actions',
        filterable: false,
        sortable: false,
        align: 'right',
        wrap: false,
      },
    ],

    OPERATIONAL: [
      {
        label: t({
          id: 'table.column.communication.code',
          message: `Communication code`,
        }),
        accessor: 'actionCode',
        type: 'text',
        filterable: false,
        sortable: true,
        align: 'center',
        wrap: false,
      },
      {
        label: t({ id: 'table.column.description', message: `Description` }),
        accessor: 'description',
        type: 'text',
        filterable: false,
        sortable: true,
        align: 'center',
        wrap: false,
      },
      {
        label: t({
          id: 'table.column.communications.type',
          message: `Communication type`,
        }),
        accessor: 'commsType',
        type: 'text',
        filterable: false,
        sortable: true,
        align: 'center',
        wrap: false,
      },
      {
        label: t({
          id: 'table.column.template.name',
          message: `Template name`,
        }),
        accessor: 'templateName',
        type: 'text',
        filterable: false,
        sortable: true,
        align: 'center',
        wrap: false,
      },
      {
        label: t({
          id: 'table.column.communications.before.stage',
          message: `Status before communication`,
        }),
        accessor: 'beforeStage',
        type: 'text',
        filterable: false,
        sortable: true,
        align: 'center',
        wrap: false,
      },
      {
        label: t({
          id: 'table.column.communications.after.stage',
          message: `Status after communication`,
        }),
        accessor: 'afterStage',
        type: 'text',
        filterable: false,
        sortable: true,
        align: 'center',
        wrap: false,
      },
      {
        label: t({
          id: 'table.column.communications.delay',
          message: `Communication Delay`,
        }),
        accessor: 'actionDelayConcat',
        type: 'text',
        filterable: false,
        sortable: true,
        align: 'center',
        wrap: false,
      },
      {
        label: t({ id: 'table.column.campaign.actions', message: `Actions` }),
        accessor: 'actions',
        type: 'actions',
        filterable: false,
        sortable: false,
        align: 'right',
        wrap: false,
      },
    ],
  };

  const templateAction = {
    label: t({ id: 'table.column.actions.template', message: `Template` }),
    handler: (_, { campaignId, templateName }) => {
      navigate(adminTemplateEditorUrl(campaignId, templateName));
    },
  };

  const actions = [
    {
      label: t({ id: 'table.column.actions.edit', message: `Edit` }),
      handler: (_, { actions, ...values }) => {
        selectCommunication(values);

        document.getElementById(formName)?.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
          inline: 'start',
        });
      },
    },
    {
      label: t({ id: 'table.column.actions.delete', message: `Delete` }),
      className: 'red',
      handler: (_, { id, templateName }) => {
        if (campaignCode !== communicationToDelete?.campaignCode) {
          setCommunicationToDelete({ campaignCode, id, templateName });
          dispatch(openModalAction('cancelCommunication'));
        }
      },
    },
  ];

  const rows = communications?.map((communication) => ({
    ...communication,
    ...(communication.commsType !== 'WAIT'
      ? { actions: [templateAction, ...actions] }
      : { templateName: '', actions }),
    actionDelayConcat:
      communication.delay || communication.delayUnit
        ? communication.delay + ' ' + communication.delayUnit
        : '',
  }));

  const deleteCommunication = () => {
    dispatch(deleteCommunicationAction(communicationToDelete));
  };

  return (
    <>
      <Table
        columns={columns[commsType]}
        data={rows}
        keyColumn='id'
        defaultOrder='actionDelay'
        defaultDir='asc'
        filterable={false}
      />

      <Modal name='cancelCommunication' onConfirm={deleteCommunication}>
        <Heading size='medium'>
          <Trans id='communication.actions.cancel.button'>
            Cancel communication
          </Trans>
        </Heading>

        <p>
          <Trans id='communication.actions.cancel.question'>
            Are you sure you want to cancel the communication{' '}
            {communicationToDelete?.templateName}?
          </Trans>
        </p>
      </Modal>
    </>
  );
};

export default Communications;
