import { changeOrderPPAction } from 'actions/carts';
import { addNotificationAction } from 'actions/ui';
import isEmpty from 'lodash/isEmpty';
import { getCurrentCart, hasDevicesInOrders } from 'utils';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { t } from '@lingui/macro';

import Footer from 'components/Footer';
import Loader from 'components/Loader';
import Multistep from 'components/Multistep';

import { blockedFeature } from 'constants/notifications';
import { customerDetailsUrl } from 'constants/routes';

import useTrackPage from 'hooks/useTrackPage';

import CartList from './components/CartList';
import PaymentPlanSelect from './components/PaymentPlanSelect';

const CartDetails = () => {
  useTrackPage('CartDetails');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cartUUID } = useParams();

  const loading = useSelector(({ ui }) => ui.loading);
  const currency = useSelector(({ whitelabel }) => whitelabel.currency);
  const language = useSelector(({ whitelabel }) => whitelabel.language);
  const cart = useSelector(({ carts }) => getCurrentCart(carts, cartUUID));

  const useQuantities =
    useSelector(({ whitelabel }) => whitelabel.configs.useQuantities) &&
    cart?.financingType !== 'UPGRADE';

  const canChangeSalePrice =
    useSelector(({ whitelabel }) => whitelabel.configs.canChangeSalePrice) &&
    cart?.financingType === 'B2B_LEASE';

  const canQuickQuote =
    useSelector(({ whitelabel }) => whitelabel.configs.canQuickQuote) &&
    cart?.financingType === 'B2B_LEASE';

  const paymentPlanOptions = useSelector(
    ({ catalog }) => catalog.paymentPlanOptions || [],
  );
  const canFinance = useSelector(
    ({ whitelabel }) => whitelabel.configs.canFinance,
  );
  const availableDiscounts = useSelector(
    ({ whitelabel }) => whitelabel.configs.availableDiscounts,
  );
  const selectedService = cart?.selectedService;

  const selectedPaymentPlan =
    paymentPlanOptions?.[0]?.options?.filter(
      (paymentPlan) => paymentPlan?.selected,
    )[0] || {};

  useEffect(() => {
    dispatch(
      changeOrderPPAction(cartUUID, selectedPaymentPlan?.id, selectedService),
    );
  }, [cartUUID, dispatch]);

  const handleNavigation = (cartUUID) => {
    canFinance
      ? navigate(customerDetailsUrl(cartUUID))
      : dispatch(addNotificationAction(blockedFeature()));
  };

  const addNewProduct = () => {
    navigate(`/posale/standard/${cartUUID}`);
  };

  const hiddenBackButtons = () => cart.financingType === 'UPGRADE';

  if (loading) return <Loader />;

  let orders = [];

  if (cart) {
    orders = [cart.current_order, ...cart.orders].filter(
      ({ products, extras, discounts }) =>
        !isEmpty(products?.items) ||
        !isEmpty(extras?.items) ||
        !isEmpty(discounts?.items),
    );
  }

  const totalItems =
    !isEmpty(orders) &&
    orders.reduce((acc, order) => {
      return acc + order.products.items.length + order.extras.items.length;
    }, 0);

  if (totalItems === 0 && paymentPlanOptions?.[0]?.options?.length) {
    paymentPlanOptions[0].options = paymentPlanOptions[0].options.map(
      (option) => {
        option.enabled = false;
        return option;
      },
    );
  }

  return (
    <>
      <Multistep
        title={t({ id: 'cart.details.title', message: `Cart Details` })}
      />

      {totalItems !== 0 && (
        <PaymentPlanSelect
          options={paymentPlanOptions}
          currency={currency}
          language={language}
          classes='left'
          // TODO make this component reliant only on class functions
          // in order to remove arrow functions and enforce this rule
          // eslint-disable-next-line react/jsx-no-bind
          action={(paymentplanId) => {
            dispatch(changeOrderPPAction(cartUUID, paymentplanId));
          }}
        />
      )}

      {(canQuickQuote || totalItems > 0) && (
        <CartList
          language={language}
          currency={currency}
          cart={cart}
          cartUUID={cartUUID}
          totalItems={totalItems}
          availableDiscounts={availableDiscounts}
          actionable
          useQuantities={useQuantities}
          selectedPaymentPlan={selectedPaymentPlan}
          canChangeSalePrice={canChangeSalePrice}
          canQuickQuote={canQuickQuote}
        />
      )}

      <Footer
        classes='main'
        copyright={false}
        backBtnAction={addNewProduct}
        backBtnLabel={t({
          id: 'button.add.new.product',
          message: `Add new product`,
        })}
        backBtnHidden={hiddenBackButtons()}
        nextBtnLabel={t({ id: 'button.checkout', message: `Checkout` })}
        nextBtnDisabled={!hasDevicesInOrders(orders)}
        // eslint-disable-next-line react/jsx-no-bind
        nextBtnAction={() => handleNavigation(cartUUID)}
      />
    </>
  );
};

export default CartDetails;
