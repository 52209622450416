import classNames from 'classnames';
import PropTypes from 'prop-types';

import React from 'react';

import './List.styl';

const List = ({
  data,
  onClick,
  keyGenerator,
  itemClasses,
  render,
  listClasses,
  testSelector,
}) => {
  return (
    <ul
      className={classNames('renderPropList', listClasses)}
      data-test={testSelector}
    >
      {data?.map((item) => (
        <li
          key={keyGenerator(item)}
          // TODO: remove in favor of using rendered items to control behavior
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => onClick && onClick(item)}
          className={itemClasses(item)}
        >
          {render(item)}
        </li>
      ))}
    </ul>
  );
};

List.defaultProps = {
  itemClasses: () => {},
};

List.propTypes = {
  data: PropTypes.array.isRequired,
  keyGenerator: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  render: PropTypes.func.isRequired,
  listClasses: PropTypes.string,
  itemClasses: PropTypes.func,
};

export default List;
