import React from 'react';

import './Hyperlink.styl';

const Hyperlink = (props) => {
  const { variant, content, onClick, classes } = props;

  const handleOnClick = (event) => {
    event.preventDefault();
    onClick();
  };

  return (
    <a
      onClick={handleOnClick}
      className={`hyperlink ${variant} ${classes}`}
      {...props}
    >
      {content}
    </a>
  );
};

export default Hyperlink;
