import footerlogo from 'assets/images/TDSYNNEX-transparent.svg';
import PropTypes from 'prop-types';

import React from 'react';

import Button from 'components/Button';

import './Footer.styl';

const Footer = (props) => {
  const {
    backBtnHidden,
    nextBtnHidden,
    backBtnLabel,
    nextBtnLabel,
    backBtnAction,
    nextBtnAction,
    backBtnDisabled,
    nextBtnDisabled,
    backBtnLoading,
    nextBtnLoading,
    copyright,
    classes,
  } = props;

  return (
    <div className={`footer ${classes}`}>
      <div className='footer-inner-wrapper'>
        {!backBtnHidden && backBtnAction && (
          <Button
            onClick={backBtnAction}
            classes='prev'
            disabled={backBtnDisabled}
            loading={backBtnLoading}
          >
            {backBtnLabel}
          </Button>
        )}

        <div className='branding'>
          <img
            src={footerlogo}
            alt='TD SYNNEX'
            data-test='footer-td-synnex-logo'
          />
        </div>

        {copyright && (
          <span className='copyright' data-test='footer-copyright'>
            &copy;{' '}
            {`Copyright ${new Date().getFullYear()} - All Rights Reserved`}
          </span>
        )}

        {!copyright && (
          <div className='build'>{`version #${process.env.__COMMIT_HASH__}`}</div>
        )}

        {!nextBtnHidden && nextBtnAction && (
          <Button
            onClick={nextBtnAction}
            classes='next'
            disabled={nextBtnDisabled}
            loading={nextBtnLoading}
          >
            {nextBtnLabel}
          </Button>
        )}
      </div>
    </div>
  );
};

Footer.defaultProps = {
  backBtnHidden: false,
  nextBtnHidden: false,
  backBtnLabel: '',
  nextBtnLabel: '',
  backBtnAction: null,
  nextBtnAction: null,
  backBtnDisabled: false,
  nextBtnDisabled: false,
  copyright: true,
  classes: '',
};

Footer.propTypes = {
  backBtnHidden: PropTypes.bool,
  backBtnLabel: PropTypes.string,
  nextBtnLabel: PropTypes.string,
  backBtnAction: PropTypes.func,
  nextBtnAction: PropTypes.func,
  backBtnDisabled: PropTypes.bool,
  nextBtnDisabled: PropTypes.bool,
  copyright: PropTypes.bool,
  classes: PropTypes.string,
};

export default Footer;
