import { fulfillmentReturnAction } from 'actions/sales';
import { closeModalAction, openModalAction } from 'actions/ui';
import { length, required } from 'helpers/validators';
import { Field, getFormValues, reduxForm } from 'redux-form';

import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import Heading from 'components/Heading';
import Modal from 'components/Modal';
import RadioButtons from 'components/RadioButtons';
import RenderTextAreaField from 'components/RenderTextAreaField';

import {
  FULFILLMENT_REPLACE,
  returnOptions,
  returnTypeItems,
} from 'constants/contracts';
import {
  fulfillmentCancelSuccessful,
  fulfillmentReplaceSuccessful,
} from 'constants/notifications';

import './ReturnAndCancelOrder.styl';

const CancelOrder = ({
  fulfillmentReturn,
  openModal,
  cartUUID,
  closeModal,
  formValues,
  invalid,
}) => {
  const [fulfillmentReturnType, setFulfillmentReturnType] = useState(undefined);

  const handleReturn = async () => {
    await fulfillmentReturn({
      ...formValues,
      cartUUID,
      fulfillmentReturnType,
      successNotification:
        fulfillmentReturnType === FULFILLMENT_REPLACE
          ? fulfillmentReplaceSuccessful
          : fulfillmentCancelSuccessful,
    });
    closeModal();
  };

  const openCancelModal = () => openModal(`cancelContract-${cartUUID}`);

  return (
    <Fragment>
      <Button
        onClick={openCancelModal}
        classes='contract-actions--right red'
        testSelector='contract-action-cancel-modal'
      >
        <Trans id='sale.return.cancel.contract.button'>Return / Cancel</Trans>
      </Button>

      <Modal
        name={`cancelContract-${cartUUID}`}
        buttons={[
          {
            label: t({
              id: 'sale.return.finish.btn',
              message: `Finish process`,
            }),
            action: handleReturn,
            disabled: invalid,
          },
          {
            label: t({
              id: 'sale.return.close.btn',
              message: `Close modal`,
            }),
            action: closeModal,
            classes: 'inverted',
          },
        ]}
        classes='returnContract'
      >
        <form className='return-form'>
          <Heading size='medium'>
            <Trans id='sale.return.title'>Contract</Trans>
            {` ${cartUUID}`}
          </Heading>

          <div>
            <Trans id='sale.return.description'>
              After select options and click on “Finish process” button,
              contract will stay in “Pending” status until device is not
              returned to the RETAILER. After device is received and reviewed,
              process status will be updated.
            </Trans>
          </div>
          <RadioButtons
            options={returnTypeItems}
            selectedValue={fulfillmentReturnType}
            onClick={(type) => setFulfillmentReturnType(type.value)}
            orientation='vertical'
          />

          <div>
            <Trans id='sale.return.reason'>
              Please select return / cancel reason from menu below
            </Trans>
          </div>
          <Field
            name='returnReasonCode'
            classes='return-form--select'
            component={Dropdown}
            disabled={!fulfillmentReturnType}
            placeholder={t({
              id: 'sale.return.reason.placeholder',
              message: `Cancel reason`,
            })}
            options={returnOptions}
            validate={required()}
            testSelector='return-form-code'
          />

          <div>
            <Trans id='sale.return.additional.comment'>
              Additional information
            </Trans>
          </div>
          <Field
            name='conditionDescription'
            className='return-form--textArea'
            disabled={!fulfillmentReturnType}
            fieldName={t({
              id: 'sale.return.condition.decision.name',
              message: `Additional information`,
            })}
            placeholder={t({
              id: 'sale.return.condition.decision',
              message: `Add comment here...`,
            })}
            component={RenderTextAreaField}
            validate={(length({ max: 2000 }), required())}
            testSelector='return-form-condition-decision'
          />
        </form>
      </Modal>
    </Fragment>
  );
};

export default reduxForm({
  form: 'returnForm',
  enableReinitialize: true,
  destroyOnUnmount: true,
})(
  connect(
    (state) => ({
      formValues: getFormValues('returnForm')(state),
    }),
    {
      fulfillmentReturn: fulfillmentReturnAction,
      openModal: openModalAction,
      closeModal: closeModalAction,
    },
  )(CancelOrder),
);
