import { getVouchers, uploadVouchers } from 'actions/admin/voucher';

import React from 'react';
import { useDispatch } from 'react-redux';

import { Trans } from '@lingui/macro';

import FileUploadReport from 'components/file_upload/FileUploadReport';

const VoucherUpload = () => {
  const dispatch = useDispatch();

  const handleSubmit = async (vouchers) => {
    const response = await dispatch(uploadVouchers(vouchers));
    dispatch(getVouchers());
    return response;
  };

  const successCols = [
    {
      label: `PDF`,
      accessor: 'pdf',
      align: 'left',
    },
    {
      label: `Code`,
      accessor: 'code',
      align: 'left',
    },
  ];

  return (
    <FileUploadReport
      title={<Trans id='voucher.upload.title'>Upload Vouchers</Trans>}
      fileFormat='.xlsx'
      formFieldLabel={<Trans id='voucher.upload.field.label'>XLS file</Trans>}
      formName='vouchersUploadForm'
      reportAcessor='voucherList'
      resultName={<Trans id='voucher.upload.result'>voucher</Trans>}
      successCols={successCols}
      uploadFile={handleSubmit}
    />
  );
};

export default VoucherUpload;
