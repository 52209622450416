import {
  createCohortRuleAction,
  getCampaignFormDataAction,
  updateCohortRuleAction,
} from 'actions/admin/campaign';
import { required } from 'helpers/validators';
import isEmpty from 'lodash/isEmpty';
import { clearFields, getFormValues } from 'redux-form';

import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import ButtonForm from 'components/ButtonForm';
import Dropdown from 'components/Dropdown';

import './CohortForm.styl';

const CohortForm = ({
  campaignCode,
  deviceCategories,
  deviceFamilies,
  devices,
  financingTypes,
  createCohortRule,
  updateCohortRule,
  onSubmit,
  onClose,
  manufacturers,
  contractStatuses,
  getCampaignFormData,
  clearFormFields,
  formValues,
  initialValues,
}) => {
  const fields = [
    {
      component: Dropdown,
      clearable: true,
      placeholder: t({
        id: 'forms.cohort.placeholder.contract.status',
        message: `Contract Status`,
      }),

      options: contractStatuses,
      name: 'contractStatus',
      size: 'medium',
      testSelector: 'addCohortStatusDropdown',
      variant: 'underline',
      validate: [required()],
    },
    {
      component: Dropdown,
      clearable: true,
      placeholder: t({
        id: 'forms.cohort.placeholder.financing.type',
        message: `Financing Type`,
      }),
      options: financingTypes,
      name: 'financingType',
      size: 'medium',
      testSelector: 'addCohortFinancingTypeDropdown',
      variant: 'underline',
    },
    {
      component: Dropdown,
      clearable: true,
      placeholder: t({
        id: 'forms.cohort.placeholder.financing.duration',
        message: `Financing Duration`,
      }),
      options: [
        { label: '12', value: 12 },
        { label: '24', value: 24 },
        { label: '36', value: 36 },
      ],
      helpText: t({
        id: 'forms.cohort.financing.duration.text',
        message: `Number of months that define the contracts`,
      }),
      name: 'financingDuration',
      size: 'medium',
      testSelector: 'addCohortFinancingDurationDropdown',
      variant: 'underline',
    },
    {
      component: Dropdown,
      clearable: true,
      options: manufacturers,
      placeholder: t({
        id: 'forms.cohort.placeholder.manufacturer',
        message: `Manufacturer`,
      }),
      name: 'manufacturer',
      size: 'medium',
      testSelector: 'addCohortManufacturersDropdown',
      variant: 'underline',
      disabled: isEmpty(manufacturers),
      onChange: (value) => {
        getCampaignFormData({ manufacturer: value });
        clearFormFields(
          'cohortForm',
          false,
          false,
          'category',
          'family',
          'deviceCode',
        );
      },
    },
    {
      component: Dropdown,
      clearable: true,
      options: deviceCategories,
      placeholder: t({
        id: 'forms.cohort.placeholder.device.category',
        message: `Device Category`,
      }),
      name: 'category',
      size: 'medium',
      testSelector: 'addCohortDeviceCategoryDropdown',
      variant: 'underline',
      disabled: isEmpty(deviceCategories),
      onChange: (value) => {
        getCampaignFormData({
          manufacturer: formValues.manufacturer,
          category: value,
        });
        clearFormFields('cohortForm', false, false, 'family', 'deviceCode');
      },
    },
    {
      component: Dropdown,
      clearable: true,
      options: deviceFamilies,
      placeholder: t({
        id: 'forms.cohort.placeholder.device.family',
        message: `Device Family`,
      }),
      name: 'family',
      size: 'medium',
      testSelector: 'addCohortDeviceFamilyDropdown',
      variant: 'underline',
      disabled: isEmpty(deviceFamilies),
      onChange: (value) => {
        getCampaignFormData({
          manufacturer: formValues.manufacturer,
          category: formValues.category,
          family: value,
        });
        clearFormFields('cohortForm', false, false, 'deviceCode');
      },
    },
    {
      component: Dropdown,
      clearable: true,
      options: devices,
      disabled: isEmpty(devices),
      placeholder: t({
        id: 'forms.cohort.placeholder.device.code',
        message: `Device Code`,
      }),
      name: 'deviceCode',
      size: 'medium',
      testSelector: 'addCohortDeviceCodeDropdown',
      variant: 'underline',
    },
  ];

  const getFormDataCallBack = useCallback(() => {
    const data = formValues || initialValues;
    getCampaignFormData({
      manufacturer: data?.manufacturer,
      category: data?.category,
      family: data?.family,
    });
  }, [formValues, initialValues, getCampaignFormData]);

  const handleSubmit = async (values) => {
    const request = isEmpty(initialValues)
      ? createCohortRule
      : updateCohortRule;
    return await request({
      campaignCode,
      ...values,
      manufacturerName: manufacturers?.find(
        ({ value }) => values?.manufacturer === value,
      )?.label,
      deviceName: devices?.find(({ value }) => values?.deviceCode === value)
        ?.label,
      familyName: deviceFamilies?.find(({ value }) => values?.family === value)
        ?.label,
      categoryName: deviceCategories?.find(
        ({ value }) => values?.category === value,
      )?.label,
    });
  };

  return isEmpty(initialValues) ? (
    <ButtonForm
      name='cohortForm'
      getFormData={getFormDataCallBack}
      fields={fields}
      title={<Trans id='cohort.form.add.title'>Add Cohort Rule</Trans>}
      openBtnLabel={<Trans id='cohort.form.add.button'>Add Cohort Rule</Trans>}
      handleSubmit={handleSubmit}
      onSubmitSuccess={onSubmit}
      onClose={onClose}
    />
  ) : (
    <ButtonForm
      name='cohortForm'
      getFormData={getFormDataCallBack}
      fields={fields}
      title={<Trans id='cohort.form.edit.title'>Edit Cohort Rule</Trans>}
      openBtnLabel={
        <Trans id='cohort.form.edit.button'>Edit Cohort Rule</Trans>
      }
      handleSubmit={handleSubmit}
      onSubmitSuccess={onSubmit}
      onClose={onClose}
      values={initialValues}
      toggleShowForm={true}
    />
  );
};

export default connect(
  (state) => ({
    formValues: getFormValues('cohortForm')(state),
    cohortTypes: state.adminCampaign.cohortTypes,
    campaignCode: state.adminCampaign.campaign.campaignCode,
    deviceCategories: state.adminCampaign.deviceCategories,
    deviceFamilies: state.adminCampaign.deviceFamilies,
    devices: state.adminCampaign.devices,
    financingTypes: state.adminCampaign.financingTypes,
    manufacturers: state.adminCampaign.manufacturers,
    contractStatuses: state.adminCampaign.contractStatuses,
  }),
  {
    getCampaignFormData: getCampaignFormDataAction,
    createCohortRule: createCohortRuleAction,
    updateCohortRule: updateCohortRuleAction,
    clearFormFields: clearFields,
  },
)(CohortForm);
