import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import React from 'react';

import { Trans } from '@lingui/macro';

import Button from 'components/Button';

import './Form.styl';

export const Form = ({
  autoComplete = 'off',
  classes,
  closeForm,
  customFieldValidation = false,
  disabled = false,
  handleSubmit,
  invalid,
  fields = [],
  actionButtons = [],
  form,
  onSubmit,
  notificationPos = 'bottom',
  submitLabel,
  submitting,
}) => {
  const handleButtonOnClick = (handleClick) => (evt) => {
    evt.preventDefault();
    evt.target.blur();
    handleClick ? handleClick() : handleSubmit();
  };

  return (
    <form
      autoComplete={autoComplete}
      className={classNames('form', classes)}
      onSubmit={onSubmit}
    >
      {fields.map(
        ({ classes, name, component, validate, testSelector, ...rest }) => (
          <Field
            key={`form-${form}-field-${name}`}
            name={name}
            classes={classNames(classes)}
            component={component}
            validate={validate}
            testSelector={testSelector}
            notificationPos={notificationPos}
            {...rest}
          />
        ),
      )}

      <div className='form-button-wrapper'>
        {submitLabel && (
          <Button
            key={`form-${form}-submit-btn`}
            disabled={submitting || invalid || disabled}
            onClick={handleButtonOnClick()}
            testSelector={`submit-${form}-form`}
          >
            {submitLabel}
          </Button>
        )}

        {!isEmpty(actionButtons) &&
          actionButtons.map(
            ({
              cx,
              disabled: btnDisabled = false,
              handleClick,
              label,
              name,
              testSelector,
              type = 'button',
              loading,
            }) => (
              <Button
                classes={cx}
                disabled={
                  customFieldValidation ? btnDisabled : submitting || invalid
                }
                key={`form-${form}-${name}`}
                onClick={handleButtonOnClick(handleClick)}
                testSelector={testSelector}
                type={type}
                loading={loading}
              >
                {label}
              </Button>
            ),
          )}

        {closeForm && (
          <Button key={`form-${form}-close-btn`} onClick={closeForm}>
            <Trans id='form.close.button.label'>Close</Trans>
          </Button>
        )}
      </div>
    </form>
  );
};

Form.propTypes = {
  classes: PropTypes.string,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      component: PropTypes.func.isRequired,
      classes: PropTypes.string,
      variant: PropTypes.string,
    }),
  ).isRequired,
  actionButtons: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      handleClick: PropTypes.func.isRequired,
      disabled: PropTypes.bool,
    }),
  ),
  form: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
};

export default reduxForm()(Form);
