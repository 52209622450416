import {
  addDevice,
  evaluateDeviceAction,
  getCriteriaAction,
  removeDevice,
  revalidateDeviceReferenceAction,
  saveEvaluationAnswerAction,
  searchDevices,
  selectDevicePromotion,
  setDeviceReference,
  setDeviceSimStatus,
} from 'actions/recycle';
import { addNotificationAction } from 'actions/ui';
import { openModalAction } from 'actions/ui';
import classNames from 'classnames';
import { currencyFormatter } from 'helpers/formatters';
import { isValidIMEI } from 'helpers/validators';
import isEmpty from 'lodash/isEmpty';
import { currencySymbolToISO3 } from 'utils';
import { allGradingQuestionsAnswered } from 'utils';

import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';

import Footer from 'components/Footer';
import Heading from 'components/Heading';
import { ImageThumbnail } from 'components/Image';
import LineItem from 'components/LineItem';
import Modal from 'components/Modal';
import RadioButtons from 'components/RadioButtons';
import Section from 'components/Section';
import TabbedContent from 'components/TabbedContent.js';
import DeviceReferenceForm from 'components/device_reference/DeviceReferenceForm';
import Criteria from 'components/recycle/Criteria';
import DeviceDisownmentChecks from 'components/recycle/DeviceDisownmentChecks';
import PromotionsDropdown from 'components/recycle/PromotionsDropdown';
import SwapLegalText from 'components/recycle/SwapLegalText';
import Table from 'components/table/Table';

import { IMEI, SERIAL } from 'constants/deviceReference';
import {
  addedToTradeCartSuccessful,
  referenceAlreadyInTradeCartWarning,
  removedFromTradeCartSuccessful,
  replaceInTradeCartSuccessful,
} from 'constants/notifications';
import { SWAP, appleDEPLink, removeICloudLink } from 'constants/recycle';
import {
  SIM_STATUS_LOCKED,
  SIM_STATUS_UNLOCKED,
  simStatusLabels,
} from 'constants/recycle';
import { recycleOutcomesOrderUrl, recycleOutcomesUrl } from 'constants/routes';

import DeviceDiagnosticTest from './DeviceDiagnosticTest';
import DeviceNameForm from './DeviceNameForm';
import IconAlertSmall from './IconAlertSmall';
import IconOkSmall from './IconOkSmall';
import IconTrashRed from './IconTrashRed';
import IconWarningSmall from './IconWarningSmall';
import './MultiSearch.styl';

export const MultiSearch = ({ recycleType, orderUUID }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const language = useSelector(({ whitelabel }) => whitelabel.language);
  const country = useSelector(({ whitelabel }) => whitelabel.country);
  const currency = useSelector(({ whitelabel }) => whitelabel.currency);
  const referenceType = useSelector(({ recycle }) => recycle.referenceType);
  const deviceReference = useSelector(({ recycle }) => recycle.deviceReference);
  const devices = useSelector(({ recycle }) => recycle.devices);
  const selectedDevices = useSelector(({ recycle }) => recycle.selectedDevices);
  const loading = useSelector(({ ui }) => ui.loading);
  const onlineQuote = useSelector(({ recycle }) => recycle.onlineQuote);

  const [defaultExpandedDevice, setDefaultExpandedDevice] = useState(1);
  const [deleteDevice, setDeleteDevice] = useState({});

  const evaluationsDifference =
    onlineQuote?.devices[0]?.value -
    selectedDevices[0]?.evaluation?.deviceValue;

  const handleSelectDevice = useCallback(
    async (device, replace) => {
      const searchForms = document.getElementById('deviceFormContainer');
      //TODO for some reason, if the smooth property is added, the scroll wont function at all. i don't know why,
      //it works properly on other parts of the application with the same approach.
      searchForms &&
        searchForms.scrollIntoView({
          block: 'center',
          //behavior: 'smooth',
          inline: 'start',
        });

      // If the selected device for a device reference is already selected, it needs to be removed since
      // there can only be one device per imei/serial
      if (deviceReference && devices.length > 1) {
        const selectedDeviceReference = selectedDevices.filter(
          (device) => device.deviceReference === deviceReference,
        )[0];
        selectedDeviceReference &&
          (await dispatch(removeDevice(selectedDeviceReference.id)));
      }
      const questionsTree = await dispatch(
        getCriteriaAction({ selectedDevice: device }),
      );

      dispatch(
        addDevice({
          ...device,
          questionsTree,
          searchedByString: isEmpty(deviceReference),
          deviceReference,
        }),
      );

      if (replace) {
        dispatch(addNotificationAction(replaceInTradeCartSuccessful()));
      } else {
        dispatch(addNotificationAction(addedToTradeCartSuccessful()));
      }
    },
    [deviceReference, devices.length, dispatch, selectedDevices],
  );

  const openDeviceRemovalModal =
    ({ id, name }) =>
    () => {
      setDeleteDevice({ id, name });
      dispatch(openModalAction('remove-device'));
    };

  const isDeviceSelected = useCallback(
    (device) =>
      selectedDevices?.some(
        (selectedDevice) => selectedDevice.deviceID === device.deviceID,
      ),
    [selectedDevices],
  );

  const isReferenceSelected = useCallback(
    (deviceReference) =>
      selectedDevices?.some(
        (selectedDevice) =>
          selectedDevice.deviceReference &&
          selectedDevice.deviceReference === deviceReference,
      ),
    [selectedDevices],
  );

  const isDeviceTradable = useCallback(
    (device) =>
      device?.evaluation?.grade !== 'NWZ' &&
      device?.evaluation?.deviceValue !== 0,
    [],
  );

  const shouldShowDiagnosticTest = useCallback(
    (device) =>
      device?.hasDiagnostics &&
      device?.useDiagnosticsMinval <= device?.evaluation?.deviceValue &&
      isDeviceTradable(device),
    [isDeviceTradable],
  );

  const isMissingDiagnostic = useCallback(
    (device) =>
      shouldShowDiagnosticTest(device) &&
      device.diagnostic?.reportStatus !== 'completed',
    [shouldShowDiagnosticTest],
  );

  const isEvaluationComplete = useCallback(
    (device) =>
      device?.checkmendOk !== false &&
      !device?.fmi?.isLocked &&
      !device?.fmi?.isLost &&
      !device?.fmi?.isActivated &&
      !isEmpty(device?.deviceReference) &&
      !isEmpty(device?.evaluation) &&
      (shouldShowDiagnosticTest(device)
        ? !isMissingDiagnostic(device) && isDeviceTradable(device)
        : isDeviceTradable(device)),
    [shouldShowDiagnosticTest, isMissingDiagnostic, isDeviceTradable],
  );

  const handleDeviceSearch = useCallback(
    async ({ deviceReference, referenceType, searchText }) => {
      if (isReferenceSelected(deviceReference)) {
        dispatch(addNotificationAction(referenceAlreadyInTradeCartWarning()));
      } else {
        await dispatch(
          searchDevices({
            deviceReference,
            referenceType,
            searchText,
            orderUUID,
          }),
        );
      }
    },
    [dispatch, isReferenceSelected, orderUUID],
  );

  const isSwap = useCallback(() => recycleType === SWAP, [recycleType]);

  useEffect(() => {
    if (
      devices.length === 1 &&
      referenceType &&
      !isDeviceSelected(devices[0])
    ) {
      handleSelectDevice(devices[0]);
    }
  }, [devices, referenceType, handleSelectDevice, isDeviceSelected]);

  useEffect(() => {
    const currentExpandedDevice = selectedDevices.find(
      (device) => device.id === defaultExpandedDevice,
    );

    if (currentExpandedDevice) {
      if (isEvaluationComplete(currentExpandedDevice)) {
        setDefaultExpandedDevice(
          selectedDevices.find((device) => !isEvaluationComplete(device))?.id,
        );
      }
    } else {
      setDefaultExpandedDevice(
        selectedDevices.find((device) => !isEvaluationComplete(device))?.id,
      );
    }
  }, [selectedDevices, defaultExpandedDevice, isEvaluationComplete]);

  useEffect(() => {
    if (orderUUID && selectedDevices.length === 0) {
      handleDeviceSearch({
        deviceReference,
        referenceType,
      });
    }
  }, [
    selectedDevices,
    orderUUID,
    referenceType,
    deviceReference,
    handleDeviceSearch,
  ]);

  useEffect(() => {
    //TODO i wasn't sure if it would be a good idea to pass via prop an id for the tabbed content. The initial
    // approach was to give ids to the forms individually, but if the tab was clicked with the section collapsed, it renders
    // the component because it doesn't add the collapsable class (since its hidden). this might be a temporary fix for that behaviour
    if (!isSwap() && isEmpty(onlineQuote)) {
      const tabbedContentBody = document.getElementsByClassName(
        'tabbedContent--content',
      )[0];

      tabbedContentBody.id = 'deviceFormContainer';
    }
  }, [isSwap, onlineQuote]);

  const showPromotions = (device) =>
    device.promotions?.length > 0 &&
    !isEmpty(device.evaluation) &&
    !isMissingDiagnostic(device);

  const handleSetDeviceReference = (device) => async (data) => {
    await dispatch(setDeviceReference(data, device.id));
    await dispatch(
      revalidateDeviceReferenceAction({
        ...data,
        device,
        isMultiTrade: true,
      }),
    );
  };

  const handleSetDeviceSimStatus = (device) => (option) => {
    dispatch(setDeviceSimStatus(device?.id, option.value));
    if (!isEmpty(device?.evaluation)) {
      dispatch(
        evaluateDeviceAction({
          orderUUID,
          selectedDevice: { ...device },
          selectedSIMStatus: option.value,
        }),
      );
    }
  };

  const handleDeviceConditionAnswer = async (
    question,
    answer,
    type,
    device,
    selectedSIMStatus,
  ) => {
    await dispatch(
      saveEvaluationAnswerAction({
        answer,
        question,
        selectedDevice: device,
        selectedSIMStatus,
        type,
        orderUUID,
      }),
    );

    // window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  };

  const handleSelectPromotion = (device) => (value) => {
    dispatch(selectDevicePromotion(device.id, value));
    dispatch(
      evaluateDeviceAction({
        orderUUID,
        selectedDevice: { ...device, promotionID: value },
        selectedSIMStatus: device.selectedSIMStatus,
      }),
    );
  };

  const shouldShowSIMStatusSelection = (device) =>
    device?.hasNetwork && device.deviceReference;

  const shouldShowCriteriaQuestions = (device) =>
    !isEmpty(device?.deviceReference) &&
    (!device?.hasNetwork || device?.networkLockStatus) &&
    device?.questionsTree;

  const disableContinueBtn = () =>
    selectedDevices.length === 0 ||
    selectedDevices.some(
      (selectedDevice) =>
        isEmpty(selectedDevice.evaluation) ||
        !isEvaluationComplete(selectedDevice),
    );

  const navigateToOutcomes = () =>
    orderUUID
      ? navigate(recycleOutcomesOrderUrl(orderUUID))
      : navigate(recycleOutcomesUrl);

  const columns = [
    {
      label: t({ id: 'table.column.manufacturer', message: `Manufacturer` }),
      accessor: 'manufacturerCode',
      type: 'text',
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.model', message: `Model` }),
      accessor: 'name',
      type: 'text',
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.quote.up.to', message: `Quote up to` }),
      accessor: 'maxValue',
      type: 'currency',
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.actions', message: `Actions` }),
      accessor: 'actions',
      type: 'actions',
      filterable: false,
      sortable: false,
      align: 'right',
      wrap: false,
    },
  ];

  const addActions = [
    {
      label: t({ id: 'table.column.actions.add.cart', message: `Add to cart` }),
      handler: (_, elem) => {
        handleSelectDevice(elem, false);
      },
      disabled: (elem) => referenceType && isDeviceSelected(elem),
    },
  ];

  const updateActions = [
    {
      label: t({
        id: 'table.column.actions.update.cart',
        message: `Update device`,
      }),
      handler: (_, elem) => {
        handleSelectDevice(elem, true);
      },
      disabled: (elem) => referenceType && isDeviceSelected(elem),
    },
  ];

  const rows = devices?.map((device) => ({
    ...device,
    actions: isReferenceSelected(device.deviceReference)
      ? updateActions
      : addActions,
  }));

  const renderEvaluationIcon = (device) => {
    if (!isEmpty(device.evaluation) && !isMissingDiagnostic(device)) {
      if (!isDeviceTradable(device)) {
        return <IconAlertSmall />;
      }
      return <IconOkSmall />;
    }
    return <IconWarningSmall />;
  };

  const TradeCartCounter = ({ totalDevices, totalEvaluatedDevices = 0 }) => (
    <div className='tradecart-counter'>
      {t({
        id: 'trade.cart.counter',
        message: `Device(s) in cart: ${totalDevices} | Evaluated: ${totalEvaluatedDevices}`,
      })}
    </div>
  );

  const setReferenceType = (device) => {
    if (device.deviceReference)
      return !isValidIMEI(device.deviceReference) ? SERIAL : IMEI;
    else return device.hasNetwork ? IMEI : SERIAL;
  };

  return (
    <Fragment>
      <Heading classes='multisearch-heading' size='big'>
        <Trans id='recycle.multisearch.title'>Device(s) evaluation</Trans>
      </Heading>

      {!isEmpty(onlineQuote) && (
        <Section>
          <LineItem
            label={`${t({
              id: 'online.tradein.quoteid',
              message: `Quote ID`,
            })}:`}
            value={onlineQuote.tradecartID}
          />

          {onlineQuote.devices.map((item) => (
            <Fragment>
              <LineItem
                label={`${t({
                  id: 'recycle.summary.device',
                  message: `Device`,
                })}:`}
                value={item.name}
              />

              <LineItem
                label={`${t({
                  id: 'recycle.device.imei',
                  message: `IMEI / Serial Number`,
                })}:`}
                value={item.deviceReference}
              />

              <LineItem
                label={`${t({
                  id: 'recycle.sim.status',
                  message: `SIM Status`,
                })}:`}
                value={
                  item.networkStatus ? (
                    item.networkStatus
                  ) : (
                    <Trans id='recycle.multisearch.summary.not.available'>
                      N/A
                    </Trans>
                  )
                }
              />

              <LineItem
                label={`${t({
                  id: 'recycle.condition.description',
                  message: `Condition`,
                })}:`}
                value={item.grade}
              />

              <LineItem
                label={`${t({
                  id: 'recycle.device.value',
                  message: `Device Value`,
                })}:`}
                value={currencyFormatter(
                  item.value,
                  currencySymbolToISO3(currency),
                  language,
                )}
                classes='filled bold'
              />
            </Fragment>
          ))}
        </Section>
      )}

      {selectedDevices?.find((device) =>
        device.manufacturerCode.toLowerCase().match(/apple/),
      ) && (
        <div className='tradecart-disowned-warning'>
          <Trans id='evaluation.trade.icloud.disown.warning'>
            * Please make sure devices are{' '}
            <a
              href={removeICloudLink(country)}
              target='_blank'
              rel='noopener noreferrer'
            >
              {'removed from iCloud'}
            </a>{' '}
            and are disowned from the{' '}
            <a
              href={appleDEPLink(country)}
              target='_blank'
              rel='noopener noreferrer'
            >
              {'Apple Device Enrolment Program (DEP)'}
            </a>
            .
          </Trans>
        </div>
      )}

      <TradeCartCounter
        totalDevices={selectedDevices.length}
        totalEvaluatedDevices={
          selectedDevices.filter((device) => isEvaluationComplete(device))
            .length
        }
      />

      {selectedDevices?.map((device, index) => (
        <div key={device.id} className='tradecart-wrapper'>
          <Section
            key={`${device.id}-${device.deviceID}`}
            expanded={device.id === defaultExpandedDevice}
            className={classNames('tradecart-details', {
              'non-working': !isDeviceTradable(device),
            })}
            inline
            hideById={[`device-evaluation-${device.id}`]}
          >
            <div className='tradecart-index'>
              {(index + 1).toLocaleString(undefined, {
                minimumIntegerDigits: 2,
              })}
            </div>

            <div className='tradecart-device'>
              <div className='tradecart-device-summary'>
                <ImageThumbnail
                  className={'device-image'}
                  src={device?.imageURL}
                  alt={device.name}
                />

                <div className={'device-details'}>
                  <div className='device-name'>
                    {device.name} {renderEvaluationIcon(device)}
                  </div>
                  <div className='device-properties'>
                    <span className='device-property'>
                      <Trans id='recycle.multisearch.summary.imei'>
                        IMEI/SN
                      </Trans>
                    </span>
                    :{' '}
                    {device.deviceReference || (
                      <Trans id='recycle.multisearch.summary.not.available'>
                        N/A
                      </Trans>
                    )}{' '}
                    |{' '}
                    <span className='device-property'>
                      <Trans id='recycle.multisearch.summary.sim.status'>
                        SIM Status
                      </Trans>
                    </span>
                    :{' '}
                    {simStatusLabels(device.networkLockStatus) || (
                      <Trans id='recycle.multisearch.summary.not.available'>
                        N/A
                      </Trans>
                    )}{' '}
                    |{' '}
                    <span className='device-property'>
                      {' '}
                      <Trans id='recycle.multisearch.summary.condition'>
                        Condition
                      </Trans>
                    </span>
                    :{' '}
                    {device?.evaluation?.gradeTitle || (
                      <Trans id='recycle.multisearch.summary.not.available'>
                        N/A
                      </Trans>
                    )}
                  </div>
                </div>

                {!isSwap() && (
                  <div className='device-price'>
                    {currencyFormatter(
                      device?.evaluation?.deviceValue >= 0
                        ? device.evaluation.deviceValue
                        : device.maxValue,
                      currency,
                      language,
                    )}
                    {device.promotions?.find(
                      (promotion) => promotion.id === device.promotionID,
                    )?.value > 0 && (
                      <div className='device-price-promotion'>
                        {t({
                          id: 'promotion.summary.text',
                          message: `Promotional "${
                            device.promotions.find(
                              (promotion) =>
                                promotion.id === device.promotionID,
                            ).title
                          } of ${currencyFormatter(
                            device.promotions.find(
                              (promotion) =>
                                promotion.id === device.promotionID,
                            ).value,
                            currencySymbolToISO3(currency),
                            language,
                          )}" added to the device value`,
                        })}
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div id={`device-evaluation-${device.id}`}>
                <DeviceDisownmentChecks device={device} isMultiTrade />

                {device.searchedByString && isDeviceTradable(device) && (
                  <div>
                    <Heading size='small'>
                      <Trans id='device.evaluation.insert.device.reference'>
                        Insert IMEI / Serial Number
                      </Trans>
                    </Heading>

                    <DeviceReferenceForm
                      form={`deviceReferenceForm-${device.id}`}
                      existingDeviceReferences={selectedDevices.map(
                        (selectedDevice) => selectedDevice.deviceReference,
                      )}
                      disableReferenceTypeField={!device.hasNetwork}
                      destroyOnUnmount={false}
                      onSubmit={handleSetDeviceReference(device)}
                      deviceReference={device.deviceReference}
                      initialreferenceType={setReferenceType(device)}
                      showRefHelper
                    />
                  </div>
                )}

                {shouldShowSIMStatusSelection(device) && (
                  <Fragment>
                    <Heading size='small' data-test='heading-sim-status'>
                      <Trans id='recycle.multisearch.sim.status.title'>
                        SIM Status:
                      </Trans>
                    </Heading>
                    <RadioButtons
                      classes='sim-status-radio-buttons'
                      wrap={true}
                      selectedValue={device.networkLockStatus}
                      onClick={handleSetDeviceSimStatus(device)}
                      options={[
                        {
                          value: SIM_STATUS_LOCKED,
                          label: simStatusLabels(SIM_STATUS_LOCKED),
                        },
                        {
                          value: SIM_STATUS_UNLOCKED,
                          label: simStatusLabels(SIM_STATUS_UNLOCKED),
                        },
                      ]}
                    />
                  </Fragment>
                )}

                {shouldShowCriteriaQuestions(device) && (
                  <Fragment>
                    <Criteria
                      data={[
                        {
                          name: 'criteria',
                          list: device.questionsTree.criteria,
                          answers: device.questionsTree.criteriaAnswers,
                          answerHandler: (question, answer) =>
                            handleDeviceConditionAnswer(
                              question,
                              answer,
                              'criteria',
                              device,
                              device.networkLockStatus,
                            ),
                        },
                      ]}
                    />

                    {allGradingQuestionsAnswered(
                      device.questionsTree.criteriaAnswers,
                    ) && (
                      <Criteria
                        title={t({
                          id: 'evaluation.device.more.questions.title',
                          message: `More Questions`,
                        })}
                        data={[
                          {
                            name: 'addition',
                            list: device.questionsTree.addition,
                            answers: device.questionsTree.additionAnswers,
                            answerHandler: (question, answer) =>
                              handleDeviceConditionAnswer(
                                question,
                                answer,
                                'addition',
                                device,
                                device.networkLockStatus,
                              ),
                          },
                          {
                            name: 'deduction',
                            list: device.questionsTree.deduction,
                            answers: device.questionsTree.deductionAnswers,
                            answerHandler: (question, answer) =>
                              handleDeviceConditionAnswer(
                                question,
                                answer,
                                'deduction',
                                device,
                                device.networkLockStatus,
                              ),
                          },
                        ]}
                      />
                    )}
                  </Fragment>
                )}

                {shouldShowDiagnosticTest(device) && (
                  <DeviceDiagnosticTest device={device} />
                )}
                {showPromotions(device) && (
                  <PromotionsDropdown
                    // eslint-disable-next-line react/jsx-no-bind
                    handleSelect={handleSelectPromotion(device)}
                    disabled={!isDeviceTradable(device)}
                    promotionID={device.promotionID}
                    promotions={device.promotions}
                    currency={currency}
                    language={language}
                  />
                )}
              </div>

              {!isDeviceTradable(device) && (
                <div className='nwz-warning'>
                  {isSwap() ? (
                    <Trans id='recycle.multisearch.device.upgrade.non.working'>
                      This device has 0 value and it cannot be returned.
                    </Trans>
                  ) : (
                    <Trans id='recycle.multisearch.device.non.working'>
                      This device has 0 value and it cannot be traded-in. Please
                      remove it from the cart.
                    </Trans>
                  )}
                </div>
              )}
            </div>
          </Section>
          {!isSwap() && isEmpty(onlineQuote) && (
            <div
              className='tradecart-remove'
              // eslint-disable-next-line react/jsx-no-bind
              onClick={openDeviceRemovalModal(device)}
            >
              <IconTrashRed />
            </div>
          )}
        </div>
      ))}

      {isEmpty(onlineQuote) && !isSwap() && (
        <Section
          className='tradecart-search-forms'
          hideById={[
            'deviceFormContainer',
            'recycle-devices',
            'recycle-devices-pagination',
          ]}
          expanded={true}
        >
          <div>
            <Heading size='medium'>
              <Trans id='recycle.multisearch.forms.title'>Add Device(s)</Trans>
            </Heading>
          </div>

          <TabbedContent
            theme='filled'
            content={[
              {
                tab: (
                  <Trans id='recycle.multisearch.reference.title'>
                    Find device(s) by IMEI/SN
                  </Trans>
                ),
                content: (
                  <DeviceReferenceForm
                    destroyOnUnmount={false}
                    onSubmit={handleDeviceSearch}
                    deviceReference={deviceReference}
                    referenceType={referenceType}
                    showRefHelper
                  />
                ),
                dataTest: 'deviceReference',
              },
              {
                tab: (
                  <Trans id='recycle.multisearch.name.title'>
                    Search by device name
                  </Trans>
                ),
                content: (
                  <DeviceNameForm
                    enableReinitialize={true}
                    destroyOnUnmount={false}
                    onSubmit={handleDeviceSearch}
                  />
                ),
                dataTest: 'search-by-name',
              },
            ]}
          />
          {devices?.length > (referenceType ? 1 : 0) && (
            <Table
              filterable={false}
              id={'recycle-devices'}
              columns={columns}
              data={rows}
              defaultOrder='status'
              keyColumn='deviceID'
            />
          )}
        </Section>
      )}

      <TradeCartCounter
        totalDevices={selectedDevices.length}
        totalEvaluatedDevices={
          selectedDevices.filter((device) => isEvaluationComplete(device))
            .length
        }
      />

      {
        //for the time being, the evaluation is being saved inside the selectedDevice object (that is unused on this component).
        // this should be changed as soon as the evaluation is implemented properly per device
        isSwap() && !isEmpty(selectedDevices[0]?.evaluation) && (
          <Section>
            <SwapLegalText evaluation={selectedDevices[0]?.evaluation} />
          </Section>
        )
      }

      {!isEmpty(onlineQuote) && !isEmpty(selectedDevices[0]?.evaluation) && (
        <Section className='tradecart-info-values'>
          {evaluationsDifference === 0 && (
            <Trans id='recycle.matching.evals.info'>
              This evaluation value,{' '}
              {currencyFormatter(
                selectedDevices[0]?.evaluation?.deviceValue,
                currencySymbolToISO3(currency),
                language,
              )}
              , is equal to the value of the customer's online valuation. Please
              confirm that the customer wishes to proceed with the trade-in.
            </Trans>
          )}

          {evaluationsDifference > 0 && (
            <Trans id='recycle.lower.eval.info'>
              This evaluation value,{' '}
              {currencyFormatter(
                selectedDevices[0]?.evaluation?.deviceValue,
                currencySymbolToISO3(currency),
                language,
              )}
              , is
              {currencyFormatter(
                Math.abs(evaluationsDifference),
                currencySymbolToISO3(currency),
                language,
              )}{' '}
              lower than the value based on the customer's online valuation.
              Please confirm that the customer wishes to proceed with the
              trade-in.
            </Trans>
          )}

          {evaluationsDifference < 0 && (
            <Trans id='recycle.higher.eval.info'>
              This evaluation value,{' '}
              {currencyFormatter(
                selectedDevices[0]?.evaluation?.deviceValue,
                currencySymbolToISO3(currency),
                language,
              )}
              , is
              {currencyFormatter(
                Math.abs(evaluationsDifference),
                currencySymbolToISO3(currency),
                language,
              )}{' '}
              higher than the value based on the customer's online valuation.
              Please confirm that the customer wishes to proceed with the
              trade-in.
            </Trans>
          )}
        </Section>
      )}

      <Modal
        name='remove-device'
        onConfirm={() => {
          dispatch(removeDevice(deleteDevice.id));
          dispatch(addNotificationAction(removedFromTradeCartSuccessful()));
        }}
      >
        <Trans id='recycle.multisearch.remove.text'>
          Do you really want to remove device {deleteDevice.name} ?
        </Trans>
      </Modal>

      <Footer
        classes='main'
        copyright={false}
        nextBtnLabel={
          <Trans id='recycle.multisearch.continue.button'>Continue</Trans>
        }
        nextBtnLoading={loading}
        nextBtnAction={navigateToOutcomes}
        nextBtnDisabled={disableContinueBtn()}
      />
    </Fragment>
  );
};

export default MultiSearch;
