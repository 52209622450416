import React from 'react';

import './CreditAcceptanceRate.styl';

const CreditAcceptanceRate = ({ key, rate, colourCode }) => {
  return (
    <div
      key={key}
      className={`creditAcceptanceRate ${colourCode}`}
      title={rate}
    >
      {rate}%
    </div>
  );
};

export default CreditAcceptanceRate;
