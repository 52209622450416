import * as React from 'react';

function SvgIconTrash({ title, titleId, ...props }) {
  return (
    <svg width='1em' height='1em' aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <clipPath id='icon-trash_svg__a'>
          <path d='M0 0h36v36H0z' />
        </clipPath>
      </defs>
      <g clipPath='url(#icon-trash_svg__a)'>
        <path fill='none' d='M0 0h36v36H0z' />
        <g data-name='Icon / Delete / Ghost'>
          <path
            d='M23 29H13a2.017 2.017 0 01-1.437-.6A1.859 1.859 0 0111 27.083L10.08 16H10a2 2 0 01-2-2v-2a2 2 0 012-2h3V9a2 2 0 012-2h6a2 2 0 012 2v1h3a2 2 0 012 2v2a2 2 0 01-2 2h-.08L25 27a2 2 0 01-2 2zM12.087 16L13 27h10v-.083L23.913 16zM10 12v2h16v-2H10zm5-3v1h6V9z'
            fill='#333'
            data-name='24 / basic / trash'
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconTrash;
