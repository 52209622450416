import {
  clearExtrasCatalogAction,
  saveExtrasAction,
  saveMoreExtrasAction,
  saveProductOptionsAction,
} from 'actions/catalog';
import { setCustomerAction } from 'actions/customer';
import {
  addNotificationAction,
  hideLoaderAction,
  showLoaderAction,
} from 'actions/ui';
import { posalePost as post } from 'apis/v2';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { destroy } from 'redux-form';
import { getCartFromOrder, getCurrentCart } from 'utils';

import { discountLabels } from 'constants/discount';
import { addToCartSuccess } from 'constants/notifications';
import { PAGE_QUANTITY } from 'constants/scroll';

export const CREATE_CART = 'CREATE_CART';
export function createCartAction(tradecartID) {
  return async function (dispatch) {
    dispatch(showLoaderAction());

    const response = await post(CREATE_CART, {
      tradecartID,
    });

    if (response.data.status === 'SUCCESS' && response.data?.data?.cart?.uuid) {
      dispatch({ type: CREATE_CART, cart: response.data.data.cart });

      dispatch(hideLoaderAction());

      return response.data.data.cart.uuid;
    }
  };
}

export const SAVE_FINANCING_TYPE = 'SAVE_FINANCING_TYPE';
export function saveFinancingTypeAction(
  cartUUID,
  financingType,
  tags = [],
  tagFilterType,
) {
  return function (dispatch) {
    dispatch({
      type: SAVE_FINANCING_TYPE,
      cartUUID,
      financingType,
      tags,
      tagFilterType,
    });
  };
}

export const SAVE_SELECTED_CATEGORY = 'SAVE_SELECTED_CATEGORY';
export function saveSelectedCategoryAction(cartUUID, { id, name }) {
  return function (dispatch) {
    dispatch({
      type: SAVE_SELECTED_CATEGORY,
      cartUUID,
      id,
      name,
    });
  };
}

export const UPDATE_SELECTED_PRODUCT = 'UPDATE_SELECTED_PRODUCT';
export function updateSelectedProductAction(cartUUID, changes) {
  return async function (dispatch, getState) {
    await dispatch({
      type: UPDATE_SELECTED_PRODUCT,
      cartUUID,
      changes,
    });

    const category = getCurrentCart(getState().carts, cartUUID)?.category;
    category !== 'ACCESSORIES' &&
      (await dispatch(selectOptionAction(cartUUID)));
  };
}

export const CLEAR_SELECTED_PRODUCT = 'CLEAR_SELECTED_PRODUCT';
export function clearSelectedProductAction(cartUUID) {
  return function (dispatch) {
    dispatch(clearExtrasCatalogAction());
    dispatch({ type: CLEAR_SELECTED_PRODUCT, cartUUID });
  };
}

export const SAVE_SELECTED_FAMILY = 'SAVE_SELECTED_FAMILY';
export function saveSelectedFamilyAction(cartUUID, family) {
  return async function (dispatch) {
    dispatch({ type: SAVE_SELECTED_FAMILY, cartUUID, family });

    await dispatch(
      updateSelectedProductAction(cartUUID, {
        cartUUID,
        deviceCode: family.device_code,
        insuranceID: family.insurance_id,
        paymentplanID: family.paymentplan_id,
        subscriptionID: family.subscription_id,
        hasCTOs: family.has_CTOs,
      }),
    );

    destroy('extrasFilters');
  };
}

export const SAVE_SELECTED_INSURANCE = 'SAVE_SELECTED_INSURANCE';
export function saveSelectedInsuranceAction(cartUUID, insuranceID) {
  return async function (dispatch) {
    dispatch({ type: SAVE_SELECTED_INSURANCE });

    await dispatch(
      updateSelectedProductAction(cartUUID, {
        insuranceID,
      }),
    );
  };
}

export const SAVE_SELECTED_EXTRA_OPTION = 'SAVE_SELECTED_EXTRA_OPTION';
export function saveSelectedExtraOptionAction(cartUUID, selectedExtraOptionID) {
  return async function (dispatch) {
    await dispatch(
      updateSelectedProductAction(cartUUID, {
        selectedExtraOptionID,
      }),
    );
  };
}

export const SAVE_SELECTED_DEVICE = 'SAVE_SELECTED_DEVICE';
export function saveSelectedDeviceAction(cartUUID, payload) {
  return async function (dispatch) {
    dispatch({ type: SAVE_SELECTED_DEVICE });

    await dispatch(updateSelectedProductAction(cartUUID, payload));
  };
}

export const SAVE_SELECTED_PAYMENT_PLAN = 'SAVE_SELECTED_PAYMENT_PLAN';
export function saveSelectedPPAction(
  cartUUID,
  paymentplanID,
  updateExtras = false,
) {
  return async function (dispatch, getState) {
    const cart = getCurrentCart(getState().carts, cartUUID);

    dispatch({ type: SAVE_SELECTED_PAYMENT_PLAN });

    await dispatch(
      updateSelectedProductAction(cartUUID, {
        paymentplanID,
      }),
    );

    updateExtras &&
      dispatch(
        getPricedExtrasAction(cartUUID, cart.selected.deviceCode, {
          updateExtrasPrice: true,
        }),
      );
  };
}

export const SELECT_OPTION = 'SELECT_OPTION';
export function selectOptionAction(cartUUID) {
  return async function (dispatch, getState) {
    const cart = getCurrentCart(getState().carts, cartUUID);
    dispatch(showLoaderAction());

    if (cart) {
      const { selected, financingType, uuid, selectedService } = cart;

      const response = await post(SELECT_OPTION, {
        ...selected,
        financingType,
        selectedServiceID: selectedService?.id,
      });

      if (response?.data?.status !== 'ERROR') {
        const {
          cart: responseCart,
          insurance_options,
          paymentplan_options,
          product_options,
          subscription_options,
          selectedOption,
          extras_options,
        } = response.data.data;

        dispatch({
          type: SELECT_OPTION,
          cartUUID: uuid,
          data: responseCart,
        });

        dispatch(
          saveProductOptionsAction({
            // TODO remove [0]?.options once the BE response changes.
            insuranceOptions: insurance_options.length
              ? insurance_options[0]?.options
              : [],
            paymentPlanOptions: paymentplan_options,
            productOptions: product_options,
            subscriptionOptions: subscription_options,
            selectedOption: selectedOption,
            extrasOptions: extras_options,
          }),
        );
      }

      dispatch(hideLoaderAction());
    }
  };
}

export const ADD_TO_CART = 'ADD_TO_CART';
export function addToCartAction(cartUUID, showNotification) {
  return async function (dispatch) {
    const response = await post(ADD_TO_CART, {
      cartUUID,
    });

    if (response.data.status !== 'ERROR') {
      if (showNotification) dispatch(addNotificationAction(addToCartSuccess()));

      dispatch({
        type: ADD_TO_CART,
        cartUUID,
        data: response.data?.data?.cart,
      });
    }
  };
}

export const ADD_PARENT_DEVICE = 'ADD_PARENT_DEVICE';
export function addParentDeviceAction(cartUUID) {
  return async function (dispatch, getState) {
    const changes = {
      parentDeviceCode: getCurrentCart(getState().carts, cartUUID)?.selected
        ?.deviceCode,
    };

    dispatch({ type: ADD_PARENT_DEVICE });

    dispatch(updateSelectedProductAction(cartUUID, changes));
  };
}

export const REMOVE_PARENT_DEVICE = 'REMOVE_PARENT_DEVICE';
export function removeParentDeviceAction(cartUUID) {
  return async function (dispatch, getState) {
    const changes = {
      parentDeviceCode: undefined,
      deviceCode: getCurrentCart(getState().carts, cartUUID)?.selected
        ?.parentDeviceCode,
    };

    dispatch({ type: REMOVE_PARENT_DEVICE });

    dispatch(updateSelectedProductAction(cartUUID, changes));
  };
}

export const GET_PRICED_EXTRAS = 'GET_PRICED_EXTRAS';
export function getPricedExtrasAction(
  cartUUID,
  deviceCode,
  { callback = null, updateExtrasPrice = false } = {},
) {
  return async function (dispatch, getState) {
    const {
      page = 0,
      category,
      isDeviceOnly = true,
    } = getState().form?.extrasFilters?.values || {};
    const { searchText } = getState().form?.searchForm?.values || {};

    let requestMostPopular =
      (!category || category === 'ALL') &&
      (updateExtrasPrice || page === 0) &&
      isEmpty(searchText) &&
      isDeviceOnly;

    const response = await post(GET_PRICED_EXTRAS, {
      cartUUID,
      deviceCode: isDeviceOnly ? deviceCode : undefined,
      searchText,
      category: category === 'ALL' ? undefined : category,
      quantity: updateExtrasPrice ? (page + 1) * PAGE_QUANTITY : PAGE_QUANTITY,
      page: updateExtrasPrice ? 0 : page,
      showMostPopular: requestMostPopular,
    });

    // the type changes wether the reducer should overwrite all the extras or just to add extras being paginated.
    // This is done to avoid more code repetition to change basically the type.
    if (response.data.status !== 'ERROR') {
      if (page === 0 || updateExtrasPrice) {
        await dispatch({
          type: GET_PRICED_EXTRAS,
          cartUUID,
          data: response.data?.data?.cart,
        });
        await dispatch(saveExtrasAction(response.data.data?.extras));
      } else if (!isEmpty(response.data?.data?.extras) && page !== 0) {
        const extras = response.data.data.extras;

        const totalExtras = Object.keys(extras).reduce((acc, key) => {
          return acc + get(extras, key).length;
        }, 0);

        dispatch(saveMoreExtrasAction(extras, totalExtras < PAGE_QUANTITY));
      }
    }

    return callback && (await callback());
  };
}

export const ADD_DISCOUNT = 'ADD_DISCOUNT';
export function addDiscountAction(orderUUID, { typeID, value }) {
  return async function (dispatch) {
    /*
      Discounts can be added to the current order using the cart UUID or
      to a specific order using the order UUID. Since the current order UUID is
      the same as the cart UUID, we can just check if they are equal
    */
    const isCurrentOrder = getCartFromOrder(orderUUID) === orderUUID;

    const response = await post(ADD_DISCOUNT, {
      cartUUID: isCurrentOrder ? orderUUID : undefined,
      orderUUID: !isCurrentOrder ? orderUUID : undefined,
      typeID,
      value,
      description: discountLabels()[typeID],
    });

    if (response.data.status !== 'ERROR') {
      dispatch({
        type: ADD_DISCOUNT,
        cartUUID: getCartFromOrder(orderUUID),
        data: response.data?.data?.cart,
      });
    }
  };
}

export const REMOVE_DISCOUNT = 'REMOVE_DISCOUNT';
export function removeDiscountAction(orderUUID, discountUUID, callback = null) {
  return async function (dispatch) {
    /*
      Discounts can be removed from the current order using the Cart UUID or
      from a specific order using the Order UUID. Since the current order UUID is
      the same as the cart UUID, we can just check if they are equal.
    */
    const isCurrentOrder = getCartFromOrder(orderUUID) === orderUUID;

    const response = await post(REMOVE_DISCOUNT, {
      cartUUID: isCurrentOrder ? orderUUID : undefined,
      orderUUID: !isCurrentOrder ? orderUUID : undefined,
      discountUUID,
    });

    if (response.data.status !== 'ERROR') {
      dispatch({
        type: REMOVE_DISCOUNT,
        cartUUID: getCartFromOrder(orderUUID),
        data: response.data?.data?.cart,
      });

      return callback && (await callback());
    }
  };
}

export const ADD_EXTRA = 'ADD_EXTRA';
export function addExtraAction(cartUUID, extraCode, callback = undefined) {
  return async function (dispatch) {
    const response = await post(ADD_EXTRA, {
      cartUUID,
      extraCode,
    });

    if (response.data.status !== 'ERROR') {
      dispatch({
        type: ADD_EXTRA,
        cartUUID,
        data: response.data?.data?.cart,
      });

      return callback && (await callback());
    }
  };
}

export const REMOVE_EXTRA = 'REMOVE_EXTRA';
export function removeExtraAction(orderUUID, extraUUID, callback = null, code) {
  return async function (dispatch, getState) {
    /*
      Extras can be removed from the current order using the Cart UUID or
      from a specific order using the Order UUID. Since the current order UUID is
      the same as the cart UUID, we can just check if they are equal.
    */
    const cartUUID = getCartFromOrder(orderUUID);
    if (code) {
      // If we remove an extra from cart we need to update the product options selection
      // So we can persist the data on the backend.
      const extrasOptions = getState()?.catalog?.extrasOptions || [];
      const found = extrasOptions.some((extra) =>
        extra.options.some((option) => option.id === code),
      );
      if (found) {
        await dispatch({
          type: UPDATE_SELECTED_PRODUCT,
          cartUUID,
          changes: {
            selectedExtraOptionID: undefined,
          },
        });
      }
    }
    const isCurrentOrder = cartUUID === orderUUID;

    const response = await post(REMOVE_EXTRA, {
      cartUUID: isCurrentOrder ? orderUUID : undefined,
      orderUUID: !isCurrentOrder ? orderUUID : undefined,
      extraUUID,
    });

    if (response.data.status !== 'ERROR') {
      dispatch({
        type: REMOVE_EXTRA,
        cartUUID: cartUUID,
        data: response.data?.data?.cart,
      });

      return callback && (await callback());
    }
  };
}

export const ADD_EXTRA_MANUALLY = 'ADD_EXTRA_MANUALLY';
export function addExtraManuallyAction(cartUUID, values, isCTO) {
  return async function (dispatch, getState) {
    const cart = getCurrentCart(getState().carts, cartUUID);
    const data = isCTO
      ? {
          ...values,
          isCTO,
          parentPartNumber: cart.selected.deviceCode,
        }
      : values;

    const response = await post(ADD_EXTRA_MANUALLY, {
      cartUUID: cartUUID,
      financingType: cart.financingType,
      ...data,
    });

    if (response.data.status !== 'ERROR') {
      dispatch({
        type: ADD_EXTRA_MANUALLY,
        cartUUID,
        data: response.data?.data?.cart,
      });

      dispatch(getPricedExtrasAction(cartUUID, cart.selected.deviceCode));
    }
  };
}

export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';
export function removeProductAction(
  orderUUID,
  productUUID,
  fetchPaymentPlans,
  callback = null,
) {
  return async function (dispatch) {
    /*
      Products can be removed from the current order using the Cart UUID or
      from a specific order using the Order UUID. Since the current order UUID is
      the same as the cart UUID, we can just check if they are equal.
    */
    const isCurrentOrder = getCartFromOrder(orderUUID) === orderUUID;

    const response = await post(REMOVE_PRODUCT, {
      cartUUID: isCurrentOrder ? orderUUID : undefined,
      orderUUID: !isCurrentOrder ? orderUUID : undefined,
      productUUID,
    });

    if (response.data.status !== 'ERROR') {
      dispatch({
        type: REMOVE_PRODUCT,
        cartUUID: getCartFromOrder(orderUUID),
        data: response.data?.data?.cart,
      });

      fetchPaymentPlans &&
        dispatch(changeOrderPPAction(getCartFromOrder(orderUUID)));

      callback && (await callback());
    }
  };
}

export const CHANGE_ORDER_PAYMENT_PLAN = 'CHANGE_ORDER_PAYMENT_PLAN';
export function changeOrderPPAction(cartUUID, paymentplanID, selectedService) {
  return async function (dispatch, getState) {
    const currentCart = getCurrentCart(getState().carts, cartUUID);
    dispatch(showLoaderAction());
    const response = await post(CHANGE_ORDER_PAYMENT_PLAN, {
      cartUUID,
      paymentplanID,
      financingType: selectedService?.type,
      selectedServiceID: selectedService?.id,
    });

    if (response.data.status !== 'ERROR' && response.data.data?.cart) {
      const { paymentplan_options, cart, customer, company, selectedService } =
        response.data.data;

      dispatch(
        saveProductOptionsAction({ paymentPlanOptions: paymentplan_options }),
      );
      dispatch({
        type: CHANGE_ORDER_PAYMENT_PLAN,
        cart: {
          selectedService,
          selected: currentCart.selected,
          ...cart,
        },
      });

      // TODO: review this later not sure it makes senses to display this warning
      // if (!hasDevicesInOrders([cart.current_order, ...cart.orders])) {
      //   dispatch(addNotificationAction(emptyCartError()));
      // }
      paymentplanID &&
        dispatch({
          type: UPDATE_SELECTED_PRODUCT,
          cartUUID,
          changes: { paymentplanID },
        });

      customer &&
        company &&
        dispatch(setCustomerAction({ ...customer, company: company }));
      selectedService &&
        dispatch(setSelectedServiceAction(cartUUID, selectedService));
    }

    dispatch(hideLoaderAction());
  };
}

export const REMOVE_ORDER = 'REMOVE_ORDER';
export function removeOrderAction(orderUUID) {
  return async function (dispatch) {
    const response = await post(REMOVE_ORDER, {
      orderUUID,
    });

    if (response.data.status !== 'ERROR') {
      dispatch({ type: REMOVE_ORDER, data: response.data?.data });
    }
  };
}

export const SAVE_CART = 'SAVE_CART';
export function saveCartAction(cart) {
  return async function (dispatch) {
    dispatch({ type: SAVE_CART, cart });
  };
}

export const SAVE_CURRENT_CART = 'SAVE_CURRENT_CART';
export function saveCurrentCartAction(cartUUID) {
  return async function (dispatch) {
    dispatch({ type: SAVE_CURRENT_CART, cartUUID });
  };
}

export const REMOVE_CURRENT_CART = 'REMOVE_CURRENT_CART';
export function removeCurrentCartAction() {
  return async function (dispatch) {
    dispatch({ type: REMOVE_CURRENT_CART });
  };
}

export const CHANGE_PRODUCT_QUANTITY = 'CHANGE_PRODUCT_QUANTITY';
export function changeProductQuantity(cartUUID, productUUID, quantity) {
  return async function (dispatch) {
    dispatch(showLoaderAction());
    const response = await post(CHANGE_PRODUCT_QUANTITY, {
      cartUUID,
      productUUID,
      quantity,
    });

    if (response.data.status !== 'ERROR') {
      dispatch({
        type: CHANGE_PRODUCT_QUANTITY,
        cartUUID: cartUUID,
        data: response.data?.data?.cart,
      });
    }
    dispatch(hideLoaderAction());
  };
}

export const CHANGE_EXTRA_QUANTITY = 'CHANGE_EXTRA_QUANTITY';
export function changeExtraQuantity(cartUUID, extraUUID, quantity) {
  return async function (dispatch) {
    dispatch(showLoaderAction());
    const response = await post(CHANGE_EXTRA_QUANTITY, {
      cartUUID,
      extraUUID,
      quantity,
    });

    if (response.data.status !== 'ERROR') {
      dispatch({
        type: CHANGE_EXTRA_QUANTITY,
        cartUUID: cartUUID,
        data: response.data?.data?.cart,
      });
    }
    dispatch(hideLoaderAction());
  };
}

export function setSelectedPaymentPlanAction(cartUUID, paymentplanID) {
  return async function (dispatch) {
    paymentplanID &&
      (await dispatch({
        type: UPDATE_SELECTED_PRODUCT,
        cartUUID,
        changes: { paymentplanID },
      }));
  };
}

export const SET_SELECTED_SERVICE = 'SET_SELECTED_SERVICE';
export function setSelectedServiceAction(cartUUID, service) {
  return async function (dispatch) {
    dispatch({ type: SET_SELECTED_SERVICE, cartUUID, service });
  };
}

export const CHANGE_SALE_PRICE = 'CHANGE_SALE_PRICE';
export function changeSalePrice(cartUUID, UUID, salePrice) {
  return async function (dispatch) {
    dispatch(showLoaderAction());
    const response = await post(CHANGE_SALE_PRICE, {
      cartUUID,
      UUID,
      salePrice,
    });

    if (response.data.status !== 'ERROR') {
      dispatch({
        type: CHANGE_SALE_PRICE,
        cartUUID: cartUUID,
        data: response.data?.data?.cart,
      });
    }
    dispatch(hideLoaderAction());
  };
}

export const ADD_MANUAL_ITEM = 'ADD_MANUAL_ITEM';
export function addManualItem(cartUUID, item) {
  return async function (dispatch) {
    dispatch(showLoaderAction());
    await post(ADD_MANUAL_ITEM, {
      cartUUID,
      ...item,
    });
    dispatch(hideLoaderAction());
  };
}
