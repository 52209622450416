import {
  getAccessoriesAction,
  uploadFileAction,
} from 'actions/admin/accessories';
import { closeModalAction, openModalAction } from 'actions/ui';
import trackPage from 'enhancers/trackPage';

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import Button from 'components/Button';
import Loader from 'components/Loader';
import FileUploadModal from 'components/file_upload/FileUploadModal';
import UploadReport from 'components/file_upload/UploadReport';
import Table from 'components/table/Table';

import { deviceStatus } from 'constants/status';

import './Accessories.styl';

class Accessories extends Component {
  componentDidMount() {
    this.props.getAccessories();
  }

  render() {
    const {
      accessories,
      canUploadAccessoriesByCSV,
      closeModal,
      loading,
      openModal,
      uploadFile,
      uploadReport,
    } = this.props;

    if (loading) return <Loader type='processing' />;

    const columns = [
      {
        label: t({ id: 'table.column.manufacturer', message: `Manufacturer` }),
        accessor: 'manufacturer',
        type: 'text',
        filterable: true,
        sortable: true,
        align: 'center',
        wrap: false,
      },
      {
        label: t({ id: 'table.column.category', message: `Category` }),
        accessor: 'category',
        type: 'text',
        filterable: true,
        sortable: true,
        align: 'center',
        wrap: false,
      },
      {
        label: t({ id: 'table.column.name', message: `Name` }),
        accessor: 'description',
        type: 'text',
        filterable: true,
        sortable: true,
        align: 'left',
        wrap: false,
      },
      {
        label: t({ id: 'table.column.part.number', message: `Part Number` }),
        accessor: 'accessoryCode',
        type: 'text',
        filterable: true,
        sortable: true,
        align: 'center',
        wrap: false,
      },
      {
        label: t({ id: 'table.column.image', message: `Image` }),
        accessor: 'imgUrl',
        type: 'image',
        filterable: false,
        sortable: false,
        align: 'center',
        wrap: false,
      },
      {
        label: t({ id: 'table.column.search.tags', message: `Search Tags` }),
        accessor: 'searchTags',
        type: 'taglist',
        filterable: true,
        sortable: true,
        align: 'center',
        wrap: true,
      },
      {
        label: t({ id: 'table.column.price', message: `Price` }),
        accessor: 'salePrice',
        type: 'currency',
        filterable: true,
        sortable: true,
        align: 'right',
        wrap: false,
      },
      {
        label: t({ id: 'table.column.status', message: `Status` }),
        accessor: 'status',
        type: 'status',
        filterable: true,
        list: deviceStatus(),
        sortable: true,
        align: 'center',
        wrap: false,
      },
      {
        label: t({ id: 'table.column.updated', message: `Updated on` }),
        accessor: 'updatedAt',
        type: 'date',
        filterable: true,
        condition: 'after',
        sortable: true,
        align: 'right',
        wrap: false,
      },
    ];

    return (
      <Fragment>
        <h1 className='admin-page-title'>
          <Trans id='accessories.title'>Accessories</Trans>
        </h1>

        {canUploadAccessoriesByCSV && (
          <Fragment>
            <Button
              // TODO make this component reliant only on class functions
              // in order to remove arrow functions and enforce this rule
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => openModal('fileUpload')}
              classes='admin-main-action'
              testSelector='upload-csv'
            >
              <Trans id='admin.upload.csv'>Upload CSV</Trans>
            </Button>

            <FileUploadModal
              type='ACCESSORIES'
              handleUpload={uploadFile}
              closeModal={closeModal}
              accept='.csv'
            />

            <UploadReport report={uploadReport} />
          </Fragment>
        )}

        <Table
          classes='accessories-table'
          columns={columns}
          data={accessories}
          defaultOrder='status'
          keyColumn='partNumber'
        />
      </Fragment>
    );
  }
}

export default connect(
  ({ adminAcessories, whitelabel, ui }) => ({
    accessories: adminAcessories.accessories,
    canUploadAccessoriesByCSV: whitelabel.configs.canUploadAccessoriesByCSV,
    currency: whitelabel.currency,
    language: whitelabel.language,
    loading: ui.loading,
    uploadReport: adminAcessories.uploadReport,
  }),
  {
    closeModal: closeModalAction,
    getAccessories: getAccessoriesAction,
    openModal: openModalAction,
    uploadFile: uploadFileAction,
  },
)(trackPage(Accessories, 'Accessories'));
