import classNames from 'classnames';
import { differenceInDays } from 'date-fns';
import { dateFormatter } from 'helpers/formatters';
import { isValidDate } from 'helpers/validators';
import PropTypes from 'prop-types';

import React from 'react';

import { Trans, t } from '@lingui/macro';

import './Timeline.styl';

const Period = ({ classes, date, title, subtitle, language, style }) => (
  <div className='period' style={style}>
    {date && <p className='period__date'>{dateFormatter(date, language)}</p>}
    <div className={classNames('period__bar', classes)} />
    <p className='period__title'>{title}</p>
    <p className='period__subtitle'>{subtitle}</p>
  </div>
);

const Timeline = ({
  start,
  withdraw,
  withdrawPeriod,
  earlyUpgrade,
  freeUpgrade,
  end,
  language,
}) => {
  const today = new Date();
  earlyUpgrade = isValidDate(earlyUpgrade) ? earlyUpgrade : false;

  const dateToPercentage = (date) => {
    return (differenceInDays(date, start) * 100) / differenceInDays(end, start);
  };

  return (
    <div className='timeline'>
      <p className='timeline__label'>
        <Trans id='timeline.contract.start'>Contract Start</Trans>
        <span>{dateFormatter(start, language)}</span>
      </p>

      <div className='timeline__bar'>
        <Period
          title={t({
            id: 'timeline.no.upgrade',
            message: `No Upgrade Available`,
          })}
          date={earlyUpgrade || freeUpgrade}
          classes='period__bar--red'
          style={{
            left: '0%',
            width: `calc(${dateToPercentage(
              earlyUpgrade || freeUpgrade,
            )}% - 0%)`,
          }}
        />

        <Period
          classes='period__bar--pink period__bar--borderless period__bar--offset'
          style={{
            left: '0%',
            width: `calc(${dateToPercentage(withdraw)}% - 0%)`,
          }}
        />

        {earlyUpgrade && (
          <Period
            classes='period__bar--orange'
            title={t({
              id: 'timeline.early.upgrade',
              message: `Early Upgrade`,
            })}
            subtitle={t({
              id: 'timeline.payment.required',
              message: `(Customer Payment Required)`,
            })}
            date={freeUpgrade}
            style={{
              left: `${dateToPercentage(earlyUpgrade)}%`,
              width: `calc(${dateToPercentage(
                freeUpgrade,
              )}% - ${dateToPercentage(earlyUpgrade)}%)`,
            }}
          />
        )}

        <Period
          classes='period__bar--green period__bar--offset'
          title={t({ id: 'timeline.free.upgrade', message: `Free Upgrade` })}
          style={{
            left: `calc(${dateToPercentage(freeUpgrade)}%)`,
            width: `calc(${dateToPercentage(end)}% - ${dateToPercentage(
              freeUpgrade,
            )}%)`,
          }}
        />

        {dateToPercentage(today) <= 100 && (
          <div
            className='timeline__bar-today'
            style={{ left: `${dateToPercentage(today)}%` }}
          >
            <Trans id='timeline.today'>Today</Trans> (
            {dateFormatter(today, language, false)})
          </div>
        )}
      </div>

      <p className='timeline__label timeline__label--align-right'>
        <Trans id='timeline.contract.end'>Contract End</Trans>
        <span>{dateFormatter(end, language)}</span>
      </p>

      {withdrawPeriod > 0 && (
        <p className='timeline__caption'>
          <Trans id='timeline.withdraw.label'>
            Withdraw allowed in the first {withdrawPeriod} days. Ends{' '}
            {dateFormatter(withdraw, language)}
          </Trans>
        </p>
      )}
    </div>
  );
};

Timeline.propTypes = {
  start: PropTypes.instanceOf(Date).isRequired,
  withdraw: PropTypes.instanceOf(Date).isRequired,
  withdrawPeriod: PropTypes.number.isRequired,
  earlyUpgrade: PropTypes.instanceOf(Date).isRequired,
  freeUpgrade: PropTypes.instanceOf(Date).isRequired,
  end: PropTypes.instanceOf(Date).isRequired,
  language: PropTypes.string.isRequired,
};

export default Timeline;
