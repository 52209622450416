import { format, numericality, required } from 'helpers/validators';
import { Field, reduxForm } from 'redux-form';

import React from 'react';

import { Trans, t } from '@lingui/macro';

import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import RenderField from 'components/RenderField';

import { discountList } from 'constants/discount';
import { currencyRegex } from 'constants/forms';

import './CartDiscountForm.styl';

const DiscountForm = ({
  addDiscount,
  availableDiscounts,
  handleSubmit,
  invalid,
  max_discounts,
  cartUUID,
  pristine,
  reset,
  submitting,
}) => {
  const handleDiscountSubmit = async (discount) => {
    await addDiscount(cartUUID, discount);
    reset();
  };

  return (
    <form className='cart__discount__form'>
      <Field
        name='typeID'
        component={Dropdown}
        placeholder={t({
          id: 'discount.form.discount.placeholder',
          message: `Select Discount`,
        })}
        options={discountList().filter(({ configString }) => {
          return availableDiscounts.includes(configString);
        })}
        validate={required()}
        testSelector='discount-form-type'
        variant='underline'
        labelType='outside'
      />

      <Field
        name='value'
        type='text'
        className='max-value'
        component={RenderField}
        validate={[
          required(),
          numericality({ greaterThan: 0 }),
          format({ with: currencyRegex }),
        ]}
        label={t({
          id: 'discount.form.max.discounts.placeholder',
          message: `Max amount ${max_discounts}`,
        })}
        testSelector='discount-form-value'
      />

      <Button
        type='submit'
        disabled={pristine || submitting || invalid}
        onClick={handleSubmit((discount) => handleDiscountSubmit(discount))}
        testSelector='discount-form-add-btn'
        classes='small'
      >
        <Trans id='discount.form.submit.button'>Add</Trans>
      </Button>
    </form>
  );
};

export default reduxForm({
  form: 'discountForm',
  destroyOnUnmount: true,
})(DiscountForm);
