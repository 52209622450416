import {
  ADD_NOTIFICATION,
  CLOSE_MODAL,
  HIDE_LOADER,
  MARK_NOTIFICATION_AS_SENT,
  OPEN_MODAL,
  REMOVE_NOTIFICATION,
  SAVE_PAGE_NAME,
  SAVE_PREVIOUS_LOCATION,
  SHOW_LOADER,
} from 'actions/ui';

import { LOADER_TYPE_DEFAULT } from 'constants/ui';

import initialState from 'reducers/initialState';

export default function (state = initialState.ui, action) {
  switch (action.type) {
    case SHOW_LOADER:
      return { ...state, loading: true, loaderType: action.loaderType };
    case HIDE_LOADER:
      return { ...state, loading: false, loaderType: LOADER_TYPE_DEFAULT };
    case OPEN_MODAL:
      return { ...state, openModal: action.modal };
    case CLOSE_MODAL:
      return { ...state, openModal: null };
    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications, action.notification],
      };
    case MARK_NOTIFICATION_AS_SENT:
      return {
        ...state,
        notifications: state.notifications.map((notification) => {
          if (notification.id !== action.notificationId) return notification;

          return {
            ...notification,
            sent: true,
          };
        }),
      };
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          ({ id }) => id !== action.notificationId,
        ),
      };
    case SAVE_PAGE_NAME:
      return {
        ...state,
        pageName: action.pageName,
      };
    case SAVE_PREVIOUS_LOCATION:
      return {
        ...state,
        prevLocation: action.location,
      };
    default:
      return state;
  }
}
