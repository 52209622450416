import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';

import Contracts from 'components/sales/Contracts';

import { defaultPendingsFilters } from 'constants/contracts';
import { CARD_PAYMENT, STANDARD } from 'constants/recycle';
import { viewSaleUrl } from 'constants/routes';

import useTrackPage from 'hooks/useTrackPage';

const PendingContracts = () => {
  useTrackPage('PendingContracts');

  const navigate = useNavigate();

  const pendings = useSelector(({ sales }) => sales.list);

  const openContract = async (cartUUID) => navigate(viewSaleUrl(cartUUID));

  const renderDeviceReference = (pending) => {
    if (pending.financingType === STANDARD)
      return (
        <Trans id='pending.device.reference.standard.finance'>
          Standard Finance
        </Trans>
      );
    else if (pending.financingType === CARD_PAYMENT)
      return (
        <Trans id='pending.device.reference.card.payment'>
          Standard Finance
        </Trans>
      );
    else if (pending.financingType === 'B2B_LEASE') return null;
    else return pending.hasDeviceReference;
  };

  const createColumn = (label, accessor, type, sortable, align, wrap) => ({
    label,
    accessor,
    type,
    sortable,
    align,
    wrap,
    condition: 'after',
  });

  const columns = [
    createColumn(
      t({ id: 'table.column.created', message: `Created on` }),
      'createdDate',
      'date',
      true,
      'center',
      false,
    ),
    createColumn(
      t({ id: 'table.column.customer', message: `Customer` }),
      'customer',
      'multiLine',
      true,
      'center',
      false,
    ),
    createColumn(
      t({ id: 'table.column.vendor', message: `Vendor` }),
      'vendorName',
      'text',
      true,
      'center',
      false,
    ),
    createColumn(
      t({ id: 'table.column.store', message: `Store` }),
      'storeName',
      'text',
      true,
      'center',
      false,
    ),
    createColumn(
      t({ id: 'table.column.cart', message: `Cart` }),
      'cartUUID',
      'text',
      true,
      'center',
      false,
    ),
    createColumn(
      t({ id: 'table.column.device', message: `Device` }),
      'descriptions',
      'multiLine',
      true,
      'left',
      true,
    ),
    createColumn(
      t({ id: 'table.column.delivery.type', message: `Delivery Type` }),
      'deliveryType',
      'text',
      true,
      'center',
      false,
    ),
    createColumn(
      t({ id: 'table.column.notes', message: `Notes` }),
      'notes',
      'encodedList',
      false,
      'left',
      true,
    ),
    createColumn(
      t({ id: 'table.column.device.reference', message: `Device Reference` }),
      'hasDeviceReference',
      'boolIcon',
      true,
      'center',
      false,
    ),
    createColumn(
      t({ id: 'table.column.is.activated', message: `Activated` }),
      'isActive',
      'boolIcon',
      true,
      'center',
      false,
    ),
    createColumn(
      t({ id: 'table.column.actions', message: `Actions` }),
      'actions',
      'actions',
      false,
      'right',
      false,
    ),
  ];

  const actions = [
    {
      label: t({ id: 'table.column.view.details', message: `View details` }),
      handler: (_, { cartUUID }) => openContract(cartUUID),
      testSelector: 'posale-pending-contracts-view-button',
    },
  ];

  const rows = pendings.map((pending) => ({
    ...pending,
    descriptions: [
      pending.productDescriptions,
      pending.numberOfExtras <= 0
        ? ''
        : t({
            id: 'pendings.table.description.extras',
            message: `${pending.numberOfExtras} Extra(s)`,
          }),
    ],
    customer: [
      pending.customerName,
      pending.customerPhone,
      pending.customerEmail,
    ],
    hasDeviceReference: renderDeviceReference(pending),
    actions,
  }));

  return (
    <Contracts
      noWordBreak={true}
      key='pendings'
      name='PendingContracts'
      title={<Trans id='pending.contracts.title'>Pending Contracts</Trans>}
      defaultOrder={'creation_date'}
      rows={rows}
      columns={columns}
      isSales={false}
      initialFilterValues={defaultPendingsFilters}
    />
  );
};

export default PendingContracts;
