import { t } from '@lingui/macro';

import { activationStatus } from 'constants/status';

export const config = {
  get dates() {
    return [
      {
        label: t({ id: 'dl.row.creation.date', message: `Creation Date` }),
        accessor: 'created_at',
        type: 'date',
      },
      {
        label: t({ id: 'dl.row.input.dl.start.date', message: `Start Date` }),
        accessor: 'start_date',
        type: 'date',
      },
      {
        label: t({ id: 'dl.row.end.date', message: `End Date` }),
        accessor: 'end_date',
        type: 'date',
      },
    ];
  },
  products(fixedPeriodEndMonth, showExtraFields = false) {
    return [
      {
        label: t({ id: 'dl.row.name', message: `Name` }),
        accessor: 'description',
        type: 'text',
      },
      {
        label: t({ id: 'dl.row.part.number', message: `Part Number` }),
        accessor: 'partNumber',
        type: 'text',
      },
      {
        label: t({ id: 'dl.row.manufacturer', message: `Manufacturer` }),
        accessor: 'manufacturer',
        type: 'text',
      },
      {
        label: t({ id: 'dl.row.category', message: `Category` }),
        accessor: 'category',
        type: 'text',
      },
      {
        label: t({ id: 'dl.row.color', message: `Colour` }),
        accessor: 'specs.color',
        type: 'text',
      },
      {
        label: t({
          id: 'dl.row.device.reference',
          message: `IMEI or Serial Number`,
        }),
        accessor: 'deviceReferences',
        type: 'inlineDeviceReferenceList',
      },
      {
        label: t({ id: 'dl.row.guarantor', message: `Guarantor` }),
        accessor: 'guarantor',
        type: 'text',
      },
      {
        label: t({
          id: 'dl.row.residual.amount',
          message: `R.V. at month ${fixedPeriodEndMonth}`,
        }),
        accessor: 'residualAmount',
        type: 'currency',
      },
      {
        label: t({ id: 'dl.row.cash.price', message: `Cash Price` }),
        accessor: 'cashPrice',
        type: 'currency',
        classes: 'bold',
      },
      {
        label: t({ id: 'dl.row.price.source', message: `Price Source` }),
        accessor: 'priceSource',
        type: 'text',
      },
      {
        label: t({ id: 'dl.row.delivery.uuid', message: `Delivery Reference` }),
        accessor: 'deliveryUUID',
        type: 'anchor',
      },
      {
        label: t({
          id: 'dl.row.fullfillment.provider',
          message: `Fullfillment Provider`,
        }),
        accessor: 'fulfillmentProvider',
        type: 'text',
      },
      {
        label: t({
          id: 'dl.row.fullfillment.status',
          message: `Fullfillment Status`,
        }),
        accessor: 'fulfillmentStatus',
        type: 'text',
      },
      {
        label: t({
          id: 'dl.row.fullfillment.purchase.order',
          message: `Purchase Order`,
        }),
        accessor: 'fulfillmentPurchaseOrder',
        type: 'text',
      },
      {
        label: t({
          id: 'dl.row.fullfillment.order.number',
          message: `Order Number`,
        }),
        accessor: 'fulfillmentOrderNumber',
        type: 'text',
      },
      {
        label: t({
          id: 'dl.row.fullfillment.invoice.number',
          message: `Invoice Number`,
        }),
        accessor: 'fulfillmentInvoiceNumber',
        type: 'text',
      },
      showExtraFields && {
        label: t({
          id: 'dl.row.fulfillment.credit.note.num',
          message: `Credit Note Number`,
        }),
        accessor: 'fulfillmentCreditNoteNumber',
        type: 'text',
      },
      showExtraFields && {
        label: t({
          id: 'dl.row.fulfillment.return.ref',
          message: `Return Reference`,
        }),
        accessor: 'fulfillmentReturnReference',
        type: 'text',
      },
      {
        label: t({
          id: 'dl.row.activation.provider',
          message: `Activation Provider`,
        }),
        accessor: 'activationProvider',
        type: 'text',
      },
      {
        label: t({
          id: 'dl.row.activation.status.name',
          message: `Activation Status`,
        }),
        list: activationStatus(),
        accessor: 'activationStatus',
        type: 'text',
      },
      {
        label: t({ id: 'dl.row.activation.id', message: `Activation ID` }),
        accessor: 'activationID',
        type: 'text',
      },
      {
        label: t({ id: 'dl.row.activation.date', message: `Activation Date` }),
        accessor: 'activatedAt',
        type: 'date',
      },
    ];
  },
  extras(showExtraFields = false) {
    return [
      {
        label: t({ id: 'dl.row.name', message: `Name` }),
        accessor: 'description',
        type: 'text',
      },
      {
        label: t({ id: 'dl.row.part.number', message: `Part Number` }),
        accessor: 'partNumber',
        type: 'text',
      },
      {
        label: t({ id: 'dl.row.manufacturer', message: `Manufacturer` }),
        accessor: 'manufacturer',
        type: 'text',
      },
      {
        label: t({ id: 'dl.row.category', message: `Category` }),
        accessor: 'category',
        type: 'text',
      },
      {
        label: t({ id: 'dl.row.price', message: `Price` }),
        accessor: 'value',
        type: 'currency',
      },
      {
        label: t({ id: 'dl.row.price.source', message: `Price Source` }),
        accessor: 'priceSource',
        type: 'text',
      },
      {
        label: t({ id: 'dl.row.delivery.uuid', message: `Delivery Reference` }),
        accessor: 'deliveryUUID',
        type: 'anchor',
      },
      {
        label: t({
          id: 'dl.row.fullfillment.provider',
          message: `Fullfillment Provider`,
        }),
        accessor: 'fulfillmentProvider',
        type: 'text',
      },
      {
        label: t({
          id: 'dl.row.fullfillment.status',
          message: `Fullfillment Status`,
        }),
        accessor: 'fulfillmentStatus',
        type: 'text',
      },
      {
        label: t({
          id: 'dl.row.fullfillment.purchase.order',
          message: `Purchase Order`,
        }),
        accessor: 'fulfillmentPurchaseOrder',
        type: 'text',
      },
      {
        label: t({
          id: 'dl.row.fullfillment.order.number',
          message: `Order Number`,
        }),
        accessor: 'fulfillmentOrderNumber',
        type: 'text',
      },
      {
        label: t({
          id: 'dl.row.fullfillment.invoice.number',
          message: `Invoice Number`,
        }),
        accessor: 'fulfillmentInvoiceNumber',
        type: 'text',
      },
      showExtraFields && {
        label: t({
          id: 'dl.row.fulfillment.credit.note.num',
          message: `Credit Note Number`,
        }),
        accessor: 'fulfillmentCreditNoteNumber',
        type: 'text',
      },
      showExtraFields && {
        label: t({
          id: 'dl.row.fulfillment.return.ref',
          message: `Return Reference`,
        }),
        accessor: 'fulfillmentReturnReference',
        type: 'text',
      },
      {
        label: t({
          id: 'dl.row.activation.provider',
          message: `Activation Provider`,
        }),
        accessor: 'activationProvider',
        type: 'text',
      },
      {
        label: t({
          id: 'dl.row.activation.status.name',
          message: `Activation Status`,
        }),
        list: activationStatus(),
        accessor: 'activationStatus',
        type: 'text',
      },
      {
        label: t({ id: 'dl.row.activation.id', message: `Activation ID` }),
        accessor: 'activationID',
        type: 'text',
      },
      {
        label: t({ id: 'dl.row.activation.date', message: `Activation Date` }),
        accessor: 'activatedAt',
        type: 'date',
      },
      {
        label: t({
          id: 'dl.row.device.reference',
          message: `IMEI or Serial Number`,
        }),
        accessor: 'deviceReferences',
        type: 'inlineDeviceReferenceList',
      },
    ];
  },

  get discounts() {
    return [
      {
        label: t({ id: 'dl.row.mobile.recycle', message: `Mobile Recycle` }),
        accessor: '1',
        type: 'currency',
      },
      {
        label: t({ id: 'dl.row.gift.cart', message: `Gift Card` }),
        accessor: '2',
        type: 'currency',
      },
      {
        label: t({ id: 'dl.row.cash', message: `Cash` }),
        accessor: '3',
        type: 'currency',
      },
      {
        label: t({ id: 'dl.row.other', message: `Other` }),
        accessor: '4',
        type: 'currency',
      },
      {
        label: t({ id: 'dl.row.discount', message: `Discount` }),
        accessor: '5',
        type: 'currency',
      },
      {
        label: t({ id: 'dl.row.student', message: `Student` }),
        accessor: '6',
        type: 'currency',
      },
      {
        label: t({ id: 'dl.row.sale', message: `Sale` }),
        accessor: '7',
        type: 'currency',
      },
      {
        label: t({ id: 'dl.row.employee', message: `Employee` }),
        accessor: '8',
        type: 'currency',
      },
    ];
  },
  b2bfinancing(financing) {
    return [
      {
        label: t({ id: 'dl.row.payment.plan', message: `Payment Plan` }),
        accessor: 'payment_plan_name',
        type: 'text',
        classes: 'bold',
      },
      {
        label: t({
          id: 'dl.row.fixed.period.monthly',
          message: `Monthly payment 1-${financing.fixed_period_end_month}`,
        }),
        accessor: 'fixed_period_monthly_price',
        type: 'currency',
      },
      ...(financing.flex_period_end_month > financing.fixed_period_end_month
        ? [
            {
              label: t({
                id: 'dl.row.flex.period.monthly',
                message: `Monthly payment ${
                  financing.fixed_period_end_month + 1
                }-${financing.flex_period_end_month}`,
              }),
              accessor: 'flex_period_monthly_price',
              type: 'currency',
            },
          ]
        : []),
      {
        label: t({
          id: 'dl.row.fixed.period.total',
          message: `Total payment 1-${financing.fixed_period_end_month}`,
        }),
        accessor: 'total_amount_fixed_period',
        type: 'currency',
      },
      {
        label: t({
          id: 'dl.row.flex.period.total',
          message: `Total payment ${financing.fixed_period_end_month + 1}-${
            financing.flex_period_end_month
          }`,
        }),
        accessor: 'total_amount_flex_period',
        type: 'currency',
      },
      {
        label: t({
          id: 'dl.row.flex.financed.total',
          message: `Financed Total`,
        }),
        accessor: 'total_device_extras_discounts',
        type: 'currency',
        classes: 'bold',
      },
    ];
  },
  financing(financing) {
    return [
      {
        label: t({ id: 'dl.row.payment.plan', message: `Payment Plan` }),
        accessor: 'payment_plan_name',
        type: 'text',
        classes: 'bold',
      },
      {
        label: t({
          id: 'dl.row.fixed.period.monthly',
          message: `Monthly payment 1-${financing.fixed_period_end_month}`,
        }),
        accessor: 'fixed_period_monthly_price',
        type: 'currency',
      },
      ...(financing.flex_period_end_month > financing.fixed_period_end_month
        ? [
            {
              label: t({
                id: 'dl.row.flex.period.monthly',
                message: `Monthly payment ${
                  financing.fixed_period_end_month + 1
                }-${financing.flex_period_end_month}`,
              }),
              accessor: 'flex_period_monthly_price',
              type: 'currency',
            },
          ]
        : []),
      {
        label: t({
          id: 'dl.row.fixed.period.total',
          message: `Total payment 1-${financing.fixed_period_end_month}`,
        }),
        accessor: 'total_amount_fixed_period',
        type: 'currency',
      },
      {
        label: t({
          id: 'dl.row.flex.period.total',
          message: `Total payment ${financing.fixed_period_end_month + 1}-${
            financing.flex_period_end_month
          }`,
        }),
        accessor: 'total_amount_flex_period',
        type: 'currency',
      },
      {
        label: t({
          id: 'dl.row.flex.financed.total',
          message: `Financed Total`,
        }),
        accessor: 'total_device_extras_discounts',
        type: 'currency',
        classes: 'bold',
      },
      {
        label: t({ id: 'dl.row.flex.interest.rate', message: `Interest Rate` }),
        accessor: 'anual_interest_rate',
        type: 'text',
      },
    ];
  },
  financingWithUpfront(financing) {
    return [
      {
        label: t({ id: 'dl.row.payment.plan', message: `Payment Plan` }),
        accessor: 'payment_plan_name',
        type: 'text',
        classes: 'bold',
      },
      {
        label: t({
          id: 'dl.row.upfront',
          message: `${financing?.openingFeePercentage}% Upfront payment`,
        }),
        accessor: 'openingFeeAmount',
        type: 'currency',
      },
      {
        label: t({
          id: 'dl.row.fixed.period.monthly',
          message: `Monthly payment 1-${financing.fixed_period_end_month}`,
        }),
        accessor: 'fixed_period_monthly_price',
        type: 'currency',
      },
      ...(financing.flex_period_end_month > financing.fixed_period_end_month
        ? [
            {
              label: t({
                id: 'dl.row.flex.period.monthly',
                message: `Monthly payment ${
                  financing.fixed_period_end_month + 1
                }-${financing.flex_period_end_month}`,
              }),
              accessor: 'flex_period_monthly_price',
              type: 'currency',
            },
          ]
        : []),
      {
        label: t({
          id: 'dl.row.fixed.period.total',
          message: `Total payment 1-${financing.fixed_period_end_month}`,
        }),
        accessor: 'total_amount_fixed_period',
        type: 'currency',
      },
      {
        label: t({
          id: 'dl.row.flex.period.total',
          message: `Total payment ${financing.fixed_period_end_month + 1}-${
            financing.flex_period_end_month
          }`,
        }),
        accessor: 'total_amount_flex_period',
        type: 'currency',
      },
      {
        label: t({
          id: 'dl.row.flex.financed.total',
          message: `Financed Total`,
        }),
        accessor: 'total_device_extras_discounts',
        type: 'currency',
        classes: 'bold',
      },
      {
        label: t({ id: 'dl.row.flex.interest.rate', message: `Interest Rate` }),
        accessor: 'anual_interest_rate',
        type: 'text',
      },
    ];
  },
  financingFullPayment() {
    return [
      {
        label: t({ id: 'dl.row.payment.plan', message: `Payment Plan` }),
        accessor: 'payment_plan_name',
        type: 'text',
        classes: 'bold',
      },

      {
        label: t({ id: 'dl.row.flex.total.amount', message: `Total Amount` }),
        accessor: 'total_device_extras_discounts',
        type: 'currency',
        classes: 'bold',
      },
    ];
  },
  get fees() {
    return [
      {
        label: t({ id: 'dl.row.funder', message: `Funder` }),
        accessor: 'funder',
        type: 'currency',
      },
      {
        label: t({ id: 'dl.row.broker', message: `Broker` }),
        accessor: 'broker',
        type: 'currency',
      },
      {
        label: t({ id: 'dl.row.non.return', message: `Non Return` }),
        accessor: 'non_return_fee_amount',
        type: 'percentage',
      },
    ];
  },
  get deviceDetails() {
    return [
      {
        label: t({ id: 'dl.row.part.number', message: `Part Number` }),
        accessor: 'deviceCode',
        type: 'text',
      },
      {
        label: t({ id: 'dl.row.category', message: `Category` }),
        accessor: 'category',
        type: 'text',
      },
      {
        label: t({ id: 'dl.row.family', message: `Family` }),
        accessor: 'family',
        type: 'text',
      },
      {
        label: t({ id: 'dl.row.memory', message: `Memory` }),
        accessor: 'memoryName',
        type: 'text',
      },
      {
        label: t({ id: 'dl.row.color', message: `Colour` }),
        accessor: 'colorName',
        type: 'text',
      },
      {
        label: t({ id: 'dl.row.screen', message: `Screen` }),
        accessor: 'screenName',
        type: 'text',
      },
      {
        label: t({ id: 'dl.row.msrp', message: `MSRP` }),
        accessor: 'msrp',
        type: 'currency',
      },
      {
        label: t({ id: 'dl.row.last.updated', message: `Last updated` }),
        accessor: 'updatedAt',
        type: 'date',
      },
    ];
  },
  get datesTradeIn() {
    return [
      {
        label: t({ id: 'dl.row.created', message: `Created` }),
        accessor: 'orderDate',
        type: 'date',
        formatToday: false,
      },
      {
        label: t({ id: 'dl.row.valid.until', message: `Valid until` }),
        accessor: 'expiredDate',
        type: 'date',
      },
    ];
  },
  get tradeInCustomerReceipt() {
    return [
      {
        label: t({ id: 'dl.row.name', message: `Name` }),
        accessor: 'name',
        type: 'text',
      },
      {
        label: t({ id: 'dl.row.phone.nr', message: `Phone nr` }),
        accessor: 'phone',
        type: 'text',
      },
      {
        label: t({ id: 'dl.row.email', message: `Email` }),
        accessor: 'email',
        type: 'text',
      },
    ];
  },
  get datesCollection() {
    return [
      {
        label: t({ id: 'dl.row.scheduled', message: `Scheduled` }),
        accessor: 'scheduleDate',
        type: 'date',
      },
      {
        label: t({ id: 'dl.row.collected', message: `Collected` }),
        accessor: 'collectedDate',
        type: 'date',
      },
    ];
  },
  get store() {
    return [
      {
        label: t({ id: 'dl.row.contact.person', message: `Contact person` }),
        accessor: 'person',
        type: 'text',
      },
      {
        label: t({ id: 'dl.row.email', message: `Email` }),
        accessor: 'email',
        type: 'text',
      },
      {
        label: t({ id: 'dl.row.phone', message: `Phone` }),
        accessor: 'phone',
        type: 'text',
      },
      {
        label: t({ id: 'dl.row.store.name', message: `Store Name` }),
        accessor: 'storeName',
        type: 'text',
      },
      {
        label: t({ id: 'dl.row.vendor.name', message: `Vendor Name` }),
        accessor: 'saleName',
        type: 'text',
      },
      {
        label: t({ id: 'dl.row.vendor.email', message: `Vendor Email` }),
        accessor: 'saleEmail',
        type: 'text',
      },
    ];
  },
  get tradeInStoreReceipt() {
    return [
      {
        label: t({ id: 'dl.row.store.name', message: `Store Name` }),
        accessor: 'storeName',
        type: 'text',
      },
      {
        label: t({ id: 'dl.row.store.email', message: `Store Email` }),
        accessor: 'storeEmail',
        type: 'text',
      },
      {
        label: t({ id: 'dl.row.vendor.name', message: `Vendor Name` }),
        accessor: 'vendorName',
        type: 'text',
      },
    ];
  },
};
