import { clearLoanDataAction, listDevicesAction } from 'actions/admin/device';
import { getLoanDataAction } from 'actions/admin/device';

import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import DeviceFiltersForm from 'components/DeviceFiltersForm';
import Heading from 'components/Heading';
import Loader from 'components/Loader';
import Table from 'components/table/Table';

import './PricingTool.styl';
import PricingToolDiscountForm from './components/PricingToolDiscountForm';

const PricingTool = ({
  devices,
  listDevices,
  loading,
  loanData,
  getLoanData,
  clearLoanData,
}) => {
  useEffect(() => {
    listDevices();
    clearLoanData();
    return undefined;
  }, [listDevices, clearLoanData]);

  const column = (
    label,
    accessor,
    type,
    sortable = true,
    align,
    wrap = false,
  ) => ({ label, accessor, type, sortable, align, wrap });

  const mainDeviceColumns = [
    column(
      t({ id: 'table.column.part.number', message: `Part Number` }),
      'deviceCode',
      'text',
      true,
      'center',
      false,
    ),
    column(
      t({ id: 'table.column.device.description', message: `Description` }),
      'name',
      'text',
      true,
      'center',
      false,
    ),
    column(
      t({ id: 'table.column.msrp', message: `MSRP` }),
      'msrp',
      'currency',
      true,
      'right',
      false,
    ),
    column(
      t({ id: 'table.column.sale.price', message: `Sale Price` }),
      'salePrice',
      'currency',
      true,
      'right',
      false,
    ),
    column(
      t({ id: 'table.column.discount', message: `Discount` }),
      'discount',
      'numeric',
      true,
      'right',
      false,
    ),
  ];

  const columns = [
    column(
      t({ id: 'table.column.payment.plan', message: `Payment Plan` }),
      'paymentPlan',
      'text',
      true,
      'center',
      false,
    ),
    column(
      t({
        id: 'table.column.effective.interest.rate',
        message: `Effective Interest Rate`,
      }),
      'effectiveInterestRate',
      'numeric',
      true,
      'right',
      false,
    ),
    column(
      t({
        id: 'table.column.annual.interest.rate',
        message: `Annual Interest Rate`,
      }),
      'annualInterestRate',
      'numeric',
      true,
      'right',
      false,
    ),
    column(
      t({ id: 'table.column.months', message: `Months` }),
      'months',
      'numeric',
      true,
      'right',
      false,
    ),
    column(
      t({ id: 'table.column.monthlyPrice', message: `Monthly Price` }),
      'monthlyPrice',
      'currency',
      true,
      'right',
      false,
    ),
    column(
      t({ id: 'table.column.total.paid.amount', message: `Total Paid Amount` }),
      'totalPaidAmount',
      'currency',
      true,
      'right',
      false,
    ),
  ];

  const submitData = ({ device, filterValues }) => {
    getLoanData(device, filterValues.selectedDate);
  };

  return (
    <div className='pricing-tool'>
      {loading && <Loader />}

      <Heading size='big'>
        <Trans id='pricing.tool.title'>Pricing tool</Trans>
      </Heading>

      <div className='pricing-tool__disclaimer'>
        <div className='icon__info-icon' />
        <span>
          <Trans id='pricing.tool.disclaimer'>
            In some markets interest is calculated on a daily basis so the
            monthly price calculated will be slightly higher at the start of the
            month than the middle or end of the month. It also varies based on
            the total number of days in the loan period. When checking price for
            a campaign, please check the price for the first day of each month
            within the campaign period to ensure you get the maximum monthly
            payment amount.
          </Trans>
        </span>
      </div>

      <div className='pricing-tool__filter'>
        <Heading size='medium'>
          <Trans id='pricing.tool.filter.title'>Find device by choosing</Trans>
        </Heading>
        {devices && (
          <DeviceFiltersForm submitData={submitData} devices={devices} />
        )}
      </div>

      {loanData && (
        <div className='pricing-tool__results'>
          <Heading size='medium'>{loanData.device.name}</Heading>

          <PricingToolDiscountForm />

          <div className='pricing-tool__tables'>
            <Table
              classes='pricing-tool__device-table'
              columns={mainDeviceColumns}
              data={[
                {
                  deviceCode: loanData.device.deviceCode,
                  name: loanData.device.name,
                  msrp: loanData.calendar[0].msrp,
                  salePrice: loanData.calendar[0].salePrice,
                  discount: loanData.discount,
                },
              ]}
              keyColumn='paymentPlan'
              filterable={false}
              pageable={false}
            />
            <Table
              classes='pricing-tool__calendar-table'
              columns={columns}
              data={loanData.calendar}
              keyColumn='paymentPlan'
              filterable={false}
              pageable={false}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(
  (state) => ({
    currency: state.whitelabel.currency,
    devices: state.adminDevice.devices,
    language: state.whitelabel.language,
    loading: state.ui.loading,
    loanData: state.adminDevice.loanData,
  }),
  {
    listDevices: listDevicesAction,
    getLoanData: getLoanDataAction,
    clearLoanData: clearLoanDataAction,
  },
)(PricingTool);
