import classnames from 'classnames';
import PropTypes from 'prop-types';

import React, { useState } from 'react';

import Heading from 'components/Heading';

import './TabbedContent.styl';

const TabbedContent = ({
  content = [],
  dataTest = 'tabbedContent',
  defaultIndex = 0,
  onAnyTabClick = () => {},
  theme = 'light',
  removePadding = false,
  fullWidthTabs = false,
}) => {
  const [activeIndex, setActiveIndex] = useState(
    defaultIndex >= 0 && defaultIndex < content.length ? defaultIndex : 0,
  );

  const handleTabClick = (index, onTabClick = () => {}) => {
    setActiveIndex(index);
    onAnyTabClick();
    onTabClick();
  };

  return (
    <div
      className={classnames('tabbedContent', `tabbedContent__${theme}`, {
        tabbedContent__unpaddedContent: removePadding,
        tabbedContent__fullWidthTabs: fullWidthTabs,
      })}
      data-test={dataTest}
    >
      <ul className='tabbedContent--tabs'>
        {content.map(({ dataTest = '', tab, onClick }, index) => (
          <li
            key={`tab-${index}`}
            data-test={dataTest}
            className={classnames(
              'tabbedContent--tab',
              index === activeIndex && 'tabbedContent--tab__active',
            )}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => handleTabClick(index, onClick)}
          >
            {tab}
          </li>
        ))}
      </ul>

      <div className={'tabbedContent--content'}>
        {content[activeIndex]?.title && (
          <Heading size='medium' classes='tabbedContent--title'>
            {content[activeIndex]?.title}
          </Heading>
        )}
        {content[activeIndex]?.content}
      </div>
    </div>
  );
};

TabbedContent.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      dataTest: PropTypes.string,
      onClick: PropTypes.func,
      tab: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    }),
  ),
  dataTest: PropTypes.string,
  defaultIndex: PropTypes.number,
  fullWidthTabs: PropTypes.bool,
  onAnyTabClick: PropTypes.func,
  removePadding: PropTypes.bool,
  theme: PropTypes.string,
};

export default TabbedContent;
