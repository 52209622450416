import React, { Fragment } from 'react';

import WLIcon from './WLIcon';

const ActiveUsers = ({ isToday, items, emptyText, className }) => {
  return (
    <Fragment>
      {isToday && (
        <table className={`StatsTable ${className}`}>
          <colgroup>
            <col width='50px;' />
            <col width='30px;' />
            <col width='140px;' />
            <col width='120px;' />
            <col width='120px;' />
            <col width='60px;' />
            <col width='100px;' />
          </colgroup>
          <tbody>
            <tr>
              <th colSpan='7'>
                <span className='StatsTable_caption'>Active Users</span>
              </th>
            </tr>

            <tr className='StatsTable_innerHeader'>
              <td>Inactive</td>
              <td colSpan='2'>Store</td>
              <td>Cart</td>
              <td>Page</td>
              <td colSpan='2'>Status</td>
            </tr>

            {items.map((item, index) => (
              <tr key={index}>
                <td className='no-wrap'>
                  {item.inactive < 60
                    ? item.inactive
                    : Math.floor(item.inactive / 60)}{' '}
                  <small>{item.inactive < 60 ? 'secs' : 'min'}</small>
                </td>

                <td colSpan='2'>
                  <WLIcon item={item} index={index} /> {item.storeName}
                </td>

                <td>{item.cartId}</td>
                <td>{item.page}</td>
                <td colSpan='2' />
              </tr>
            ))}

            {items.length === 0 && (
              <tr>
                <td colSpan='7' className='StatsTable_none'>
                  {emptyText}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </Fragment>
  );
};

export default ActiveUsers;
