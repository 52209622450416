import { addSerialNumbers, getSaleAction } from 'actions/sales';
import { closeModalAction } from 'actions/ui';
import completeIcon from 'assets/images/Ico-complete.svg';
import alertIcon from 'assets/images/alert-red.svg';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Trans, defineMessage, t } from '@lingui/macro';

import Modal from 'components/Modal';

import './SerialNumbersModal.styl';

const labels = {
  save: defineMessage({
    id: 'serial.numbers.save.changes',
    message: 'Save Changes',
  }),
  close: defineMessage({
    id: 'serial.numbers.close',
    message: 'Close',
  }),
  notRequired: defineMessage({
    id: 'serial.numbers.not.required',
    message: 'Serial numbers not required',
  }),
};

const SerialNumbersModal = () => {
  const dispatch = useDispatch();

  const { cartUUID } = useParams();

  const products = useSelector(({ sales }) =>
    sales.sale.contracts.reduce((acc, cv) => [...acc, ...cv.products], []),
  );

  const extras = useSelector(({ sales }) =>
    sales.sale.contracts.reduce((acc, cv) => [...acc, ...cv.extras], []),
  );

  const [serialNumbers, setSerialNumbers] = useState([]);

  const addSerialNumber = (id, serialNumber) => {
    const current = serialNumbers;
    const match = current.findIndex((s) => s.id === id);
    if (match === -1) {
      setSerialNumbers([...current, { id: id, deviceReference: serialNumber }]);
    } else {
      current[match].deviceReference = serialNumber;
      setSerialNumbers([...current]);
    }
  };

  return (
    <Modal
      name='serial-numbers'
      title='Serial Numbers'
      scrollable={true}
      buttons={[
        {
          label: <Trans id={labels.save.id} />,
          action: async () => {
            await dispatch(addSerialNumbers(cartUUID, serialNumbers));
            await dispatch(getSaleAction(cartUUID));
          },
        },
        {
          label: <Trans id={labels.close.id} />,
          action: () => dispatch(closeModalAction('serial-numbers')),
        },
      ]}
    >
      <div className='add__serial__numbers__content'>
        {products && (
          <SerialNumbers items={products} addSerialNumber={addSerialNumber} />
        )}
        {extras && (
          <SerialNumbers items={extras} addSerialNumber={addSerialNumber} />
        )}
      </div>
    </Modal>
  );
};

const SerialNumbers = ({ items, addSerialNumber }) => {
  return (
    <>
      {items.map((i) => (
        <section key={i.uuid}>
          <span>{i.description}</span>
          {i.requiresSerialNumber ? (
            <ul key={i.uuid}>
              {i.deviceReferences.map((dr) => (
                <SerialNumberInput
                  item={dr}
                  addSerialNumber={addSerialNumber}
                />
              ))}
            </ul>
          ) : (
            <div>
              <Trans id={labels.notRequired.id} />
            </div>
          )}
        </section>
      ))}
    </>
  );
};

const SerialNumberInput = ({ item, addSerialNumber }) => {
  const { id, deviceReference } = item;

  return (
    <li key={id}>
      {
        <div>
          <input
            placeholder={t({
              id: 'add.serial.numbers.input.placeholder',
              message: `Insert Serial Number`,
            })}
            value={deviceReference}
            disabled={deviceReference}
            onChange={({ currentTarget }) =>
              addSerialNumber(id, currentTarget.value)
            }
          ></input>
          {deviceReference ? (
            <div>
              <img
                src={completeIcon}
                alt={t({
                  id: 'add.serial.numbers.complete.message',
                  message: 'Added',
                })}
              />
            </div>
          ) : (
            <div>
              <img
                src={alertIcon}
                alt={t({
                  id: 'add.serial.numbers.missing.message',
                  message: 'Missing',
                })}
              />
            </div>
          )}
        </div>
      }
    </li>
  );
};

export default SerialNumbersModal;
