import classNames from 'classnames';
import PropTypes from 'prop-types';

import React from 'react';

import './InputHelperText.styl';

export const InputHelperText = ({
  showError,
  helpText = '',
  errorText = '',
  notificationPos = 'bottom',
  inputName = '',
}) => {
  return (
    <div
      className={classNames(`input__input-helper`, {
        'input__input-helper--error': showError,
        'input__input-helper--bottom': notificationPos === 'bottom',
      })}
    >
      <span className={`input__input-helper-msg`}>
        {showError ? `${inputName} ${errorText}` : helpText}
      </span>
    </div>
  );
};

InputHelperText.propTypes = {
  showError: PropTypes.bool,
  errorText: PropTypes.string,
  helpText: PropTypes.string,
  notificationPos: PropTypes.oneOf(['bottom', 'side']),
  inputName: PropTypes.string,
};

export default InputHelperText;
