import { hideLoaderAction, showLoaderAction } from 'actions/ui';
import { adminPost as post } from 'apis/v2';
import { uploadPost as upload } from 'apis/v2';

export const GET_VOUCHERS = 'GET_VOUCHERS';
export function getVouchers() {
  return async function (dispatch) {
    const response = await post(GET_VOUCHERS);

    dispatch({
      type: GET_VOUCHERS,
      data: response.data.data.vouchers,
    });
  };
}

const VOUCHERS = 'VOUCHERS';
const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_VOUCHERS = 'UPLOAD_VOUCHERS';
export function uploadVouchers(file) {
  return async function (dispatch) {
    dispatch(showLoaderAction());

    const response = await upload(UPLOAD_FILE, { file, type: VOUCHERS });

    if (response.data.status !== 'ERROR') {
      dispatch(hideLoaderAction());
      return response?.data?.data;
    }

    dispatch(hideLoaderAction());
  };
}
