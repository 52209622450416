import PropTypes from 'prop-types';

import React, { useCallback, useEffect, useState } from 'react';

import Button from 'components/Button';
import Form from 'components/Form';

import './ButtonForm.styl';

const ButtonForm = ({
  name,
  title,
  fields,
  values,
  handleSubmit,
  onClose = () => {},
  onOpen = () => {},
  onSubmitSuccess = () => {},
  toggleShowForm = false,
  openBtnLabel,
  getFormData,
}) => {
  const [showForm, setShowForm] = useState(false);
  const [valuesData, setValuesData] = useState({});
  const [hasRequestedFormData, setHasRequestedFormData] = useState(false);

  const onOpenCallback = useCallback(() => onOpen(), [onOpen]);

  const closeForm = useCallback(() => {
    setValuesData({});
    setShowForm(false);
    setHasRequestedFormData(false);
    onClose();
  }, [onClose]);

  const openForm = useCallback(() => {
    if (!showForm) {
      setShowForm(true);
      onOpenCallback();
    }
  }, [onOpenCallback, showForm]);

  const submitHandler = async (values) => {
    const response = await handleSubmit(values);

    if (response) {
      onSubmitSuccess();
      closeForm();
    }
  };

  useEffect(() => {
    return () => closeForm();
  }, []);

  useEffect(() => {
    if (showForm && !hasRequestedFormData) {
      getFormData();
      onOpenCallback();
      setHasRequestedFormData(true);
    }
  }, [showForm, hasRequestedFormData, getFormData, onOpenCallback]);

  useEffect(() => {
    setValuesData(values);
  }, [values]);

  useEffect(() => {
    if (toggleShowForm) {
      openForm();
    } else {
      setShowForm(false);
    }
  }, [toggleShowForm]);

  return (
    <div id={name} className='button-form'>
      {showForm && (
        <div className='button-form__wrapper'>
          <h2>{title}</h2>
          <Form
            form={name}
            classes='form--large'
            fields={fields}
            notificationPos={'side'}
            submitLabel='Submit'
            onSubmit={submitHandler}
            closeForm={closeForm}
            enableReinitialize={true}
            initialValues={valuesData}
          />
        </div>
      )}
      {!showForm && <Button onClick={openForm}>{openBtnLabel}</Button>}
    </div>
  );
};

ButtonForm.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  openBtnLabel: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      component: PropTypes.func.isRequired,
      classes: PropTypes.string,
      variant: PropTypes.string,
    }),
  ).isRequired,
};

export default ButtonForm;
