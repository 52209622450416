import { addToCartAction } from 'actions/carts';
import emptyBag from 'assets/images/bag-empty.svg';
import fullBag from 'assets/images/bag-full.svg';
import isEmpty from 'lodash/isEmpty';
import { pluralize } from 'utils';

import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';

import { cartPageUrl } from 'constants/routes';

import './CartBadge.styl';

const CartBadge = ({ cart }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let orders = [];

  if (cart) {
    orders = cart?.orders?.filter(
      ({ products, extras, discounts }) =>
        !isEmpty(products?.items) ||
        !isEmpty(extras?.items) ||
        !isEmpty(discounts?.items),
    );
  }

  const getTotalItems = () =>
    orders?.reduce((acc, order) => {
      return (
        acc +
        order.products.items.reduce((acc, product) => {
          return acc + product.quantity;
        }, 0) +
        order.extras.items.reduce((acc, extra) => {
          return acc + extra.quantity;
        }, 0)
      );
    }, 0);

  return (
    <>
      {getTotalItems() > 0 ? (
        cart.uuid && (
          <Link
            onClick={async () => {
              await dispatch(addToCartAction(cart.uuid, true));
              navigate(cartPageUrl(cart.uuid));
            }}
            className='cart-summary'
            data-test='cart-summary'
          >
            <img src={fullBag} alt='Full Cart' />(
            {pluralize(
              getTotalItems(),
              t({ id: 'cart.summary.item', message: `item` }),
            )}
            )
          </Link>
        )
      ) : (
        <div className='cart-summary'>
          <img src={emptyBag} alt='Empty Cart' />(
          <Trans id='cart.summary.empty.cart'>Empty Cart</Trans>)
        </div>
      )}
    </>
  );
};

export default CartBadge;
