import { t } from '@lingui/macro';

// prettier-ignore
export const extraTypes = () => ({
  ADAPTER: t({id: 'extras.adapters', message: `Adapters`}),
  ADAPTERKIT: t({id: 'extras.adapters.kits', message: `Adapter Kits`}),
  AIRPOD: t({id: 'extras.airpods', message: `AirPods`}),
  APPLECARE: t({id: 'extras.apple.care', message: `Apple Care`}),
  BAND: t({id: 'extras.bands', message: `Bands`}),
  BATTERYCASE: t({id: 'extras.battery.cases', message: `Battery Cases`}),
  BRACELET: t({id: 'extras.bracelets', message: `Bracelets`}),
  BRACELETKIT: t({id: 'extras.bracelets.kits', message: `Bracelet Kits`}),
  BUCKLE: t({id: 'extras.buckles', message: `Buckles`}),
  CABLE: t({id: 'extras.cables', message: `Cables`}),
  CASE: t({id: 'extras.cases', message: `Cases`}),
  CONVERTER: t({id: 'extras.converters', message: `Converters`}),
  DOCK: t({id: 'extras.docks', message: `Docks`}),
  EARPHONE: t({id: 'extras.earphones', message: `Earphones`}),
  EARPOD: t({id: 'extras.earpods', message: `Earpods`}),
  FOLIOCASE: t({id: 'extras.folio.case', message: `Folio Case`}),
  HEADPHONE: t({id: 'extras.headphones', message: `Headphones`}),
  IPHONE: t({id: 'extras.iphone', message: `iPhone`}),
  IPOD: t({id: 'extras.ipod', message: `iPod`}),
  KEYBOARD: t({id: 'extras.keyboards', message: `Keyboards`}),
  LEATHERCASE: t({id: 'extras.leather.cases', message: `Leather Cases`}),
  LEATHERSLEEVE: t({id: 'extras.leather.sleeves', message: `Leather Sleeves`}),
  LEATHERSMARTCOVER: t({id: 'extras.leather.smart.covers', message: `Leather Smart Covers`}),
  LOOP: t({id: 'extras.loops', message: `Loops`}),
  MOUSE: t({id: 'extras.mouses', message: `Mouses`}),
  PENCIL: t({id: 'extras.pencils', message: `Pencils`}),
  PENCILCASE: t({id: 'extras.pencil.cases', message: `Pencil Cases`}),
  PENCILTIP: t({id: 'extras.pencil.tips', message: `Pencil Tips`}),
  PERIPHERALS: t({id: 'extras.peripherals', message: `Peripherals`}),
  POWERADAPTER: t({id: 'extras.power.adapters', message: `Power Adapters`}),
  SILICONECASE: t({id: 'extras.silicone.cases', message: `Silicone Cases`}),
  SMARTCOVER: t({id: 'extras.smart.covers', message: `Smart Covers`}),
  SPEAKER: t({id: 'extras.speakers', message: `Speakers`}),
  STRIPE: t({id: 'extras.stripes', message: `Stripes`}),
  TRACKPAD: t({id: 'extras.trackpads', message: `Trackpads`}),
  UPGRADE: t({id: 'extras.upgrades', message: `Upgrades`}),
  WOVENNYLON: t({id: 'extras.woven.nylon', message: `Woven Nylon`}),
});
