import {
  CLEAR_EXTRAS_CATALOG,
  CLEAR_PRODUCT_CATEGORIES,
  CLEAR_PRODUCT_FAMILIES,
  CLEAR_PRODUCT_OPTIONS,
  GET_EXTRAS_CATEGORIES,
  GET_PRODUCT_CATEGORIES,
  SAVE_EXTRAS,
  SAVE_MORE_EXTRAS,
  SAVE_MORE_PRODUCT_FAMILIES,
  SAVE_PRODUCT_FAMILIES,
  SAVE_PRODUCT_OPTIONS,
  UNLOCK_MORE_EXTRAS,
} from 'actions/catalog';
import isEmpty from 'lodash/isEmpty';
import mergeWith from 'lodash/mergeWith';

import initialState from 'reducers/initialState';

export default function catalog(state = initialState.catalog, action) {
  switch (action.type) {
    case GET_PRODUCT_CATEGORIES:
      return { ...state, categories: action.data };
    case CLEAR_PRODUCT_CATEGORIES:
      return { ...state, categories: [] };
    case SAVE_PRODUCT_FAMILIES:
      return {
        ...state,
        families: action.families || [],
        allProductsFetched: action.allProductsFetched,
      };
    case SAVE_MORE_PRODUCT_FAMILIES:
      return {
        ...state,
        families: state.families.concat(action.families),
        allProductsFetched: action.allProductsFetched,
      };
    case CLEAR_PRODUCT_FAMILIES:
      return { ...state, families: [], allProductsFetched: false };
    case SAVE_PRODUCT_OPTIONS:
      return { ...state, ...action.options };
    case CLEAR_PRODUCT_OPTIONS:
      return { ...state, productOptions: [] };
    case SAVE_EXTRAS:
      return { ...state, extras: action.extras };
    case CLEAR_EXTRAS_CATALOG:
      return { ...state, extras: {} };
    case SAVE_MORE_EXTRAS:
      const extras = mergeWith(
        { ...state.extras },
        { ...action.extras },
        (objValue = {}, srcValue) => {
          return isEmpty(objValue) ? srcValue : objValue.concat(srcValue);
        },
      );

      return {
        ...state,
        ...action.data,
        extras,
        allExtrasFetched: action.allExtrasFetched,
      };
    case GET_EXTRAS_CATEGORIES:
      return { ...state, extrasCategories: action.data };
    case UNLOCK_MORE_EXTRAS:
      return { ...state, allExtrasFetched: false };
    default:
      return state;
  }
}
