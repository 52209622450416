import React from 'react';

import { Trans, t } from '@lingui/macro';

import Modal from 'components/Modal';

import FileUploadForm from './FileUploadForm';

const FileUploadModal = ({
  type,
  handleUpload,
  closeModal,
  title,
  accept,
  campaignCode,
}) => {
  const handleFileUpload = (file) => {
    handleUpload({ file, type, campaignCode });
  };
  return (
    <Modal name='fileUpload' classes='file-upload'>
      <h2>{title ?? <Trans id='file.upload.modal.title'>Upload CSV</Trans>}</h2>

      <FileUploadForm
        cancelAction={closeModal}
        cancelButtonLabel={t({
          id: 'file.upload.modal.cancel',
          message: `Cancel`,
        })}
        fieldLabel={t({
          id: 'file.upload.modal.label',
          message: `Click here to upload file`,
        })}
        form='fileUploadModalForm'
        uploadAction={handleFileUpload}
        uploadButtonLabel={t({
          id: 'file.upload.modal.confirm',
          message: `Upload`,
        })}
        vertical
        accept={accept}
      />
    </Modal>
  );
};

export default FileUploadModal;
