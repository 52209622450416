import isEmpty from 'lodash/isEmpty';

import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import Button from 'components/Button';
import Heading from 'components/Heading';
import Loader from 'components/Loader';
import Section from 'components/Section';
import TabbedContent from 'components/TabbedContent.js';
import IconAlert from 'components/icons/IconAlert';
import IconApproved from 'components/icons/IconApproved';
import Table from 'components/table/Table';

import { errorCodes } from 'constants/errors';

import FileUploadForm from './FileUploadForm';
import './FileUploadReport.styl';

const FileUploadReport = ({
  loading,
  fileFormat = '.csv',
  formName,
  formHelpText,
  formFieldLabel,
  formContent = '',
  resultName = '',
  reportAcessor,
  successCols,
  uploadFile,
  title,
  successObjMapper = (c) => c,
}) => {
  const [report, setReport] = useState({});
  const uploadHandler = async (file) => {
    const response = await uploadFile(file);
    setReport(response);
  };

  const errorCols = [
    {
      label: t({ id: 'file.upload.report.table.row', message: `Row` }),
      accessor: 'line',
      align: 'left',
    },
    {
      label: t({
        id: 'file.upload.report.table.error',
        message: `Error Description`,
      }),
      accessor: 'errorDesc',
      align: 'left',
    },
  ];

  const errorObjMapper = (c) => ({
    line: c.line,
    errorDesc: Object.keys(c.errorFields).reduce(
      (prev, curr) =>
        `${prev} ${curr} - ${
          errorCodes(
            c.errorFields[curr].values.length > 0
              ? c.errorFields[curr].values[0]
              : '',
          )[c.errorFields[curr].code] ?? c.errorFields[curr].code
        };`,
      '',
    ),
  });

  const closeReport = () => setReport({});

  return (
    <Fragment>
      {loading && <Loader />}
      <Heading size='big' classes='admin-page-title'>
        {title}
      </Heading>
      <Section className='file-upload__form-section'>
        <FileUploadForm
          accept={fileFormat}
          uploadButtonLabel={t({ id: 'file.upload.submit', message: `Upload` })}
          fieldLabel={formFieldLabel}
          fieldVariant='underline'
          form={formName}
          helpText={formHelpText}
          uploadAction={uploadHandler}
        />
        {formContent}
      </Section>
      {!isEmpty(report) && (
        <Section className='file-upload__report-section'>
          <Heading size='medium'>
            <Trans id='file.upload.report.title'>
              Upload report for {report.fileName}
            </Trans>
          </Heading>
          {report.errorCount === 0 && (
            <p>
              <Trans id='file.upload.report.success'>
                File uploaded successfully. Please check the report below.
              </Trans>
            </p>
          )}
          {report.errorCount > 0 && (
            <p>
              <Trans id='file.upload.report.failure'>
                Errors found while parsing file. Please check the report below.
                below. Try uploading the file again after correcting the issues.
              </Trans>
            </p>
          )}
          <ul>
            <li>
              <IconApproved className='file-upload__icon-approved' />
              <Trans id='file.upload.report.total'>
                {report.totalCount} rows ({resultName}) with{' '}
                {report.totalColumnsCount} columns found.
              </Trans>
            </li>
            <li>
              <IconApproved className='file-upload__icon-approved' />
              <Trans id='file.upload.report.parsed'>
                {report.parseSuccessCount} row(s) successfully parsed.
                {report.insertSuccessCount} {resultName}(s) inserted.
                {report.updateSuccessCount} {resultName}(s) updated.
              </Trans>
            </li>
            {report.errorCount > 0 && (
              <li>
                <IconAlert className='file-upload__icon-alert' />
                <Trans id='file.upload.report.errors'>
                  {report.errorCount} row(s) failed parsing.
                  {report?.columnsErrorCount} column(s) with errors.
                </Trans>
              </li>
            )}
          </ul>
          {!isEmpty(report[reportAcessor]) && (
            <TabbedContent
              content={[
                {
                  tab: t({
                    id: 'file.upload.report.errors.tab.title',
                    message: `Errors`,
                  }),
                  content: (
                    <Table
                      classes='file-upload__table-errors'
                      columns={errorCols}
                      data={report[reportAcessor]
                        .filter((c) => c.withErrors)
                        .map(errorObjMapper)}
                      keyColumn='line'
                      defaultOrder='line'
                      filterable={false}
                    />
                  ),
                },
                {
                  tab: t({
                    id: 'file.upload.report.inserted.tab.title',
                    message: `Inserted`,
                  }),
                  content: (
                    <Table
                      classes='file-upload__table-inserted'
                      columns={successCols}
                      data={report[reportAcessor]
                        .filter((c) => c.inserted)
                        .map(successObjMapper)}
                      keyColumn='line'
                      defaultOrder='line'
                      filterable={false}
                    />
                  ),
                },
                {
                  tab: t({
                    id: 'file.upload.report.updated.tab.title',
                    message: `Updated`,
                  }),
                  content: (
                    <Table
                      classes='file-upload__table-updated'
                      columns={successCols}
                      data={report[reportAcessor]
                        .filter((c) => c.updated)
                        .map(successObjMapper)}
                      keyColumn='line'
                      defaultOrder='line'
                      filterable={false}
                    />
                  ),
                },
              ]}
            />
          )}
          <div className='file-upload__report-actions'>
            <Button onClick={closeReport}>
              <Trans id='file.upload.report.actions.close'>Close report</Trans>
            </Button>
          </div>
        </Section>
      )}
    </Fragment>
  );
};

export default connect(({ ui, whitelabel }) => ({
  currency: whitelabel.currency,
  language: whitelabel.language,
  loading: ui.loading,
}))(FileUploadReport);
