import { getStartingInfo } from 'actions/whitelabel';
import { createBrowserHistory } from 'history';
import 'styles/themes/base.styl';
import { getSubOrFullDomain } from 'utils';

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import './AppRoot.styl';
import CacheReloader from './CacheReloader';
import I18nLoader from './I18nLoader';
import Notifications from './Notifications';
import PosaleRouter from './PosaleRouter';

export const history = createBrowserHistory();

export const AppRoot = () => {
  const dispatch = useDispatch();

  const { REACT_APP_SUBDOMAIN } = process.env;
  const subdomain = REACT_APP_SUBDOMAIN || getSubOrFullDomain();

  useEffect(() => {
    const getWhitelabelData = async () => {
      await dispatch(getStartingInfo(subdomain));
    };

    getWhitelabelData();

    if (process.env.NODE_ENV !== 'development') {
      Sentry.init({
        dsn: 'https://ecb8b6bdfd2c4bd3ae37ee620a6a782b@o348393.ingest.sentry.io/2212535',
        autoSessionTracking: true,
        integrations: [new Integrations.BrowserTracing()],
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
      });
    }

    const themeObserver = new MutationObserver((mutationList, observer) => {
      mutationList.forEach((mutation) => {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'theme'
        ) {
          const theme = document.documentElement.getAttribute('theme');
          const link16 = document.getElementById('favicon-16');
          const link32 = document.getElementById('favicon-32');
          const linkApple = document.getElementById('favicon-apple-touch');
          const linkMask = document.getElementById('favicon-mask');
          const linkManifest = document.getElementById('link-manifest');

          linkApple.href = `https://cdn.financetech365.com/favicons/${theme}/apple-touch-icon.png`;
          link32.href = `https://cdn.financetech365.com/favicons/${theme}/favicon-32x32.png`;
          link16.href = `https://cdn.financetech365.com/favicons/${theme}/favicon-16x16.png`;
          linkMask.href = `https://cdn.financetech365.com/favicons/${theme}/safari.svg`;
          linkManifest.href = `https://cdn.financetech365.com/favicons/${theme}/manifest.webmanifest`;

          return import(`styles/themes/${theme}.styl`);
        }

        return null;
      });
    });

    themeObserver.observe(document.documentElement, {
      attributes: true,
      childList: false,
      subtree: false,
    });

    return () => {
      themeObserver.disconnect();
    };
  }, [subdomain, dispatch]);

  return (
    <CacheReloader>
      <I18nLoader>
        <HistoryRouter history={history}>
          <Notifications />
          <PosaleRouter />
        </HistoryRouter>
      </I18nLoader>
    </CacheReloader>
  );
};

export default AppRoot;
