import classNames from 'classnames';
import { validateDeviceReference } from 'helpers/validators';
import { format, length, required } from 'helpers/validators';
import { Field, getFormValues, reduxForm } from 'redux-form';

import React from 'react';
import { connect } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import Badges from 'components/Badges';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import RadioButtons from 'components/RadioButtons';
import RenderField from 'components/RenderField';
import HowToFindIMEI from 'components/device_reference/HowToFindIMEI';

import { ACTIVE_WITHOUT_DEVICE_REFERENCE } from 'constants/contracts';
import { IMEI, SERIAL } from 'constants/deviceReference';

import './DeviceCodeForm.styl';

const DeviceCodeForm = ({
  id,
  disabled = false,
  disableReferenceTypeField = false,
  deviceReference,
  initialreferenceType = IMEI,
  invalid,
  formValues: { referenceType } = { referenceType: initialreferenceType },
  handleSubmit,
  customIMEILabel = null,
  customSerialLabel = null,
  submitting,
  showRefHelper = false,
  submitted = false,
  statusLabel,
  codeAmendDevices,
}) => {
  const referenceTypeFieldRender = ({
    input,
    disabled,
    referenceType,
    classes,
  }) => {
    const { onChange } = input;
    const clickHandler = ({ value }) => onChange(value);

    return (
      <RadioButtons
        disabled={disabled}
        options={[
          {
            key: 'imei',
            value: IMEI,
            label: t({
              id: 'button.label.reference.type.imei',
              message: `IMEI`,
            }),
          },
          {
            key: 'serial',
            value: SERIAL,
            label: t({
              id: 'button.label.reference.type.serial',
              message: `Serial Number`,
            }),
          },
        ]}
        selectedValue={referenceType}
        onClick={clickHandler}
        classes={classes}
      />
    );
  };

  const parseDeviceList = (devices) => {
    return devices?.map(({ name, deviceCode }) => ({
      value: deviceCode,
      label: name,
    }));
  };

  return (
    <form
      id={id}
      className={classNames('deviceCodeForm', {
        'deviceCodeForm--has-helper': showRefHelper,
      })}
      onSubmit={!disabled ? handleSubmit : undefined}
    >
      <Field
        name='deviceCode'
        component={Dropdown}
        classes='deviceCodeForm__code-select'
        placeholder={t({
          id: 'device.code.form.part-number.placeholder',
          message: `New Part Number`,
        })}
        options={parseDeviceList(codeAmendDevices)}
        validate={required()}
        labelType='outside'
        variant='underline'
        testSelector='device-code-input'
      />

      {!submitted && statusLabel !== ACTIVE_WITHOUT_DEVICE_REFERENCE && (
        <Field
          disabled={disableReferenceTypeField}
          name='referenceType'
          classes='deviceCodeForm__refType'
          component={referenceTypeFieldRender}
          props={{
            disabled: disableReferenceTypeField
              ? disableReferenceTypeField
              : disabled,
            referenceType,
          }}
        />
      )}

      {statusLabel !== ACTIVE_WITHOUT_DEVICE_REFERENCE && (
        <Field
          name='deviceReference'
          type='text'
          className={classNames('deviceCodeForm__ref', {
            'deviceCodeForm__ref--disabled': disabled,
          })}
          component={RenderField}
          disabled={disabled}
          initialValue={deviceReference}
          validate={[
            format({
              without: /#/g,
              message: t({
                id: 'form.hashtag.error',
                message: `# isn't allowed.`,
              }),
            }),
            length({ max: 50 }),
          ]}
          animateLabel={false}
          label={
            referenceType === IMEI
              ? customIMEILabel ||
                t({
                  id: 'device.reference.form.imei.placeholder',
                  message: `Insert IMEI`,
                })
              : customSerialLabel ||
                t({
                  id: 'device.reference.form.serial.placeholder',
                  message: `Insert Serial Number`,
                })
          }
        />
      )}

      {submitted ? (
        <Badges
          badges={{
            classes: 'device-reference__badge--valid',
            label: t({
              id: 'deviceReference.form.submitted.badge',
              message: `OK`,
            }),
          }}
        />
      ) : (
        <Button
          classes='device-code__button'
          type='submit'
          disabled={invalid || submitting || disabled}
          loading={submitting}
          testSelector='submit-button'
        >
          <Trans id='deviceCode.form.submit.button'>Submit</Trans>
        </Button>
      )}

      {showRefHelper && <HowToFindIMEI />}
    </form>
  );
};

const ReduxDeviceCodeForm = reduxForm({
  shouldValidate: () => true,
  enableReinitialize: true,
  validate: (
    { referenceType, deviceReference },
    { useAppleValidation = false },
  ) =>
    validateDeviceReference(
      deviceReference,
      referenceType === IMEI,
      useAppleValidation,
    ),
})(DeviceCodeForm);

export default connect(
  (
    state,
    {
      form = 'deviceCodeForm',
      initialreferenceType = IMEI,
      deviceReference = '',
      referenceType = initialreferenceType,
    },
  ) => ({
    form: form,
    initialValues: { referenceType, deviceReference },
    codeAmendDevices: state.sales.codeAmendDevices,
    formValues: getFormValues(form)(state),
  }),
  null,
)(ReduxDeviceCodeForm);
