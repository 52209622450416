// Version
export const API_VERSION = process.env.REACT_APP_API_VERSION;

export const API_POSALE_ENDPOINT = process.env.REACT_APP_POSALE_URL;
export const API_ADMIN_ENDPOINT = process.env.REACT_APP_ADMIN_URL;
export const API_UPLOAD_ENDPOINT = process.env.REACT_APP_UPLOAD_URL;

export const API_POSALE_SERVICE = 'PosaleService';
export const API_ADMIN_SERVICE = 'AdminService';
export const API_UPLOAD_SERVICE = 'UploadService';
