let DATA = [
  {
    id: 'XXX',
    fileName: 'FileName.pdf',
    extension: 'pdf',
    fileSize: '15MB',
    uploadedAt: new Date().toLocaleString(),
    uploadedBy: 'User Example',
    html: `I'm and HTML attachment`,
    campaigns: ['campaign_1', 'campaign_2'],
    templates: ['template_1', 'template_2', 'template_3', 'template_4'],
    associated: true,
  },
  {
    id: 'XXX_1',
    fileName: 'FileName.xlsx',
    extension: 'xlsx',
    fileSize: '100KB',
    uploadedAt: new Date().toLocaleString(),
    uploadedBy: 'User Example',
    campaigns: ['campaign_1'],
    templates: [],
    associated: false,
  },
  {
    id: 'XXX_2',
    fileName: 'FileName.html',
    extension: 'html',
    fileSize: '10KB',
    uploadedAt: new Date().toLocaleString(),
    uploadedBy: 'User Example',
    html: `I'm and HTML attachment`,
    campaigns: [],
    templates: [],
    associated: false,
  },
];

export const GET_ATTACHMENTS = 'GET_ATTACHMENTS';
export function getAttachmentsAction(fileExtension) {
  return async function (dispatch) {
    const arr = fileExtension
      ? DATA.filter((item) => item.extension === fileExtension)
      : DATA;
    dispatch({
      type: GET_ATTACHMENTS,
      data: arr,
    });
  };
}

export const SAVE_ATTACHMENT = 'SAVE_ATTACHMENT';
export function saveAttachment(attachment) {
  return async function (dispatch) {
    DATA = [
      ...DATA,
      {
        ...attachment,
        id: Math.floor(Math.random() * 1000),
        fileSize: '15MB',
        extension: attachment.fileName.split('.').pop(),
        uploadedAt: new Date().toLocaleString(),
        uploadedBy: 'User Example',
      },
    ];
    dispatch({
      type: GET_ATTACHMENTS,
      data: DATA,
    });
  };
}

export const DELETE_ATTACHMENTS = 'DELETE_ATTACHMENTS';
export function deleteAttachments(selected) {
  DATA = DATA.filter(({ id }) => !selected.includes(id));
  return async function (dispatch) {
    dispatch({
      type: GET_ATTACHMENTS,
      data: DATA,
    });
  };
}
