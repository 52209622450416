import classNames from 'classnames';
import PropTypes from 'prop-types';

import React from 'react';

import InputHelperText from 'components/InputHelperText';

import './Input.styl';

const WRAPPER_CLASS = 'input__wrapper';
const CONTAINER_CLASS = 'input__container';
const LABEL_CLASS = 'input__floating-label';
const INPUT_CLASS = 'input__input';

const variants = {
  'no-radius': `${CONTAINER_CLASS}--no-radius`,
  underline: `${CONTAINER_CLASS}--underline`,
  rounded: `${CONTAINER_CLASS}--rounded`,
};
const sizes = {
  medium: `${CONTAINER_CLASS}--medium-height`,
  large: `${CONTAINER_CLASS}--large-height`,
};
const widths = {
  half: `${WRAPPER_CLASS}--half-width`,
  third: `${WRAPPER_CLASS}--third-width`,
};

export const Input = ({
  classes,
  debounce = false,
  disabled = false,
  icon,
  input = {},
  helpText = '',
  notificationPos = 'bottom',
  labelType,
  meta: { active, error, touched } = {},
  onChange = () => {},
  placeholder = '',
  placeholderExtra = '',
  size = 'medium',
  testSelector,
  type = 'text',
  value = '',
  variant = '',
  width = 'full',
  showInputNameError = true,
}) => {
  const showError = (active || touched) && error?.length > 0;

  return (
    <div
      className={classNames(WRAPPER_CLASS, classes, widths[width])}
      data-test={testSelector}
    >
      <div
        className={classNames(CONTAINER_CLASS, sizes[size], variants[variant], {
          [`${CONTAINER_CLASS}--focused`]: active,
          [`${CONTAINER_CLASS}--disabled`]: disabled,
          [`${CONTAINER_CLASS}--error`]: showError,
        })}
      >
        <input
          {...input}
          className={classNames(INPUT_CLASS, {
            [`${INPUT_CLASS}--with-icon`]: icon,
            [`${INPUT_CLASS}--outside-label`]: labelType === 'outside',
          })}
          disabled={disabled}
          type={type}
          onChange={debounce ? onChange : input.onChange}
          value={debounce ? value : input.value}
        />

        {/* // TODO - designs show 2 different values for the placeholder and floating label */}
        <label
          className={classNames(LABEL_CLASS, {
            [`${LABEL_CLASS}--active`]:
              active || input.value || input.value === 0,
            [`${LABEL_CLASS}--hidden`]: labelType === 'hidden',
            [`${LABEL_CLASS}--outside`]: labelType === 'outside',
          })}
        >
          {placeholder}
          {placeholderExtra}
        </label>

        {icon && icon({ title: '' })}
      </div>
      <InputHelperText
        showError={showError}
        helpText={helpText}
        errorText={error}
        notificationPos={notificationPos}
        inputName={showInputNameError ? placeholder : ''}
      />
    </div>
  );
};

Input.propTypes = {
  classes: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.func,
  input: PropTypes.object.isRequired,
  helpText: PropTypes.string,
  meta: PropTypes.shape({
    active: PropTypes.bool,
    error: PropTypes.string,
    touched: PropTypes.bool,
  }).isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.oneOf(['', 'no-radius', 'underline', 'rounded']),
  showInputNameError: PropTypes.bool,
  size: PropTypes.oneOf(['medium', 'large']),
  width: PropTypes.oneOf(['full', 'half', 'third']),
  labelType: PropTypes.oneOf(['', 'hidden', 'outside']),
};

export default Input;
