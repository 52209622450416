import * as React from 'react';

function SvgIconAlert({ title, titleId, ...props }) {
  return (
    <svg
      viewBox='0 0 24 24'
      width='1em'
      height='1em'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <clipPath id='icon-alert_svg__a'>
          <path d='M0 0h24v24H0z' />
        </clipPath>
      </defs>
      <g clipPath='url(#icon-alert_svg__a)'>
        <path
          fill='currentColor'
          d='M4.222 19.778A11 11 0 1119.778 4.222 11 11 0 114.222 19.778zM3 12a9 9 0 109-9 9.01 9.01 0 00-9 9zm8 5a1 1 0 111 1 1 1 0 01-1-1zm0-3V6h2v8z'
        />
      </g>
    </svg>
  );
}

export default SvgIconAlert;
