import { isCartUUID, isDigitOnlyString } from 'helpers/validators';

import React from 'react';
import { useParams } from 'react-router-dom';

const TrackingPixel = ({
  pageName,
  userID,
  storeID,
  orderGUID,
  wlID,
  type,
  message,
  display = true,
  param1,
  param2,
}) => {
  const { cartUUID } = useParams();
  // sending orderGUID as cartID to show up in dashboard
  const parseOrderGUID = () => `${orderGUID ? `&cartId=${orderGUID}` : ''}`;
  return (
    <>
      {display && (
        <img
          className='img-pixel'
          src={
            `${process.env.REACT_APP_TRACK_URL}` +
            `?action=${type}` +
            `${userID ? `&userId=${userID}` : ''}` +
            `${storeID ? `&storeId=${storeID}` : ''}` +
            `${wlID ? `&wlId=${wlID}` : ''}` +
            `${
              isCartUUID(cartUUID) || isDigitOnlyString(cartUUID)
                ? `&cartId=${cartUUID}`
                : parseOrderGUID()
            }` +
            `${pageName ? `&page=${pageName}` : ''}` +
            `${message ? `&error=${encodeURI(message)}` : ''}` +
            `${param1 ? `&param1=${param1}` : ''}` +
            `${param2 ? `&param2=${param2}` : ''}`
          }
          width='1'
          height='1'
          alt={type}
        />
      )}
    </>
  );
};

export default TrackingPixel;
