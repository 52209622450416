import {
  CREATE_CAMPAIGN,
  DELETE_COMMUNICATION,
  DELETE_UPGRADE_OFFER,
  DELETE_UPGRADE_OFFER_RULE,
  GET_ALL_CAMPAIGNS,
  GET_CAMPAIGN,
  GET_CAMPAIGN_COHORTS,
  GET_CAMPAIGN_FORM_DATA,
  GET_CAMPAIGN_MESSAGES,
  GET_CAMPAIGN_OFFERS,
  GET_OFFERS_FORM_DATA,
  UPDATE_CAMPAIGN,
} from 'actions/admin/campaign';

import initialState from 'reducers/initialState';

export default function admin(state = initialState.adminCampaign, action) {
  switch (action.type) {
    case GET_ALL_CAMPAIGNS:
    case CREATE_CAMPAIGN:
      return { ...state, campaigns: action.data };
    case GET_CAMPAIGN:
    case UPDATE_CAMPAIGN:
    case DELETE_COMMUNICATION:
      return {
        ...state,
        campaign: { ...action.data, replyTo: action.data?.replyTo?.[0] },
      };
    case GET_CAMPAIGN_OFFERS:
      return { ...state, ...action.data };
    case GET_CAMPAIGN_MESSAGES:
      return { ...state, ...action.data };
    case GET_CAMPAIGN_COHORTS:
      return { ...state, ...action.data };
    case GET_CAMPAIGN_FORM_DATA:
      return {
        ...state,
        ...action.data,
      };
    case GET_OFFERS_FORM_DATA:
      return {
        ...state,
        ...action.data,
      };
    case DELETE_UPGRADE_OFFER:
      return {
        ...state,
        offers: action.data,
      };
    case DELETE_UPGRADE_OFFER_RULE:
      return {
        ...state,
        offerRules: action.data,
      };
    default:
      return state;
  }
}
