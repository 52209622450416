import React from 'react';

import WLList from './WLList';

const SalesGraph = ({
  title,
  wls,
  items,
  multiplier,
  emptyText,
  className,
}) => {
  const renderSalesBlock = (item, index, multiplier) => {
    var backgroundColour = item.colour;
    if (!backgroundColour) {
      var wl = wls.find(function (element) {
        return element.wlID === item.id;
      }, item.id);
      backgroundColour = wl.wlColourCode;
    }
    var divStyle = {
      width: `${Math.ceil(item.itemCount * multiplier)}px`,
      backgroundColor: backgroundColour,
    };
    var titleName = item.name;
    if (!titleName) {
      titleName = wl?.wlName || '';
    }

    return (
      <div
        key={`d${index}`}
        style={divStyle}
        className='StatsTable_salesCounter'
        title={`${titleName} ${item.itemCount}`}
      />
    );
  };

  return (
    <table className={`StatsTable ${className}`}>
      <colgroup>
        <col width='50px;' />
        <col width='40px;' />
        <col width='160px;' />
        <col width='40px;' />
        <col width='190px;' />
        <col width='70px;' />
        <col width='80px;' />
      </colgroup>
      <tbody>
        <tr>
          <th colSpan='7'>
            <span className='StatsTable_caption'>{title}</span>{' '}
            <WLList items={wls} />
          </th>
        </tr>

        <tr className='StatsTable_innerHeader'>
          <td>Day</td>
          <td>Total</td>
          <td colSpan='5' />
        </tr>

        {items.map((item, index) => (
          <tr key={`r${index}`}>
            <td>
              <small>{item.period}</small>
            </td>
            <td>{item.totalItems}</td>
            <td colSpan='5'>
              {item.itemCounters.map((item, index) => {
                return renderSalesBlock(item, index, multiplier);
              })}
            </td>
          </tr>
        ))}

        {items.length === 0 && (
          <tr>
            <td colSpan='7' className='StatsTable_none'>
              {emptyText}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default SalesGraph;
