import { t } from '@lingui/macro';

export const STORE_DISABLED = 0;
export const STORE_ENABLED = 1;

export const storeOptions = () => [
  {
    value: STORE_DISABLED,
    label: t({ id: 'status.options.disabled', message: `Disabled` }),
  },
  {
    value: STORE_ENABLED,
    label: t({ id: 'status.options.enabled', message: `Enabled` }),
  },
];

export const CONTRACT_ADJUST_PAGE_QUANTITY = 20;
