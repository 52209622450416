import { getReturnedDevicesAction } from 'actions/admin/collection';
import { Field, getFormValues, reduxForm, submit } from 'redux-form';

import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';

import Footer from 'components/Footer';
import RenderDatePicker, { formatDate } from 'components/RenderDatePicker';
import SearchBar from 'components/SearchBar';
import Section from 'components/Section';
import Table from 'components/table/Table';

import { recycleDetailsUrl } from 'constants/routes';
import { recycleStatus } from 'constants/status';

import useTrackPage from 'hooks/useTrackPage';

import './Recycle.styl';

const Recycle = ({ handleSubmit }) => {
  useTrackPage('Trades');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const returnedDevices = useSelector(
    ({ adminCollection }) => adminCollection?.returnedDevices,
  );
  const formValues = useSelector((state) =>
    getFormValues('recycle-form')(state),
  );

  useEffect(() => {
    handleSubmit();
  }, [handleSubmit]);

  const columns = [
    {
      label: t({ id: 'table.column.source', message: `Source` }),
      accessor: 'source',
      type: 'text',
      list: recycleStatus(),
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.id', message: `ID` }),
      accessor: 'id',
      type: 'text',
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.quote.id', message: `Quote ID` }),
      accessor: 'orderId',
      type: 'text',
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.device', message: `Device` }),
      accessor: 'deviceName',
      type: 'text',
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.imei.or.serial',
        message: `IMEI / Serial No.`,
      }),
      accessor: 'imei',
      type: 'text',
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.condition', message: `Condition` }),
      accessor: 'condition',
      type: 'text',
      filterable: true,
      sortable: true,
      align: 'left',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.store', message: `Store` }),
      accessor: 'storeName',
      type: 'text',
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.received.date',
        message: `Received Date`,
      }),
      accessor: 'receivedDate',
      type: 'date',
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.last.collection.date',
        message: `Latest Collection Date`,
      }),
      accessor: 'latestCollectionDate',
      type: 'date',
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.actions', message: `Actions` }),
      accessor: 'actions',
      type: 'actions',
      filterable: false,
      sortable: false,
      align: 'right',
      wrap: false,
    },
  ];

  const actions = [
    {
      label: t({ id: 'quote.action.view', message: `View` }),
      handler: (_, recycleReport) => {
        navigate(recycleDetailsUrl(recycleReport.tradecartId));
      },
    },
  ];

  const rows = returnedDevices.map((recycleReport) => ({
    ...recycleReport,
    actions,
  }));

  const searchHandler = () =>
    dispatch(getReturnedDevicesAction(formValues, true));

  return (
    <Fragment>
      <h1 className='admin-page-title'>
        <Trans id='contract.recycle.title'>Recycled Quotes</Trans>
      </h1>

      <Section inline>
        <SearchBar
          label={t({
            id: 'trade.reports.imei.placeholder',
            message: `Search by IMEI or Serial Number`,
          })}
          handleChange={searchHandler}
        />

        <form className='datepicker-container'>
          <Trans id='recycle.received'>Received</Trans>

          <Field
            name='startDate'
            component={RenderDatePicker}
            label={t({
              id: 'recycle.form.start.date.label',
              message: `from`,
            })}
            format={formatDate}
            parse={formatDate}
            startDate={formValues?.startDate}
            endDate={formValues?.endDate}
            maxDate={formValues?.endDate || new Date().toString()}
            selectsStart
          />

          <Field
            name='endDate'
            component={RenderDatePicker}
            label={t({ id: 'recycle.form.end.date.label', message: `to` })}
            format={formatDate}
            parse={formatDate}
            startDate={formValues?.startDate}
            endDate={formValues?.endDate}
            minDate={formValues?.startDate}
            maxDate={new Date().toString()}
            selectsEnd
          />
        </form>
      </Section>

      <Section>
        <Table
          columns={columns}
          data={rows}
          keyColumn='createdAt'
          defaultOrder='createdAt'
          defaultDir='desc'
        />
      </Section>

      <Footer classes='main' copyright={false} />
    </Fragment>
  );
};

Recycle.defaulProps = {
  formValues: { imei: '' },
};

export default reduxForm({
  form: 'recycle-form',
  onSubmit: (values, dispatch) => {
    dispatch(getReturnedDevicesAction(values, true));
  },
  onChange: (_values, dispatch) => {
    dispatch(submit('recycle-form'));
  },
})(Recycle);
