import { upsertStoreAction } from 'actions/admin/store';
import { email, format, required } from 'helpers/validators';
import { length } from 'helpers/validators';
import isEmpty from 'lodash/isEmpty';
import { Field, reduxForm } from 'redux-form';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import Heading from 'components/Heading';
import Modal from 'components/Modal';
import RenderField from 'components/RenderField';
import RenderTextAreaField from 'components/RenderTextAreaField';

import { storeOptions } from 'constants/admin';
import { contractWarn, phoneRegex } from 'constants/forms';

export class StoreForm extends Component {
  render() {
    const {
      invalid,
      storeExternalSID,
      submitting,
      handleSubmit,
      upsertStore,
      initialValues,
    } = this.props;

    const schedulePlaceholder = [
      t({
        id: 'store.form.schedule.placeholder.one',
        message: `Insert the opening and closing schedule of the store on each line, for e.g:.`,
      }),
      '',
      t({
        id: 'store.form.schedule.placeholder.two',
        message: `Mon 9h00-18h00`,
      }),
      t({
        id: 'store.form.schedule.placeholder.three',
        message: `Tue 9h00-12h00, 13h00-18h00`,
      }),
      '...',
    ];

    return (
      <Modal name='admin-store'>
        <form>
          {storeExternalSID && (
            <Field
              disabled={!isEmpty(initialValues.externalSID)}
              name='externalSID'
              type='text'
              testSelector='externalSID'
              component={RenderField}
              validate={[required(), length({ max: 255 })]}
              label={t({
                id: 'store.form.externalSID.placeholder',
                message: `External SID`,
              })}
            />
          )}

          <Field
            name='name'
            type='text'
            testSelector='name'
            component={RenderField}
            validate={[required(), length({ max: 250 })]}
            warn={contractWarn}
            label={t({ id: 'store.form.name.placeholder', message: `Name` })}
          />

          <Field
            name='email'
            type='email'
            testSelector='email-address'
            component={RenderField}
            validate={[required(), email(), length({ max: 250 })]}
            warn={contractWarn}
            label={t({
              id: 'store.form.email.placeholder',
              message: `Email Address`,
            })}
          />

          <Field
            name='phone'
            type='tel'
            testSelector='phone-number'
            component={RenderField}
            validate={[
              required(),
              format({ with: phoneRegex }),
              length({ max: 45 }),
            ]}
            label={t({ id: 'store.form.phone.placeholder', message: `Phone` })}
          />

          <Field
            name='streetAddress'
            type='text'
            testSelector='address'
            component={RenderField}
            validate={length({ max: 255 })}
            label={t({
              id: 'store.form.address.placeholder',
              message: `Address`,
            })}
          />

          <Field
            name='postalCode'
            type='text'
            testSelector='postal-code'
            component={RenderField}
            validate={length({ max: 45 })}
            label={t({
              id: 'store.form.postal.code.placeholder',
              message: `Postal Code`,
            })}
          />

          <Field
            name='city'
            type='text'
            testSelector='city'
            component={RenderField}
            validate={length({ max: 100 })}
            label={t({ id: 'store.form.city.placeholder', message: `City` })}
          />

          <Field
            name='country'
            type='text'
            testSelector='country'
            component={RenderField}
            validate={length({ max: 45 })}
            label={t({
              id: 'store.form.country.placeholder',
              message: `Country`,
            })}
          />

          <Field
            name='retailerStoreSID'
            type='text'
            testSelector='retailerStoreSID'
            component={RenderField}
            validate={[length({ max: 255 })]}
            label={t({
              id: 'store.form.storeRetailerSID.placeholder',
              message: `Partner store ID`,
            })}
          />

          <Heading size='small'>
            <Trans id='store.form.schedule.title'>Schedule</Trans>
          </Heading>

          <Field
            className={'schedule-field'}
            placeholder={schedulePlaceholder.join('\n')}
            name='schedule'
            fieldName={t({
              id: 'store.form.schedule.placeholder',
              message: `Schedule`,
            })}
            component={RenderTextAreaField}
          />

          <Field
            name='status'
            component={Dropdown}
            placeholder={t({
              id: 'store.form.status.placeholder',
              message: `Status`,
            })}
            options={storeOptions()}
            variant='underline'
            labelType='outside'
          />

          <Button
            type='submit'
            disabled={submitting || invalid}
            onClick={handleSubmit(upsertStore)}
          >
            <Trans id='price.form.submit.button'>Submit</Trans>
          </Button>
        </form>
      </Modal>
    );
  }
}

export default connect(
  ({ whitelabel }) => ({
    storeExternalSID: whitelabel.configs.storeExternalSID,
  }),
  { upsertStore: upsertStoreAction },
)(
  reduxForm({
    form: 'admin-store',
    enableReinitialize: true,
  })(StoreForm),
);
