import { t } from '@lingui/macro';

export const upgradeFlow = 'UPGRADE';
export const standardFlow = 'STANDARD';

const categories = () => ({
  name: 'categories',
  title: t({ id: 'title.product.categories', message: `Product Categories` }),
  path: '',
});
const catalog = () => ({
  name: 'catalog',
  title: t({ id: 'title.product.list', message: `Choose your model` }),
  path: 'catalog',
});
const options = () => ({
  name: 'options',
  title: t({ id: 'title.product.options', message: `Product Options` }),
  path: 'options',
});
const extras = () => ({
  name: 'extras',
  title: t({
    id: 'title.product.extras',
    message: `Choose your accessories`,
  }),
  path: 'extras',
});
const customize = () => ({
  name: 'customize',
  title: t({
    id: 'title.product.customize',
    message: `Customise your product`,
  }),
  path: 'customize',
});
const customerInfo = () => ({
  name: 'customerInfo',
  title: t({ id: 'title.customer.info', message: `Customer Info` }),
  path: 'customer',
});
const contractStatus = () => ({
  name: 'contractStatus',
  title: t({ id: 'title.contract.status', message: `Contract Status` }),
  path: 'credit',
});

export const upgrade = {
  get default() {
    return [
      { ...categories() },
      { ...catalog() },
      {
        ...options(),
        nextBtnLabel: t({ id: 'button.goto.extras', message: `Extras` }),
      },
      {
        ...extras(),
        nextBtnLabel: t({
          id: 'button.goto.customer',
          message: `Customer Info`,
        }),
      },
      {
        ...customerInfo(),
        nextBtnLabel: t({
          id: 'button.goto.credit.check',
          message: `Credit Application`,
        }),
      },
      {
        ...contractStatus(),
        nextBtnLabel: t({
          id: 'button.goto.contract.details',
          message: `Contract Details`,
        }),
      },
    ];
  },

  get customizable() {
    return [
      { ...categories() },
      { ...catalog() },
      {
        ...options(),
        nextBtnLabel: t({ id: 'button.goto.customize', message: `Customise` }),
      },
      {
        ...customize(),
        nextBtnLabel: t({ id: 'button.goto.extras', message: `Extras` }),
      },
      {
        ...extras(),
        nextBtnLabel: t({ id: 'button.goto.checkout', message: `Checkout` }),
      },
      {
        ...customerInfo(),
        nextBtnLabel: t({
          id: 'button.goto.credit.check',
          message: `Credit Application`,
        }),
      },
      {
        ...contractStatus(),
        nextBtnLabel: t({
          id: 'button.goto.contract.details',
          message: `Contract Details`,
        }),
      },
    ];
  },
};

export const financing = {
  get default() {
    return [
      { ...categories() },
      {
        ...catalog(),
        nextBtnLabel: t({ id: 'button.goto.options', message: `Options` }),
      },
      {
        ...options(),
        nextBtnLabel: t({ id: 'button.goto.extras', message: `Extras` }),
      },
      {
        ...extras(),
        nextBtnLabel: t({
          id: 'button.goto.add.to.cart',
          message: `Add to Cart`,
        }),
      },
    ];
  },

  get customizable() {
    return [
      { ...categories() },
      {
        ...catalog(),
        nextBtnLabel: t({ id: 'button.goto.options', message: `Options` }),
      },
      {
        ...options(),
        nextBtnLabel: t({ id: 'button.goto.customize', message: `Customise` }),
      },
      {
        ...customize(),
        nextBtnLabel: t({ id: 'button.goto.extras', message: `Extras` }),
      },
      {
        ...extras(),
        nextBtnLabel: t({
          id: 'button.goto.add.to.cart',
          message: `Add to Cart`,
        }),
      },
    ];
  },

  get accessories() {
    return [
      { ...categories() },
      {
        ...catalog(),
        name: 'accessoriesCatalog',
        title: t({
          id: 'title.product.accessory',
          message: `Choose your Accessory`,
        }),
        nextBtnLabel: t({
          id: 'button.goto.add.to.cart',
          message: `Add to Cart`,
        }),
      },
    ];
  },
};

export const checkout = () => [
  {
    ...customerInfo(),
    nextBtnLabel: t({
      id: 'button.goto.credit.check',
      message: `Credit Application`,
    }),
  },
  {
    ...contractStatus(),
    nextBtnLabel: t({
      id: 'button.goto.contract.details',
      message: `Contract Details`,
    }),
  },
];
