import {
  addExtraManuallyAction,
  getPricedExtrasAction,
  removeExtraAction,
} from 'actions/carts';
import classNames from 'classnames';
import trackPage from 'enhancers/trackPage';
import { currencyFormatter } from 'helpers/formatters';
import { format, numericality, required } from 'helpers/validators';
import { Field, reduxForm } from 'redux-form';

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import Loader from 'components/Loader';
import RenderField from 'components/RenderField';

import { currencyRegex } from 'constants/forms';

class ProductExtrasManual extends Component {
  addExtra = (values) => {
    const { addExtraManually, reset, isCTO = false, cart } = this.props;

    addExtraManually(cart.uuid, values, isCTO);
    reset();
  };

  removeExtra = (cartUUID, extraUUID) => {
    const { deviceCode, getPricedExtras, removeExtra } = this.props;

    return removeExtra(cartUUID, extraUUID, () =>
      getPricedExtras(cartUUID, deviceCode),
    );
  };

  render() {
    const {
      cart,
      classes,
      currency,
      current,
      handleSubmit,
      invalid,
      isCTO = false,
      language,
      pristine,
      submitting,
    } = this.props;
    const { items } = current
      ? cart?.current_order?.extras || { items: [] }
      : cart?.orders[0]?.extras || { items: [] };
    const filtered = items.filter((item) => item.isCTO === isCTO);

    if (!cart?.current_order?.extras) return <Loader />;
    // Removed temporarily for TV2-991, needs to be added back with TV-909
    // const { max_extras } = cart.current_order.extras;
    return (
      <Fragment>
        <form className='extra-form' onSubmit={handleSubmit(this.addExtra)}>
          {!isCTO && (
            <div>
              <Field
                name='category'
                component={Dropdown}
                placeholder={t({
                  id: 'extras.form.category.placeholder',
                  message: 'Category',
                })}
                clearable
                options={[
                  {
                    value: 'Installation',
                    label: t({
                      id: 'extras.form.option.installation',
                      message: 'Installation',
                    }),
                  },
                  {
                    value: 'Service',
                    label: t({
                      id: 'extras.form.option.service',
                      message: 'Service',
                    }),
                  },
                  {
                    value: 'Software',
                    label: t({
                      id: 'extras.form.option.software',
                      message: 'Software',
                    }),
                  },
                  {
                    value: 'Accessories',
                    label: t({
                      id: 'extras.form.option.accessories',
                      message: 'Accessories',
                    }),
                  },
                ]}
                labelType='outside'
                variant='underline'
                testSelector='manual-extra-category'
              />
            </div>
          )}
          <Field
            name='description'
            type='text'
            component={RenderField}
            label={t({
              id: 'extras.form.description.placeholder',
              message: `Description`,
            })}
            validate={required()}
            testSelector='manual-extra-description'
          />

          <Field
            name='price'
            type='text'
            component={RenderField}
            validate={[
              required(),
              numericality({ /*'<': max_extras,*/ greaterThan: 0 }),
              format({ with: currencyRegex }),
            ]}
            label={t({ id: 'extras.form.price.placeholder', message: `Price` })}
            testSelector='manual-extra-price'
          />

          <Button
            type='submit'
            disabled={pristine || submitting || invalid}
            testSelector='add-manual-extra-button'
          >
            <Trans id='extras.form.submit.button'>Add</Trans>
          </Button>
        </form>

        <ul className={classNames('extras-list', classes)}>
          {filtered.map(({ description, isManual, uuid, value, locked }) => (
            <li key={uuid} className='extra-item'>
              {isManual && (
                <Fragment>
                  <div className='extra-item-description'>
                    <span>{description}</span>
                    <span>{`${currencyFormatter(
                      value,
                      currency,
                      language,
                    )}`}</span>
                  </div>

                  <Button
                    // TODO make this component reliant only on class functions
                    // in order to remove arrow functions and enforce this rule
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={() => this.removeExtra(cart.uuid, uuid)}
                    classes='red small'
                    disabled={locked}
                    testSelector='remove-extra-button'
                  >
                    <Trans id='extras.form.remove.button'>Remove</Trans>
                  </Button>
                </Fragment>
              )}
            </li>
          ))}
        </ul>
      </Fragment>
    );
  }
}

ProductExtrasManual.defaultProps = {
  classes: '',
};

export default reduxForm({
  form: 'extra-form',
  destroyOnUnmount: true,
})(
  connect(
    ({ whitelabel }, { cart }) => ({
      canAddExtras: whitelabel.configs.canAddExtras,
      currency: whitelabel.currency,
      language: whitelabel.language,
      financingType: cart.financingType,
    }),
    {
      addExtraManually: addExtraManuallyAction,
      removeExtra: removeExtraAction,
      getPricedExtras: getPricedExtrasAction,
    },
  )(trackPage(ProductExtrasManual, 'ProductExtrasManual')),
);
