import React from 'react';

import WLIcon from './WLIcon';

const UserErrors = ({ items, className }) => (
  <table className={`StatsTable ${className}`}>
    <colgroup>
      <col width='50px;' />
      <col width='30px;' />
      <col width='140px;' />
      <col width='120px;' />
      <col width='120px;' />
      <col width='60px;' />
      <col width='100px;' />
    </colgroup>
    <tbody>
      <tr>
        <th colSpan='7'>
          <span className='StatsTable_caption'>Front End Errors</span>
        </th>
      </tr>

      <tr className='StatsTable_innerHeader'>
        <td>Time</td>
        <td colSpan='2'>Store</td>
        <td>Cart</td>
        <td>Page</td>
        <td colSpan='2'>Error</td>
      </tr>

      {items.map((item, index) => (
        <tr key={index}>
          <td>{item.time}</td>
          <td colSpan='2'>
            <WLIcon item={item} index={index} /> {item.storeName}
          </td>
          <td>{item.cartID}</td>
          <td>{item.page}</td>
          <td colSpan='2'>{item.error}</td>
        </tr>
      ))}

      {items.length === 0 && (
        <tr>
          <td colSpan='7' className='StatsTable_none'>
            No Errors
          </td>
        </tr>
      )}
    </tbody>
  </table>
);

export default UserErrors;
