import { cancelOrderAction } from 'actions/sales';
import { openModalAction } from 'actions/ui';

import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { Trans } from '@lingui/macro';

import Button from 'components/Button';
import Heading from 'components/Heading';
import Modal from 'components/Modal';

const CancelOrder = ({ cancelOrder, openModal, uuid }) => {
  const handleCancelOrder = async () => {
    await cancelOrder(uuid);
  };

  const openCancelModal = () => openModal(`cancelContract-${uuid}`);

  return (
    <Fragment>
      <Button
        onClick={openCancelModal}
        classes='contract-actions--right red'
        testSelector='contract-action-cancel-modal'
      >
        <Trans id='sale.cancel.contract.button'>Cancel Contract</Trans>
      </Button>

      <Modal name={`cancelContract-${uuid}`} onConfirm={handleCancelOrder}>
        <Heading size='medium'>
          <Trans id='order.actions.cancel.title'>Cancel Contract</Trans>
          {` ${uuid}`}
        </Heading>

        <p>
          <Trans id='order.actions.cancel.question'>
            Are you sure you want to proceed with the cancellation of this
            contract?
          </Trans>
        </p>
      </Modal>
    </Fragment>
  );
};

export default connect(null, {
  cancelOrder: cancelOrderAction,
  openModal: openModalAction,
})(CancelOrder);
