import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import React from 'react';

import { t } from '@lingui/macro';

import Heading from 'components/Heading';

import './Criteria.styl';
import Criterion from './Criterion';

const Criteria = ({ data = [], title, showHelp = false }) => {
  const hasQuestions = () => data.some((questions) => !isEmpty(questions.list));

  return (
    hasQuestions() && (
      <div className='criteria'>
        <Heading size='small'>
          {title ??
            t({
              id: 'evaluation.device.condition.title',
              message: `Device Condition`,
            })}
        </Heading>

        <ul
          className='criteria-list'
          id='criteria-list'
          data-test='criteria-questions'
        >
          {data.map((questions) =>
            questions.answers.map((answer) => (
              <li
                key={`item-${questions.name}-${answer.code}`}
                className={classNames({
                  'criteria-item--with-help': showHelp,
                })}
              >
                {!isEmpty(answer) && (
                  <Criterion
                    name={questions.name}
                    data={answer}
                    handleClick={questions.answerHandler}
                    showHelp={showHelp}
                  />
                )}
              </li>
            )),
          )}
        </ul>
      </div>
    )
  );
};

export default Criteria;
