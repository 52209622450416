import {
  createCampaignAction,
  getCampaignAction,
  getCampaignFormDataAction,
  updateCampaignAction,
} from 'actions/admin/campaign';
import { date, numericality, required } from 'helpers/validators';
import { getFormValues } from 'redux-form';

import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';

import ButtonForm from 'components/ButtonForm';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import RenderDatePicker from 'components/RenderDatePicker';

import { RenderToggle } from './Toggle';

const CampaignForm = ({
  campaign,
  update,
  triggerEvents,
  salesChannels,
  languages,
  marketingOptIns,
  getCampaignFormData,
  getCampaign,
  createCampaign,
  updateCampaign,
  onClose = () => {},
  onOpen = () => {},
  formValues,
  campaignCode,
  toggleShowForm,
  initialValues,
}) => {
  useEffect(() => {
    if (campaignCode) {
      getCampaign(campaignCode);
    }
  }, [campaignCode, getCampaign]);

  /* Removed input fields for KRCS. Re-add them when necessary
      {
      component: Dropdown,
      placeholder:
        t({id: 'forms.campaign.placeholder.communication.type', message: `Communication Type`}),
      options: crmCommsCategories,
      name: 'commsType',
      size: 'medium',
      variant: 'underline',
      validate: [required()],
    },
      {
      component: Input,
      placeholder:
        t({id: 'forms.campaign.placeholder.initial.stage', message: `Initial Stage`}),
      name: 'initialStage',
      size: 'medium',
      variant: 'underline',
      validate: [
        required({
          if: values => values.commsType === 'OPERATIONAL',
        }),
      ],
    },
  */

  const onCloseForm = useCallback(() => {
    onClose();
    document
      .getElementsByClassName('header-inner-container')[0]
      .scrollIntoView({
        block: 'start',
        behavior: 'smooth',
        inline: 'start',
      });
  }, [onClose]);

  const fields = [
    {
      component: Input,
      placeholder: t({
        id: 'forms.campaign.placeholder.campaign.id',
        message: `Campaign ID`,
      }),
      name: 'campaignCode',
      size: 'medium',
      variant: 'underline',
      validate: [required()],
      disabled: update,
    },
    {
      component: Input,
      placeholder: t({
        id: 'forms.campaign.placeholder.description',
        message: `Description`,
      }),
      name: 'description',
      size: 'medium',
      variant: 'underline',
      validate: [required()],
    },
    {
      component: Dropdown,
      clearable: true,
      placeholder: t({
        id: 'forms.campaign.placeholder.status',
        message: `Status`,
      }),
      options: [
        { label: 'Enabled', value: 'ENABLED' },
        { label: 'Disabled', value: 'DISABLED' },
      ],
      name: 'status',
      size: 'medium',
      testSelector: 'addCampaignStatusDropdown',
      variant: 'underline',
    },
    {
      component: RenderDatePicker,
      label: t({
        id: 'forms.campaign.placeholder.start.date',
        message: `Start Date`,
      }),
      name: 'startDate',
      validate: [
        required(),
        date({ '<': new Date(formValues?.endDate) || '' }),
      ],
      maxDate: formValues?.endDate,
    },
    {
      component: RenderDatePicker,
      label: t({
        id: 'forms.campaign.placeholder.end.date',
        message: `End Date`,
      }),
      name: 'endDate',
      validate: [
        date({ allowBlank: true, '>': new Date(formValues?.startDate) || '' }),
      ],
      minDate: formValues?.startDate,
    },
    {
      component: Dropdown,
      clearable: true,
      options: salesChannels,
      placeholder: t({
        id: 'forms.campaign.placeholder.category.channel',
        message: `Sales Channel`,
      }),
      name: 'channel',
      size: 'medium',
      testSelector: 'addCampaignSalesChannelDropdown',
      variant: 'underline',
    },
    {
      component: Dropdown,
      clearable: true,
      placeholder: t({
        id: 'forms.campaign.placeholder.marketing',
        message: `Marketing Opt In`,
      }),
      options: marketingOptIns,
      name: 'marketing',
      helpText: t({
        id: 'forms.cohort.marketing.text',
        message: `Select what contracts should belong to this cohort rule regarding their marketing option, defined upon sale`,
      }),
      size: 'medium',
      testSelector: 'addCampaignMarketingOptInDropdown',
      variant: 'underline',
    },
    {
      component: Dropdown,
      clearable: true,
      options: triggerEvents,
      placeholder: t({
        id: 'forms.campaign.placeholder.trigger.event',
        message: `Trigger Event`,
      }),
      name: 'triggerEvent',
      helpText: t({
        id: 'forms.cohort.trigger.event.text',
        message: `The contract date type to filter the contracts`,
      }),
      size: 'medium',
      testSelector: 'addCampaignTriggerEventDropdown',
      variant: 'underline',
    },
    {
      component: RenderDatePicker,
      label: t({
        id: 'forms.campaign.placeholder.trigger.start.date',
        message: `Trigger Start Date`,
      }),
      name: 'triggerDateRangeStart',
      helpText: t({
        id: 'forms.cohort.trigger.start.date.text',
        message: `Start date filter relative to the trigger event`,
      }),
      validate: [
        date({
          allowBlank: true,
          '<': new Date(formValues?.triggerDateRangeEnd) || '',
        }),
      ],
      maxDate: formValues?.triggerDateRangeStart,
    },
    {
      component: RenderDatePicker,
      label: t({
        id: 'forms.campaign.placeholder.trigger.end.date',
        message: `Trigger End Date`,
      }),
      name: 'triggerDateRangeEnd',
      helpText: t({
        id: 'forms.campaign.trigger.end.date.text',
        message: `End date filter relative to the trigger event`,
      }),
      validate: [
        date({
          allowBlank: true,
          '>': new Date(formValues?.triggerDateRangeStart) || '',
        }),
      ],
      minDate: formValues?.triggerDateRangeEnd,
    },
    {
      component: Input,
      placeholder: t({
        id: 'forms.cohort.placeholder.trigger.days.offset',
        message: `Trigger Days Offset`,
      }),
      name: 'triggerDaysOffset',
      helpText: t({
        id: 'forms.cohort.trigger.offset.text',
        message: `The number of days counting from today until the trigger event`,
      }),
      size: 'medium',
      variant: 'underline',
      validate: [numericality({ allowBlank: true })],
    },
    {
      component: Input,
      placeholder: t({
        id: 'forms.campaign.placeholder.end.stage.success',
        message: `End Stage Success`,
      }),
      name: 'endStageSuccess',
      size: 'medium',
      variant: 'underline',
    },
    {
      component: Input,
      placeholder: t({
        id: 'forms.campaign.placeholder.end.stage.fail',
        message: `End Stage Fail`,
      }),
      name: 'endStageFail',
      size: 'medium',
      variant: 'underline',
    },
    {
      component: Input,
      placeholder: t({
        id: 'forms.campaign.placeholder.email.send.from',
        message: `Send From`,
      }),
      name: 'emailFrom',
      validate: [required()],
      size: 'medium',
      variant: 'underline',
      helpText: t({
        id: 'forms.campaign.email.from.text',
        message: `Email account from where the email communications are sent to the customer`,
      }),
    },
    {
      component: Input,
      placeholder: t({
        id: 'forms.campaign.placeholder.email.reply.to',
        message: `Reply to`,
      }),
      name: 'replyTo',
      size: 'medium',
      variant: 'underline',
      helpText: t({
        id: 'forms.campaign.email.to.text',
        message: `Email account that will receive the customer replies to the email communications`,
      }),
    },
    {
      component: Input,
      placeholder: t({
        id: 'forms.campaign.placeholder.sms.subject',
        message: `SMS Subject`,
      }),
      name: 'smsSubject',
      validate: [required()],
      size: 'medium',
      variant: 'underline',
    },
    {
      component: Dropdown,
      clearable: true,
      options: languages,
      placeholder: t({
        id: 'forms.campaign.placeholder.language',
        message: `Target language`,
      }),
      name: 'language',
      helpText: t({
        id: 'forms.campaign.trigger.language.text',
        message: `Target language to filter the contracts`,
      }),
      size: 'medium',
      testSelector: 'addCampaignLanguageDropdown',
      variant: 'underline',
    },
    {
      component: RenderToggle,
      placeholder: t({
        id: 'forms.campaign.placeholder.add.tracking.pixel',
        message: `Automatically track emails in this campaign`,
      }),
      name: 'addTrackingPixel',
    },
  ];

  return update ? (
    <ButtonForm
      name='campaignForm'
      getFormData={getCampaignFormData}
      fields={fields}
      title={<Trans id='campaign.form.edit.title'>Edit Campaign</Trans>}
      openBtnLabel={
        <Trans id='admin.campaign.edit.button'>Edit Campaign</Trans>
      }
      handleSubmit={updateCampaign}
      onOpen={onOpen}
      onClose={onCloseForm}
      values={campaignCode ? campaign : {}}
    />
  ) : (
    <ButtonForm
      name='campaignForm'
      getFormData={getCampaignFormData}
      fields={fields}
      title={<Trans id='campaign.form.add.title'>Add Campaign</Trans>}
      openBtnLabel={<Trans id='admin.campaing.add.button'>Add Campaign</Trans>}
      handleSubmit={createCampaign}
      onOpen={onOpen}
      onClose={onCloseForm}
      values={initialValues}
      toggleShowForm={toggleShowForm}
    />
  );
};

export default connect(
  (state) => ({
    formValues: getFormValues('campaignForm')(state),
    campaign: state.adminCampaign?.campaign,
    triggerEvents: state.adminCampaign.triggerEvents,
    communicationsTypes: state.adminCampaign.communicationsTypes,
    crmCommsCategories: state.adminCampaign.crmCommsCategories,
    contractStatuses: state.adminCampaign.contractStatuses,
    marketingOptIns: state.adminCampaign.marketingOptIns,
    salesChannels: state.adminCampaign.salesChannels,
    languages: state.adminCampaign.languages,
  }),
  {
    getCampaign: getCampaignAction,
    getCampaignFormData: getCampaignFormDataAction,
    createCampaign: createCampaignAction,
    updateCampaign: updateCampaignAction,
  },
)(CampaignForm);
