import { hideLoaderAction } from 'actions/ui';

export const CLEAN_STORE = 'CLEAN_STORE';
export function cleanStoreAction() {
  return async function (dispatch) {
    dispatch({ type: CLEAN_STORE });
    dispatch(hideLoaderAction());
  };
}

export const UPDATE_BUILD_HASH = 'UPDATE_BUILD_HASH';
export function updateBuildHashAction(buildHash) {
  return function (dispatch) {
    dispatch({ type: UPDATE_BUILD_HASH, buildHash });
  };
}
