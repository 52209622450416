import closedEye from 'assets/images/closed_eye.svg';
import openEye from 'assets/images/open_eye.svg';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import React, { Component } from 'react';

class PasswordInput extends Component {
  state = { showText: false };

  toggleIcon = () =>
    this.setState((prevState) => ({ showText: !prevState.showText }));

  render() {
    const {
      type,
      label,
      input,
      animateLabel = true,
      meta: { touched, error, warning, active },
      testSelector,
    } = this.props;
    const { showText } = this.state;

    return (
      <div className='input-container'>
        {animateLabel && (
          <label
            className={classNames('input__floating-label', {
              active: input.value.length > 0 || active,
            })}
          >
            {label}
          </label>
        )}

        <input
          {...input}
          placeholder={!animateLabel ? label : undefined}
          type={showText ? 'text' : type}
          className='form'
          data-test={testSelector}
        />

        <figure
          className='password-mask'
          role='button'
          onClick={this.toggleIcon}
        >
          {showText ? (
            <img src={openEye} alt='Show password' />
          ) : (
            <img src={closedEye} alt='Hide password' />
          )}
        </figure>
        {(touched && error && <p className='error'>{error}</p>) ||
          (warning && <p className='warning'>{warning}</p>)}
      </div>
    );
  }
}

PasswordInput.defaultProps = {
  type: 'text',
  label: '',
};

PasswordInput.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
};

export default PasswordInput;
