import * as React from 'react';

function SvgIconRotate90({ title, titleId, ...props }) {
  return (
    <svg width='1em' height='1em' aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <clipPath id='icon-rotate90_svg__a'>
          <path d='M0 0h36v36H0z' />
        </clipPath>
      </defs>
      <g clipPath='url(#icon-rotate90_svg__a)'>
        <path fill='none' d='M0 0h36v36H0z' />
        <g data-name='Icon / Rotate CW / Ghost'>
          <g data-name='Group 662'>
            <path
              d='M19.522 11.208l-3.794-3.794L17.142 6l6.364 6.364-6.364 6.364-1.414-1.414 4.053-4.054c-.898-.117-2.216-.295-2.843-.324a7.242 7.242 0 00-5.806 1.903c-3.61 3.61-2.9 7.707.353 10.96l-1.414 1.414c-3.951-3.951-4.872-9.27-.353-13.789a9.161 9.161 0 017.312-2.486c.623.03 1.678.162 2.492.27z'
              fill='#333'
              data-name='24 / arrows / arrow-redo'
            />
            <path
              data-name='Path 155'
              d='M20.361 24.108a6.371 6.371 0 01-1.191 4.221 4.483 4.483 0 01-3.606 1.394 6.771 6.771 0 01-1.289-.092v-1.622a4.733 4.733 0 001.152.137 4.045 4.045 0 001.669-.3 2.105 2.105 0 001-.936 4.122 4.122 0 00.4-1.757h-.076a2.237 2.237 0 01-.877.864 2.768 2.768 0 01-1.243.249 2.513 2.513 0 01-1.97-.8 3.217 3.217 0 01-.72-2.228 3.328 3.328 0 01.87-2.432 3.175 3.175 0 012.379-.893 3.425 3.425 0 011.855.5 3.193 3.193 0 011.221 1.441 5.464 5.464 0 01.426 2.254zm-3.462-2.572a1.2 1.2 0 00-.982.432 1.919 1.919 0 00-.353 1.243 1.726 1.726 0 00.321 1.1 1.167 1.167 0 00.975.406 1.5 1.5 0 001.054-.4 1.226 1.226 0 00.438-.926 2.106 2.106 0 00-.41-1.316 1.259 1.259 0 00-1.043-.539zm11.1 3.272a6.669 6.669 0 01-.821 3.711 2.85 2.85 0 01-2.529 1.2 2.817 2.817 0 01-2.498-1.244 6.575 6.575 0 01-.841-3.667 6.733 6.733 0 01.818-3.733 2.838 2.838 0 012.52-1.2 2.824 2.824 0 012.5 1.257 6.588 6.588 0 01.85 3.676zm-4.679 0a7.444 7.444 0 00.3 2.523 1.054 1.054 0 001.024.762 1.065 1.065 0 001.021-.772 7.238 7.238 0 00.314-2.513 7.217 7.217 0 00-.314-2.533 1.066 1.066 0 00-1.018-.769 1.057 1.057 0 00-1.021.769 7.431 7.431 0 00-.306 2.533zm5.759-2.657a2.221 2.221 0 01.3-1.126 2.249 2.249 0 01.825-.831 2.208 2.208 0 011.139-.308 2.239 2.239 0 011.129.3 2.2 2.2 0 01.831.831 2.238 2.238 0 01.3 1.132 2.232 2.232 0 01-.3 1.135 2.169 2.169 0 01-.828.821 2.271 2.271 0 01-1.132.295 2.2 2.2 0 01-1.607-.651 2.164 2.164 0 01-.658-1.598zm1.25 0a.977.977 0 00.291.713.976.976 0 00.723.295.976.976 0 00.726-.3.978.978 0 00.295-.707 1 1 0 00-.3-.72.969.969 0 00-.723-.308.972.972 0 00-.717.3 1 1 0 00-.296.727z'
              fill='#313131'
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgIconRotate90;
