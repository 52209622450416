import { hideLoaderAction, showLoaderAction } from 'actions/ui';
import { posalePost as post } from 'apis/v2';

export const GET_RETURNED_DEVICES = 'GET_RETURNED_DEVICES';
export function getReturnedDevicesAction(data, showCollected) {
  return async function (dispatch, getState) {
    const imei = getState().form?.searchForm?.values?.searchText || '';

    const response = await post(GET_RETURNED_DEVICES, {
      ...data,
      imei,
      showCollected,
    });

    if (
      response?.data?.status !== 'ERROR' &&
      response.data.data.returned_devices
    ) {
      dispatch({
        type: GET_RETURNED_DEVICES,
        data: response.data.data.returned_devices,
      });
    }

    dispatch(hideLoaderAction());
  };
}

export const GET_COLLECTION_FORM_INFO = 'GET_COLLECTION_FORM_INFO';
export function getCollectionFormInfoAction(storeID) {
  return async function (dispatch) {
    const response = await post(GET_COLLECTION_FORM_INFO, {
      storeID,
    });

    dispatch({
      type: GET_COLLECTION_FORM_INFO,
      data: response.data.data.address,
    });
  };
}

export const SET_COLLECTION_DEVICES = 'SET_COLLECTION_DEVICES';
export function setCollectionDevicesAction(values) {
  return function (dispatch) {
    dispatch({
      type: SET_COLLECTION_DEVICES,
      data: values,
    });
  };
}

export const CREATE_NEW_COLLECTION = 'CREATE_NEW_COLLECTION';
export function upsertCollectionsAction({
  totalWeight,
  boxCount,
  collector,
  collectionTimeSlotCode,
  collectionDate,
  ...address
}) {
  return async function (dispatch, getState) {
    dispatch(showLoaderAction());

    const response = await post(CREATE_NEW_COLLECTION, {
      collector,
      address,
      collectionTimeSlotCode,
      collectionDate,
      boxCount,
      totalWeight,
      selectedDevices: getState().adminCollection.collectionDevices.map(
        (device) => device.id,
      ),
    });

    dispatch(hideLoaderAction());

    if (response.data.status === 'SUCCESS') {
      const url = response.data.data.courierUrl;

      dispatch({ type: CREATE_NEW_COLLECTION, data: url });

      return { url, status: response.data.status };
    }

    return { status: response.data.status };
  };
}

export const GET_COLLECTIONS = 'GET_COLLECTIONS';
export function getCollectionsAction(data = {}) {
  return async function (dispatch) {
    dispatch(showLoaderAction());

    const response = await post(GET_COLLECTIONS, data);

    dispatch({
      type: GET_COLLECTIONS,
      data: response.data.data.collections,
    });

    dispatch(hideLoaderAction());
  };
}

export const GET_COLLECTION_DETAILS = 'GET_COLLECTION_DETAILS';
export function getCollectionDetailsAction(collectionUUID) {
  return async function (dispatch) {
    dispatch(showLoaderAction());

    const response = await post(GET_COLLECTION_DETAILS, {
      collectionUUID,
    });

    dispatch({
      type: GET_COLLECTION_DETAILS,
      data: response.data.data,
    });

    dispatch(hideLoaderAction());
  };
}

export const GET_COLLECTORS = 'GET_COLLECTORS';
export function getCollectorsAction() {
  return async function (dispatch) {
    const response = await post(GET_COLLECTORS, {});

    const { collectors } = response.data.data;

    if (response.data.status !== 'ERROR') {
      dispatch({
        type: GET_COLLECTORS,
        data: collectors.reduce((acc, collector) => {
          acc[collector] = collector;
          return acc;
        }, {}),
      });
    }
  };
}

export const GET_COLLECTORS_RULES = 'GET_COLLECTORS_RULES';
export function getCollectorsRulesAction() {
  return async function (dispatch) {
    const response = await post(GET_COLLECTORS_RULES, {});

    if (response.data.status !== 'ERROR') {
      const collectorRules = response.data.data.rules.reduce((acc, rule) => {
        acc[rule.collector] = {
          devicesLimit: rule.collectionFreeMinItems,
          value: rule.collectionFee,
        };
        return acc;
      }, {});

      dispatch({ type: GET_COLLECTORS_RULES, data: collectorRules });
    }
  };
}
