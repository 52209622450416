import { deleteOffer } from 'actions/admin/campaign';
import { openModalAction } from 'actions/ui';

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import Modal from 'components/Modal';
import Table from 'components/table/Table';

export const Offers = ({ selectOffer, offers = [] }) => {
  const dispatch = useDispatch();
  const [offer, setOffer] = useState(null);

  const performDeleteOffer = async () => {
    await dispatch(deleteOffer(offer));
  };

  const columns = [
    {
      label: t({ id: 'table.column.offer.group', message: `Offer Group` }),
      accessor: 'offerGroup',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.offer.order', message: `Order` }),
      accessor: 'sequenceNum',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.offer.manufacturer',
        message: `Manufacturer`,
      }),
      accessor: 'manufacturerName',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.offer.category', message: `Category` }),
      accessor: 'categoryName',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.offer.family', message: `Family` }),
      accessor: 'familyName',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.offer.device', message: `Device` }),
      accessor: 'deviceName',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.offer.type', message: `Type` }),
      accessor: 'type',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.offer.image', message: `Image` }),
      accessor: 'imgURL',
      type: 'image',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.offer.external.url',
        message: `External URL`,
      }),
      accessor: 'url',
      type: 'link',
      filterable: false,
      sortable: true,
      align: 'left',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.campaign.actions', message: `Actions` }),
      accessor: 'actions',
      type: 'actions',
      filterable: false,
      sortable: false,
      align: 'right',
      wrap: false,
    },
  ];

  const actions = [
    {
      label: t({ id: 'table.column.actions.edit', message: `Edit` }),
      handler: (_, { actions, ...values }) => {
        selectOffer(values);

        document.getElementById('offerForm').scrollIntoView({
          block: 'start',
          behavior: 'smooth',
          inline: 'start',
        });
      },
    },
    {
      label: t({ id: 'table.column.actions.delete', message: `Delete` }),
      handler: (_, { id, campaignID }) => {
        setOffer({ id, campaignID });
        dispatch(openModalAction('admin-offer-delete'));
      },
    },
  ];

  const rows = offers?.map((offer) => ({ ...offer, actions }));

  return (
    <>
      <Table
        columns={columns}
        data={rows}
        keyColumn='order'
        filterable={false}
      />
      <Modal name='admin-offer-delete' onConfirm={performDeleteOffer}>
        <Trans id='blocks.delete.offer.confirmation'>
          Are you sure you want to delete this offer ?
        </Trans>
      </Modal>
    </>
  );
};

export default Offers;
