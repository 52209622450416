export const SAVE_SOCKET_STATUS = 'SAVE_SOCKET_STATUS';
export function saveSocketStatus(status) {
  return async function (dispatch) {
    dispatch({ type: SAVE_SOCKET_STATUS, status });
  };
}

export const SAVE_SOCKET_MESSAGE = 'SAVE_SOCKET_MESSAGE';
export function saveSocketMessage(message) {
  return async function (dispatch) {
    let data;

    if (message.messageName === 'ServiceMonitorData') {
      data = {
        services: message.services,
        database: message.database,
        jvmDetails: message.jvmDetails,
        threadPoolMonitorData: message.threadPoolMonitorData,
      };
    }
    dispatch({ type: SAVE_SOCKET_MESSAGE, data });
  };
}

export const SAVE_SOCKET_CONFIG = 'SAVE_SOCKET_CONFIG';
export function saveSocketConfig(uid, date) {
  return async function (dispatch) {
    dispatch({ type: SAVE_SOCKET_CONFIG, uid, date });
  };
}

export const CLEAR_DASHBOARD_DATA = 'CLEAR_DASHBOARD_DATA';
export function clearDashboardData() {
  return async function (dispatch) {
    dispatch({ type: CLEAR_DASHBOARD_DATA });
  };
}
