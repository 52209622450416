import React, { Fragment } from 'react';

import { t } from '@lingui/macro';

import Table from 'components/table/Table';

export const SaleActions = ({ saleActions = [] }) => {
  const columns = [
    {
      label: t({
        id: 'table.column.sale.actions.date',
        message: `Change date`,
      }),
      accessor: 'actionDateTime',
      type: 'dateTime',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.sale.actions.user', message: `User` }),
      accessor: 'userName',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'left',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.sale.actions.action', message: `Action` }),
      accessor: 'actionType',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'left',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.sale.actions.data', message: `Data` }),
      accessor: 'actionContent',
      type: 'html',
      filterable: false,
      sortable: true,
      align: 'left',
      wrap: true,
    },
  ];

  return (
    <Fragment>
      <Table
        id='actions'
        columns={columns}
        classes='sale-actions__table'
        data={saleActions}
        keyColumn='id'
        defaultOrder='actionDateTime'
        defaultDir='desc'
        filterable={false}
        testSelector='posale-contract'
      />
    </Fragment>
  );
};

export default SaleActions;
