import classNames from 'classnames';
import PropTypes from 'prop-types';

import React from 'react';

import './Heading.styl';

const Heading = ({
  children,
  classes = '',
  dataTest = 'heading',
  size = 'big',
  align = 'left',
}) => {
  const sizes = {
    big: 'h1',
    medium: 'h2',
    small: 'h3',
  };

  const HeadingTag = `${sizes[size] || 'h1'}`;

  return (
    <HeadingTag
      className={classNames('heading', align, classes)}
      data-test={dataTest}
    >
      {children}
    </HeadingTag>
  );
};

Heading.propTypes = {
  align: PropTypes.string,
  children: PropTypes.node.isRequired,
  classes: PropTypes.string,
  dataTest: PropTypes.string,
  size: PropTypes.string,
};

export default Heading;
