import classNames from 'classnames';
import { CellFormatter } from 'helpers/tableFormatters';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { textToKebab } from 'utils';

import React from 'react';
import { useSelector } from 'react-redux';

import LineItem from 'components/LineItem';

import './RenderDetails.styl';

const sizes = {
  small: 'details--small',
  medium: 'details--medium',
};
export const RenderDetails = ({
  classes = '',
  size = 'medium',
  config,
  data,
  imgURL,
  name,
  showLabel,
  title,
  testSelector = '',
}) => {
  const CreateRow = ({ data, name, row, showLabel }) => {
    const currency = useSelector(({ whitelabel }) => whitelabel.currency);
    const language = useSelector(({ whitelabel }) => whitelabel.language);

    if (get(data, row.accessor) === undefined || get(data, row.accessor) === '')
      return null;

    return (
      <LineItem
        classes={row.classes}
        label={row.label}
        key={`${name}-${row.accessor}`}
        showLabel={showLabel}
        value={CellFormatter(data, row, { currency, language })}
        testSelector={`${testSelector}${textToKebab(
          row.accessor || row.label,
        )}`}
      />
    );
  };

  return (
    <div className='details-wrapper'>
      {!isEmpty(data) && title && <h3 className='details-title'>{title}</h3>}

      <div
        className={classNames('details', classes, sizes[size])}
        key={`details-${name}`}
      >
        {imgURL && <img src={imgURL} alt='' />}

        <div className='detail-list'>
          {config
            ? config.map((row) => (
                <CreateRow
                  data={data}
                  name={name}
                  row={row}
                  showLabel={showLabel}
                />
              ))
            : Object.entries(data).map(
                ([label, value]) =>
                  !isEmpty(value) && (
                    <LineItem
                      key={label}
                      label={label}
                      showLabel={showLabel}
                      value={value}
                      testSelector={`${testSelector}${textToKebab(label)}`}
                    />
                  ),
              )}
        </div>
      </div>
    </div>
  );
};

export default RenderDetails;
