import * as React from 'react';

function SvgIconUnlocked({ title, titleId, ...props }) {
  return (
    <svg
      viewBox='0 0 24 24'
      width='1em'
      height='1em'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <clipPath id='icon-unlocked_svg__b'>
          <path fill='none' d='M0 0h12.182v16H0z' />
        </clipPath>
        <clipPath id='icon-unlocked_svg__a'>
          <path d='M0 0h24v24H0z' />
        </clipPath>
      </defs>
      <g clipPath='url(#icon-unlocked_svg__a)'>
        <g clipPath='url(#icon-unlocked_svg__b)' transform='translate(6 3)'>
          <path
            fill='currentColor'
            d='M1.523 16A1.492 1.492 0 010 14.546V8.727a1.492 1.492 0 011.523-1.454h.761V3.637A3.725 3.725 0 016.091 0 3.724 3.724 0 019.9 3.637v1.454H8.375V3.637a2.235 2.235 0 00-2.284-2.183 2.235 2.235 0 00-2.284 2.183v3.636h6.853a1.492 1.492 0 011.523 1.454v5.818A1.492 1.492 0 0110.66 16zm0-1.454h9.137V8.727H1.523zm3.807-2.909a.762.762 0 11.761.727.744.744 0 01-.761-.727z'
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconUnlocked;
