import { savePreviousLocationAction } from 'actions/ui';

import { useEffect, useRef } from 'react';

const PrevLocationUpdater = ({ location, store }) => {
  const prevLocation = useRef();

  useEffect(() => {
    if (location.pathname !== prevLocation.current) {
      store.dispatch(savePreviousLocationAction(location.pathname));
    }
  }, [location.pathname, store]);

  return null;
};

export default PrevLocationUpdater;
