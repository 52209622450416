import { currencyFormatter } from 'helpers/formatters';
import { currencySymbolToISO3 } from 'utils';

import React from 'react';

import { Trans, t } from '@lingui/macro';

import Dropdown from 'components/Dropdown';
import Heading from 'components/Heading';

export const PromotionsDropdown = ({
  disabled,
  handleSelect,
  promotionID = 0,
  promotions,
  language,
  currency,
}) => {
  return (
    <div className='promotions'>
      <Heading size='small'>
        <Trans id='device.promotions.title'>Promotions</Trans>
      </Heading>
      <Dropdown
        disabled={disabled}
        name='devicePromotion'
        placeholder={t({
          id: 'device.promotions.placeholder',
          message: `Select promotion`,
        })}
        variant='underline'
        options={[
          {
            value: 0,
            label: t({
              id: 'device.no.promotion.option',
              message: `No promotion`,
            }),
          },
          ...promotions.map((promotion) => {
            return {
              value: promotion.id,
              label: t({
                id: 'device.promotion.label',
                message: `${currencyFormatter(
                  promotion.value,
                  currencySymbolToISO3(currency),
                  language,
                )} discount for ${promotion.title}`,
              }),
            };
          }),
        ]}
        input={{
          onChange: handleSelect,
          onFocus: () => {},
          onBlur: () => {},
          value: promotionID,
        }}
      />
    </div>
  );
};

export default PromotionsDropdown;
