import {
  getCampaignCohortsAction,
  getCampaignMessagesAction,
} from 'actions/admin/campaign';
import { openModalAction } from 'actions/ui';

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import EmailPreview from 'components/EmailPreview';
import Footer from 'components/Footer';
import Heading from 'components/Heading';
import Table from 'components/table/Table';

import './CampaignReports.styl';
import DoughnutGraph from './DoughnutGraph';

export const CampaignReports = ({
  campaignCode,
  cohorts,
  getCampaignCohorts,
  getCampaignMessages,
  messages,
  openModal,
}) => {
  const [emailBody, setEmailBody] = useState(null);

  useEffect(() => {
    getCampaignCohorts(campaignCode);
    getCampaignMessages(campaignCode);
  }, [campaignCode, getCampaignCohorts, getCampaignMessages]);

  const messageColumns = [
    {
      label: t({ id: 'table.column.contract.uuid', message: `Contract ID` }),
      accessor: 'contractUUID',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.quoteID', message: `Quote ID` }),
      accessor: 'quoteID',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.message.email', message: `Email` }),
      accessor: 'destination',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.template.name', message: `Template name` }),
      accessor: 'templateName',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.send.status', message: `Send Status` }),
      accessor: 'sendStatus',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.delivery.status',
        message: `Delivery Status`,
      }),
      accessor: 'deliveryStatus',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.sent.at', message: `Sent at` }),
      accessor: 'sentAt',
      type: 'date',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.delivery.at', message: `Delivery at` }),
      accessor: 'deliveryStatusAt',
      type: 'date',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.actions', message: `Actions` }),
      accessor: 'actions',
      type: 'actions',
      filterable: false,
      sortable: false,
      align: 'right',
      wrap: false,
    },
  ];

  const cohortsColumns = [
    {
      label: t({ id: 'table.column.contract.uuid', message: `Contract ID` }),
      accessor: 'cartUuid',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.quoteID', message: `Quote ID` }),
      accessor: 'quoteID',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.customer.email', message: `Email` }),
      accessor: 'customerEmail',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.entered.at', message: `Entered cohort at` }),
      accessor: 'enteredAt',
      type: 'date',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.current.stage',
        message: `Current campaign stage`,
      }),
      accessor: 'stage',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.next.action', message: `Next action at` }),
      accessor: 'nextActionAt',
      type: 'date',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.left.at', message: `Left at` }),
      accessor: 'leftAt',
      type: 'date',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
  ];

  const actions = [
    {
      label: t({ id: 'table.column.actions.view', message: `View` }),
      handler: (_, { parametersJSON }) => {
        setEmailBody(parametersJSON);
        openModal('email-preview');
      },
    },
  ];

  const messageRows = messages?.map((message) => ({
    ...message,
    contractUUID: message.tradeCartUUID || message.cartUuid,
    actions,
  }));

  const getMessagesPerStatus = (actionType, status) =>
    messages?.reduce(
      (acc, message) => (message[actionType] === status ? acc + 1 : acc),
      0,
    );

  const sendPendingMessages = getMessagesPerStatus('sendStatus', 'PENDING');
  const sendDeliveredMessages = getMessagesPerStatus('sendStatus', 'SENT');
  const sendErrorMessages = getMessagesPerStatus('sendStatus', 'ERROR');

  const deliveryPendingMessages = getMessagesPerStatus(
    'deliveryStatus',
    'PENDING',
  );
  const deliveryDeliveredMessages = getMessagesPerStatus(
    'deliveryStatus',
    'DELIVERED',
  );
  const deliveryBouncedMessages = getMessagesPerStatus(
    'deliveryStatus',
    'BOUNCED',
  );
  const deliveryErrorMessages = getMessagesPerStatus('deliveryStatus', 'ERROR');

  const openingNotOpenedMessages = getMessagesPerStatus(
    'deliveryStatus',
    'DELIVERED',
  );
  const openingOpenedMessages = getMessagesPerStatus(
    'deliveryStatus',
    'OPENED',
  );

  return (
    <Fragment>
      <div className='campaign-reports'>
        <EmailPreview body={emailBody} />

        {messages?.length > 0 && (
          <div className='campaign-graphs'>
            <div>
              <Heading size='medium'>
                <Trans id='campaign.reports.send.graph'>Send status</Trans>
              </Heading>

              <DoughnutGraph
                labels={[
                  t({
                    id: 'campaign.reports.send.pending',
                    message: `Pending: ${sendPendingMessages}`,
                  }),
                  t({
                    id: 'campaign.reports.send.sent',
                    message: `Sent: ${sendDeliveredMessages}`,
                  }),
                  t({
                    id: 'campaign.reports.send.error',
                    message: `Error: ${sendErrorMessages}`,
                  }),
                ]}
                data={[
                  sendPendingMessages,
                  sendDeliveredMessages,
                  sendErrorMessages,
                ]}
                backgroundColor={['#FF6384', '#36A2EB', '#D92B47']}
              />
            </div>

            <div>
              <Heading size='medium'>
                <Trans id='campaign.reports.delivery.graph'>
                  Delivery status
                </Trans>
              </Heading>

              <DoughnutGraph
                labels={[
                  t({
                    id: 'campaign.reports.delivery.pending',
                    message: `Pending: ${deliveryPendingMessages}`,
                  }),
                  t({
                    id: 'campaign.reports.delivery.delivered',
                    message: `Delivered: ${
                      deliveryDeliveredMessages + openingOpenedMessages
                    }`,
                  }),
                  t({
                    id: 'campaign.reports.delivery.bounced',
                    message: `Bounced: ${deliveryBouncedMessages}`,
                  }),
                  t({
                    id: 'campaign.reports.delivery.error',
                    message: `Error: ${deliveryErrorMessages}`,
                  }),
                ]}
                data={[
                  deliveryPendingMessages,
                  deliveryDeliveredMessages + openingOpenedMessages,
                  deliveryBouncedMessages,
                  deliveryErrorMessages,
                ]}
                backgroundColor={['#FF6384', '#36A2EB', '#F2F2F2', '#D92B47']}
              />
            </div>

            <div>
              <Heading size='medium'>
                <Trans id='campaign.reports.opening.graph'>
                  Opening status
                </Trans>
              </Heading>

              <DoughnutGraph
                labels={[
                  t({
                    id: 'campaign.reports.openening.not.opened',
                    message: `Not opened: ${openingNotOpenedMessages}`,
                  }),
                  t({
                    id: 'campaign.reports.openening.opened',
                    message: `Opened: ${openingOpenedMessages}`,
                  }),
                ]}
                data={[openingNotOpenedMessages, openingOpenedMessages]}
                backgroundColor={['#FF6384', '#36A2EB']}
              />
            </div>
          </div>
        )}

        <Heading size='medium'>
          <Trans id='campaign.reports.messages.title'>Messages</Trans>
        </Heading>

        <Table
          columns={messageColumns}
          data={messageRows}
          keyColumn='id'
          defaultOrder='campaignId'
          defaultDir='desc'
          filterable={false}
        />

        <Heading size='medium'>
          <Trans id='campaign.reports.cohorts.title'>Cohort</Trans>
        </Heading>

        <Table
          columns={cohortsColumns}
          data={cohorts}
          keyColumn='id'
          defaultOrder='enteredAt'
          defaultDir='desc'
          filterable={false}
        />
      </div>

      <Footer classes='main' copyright={false} />
    </Fragment>
  );
};

export default connect(
  ({ adminCampaign, whitelabel }) => ({
    campaign: adminCampaign?.campaign,
    cohorts: adminCampaign?.cohorts,
    currency: whitelabel.currency,
    language: whitelabel.language,
    messages: adminCampaign?.messages,
  }),
  {
    getCampaignCohorts: getCampaignCohortsAction,
    getCampaignMessages: getCampaignMessagesAction,
    openModal: openModalAction,
  },
)(CampaignReports);
