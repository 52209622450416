import {
  updateCustomerAction,
  updateCustomerOptionAction,
} from 'actions/customer';
import { getCountryLabel, getCurrentCart } from 'utils';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Trans } from '@lingui/macro';

import Form from 'components/Form';
import Loader from 'components/Loader';
import Cart from 'components/cart/Cart';
import List from 'components/deprecated_list/DeprecatedList';
import IconExclamation from 'components/icons/IconExclamation';

import { posCustomerInfoFields } from 'constants/forms';
import { phoneFormats } from 'constants/locales';
import { SWAP, TRADE_IN } from 'constants/recycle';

import useTrackPage from 'hooks/useTrackPage';

import './CustomerInfo.styl';
import B2BCustomerInfo from './components/B2BCustomerInfo';

const CustomerInfo = ({ testSelector }) => {
  useTrackPage('CustomerInfo');

  const dispatch = useDispatch();
  const { cartUUID } = useParams();

  const country = useSelector(({ whitelabel }) => whitelabel.country);
  const language = useSelector(({ whitelabel }) => whitelabel.language);
  const currency = useSelector(({ whitelabel }) => whitelabel.currency);
  const countryPhoneCodes = useSelector(
    ({ whitelabel }) => whitelabel.configs.countryPhoneCodes,
  );
  const hasCompanyLookup = useSelector(
    ({ whitelabel }) => whitelabel.configs.hasCompanyLookup,
  );
  const loading = useSelector(({ ui }) => ui.loading);
  const cart = useSelector(({ carts }) => getCurrentCart(carts, cartUUID));
  const funder = useSelector(() => cart.funder);
  const options = useSelector(({ customer }) => customer.options);
  const showSapCustomerForm = useSelector(
    ({ whitelabel, recycle }) =>
      (recycle.type === SWAP || recycle.type === TRADE_IN) &&
      whitelabel.configs.showSapCustomerForm,
  );
  const requireSellerEmployeeNumber = useSelector(
    ({ whitelabel }) =>
      whitelabel.configs.requireSellerEmployeeNumberOnPOSCheckout,
  );

  const initialValues = useSelector(({ whitelabel, customer }) => {
    return {
      email: customer.email || '',
      title: customer.title || '',
      firstName: customer.firstName || '',
      lastName: customer.lastName || '',
      phone: customer.phone || phoneFormats[whitelabel.country]?.prefix,
      street: customer.street || '',
      city: customer.city || '',
      postCode: customer.postCode || '',
      country: getCountryLabel(country) || '',
      idNumber: customer.idNumber || '',
      customerReference: customer.customerReference || '',
      ssn: customer.lastName || '',
      applyForFinance: customer.applyForFinance || '',
      orderProductsFromTD: customer.orderProductsFromTD || '',
      isNewCustomer: customer.isNewCustomer || '',
      company: customer.company || {},
    };
  });

  const handleChange = (input, value) => {
    dispatch(updateCustomerAction(input.target.name, value));
  };

  if (loading) return <Loader />;

  const getSpecificFunderFields = () => {
    // TODO refactor later the string array of fields to a config
    switch (funder.toLowerCase()) {
      case 'bnp_uk':
        return (
          <>
            <Form
              initialValues={initialValues}
              form={'customerInfo'}
              destroyOnUnmount={true}
              fields={posCustomerInfoFields({
                country,
                countryPhoneCodes,
                handleChange,
              }).filter((field) =>
                [
                  'firstName',
                  'lastName',
                  'bnpPhone',
                  'email',
                  ...(showSapCustomerForm
                    ? ['idNumber', 'customerReference']
                    : []),
                ].includes(field.name),
              )}
            />

            <List
              type='circle'
              items={options}
              // TODO make this component reliant only on class functions
              // in order to remove arrow functions and enforce this rule
              // eslint-disable-next-line react/jsx-no-bind
              handleClick={(value) => {
                dispatch(updateCustomerOptionAction(value));
              }}
            />

            <p data-test='instructions'>
              <Trans id='customer.info.instructions.contact'>
                Customer should receive an email / SMS with full instructions.
              </Trans>
            </p>
          </>
        );
      case 'klarna':
        return (
          <>
            <Form
              initialValues={initialValues}
              form={'customerInfo'}
              destroyOnUnmount={true}
              fields={posCustomerInfoFields({
                country,
                countryPhoneCodes,
                handleChange,
              }).filter((field) =>
                [
                  'firstName',
                  'lastName',
                  'phone',
                  'email',
                  ...(showSapCustomerForm
                    ? ['idNumber', 'customerReference']
                    : []),
                ].includes(field.name),
              )}
            />

            <List
              type='circle'
              items={options}
              // TODO make this component reliant only on class functions
              // in order to remove arrow functions and enforce this rule
              // eslint-disable-next-line react/jsx-no-bind
              handleClick={(value) => {
                dispatch(updateCustomerOptionAction(value));
              }}
            />

            <p data-test='instructions'>
              <Trans id='customer.info.instructions.contact'>
                Customer should receive an email / SMS with full instructions.
              </Trans>
            </p>
          </>
        );
      case 'oneyfr':
        return (
          <>
            <p className='addressInputdisclaimer'>
              <IconExclamation data-test='icon-exclamation' />{' '}
              <Trans id='address.input.disclaimer' data-test='address-text'>
                Only addresses from {getCountryLabel(country)} are allowed.
              </Trans>
            </p>

            <Form
              initialValues={initialValues}
              form={'customerInfo'}
              destroyOnUnmount={true}
              fields={posCustomerInfoFields({
                country,
                countryPhoneCodes,
                handleChange,
              }).filter(
                (field) =>
                  [
                    'title',
                    'firstName',
                    'lastName',
                    'phone',
                    'email',
                    ...(showSapCustomerForm
                      ? ['idNumber', 'customerReference']
                      : []),
                  ].includes(field.name) ||
                  [
                    'street-oney',
                    'city-oney',
                    'postCode-oney',
                    'country-oney',
                    requireSellerEmployeeNumber ? 'sellerEmployeeNumber' : null,
                  ].includes(field.id),
              )}
            />

            <List
              type='circle'
              items={options}
              // TODO make this component reliant only on class functions
              // in order to remove arrow functions and enforce this rule
              // eslint-disable-next-line react/jsx-no-bind
              handleClick={(value) => {
                dispatch(updateCustomerOptionAction(value));
              }}
            />

            <p data-test='instructions'>
              <Trans id='customer.info.instructions.contact'>
                Customer should receive an email / SMS with full instructions.
              </Trans>
            </p>
          </>
        );
      case 'instabank':
        return (
          <>
            <Form
              initialValues={initialValues}
              form={'customerInfo'}
              destroyOnUnmount={true}
              fields={posCustomerInfoFields({
                country,
                countryPhoneCodes,
                handleChange,
              }).filter((field) =>
                [
                  'firstName',
                  'lastName',
                  'deviceReference',
                  'sequence',
                ].includes(field.name),
              )}
            />

            <p>
              <Trans id='customer.info.sequence.info'>
                Please insert the sequence number given to the customer after
                credit approval.
              </Trans>
            </p>
          </>
        );
      case 'expressbank':
        return (
          <>
            <Form
              data-test={testSelector}
              initialValues={initialValues}
              form={'customerInfo'}
              destroyOnUnmount={true}
              fields={posCustomerInfoFields({
                country,
                countryPhoneCodes,
                handleChange,
              }).filter((field) =>
                ['firstName', 'lastName', 'phone', 'email', 'ssn'].includes(
                  field.name,
                ),
              )}
            />

            <List
              type='circle'
              items={[options.find((option) => option.id === 'sms')]}
              // eslint-disable-next-line react/jsx-no-bind
              handleClick={(value) => {
                dispatch(updateCustomerOptionAction(value));
              }}
            />

            <p data-test='instructions'>
              <Trans id='customer.info.express.instructions.contact'>
                Customer should receive a SMS with full instructions.
              </Trans>
            </p>
          </>
        );
      case 'ekm':
        return (
          <B2BCustomerInfo
            testSelector={testSelector}
            initialValues={initialValues}
            country={country}
            countryPhoneCodes={countryPhoneCodes}
            hasCompanyLookup={hasCompanyLookup}
            cartUUID={cartUUID}
          />
        );
      default:
        return;
    }
  };

  return (
    <>
      <main className='content-left customer-info'>
        {getSpecificFunderFields()}
      </main>

      <aside className='content-right'>
        <Cart cart={cart} currency={currency} language={language} actionable />
      </aside>
    </>
  );
};

export default CustomerInfo;
