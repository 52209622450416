export const availableParams = {
  customer: {
    title: "Customer's title",
    firstName: "Customer's first name",
    lastName: "Customer's last name",
    email: "Customer's email",
    phone: "Customer's phone number",
  },
  deviceContract: {
    guid: "Device contract's unique identifier",
    reference: "Finance Technology contract's reference",
    storeName: 'Name of the store where the contract was performed',
    storeAddress: 'Addresss of the store where the contract was performed',
    storePhone: 'Phone number of the store where the contract was performed',
    storeEmail: 'Email of store where the contract was performed',
    storeSchedule:
      'Opening hours of the store where the contract was performed',
    deviceName: "Device contract's device name",
    categoryName: "Device contract's device category",
    familyName: "Device contract's device family",
    partNumber: "Device contract's device part number",
    deviceReference: "Device contract's device IMEI or serial number",
    categoryImage: "Device contract's category image link",
    imgUrl: "Device contract's device image link",
    paymentPlanName: "Device contract's payment plan name",
    earlyUpgradeDate: "Device contract's early upgrade date",
    freeUpgradeDate: "Device contract's free upgrade date",
    startDate: "Device contract's start date",
    endDate: "Device contract's end date",
    monthlyPrice: "Device contract's value paid per month",
    currency: "Device contract's currency",
    funderName: "Device contract's funder name",
    financingType: "Device contract's financing type",
    deliveryAddress: "Device contract's delivery address",
    startingPrincipal: "Device contract's starting principal",
    remainingPrincipal: 'Remaining principal at the of the contract',
    insuranceType: "Device contract's insurance type",
    insuranceStartDate: "Device contract's insurance start date",
    insuranceEndDate: "Device contract's insurance end date",
    months: "Device contract's fixed periods number",
    summaryURL: 'Link to the device contract summary page',
  },
  extrasContract: {
    guid: "Extras contract's unique identifier",
    reference: "Finance Technology contract's reference",
    extrasDescription: "Extras contract's extras description",
    extrasAmount: "Extras contract's extras total amount",
    paymentPlanName: "Extras contract's payment plan name",
    startDate: "Extras contract's start date",
    endDate: "Extras contract's end date",
    monthlyPrice: "Extras contract's value paid per month",
    currency: "Extras contract's currency",
    funderName: "Extras contract's funder name",
    financingType: "Extras contract's financing type",
    deliveryAddress: "Extras contract's delivery address",
    startingPrincipal: "Extras contract's starting principal",
    remainingPrincipal: 'Remaining principal at the of the contract',
    insuranceType: "Extras contract's insurance type",
    insuranceStartDate: "Extras contract's insurance start date",
    insuranceEndDate: "Extras contract's insurance end date",
    months: "Extras contract's fixed periods number",
    summaryURL: "Extras contract's summary page URL",
  },
  upgradedDeviceContract: {
    guid: "New Device Contract's unique identifier",
    reference: "Finance Technology contract's reference",
    storeName: 'Name of the store where the contract was performed',
    storeAddress: 'Addresss of the store where the contract was performed',
    storePhone: 'Phone number of the store where the contract was performed',
    storeEmail: 'Email of store where the contract was performed',
    storeSchedule:
      'Opening hours of the store where the contract was performed',
    deviceName: "New Device Contract's device name",
    categoryName: "New Device Contract's device category",
    familyName: "New Device Contract's device family",
    partNumber: "New Device Contract's device part number",
    deviceReference: "New Device Contract's device IMEI or serial number",
    categoryImage: "New Device contract's category image link",
    imgUrl: "New Device Contract's device image link",
    paymentPlanName: "New Device Contract's payment plan name",
    earlyUpgradeDate: "New Device Contract's early upgrade date",
    freeUpgradeDate: "New Device Contract's free upgrade date",
    startDate: "New Device Contract's start date",
    endDate: "New Device Contract's end date",
    monthlyPrice: "New Device Contract's value paid per month",
    currency: "New Device Contract's currency",
    funderName: "New Device Contract's funder name",
    financingType: "New Device Contract's financing type",
    deliveryAddress: "New Device Contract's delivery address",
    startingPrincipal: "New Device Contract's starting principal",
    remainingPrincipal: 'Remaining principal at the of the contract',
    insuranceType: "New Device Contract's insurance type",
    insuranceStartDate: "New Device Contract's insurance start date",
    insuranceEndDate: "New Device Contract's insurance end date",
    months: "New Device Contract's fixed periods number",
    summaryURL: "New Device Contract's summary page URL",
  },
  newExtrasContract: {
    guid: "New Extras Contract's unique identifier",
    reference: "Finance Technology contract's reference",
    extrasDescription: "New Extras Contract's extras description",
    extrasAmount: "New Extras Contract's extras total amount",
    paymentPlanName: "New Extras Contract's payment plan name",
    startDate: "New Extras Contract's start date",
    endDate: "New Extras Contract's end date",
    monthlyPrice: "New Extras Contract's value paid per month",
    currency: "New Extras Contract's currency",
    funderName: "New Extras Contract's funder name",
    financingType: "New Extras Contract's financing type",
    deliveryAddress: "New Extras Contract's delivery address",
    startingPrincipal: "New Extras Contract's starting principal",
    remainingPrincipal: 'Remaining principal at the of the contract',
    insuranceType: "New Extras Contract's insurance type",
    insuranceStartDate: "New Extras Contract's insurance start date",
    insuranceEndDate: "New Extras Contract's insurance end date",
    months: "New Extras Contract's fixed periods number",
  },
  retailer: {
    name: "Retailer's name",
    logo: 'Retailer logo url',
    url: "Retailer's homepage url",
  },
  funder: {
    name: "Funder's name",
    refundAmount: "Funder's final device evaluation",
  },
  recycler: {
    daysAfterSendingDevice:
      'Number of days passed without receiving the returned device',
    deliveryDays: 'Number of days where the customer can send the device',
    deviceValue: "Upgrade old contract's device evaluation value",
    deviceImg: "Upgrade old contract's device image",
    downGradeImg: "Recycler's downgrade reason image",
    downgradeReasons: "Recycler's device value lowering reasons",
    email: "Recycler's email",
    hasPackaging: 'Customer has requested packaging for returned device',
    orderId: "Recycler's order/quote ID",
    postGradingValue: "Recycler's final device evaluation",
    name: "Recycler's name",
    totalDeviceValue: "Upgrade old contract's all device evaluations value",
    totalPostGradingValue: "Recycler's total final of all device evaluations",
    trackingUrl: "Recycler's tracking delivery URL",
    valuationExpireDate: "Recycler's device evaluation expiry date",
  },
  offers: {
    familyName: "Offer's family name",
    deviceName: "Offer's name",
    monthlyPrice: "Offer's monthly price",
    paymentPlan: "Offer's payment plan name",
    media: {
      size: "Offer's image size",
      url: "Offer's image url",
      description: "Offer's image description",
      type: "Offer's image type",
      displayOrder: "Offer's image display order",
    },
  },
};
