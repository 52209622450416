import React from 'react';

const JVMDetailsTable = ({ item }) => {
  const renderJVMTable = (item) => {
    return (
      <table className='MethodTable'>
        <colgroup>
          <col width='20%;' />
          <col width='16%;' />
          <col width='16%;' />
          <col width='16%;' />
          <col width='16%;' />
          <col width='16%;' />
        </colgroup>
        <tbody>
          <tr className='stripe_even'>
            <th>Server Name</th>
            <th>OS</th>
            <th>Java</th>
            <th>Startup</th>
            <th>Current Time</th>
            <th>Uptime</th>
          </tr>
          <tr className='stripe_odd'>
            <td>{item.serverName}</td>
            <td>{item.osDetails}</td>
            <td>{item.vmDetails}</td>
            <td>{item.serverStartupTime}</td>
            <td>{item.serverCurrentTime}</td>
            <td>{item.serverUptime}</td>
          </tr>
          <tr className='stripe_even'>
            <th>Max Memory</th>
            <th>Allocated Memory</th>
            <th>Used Memory</th>
            <th>Free Memory</th>
            <th>Total Free Memory</th>
            <th>Active Threads</th>
          </tr>
          <tr className='stripe_odd'>
            <td>{item.maxMemoryStr}</td>
            <td>{item.allocatedMemoryStr}</td>
            <td>{item.usedMemoryStr}</td>
            <td>{item.freeMemoryStr}</td>
            <td>{item.totalFreeMemoryStr}</td>
            <td>{item.threadCount}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <div>
      <h1>JVM</h1>
      {renderJVMTable(item)}
      <br />
    </div>
  );
};

export default JVMDetailsTable;
