import print from 'assets/images/print.svg';
import {
  setRecycleTypeAction,
  setSearchDeviceReferenceAction,
} from 'actions/recycle';
import { exportEntityAction } from 'actions/reports';
import {
  amendDeviceCodeAction,
  amendDeviceReferenceAction,
  cancelUpgradeAction,
} from 'actions/sales';
import { openModalAction } from 'actions/ui';
import { validIMEICheckDigit } from 'helpers/validators';

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Trans } from '@lingui/macro';

import Button from 'components/Button';

import {
  ACTIVE_STATUS,
  ACTIVE_WITHOUT_DEVICE_REFERENCE,
  RETURN_REPLACE,
  UPGRADE_PENDING,
} from 'constants/contracts';
import { IMEI, SERIAL } from 'constants/deviceReference';
import { SWAP } from 'constants/recycle';
import { recycleSearchOrderUrl, viewSaleUrl } from 'constants/routes';

import CancelOrder from './CancelOrder';
import DeviceCodeModal from './DeviceCodeModal';
import DeviceReferenceModal from './DeviceReferenceModal';
import PaymentInfo from './PaymentInfo';
import ReturnAndCancelOrder from './ReturnAndCancelOrder';
import UpgradeUnavailable from './UpgradeUnavailable';

const ContractActions = ({
  amendDeviceReference,
  amendDeviceCode,
  cancelUpgrade,
  cartUUID,
  contract: {
    cancelType,
    discounts,
    extras,
    financing,
    financingType,
    funderReference,
    newSale,
    oldSale,
    products,
    status,
    statusLabel,
    swapStatus,
    uuid,
  },
  exportEntity,
  funder,
  openModal,
  setRecycleType,
  setSearchDeviceReference,
  useAppleValidation,
}) => {
  const navigate = useNavigate();

  const handleSwap = async () => {
    if (swapStatus === 'NO_SWAP') {
      openModal('upgradeUnavailable');
    } else {
      setRecycleType({
        recycleType: SWAP,
        orderUUID: uuid,
        funder,
      });

      await setSearchDeviceReference({
        deviceReference: products[0].deviceReference,
        referenceType: validIMEICheckDigit(products[0].deviceReference)
          ? IMEI
          : SERIAL,
      });

      navigate(recycleSearchOrderUrl(uuid));
    }
  };

  const exportAgreement = (orderUuid) =>
    exportEntity('CONTRACT', 'PDF', { orderUuid });

  const submitDeviceReference = ({ deviceReference, referenceType }) =>
    amendDeviceReference(uuid, deviceReference, referenceType === IMEI);

  const submitDeviceCode = ({ deviceCode, deviceReference, referenceType }) =>
    amendDeviceCode(uuid, deviceCode, deviceReference, referenceType === IMEI);

  const downloadContract = () => exportAgreement(uuid);

  const goToNewSale = () => navigate(viewSaleUrl(newSale));

  const goToOldSale = () => navigate(viewSaleUrl(oldSale));

  const cancelSwap = () => cancelUpgrade(uuid);

  const printReceipt = () => window.print();

  const isUpgradeFinancing = financingType === 'UPGRADE';

  return (
    <div className='contract-actions'>
      {isUpgradeFinancing && funder !== 'Instabank' && (
        <PaymentInfo
          uuid={uuid}
          financing={financing}
          financingType={financingType}
          funder={funder}
          items={products}
          discounts={discounts}
          extras={extras}
        />
      )}
      <Button classes='contract-actions__print' onClick={printReceipt}>
        <img src={print} className='print-img' alt='print' />
      </Button>
      {status === ACTIVE_STATUS &&
        swapStatus !== 'NO_SWAP' &&
        isUpgradeFinancing && (
          <Fragment>
            <Button onClick={handleSwap} data-test='contract-action-swap'>
              <Trans id='sale.upgrade'>Upgrade</Trans>
            </Button>

            <UpgradeUnavailable />
          </Fragment>
        )}
      {status === ACTIVE_STATUS &&
        isUpgradeFinancing &&
        statusLabel !== UPGRADE_PENDING &&
        statusLabel !== ACTIVE_WITHOUT_DEVICE_REFERENCE && (
          <DeviceReferenceModal
            buttonText={<Trans id='sale.update'>Update IMEI/SN</Trans>}
            testSelector='contract-action-device-reference'
            deviceReference={products[0].deviceReference}
            name='amendDeviceReference'
            onSubmit={submitDeviceReference}
            orderUUID={uuid}
            useAppleValidation={useAppleValidation}
          />
        )}
      {status === ACTIVE_STATUS &&
        isUpgradeFinancing &&
        !products[0].isCTO &&
        statusLabel !== UPGRADE_PENDING && (
          <DeviceCodeModal
            buttonText={<Trans id='sale.update.code'>Update Part Number</Trans>}
            testSelector='contract-action-device-code'
            deviceReference={products[0].deviceReference}
            name='amendDeviceCode'
            onSubmit={submitDeviceCode}
            orderUUID={uuid}
            useAppleValidation={useAppleValidation}
            statusLabel={statusLabel}
          />
        )}
      {funder === 'Instabank' && (
        <Button
          onClick={downloadContract}
          data-test='contract-action-download-contract'
        >
          <Trans id='sale.download'>Download</Trans>
        </Button>
      )}

      {status === ACTIVE_STATUS &&
        statusLabel === 'ACTIVE' &&
        (cancelType === RETURN_REPLACE ? (
          <ReturnAndCancelOrder cartUUID={cartUUID} />
        ) : (
          <CancelOrder uuid={uuid} funderReference={funderReference} />
        ))}

      {newSale && (
        <Button
          classes='contract-actions--right'
          onClick={goToNewSale}
          data-test='contract-action-nav-new-sale'
        >
          <Trans id='sale.newSale.redirect'>View new contract</Trans>
        </Button>
      )}
      {oldSale && (
        <Button
          classes='contract-actions--right'
          onClick={goToOldSale}
          data-test='contract-action-nav-old-sale'
        >
          <Trans id='sale.oldSale.redirect'>View old contract</Trans>
        </Button>
      )}
      {statusLabel === UPGRADE_PENDING && (
        <Button
          classes='contract-actions--right'
          onClick={cancelSwap}
          data-test='contract-action-sancel-swap'
        >
          <Trans id='sale.cancel.upgrade'>Cancel upgrade</Trans>
        </Button>
      )}
    </div>
  );
};

export default connect(
  ({ whitelabel }) => ({
    useAppleValidation: whitelabel.configs.useAppleDeviceReferenceValidation,
  }),
  {
    cancelUpgrade: cancelUpgradeAction,
    amendDeviceReference: amendDeviceReferenceAction,
    amendDeviceCode: amendDeviceCodeAction,
    openModal: openModalAction,
    setRecycleType: setRecycleTypeAction,
    setSearchDeviceReference: setSearchDeviceReferenceAction,
    exportEntity: exportEntityAction,
  },
)(ContractActions);
