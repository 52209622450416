import React from 'react';

const SvgIconTrashRed = ({ title, ...props }) => (
  <svg viewBox='0 0 40 40' width='40' height='40' {...props}>
    <path
      d='M25 31H15a2.017 2.017 0 01-1.437-.6A1.859 1.859 0 0113 29.083L12.08 18H12a2 2 0 01-2-2v-2a2 2 0 012-2h3v-1a2 2 0 012-2h6a2 2 0 012 2v1h3a2 2 0 012 2v2a2 2 0 01-2 2h-.08L27 29a2 2 0 01-2 2zM14.087 18L15 29h10v-.083L25.913 18zM12 14v2h16v-2H12zm5-3v1h6v-1z'
      fill='#d90000'
      data-name='24 / basic / trash'
    />
  </svg>
);

export default SvgIconTrashRed;
