import { getCurrentCart } from 'utils';

import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useOutletContext, useParams } from 'react-router-dom';

import ProductExtrasManual from 'components/ProductExtrasManual';

import './ProductList.styl';
import ProductListCatalog from './components/ProductListCatalog';

const ProductList = () => {
  const { cartUUID } = useParams();
  const [nextPage] = useOutletContext();

  const canAddExtras = useSelector(
    ({ whitelabel }) => whitelabel.configs.canAddExtras,
  );
  const cart = useSelector(({ carts }) => getCurrentCart(carts, cartUUID));

  return (
    <Fragment>
      {cart?.financingType === 'STANDARD' &&
      cart?.category === 'ACCESSORIES' &&
      canAddExtras === 'MANUAL' ? (
        <ProductExtrasManual current cart={cart} />
      ) : (
        <ProductListCatalog nextPage={nextPage} cart={cart} />
      )}
    </Fragment>
  );
};

export default ProductList;
