import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Outlet, useMatch } from 'react-router-dom';

import Header from 'components/header/Header';

import { changePasswordUrl, loginPageUrl } from 'constants/routes';
import { ALL_ROLES, userStatus } from 'constants/user';

import ErrorBoundary from './ErrorBoundary';
import TrackingPixel from './TrackingPixel';
import Unauthorized from './Unauthorized';

const RenderComponents = ({ allowedRoles, role, component, routerProps }) => {
  return allowedRoles.includes(role) ? (
    <Components component={component} routerProps={routerProps} />
  ) : (
    <Unauthorized />
  );
};

const Components = ({ component: Component, routerProps }) => (
  <ErrorBoundary>
    <main>
      {Component ? (
        <div tabIndex='-1' role='group'>
          <Component {...routerProps}>
            <Outlet />
          </Component>
        </div>
      ) : (
        <div id='content'>
          <Outlet />
        </div>
      )}
    </main>
  </ErrorBoundary>
);

const RouteRules = ({
  allowedRoles = ALL_ROLES,
  component,
  className = '',
  ...routerProps
}) => {
  const match = useMatch({ path: changePasswordUrl, end: true });
  const navigate = useNavigate();

  const wlID = useSelector(({ whitelabel }) => whitelabel?.wlId);
  const storeID = useSelector(({ user }) => user?.selectedStore);
  const sessionToken = useSelector(({ user }) => user?.session_token);
  const status = useSelector(({ user }) => user?.status);
  const role = useSelector(({ user }) => user?.role);
  const pageName = useSelector(({ ui }) => ui?.pageName);

  useEffect(() => {
    if (!sessionToken) navigate(loginPageUrl);
  }, [navigate, sessionToken]);

  useEffect(() => {
    if (!match && status === userStatus?.pending) navigate(changePasswordUrl);
  }, [match, navigate, status]);

  return (
    <>
      {(match || (!match && status === userStatus.active)) && (
        <div id='app' className={className}>
          <Header initialValues={{ storeId: storeID }} />

          <RenderComponents
            allowedRoles={allowedRoles}
            role={role}
            component={component}
            routerProps={routerProps}
          />

          <TrackingPixel
            type='pagetrack'
            pageName={pageName}
            storeID={storeID}
            wlID={wlID}
          />
        </div>
      )}
    </>
  );
};

export default RouteRules;
