import { setLanguageAction } from 'actions/whitelabel';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { getLanguageName } from 'utils';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import Dropdown from 'components/Dropdown';

class LanguageSwitcher extends Component {
  render() {
    const { availableLocales, className, setLanguage } = this.props;

    const options = availableLocales.map((cur) => ({
      value: cur,
      label: getLanguageName(cur) || cur,
    }));

    return (
      <form className='language-switcher'>
        <Field
          name='language'
          component={Dropdown}
          options={options}
          classes={`${className}`}
          onChange={setLanguage}
          variant='underline'
          testSelector='language-selector'
        />
      </form>
    );
  }
}

LanguageSwitcher.propTypes = {
  language: PropTypes.string,
  availableLocales: PropTypes.arrayOf(PropTypes.string).isRequired,
  setLanguage: PropTypes.func.isRequired,
};

LanguageSwitcher = reduxForm({
  form: 'language',
  enableReinitialize: true,
  destroyOnUnmount: false,
})(LanguageSwitcher);

export default connect(
  ({ whitelabel }) => ({
    language: whitelabel.language,
    availableLocales: whitelabel.configs.availableLocales,
    initialValues: { language: whitelabel.language },
  }),
  { setLanguage: setLanguageAction },
)(LanguageSwitcher);
