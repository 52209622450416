import { openModalAction } from 'actions/ui';
import { ReactComponent as Info } from 'assets/images/info.svg';
import { currencyFormatter } from 'helpers/formatters';

import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { t } from '@lingui/macro';

import Button from 'components/Button';
import Modal from 'components/Modal';

import { contractInfo, upfrontInfoText } from 'constants/contracts';

import './PaymentInfo.styl';

export const PaymentInfo = ({
  currency,
  discounts = {},
  extras = {},
  financing,
  funder,
  items,
  language,
  linkText,
  openModal,
  uuid,
}) => {
  const contractInfoText = contractInfo(
    funder,
    discounts,
    financing,
    items,
    extras,
    currency,
    language,
  );

  return (
    <Fragment>
      <Modal
        name={`contractInfo-${uuid}`}
        title={t({
          id: 'contract.info.title',
          message: `Loan Product Information`,
        })}
        classes='payment-info'
      >
        <ul className='modal-list'>
          {(financing?.openingFeePercentage > 0
            ? [
                upfrontInfoText(
                  currencyFormatter(
                    financing.openingFeeAmount,
                    currency,
                    language,
                  ),
                ),
                ...contractInfoText,
              ]
            : contractInfoText
          ).map((text, index) => (
            <li key={index}>{text}</li>
          ))}
        </ul>
      </Modal>
      {linkText ? (
        <span
          className='payment-info-anchor'
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => openModal(`contractInfo-${uuid}`)}
        >
          {linkText}
        </span>
      ) : (
        <Button
          testSelector='payment-info-modal-button'
          classes='inverted payment-info-btn'
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => openModal(`contractInfo-${uuid}`)}
        >
          <Info className='payment-info-btn-icon' />
        </Button>
      )}
    </Fragment>
  );
};

export default connect(
  ({ whitelabel }) => ({
    currency: whitelabel.currency,
    language: whitelabel.language,
  }),
  { openModal: openModalAction },
)(PaymentInfo);
