import { listStoresAction } from 'actions/admin/store';
import {
  blockUserAction,
  getUserAction,
  listUsersAction,
  resetUserPasswordAction,
  unblockUserAction,
} from 'actions/admin/user';
import { openModalAction } from 'actions/ui';
import trackPage from 'enhancers/trackPage';
import { getStatusKey } from 'utils';

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import Button from 'components/Button';
import Loader from 'components/Loader';
import Modal from 'components/Modal';
import Table from 'components/table/Table';

import { userStatus } from 'constants/status';
import { allRolesList } from 'constants/user';

import UserForm from './components/UserForm';

class Users extends Component {
  componentDidMount() {
    this.props.listUsers();
  }

  handleModalOpening = (user, modal) => {
    const { openModal } = this.props;

    this.setState({ user });
    openModal(modal);
  };

  render() {
    const {
      blockUser,
      loading,
      getUser,
      loggedEmail,
      openModal,
      resetUserPassword,
      stores,
      unblockUser,
      users,
    } = this.props;

    if (loading) return <Loader />;
    const columns = [
      {
        label: t({ id: 'table.column.first.name', message: `First Name` }),
        accessor: 'firstName',
        type: 'text',
        filterable: true,
        sortable: true,
        align: 'center',
        wrap: false,
      },
      {
        label: t({ id: 'table.column.last.name', message: `Last Name` }),
        accessor: 'lastName',
        type: 'text',
        filterable: true,
        sortable: true,
        align: 'center',
        wrap: false,
      },
      {
        label: t({ id: 'table.column.username', message: `Username` }),
        accessor: 'username',
        type: 'text',
        filterable: true,
        sortable: true,
        align: 'center',
        wrap: false,
      },
      {
        label: t({ id: 'table.column.role', message: `Role` }),
        accessor: 'roleID',
        type: 'text',
        filterable: true,
        list: allRolesList,
        sortable: true,
        align: 'center',
        wrap: false,
      },
      {
        label: t({
          id: 'table.column.email.address',
          message: `Email Address`,
        }),
        accessor: 'email',
        type: 'text',
        filterable: true,
        sortable: true,
        align: 'center',
        wrap: false,
      },
      {
        label: t({ id: 'table.column.stores', message: `Store` }),
        accessor: 'defaultStore',
        type: 'text',
        filterable: true,
        list: Object.entries(stores).reduce((acc, store) => {
          return {
            ...acc,
            [store[0]]: { label: store[1] },
          };
        }, {}),
        sortable: true,
        align: 'center',
        wrap: false,
      },
      {
        label: t({ id: 'table.column.status', message: `Status` }),
        accessor: 'status',
        type: 'status',
        filterable: true,
        list: userStatus(),
        sortable: true,
        align: 'center',
        wrap: false,
      },
      {
        label: t({
          id: 'table.column.last.activity',
          message: `Last Activity`,
        }),
        accessor: 'lastActivity',
        type: 'date',
        filterable: true,
        condition: 'after',
        sortable: true,
        align: 'center',
        wrap: false,
      },
      {
        label: t({ id: 'table.column.actions', message: `Actions` }),
        accessor: 'actions',
        type: 'actions',
        filterable: false,
        sortable: false,
        align: 'right',
        wrap: false,
      },
    ];

    const actions = [
      {
        label: t({ id: 'admin.user.actions.edit', message: `Edit` }),
        testSelector: 'edit-user',
        handler: async (_, { id }) => {
          const user = await getUser(id);
          this.handleModalOpening(user, 'admin-user');
        },
      },
      {
        label: t({
          id: 'admin.user.actions.reset.pass',
          message: `Reset pass`,
        }),
        testSelector: 'reset-password',
        handler: (_, { id, email }) => {
          this.handleModalOpening({ id, email }, 'admin-user-reset');
        },
      },
    ];

    const rows = users.map((user) => {
      if (getStatusKey(userStatus(), 'blocked') === user.status)
        return {
          ...user,
          actions: [
            {
              label: t({
                id: 'admin.users.actions.unblock',
                message: `Unblock`,
              }),
              testSelector: 'unblock-user',
              handler: (_, { id }) => unblockUser(id),
            },
          ],
        };

      return {
        ...user,
        actions: [
          ...actions,
          {
            label: t({ id: 'admin.users.actions.block', message: `Block` }),
            testSelector: 'block-user',
            className: 'red',
            handler: (_, { id }) => {
              this.setState({ user: { id } }, openModal('admin-user-block'));
            },
          },
        ],
      };
    });

    return (
      <Fragment>
        <h1 className='admin-page-title'>
          <Trans id='admin.users.title'>Users</Trans>
        </h1>

        <Button
          // TODO make this component reliant only on class functions
          // in order to remove arrow functions and enforce this rule
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => this.handleModalOpening({}, 'admin-user')}
          classes='admin-main-action'
          testSelector='add-user'
        >
          <Trans id='admin.user.add.button'>Add User</Trans>
        </Button>

        <Table columns={columns} data={rows} keyColumn='email' />

        <UserForm
          initialValues={this.state?.user}
          stores={Object.entries(stores).reduce(
            (acc, store) => ({ ...acc, [store[0]]: [store[1]] }),
            {},
          )}
        />

        <Modal
          name='admin-user-block'
          // eslint-disable-next-line react/jsx-no-bind
          onConfirm={() => {
            blockUser(this.state?.user?.id);
          }}
        >
          <Trans id='admin.user.block.confirmation'>
            Are you sure you want to block the user?
          </Trans>
        </Modal>

        <Modal
          name='admin-user-reset'
          // eslint-disable-next-line react/jsx-no-bind
          onConfirm={() => {
            resetUserPassword(this.state?.user?.id, this.state?.user?.email);
          }}
        >
          {loggedEmail === this.state?.user?.email ? (
            <Trans id='admin.self.reset.pass.confirmation'>
              You will be logged out! Are you sure you want to reset your
              password?
            </Trans>
          ) : (
            <Trans id='admin.user.reset.pass.confirmation'>
              Are you sure you want to reset the user's password?
            </Trans>
          )}
        </Modal>
      </Fragment>
    );
  }
}

Users.defaultProps = { users: [], initialValues: {} };

export default connect(
  ({ adminUser, whitelabel, ui, user }) => ({
    users: adminUser?.users,
    stores: user?.stores,
    language: whitelabel.language,
    loading: ui.loading,
    currency: whitelabel.currency,
    loggedEmail: user.email,
  }),
  {
    listUsers: listUsersAction,
    getUser: getUserAction,
    listStores: listStoresAction,
    resetUserPassword: resetUserPasswordAction,
    blockUser: blockUserAction,
    unblockUser: unblockUserAction,
    openModal: openModalAction,
  },
)(trackPage(Users, 'Users'));
