import React from 'react';

import WLIcon from './WLIcon';
import WLList from './WLList';

const TradeInsTable = ({ title, wls, items, emptyText, className }) => {
  const renderCostRow = (item, index, stripe) => {
    return (
      <tr key={index + 'x'} className={stripe}>
        <td>
          <span className='StatsTable_smallText'>Upgrade Cost</span>
        </td>
        <td>
          {item.swapCost}
          <br />
          <span className='StatsTable_smallTextI'>{item.currencyCode}</span>
        </td>
      </tr>
    );
  };

  const renderTradeInsRow = (item, index, stripe, wl) => {
    return (
      <tr key={index} className={stripe}>
        <td rowSpan={item.rows}>{item.time}</td>
        <td colSpan='3' rowSpan={item.rows}>
          {item.tradeInUUID}
          <br />
          <WLIcon key={'WLI' + index} item={wl} index={index} />{' '}
          {item.storeName}
          <br />
          <span className='StatsTable_smallTextI'>{item.salesPerson}</span>
        </td>
        <td>
          <span className='StatsTable_smallText'>{item.product} </span>
          <br />
          <span className='StatsTable_smallTextI'>
            ({item.network} {item.grade})
          </span>
        </td>
        <td>
          {item.tradeInValue}
          <br />
          <span className='StatsTable_smallTextI'>{item.currencyCode}</span>
        </td>
        <td
          className='StatsTable_smallText StatsTable_wrap'
          rowSpan={item.rows}
        >
          <span className='StatsTable_smallTextI'>{item.source}</span>
          <br />
          <span className='StatsTable_smallText'>{item.status}</span>
        </td>
      </tr>
    );
  };

  return (
    <table className={`StatsTable ${className}`}>
      <colgroup>
        <col width='50px;' />
        <col width='30px;' />
        <col width='140px;' />
        <col width='50px;' />
        <col width='200px;' />
        <col width='70px;' />
        <col width='80px;' />
      </colgroup>
      <tbody>
        <tr>
          <th colSpan='7'>
            <span className='StatsTable_caption'>{title}</span>{' '}
            <WLList items={wls} />
          </th>
        </tr>
        <tr className='StatsTable_innerHeader'>
          <td>Time</td>
          <td colSpan='3'>Store / Cart</td>
          <td>Product</td>
          <td>Amount</td>
          <td>Status</td>
        </tr>
        {items.map((item, index) => {
          var wl = wls.find(function (element) {
            return element.wlID === item.wlID;
          }, item.wlID);
          var stripe = index % 2 === 1 ? 'stripe_even' : 'stripe_odd';
          var rows = [];
          rows.push(renderTradeInsRow(item, index, stripe, wl));
          if (item.rows > 1) rows.push(renderCostRow(item, index, stripe));
          return rows;
        })}
        {items.length === 0 && (
          <tr>
            <td colSpan='7' className='StatsTable_none'>
              {emptyText}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
export default TradeInsTable;
