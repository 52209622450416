import React, { useEffect, useRef, useState } from 'react';

import './DebounceInput.styl';

const DebounceInput = ({
  inputValue,
  debounceTimer,
  formatter,
  onChange,
  validator,
  ...otherProps
}) => {
  const [value, setValue] = useState(inputValue);
  const [formattedValue, setFormattedValue] = useState(formatter(inputValue));
  const [showFormatted, setShowFormatted] = useState(true);
  const [error, setError] = useState(null);
  const timerRef = useRef(null);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    const errorMessage = validator(newValue);
    setError(errorMessage);
    if (!errorMessage) {
      setFormattedValue(formatter(newValue));
      if (value !== newValue) {
        event.persist();
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
          onChange(newValue);
        }, debounceTimer);
      }
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  return (
    <div className='debounce-input-container'>
      <input
        {...otherProps}
        onChange={handleChange}
        value={showFormatted && !error ? formattedValue : value}
        onBlur={() => setShowFormatted(true)}
        onFocus={() => setShowFormatted(false)}
      />
      {error && <p className='error'>{error}</p>}
    </div>
  );
};

DebounceInput.defaultProps = {
  inputValue: '',
  debounceTimer: 750,
  formatter: (value) => value,
  onChange: () => {},
  validator: () => {},
};

export default DebounceInput;
