import { withdrawAction } from 'actions/contract';
import { selectRecycleOutcomeAction } from 'actions/recycle';
import { currencyFormatter } from 'helpers/formatters';
import { hasDiscounts } from 'helpers/validators';

import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Trans } from '@lingui/macro';

import Button from 'components/Button';

import { REFUSED, WITHDRAWN } from 'constants/contracts';
import { GIFT_CARD, TRADE_IN } from 'constants/recycle';
import { recycleOutcomesUrl, servicesUrl } from 'constants/routes';

export const CreditSignActions = ({
  currency,
  language,
  cart,
  status,
  allowGiftCardOption,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tradecart = useSelector(({ recycle }) => recycle.tradecart);

  const cancelSale = () => {
    status !== WITHDRAWN && dispatch(withdrawAction(cart.uuid));
    navigate(servicesUrl);
  };

  const mobileRecycleDiscount = () => {
    const getRecycleDiscountSum = (discounts = []) =>
      discounts.reduce((acc, discount) => {
        if (discount?.type_id === 1 && discount?.locked) {
          return acc + discount.value;
        }
        return acc;
      }, 0);

    const getTotalRecycleDiscountSum = (orders = []) =>
      orders.reduce(
        (acc, order) => acc + getRecycleDiscountSum(order?.discounts?.items),
        0,
      );

    return currencyFormatter(
      Math.abs(
        getTotalRecycleDiscountSum(
          cart?.orders?.length > 0 ? cart?.orders : [cart?.current_order],
        ),
      ),
      currency,
      language,
    );
  };

  const navigateToOutcomes = () => {
    allowGiftCardOption && dispatch(selectRecycleOutcomeAction(GIFT_CARD));
    navigate(recycleOutcomesUrl);
  };

  return (
    <div className='creditSignActions'>
      {hasDiscounts(cart.orders) && (
        <p>
          <Trans id='contract.extras.discount.loss'>
            Your discounts might be lost if you continue!
          </Trans>
        </p>
      )}
      {status === 'REFUSED' &&
        allowGiftCardOption &&
        tradecart.source === TRADE_IN && (
          <p>
            <Trans id='contract.trade.in.store.credit'>
              Trade in value of {mobileRecycleDiscount()} added to your store
              credit.
            </Trans>
          </p>
        )}

      <div className='button-wrapper'>
        {status === 'REFUSED' && allowGiftCardOption && (
          <Fragment>
            <Button onClick={navigateToOutcomes}>
              <Trans id='contract.continue.return.to.outcomes'>
                Return to outcomes
              </Trans>
            </Button>
          </Fragment>
        )}

        {(status === REFUSED || status === WITHDRAWN) && (
          <Button onClick={cancelSale} classes={'red cancel-process'}>
            <Trans id='contract.cancel'>Cancel process</Trans>
          </Button>
        )}
      </div>
    </div>
  );
};

export default CreditSignActions;
