import {
  ADD_DEVICE_REFERENCE_TO_CART,
  CLEAR_CONTRACT,
  FINALISE_CART,
  GET_ORDER_STATUS,
  PERFORM_CREDIT_CHECK,
} from 'actions/contract';

import initialState from 'reducers/initialState';

export default function (state = initialState.contract, action) {
  switch (action.type) {
    case GET_ORDER_STATUS:
    case PERFORM_CREDIT_CHECK:
    case ADD_DEVICE_REFERENCE_TO_CART:
    case FINALISE_CART:
      return { ...state, ...action.data };
    case CLEAR_CONTRACT:
      return { ...initialState.contract };
    default:
      return state;
  }
}
