import removeIcon from 'assets/images/close.svg';
import map from 'lodash/map';

import React from 'react';

import { Trans } from '@lingui/macro';

import Button from 'components/Button';
import { ImageThumbnail } from 'components/Image';

import { TYPE_ID_MOBILE_RECYCLE } from 'constants/discount';

import './CartItems.styl';
import IconTradeinDevice from './IconTradeinDevice';

const createItem = (cartItems, remove) => {
  const { products, extras, discounts } = cartItems;
  return [
    ...(products?.reduce((acc, p) => {
      return [
        ...acc,
        {
          type: 'product',
          id: p.uuid,
          img: p.img_url,
          name: p.short_name,
          partNumber: p.part_number,
          option: p.selected_options,
          cashPrice: p.cash_price,
          totalCashPrice: p.totalCashPrice,
          quantity: p.quantity,
          monthlyPrice: p.monthly_price,
          remove: remove,
          locked: p.locked || products.length < 2,
          retailerMarkup: p.retailerMarkup,
          pricingIncludesTax: p.pricingIncludesTax,
        },
      ];
    }, []) || []),
    ...(extras?.reduce((acc, e) => {
      return [
        ...acc,
        {
          type: 'extra',
          id: e.uuid,
          img: e.img_url,
          name: e.description,
          partNumber: e.extra_code,
          cashPrice: e.value,
          totalCashPrice: e.totalCashPrice,
          quantity: e.quantity,
          monthlyPrice: e.monthly_price,
          remove: remove,
          locked: e.locked,
          retailerMarkup: e.retailerMarkup,
          pricingIncludesTax: e.pricingIncludesTax,
        },
      ];
    }, []) || []),

    ...(discounts?.reduce((acc, d) => {
      return [
        ...acc,
        {
          type: 'discount',
          typeID: d.type_id,
          id: d.uuid,
          img: 'https://cdn.financetech365.com/svg/Discount.svg',
          name: d.description,
          totalCashPrice: d.value,
          monthlyPrice: d.monthly_value,
          remove: remove,
          locked: d.locked,
          pricingIncludesTax: true,
        },
      ];
    }, []) || []),
  ];
};

const Item = ({ item, actionable }) => {
  const { img, name, partNumber, options, remove, typeID, locked } = item || {};
  return (
    <div className='cart__items__products__extras'>
      <div>
        {typeID === +TYPE_ID_MOBILE_RECYCLE ? (
          <IconTradeinDevice />
        ) : (
          <ImageThumbnail src={img} alt={name} />
        )}
      </div>
      <div>
        <div>{name && <strong>{name}</strong>}</div>
        {partNumber && <span>{partNumber}</span>}
        {options && <div>{map(options, (value, _) => value).join(' ')}</div>}
      </div>
      <div className='cart__items__remove__btn__container'>
        {!locked && actionable && (
          <Button
            classes='cart__items__remove__btn__container__button'
            onClick={() => remove(item)}
          >
            <img className='remove' src={removeIcon} alt='remove' />
          </Button>
        )}
      </div>
    </div>
  );
};

const RenderEmptyColumn = ({ renderEmptyRow }) => {
  return (
    <>
      <span>&nbsp;</span>
      <span>
        &nbsp;
        {renderEmptyRow && <div>&nbsp;</div>}
      </span>
    </>
  );
};

const PricingTable = ({ item, formatPrice }) => {
  const {
    cashPrice,
    totalCashPrice,
    quantity,
    monthlyPrice,
    retailerMarkup,
    pricingIncludesTax,
  } = item || {};
  return (
    <div className='cart__items__pricing__table'>
      <div>
        {cashPrice >= 0 ? (
          <>
            <span>
              <Trans id='cart.items.item.per.item'>Per item</Trans>
            </span>
            <span>
              {formatPrice(cashPrice)}
              {!pricingIncludesTax && (
                <div>
                  <Trans id='cart.items.item.excl.vat'>Excl. VAT</Trans>
                </div>
              )}
            </span>
          </>
        ) : (
          <RenderEmptyColumn renderEmptyRow={!pricingIncludesTax} />
        )}
      </div>
      <div>
        {!pricingIncludesTax && retailerMarkup >= 0 ? (
          <>
            <span>
              <Trans id='cart.items.item.retailer.markup'>Markup</Trans>
            </span>
            <span>
              {formatPrice(retailerMarkup)}
              <div>
                <Trans id='cart.items.item.retailer.markup.included'>
                  Included
                </Trans>
              </div>
            </span>
          </>
        ) : (
          <RenderEmptyColumn renderEmptyRow={!pricingIncludesTax} />
        )}
      </div>
      <div>
        {quantity >= 0 ? (
          <>
            <span>
              <Trans id='cart.items.item.quantity'>Qty.</Trans>
            </span>
            <span>
              {quantity}
              {!pricingIncludesTax && <div>&nbsp;</div>}
            </span>
          </>
        ) : (
          <RenderEmptyColumn renderEmptyRow={!pricingIncludesTax} />
        )}
      </div>
      <div className='rightalign-value'>
        {totalCashPrice ? (
          <>
            <span>
              <strong>
                <Trans id='cart.items.item.total'>Total</Trans>
              </strong>
            </span>
            <span>
              <strong>{formatPrice(totalCashPrice)}</strong>
              {!pricingIncludesTax && (
                <div className='rightalign-value'>
                  <Trans id='cart.items.item.excl.vat'>Excl. VAT</Trans>
                </div>
              )}
            </span>
          </>
        ) : (
          <RenderEmptyColumn renderEmptyRow={!pricingIncludesTax} />
        )}
      </div>

      <div className='rightalign-value'>
        {monthlyPrice ? (
          <>
            <span>
              <strong>
                <Trans id='cart.items.item.per.month'>Per mon.</Trans>
              </strong>
            </span>
            <span>
              <strong>{formatPrice(monthlyPrice)}</strong>
              {!pricingIncludesTax && <div>&nbsp;</div>}
            </span>
          </>
        ) : (
          <RenderEmptyColumn renderEmptyRow={!pricingIncludesTax} />
        )}
      </div>
    </div>
  );
};

const CartItems = ({
  type,
  cartItems,
  formatPrice,
  actionable,
  remove,
  discountForm,
}) => {
  const items = createItem(cartItems, remove);
  return (
    <div id={type}>
      {discountForm && discountForm}
      <ul>
        {items?.map((p) => (
          <li key={p.id}>
            <Item item={p} actionable={actionable}></Item>
            <PricingTable item={p} formatPrice={formatPrice}></PricingTable>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CartItems;
