import { LIST_STORES } from 'actions/admin/store';

import initialState from 'reducers/initialState';

export default function admin(state = initialState.adminStore, action) {
  switch (action.type) {
    case LIST_STORES:
      return { ...state, stores: action.data };
    default:
      return state;
  }
}
