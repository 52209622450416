import * as plurals from 'make-plural/plurals';

import React, { useEffect } from 'react';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { useSelector } from 'react-redux';

import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';

const i18nToDatefnsLocaleMap = {
  en: 'en-GB',
  'fr-BE': 'fr',
};

const getMessages = async (locale = 'en') => {
  const messageFile = await import(
    `@lingui/loader!locales/${locale}/messages.po`
  );

  return messageFile?.messages || {};
};

export const I18nLoader = ({ children }) => {
  const locale = useSelector(({ whitelabel }) => whitelabel.language);

  useEffect(() => {
    const loadCatalog = async (locale) => {
      let messages;

      try {
        messages = await getMessages(locale);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(`Invalid locale: ${locale} >`, err);

        locale = 'en';
        messages = await getMessages(locale);
      }

      i18n.loadLocaleData(locale, { plurals: plurals[locale.split('-')[0]] });
      i18n.load(locale, messages);
      i18n.activate(locale);

      const datefnsLocale = i18nToDatefnsLocaleMap[locale] || locale || 'en-GB';

      const localeDataModule = await import(`date-fns/locale/${datefnsLocale}`);
      const localeData = localeDataModule.default;

      registerLocale(datefnsLocale, localeData);
      setDefaultLocale(datefnsLocale);
    };
    loadCatalog(locale ? locale : 'en');
  }, [locale]);

  return <I18nProvider i18n={i18n}>{children}</I18nProvider>;
};

export default I18nLoader;
