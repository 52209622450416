import { upsertUserAction } from 'actions/admin/user';
import { closeModalAction } from 'actions/ui';
import classNames from 'classnames';
import { length } from 'helpers/validators';
import { email, required } from 'helpers/validators';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { Field, FieldArray, getFormValues, reduxForm } from 'redux-form';

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import LineItem from 'components/LineItem';
import Modal from 'components/Modal';
import RenderField from 'components/RenderField';

import { roles } from 'constants/user';

import './UserForm.styl';

class UserForm extends Component {
  addStore = (fields, selectedStore) => {
    if (selectedStore) {
      fields.push({ storeID: selectedStore, isDefault: false });
      this.props.change('storeID', null);
    }
  };

  setDefaultStore = (fields, setDefault = () => {}) => {
    fields.map((store) => this.props.change(`${store}.isDefault`, false));
    setDefault();
  };

  hasNoDefaultStore = () => {
    const userStores =
      (this.props.formValues && this.props.formValues.userStores) || [];
    return !userStores.find((store) => store.isDefault);
  };

  filterOutSelectedStores = (stores) => {
    const userStores =
      (this.props.formValues && this.props.formValues.userStores) || [];
    const userStoresIds = userStores.reduce(
      (acc, store) => [...acc, store.storeID],
      [],
    );
    return Object.keys(stores).reduce((acc, storeID) => {
      const value = parseInt(storeID, 10);
      if (userStoresIds.includes(value)) {
        return acc;
      }
      return [...acc, { value, label: stores[storeID] }];
    }, []);
  };

  renderUserStoresFields = ({ fields, selectedStore, stores, userStores }) => (
    <Fragment>
      <p>
        <Trans id='user.form.stores.list.title'>
          Stores associated with the user
        </Trans>
      </p>

      <ul className='stores-list'>
        {!isEmpty(stores) && (
          <li>
            <Field
              name='storeID'
              component={Dropdown}
              animateLabel={false}
              placeholder={t({
                id: 'user.form.stores.list.label',
                message: `Stores`,
              })}
              options={stores}
              variant='underline'
              labelType='outside'
              testSelector='stores-dropdown'
            />
            <Button
              classes='add-store'
              disabled={!selectedStore}
              testSelector='add-store-button'
              // TODO make this component reliant only on class functions
              // in order to remove arrow functions and enforce this rule
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => this.addStore(fields, selectedStore)}
            >
              <Trans id='user.form.add.store.to.user'>Add to User</Trans>
            </Button>
          </li>
        )}

        {this.hasNoDefaultStore() && (
          <p className='error'>
            <Trans id='user.form.stores.default.warning'>
              User must have a default store
            </Trans>
          </p>
        )}

        {fields &&
          fields.map((storeAccessor, index) => (
            <li key={storeAccessor} className='store-item'>
              <Field
                name={storeAccessor}
                // eslint-disable-next-line react/jsx-no-bind
                component={({ input }) => (
                  <LineItem
                    label={userStores[input.value.storeID]}
                    removable
                    // eslint-disable-next-line react/jsx-no-bind
                    onRemove={() => fields.remove(index)}
                  />
                )}
              />

              <Field
                name={`${storeAccessor}.isDefault`}
                // eslint-disable-next-line react/jsx-no-bind
                component={({ input }) => {
                  const { value, onChange } = input;
                  return (
                    <Button
                      classes={classNames('set-default', {
                        'default-store': value,
                      })}
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={() =>
                        value
                          ? onChange(false)
                          : this.setDefaultStore(fields, () => onChange(true))
                      }
                    >
                      {value ? (
                        <Trans id='user.form.stores.list.default.selected'>
                          Default Store
                        </Trans>
                      ) : (
                        <Trans id='user.form.stores.list.default.button'>
                          Set as Default
                        </Trans>
                      )}
                    </Button>
                  );
                }}
              />
            </li>
          ))}
      </ul>
    </Fragment>
  );

  render() {
    const {
      closeModal,
      formValues,
      handleSubmit,
      invalid,
      pristine,
      role,
      stores,
      submitting,
      upsertUser,
    } = this.props;
    const selectedStore = formValues && formValues.storeID;

    return (
      <Modal name='admin-user'>
        <form className='admin-user'>
          <Field
            name='firstName'
            type='text'
            testSelector='first-name'
            component={RenderField}
            validate={[required(), length({ max: 45 })]}
            label={t({
              id: 'user.form.first.name.placeholder',
              message: `First Name`,
            })}
          />

          <Field
            name='lastName'
            type='text'
            testSelector='last-name'
            component={RenderField}
            validate={[required(), length({ max: 45 })]}
            label={t({
              id: 'user.form.last.name.placeholder',
              message: `Last Name`,
            })}
          />

          <Field
            name='username'
            type='text'
            testSelector='username'
            component={RenderField}
            validate={[length({ max: 255 })]}
            label={t({
              id: 'user.form.username.placeholder',
              message: `Username`,
            })}
          />

          <Field
            name='email'
            type='email'
            testSelector='email-address'
            component={RenderField}
            validate={[required(), email(), length({ max: 250 })]}
            label={t({
              id: 'user.form.email.placeholder',
              message: `Email Address`,
            })}
          />

          <Field
            name='roleID'
            component={Dropdown}
            placeholder={t({
              id: 'user.form.role.placeholder',
              message: `Role`,
            })}
            testSelector='role'
            options={roles(role)}
            validate={[required()]}
            variant='underline'
            labelType='outside'
          />

          <FieldArray
            name='userStores'
            component={this.renderUserStoresFields}
            stores={this.filterOutSelectedStores(stores)}
            userStores={stores}
            selectedStore={selectedStore}
          />

          <Button
            classes='inverted'
            onClick={closeModal}
            testSelector='cancel-form-button'
          >
            <Trans id='device.form.cancel.button'>Cancel</Trans>
          </Button>

          <Button
            type='submit'
            disabled={
              pristine || invalid || this.hasNoDefaultStore() || submitting
            }
            onClick={handleSubmit(upsertUser)}
            testSelector='submit-form-button'
          >
            <Trans id='user.form.submit'>Submit</Trans>
          </Button>
        </form>
      </Modal>
    );
  }
}

UserForm.propTypes = {
  role: PropTypes.number.isRequired,
};

UserForm = reduxForm({
  form: 'admin-user',
  enableReinitialize: true,
})(UserForm);

UserForm = connect(
  (state) => ({
    role: state.user.role,
    formValues: getFormValues('admin-user')(state),
  }),
  {
    upsertUser: upsertUserAction,
    closeModal: closeModalAction,
  },
)(UserForm);

export default UserForm;
