import React from 'react';
import { connect } from 'react-redux';

import { Trans } from '@lingui/macro';

import { LOADER_TYPE_DEFAULT, LOADER_TYPE_PROCESSING } from 'constants/ui';

import './Loader.styl';

const Loader = ({ type = LOADER_TYPE_DEFAULT }) => {
  if (type === LOADER_TYPE_PROCESSING) {
    return (
      <div className='loader processing'>
        <div className='slideshow' />
      </div>
    );
  }

  return (
    <div className='loader loading'>
      <div className='triple-spinner' />
      <div className='loader-title'>
        <Trans id='loader.loading'>Loading...</Trans>
      </div>
    </div>
  );
};

export default connect(({ ui }) => ({ type: ui.loaderType }))(Loader);
