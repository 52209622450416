import { changeContractToSwapAction } from 'actions/admin/contracts';
import { getReportsAction } from 'actions/reports';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { t } from '@lingui/macro';

import Table from 'components/table/Table';

import { CONTRACT_ADJUST_PAGE_QUANTITY } from 'constants/admin';
import { ACTIVE } from 'constants/contracts';
import { swapStatus } from 'constants/status';

export const ContractDateAdjust = ({
  changeContractToSwap,
  contracts,
  getContracts,
  hasEarlyUpgrade,
  loading,
  totalCount,
}) => {
  const [page, setPage] = useState(0);

  useEffect(() => {
    getContracts('UPGRADE', [ACTIVE], page / CONTRACT_ADJUST_PAGE_QUANTITY);
  }, [getContracts, page]);

  const columns = [
    {
      label: t({ id: 'table.column.created', message: `Created on` }),
      accessor: 'creation_date',
      type: 'date',
      condition: 'after',
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.updated', message: `Updated on` }),
      accessor: 'lastUpdateDatetime',
      type: 'date',
      condition: 'after',
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.vendor', message: `Vendor` }),
      accessor: 'vendor',
      type: 'text',
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.store', message: `Store` }),
      accessor: 'store',
      type: 'text',
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.order', message: `Order` }),
      accessor: 'order_uuid',
      type: 'text',
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.customer', message: `Customer` }),
      accessor: 'customer',
      type: 'text',
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.device', message: `Device` }),
      accessor: 'device',
      type: 'text',
      align: 'center',
      wrap: true,
    },
    {
      label: t({ id: 'table.column.swap.status', message: `Swap Status` }),
      accessor: 'swapStatus',
      type: 'status',
      list: swapStatus(),
      align: 'center',
      wrap: true,
    },
  ];

  const handleSwapChange = async (rows, status) => {
    await changeContractToSwap(
      rows.map((row) => row.order_uuid),
      status,
    );
    getContracts('UPGRADE', [ACTIVE], page / CONTRACT_ADJUST_PAGE_QUANTITY);
  };

  const getPageReports = (offset) => {
    setPage(page + offset);
  };

  let multiSelectActions = [
    {
      label: t({
        id: 'table.column.move.free.upgrade',
        message: `Move to Free Upgrade`,
      }),
      handler: (rows) => handleSwapChange(rows, 'FREE_SWAP'),
    },
  ];

  if (hasEarlyUpgrade) {
    multiSelectActions = [
      {
        label: t({
          id: 'table.column.move.early.upgrade',
          message: `Move to Early Upgrade`,
        }),
        handler: (rows) => handleSwapChange(rows, 'EARLY_SWAP'),
      },
      ...multiSelectActions,
    ];
  }

  return (
    <Table
      filterable={false}
      columns={columns}
      data={contracts}
      multiSelect={true}
      multiSelectOptions={{
        multiSelectActions,
      }}
      pagination={{
        offset: page,
        count: CONTRACT_ADJUST_PAGE_QUANTITY,
        total: totalCount,
        getPaginatedData: getPageReports,
      }}
      keyColumn='order_uuid'
      defaultOrder={'lastUpdateDatetime'}
      defaultDir='desc'
      loading={loading}
    />
  );
};

export default connect(
  ({ reports, whitelabel, ui }) => ({
    contracts: reports.list,
    totalCount: reports.totalCount,
    currency: whitelabel.currency,
    language: whitelabel.language,
    loading: ui.loading,
    hasEarlyUpgrade: whitelabel.configs.earlySwapMonth !== -1,
  }),
  {
    changeContractToSwap: changeContractToSwapAction,
    getContracts: getReportsAction,
  },
)(ContractDateAdjust);
