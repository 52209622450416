import classNames from 'classnames';
import { currencyFormatter } from 'helpers/formatters';
import { validCurrencyValue } from 'helpers/validators';

import React, { Fragment, useState } from 'react';

import { Trans } from '@lingui/macro';

import { ImageMedium } from 'components/Image';
import LineItem from 'components/LineItem';

import './CartItem.styl';
import DebounceInput from './DebounceInput';

const CartItem = ({
  cartUUID,
  UUID,
  monthlyPrice,
  price,
  imgURL,
  name,
  actionRemove,
  actionable,
  isCTO,
  currency,
  language,
  quantity = 1,
  totalPrice,
  changeQuantity = () => {},
  changeSalePrice = () => {},
  useQuantities,
  canChangeSalePrice,
}) => {
  const [quantityValue, setQuantityValue] = useState(quantity);

  const renderImage = () => {
    if (isCTO) {
      return (
        <Fragment>
          <span className='manual-cto-img'>
            <Trans id='order.cto.extra.badge'>CTO</Trans>
          </span>
        </Fragment>
      );
    } else {
      return <ImageMedium src={imgURL} alt={name} />;
    }
  };

  return (
    <LineItem
      label={
        <Fragment>
          <div className='cart-item-img-wrapper'>{renderImage()}</div>
          <span>{name}</span>
        </Fragment>
      }
      value={
        <Fragment>
          <span className='price'>
            <DebounceInput
              className={
                canChangeSalePrice
                  ? 'cart-item-unit-price'
                  : 'cart-item-unit-price-none'
              }
              min='1'
              type='text'
              disabled={!canChangeSalePrice}
              inputValue={price}
              formatter={(value) =>
                currencyFormatter(value, currency, language)
              }
              onChange={(value) => changeSalePrice(cartUUID, UUID, value)}
              validator={(value) => validCurrencyValue(value)}
            />
          </span>
          <span className='price'>
            <DebounceInput
              className={`cart-item-quantity cart-item-quantity-${useQuantities}`}
              min={'1'}
              type='number'
              disabled={!useQuantities}
              inputValue={quantityValue}
              onChange={(value) => {
                setQuantityValue(value);
                changeQuantity(cartUUID, UUID, value);
              }}
            />
          </span>
          <span className='price'>
            {currencyFormatter(totalPrice, currency, language)}
          </span>
          <span className='price'>
            {currencyFormatter(monthlyPrice, currency, language)}
          </span>
        </Fragment>
      }
      onRemove={actionRemove}
      removable={actionable}
      classes={classNames('cart-item', { small: !actionable })}
    />
  );
};

export default CartItem;
