import { getVouchers } from 'actions/admin/voucher';

import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import Heading from 'components/Heading';
import Section from 'components/Section';
import Table from 'components/table/Table';

import VoucherUpload from './components/VoucherUpload';

const Vouchers = () => {
  const vouchers = useSelector(({ adminVoucher }) => adminVoucher?.vouchers);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getVouchers());
  }, [dispatch]);

  const columns = [
    {
      label: t({ id: 'admin.vouchers.created.at', message: `Created on` }),
      accessor: 'createdAt',
      type: 'date',
      filterable: true,
      condition: 'after',
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'admin.vouchers.code', message: `Name` }),
      accessor: 'code',
      type: 'text',
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'admin.vouchers.cart.uuid', message: `Cart UUID` }),
      accessor: 'cartUUID',
      type: 'text',
      filterable: false,
      sortable: false,
      align: 'right',
      wrap: false,
    },
    {
      label: t({ id: 'admin.vouchers.amount', message: `Amount` }),
      accessor: 'amount',
      type: 'currency',
      filterable: true,
      condition: 'after',
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'admin.vouchers.expiry.date', message: `Expiry Date` }),
      accessor: 'expiryDate',
      type: 'date',
      filterable: true,
      condition: 'after',
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'admin.vouchers.issue.date', message: `Issue Date` }),
      accessor: 'issueDate',
      type: 'date',
      filterable: true,
      condition: 'after',
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'admin.vouchers.retailer.sent.at',
        message: `Retailer Sent Date`,
      }),
      accessor: 'retailerSentAt',
      type: 'date',
      filterable: true,
      condition: 'after',
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'admin.vouchers.retailer.sent.status',
        message: `Retailer Send Status`,
      }),
      accessor: 'retailerSentStatus',
      type: 'text',
      filterable: false,
      sortable: false,
      align: 'right',
      wrap: false,
    },
    {
      label: t({ id: 'admin.vouchers.channel', message: `Channel` }),
      accessor: 'channel',
      type: 'text',
      filterable: false,
      sortable: false,
      align: 'right',
      wrap: false,
    },
    {
      label: t({ id: 'admin.vouchers.voucher.type', message: `Voucher Type` }),
      accessor: 'voucherTypeCode',
      type: 'text',
      filterable: false,
      sortable: false,
      align: 'right',
      wrap: false,
    },
    {
      label: t({ id: 'admin.vouchers.description', message: `Description` }),
      accessor: 'description',
      type: 'text',
      filterable: false,
      sortable: false,
      align: 'right',
      wrap: false,
    },
    {
      label: t({ id: 'admin.vouchers.status', message: `Status` }),
      accessor: 'status',
      type: 'text',
      filterable: false,
      sortable: false,
      align: 'right',
      wrap: false,
    },
  ];
  return (
    <Fragment>
      <VoucherUpload />
      <Heading classes='admin-page-title'>
        <Trans id='vouchers.title'>Vouchers</Trans>
      </Heading>
      <Section>
        <Table columns={columns} data={vouchers} />
      </Section>
    </Fragment>
  );
};

export default Vouchers;
