import isEmpty from 'lodash/isEmpty';
import { getFormValues, reduxForm } from 'redux-form';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { t } from '@lingui/macro';

import Form from 'components/Form';
import List from 'components/deprecated_list/DeprecatedList';

import { posCustomerInfoFields } from 'constants/forms';

import './CustomerInfoForm.styl';

class CustomerInfoForm extends Component {
  render() {
    const {
      change,
      country,
      countryPhoneCodes = [],
      formValues,
      handleSubmit,
      initialValues,
    } = this.props;

    return (
      <Form
        form={'customerInfo'}
        destroyOnUnmount={true}
        fields={[
          ...posCustomerInfoFields({
            country,
            countryPhoneCodes,
          })?.filter(
            (field) =>
              field.name ===
              formValues?.creditCheckOptions.find((option) => option.selected)
                .type,
          ),
          ...(!isEmpty(initialValues.creditCheckOptions)
            ? [
                {
                  component: List,
                  name: 'creditCheckOptions',
                  type: 'circle',
                  items: formValues
                    ? formValues.creditCheckOptions
                    : initialValues.creditCheckOptions,
                  handleClick: (value) => {
                    change(
                      'creditCheckOptions',
                      initialValues.creditCheckOptions.map((option) => ({
                        ...option,
                        selected: option.id === value,
                      })),
                    );
                  },
                },
              ]
            : []),
        ]}
        submitLabel={t({
          id: 'customer.form.submit.button',
          message: `Submit`,
        })}
        handleSubmit={handleSubmit}
        classes='customer-info-form'
      />
    );
  }
}

CustomerInfoForm = reduxForm({
  form: 'customerInfo',
  destroyOnUnmount: true,
})(CustomerInfoForm);

CustomerInfoForm = connect((state) => ({
  country: state.whitelabel.country,
  formValues: getFormValues('customerInfo')(state),
}))(CustomerInfoForm);

export default CustomerInfoForm;
