import {
  addNotificationAction,
  closeModalAction,
  hideLoaderAction,
  showLoaderAction,
} from 'actions/ui';
import { posalePost as post } from 'apis/v2';
import { stringWithCommasFormatter } from 'helpers/formatters';
import { getCurrentCart } from 'utils';

import {
  contractCancelSuccessful,
  postponeContractSuccess,
  retryCreditCheckSuccess,
} from 'constants/notifications';

export const GET_ORDER_STATUS = 'GET_ORDER_STATUS';
export function getContractStatusAction({ cartUUID }) {
  return async function (dispatch) {
    const response = await post(GET_ORDER_STATUS, {
      cartUUID,
    });

    dispatch({ type: GET_ORDER_STATUS, data: response.data.data });
  };
}

export const FINALISE_CART = 'FINALISE_CART';
export function finalizeCartAction(cartUUID) {
  return async function (dispatch) {
    const response = await post(FINALISE_CART, {
      cartUUID,
    });

    if (response.data.status === 'SUCCESS') {
      dispatch({
        type: FINALISE_CART,
        data: response.data.data,
      });
    }
  };
}

export const PERFORM_CREDIT_CHECK = 'PERFORM_CREDIT_CHECK';
export function performCreditCheckAction(cartUUID, data) {
  return async function (dispatch, getState) {
    dispatch(showLoaderAction());

    const response = await post(PERFORM_CREDIT_CHECK, {
      cartUUID,
      ...data,
    });

    if (response.data.status === 'SUCCESS') {
      dispatch({ type: PERFORM_CREDIT_CHECK, data: response.data.data });
      let cartFunder = getCurrentCart(
        getState().carts,
        cartUUID,
      )?.funder?.toLowerCase();
      if (
        response.data.data.url &&
        (process.env.NODE_ENV !== 'production' ||
          window.location.hostname.match(/.*financetechdemo.com/g) ||
          window.location.hostname.match(/.*financetechtest.com/g)) &&
        (cartFunder === 'klarna' ||
          cartFunder === 'bnp_uk' ||
          cartFunder === 'oneyfr')
      )
        window.open(response.data.data.url, '_blank');
    } else {
      dispatch({
        type: PERFORM_CREDIT_CHECK,
        data: { credit_status: 'ERROR' },
      });
    }

    dispatch(hideLoaderAction());
    return {
      creditStatus: response.data.status,
      quotationOnly: response.data.data.quotationOnly,
    };
  };
}

export const ADD_DEVICE_REFERENCE_TO_CART = 'ADD_DEVICE_REFERENCE_TO_CART';
export function addDeviceReferenceToCartAction(
  cartUUID,
  deviceReference,
  isIMEI,
) {
  return async function (dispatch) {
    const response = await post(ADD_DEVICE_REFERENCE_TO_CART, {
      cartUUID,
      deviceReference,
      isIMEI,
    });

    if (response.data.status === 'SUCCESS') {
      dispatch({
        type: ADD_DEVICE_REFERENCE_TO_CART,
        data: response.data.data,
      });
    }
  };
}

export const WITHDRAW = 'WITHDRAW';
export function withdrawAction(cartUUID) {
  return async function (dispatch) {
    const response = await post(WITHDRAW, {
      cartUUID,
    });

    if (response.data.status === 'SUCCESS')
      dispatch(addNotificationAction(contractCancelSuccessful()));

    dispatch({ type: WITHDRAW });
  };
}

export const ADD_NOTE_TO_CART = 'ADD_NOTE_TO_CART';
export function postponeContractAction({ note }, cartUUID) {
  return function (dispatch) {
    dispatch(showLoaderAction());

    post(ADD_NOTE_TO_CART, {
      cartUUID,
      note: stringWithCommasFormatter(note),
    });

    dispatch(closeModalAction());

    dispatch(addNotificationAction(postponeContractSuccess()));

    dispatch(hideLoaderAction());
  };
}

export const RETRY_CREDIT_CHECK = 'RETRY_CREDIT_CHECK';
export function retryCreditCheckAction({
  cartUUID,
  creditCheckOptions,
  email,
  phone,
}) {
  return async function (dispatch) {
    dispatch(showLoaderAction());
    const distributionMethod = creditCheckOptions.find(
      ({ selected }) => selected === true,
    ).id;
    const response = await post(RETRY_CREDIT_CHECK, {
      cartUUID,
      distributionMethod,
      email: distributionMethod === 'email' ? email : undefined,
      phone: distributionMethod === 'sms' ? phone : '',
    });

    if (response.data.status === 'SUCCESS') {
      dispatch(addNotificationAction(retryCreditCheckSuccess()));
      dispatch(closeModalAction());
      dispatch({ type: RETRY_CREDIT_CHECK });
    }

    dispatch(hideLoaderAction());
  };
}

export const CLEAR_CONTRACT = 'CLEAR_CONTRACT';
export function clearContractAction() {
  return function (dispatch) {
    dispatch({ type: CLEAR_CONTRACT });
  };
}
