import alertIcon from 'assets/images/alert.svg';
import declinedIcon from 'assets/images/declined.svg';
import okIcon from 'assets/images/ok.svg';
import classNames from 'classnames';

import React from 'react';

import Spinner from 'components/Spinner';

import {
  APPROVED,
  PARTIALLY_APPROVED,
  PENDING,
  PRE_APPROVED,
  REFERRED,
  REFUSED,
  WITHDRAWN,
  labels,
} from 'constants/contracts';

import './CreditStep.styl';

const CreditStep = ({
  data = {},
  disabled,
  renderAside,
  renderExtraContent,
  visibleExtraContent = false,
  status,
  type,
  visible = true,
}) => {
  const statusIconMap = {
    [APPROVED]: okIcon,
    [REFUSED]: declinedIcon,
    [PARTIALLY_APPROVED]: alertIcon,
    [WITHDRAWN]: alertIcon,
    [PRE_APPROVED]: okIcon,
  };

  const cx = classNames('creditStep', { disabled });

  if (!status || !visible) return null;

  return (
    <div className={cx}>
      <div className={`creditStep__indicator ${status.toLowerCase()}`}>
        {status === PENDING || status === REFERRED ? (
          <Spinner />
        ) : (
          <img src={statusIconMap[status]} alt={status} />
        )}
      </div>

      <div className='creditStep__labels'>
        <div>{labels(data)[type]?.titles?.[status]}</div>
        <div>{labels(data)[type]?.subtitles?.[status]}</div>
      </div>

      <div className='creditStep__aside'>{renderAside}</div>

      {visibleExtraContent && (
        <div className='creditStep__extraContent'>{renderExtraContent}</div>
      )}
    </div>
  );
};

export default CreditStep;
