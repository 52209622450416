import * as React from 'react';

function SvgIconCamera({ title, titleId, ...props }) {
  return (
    <svg width='1em' height='1em' aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <clipPath id='icon-camera_svg__a'>
          <path d='M0 0h36v36H0z' />
        </clipPath>
      </defs>
      <g clipPath='url(#icon-camera_svg__a)'>
        <path fill='none' d='M0 0h36v36H0z' />
        <path
          d='M26 27H10a3 3 0 01-3-3v-9a3 3 0 013-3h2.816c.033-.038.077-.093.122-.154.075-.105.154-.227.249-.382.049-.078.21-.343.27-.443l.025-.042C14.284 9.666 14.894 9 16 9h4c1.106 0 1.716.666 2.518 1.978l.025.04.127.21.073.122.018.03.034.056.014.023c.114.183.189.3.25.382a1.863 1.863 0 00.125.159H26a3 3 0 013 3v9a3 3 0 01-3 3zM10 14a1 1 0 00-1 1v9a1 1 0 001 1h16a1 1 0 001-1v-9a1 1 0 00-1-1h-3a1.859 1.859 0 01-1.561-.987 7.258 7.258 0 01-.328-.5l-.058-.094-.047-.077v-.007l-.065-.107-.041-.06-.022-.035-.028-.047-.008-.014-.013-.022-.006-.009-.01-.015C20.38 11.316 20.093 11 20 11h-4c-.093 0-.38.316-.812 1.022l-.3.493a8.033 8.033 0 01-.328.5A1.859 1.859 0 0113 14zm8 10a5 5 0 115-5 5.006 5.006 0 01-5 5zm0-8a3 3 0 103 3 3 3 0 00-3-3zm7 1a1 1 0 111-1 1 1 0 01-1 1z'
          fill='currentColor'
          data-name='Icon / Photo / Ghost'
        />
      </g>
    </svg>
  );
}

export default SvgIconCamera;
