import { cleanStoreAction } from 'actions/app';
import { addNotificationAction } from 'actions/ui';
import { posalePost as post } from 'apis/v2';

import { errorCodes, notificationTitle } from 'constants/errors';
import {
  changePasswordSuccess,
  recoverPasswordSuccess,
} from 'constants/notifications';

export const CHANGE_SELECTED_STORE = 'CHANGE_SELECTED_STORE';
export function changeStoreAction(storeID, cartUUID) {
  return async function (dispatch) {
    await post('CHANGE_SELECTED_STORE', {
      storeID,
      cartUUID,
    });

    dispatch({ type: CHANGE_SELECTED_STORE, storeID });
  };
}

export const LOGIN = 'LOGIN';
export function loginAction({ login, password }) {
  return async function (dispatch) {
    try {
      const response = await post(LOGIN, {
        login,
        password,
      });

      if (response.data.status === 'SUCCESS') {
        await dispatch({ type: LOGIN, data: response.data.data });

        return response.data.data;
      }
    } catch (exception) {
      dispatch(
        addNotificationAction({
          title: notificationTitle().ERROR,
          message: errorCodes().REQUEST_FAIL,
          type: 'error',
        }),
      );
    }
  };
}

export const SINGLE_SIGN_ON = 'SINGLE_SIGN_ON';
export function singleSignOnAction() {
  return async function (dispatch) {
    const response = await post(SINGLE_SIGN_ON, {});

    dispatch({ type: SINGLE_SIGN_ON });

    window.location.href = response.data.data.redirectUrl;
  };
}

export const GET_USER_INFO = 'GET_USER_INFO';
export function getUserInfoAction(token) {
  return async function (dispatch) {
    const response = await post(LOGIN, {
      token,
    });

    await dispatch({ type: GET_USER_INFO, data: response.data.data });
  };
}

export const LOGOUT = 'LOGOUT';
export function logoutAction() {
  return async function (dispatch) {
    const response = await post(LOGOUT, {});

    if (response.data.status === 'SUCCESS') {
      dispatch({ type: LOGOUT });
      dispatch(cleanStoreAction());
      response.data.data.url && window.open(response.data.data.url, '_blank');
    }
  };
}

export const INITIALIZE_USER = 'INITIALIZE_USER';
export function initializeUserAction() {
  return function (dispatch) {
    dispatch({ type: INITIALIZE_USER });
  };
}

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export function forgotPasswordAction({ login }) {
  return async function (dispatch) {
    const response = await post(FORGOT_PASSWORD, {
      login,
    });

    if (response.data.status === 'SUCCESS') {
      dispatch(addNotificationAction(recoverPasswordSuccess()));
    }

    dispatch({ type: FORGOT_PASSWORD });
  };
}

export const RESET_PASSWORD = 'RESET_PASSWORD';
export function resetPasswordAction(resetToken) {
  return async function (dispatch) {
    const response = await post(RESET_PASSWORD, {
      resetToken,
    });

    dispatch({ type: RESET_PASSWORD, data: response.data.status });
  };
}

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export function changePasswordAction({ oldPassword, newPassword }) {
  return async function (dispatch) {
    const response = await post(CHANGE_PASSWORD, {
      newPassword,
      oldPassword,
    });

    if (response.data.status === 'SUCCESS') {
      dispatch({ type: CHANGE_PASSWORD });

      dispatch(addNotificationAction(changePasswordSuccess()));
    }

    return response.data.status === 'SUCCESS';
  };
}
