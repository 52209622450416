import store from 'store';

import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import {
  adminAccessoriesPageUrl,
  adminAttachmentsRoute,
  adminBeginUrl,
  adminCampaignDetailsRoute,
  adminCampaignsRoute,
  adminCollectionRoute,
  adminCollectionsOrganiseRoute,
  adminCollectionsRoute,
  adminContractDateAdjustRoute,
  adminDevicesPageUrl,
  adminPricingToolRoute,
  adminResidualValuesSearchRoute,
  adminStoresPageUrl,
  adminTemplateBlockEditorRoute,
  adminTemplateBlocksRoute,
  adminTemplateEditorRoute,
  adminTestContractMessagesRoute,
  adminTestContractsRoute,
  adminUserPageUrl,
  adminVouchersRoute,
  cartContractRoute,
  cartDetailsRoute,
  cartSaleRoute,
  changePasswordUrl,
  checkoutCartRoute,
  creditRoute,
  customerRoute,
  dashboardUrl,
  extrasRoute,
  loginPageUrl,
  loginRecoverUrl,
  loginTokenRoute,
  monitorUrl,
  passwordResetRoute,
  pendingContractsRoute,
  productCustomizeRoute,
  productListRoute,
  productOptionsRoute,
  recycleDetailsRoute,
  recycleEvaluateOrderRoute,
  recycleEvaluateRoute,
  recycleOutcomesOrderRoute,
  recycleOutcomesRoute,
  recycleQuoteSearchRoute,
  recycleQuotesRoute,
  recycleReportsRoute,
  recycleRoute,
  recycleSearchOrderRoute,
  recycleSearchRoute,
  reportSettlementRoute,
  reportsRoute,
  salesRoute,
  servicesUrl,
  servicesUrlAny,
  standardAnyUrl,
  standardFinanceRoute,
  upgradeAnyUrl,
  upgradeFlowRoute,
  uploadSalesRoute,
} from 'constants/routes';
import {
  ADMIN,
  ADMIN_WHITELIST,
  STORE_MANAGER,
  VENDOR_MANAGER,
} from 'constants/user';

import Dashboard from 'pages/dashboard/Dashboard';
import ServiceMonitor from 'pages/monitor/ServiceMonitor';
import StandardFinancing from 'pages/posale/[financingType]/StandardFinancing';
import Upgrade from 'pages/posale/[financingType]/Upgrade';
import ProductCategories from 'pages/posale/[financingType]/[cartUUID]/ProductCategories';
import ProductList from 'pages/posale/[financingType]/catalog/ProductList';
import CreditSteps from 'pages/posale/[financingType]/credit/CreditSteps';
import CustomerInfo from 'pages/posale/[financingType]/customer/CustomerInfo';
import ProductCustomization from 'pages/posale/[financingType]/customize/ProductCustomization';
import ProductExtras from 'pages/posale/[financingType]/extras/ProductExtras';
import ProductOptions from 'pages/posale/[financingType]/options/ProductOptions';
import Accessories from 'pages/posale/admin/accessories/Accessories';
import Campaigns from 'pages/posale/admin/campaigns/Campaigns';
import CampaignDetails from 'pages/posale/admin/campaigns/[campaignCode]/details/CampaignDetails';
import Collections from 'pages/posale/admin/collections/Collections';
import CollectionDetails from 'pages/posale/admin/collections/[collectionUUID]/CollectionDetails';
import CollectionsOrganise from 'pages/posale/admin/collections/organise/CollectionsOrganise';
import AdminPage from 'pages/posale/admin/components/AdminPage';
import ContractDateAdjust from 'pages/posale/admin/contracts/adjust/ContractDateAdjust';
import Devices from 'pages/posale/admin/devices/Devices';
import Attachments from 'pages/posale/admin/email/attachments/Attachments';
import TemplateEditor from 'pages/posale/admin/emails/[campaignCode]/[templateName]/TemplateEditor';
import Blocks from 'pages/posale/admin/emails/blocks/Blocks';
import BlockEditor from 'pages/posale/admin/emails/blocks/[blockUUID]/BlockEditor';
import PricingTool from 'pages/posale/admin/pricing_tool/PricingTool';
import ResidualValuesSearch from 'pages/posale/admin/residualvalues/ResidualValuesSearch';
import Stores from 'pages/posale/admin/stores/Stores';
import TestContracts from 'pages/posale/admin/testcontracts/TestContracts';
import TestContractDetails from 'pages/posale/admin/testcontracts/[testContractID]/TestContractDetails';
import Users from 'pages/posale/admin/users/Users';
import Vouchers from 'pages/posale/admin/vouchers/Vouchers';
import CartDetails from 'pages/posale/cart/[cartUUID]/CartDetails';
import Checkout from 'pages/posale/checkout/[cartUUID]/Checkout';
import Login from 'pages/posale/login/Login';
import PasswordForgot from 'pages/posale/login/recover/PasswordForgot';
import PasswordReset from 'pages/posale/login/resetpassword/[id]/PasswordReset';
import PasswordChange from 'pages/posale/password/change/PasswordChange';
import Evaluation from 'pages/posale/recycle/[[orderUUID]]/evaluate/Evaluation';
import RecycleOutcomes from 'pages/posale/recycle/[[orderUUID]]/outcomes/RecycleOutcomes';
import RenderRecycleSearch from 'pages/posale/recycle/[[orderUUID]]/search/RenderRecycleSearch';
//TODO remove this component when the multi trade search is completed
import RecycleDetails from 'pages/posale/recycle/details/[tradecartID]/RecycleDetails';
import Recycle from 'pages/posale/recycle/quotes/Recycle';
import OnlineQuoteSearch from 'pages/posale/recycle/quotes/search/OnlineQuoteSearch';
import RecycleReports from 'pages/posale/reports/recycle/RecycleReports';
import Settlements from 'pages/posale/reports/settlements/Settlements';
import Sales from 'pages/posale/sales/Sales';
import Sale from 'pages/posale/sales/[cartUUID]/Sale';
import PendingContracts from 'pages/posale/sales/contracts/pending/PendingContracts';
import SalesUpload from 'pages/posale/sales/upload/SalesUpload';
import Services from 'pages/posale/services/[[cartUUID]]/Services';

import PageNotFound from './PageNotFound';
import PrevLocationUpdater from './PrevLocationUpdater';
import RouteRules from './RouteRules';
import ScrollToTop from './ScrollToTop';

const Router = () => {
  let location = useLocation();
  const { servicesConfig } = store?.getState().whitelabel.configs;
  const { services } = servicesConfig || {};
  const activeFlows = services?.reduce((result, service) => {
    const cv = service?.group?.reduce((acc, item) => {
      return {
        ...acc,
        [item.flow]: item.active && { active: true },
      };
    }, {});

    return {
      ...result,
      ...cv,
    };
  }, {});

  const { UPGRADE, STANDARD, TRADE_IN, UPLOAD_SALES } = activeFlows || {};

  return (
    <>
      <PrevLocationUpdater location={location} store={store} />

      <Routes location={location} primary={false}>
        <Route exact element={<Navigate to='/posale/services' />} path='/' />

        <Route element={<ScrollToTop />} path='/'>
          <Route path='*' element={<Navigate to='/posale/services' />} />

          <Route
            path={'/posale'}
            element={<Navigate replace to={servicesUrl} />}
          />

          <Route element={<Login />} path={loginPageUrl} />

          <Route element={<Login />} path={loginTokenRoute} />
          <Route element={<PasswordForgot />} path={loginRecoverUrl} />
          <Route element={<PasswordReset />} path={passwordResetRoute} />
          <Route
            element={<RouteRules component={PasswordChange} />}
            path={changePasswordUrl}
          />
          <Route element={<Dashboard />} path={dashboardUrl} />
          <Route element={<ServiceMonitor />} path={monitorUrl} />

          <Route
            element={<RouteRules component={Services} />}
            path={servicesUrl}
          />

          <Route
            element={<RouteRules component={Services} />}
            path={servicesUrlAny}
          />

          {UPGRADE?.active ? (
            <Route
              element={<RouteRules exact component={Upgrade} />}
              path={upgradeFlowRoute}
            >
              <Route element={<PageNotFound withHeader />} path='*' />

              <Route element={<ProductCategories />} path={upgradeFlowRoute} />
              <Route element={<ProductList />} path={productListRoute} />
              <Route element={<ProductOptions />} path={productOptionsRoute} />
              <Route
                element={<ProductCustomization />}
                path={productCustomizeRoute}
              />
              <Route element={<ProductExtras />} path={extrasRoute} />
              <Route element={<CustomerInfo />} path={customerRoute} />
              <Route element={<CreditSteps />} path={creditRoute} />
            </Route>
          ) : (
            <Route
              path={upgradeAnyUrl}
              element={<Navigate replace to={servicesUrl} />}
            />
          )}
          {STANDARD?.active ? (
            <Route
              element={<RouteRules component={StandardFinancing} />}
              path={standardFinanceRoute}
            >
              <Route element={<PageNotFound withHeader />} path='*' />

              <Route
                element={<ProductCategories />}
                path={standardFinanceRoute}
              />
              <Route element={<ProductList />} path={productListRoute} />
              <Route element={<ProductOptions />} path={productOptionsRoute} />
              <Route
                element={<ProductCustomization />}
                path={productCustomizeRoute}
              />
              <Route element={<ProductExtras />} path={extrasRoute} />
            </Route>
          ) : (
            <Route
              path={standardAnyUrl}
              element={<Navigate replace to={servicesUrl} />}
            />
          )}

          <Route
            element={<RouteRules component={CartDetails} />}
            path={cartDetailsRoute}
          />
          <Route
            element={<RouteRules component={Checkout} />}
            path={checkoutCartRoute}
          >
            <Route element={<PageNotFound withHeader />} path='*' />

            <Route element={<CustomerInfo />} path={customerRoute} />
            <Route element={<CreditSteps />} path={creditRoute} />
          </Route>

          <Route
            element={<RouteRules component={Sale} actionable />}
            path={cartContractRoute}
          />

          <Route
            element={<RouteRules exact className='grey-bg' />}
            path={salesRoute}
          >
            <Route element={<PageNotFound withHeader />} path='*' />

            <Route element={<Sales />} path={salesRoute} />
            <Route element={<Sale actionable />} path={cartSaleRoute} />

            {UPLOAD_SALES?.active ? (
              <>
                <Route element={<PageNotFound withHeader />} path='*' />
                <Route
                  element={
                    <SalesUpload allowedRoles={[ADMIN, VENDOR_MANAGER]} />
                  }
                  path={uploadSalesRoute}
                />
              </>
            ) : (
              <Route
                path={uploadSalesRoute}
                element={<Navigate replace to={servicesUrl} />}
              />
            )}
          </Route>
          <Route
            element={
              <RouteRules component={PendingContracts} className='grey-bg' />
            }
            path={pendingContractsRoute}
          />

          {TRADE_IN?.active ? (
            <Route
              element={<RouteRules component={RenderRecycleSearch} />}
              path={recycleSearchRoute}
            />
          ) : (
            <Route
              path={recycleSearchRoute}
              element={<Navigate replace to={servicesUrl} />}
            />
          )}

          <Route
            element={<RouteRules component={Evaluation} />}
            path={recycleEvaluateRoute}
          />
          <Route
            element={<RouteRules component={Evaluation} />}
            path={recycleEvaluateOrderRoute}
          />

          <Route
            element={<RouteRules component={RecycleOutcomes} />}
            path={recycleOutcomesRoute}
          />
          <Route
            element={<RouteRules component={RecycleOutcomes} />}
            path={recycleOutcomesOrderRoute}
          />

          <Route
            element={<RouteRules component={RenderRecycleSearch} />}
            path={recycleSearchOrderRoute}
          />

          <Route
            element={
              <RouteRules component={OnlineQuoteSearch} className='grey-bg' />
            }
            path={recycleQuoteSearchRoute}
          />

          <Route element={<RouteRules />} path={recycleRoute}>
            <Route element={<PageNotFound withHeader />} path='*' />

            <Route element={<RecycleDetails />} path={recycleDetailsRoute} />
            <Route element={<Recycle />} path={recycleQuotesRoute} />
          </Route>

          <Route
            element={<RouteRules className='grey-bg' />}
            path={reportsRoute}
          >
            <Route element={<PageNotFound withHeader />} path='*' />

            <Route element={<RecycleReports />} path={recycleReportsRoute} />
            <Route element={<Settlements />} path={reportSettlementRoute} />
          </Route>

          <Route
            element={
              <RouteRules
                allowedRoles={[ADMIN, VENDOR_MANAGER]}
                component={ResidualValuesSearch}
                className='grey-bg'
              />
            }
            path={adminResidualValuesSearchRoute}
          />

          <Route
            element={<RouteRules component={Vouchers} className='grey-bg' />}
            path={adminVouchersRoute}
          />

          <Route element={<RouteRules />} path={adminBeginUrl}>
            <Route element={<PageNotFound withHeader />} path='*' />

            <Route
              element={
                <AdminPage
                  allowedRoles={[ADMIN, VENDOR_MANAGER, STORE_MANAGER]}
                  component={Users}
                />
              }
              path={adminUserPageUrl}
            />

            <Route
              element={
                <AdminPage
                  allowedRoles={[ADMIN, VENDOR_MANAGER]}
                  component={Stores}
                />
              }
              path={adminStoresPageUrl}
            />

            <Route
              element={
                <AdminPage
                  allowedRoles={[ADMIN, VENDOR_MANAGER]}
                  component={Devices}
                />
              }
              path={adminDevicesPageUrl}
            />

            <Route
              element={
                <AdminPage
                  allowedRoles={[ADMIN, VENDOR_MANAGER]}
                  component={Accessories}
                />
              }
              path={adminAccessoriesPageUrl}
            />

            <Route
              element={
                <AdminPage
                  allowedRoles={[ADMIN]}
                  component={ContractDateAdjust}
                />
              }
              path={adminContractDateAdjustRoute}
            />

            <Route
              element={
                <AdminPage
                  allowedRoles={[ADMIN, VENDOR_MANAGER]}
                  component={Campaigns}
                />
              }
              path={adminCampaignsRoute}
            />

            <Route
              element={
                <AdminPage
                  allowedRoles={[ADMIN, VENDOR_MANAGER]}
                  component={CampaignDetails}
                />
              }
              path={adminCampaignDetailsRoute}
            />

            <Route
              element={
                <AdminPage
                  allowedRoles={[ADMIN, VENDOR_MANAGER]}
                  component={TemplateEditor}
                />
              }
              path={adminTemplateEditorRoute}
            />

            <Route
              element={
                <AdminPage
                  allowedRoles={[ADMIN, VENDOR_MANAGER]}
                  component={Blocks}
                />
              }
              path={adminTemplateBlocksRoute}
            />

            <Route
              element={
                <AdminPage
                  allowedRoles={[ADMIN, VENDOR_MANAGER]}
                  component={BlockEditor}
                />
              }
              path={adminTemplateBlockEditorRoute}
            />

            <Route
              element={
                <AdminPage allowedRoles={[ADMIN]} component={Attachments} />
              }
              path={adminAttachmentsRoute}
            />

            <Route
              element={<AdminPage component={Collections} />}
              path={adminCollectionsRoute}
            />

            <Route
              element={<AdminPage component={CollectionsOrganise} />}
              path={adminCollectionsOrganiseRoute}
            />

            <Route
              element={<AdminPage component={CollectionDetails} />}
              path={adminCollectionRoute}
            />

            <Route
              element={
                <AdminPage
                  allowedRoles={ADMIN_WHITELIST}
                  component={TestContracts}
                />
              }
              path={adminTestContractsRoute}
            />

            <Route
              element={
                <AdminPage
                  allowedRoles={ADMIN_WHITELIST}
                  component={TestContractDetails}
                />
              }
              path={adminTestContractMessagesRoute}
            />

            <Route
              element={
                <AdminPage
                  allowedRoles={[ADMIN, VENDOR_MANAGER]}
                  component={PricingTool}
                />
              }
              path={adminPricingToolRoute}
            />
          </Route>
        </Route>
      </Routes>
    </>
  );
};

export default Router;
