const trackEvent = (eventName, eventParams = {}) => {
  if (typeof window.gtag === 'function') {
    window.gtag('event', eventName, { ...eventParams });
  }
};

export const trackPageView = (pageTitle, pagePath, pageLocation) =>
  trackEvent('page_view', {
    page_title: pageTitle,
    page_path: pagePath,
    page_location: pageLocation,
  });
