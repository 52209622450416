import classNames from 'classnames';

import React from 'react';

import './RadioButtons.styl';

const RadioButtons = ({
  classes,
  disabled,
  onClick,
  options = [],
  orientation = 'horizontal',
  selectedValue,
  size = 'medium',
  variant = '',
  wrap = false,
  testSelector,
}) => {
  const renderOption = (option) => {
    const clickHandler = () =>
      !disabled && onClick({ value: option.value, label: option.label });

    return (
      <li
        key={`li-radio-${option.key || option.value}`}
        onClick={clickHandler}
        className={classNames({
          selected: selectedValue === option.value,
          disabled: disabled,
        })}
        data-test={testSelector}
      >
        <input
          type='radio'
          name={option.value.toString()}
          value={option.value}
          checked={selectedValue === option.value}
          readOnly
        />
        <span className='checkmark' />
        <div className='labelContainer'>
          <label>{option.label}</label>
          {option.helper && <label className='italic'>{option.helper}</label>}
        </div>
      </li>
    );
  };

  return (
    <ul
      className={classNames(
        'radio-buttons',
        orientation,
        size,
        variant,
        { wrap: wrap },
        classes,
      )}
    >
      {options.map(renderOption)}
    </ul>
  );
};

export default RadioButtons;
