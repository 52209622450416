import { adminPost as post } from 'apis/v2';

export const CHANGE_CONTRACT_TO_SWAP = 'CHANGE_CONTRACT_TO_SWAP';
export function changeContractToSwapAction(uuids, swapStatus) {
  return async function () {
    const response = await post(CHANGE_CONTRACT_TO_SWAP, {
      uuids,
      swapStatus,
    });

    return response.data.status === 'SUCCESS';
  };
}
