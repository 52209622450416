import {
  clearDashboardData,
  saveSocketConfig,
  saveSocketMessage,
  saveSocketStatus,
} from 'actions/monitor';
import queryString from 'query-string';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import DashboardHeader from 'components/DashboardHeader';
import Dropdown from 'components/Dropdown';
import WebSocket from 'components/WebSocket';

import './ServiceMonitor.styl';
import JVMDetailsTable from './components/JVMDetailsTable';
import ServiceTable from './components/ServiceTable';
import ThreadPoolTable from './components/ThreadPoolTable';

const monitorOptions = JSON.parse(process.env.REACT_APP_MONITORS);
const [defaultMonitor] = monitorOptions;

class ServiceMonitor extends Component {
  state = {
    date: '',
    uid: '',
    endpoint: defaultMonitor.value,
  };

  componentDidMount() {
    const { saveSocketConfig, sessionToken } = this.props;
    const values = queryString.parse(window.location.search);
    const { date, uid } = this.state;

    this.setState({
      date: this.formatDate(values.date),
      uid: values.uid || `SESSION_${sessionToken}`,
    });

    if (uid && date) saveSocketConfig(uid, date);
  }

  componentDidUpdate(_, prevState) {
    const values = queryString.parse(window.location.search);
    const { date, uid, endpoint } = this.state;

    if (
      values.date &&
      values.uid &&
      (prevState.date !== values.date || prevState.uid !== values.uid)
    ) {
      this.setState({
        date: this.formatDate(values.date),
        uid: values.uid,
        websocket: (
          <WebSocket
            url={`${endpoint}/${uid}/${date}`}
            onMessage={this.handleData}
            onOpen={this.handleOpen}
            onClose={this.handleClose}
            reconnect={true}
            debug={true}
          />
        ),
      });

      this.props.saveSocketConfig(uid, date);
    }
  }

  formatDate = (date = '') => {
    if (date.length !== 10) {
      return new Date().toISOString().substring(0, 10);
    }

    return date;
  };

  handleData = (message) => {
    if (message.length === 0) return;

    try {
      this.props.saveSocketMessage(JSON.parse(message));
      this.refWebSocket.sendMessage('OK');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Malformed message received: Error:' + error);
    }
  };

  handleOpen = (websocket) => {
    this.props.clearDashboardData();
    this.props.saveSocketStatus('Connected');
    websocket.send('MONITOR');
  };

  handleClose = () => {
    this.props.saveSocketStatus('Disconnected');
  };

  render() {
    const { socketStatus, services, jvmDetails, threadPoolMonitorData } =
      this.props;
    const { date, uid, endpoint } = this.state;

    return (
      <div className='dashboard'>
        <Dropdown
          input={{
            value: endpoint,
            onFocus: () => {},
            onBlur: () => {},
            onChange: (e) => {
              this.setState({
                endpoint: e,
              });
            },
          }}
          options={monitorOptions}
          meta={{
            active: false,
            error: undefined,
            touched: false,
          }}
        />
        {uid && date && endpoint && (
          <WebSocket
            url={`${endpoint}/${uid}/${date}`}
            onMessage={this.handleData}
            onOpen={this.handleOpen}
            onClose={this.handleClose}
            reconnect={true}
            debug={true}
            ref={(Websocket) => {
              this.refWebSocket = Websocket;
            }}
          />
        )}

        <DashboardHeader socketStatus={socketStatus} />
        <a name='jvmDetails'>
          <JVMDetailsTable
            item={jvmDetails}
            title='JVM Details'
            emptyText='No JVM Details'
          />
        </a>
        <a name='threadPoolMonitorData'>
          <ThreadPoolTable
            item={threadPoolMonitorData}
            title='Thread Pool Data'
            emptyText='No Thread Pool Data'
          />
        </a>
        <a name='services'>
          <ServiceTable
            items={services}
            title='Services'
            emptyText='No Services'
          />
        </a>
      </div>
    );
  }
}

ServiceMonitor.defaultProps = {
  services: [],
  threadPoolMonitorData: '',
  jvmDetails: '',
  response: '',
  socketStatus: 'Disconnected',
  items: [],
  uid: '',
  date: new Date().toISOString().substring(0, 10),
  today: new Date().toISOString().substring(0, 10),
};

export default connect(
  ({ dashboard, user }) => ({
    ...dashboard,
    sessionToken: user.session_token,
  }),
  {
    saveSocketStatus,
    saveSocketMessage,
    saveSocketConfig,
    clearDashboardData,
  },
)(ServiceMonitor);
