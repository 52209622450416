import classNames from 'classnames';
import PropTypes from 'prop-types';

import React, { useState } from 'react';

import InputHelperText from 'components/InputHelperText';

import './FileInput.styl';

const WRAPPER_CLASS = 'file-input__wrapper';
const CONTAINER_CLASS = 'file-input__container';
const LABEL_CLASS = 'file-input__floating-label';
const INPUT_CLASS = 'file-input__input';
const VALUE_CLASS = 'file-input__value';

const variants = {
  underline: `${CONTAINER_CLASS}--underline`,
};

// TODO: this component needs fixing for large size version
const sizes = {
  medium: `${CONTAINER_CLASS}--medium-height`,
  large: `${CONTAINER_CLASS}--large-height`,
};

export const FileInput = ({
  className,
  disabled = false,
  input: { name, onChange } = {},
  helpText = '',
  notificationPos = 'bottom',
  meta: { active, dirty, error, touched } = {},
  placeholder = '',
  size = 'medium',
  testSelector,
  variant = '',
}) => {
  const showError = (active || touched || dirty) && error?.length > 0;
  const [filename, setFilename] = useState('');

  const changeHandler = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    setFilename(file?.name);
    onChange(file);
  };

  return (
    <div
      className={classNames(WRAPPER_CLASS, className)}
      data-test={testSelector}
    >
      <div
        className={classNames(CONTAINER_CLASS, sizes[size], variants[variant], {
          [`${CONTAINER_CLASS}--focused`]: active,
          [`${CONTAINER_CLASS}--disabled`]: disabled,
          [`${CONTAINER_CLASS}--error`]: showError,
        })}
      >
        {filename && <span className={VALUE_CLASS}>{filename}</span>}
        <input
          className={INPUT_CLASS}
          disabled={disabled}
          id={name}
          name={name}
          onChange={changeHandler}
          type='file'
        />

        <label
          htmlFor={name}
          className={classNames(LABEL_CLASS, {
            [`${LABEL_CLASS}--active`]: active || filename,
          })}
        >
          {placeholder}
        </label>
      </div>
      <InputHelperText
        showError={showError}
        helpText={helpText}
        errorText={error}
        notificationPos={notificationPos}
        inputName={placeholder}
      />
    </div>
  );
};

FileInput.propTypes = {
  classeName: PropTypes.string,
  disabled: PropTypes.bool,
  input: PropTypes.object.isRequired,
  helpText: PropTypes.string,
  meta: PropTypes.shape({
    active: PropTypes.bool,
    error: PropTypes.string,
    touched: PropTypes.bool,
  }).isRequired,
  notificationPos: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  testSelector: PropTypes.string,
  variant: PropTypes.oneOf(['', 'underline']),
};

export default FileInput;
