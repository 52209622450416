import { addExtraAction } from 'actions/carts';

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import List from 'components/deprecated_list/DeprecatedList';

import { extraTypes } from 'constants/extras';

class ProductExtrasCatalogList extends Component {
  render() {
    const {
      cartUUID,
      currency,
      language,
      keyValue,
      data,
      addExtraCallback,
      addExtra,
    } = this.props;

    return (
      <Fragment key={keyValue}>
        <h2 className='extras-list-title'>
          {extraTypes()[keyValue] || keyValue}
        </h2>

        <List
          type='extra'
          items={data}
          currency={currency}
          language={language}
          // TODO make this component reliant only on class functions
          // in order to remove arrow functions and enforce this rule
          // eslint-disable-next-line react/jsx-no-bind
          handleClick={async (extraUUID) =>
            await addExtra(cartUUID, extraUUID, addExtraCallback)
          }
          classes='extras'
          highlight
        />
      </Fragment>
    );
  }
}

ProductExtrasCatalogList = connect(
  ({ whitelabel }) => ({
    currency: whitelabel.currency,
    language: whitelabel.language,
  }),
  { addExtra: addExtraAction },
)(ProductExtrasCatalogList);

export default ProductExtrasCatalogList;
