import {
  SEARCH_TYPE_EMAIL,
  SEARCH_TYPE_NAME,
  SEARCH_TYPE_TRADECART,
  addDevice,
  clearRecycleAction,
  getCriteriaAction,
  getOnlineTradeinQuote,
  searchOnlineTradeinQuotes,
  setRecycleTypeAction,
} from 'actions/recycle';
import { currencyFormatter, dateFormatter } from 'helpers/formatters';
import { length, required } from 'helpers/validators';
import isEmpty from 'lodash/isEmpty';
import { Field, reduxForm } from 'redux-form';

import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';

import Button from 'components/Button';
import Footer from 'components/Footer';
import RenderField from 'components/RenderField';
import Section from 'components/Section';
import Table from 'components/table/Table';

import { API_POSALE_SERVICE } from 'constants/api';
import { EMAIL_REGEX, TRADECART_ID_REGEX } from 'constants/recycle';
import { recycleEvaluateUrl, recycleSearchUrl } from 'constants/routes';

import useTrackPage from 'hooks/useTrackPage';

import './OnlineQuoteSearch.styl';

export const OnlineQuoteSearch = ({
  disabled = false,
  handleSubmit,
  invalid,
  submitting,
}) => {
  useTrackPage('RecycleOnlineQuoteSearch');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { currency, language, configs } = useSelector(
    ({ whitelabel }) => whitelabel,
  );

  const [quotes, setQuotes] = useState([]);
  const [searchTypeDesc, setSearchTypeDesc] = useState('');
  const [searchTextVal, setSearchTextVal] = useState('');

  useEffect(() => {
    dispatch(clearRecycleAction());
    dispatch(setRecycleTypeAction({ recycleType: 'TRADE_IN' }));
  }, [dispatch]);

  const handleDeviceSearch = async ({ searchText }) => {
    setQuotes([]);
    setSearchTypeDesc('');
    setSearchTextVal('');

    const newSearchTextVal = searchText.trim();
    let newSearchType = SEARCH_TYPE_NAME;
    let newSearchTypeDesc = t({
      id: 'online.quote.search.type.desc.name',
      message: `Customer Name`,
    });
    if (TRADECART_ID_REGEX.test(newSearchTextVal)) {
      newSearchType = SEARCH_TYPE_TRADECART;
      newSearchTypeDesc = t({
        id: 'online.quote.search.type.desc.quote.id',
        message: `Quote ID`,
      });
    } else if (EMAIL_REGEX.test(newSearchTextVal)) {
      newSearchType = SEARCH_TYPE_EMAIL;
      newSearchTypeDesc = t({
        id: 'online.quote.search.type.desc.email',
        message: `Customer Email`,
      });
    }

    const { status, data } = await dispatch(
      searchOnlineTradeinQuotes(newSearchTextVal, newSearchType),
    );

    if (status === 'SUCCESS' && !isEmpty(data)) {
      if (data.quotes.length === 1) {
        await selectQuote(data.quotes[0].tradecartSID);
      } else {
        setQuotes(data?.quotes ?? []);
        setSearchTypeDesc(newSearchTypeDesc ?? '');
        setSearchTextVal(newSearchTextVal ?? '');
      }
    }
  };

  const selectQuote = async (tradecartSID) => {
    const { data, status } = await dispatch(
      getOnlineTradeinQuote(tradecartSID),
    );
    if (status === 'SUCCESS' && !isEmpty(data)) {
      if (configs.hasPosaleMultiDeviceTradein) {
        const questionsTree = await dispatch(
          getCriteriaAction(
            {
              selectedDevice: {
                ...data.devices[0],
                tradecartID: data.tradecartID,
              },
            },
            API_POSALE_SERVICE,
          ),
        );

        dispatch(
          addDevice({
            ...data.devices[0],
            questionsTree,
          }),
        );

        navigate(recycleSearchUrl);
      } else {
        await dispatch(
          getCriteriaAction(
            {
              selectedDevice: {
                ...data.devices[0],
                tradecartID: data.tradecartID,
              },
            },
            API_POSALE_SERVICE,
          ),
        );

        navigate(recycleEvaluateUrl);
      }
    }
  };

  const handleItemClick = async (_, item) => {
    selectQuote(item.tradecartSID);
  };

  const columns = [
    {
      label: t({
        id: 'table.column.online.tradein.quote.id',
        message: `Quote ID`,
      }),
      accessor: 'tradecartSID',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.online.tradein.customer',
        message: `Customer`,
      }),
      accessor: 'customer',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.online.tradein.device', message: `Device` }),
      accessor: 'device',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.online.tradein.device.expires',
        message: `Expires`,
      }),
      accessor: 'expires',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.actions', message: `Actions` }),
      accessor: 'actions',
      type: 'actions',
      filterable: false,
      sortable: false,
      align: 'center',
      wrap: false,
    },
  ];

  const actions = [
    {
      label: t({ id: 'collection.action.details', message: `Details` }),
      handler: handleItemClick,
    },
  ];

  const rows = quotes.reduce((all, curr) => {
    const now = Date.now();
    return [
      ...all,
      ...curr.items?.map((item) => ({
        actions: now >= Date.parse(curr.expireDate) ? [] : actions,
        customer: `${curr.customerName}\n${curr.customerEmail}`,
        device: `${item.deviceName}\ngrade: ${
          item.grade
        } value: ${currencyFormatter(item.value, currency, language)}`,
        expires:
          now >= Date.parse(curr.expireDate)
            ? t({ id: 'online.tradein.quote.expired', message: `Expired` })
            : dateFormatter(curr.expireDate, language),
        tradecartSID: curr.tradecartSID,
      })),
    ];
  }, []);

  return (
    <Fragment>
      <h1 className='admin-page-title'>
        <Trans id='recycle.online.quotes.title'>
          Online trade in quote search
        </Trans>
      </h1>
      <Section>
        <form
          className='quote-search-form'
          name='onlineQuoteSearchForm'
          disabled={disabled}
          onSubmit={handleSubmit(handleDeviceSearch)}
        >
          <Field
            testSelector='online-quote-search'
            name='searchText'
            type='text'
            component={RenderField}
            label={t({
              id: 'online.quote.search.search.text',
              message: `Quote ID, Customer Email or Customer Name`,
            })}
            validate={[required(), length({ min: 3 })]}
          />

          <Button
            testSelector='submit-search-button'
            type='submit'
            disabled={invalid || submitting || disabled}
            loading={submitting}
          >
            <Trans id='reports.form.search.button'>Search</Trans>
          </Button>
        </form>
      </Section>

      {searchTypeDesc.length > 0 && searchTextVal.length > 0 && (
        <Section>
          <h2>
            <Trans id='online.tradein.quotes.results.title'>
              Searched for {searchTypeDesc} using "{searchTextVal}"
            </Trans>
          </h2>
          {quotes.length > 0 ? (
            <Table
              classes='quote-search-results-table'
              columns={columns}
              data={rows}
              pageable={false}
              filterable={false}
            />
          ) : (
            <p>
              <Trans id='online.tradein.empty.search.results'>
                No quotes were found that satisfied your search.
              </Trans>
            </p>
          )}
        </Section>
      )}
      <Footer classes='main' copyright={false} />
    </Fragment>
  );
};

export default reduxForm({
  form: 'onlineQuoteSearchForm',
  enableReinitialize: true,
  destroyOnUnmount: true,
  initialValues: { searchText: '' },
})(OnlineQuoteSearch);
