import classNames from 'classnames';
import PropTypes from 'prop-types';

import React from 'react';

import './Badges.styl';

const Badges = ({ badges }) => {
  const items = Array.isArray(badges) ? badges : [badges];
  return (
    <div className='badges'>
      {items.map((item, idx) => (
        <span
          className={classNames('badge', item.classes)}
          data-test={item.dataTest}
          key={`badge-${idx}`}
        >
          {item.icon}
          {item.label && <span>{item.label}</span>}
        </span>
      ))}
    </div>
  );
};

const badgeShape = PropTypes.shape({
  classes: PropTypes.string,
  dataTest: PropTypes.string,
  icon: PropTypes.element, // SVG imported as react elements
  label: PropTypes.string,
});

Badges.propTypes = {
  badges: PropTypes.oneOfType([badgeShape, PropTypes.arrayOf(badgeShape)]),
};

export default Badges;
