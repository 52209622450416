import {
  renderEmailAction,
  sendEmailAction,
  sendSMSAction,
} from 'actions/admin/templates';
import { sanitize } from 'dompurify';
import { phoneValidator } from 'helpers/validators';
import { email as emailValidation } from 'helpers/validators';
import isEmpty from 'lodash/isEmpty';
import { getFormSyncErrors, getFormValues } from 'redux-form';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { t } from '@lingui/macro';

import Form from 'components/Form';
import Input from 'components/Input';

export const TemplatePreview = ({ campaignCode, template = {} }) => {
  const [parseTemplate, setParseTemplate] = useState(false);

  const dispatch = useDispatch();

  const emailBlocks = useSelector(
    ({ adminTemplates }) => adminTemplates?.blocks,
  );

  const parsedTemplate = useSelector(
    ({ adminTemplates }) => adminTemplates?.parsedTemplate,
  );

  const smsFormValues = useSelector((state) =>
    getFormValues('sendSMSForm')(state),
  );

  const emailFormValues = useSelector((state) =>
    getFormValues('sendEmailForm')(state),
  );

  const smsFormErrors = useSelector((state) =>
    getFormSyncErrors('sendSMSForm')(state),
  );

  const emailFormErrors = useSelector((state) =>
    getFormSyncErrors('sendEmailForm')(state),
  );

  const sendEmailButton = {
    name: 'sendEmail',
    disabled:
      isEmpty(emailFormValues?.emailAddress) ||
      typeof emailFormErrors?.emailAddress !== 'undefined' ||
      isEmpty(emailFormValues?.contractReference),
    label: t({ id: 'template.editor.test.email', message: `Send test email` }),
    // eslint-disable-next-line react/jsx-no-bind
    handleClick: (formData) =>
      dispatch(sendEmailAction(template?.templateKey, emailFormValues)),
  };

  const sendSMSButton = {
    name: 'sendSMS',
    label: t({ id: 'template.editor.test.sms', message: `Send test SMS` }),
    disabled:
      isEmpty(smsFormValues?.phone) ||
      typeof smsFormErrors?.phone !== 'undefined',
    // eslint-disable-next-line react/jsx-no-bind
    handleClick: (formData) =>
      dispatch(sendSMSAction(template?.templateKey, smsFormValues)),
  };

  const testWithContractButton = {
    name: 'testWithContract',
    label: t({
      id: 'template.editor.test.data',
      message: `Test with contract reference`,
    }),
    disabled:
      template?.templateType === 'EMAIL'
        ? isEmpty(emailFormValues?.contractReference)
        : isEmpty(smsFormValues?.contractReference),
    handleClick: async () => {
      await dispatch(
        renderEmailAction({
          campaignCode,
          contractReference:
            template?.templateType === 'EMAIL'
              ? emailFormValues?.contractReference
              : smsFormValues?.contractReference,
          subject: template?.subject,
          htmlPart: template?.htmlPart,
          templateName: template?.templateName,
        }),
      );

      setParseTemplate(true);
    },
  };

  const showPlaceholdersButton = {
    name: 'showPlaceholders',
    label: t({
      id: 'template.editor.show.placeholders',
      message: `Show data placeholders`,
    }),
    handleClick: () => setParseTemplate(false),
  };

  const formButtons = [
    template?.templateType === 'SMS' ? sendSMSButton : sendEmailButton,
    parseTemplate ? showPlaceholdersButton : testWithContractButton,
  ];

  const substituteBlocks = (text, blocks) => {
    return text?.replace(/\[\[\s*[\w.]+\s*\]\]/g, (textKey) =>
      blocks?.find(({ key }) => key === textKey.substr(2, textKey.length - 4))
        ? blocks?.find(
            ({ key }) => key === textKey.substr(2, textKey.length - 4),
          ).body
        : `<span class='error'>${textKey}</span>`,
    );
  };

  const markMissingPlaceholders = (text) => {
    return text?.replace(
      /{{\s*[\w.]+\s*}}/g,
      (key) => `<span class='error'>${key}</span>`,
    );
  };

  return (
    <div className='templatePreview'>
      {template?.templateType === 'EMAIL' && (
        <iframe
          title='subjectPreviewArea'
          className='subjectPreviewArea'
          srcDoc={
            parseTemplate
              ? sanitize(markMissingPlaceholders(parsedTemplate?.subject), {
                  KEEP_CONTENT: false,
                })
              : sanitize(template?.subject, {
                  KEEP_CONTENT: false,
                })
          }
        />
      )}

      <iframe
        title='bodyPreviewArea'
        className='bodyPreviewArea'
        srcDoc={
          parseTemplate
            ? sanitize(markMissingPlaceholders(parsedTemplate?.htmlPart), {
                KEEP_CONTENT: false,
              })
            : sanitize(substituteBlocks(template?.htmlPart, emailBlocks), {
                KEEP_CONTENT: false,
              })
        }
      />

      {template?.templateType === 'EMAIL' && (
        <Form
          form='sendEmailForm'
          classes='sendEmailForm'
          customFieldValidation
          fields={[
            {
              component: Input,
              placeholder: t({
                id: 'forms.address.placeholder.email',
                message: `Email Address`,
              }),
              name: 'emailAddress',
              size: 'medium',
              width: 'half',
              validate: [emailValidation()],
            },
            {
              component: Input,
              placeholder: t({
                id: 'forms.address.placeholder.contract.reference',
                message: `Contract Reference`,
              }),
              name: 'contractReference',
              size: 'medium',
              width: 'half',
              normalize: (value) => value.trim(),
            },
          ]}
          actionButtons={formButtons}
        />
      )}

      {template?.templateType === 'SMS' && (
        <Form
          form='sendSMSForm'
          classes='sendSMSForm'
          customFieldValidation
          fields={[
            {
              component: Input,
              placeholder: t({
                id: 'forms.address.placeholder.phone',
                message: `Phone Number`,
              }),
              name: 'phone',
              size: 'medium',
              width: 'half',
              validate: [phoneValidator()],
            },
            {
              component: Input,
              placeholder: t({
                id: 'forms.address.placeholder.contract.reference',
                message: `Contract Reference`,
              }),
              name: 'contractReference',
              size: 'medium',
              width: 'half',
              normalize: (value) => value.trim(),
            },
          ]}
          actionButtons={formButtons}
        />
      )}
    </div>
  );
};

export default TemplatePreview;
