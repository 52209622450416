import classNames from 'classnames';
import { currencyFormatter } from 'helpers/formatters';
import { textToKebab } from 'utils';

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import Badges from 'components/Badges';
import Button from 'components/Button';
import { ImageLarge } from 'components/Image';

class ProductItems extends Component {
  render() {
    const {
      currency,
      language,
      data,
      selectedDeviceCode,
      canCustomize,
      manualCTOCategories,
      category,
      selectProduct,
    } = this.props;

    let badges = [];
    if (
      (canCustomize === 'CATALOG' && data.has_CTOs) ||
      (canCustomize === 'MANUAL' && manualCTOCategories.includes(category))
    ) {
      badges.push({
        label: t({
          id: 'products.customizable.badge',
          message: `Customisable`,
        }),
        classes: '',
      });
    }
    if (data.hasPromotions) {
      badges.push({
        label: t({
          id: 'products.promotion.badge',
          message: `Promotion available`,
        }),
        classes: 'badge--promotion',
      });
    }

    return (
      <Fragment key={data.device_id}>
        <li
          key={data.device_id}
          className={classNames('product-item', {
            selected: data.device_code === selectedDeviceCode,
          })}
          // TODO make this component reliant only on class functions
          // in order to remove arrow functions and enforce this rule
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => selectProduct(data)}
        >
          <Badges badges={badges} />
          <div className='item-img-wrapper'>
            <ImageLarge src={data.device_url} alt={data.device_name} />
          </div>

          <section>
            <p className='big'>{data.device_name}</p>
            <p>
              <Trans id='product.price.from.label'>from</Trans>
            </p>
            <p className='big'>
              {currencyFormatter(data.monthly_price, currency, language)} /{' '}
              <Trans
                className='monthly-price-extension'
                id='product.price.permonth.label'
              >
                per month
              </Trans>
            </p>
          </section>

          <Button testSelector={`product-${textToKebab(data.device_name)}`}>
            {data.device_code === selectedDeviceCode ? (
              <Trans id='products.selected.button'>Selected</Trans>
            ) : (
              <Trans id='products.select.button'>Select</Trans>
            )}
          </Button>
        </li>
      </Fragment>
    );
  }
}

ProductItems = connect(({ whitelabel }) => ({
  currency: whitelabel.currency,
  language: whitelabel.language,
  canCustomize: whitelabel.configs.canCustomize,
  manualCTOCategories: whitelabel.configs.manualCTOCategories,
}))(ProductItems);

export default ProductItems;
