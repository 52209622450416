import React from 'react';

import { Trans, t } from '@lingui/macro';

import Table from 'components/table/Table';

import './Invoice.styl';

const Invoice = ({ invoice = [] }) => {
  const columns = [
    {
      label: t({
        id: 'table.column.invoice.delivery',
        message: `Delivery No.`,
      }),
      accessor: 'deliveryNumber',
      type: 'text',
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.invoice.orderReference',
        message: `Order Ref.`,
      }),
      accessor: 'orderReference',
      type: 'text',
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.invoice.currency', message: `Currency` }),
      accessor: 'currencyCode',
      type: 'text',
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.invoice.price',
        message: `Price val. (incl. tax)`,
      }),
      accessor: 'invoiceAmountInclTax',
      type: 'text',
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.invoice.tax',
        message: `Tax val.`,
      }),
      accessor: 'taxAmount',
      type: 'text',
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.invoice.created',
        message: `Received`,
      }),
      accessor: 'createdAt',
      type: 'dateTime',
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.invoice.item',
        message: `Item`,
      }),
      accessor: 'description',
      type: 'text',
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.invoice.partNumber',
        message: `Part No.`,
      }),
      accessor: 'partNumber',
      type: 'text',
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.invoice.serialNumber',
        message: `Serial No.`,
      }),
      accessor: 'serialNumbers',
      type: 'text',
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.invoice.quantity',
        message: `Quantity`,
      }),
      accessor: 'quantity',
      type: 'text',
      align: 'center',
      wrap: false,
    },
  ];

  const rows = invoice?.items.map((item) => ({
    ...invoice,
    ...item,
  }));

  return (
    <div className='invoice-container'>
      <div className='invoice-info-container'>
        <div
          className='invoice-info-container__title'
          data-test='posale-contract-invoice-number'
        >
          {invoice.invoiceType === 'CREDIT_NOTE' ? (
            <Trans id='sale.creditnote.title'>Credit Note</Trans>
          ) : (
            <Trans id='sale.invoice.title'>Invoice</Trans>
          )}
          {' ' + invoice.invoiceNumber}
        </div>
        <div className='invoice-info-container__entities'>
          <div>
            <span data-test='posale-contract-invoice-by'>
              <Trans id='sale.invoiced.by'>
                Invoiced by: {invoice.invoicedBy}
              </Trans>
            </span>
            {' | '}
            <span data-test='posale-contract-invoice-to'>
              <Trans id='sale.invoiced.to'>
                Invoiced to: {invoice.invoicedTo}
              </Trans>
            </span>
          </div>
        </div>
      </div>

      <Table
        columns={columns}
        data={rows}
        keyColumn='id'
        defaultOrder='createdAt'
        defaultDir='desc'
        filterable={false}
        pageable={false}
        testSelector='posale-contract'
      />
    </div>
  );
};

export default Invoice;
