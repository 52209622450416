import { addNotificationAction } from 'actions/ui';
import magIcon from 'assets/images/mag-white.svg';
import addDays from 'date-fns/addDays';
import { required } from 'helpers/validators';
import { change } from 'redux-form';
import { createDevicesFilter, generateDevicesFilterHierarchy } from 'utils';

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { t } from '@lingui/macro';

import Dropdown from 'components/Dropdown';
import Form from 'components/Form';
import RenderDatePicker, { formatDate } from 'components/RenderDatePicker';

import { invalidPricingToolData } from 'constants/notifications';

import './DeviceFiltersForm.styl';

const DeviceFiltersForm = ({
  changeForm,
  devices,
  filter,
  actionButtons,
  requireDevice = true,
  addNotification,
  submitData,
}) => {
  const [deviceTree, setDevices] = useState(false);

  useEffect(() => {
    if (devices.length > 0) {
      if (devices.some((device) => !device)) {
        addNotification(invalidPricingToolData());
      } else {
        const parsedDevices = generateDevicesFilterHierarchy(
          devices.map((device) => device),
          [
            createDevicesFilter('manufacturer', 'manufacturerName'),
            createDevicesFilter('category', 'categoryName'),
            createDevicesFilter('family', 'familyName'),
            createDevicesFilter('deviceCode', 'name'),
          ],
        );
        setDevices(parsedDevices);
      }
    }
  }, [devices, addNotification]);

  const defaultValues = {
    selectedManufacturer: null,
    selectedCategory: null,
    selectedFamily: null,
    selectedDevice: null,
    selectedDate: null,
  };

  const {
    selectedManufacturer,
    selectedCategory,
    selectedFamily,
    selectedDevice,
    selectedDate,
  } = filter?.values || defaultValues;

  const formItem = (
    component,
    options,
    placeholder,
    name,
    disabled,
    classes,
    onChange,
  ) => ({
    component,
    options,
    placeholder,
    name,
    disabled,
    classes,
    onChange,
    clearable: true,
    validate: requireDevice ? [required()] : undefined,
  });

  const handleSubmit = () => {
    const device = devices.find(
      (device) => device.deviceCode === selectedDevice,
    );

    const filterValues = {
      selectedManufacturer,
      selectedCategory,
      selectedFamily,
      selectedDevice,
      selectedDate,
    };

    submitData({ device, filterValues });
  };

  return (
    <Fragment>
      {deviceTree && (
        <Form
          form='deviceFilters'
          classes='device-filters-form'
          destroyOnUnmount={true}
          fields={[
            formItem(
              Dropdown,
              Object.keys(deviceTree)?.map((mnf) => ({
                label: deviceTree[mnf].label,
                value: deviceTree[mnf].value,
              })),
              t({
                id: 'device.filters.form.manufacturer',
                message: `Manufacturer`,
              }),
              'selectedManufacturer',
              false,
              'device-filters-form__manufacturer-filter',
              () => {
                changeForm('selectedCategory', null);
                changeForm('selectedDevice', null);
                changeForm('selectedFamily', null);
              },
            ),
            formItem(
              Dropdown,
              (selectedManufacturer
                ? Object.keys(deviceTree?.[selectedManufacturer]?.children)
                : []
              ).map((ctg) => ({
                label: deviceTree[selectedManufacturer].children[ctg].label,
                value: deviceTree[selectedManufacturer].children[ctg].value,
              })),
              t({ id: 'device.filters.form.category', message: `Category` }),
              'selectedCategory',
              !selectedManufacturer,
              'device-filters-form__category-filter',
              () => {
                changeForm('selectedDevice', null);
                changeForm('selectedFamily', null);
              },
            ),
            formItem(
              Dropdown,
              (selectedManufacturer && selectedCategory
                ? Object.keys(
                    deviceTree?.[selectedManufacturer]?.children[
                      selectedCategory
                    ].children,
                  )
                : []
              ).map((fml) => ({
                label:
                  deviceTree[selectedManufacturer].children[selectedCategory]
                    .children[fml].label,
                value:
                  deviceTree[selectedManufacturer].children[selectedCategory]
                    .children[fml].value,
              })),
              t({ id: 'device.filters.form.family', message: `Family` }),
              'selectedFamily',
              !selectedManufacturer || !selectedCategory,
              'device-filters-form__family-filter',
              () => {
                changeForm('selectedDevice', null);
              },
            ),
            formItem(
              Dropdown,
              (selectedManufacturer && selectedCategory && selectedFamily
                ? Object.keys(
                    deviceTree?.[selectedManufacturer]?.children[
                      selectedCategory
                    ]?.children[selectedFamily].children,
                  )
                : []
              ).map((dvc) => ({
                label:
                  deviceTree[selectedManufacturer].children[selectedCategory]
                    .children[selectedFamily].children[dvc].label,
                value:
                  deviceTree[selectedManufacturer].children[selectedCategory]
                    .children[selectedFamily].children[dvc].value,
              })),
              t({ id: 'device.filters.form.device', message: `Device` }),
              'selectedDevice',
              !selectedManufacturer || !selectedCategory || !selectedFamily,
              'device-filters-form__device-filter',
            ),
            {
              component: RenderDatePicker,
              label: t({ id: 'device.filters.form.date', message: `Date` }),
              name: 'selectedDate',
              format: formatDate,
              parse: formatDate,
              disabled: requireDevice
                ? !selectedManufacturer ||
                  !selectedCategory ||
                  !selectedFamily ||
                  !selectedDevice
                : false,
              validate: [required()],
              variant: 'full-border',
              minDate: new Date().toString(),
              maxDate: addDays(new Date(), 364),
              classes: 'device-filters-form__date-filter',
            },
          ]}
          // TODO make this component reliant only on hooks
          // in order to remove arrow functions and enforce this rule
          // eslint-disable-next-line react/jsx-no-bind
          actionButtons={actionButtons}
          handleSubmit={handleSubmit}
          submitLabel={
            <Fragment>
              <span className='form-search-text'>
                {t({
                  id: 'device.filters.form.button.results',
                  message: `Show results`,
                })}
              </span>
              <img src={magIcon} alt='Magnifier' className='form-search-icon' />
            </Fragment>
          }
        />
      )}
    </Fragment>
  );
};

export default connect(
  ({ form }) => ({
    filter: form.deviceFilters,
  }),
  {
    addNotification: addNotificationAction,
    changeForm: (fieldName, value) => change('deviceFilters', fieldName, value),
  },
)(DeviceFiltersForm);
