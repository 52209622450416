import { capitalize } from 'utils';

import { t } from '@lingui/macro';

const productOptionsTitles = (option) => {
  const titles = {
    COLOR: t({ id: 'product.option.color', message: `Colour` }),
    MEMORY: t({ id: 'product.option.memory', message: `Memory` }),
    SCREEN: t({ id: 'product.option.screen', message: `Screen` }),
    CPU: t({ id: 'product.option.CPU', message: `CPU` }),
    STORAGE: t({ id: 'product.option.storage', message: `Storage` }),
    CONNECTIVITY: t({
      id: 'product.option.connectivity',
      message: `Connectivity`,
    }),
    GRAPHICS: t({ id: 'product.option.graphics', message: `Graphics` }),
    KEYBOARD: t({ id: 'product.option.keyboard', message: `Keyboard` }),
    POWER: t({ id: 'product.option.power', message: `Power` }),
    TOUCHBAR: t({ id: 'product.option.touchbar', message: `Touchbar` }),
    MOUSE: t({ id: 'product.option.mouse', message: `Mouse` }),
    VESAMOUNT: t({ id: 'product.option.vesamount', message: `Vesamount` }),
    ETHERNET: t({ id: 'product.option.ethernet', message: `Ethernet` }),
    SPECS: t({ id: 'product.option.specs', message: `Spec` }),
    ADAPTOR: t({ id: 'product.option.adaptor', message: `Power adapter` }),
    Insurances: t({ id: 'product.option.insurances', message: `Insurances` }),
    APPLE_CARE: t({ id: 'product.option.apple.care', message: `AppleCare` }),
  };
  return titles[option] || capitalize(option);
};

export default productOptionsTitles;
