import { cancelSaleAction, getSaleAction } from 'actions/sales';
import { openModalAction } from 'actions/ui';

import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { Trans } from '@lingui/macro';

import Button from 'components/Button';
import Heading from 'components/Heading';
import Modal from 'components/Modal';

export const CancelContract = ({
  cancelSale,
  cartUUID,
  getSale,
  openModal,
}) => {
  const cancelContract = async () => {
    await cancelSale(cartUUID);

    await getSale(cartUUID);
  };

  const openCancelModal = () => openModal('cancelContract');

  return (
    <Fragment>
      <Button
        onClick={openCancelModal}
        classes='red'
        data-test='contract-action-cancel'
      >
        <Trans id='contracts.actions.cancel.button'>Cancel contracts</Trans>
      </Button>

      <Modal name='cancelContract' onConfirm={cancelContract}>
        <Heading size='medium'>
          <Trans id='contracts.actions.cancel.button'>Cancel contracts</Trans>
        </Heading>

        <p>
          <Trans id='contract.actions.cancel.question'>
            Are you sure you want to cancel both contracts?
          </Trans>
        </p>
      </Modal>
    </Fragment>
  );
};

export default connect(null, {
  cancelSale: cancelSaleAction,
  getSale: getSaleAction,
  openModal: openModalAction,
})(CancelContract);
