import React from 'react';

import { t } from '@lingui/macro';

import Table from 'components/table/Table';

export const CohortRules = ({ selectCohort, rules = [] }) => {
  const columns = [
    {
      label: t({
        id: 'table.column.contract.status',
        message: `Contract Status`,
      }),
      accessor: 'contractStatus',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.financing.type',
        message: `Financing Type`,
      }),
      accessor: 'financingType',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.financing.duration',
        message: `Financing Duration`,
      }),
      accessor: 'financingDuration',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.manufacturer', message: `Manufacturer` }),
      accessor: 'manufacturerName',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.category', message: `Category` }),
      accessor: 'categoryName',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.family', message: `Family` }),
      accessor: 'familyName',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.device', message: `Device` }),
      accessor: 'deviceName',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.cohort.actions', message: `Actions` }),
      accessor: 'actions',
      type: 'actions',
      filterable: false,
      sortable: false,
      align: 'right',
      wrap: false,
    },
  ];

  const actions = [
    {
      label: t({ id: 'table.column.actions.edit', message: `Edit` }),
      handler: async (_, { actions, ...values }) => {
        await selectCohort(values);
        document.getElementById('cohortForm').scrollIntoView({
          block: 'start',
          behavior: 'smooth',
          inline: 'start',
        });
      },
    },
  ];

  const rows = rules?.map((rule) => ({ ...rule, actions }));

  return (
    <Table columns={columns} data={rows} keyColumn='id' filterable={false} />
  );
};

export default CohortRules;
