import get from 'lodash/get';
import { capitalize } from 'utils';

import { t } from '@lingui/macro';

export const ONLINE = 'ONLINE';
export const POSALE = 'POSALE';
export const SWAP = 'SWAP';
export const TRADE_IN = 'TRADE_IN';
export const UPGRADE = 'UPGRADE';
export const STANDARD = 'STANDARD';
export const CARD_PAYMENT = 'CARD_PAYMENT';
export const GIFT_CARD = 'GIFT_CARD';
export const RETURN_DEVICE = 'RETURN_DEVICE';
export const CASH_IN_STORE = 'CASH_IN_STORE';
export const BANK_PAYMENT = 'BANK_PAYMENT';
export const ROLLOVER_CREDIT = 'ROLLOVER_CREDIT';
export const OK_GRADE_STATUS = 'ok';
export const WARNING_GRADE_STATUS = 'warning';
export const ALERT_GRADE_STATUS = 'alert';
export const FINANCING = 'FINANCING';

export const evaluationGradeTitles = (key) =>
  get(
    {
      A: t({
        id: 'evaluation.grade.a.functional.good',
        message: `Fully Functional and Good Condition`,
      }),
      B: t({
        id: 'evaluation.grade.b.functional',
        message: `Fully Functional`,
      }),
      BC: t({ id: 'evaluation.grade.bc.damage', message: `Minor Damage` }),
      C: t({ id: 'evaluation.grade.c.poor', message: `Poor Condition` }),
      W: t({
        id: 'evaluation.grade.w.functional.good',
        message: `Fully Functional and Good Condition`,
      }),
      D: t({ id: 'evaluation.grade.d.damaged', message: `Damaged or Faulty` }),
      NW: t({ id: 'evaluation.grade.nw.non.working', message: `Non-Working` }),
      NWZ: t({
        id: 'evaluation.grade.nwz.non.working.zero',
        message: `Non-Working Zero Value`,
      }),
      IN_AGREED_CONDITION: t({
        id: 'evaluation.grade.in.agreed.condition',
        message: `Is in agreed condition`,
      }),
      NOT_IN_AGREED_CONDITION: t({
        id: 'evaluation.grade.not.in.agreed.condition',
        message: `Is not in agreed condition`,
      }),
    },
    key,
    key,
  );

export const evaluationGradeTitlesList = () => ({
  A: t({
    id: 'evaluation.grade.a.functional.good',
    message: `Fully Functional and Good Condition`,
  }),
  B: t({ id: 'evaluation.grade.b.functional', message: `Fully Functional` }),
  BC: t({ id: 'evaluation.grade.bc.damage', message: `Minor Damage` }),
  C: t({ id: 'evaluation.grade.c.poor', message: `Poor Condition` }),
  W: t({
    id: 'evaluation.grade.w.functional.good',
    message: `Fully Functional and Good Condition`,
  }),
  D: t({ id: 'evaluation.grade.d.damaged', message: `Damaged or Faulty` }),
  NW: t({ id: 'evaluation.grade.nw.non.working', message: `Non-Working` }),
  NWZ: t({
    id: 'evaluation.grade.nwz.non.working.zero',
    message: `Non-Working Zero Value`,
  }),
  IN_AGREED_CONDITION: t({
    id: 'evaluation.grade.in.agreed.condition',
    message: `Is in agreed condition`,
  }),
  NOT_IN_AGREED_CONDITION: t({
    id: 'evaluation.grade.not.in.agreed.condition',
    message: `Is not in agreed condition`,
  }),
});

export const SIM_STATUS_LOCKED = 'LOCKED';
export const SIM_STATUS_UNLOCKED = 'UNLOCKED';
export const NONE = 'none';
export const networkStatus = [SIM_STATUS_LOCKED, SIM_STATUS_UNLOCKED];

export const simStatusLabels = (key) =>
  get(
    {
      [SIM_STATUS_LOCKED]: t({
        id: 'recycle.network.locked',
        message: `Locked`,
      }),
      [SIM_STATUS_UNLOCKED]: t({
        id: 'recycle.network.unlocked',
        message: `Unlocked`,
      }),
    },
    key,
    key,
  );

export const simStatusLabelsList = () => ({
  [capitalize(SIM_STATUS_LOCKED)]: t({
    id: 'recycle.network.locked',
    message: `Locked`,
  }),
  [capitalize(SIM_STATUS_UNLOCKED)]: t({
    id: 'recycle.network.unlocked',
    message: `Unlocked`,
  }),
  [NONE]: t({ id: 'recycle.network.none', message: `None` }),
});

export const recycleLabels = (key, plan) =>
  get(
    {
      [UPGRADE]: t({
        id: 'recycle.outcome.apply.new.plan',
        message: `Apply to new ${plan} plan`,
      }),
      [STANDARD]: t({
        id: 'recycle.outcome.apply.new.plan',
        message: `Apply to new ${plan} plan`,
      }),
      [GIFT_CARD]: t({
        id: 'recycle.outcome.apply.gift.cart',
        message: `Apply to gift card`,
      }),
      [RETURN_DEVICE]: t({
        id: 'recycle.outcome.return.device',
        message: `Return device`,
      }),
      [CASH_IN_STORE]: t({
        id: 'recycle.payment.cash.store',
        message: `Cash in store`,
      }),
      [BANK_PAYMENT]: t({
        id: 'recycle.payment.bank.payment',
        message: `Bank payment`,
      }),
      [ROLLOVER_CREDIT]: t({
        id: 'recycle.payment.rollover.credit',
        message: `Rollover credit`,
      }),
      [FINANCING]: t({
        id: 'recycle.outcome.apply.new.order',
        message: `Apply to new Order`,
      }),
    },
    key,
    key,
  );

const swapLegalPosaleText = (key, amount) =>
  get(
    {
      title: t({
        id: 'posale.swap.legal.text.title',
        message: `Customer's estimated current \n Easy Upgrade loan balance: ${amount}*`,
      }),
      subtitle: t({
        id: 'posale.swap.legal.text.subtitle',
        message: `Which is based on having paid ${amount} each month in line with the Klarna terms agreed. Please check the Klarna App to confirm current loan balance.`,
      }),
      returnValue: t({
        id: 'posale.swap.legal.return.value',
        message: `Return device value: ${amount}`,
      }),
      body1: t({
        id: 'posale.swap.legal.text.body1',
        message: `This amount will be credited to the customer's Klarna Loan Account within 3 working days of you being approved for the new device.`,
      }),
      body2: t({
        id: 'posale.swap.legal.text.body2',
        message: `After the Return Device Value is credited to the customer's account, the estimated Remaining Loan Balance will be ${amount}.`,
      }),
      body3: t({
        id: 'posale.swap.legal.text.body3',
        message: `The customer should continue to pay his loan until your Remaining Loan Balance is fully paid. This monthly payment will be in addition to the payment for the new device. Please check the Klarna app for alternative payment schedules.`,
      }),
      body4: t({
        id: 'posale.swap.legal.text.body4',
        message: `The Early Return process will be finalized after the customer has been credit approved for the new device.`,
      }),
      body5: t({
        id: 'posale.swap.legal.text.body5',
        message: `If the customer does not receive credit approval for the new contract, the Early Return will be cancelled and he should be given the option to keep the existing device and continue monthly payments or return the device**`,
      }),
      body6: t({
        id: 'posale.swap.legal.text.body6',
        message: `*Loan balance is calculated based on the customer settling his monthly statement each month, late fees or accrued interest have not been taken into consideration. Please revert to the Klarna account for further information.`,
      }),
      body7: t({
        id: 'posale.swap.legal.text.body7',
        message: `**If the customer chooses to return the device he will be given the Return Device Value, this may or may not settle the balance on his Klarna account based on *`,
      }),
    },
    key,
    key,
  );

export const swapLegalText = (type, key, amount) =>
  ({
    [POSALE]: swapLegalPosaleText(key, amount),
  }[type]);

export const REPORTS_STATUS_ALL = 'ALL';
export const REPORTS_STATUS_CREATED = 'CREATED';
export const REPORTS_STATUS_ORDER_SUCCESSFUL = 'ORDER_SUCCESSFUL';
export const REPORTS_STATUS_PENDING = 'PENDING';
export const REPORTS_STATUS_CANCELLED = 'CANCELLED';

export const removeICloudLink = (country = '') => {
  switch (country.toLowerCase()) {
    case 'es':
      return 'https://support.apple.com/es-es/guide/icloud/mmfc0ef36f/icloud';
    case 'fr':
      return 'https://support.apple.com/fr-fr/guide/icloud/mmfc0ef36f/icloud';
    default:
      return 'https://cdn.financetech365.com/pdfs/apple-remove-iCloud.pdf';
  }
};

export const appleDEPLink = (country = '') => {
  switch (country.toLowerCase()) {
    case 'es':
      return 'https://cdn.financetech365.com/pdfs/apple-dep-es.pdf';
    default:
      return 'https://cdn.financetech365.com/pdfs/apple-dep.pdf';
  }
};

export const EMAIL_REGEX =
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
export const TRADECART_ID_REGEX = /^[a-z]{2}-[a-z]{2}-20[0-9]{2}-T[0-9]{7}$/i;
