import {
  revalidateDeviceReferenceAction,
  selectRecycleNetworkStatusAction,
} from 'actions/recycle';
import includes from 'lodash/includes';
import { anyCheckFailed, anyCheckWasPerformed } from 'utils';

import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Trans } from '@lingui/macro';

import Button from 'components/Button';
import Heading from 'components/Heading';
import RadioButtons from 'components/RadioButtons';
import IconChecked from 'components/icons/IconChecked';
import IconExclamation from 'components/icons/IconExclamation';
import IconLocked from 'components/icons/IconLocked';
import IconUnlocked from 'components/icons/IconUnlocked';

import {
  SIM_STATUS_LOCKED,
  SIM_STATUS_UNLOCKED,
  networkStatus,
  simStatusLabels,
} from 'constants/recycle';

import './DeviceDisownmentChecks.styl';

const DeviceDisownmentChecks = ({
  device,
  orderUUID,
  mode,
  isMultiTrade = false,
}) =>
  isMultiTrade ? (
    <DeviceDisownmentChecksMultiTrade device={device} />
  ) : (
    <DeviceDisownmentChecksSoloTrade orderUUID={orderUUID} mode={mode} />
  );

const DeviceDisownmentChecksMultiTrade = ({ device }) => {
  const { checkmendOk, deviceReference, fmi, referenceType } = device;

  const dispatch = useDispatch();

  const reevaluateLockedDevice = () =>
    dispatch(
      revalidateDeviceReferenceAction({
        deviceReference,
        referenceType,
        device,
        isMultiTrade: true,
      }),
    );

  if (!anyCheckWasPerformed(checkmendOk, fmi)) {
    return null;
  }

  return (
    <div className='deviceDisownment'>
      <Heading size='medium' data-test='trade-checkmend-disclaimer-title'>
        <Trans id='trade.checkmend.disclaimer.title'>
          IMEI Checkmend / FMI Status
        </Trans>
      </Heading>
      <div className='deviceDisownment__indicators'>
        {(checkmendOk ||
          (typeof fmi !== 'undefined' &&
            Object.values(fmi)?.every((value) => value === false))) && (
          <div className='disclaimer-msg'>
            <IconChecked className='checked-icon disclaimer-icon disclaimer-icon--success' />

            <span>
              <Trans id='trade.checkmend.pass'>Checkmend pass</Trans>
            </span>
          </div>
        )}

        {typeof checkmend !== 'undefined' && !checkmendOk && (
          <div className='disclaimer-msg'>
            <IconExclamation className='exclamation-icon disclaimer-icon disclaimer-icon--warning' />

            <span>
              <Trans id='trade.checkmend.fail'>Checkmend fail</Trans>
            </span>
          </div>
        )}

        {typeof fmi !== 'undefined' && includes(fmi, true) && (
          <div className='disclaimer-msg'>
            <IconExclamation className='exclamation-icon disclaimer-icon disclaimer-icon--warning' />

            <span>
              <Trans id='trade.fmip.fail'>FMiP fail</Trans>
            </span>
          </div>
        )}
      </div>

      {anyCheckFailed(checkmendOk, fmi) && (
        <div className='deviceDisownment__actions'>
          <div className='deviceDisownment__disclaimers'>
            <Trans id='evaluation.recycle.blocked.device'>
              This device can't be traded.
            </Trans>
            {!checkmendOk && (
              <Trans id='evaluation.trade.device.stolen'>
                It was reported as lost or stolen by Checkmend! Customer can
                contact Checkmend for further information and get the block
                removed.
              </Trans>
            )}
            {fmi?.isLocked && (
              <Trans id='evaluation.trade.device.locked'>
                It was found to be locked by iCloud. Please remove the lock and
                the device from the customer's iCloud to continue with the Trade
                In.
              </Trans>
            )}
            {fmi?.isLost && (
              <Trans id='evaluation.trade.device.lost'>
                It was reported to be lost mode by iCloud. Please deactivate
                lost mode and remove device from the customer's iCloud to
                continue.
              </Trans>
            )}
            {fmi?.isActivated && (
              <Trans id='evaluation.trade.device.found'>
                It was found to be connected to the customer's iCloud. Please
                disconnect it to continue the Trade In process.
              </Trans>
            )}
          </div>

          <Button
            classes='restartRecycleButton'
            onClick={reevaluateLockedDevice}
          >
            <Trans id='device.evaluation.subtitle'>
              Re-validate IMEI / Serial Number
            </Trans>
          </Button>
        </div>
      )}
    </div>
  );
};

const DeviceDisownmentChecksSoloTrade = ({ orderUUID, mode = 'vendor' }) => {
  const checkmendOk = useSelector(({ recycle }) => recycle.checkmendOk);
  const deviceReference = useSelector(({ recycle }) => recycle.deviceReference);
  const dispatch = useDispatch();
  const fmi = useSelector(({ recycle }) => recycle.fmi);
  const referenceType = useSelector(({ recycle }) => recycle.referenceType);
  const searchText = useSelector(({ recycle }) => recycle.searchText);
  const selectedDevice = useSelector(({ recycle }) => recycle.selectedDevice);
  const selectedSIMStatus = useSelector(
    ({ recycle }) => recycle.selectedSIMStatus,
  );

  const shouldShowSIMStatusSelection =
    selectedDevice?.hasNetwork &&
    !networkStatus.includes(fmi?.networkLockStatus) &&
    !selectedSIMStatus;

  const handleDeviceNetworkSelection = ({ value }) => {
    dispatch(selectRecycleNetworkStatusAction(value));
  };

  const reevaluateLockedDevice = () =>
    dispatch(
      revalidateDeviceReferenceAction({
        deviceReference,
        referenceType,
        searchText,
        orderUUID,
        deviceID: selectedDevice.deviceID,
      }),
    );

  return (
    <Fragment>
      {anyCheckWasPerformed(checkmendOk, fmi) && mode === 'vendor' && (
        <div className='deviceDisownmentChecks'>
          <div className='deviceDisownmentChecks__checkmend'>
            <Heading size='medium' data-test='trade-checkmend-disclaimer-title'>
              <Trans id='trade.checkmend.disclaimer.title'>
                IMEI Checkmend / FMI Status
              </Trans>
            </Heading>

            <div className='deviceDisownmentChecks__disclaimers'>
              {(checkmendOk ||
                (typeof fmi !== 'undefined' &&
                  Object.values(fmi)?.every((value) => value === false))) && (
                <div className='disclaimer-msg'>
                  <IconChecked className='checked-icon disclaimer-icon disclaimer-icon--success' />

                  <span>
                    <Trans id='trade.checkmend.pass'>Checkmend pass</Trans>
                  </span>
                </div>
              )}

              {typeof checkmend !== 'undefined' && !checkmendOk && (
                <div className='disclaimer-msg'>
                  <IconExclamation className='exclamation-icon disclaimer-icon disclaimer-icon--warning' />

                  <span>
                    <Trans id='trade.checkmend.fail'>Checkmend fail</Trans>
                  </span>
                </div>
              )}

              {typeof fmi !== 'undefined' && includes(fmi, true) && (
                <div className='disclaimer-msg'>
                  <IconExclamation className='exclamation-icon disclaimer-icon disclaimer-icon--warning' />

                  <span>
                    <Trans id='trade.fmip.fail'>FMiP fail</Trans>
                  </span>
                </div>
              )}
            </div>

            {anyCheckFailed(checkmendOk, fmi) && (
              <Button
                classes='restartRecycleButton'
                onClick={reevaluateLockedDevice}
              >
                <Trans id='device.evaluation.subtitle'>
                  Re-validate IMEI / Serial Number
                </Trans>
              </Button>
            )}
          </div>

          {selectedDevice?.hasNetwork &&
            (networkStatus.includes(fmi?.networkLockStatus) ||
              networkStatus.includes(selectedSIMStatus)) && (
              <div className='sim-status-disclaimer'>
                <Heading size='medium' data-test='heading-sim-status-noselect'>
                  <Trans id='evaluation.sim.status.noselect'>SIM Status</Trans>
                </Heading>

                <div className='disclaimer-msg'>
                  {(fmi?.networkLockStatus || selectedSIMStatus) ===
                    SIM_STATUS_UNLOCKED && (
                    <IconUnlocked className='unlocked-icon disclaimer-icon disclaimer-icon--success' />
                  )}
                  {(fmi?.networkLockStatus || selectedSIMStatus) ===
                    SIM_STATUS_LOCKED && (
                    <IconLocked className='locked-icon disclaimer-icon disclaimer-icon--success' />
                  )}
                  <span>
                    {simStatusLabels(
                      fmi?.networkLockStatus || selectedSIMStatus,
                    )}
                  </span>
                </div>
              </div>
            )}
        </div>
      )}

      {shouldShowSIMStatusSelection && (
        <Fragment>
          <Heading size='small' data-test='heading-sim-status'>
            <Trans id='evaluation.sim.status'>Select SIM Status</Trans>
          </Heading>

          {mode === 'endUser' && (
            <p>
              <Trans id='evaluation.sim.status.question'>
                Is the device locked to a mobile network or unlocked (sim free)?
              </Trans>
            </p>
          )}

          <RadioButtons
            classes='sim-status-radio-buttons'
            wrap={true}
            size='big'
            onClick={handleDeviceNetworkSelection}
            options={[
              {
                value: SIM_STATUS_LOCKED,
                label: simStatusLabels(SIM_STATUS_LOCKED),
              },
              {
                value: SIM_STATUS_UNLOCKED,
                label: simStatusLabels(SIM_STATUS_UNLOCKED),
              },
            ]}
            testSelector='sim-status-answers'
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default DeviceDisownmentChecks;
