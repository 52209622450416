import { getLoanDataAction } from 'actions/admin/device';

import React from 'react';
import { connect } from 'react-redux';

import { t } from '@lingui/macro';

import Form from 'components/Form';
import Input from 'components/Input';

const PricingToolDiscountForm = ({
  filtersForm,
  discountForm,
  getLoanData,
  loanData,
}) => {
  return (
    <Form
      form='pricingToolDiscount'
      classes='pricing-tool__discount'
      destroyOnUnmount={true}
      fields={[
        {
          component: Input,
          placeholder: t({
            id: 'forms.pricing.tool.discount',
            message: `Discount amount`,
          }),
          name: 'selectedDiscount',
          type: 'number',
        },
      ]}
      // TODO make this component reliant only on hooks
      // in order to remove arrow functions and enforce this rule
      // eslint-disable-next-line react/jsx-no-bind
      handleSubmit={() => {
        getLoanData(
          loanData.device,
          filtersForm.values?.selectedDate,
          discountForm.values?.selectedDiscount || undefined,
        );
      }}
      submitLabel={t({
        id: 'forms.pricing.tool.discount.simulate',
        message: `Simulate`,
      })}
    />
  );
};

export default connect(
  ({ adminDevice, form }) => ({
    loanData: adminDevice.loanData,
    filtersForm: form.deviceFilters,
    discountForm: form.pricingToolDiscount,
  }),
  {
    getLoanData: getLoanDataAction,
  },
)(PricingToolDiscountForm);
