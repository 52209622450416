import * as React from 'react';

function SvgIconZoom({ title, titleId, ...props }) {
  return (
    <svg width='1em' height='1em' aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <clipPath id='icon-zoom_svg__a'>
          <path d='M0 0h36v36H0z' />
        </clipPath>
      </defs>
      <g clipPath='url(#icon-zoom_svg__a)'>
        <path fill='none' d='M0 0h36v36H0z' />
        <path
          d='M26.292 27.706l-5.386-5.386A7.926 7.926 0 0116 24a8 8 0 118-8 7.924 7.924 0 01-1.68 4.906l5.387 5.386-1.414 1.414zM10 16a6 6 0 106-6 6.007 6.007 0 00-6 6zm5 4v-3h-3v-2h3v-3h2v3h3v2h-3v3z'
          fill='#333'
          data-name='Icon / Zoom / Ghost'
        />
      </g>
    </svg>
  );
}

export default SvgIconZoom;
