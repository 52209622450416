import magIcon from 'assets/images/mag.svg';
import { debounce } from 'lodash';
import { Field, reduxForm } from 'redux-form';

import React, { Component } from 'react';

import RenderField from 'components/RenderField';

import './SearchBar.styl';

class SearchBar extends Component {
  debounceHandleChange = debounce(
    this.props.handleChange,
    this.props.debounceDelay,
  );

  render() {
    const { validate, label, testSelector } = this.props;

    return (
      <form data-test={testSelector} className='search-bar-box'>
        <div className='search-filter-wrapper'>
          <Field
            name='searchText'
            type='text'
            className='search-input'
            component={RenderField}
            validate={validate}
            label={label}
            onChange={this.debounceHandleChange}
            testSelector={testSelector && `${testSelector}-input`}
          />

          <img src={magIcon} alt='Magnifier' className='search-input-icon' />
        </div>
      </form>
    );
  }
}

SearchBar.defaultProps = {
  debounceDelay: 500,
};

SearchBar = reduxForm({
  form: 'searchForm',
  destroyOnUnmount: true,
})(SearchBar);

export default SearchBar;
