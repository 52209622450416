import {
  CREATE_NEW_COLLECTION,
  GET_COLLECTIONS,
  GET_COLLECTION_DETAILS,
  GET_COLLECTION_FORM_INFO,
  GET_COLLECTORS,
  GET_COLLECTORS_RULES,
  GET_RETURNED_DEVICES,
  SET_COLLECTION_DEVICES,
} from 'actions/admin/collection';

import initialState from 'reducers/initialState';

export default function admin(state = initialState.adminCollection, action) {
  switch (action.type) {
    case GET_RETURNED_DEVICES:
      return { ...state, returnedDevices: action.data };
    case GET_COLLECTION_FORM_INFO:
      return { ...state, collectionStore: action.data };
    case SET_COLLECTION_DEVICES:
      return { ...state, collectionDevices: action.data };
    case GET_COLLECTIONS:
      return { ...state, collections: action.data };
    case CREATE_NEW_COLLECTION:
      return {
        ...state,
        createCollectionSuccess: true,
        collectionUrl: action.data,
      };
    case GET_COLLECTION_DETAILS:
      return { ...state, collection: action.data };
    case GET_COLLECTORS:
      return { ...state, collectors: action.data };
    case GET_COLLECTORS_RULES:
      return { ...state, collectorsRules: action.data };
    default:
      return state;
  }
}
