import * as React from 'react';

function SvgIconChecked({ title, titleId, ...props }) {
  return (
    <svg
      viewBox='0 0 16 16'
      width='1em'
      height='1em'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M3.5 7.682L7.029 11.5l6.471-7'
      />
    </svg>
  );
}

export default SvgIconChecked;
