import React from 'react';

import './Counters.styl';

const Counters = ({ item }) => {
  const counter = (color, value, name) => {
    return (
      <div className={`counter ${color || 'loading'}`} id={`${name}div`}>
        <div className='total' id='totalSales'>
          {value || 0}
        </div>
        <div className='label'>{name}</div>
      </div>
    );
  };

  return (
    <div className='counter-row'>
      <div className='counter-group'>
        <p className='counter-group-title'>Sales</p>
        <a href='#sales'>
          {counter(item.numSalesColour, item.numSales, 'Activated')}
        </a>
        <a href='#online'>
          {counter(
            item.numOnlinePendingColour,
            item.numOnlinePending,
            'Online Pending',
          )}
        </a>
      </div>

      <div className='counter-group'>
        <p className='counter-group-title'>Backlog</p>
        <a href='#backlog-instore'>
          {counter(
            item.numInstoreBacklogColour,
            item.numInstoreBacklog,
            'In Store',
          )}
        </a>
        <a href='#backlog-online'>
          {counter(
            item.numOnlineBacklogColour,
            item.numOnlineBacklog,
            'Online',
          )}
        </a>
      </div>

      <div className='counter-group'>
        <p className='counter-group-title'>Upgrade / Trade-in</p>
        <a href='#swaps'>
          {counter(item.numSwapsColour, item.numSwaps, 'Upgrades')}
        </a>
        <a href='#tradeins'>
          {counter(item.numTradeInsColour, item.numTradeIns, 'Trade-ins')}
        </a>
      </div>

      <div className='counter-group'>
        <p className='counter-group-title'>Errors</p>
        <a href='#frontenderrors'>
          {counter(item.numFrontEndErrorsColour, item.numFrontEndErrors, 'Web')}
        </a>
        <a href='#apierrors'>
          {counter(item.numAPICallsErrorsColour, item.numAPICallsErrors, 'API')}
        </a>
      </div>
    </div>
  );
};

export default Counters;
