import arrow from 'assets/images/icon-arrow-back.svg';
import classNames from 'classnames';

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { STORE_PERSON } from 'constants/user';

import './DropdownMenu.styl';

export default class DropdownMenu extends Component {
  state = { visible: false };

  showMenu = (event) => {
    event.preventDefault();

    this.setState({ visible: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  };

  closeMenu = (event) => {
    event.preventDefault();

    this.setState({ visible: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  };

  render() {
    const { visible } = this.state;
    const {
      title,
      classes,
      options,
      role,
      icon = arrow,
      testSelector,
    } = this.props;

    return (
      <div className={classNames('dropdown-menu', classes)}>
        <div onClick={this.showMenu} data-test={testSelector}>
          {title}
          <img src={icon} alt='Menu indicator' />
        </div>
        {visible && (
          <ul>
            {options.map((option) => {
              return (
                role <= option.minRole &&
                (option.isMigrated ? (
                  <li key={option.id}>
                    <a
                      data-test={`${testSelector}-${option.id}`}
                      onClick={() => (window.location.href = option.path)}
                    >
                      {option.label}
                    </a>
                  </li>
                ) : (
                  <li key={option.id}>
                    <Link
                      to={`${option.path}`}
                      onClick={option?.onClick}
                      data-test={`${testSelector}-${option.id}`}
                    >
                      {option.label}
                    </Link>
                  </li>
                ))
              );
            })}
          </ul>
        )}
      </div>
    );
  }
}

DropdownMenu.defaultProps = {
  title: '',
  classes: '',
  options: [],
  role: STORE_PERSON,
};
