import {
  CLEAR_CUSTOMER,
  SET_COMPANY_DETAILS,
  SET_CUSTOMER,
  UPDATE_COMPANY_DETAILS,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_OPTION,
} from 'actions/customer';

import initialState from 'reducers/initialState';

const customer = function (state = initialState.customer, action) {
  switch (action.type) {
    case CLEAR_CUSTOMER:
      return { ...initialState.customer };
    case UPDATE_CUSTOMER:
      return { ...state, [action.key]: action.value };
    case SET_CUSTOMER:
      return { ...state, ...action.values };
    case UPDATE_CUSTOMER_OPTION:
      return { ...state, options: action.options };
    case UPDATE_COMPANY_DETAILS:
      return {
        ...state,
        company: { ...state.company, [action.key]: action.value },
      };
    case SET_COMPANY_DETAILS:
      return { ...state, company: action.value };
    default:
      return state;
  }
};

export default customer;
