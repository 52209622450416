import { getPricedExtrasAction, selectOptionAction } from 'actions/carts';
import {
  getExtrasCategoriesAction,
  unlockMoreExtrasAction,
} from 'actions/catalog';
import isEmpty from 'lodash/isEmpty';
import { Field, getFormValues, reduxForm, submit } from 'redux-form';

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import Dropdown from 'components/Dropdown';
import InfiniteScroll from 'components/InfiniteScroll';
import Loader from 'components/Loader';
import RenderField from 'components/RenderField';
import SearchBar from 'components/SearchBar';

import { extraTypes } from 'constants/extras';

import ProductExtrasCatalogList from './ProductExtrasCatalogList';

class ProductExtrasCatalog extends Component {
  componentDidMount() {
    const { getExtrasCategories } = this.props;
    getExtrasCategories();
    this.updateExtras();
  }

  updateExtras = async () => {
    const { cart, getPricedExtras } = this.props;
    const {
      selected: { deviceCode },
      uuid: cartUUID,
    } = cart;

    await getPricedExtras(cartUUID, deviceCode, {
      updateExtrasPrice: true,
    });
  };

  addExtraCallback = async () => {
    const { cart, selectOption } = this.props;
    const { uuid: cartUUID } = cart;
    await selectOption(cartUUID);
    await this.updateExtras();
  };

  render() {
    const {
      cart,
      categories,
      change,
      unlockMoreExtras,
      extras,
      formValues,
      getPricedExtras,
      loading,
      allExtrasFetched,
    } = this.props;

    const { page } = formValues;
    return (
      <Fragment>
        <Fragment>
          {loading && <Loader />}
          <SearchBar
            // TODO make this component reliant only on class functions
            // in order to remove arrow functions and enforce this rule
            // eslint-disable-next-line react/jsx-no-bind
            handleChange={() =>
              getPricedExtras(cart.uuid, cart.selected.deviceCode) &&
              change('page', 0) &&
              unlockMoreExtras()
            }
            label={t({
              id: 'extras.search.placeholder',
              message: `Search extras`,
            })}
            testSelector='search-bar-extras'
          />

          <form className='search-box'>
            <Field
              name='category'
              component={Dropdown}
              placeholder={t({
                id: 'extras.select.category.placeholder',
                message: `Select category`,
              })}
              options={categories.reduce(
                (acc, value) => {
                  return [
                    ...acc,
                    { value: value, label: extraTypes()[value] || value },
                  ];
                },
                [
                  {
                    value: 'ALL',
                    label: t({
                      id: 'extras.filter.all',
                      message: `All Categories`,
                    }),
                  },
                ],
              )}
              // eslint-disable-next-line react/jsx-no-bind
              onChange={(_value) => {
                change('page', 0);
              }}
              testSelector='categories-list'
              variant='underline'
              labelType='outside'
            />

            <div className='checkbox-extras-container'>
              <Field
                type='checkbox'
                className='checkbox-container'
                inputClasses='checkbox-input'
                name='isDeviceOnly'
                component={RenderField}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={(_value) => change('page', 0)}
                testSelector='extras-checkbox'
              />

              <Trans id='search.extras'> Related to device only </Trans>
            </div>
          </form>
        </Fragment>

        {!isEmpty(extras) ? (
          <InfiniteScroll
            items={extras}
            // eslint-disable-next-line react/jsx-no-bind
            fetchItems={(page) => change('page', page)}
            page={page}
            endPagination={allExtrasFetched}
          >
            <ProductExtrasCatalogList
              addExtraCallback={this.addExtraCallback}
              cartUUID={cart.uuid}
            />
          </InfiniteScroll>
        ) : (
          <p className='no-extras-warning'>
            <Trans id='no.extras.warning'> No extras available </Trans>
          </p>
        )}
      </Fragment>
    );
  }
}

ProductExtrasCatalog.defaultProps = {
  extras: [],
  formValues: {},
  categories: [],
};

ProductExtrasCatalog = reduxForm({
  form: 'extrasFilters',
  destroyOnUnmount: false,
  onSubmit: (_values, dispatch, props) => {
    const {
      uuid,
      selected: { deviceCode },
    } = props.cart;

    dispatch(getPricedExtrasAction(uuid, deviceCode));
  },
  onChange: (_values, dispatch, props, previous) => {
    if (previous.isDeviceOnly !== undefined && previous.page !== undefined) {
      dispatch(submit('extrasFilters'));
      if (props.allExtrasFetched) dispatch(unlockMoreExtrasAction());
    }
  },
})(ProductExtrasCatalog);

ProductExtrasCatalog = connect(
  (state) => ({
    allExtrasFetched: state.catalog.allExtrasFetched,
    categories: state.catalog.extrasCategories,
    currency: state.whitelabel.currency,
    extras: state.catalog.extras,
    formValues: getFormValues('extrasFilters')(state),
    initialValues: { isDeviceOnly: true, page: 0 },
    language: state.whitelabel.language,
    loading: state.ui.loading,
  }),
  {
    getExtrasCategories: getExtrasCategoriesAction,
    getPricedExtras: getPricedExtrasAction,
    unlockMoreExtras: unlockMoreExtrasAction,
    selectOption: selectOptionAction,
  },
)(ProductExtrasCatalog);

export default ProductExtrasCatalog;
