import { updateBuildHashAction } from 'actions/app';
import { cleanStoreAction } from 'actions/app';
import { addNotificationAction } from 'actions/ui';
import { initializeUserAction } from 'actions/user';
import { posaleVersions } from 'apis/version';
import axios from 'axios';
import map from 'lodash/map';
import store from 'store';
import { getSubOrFullDomain, snakeToCamel } from 'utils';

import { history } from 'components/core/AppRoot';

import {
  API_ADMIN_ENDPOINT,
  API_ADMIN_SERVICE,
  API_POSALE_ENDPOINT,
  API_POSALE_SERVICE,
  API_UPLOAD_ENDPOINT,
  API_UPLOAD_SERVICE,
  API_VERSION,
} from 'constants/api';
import { errorCodes, notificationTitle } from 'constants/errors';
import { servicesUrl } from 'constants/routes';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  method: 'post',
  headers: {
    Accept: 'application/json, text/csv, application/pdf',
    'Content-Type': 'application/json',
  },
  transformRequest: (data, headers) => {
    const requestPayload = {
      version: posaleVersions[data.header.function] || API_VERSION,
      build: process.env.__COMMIT_HASH__,
      sessionID: store.getState()?.user?.session_token,
    };
    const subdomain = process.env.REACT_APP_SUBDOMAIN || getSubOrFullDomain();

    if (data?.data instanceof FormData) {
      headers['Content-Type'] = 'multipart/form-data';

      data.data.append('service', data.header.service);
      data.data.append('function', data.header.function);
      data.data.append('clientID', data.header.clientID);
      data.data.append('subdomain', subdomain);

      Object.entries(requestPayload).forEach(([key, value]) =>
        data.data.append(key, value),
      );

      return data.data;
    } else {
      return JSON.stringify({
        ...data,
        subdomain,
        header: {
          ...data.header,
          ...requestPayload,
        },
      });
    }
  },
});

api.interceptors.response.use((response) => {
  if (response) {
    const { header = {}, ...data } = response.data;

    if (header.apiStatus) {
      response.data = {
        data: data,
        status: header?.apiStatus,
      };
    }

    const { apiStatus, errorNumber } = header;

    const prevBuildHash = store.getState().app.buildHash;
    const currentBuildHash = response.data.build;
    if (!prevBuildHash || prevBuildHash !== currentBuildHash) {
      store.dispatch(updateBuildHashAction(currentBuildHash));
    }

    if (apiStatus === 'ERROR' || apiStatus === 'WARNING') {
      map(header[`${apiStatus.toLowerCase()}s`], (elem) => {
        const { code, values, message } = elem;

        if (
          code === 'CORE_CODE_CLIENT_SESSION_UNAUTHORIZED_ACCESS' ||
          code === 'CORE_CODE_CLIENT_SESSION_NOT_FOUND'
        ) {
          store.dispatch(cleanStoreAction());
          store.dispatch(initializeUserAction());
        }

        if (code === 'CONTRACT_ALREADY_ACTIVE') {
          history.push(servicesUrl);
        }

        store.dispatch(
          addNotificationAction({
            title: notificationTitle()[apiStatus],
            message: `${errorNumber ? `Error ${errorNumber}: ` : ''}${
              errorCodes()[code] || message
            }`,
            type: apiStatus.toLowerCase(),
            values,
          }),
        );
      });
    }

    if (response.data.data?.loginStatus) {
      response.data.data.status = response.data.data.loginStatus;
    }
  }

  return response;
});

const performPost = (endpoint, func, clientID, data, config, service) => {
  if (data instanceof FormData) {
    data = {
      data,
    };
  }

  return api.post(
    `${endpoint}${func}`,
    {
      header: {
        service,
        function: func,
        clientID,
      },
      ...data,
    },
    config,
  );
};

export const posalePost = (func, data = {}, config) => {
  return performPost(
    API_POSALE_ENDPOINT,
    snakeToCamel(func),
    process.env.REACT_APP_POSALE_TOKEN,
    data,
    config,
    API_POSALE_SERVICE,
  );
};

export const adminPost = (func, data = {}, config) => {
  return performPost(
    API_ADMIN_ENDPOINT,
    snakeToCamel(func),
    process.env.REACT_APP_POSALE_TOKEN,
    data,
    config,
    API_ADMIN_SERVICE,
  );
};

export const uploadPost = (func, { file, type, campaignCode }, config) => {
  const data = new FormData();
  data.append('file', file);
  data.append('type', type);
  data.append('campaignCode', campaignCode);

  return performPost(
    API_UPLOAD_ENDPOINT,
    snakeToCamel(func),
    process.env.REACT_APP_POSALE_TOKEN,
    data,
    config,
    API_UPLOAD_SERVICE,
  );
};

export const post = (service = API_POSALE_SERVICE, data) => {
  if (service === API_POSALE_SERVICE) return posalePost(...data);
  if (service === API_ADMIN_SERVICE) return adminPost(...data);
};

export default api;
