import { hideLoaderAction, showLoaderAction } from 'actions/ui';
import { posalePost } from 'apis/v2';

export const GET_STARTING_INFO = 'GET_STARTING_INFO';
export function getStartingInfo(subdomain) {
  return async function (dispatch) {
    dispatch(showLoaderAction());

    const response = await posalePost(GET_STARTING_INFO, { subdomain });

    if (response.data.status !== 'ERROR') {
      const whitelabelInfo = response.data.data;
      const domainSplit = subdomain.split('-');

      document.documentElement.setAttribute(
        'theme',
        whitelabelInfo.configs?.theme || 'default',
      );

      document.title = whitelabelInfo.name;

      dispatch({
        type: GET_STARTING_INFO,
        subdomain,
        shortName: domainSplit[domainSplit.length - 1],
        data: whitelabelInfo,
      });
    }

    dispatch(hideLoaderAction());
  };
}

export const CHANGE_LOGIN_METHOD = 'CHANGE_LOGIN_METHOD';
export function changeLoginMethodAction() {
  return async function (dispatch) {
    dispatch({ type: CHANGE_LOGIN_METHOD });
  };
}

export const SET_LANGUAGE = 'SET_LANGUAGE';
export function setLanguageAction(language) {
  return async function (dispatch) {
    dispatch({ type: SET_LANGUAGE, data: language });
  };
}
