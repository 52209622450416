import { openModalAction } from 'actions/ui';
import classNames from 'classnames';
import { validateDeviceReference } from 'helpers/validators';
import { Field, getFormValues, reduxForm } from 'redux-form';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import Badges from 'components/Badges';
import Button from 'components/Button';
import RenderField from 'components/RenderField';

import './DeviceNameForm.styl';

class DeviceNameForm extends Component {
  render() {
    const {
      id,
      disabled = false,
      invalid,
      handleSubmit,
      submitting,
      submitted = false,
      searchText,
    } = this.props;

    return (
      <form
        id={id}
        className='deviceNameForm'
        onSubmit={!disabled ? handleSubmit : undefined}
      >
        <Field
          name='searchText'
          type='text'
          className={classNames('deviceNameForm__input', {
            'deviceNameForm__input--disabled': disabled,
          })}
          component={RenderField}
          disabled={disabled}
          initialValue={searchText}
          animateLabel={false}
          label={t({
            id: 'device.name.form.text.placeholder',
            message: `Device name`,
          })}
          testSelector='device-name'
        />

        {submitted ? (
          <Badges
            badges={{
              classes: 'device-name__badge--valid',
              label: t({
                id: 'deviceReference.form.submitted.badge',
                message: `OK`,
              }),
            }}
          />
        ) : (
          <Button
            classes='device-name__button'
            type='submit'
            disabled={invalid || submitting || disabled}
            loading={submitting}
            testSelector='submit-button'
          >
            <Trans id='deviceReference.form.submit.button'>Submit</Trans>
          </Button>
        )}
      </form>
    );
  }
}

DeviceNameForm = reduxForm({
  enableReinitialize: true,
  form: 'deviceNameForm',
  validate: ({ searchText }, { useAppleValidation = false }) =>
    validateDeviceReference(searchText, useAppleValidation),
})(DeviceNameForm);

DeviceNameForm = connect(
  (state, { searchText = '' }) => ({
    initialValues: { searchText },
    formValues: getFormValues('deviceNameForm')(state),
  }),
  { openModal: openModalAction },
)(DeviceNameForm);

export default DeviceNameForm;
