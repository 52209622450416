import { clearDiagnosticAction, setDiagnosticAction } from 'actions/recycle';
import { addNotificationAction } from 'actions/ui';

import React, { Fragment, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import { Trans } from '@lingui/macro';

import Button from 'components/Button';
import Heading from 'components/Heading';
import Modal from 'components/Modal';

import { deviceValueNotHighEnough } from 'constants/notifications';

import './RecycleDiagnostic.styl';

const RecycleDiagnostic = ({
  deviceValue,
  diagnostic,
  diagnosticURL,
  continueAction,
  orderUUID,
  setDiagnostic,
  clearDiagnostic,
  selectedSIMStatus,
  selectedDevice,
  onlyUpgradeMaxValue,
  addNotification,
}) => {
  const iframeListener = useCallback(
    async (event) => {
      const completedRegExp = new RegExp(
        /(\w*~\w{1,3}~.*-.*-.*-.*-.*)|(\w*~)/g,
      );

      if (
        typeof event.data === 'string' &&
        (completedRegExp.test(event.data) || event.data === 'Skipped~~')
      ) {
        const response = await setDiagnostic({
          diagnosticString: event.data,
          selectedDevice,
          selectedSIMStatus,
          orderUUID,
        });

        if (orderUUID && onlyUpgradeMaxValue && response.swapCost > 0)
          addNotification(deviceValueNotHighEnough());
        if (window) {
          window.removeEventListener('message', iframeListener);
        }
      }
    },
    [
      onlyUpgradeMaxValue,
      orderUUID,
      setDiagnostic,
      selectedDevice,
      selectedSIMStatus,
      addNotification,
    ],
  );

  const addEventListener = useCallback(() => {
    if (window) {
      window.addEventListener('message', iframeListener);
    }
  }, [iframeListener]);

  useEffect(() => {
    addEventListener();
  }, [addEventListener]);

  const restartDiagnostic = () => {
    clearDiagnostic();
    addEventListener();
  };

  return (
    <Modal classes='recyle-diagnostic__modal' name='deviceDiagnostic'>
      <Heading size='medium' align='center'>
        <Trans id='evaluation.device.diagnostic.modal.title'>
          Device Diagnostic
        </Trans>
      </Heading>

      {deviceValue === 0 ? (
        <Heading size='medium' className='diagnostic-test-warning-title'>
          <Trans id='criteria.form.device.error'>
            Device does not fulfill conditions for a software testing. Value
            will be based on device visual physical state.
          </Trans>
        </Heading>
      ) : diagnostic?.reportStatus === 'skipped' ? (
        <Fragment>
          <Trans id='skip.diagnostics.modal.info'>
            The current device will be submitted to further tests. If the device
            has software failures, it will lead to some loss in revenue.
          </Trans>

          <div className='recyle-diagnostic__buttons'>
            <Button
              classes='recyle-diagnostic__button inverted'
              onClick={continueAction}
            >
              <Trans id='diagnostic.continue.button'>
                Continue with recycle
              </Trans>
            </Button>
            <Button
              classes='recyle-diagnostic__button'
              onClick={restartDiagnostic}
            >
              <Trans id='diagnostic.restart.button'>Restart diagnostic</Trans>
            </Button>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <iframe
            id='ifdiagnostics_run'
            title='Device diagnostic'
            className='recyle-diagnostic__iframe'
            src={diagnosticURL}
          />

          {diagnostic?.reportGrade && (
            <Button
              classes='recyle-diagnostic__button'
              onClick={continueAction}
            >
              <Trans id='diagnostic.continue.button'>
                Continue with recycle
              </Trans>
            </Button>
          )}
        </Fragment>
      )}
    </Modal>
  );
};

export default connect(
  ({ whitelabel, recycle }) => ({
    orderUUID: recycle.orderUUID,
    selectedSIMStatus: recycle.selectedSIMStatus,
    diagnosticURL: recycle.selectedDevice.diagnosticURL,
    selectedDevice: recycle.selectedDevice,
    diagnostic: recycle.selectedDevice.diagnostic,
    deviceValue: recycle.selectedDevice?.evaluation?.deviceValue,
    onlyUpgradeMaxValue: whitelabel.configs.onlyUpgradeMaxValue,
  }),
  {
    setDiagnostic: setDiagnosticAction,
    clearDiagnostic: clearDiagnosticAction,
    addNotification: addNotificationAction,
  },
)(RecycleDiagnostic);
