import { sanitize } from 'dompurify';

import React from 'react';

export const BlockPreview = ({ block = {} }) => {
  return (
    <div className='bodyPreview'>
      <iframe
        title='bodyPreviewArea'
        className='bodyPreviewArea'
        srcDoc={sanitize(block?.body, {
          KEEP_CONTENT: false,
        })}
      />
    </div>
  );
};

export default BlockPreview;
