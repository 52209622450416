import { getAmendDeviceCodeListAction } from 'actions/sales';
import { openModalAction } from 'actions/ui';

import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import Button from 'components/Button';
import Modal from 'components/Modal';

import { ACTIVE_WITHOUT_DEVICE_REFERENCE } from 'constants/contracts';

import DeviceCodeForm from './DeviceCodeForm';
import './DeviceCodeModal.styl';

const DeviceCodeModal = ({
  buttonText,
  testSelector,
  dataTest,
  name = 'deviceCodeModal',
  onSubmit,
  openModal,
  getAmendDeviceCodeList,
  useAppleValidation,
  statusLabel,
  ...formProps
}) => {
  const openDeviceCodeForm = () => {
    openModal(name);
    getAmendDeviceCodeList(formProps.orderUUID);
  };

  return (
    <Fragment>
      <Button onClick={openDeviceCodeForm} testSelector={testSelector}>
        {buttonText}
      </Button>

      <Modal name={name} classes={'device-code-modal'}>
        <h2>
          {statusLabel === ACTIVE_WITHOUT_DEVICE_REFERENCE ? (
            <Trans id='amend.device.code.no.imei.form.title'>
              Update Part Number
            </Trans>
          ) : (
            <Trans id='amend.device.code.form.title'>
              Update Part Number and IMEI/Serial Number
            </Trans>
          )}
        </h2>

        <DeviceCodeForm
          destroyOnUnmount={false}
          onSubmit={onSubmit}
          customIMEILabel={t({
            id: 'amend.device.reference.form.imei.optional.placeholder',
            message: `Insert new IMEI (optional)`,
          })}
          customSerialLabel={t({
            id: 'amend.device.reference.form.serial.optional.placeholder',
            message: `Insert new Serial Number (optional)`,
          })}
          useAppleValidation={useAppleValidation}
          statusLabel={statusLabel}
          {...formProps}
        />
      </Modal>
    </Fragment>
  );
};

export default connect(null, {
  openModal: openModalAction,
  getAmendDeviceCodeList: getAmendDeviceCodeListAction,
})(DeviceCodeModal);
