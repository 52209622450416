import { sanitize } from 'dompurify';

import React from 'react';

import Modal from 'components/Modal';

import './EmailPreview.styl';

export const EmailPreview = ({ body }) => {
  return (
    <Modal name='email-preview'>
      <iframe
        title='emailPreview'
        className='emailPreview'
        srcDoc={sanitize(body, {
          KEEP_CONTENT: false,
        })}
      />
    </Modal>
  );
};

export default EmailPreview;
