/**
 * Returns a DTO decorated with the functionality provided by its parameters
 * @param {function} [validator] validation of the raw data provided
 * @param {object} [config] conversion to JSON, to a Model, from a Model and parsing functionalities
 * @return {object} decorated DTO
 */
const AbstractDTO = (
  validator = (raw) => raw,
  config = {
    toJSONFunc: (data = {}) => {
      return () => ({ ...data });
    },
    toModelFunc: (data = {}) => {
      // TODO: ideally we should have a conversion to a Model for business logic abstraction purposes
      return () => ({ ...data });
    },
    fromModelFunc: (model = {}) => {
      // TODO: ideally we should have a conversion from a Model for business logic abstraction purposes
      const data = validator(model);
      return {
        ...data,
        toJSON: config.toJSONFunc(data),
        toModel: config.toModelFunc(data),
      };
    },
    parseFunc: (raw = {}) => {
      const data = validator(raw);
      return {
        ...data,
        toJSON: config.toJSONFunc(data),
        toModel: config.toModelFunc(data),
      };
    },
  },
) => {
  return {
    fromModel: config.fromModelFunc,
    parse: config.parseFunc,
  };
};

export default AbstractDTO;
