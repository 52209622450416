import {
  AMEND_DEVICE_CODE,
  AMEND_DEVICE_REFERENCE,
  CANCEL_ORDER,
  CANCEL_UPGRADE,
  CLEAR_SALE,
  FULFILLMENT_RETURN,
  GET_AMEND_DEVICE_CODE_LIST,
  GET_CART_ACTIONS,
  GET_CART_INVOICES,
  GET_SALE,
  SEARCH_SALES,
} from 'actions/sales';

import initialState from 'reducers/initialState';

export default function (state = initialState.sales, action) {
  switch (action.type) {
    case AMEND_DEVICE_REFERENCE:
    case AMEND_DEVICE_CODE:
    case CANCEL_ORDER:
    case FULFILLMENT_RETURN:
    case CANCEL_UPGRADE:
    case GET_SALE:
      return { ...state, sale: action.sale };
    case GET_AMEND_DEVICE_CODE_LIST:
      return {
        ...state,
        codeAmendDevices: action.data,
      };
    case CLEAR_SALE:
      return {
        ...state,
        sale: initialState.sales.sale,
        cartInvoices: [],
        saleActions: [],
      };
    case SEARCH_SALES:
      return {
        ...state,
        ...action.data,
      };
    case GET_CART_INVOICES:
      return {
        ...state,
        cartInvoices: action.data,
      };
    case GET_CART_ACTIONS:
      return {
        ...state,
        saleActions: action.saleActions.map((saleAction) => ({
          ...saleAction,
          actionType: saleAction.actionType.replaceAll('_', ' '),
        })),
      };
    default:
      return state;
  }
}
