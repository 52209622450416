import {
  addDiscountAction,
  addManualItem,
  changeExtraQuantity,
  changeOrderPPAction,
  changeProductQuantity,
  changeSalePrice,
  removeDiscountAction,
  removeExtraAction,
  removeProductAction,
} from 'actions/carts';
import { openModalAction } from 'actions/ui';
import classNames from 'classnames';
import { currencyFormatter } from 'helpers/formatters';
import isEmpty from 'lodash/isEmpty';
import { reduceCartOrders } from 'utils';

import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import Hyperlink from 'components/Hyperlink';
import LineItem from 'components/LineItem';

import { discountLabels } from 'constants/discount';

import CartItem from './CartItem';
import './CartList.styl';
import DiscountForm from './DiscountForm';
import ManualItemForm from './ManualItemForm';

const CartList = ({
  language,
  currency,
  cart,
  cartUUID,
  totalItems,
  availableDiscounts,
  actionable,
  useQuantities,
  canChangeSalePrice,
  selectedPaymentPlan,
  canQuickQuote,
}) => {
  const dispatch = useDispatch();
  const openDiscount = (ev) => {
    ev.preventDefault();
    dispatch(openModalAction('discountForm'));
  };

  const openManualItem = (ev) => {
    ev.preventDefault();
    dispatch(openModalAction('manualItemForm'));
  };
  const orders = cart.orders;
  const {
    products,
    extras,
    discounts,
    totalPrincipal,
    totalMonthlyPayment,
    openingFeeAmount,
    totalDiscounts,
    totalMonthlyDiscounts,
  } = reduceCartOrders(orders);

  const hasDiscounts = discounts.length > 0;
  const orderUUID = cart.uuid;
  const orderWithUpfront = orders.find(
    (order) => order.financing?.openingFeePercentage > 0,
  );
  const openingFeePercentage =
    orderWithUpfront?.financing?.openingFeePercentage;
  const paymentPlanName = selectedPaymentPlan?.name;

  return (
    <>
      <LineItem
        testSelector='total-per-month'
        value={
          <>
            <span className='price'>
              <Trans id='sale.summary.unit'>Unit</Trans>
            </span>
            <span className='price'>
              <Trans id='sale.summary.quantity'>Quantity</Trans>
            </span>
            <span className='price'>
              <Trans id='sale.summary.total'>Total</Trans>
            </span>
            <span className='price'>
              <Trans id='sale.summary.per.month'>Per month</Trans>
            </span>
          </>
        }
        label={
          <span>
            <h3 className='cart-list-title'>
              {cartUUID} / {paymentPlanName}
            </h3>
          </span>
        }
        classes={classNames('cart-item cart-list-headers', {
          small: !actionable,
        })}
      />
      <div className='cart-list-orders'>
        <Fragment>
          {products?.map((product) => (
            <CartItem
              cartUUID={cartUUID}
              UUID={product.uuid}
              key={product.uuid}
              monthlyPrice={product.monthly_price}
              price={product.cash_price}
              totalPrice={product.totalCashPrice}
              imgURL={product.img_url}
              name={product.name}
              actionable={actionable}
              // TODO make this component reliant only on class functions
              // in order to remove arrow functions and enforce this rule
              // eslint-disable-next-line react/jsx-no-bind
              actionRemove={() =>
                dispatch(removeProductAction(cartUUID, product.uuid, true))
              }
              quantity={product.quantity}
              currency={currency}
              language={language}
              changeQuantity={(cartUUID, UUID, quantity) => {
                useQuantities &&
                  dispatch(changeProductQuantity(cartUUID, UUID, quantity));
              }}
              useQuantities={useQuantities}
              canChangeSalePrice={canChangeSalePrice}
              changeSalePrice={(cartUUID, UUID, salePrice) => {
                canChangeSalePrice &&
                  dispatch(changeSalePrice(cartUUID, UUID, salePrice));
              }}
            />
          ))}

          {extras?.map((extra) => (
            <CartItem
              cartUUID={cartUUID}
              UUID={extra.uuid}
              key={extra.uuid}
              monthlyPrice={extra.monthly_price}
              price={extra.value}
              totalPrice={extra.totalCashPrice}
              imgURL={extra.img_url}
              name={extra.description}
              isCTO={extra.isCTO}
              actionable={actionable}
              // eslint-disable-next-line react/jsx-no-bind
              actionRemove={() =>
                dispatch(
                  removeExtraAction(cartUUID, extra.uuid, () =>
                    dispatch(changeOrderPPAction(cartUUID)),
                  ),
                )
              }
              quantity={extra.quantity}
              currency={currency}
              language={language}
              changeQuantity={(cartUUID, UUID, quantity) => {
                useQuantities &&
                  dispatch(changeExtraQuantity(cartUUID, UUID, quantity));
              }}
              useQuantities={useQuantities}
              canChangeSalePrice={canChangeSalePrice}
              changeSalePrice={(cartUUID, UUID, salePrice) => {
                canChangeSalePrice &&
                  dispatch(changeSalePrice(cartUUID, UUID, salePrice));
              }}
            />
          ))}
        </Fragment>
      </div>

      {hasDiscounts && (
        <div
          className={classNames('cart-list-discounts', {
            small: !actionable,
          })}
        >
          {discounts?.map((discount) => (
            <LineItem
              testSelector='discounts'
              key={discount.uuid}
              label={
                <Trans id='sale.summary.discount'>{`${
                  discountLabels()[discount.type_id]
                } discount`}</Trans>
              }
              value={
                <>
                  <span className='price'></span>
                  <span className='price'></span>
                  <span className='price'>{`${currencyFormatter(
                    discount.value,
                    currency,
                    language,
                  )}`}</span>
                  <span className='price'>{`${currencyFormatter(
                    discount.monthly_value,
                    currency,
                    language,
                  )}`}</span>
                </>
              }
              removable={!discount.locked}
              // eslint-disable-next-line react/jsx-no-bind
              onRemove={() =>
                dispatch(
                  removeDiscountAction(orderUUID, discount.uuid, () =>
                    dispatch(changeOrderPPAction(cartUUID)),
                  ),
                )
              }
            />
          ))}
        </div>
      )}
      <div className='cart-list-action-btns'>
        {actionable && canQuickQuote && (
          <Hyperlink
            variant='plus'
            classes='cart-list-add-discount'
            content={'Add item'}
            onClick={openManualItem}
          />
        )}

        {actionable && !isEmpty(availableDiscounts) && totalItems !== 0 && (
          <Hyperlink
            variant='plus'
            classes='cart-list-add-discount'
            content={t({
              id: 'sale.summary.add.discount',
              message: 'Add discount',
            })}
            onClick={openDiscount}
          />
        )}
      </div>

      <div className={classNames('cart-list-summary', { small: !actionable })}>
        {hasDiscounts && (
          <LineItem
            testSelector='discount-total'
            label={t({
              id: 'sale.summary.discounts.total',
              message: 'Discounts total',
            })}
            value={
              <>
                <span className='price'></span>
                <span className='price'></span>
                <span className='price'>{`${currencyFormatter(
                  totalDiscounts,
                  currency,
                  language,
                )}`}</span>
                <span className='price'>{`${currencyFormatter(
                  totalMonthlyDiscounts,
                  currency,
                  language,
                )}`}</span>
              </>
            }
          />
        )}

        {openingFeeAmount > 0 && openingFeePercentage > 0 && (
          <LineItem
            testSelector='total-upfront'
            label={
              <Trans id='product.upfront'>{`${openingFeePercentage}% Upfront payment`}</Trans>
            }
            value={
              <Fragment>
                <span className='price'></span>
                <span className='price'></span>
                <span className='price'>
                  {currencyFormatter(openingFeeAmount, currency, language)}
                </span>
                <span className='price' />
              </Fragment>
            }
          />
        )}

        <LineItem
          testSelector='total-price'
          classes='filled'
          label={t({
            id: 'sale.summary.total',
            message: 'Total',
          })}
          value={
            <>
              <span className='price'></span>
              <span className='price'></span>
              <span className='price'>
                {currencyFormatter(totalPrincipal, currency, language)}
              </span>
              <span className='price'>
                {currencyFormatter(totalMonthlyPayment, currency, language)}
              </span>
            </>
          }
        />

        {actionable && !isEmpty(availableDiscounts) && (
          <DiscountForm
            availableDiscounts={availableDiscounts}
            addDiscount={(uuid, discount) =>
              dispatch(addDiscountAction(uuid, discount))
            }
            order={orders[0]}
            currency={currency}
            language={language}
            removeDiscount={(uuid, discount) =>
              dispatch(removeDiscountAction(uuid, discount))
            }
            // eslint-disable-next-line react/jsx-no-bind
            onCloseDiscount={() => dispatch(changeOrderPPAction(cartUUID))}
          />
        )}

        {actionable && canQuickQuote && (
          <ManualItemForm
            cartUUID={cartUUID}
            addItem={(cartUUID, item) =>
              dispatch(addManualItem(cartUUID, item))
            }
            onClose={() => dispatch(changeOrderPPAction(cartUUID))}
          />
        )}
      </div>
    </>
  );
};

export default CartList;
