import AbstractDTO from 'DTOs/AbstractDTO';

import { STORE_DISABLED } from 'constants/admin';

const storeValidator = (raw) => {
  return {
    city: raw.city ?? '',
    country: raw.country ?? '',
    createdAt: raw.createdAt ?? null,
    email: raw.email ?? '',
    externalSID: raw.externalSID ?? '',
    id: raw.id ?? null,
    isDefault: raw.isDefault ?? false,
    isOnlineStore: raw.isOnlineStore ?? false,
    name: raw.name ?? '',
    phone: raw.phone ?? '',
    postalCode: raw.postalCode ?? '',
    retailerStoreSID: raw.retailerStoreSID ?? '',
    schedule: raw.schedule ?? '',
    status: raw.status ?? STORE_DISABLED,
    streetAddress: raw.streetAddress ?? '',
    updatedAt: raw.updatedAt ?? null,
    uuid: raw.uuid ?? '',
    wlID: raw.wlID ?? 0,
  };
};

export default AbstractDTO(storeValidator);
