import print from 'assets/images/print.svg';
import classNames from 'classnames';
import trackPage from 'enhancers/trackPage';
import { dateFormatter } from 'helpers/formatters';

import React, { Component, Fragment } from 'react';
import Barcode from 'react-barcode';
import { connect } from 'react-redux';

import { Trans } from '@lingui/macro';

import Button from 'components/Button';
import Logo from 'components/Logo';
import RenderDetails from 'components/RenderDetails';
import Section from 'components/Section';
import DevicesSummary from 'components/recycle/DevicesSummary';

import { config } from 'constants/dl';
import { additionalInfo, customerText, storeText } from 'constants/trade';

import OutcomesHeader from './OutcomesHeader';
import './ReceiptPrint.styl';

const ESReceiptInfo = ({ customerName, customerID, storeName }) => (
  <div className='receipt-text__spain'>
    {customerName}, mayor de edad, con DNI/Tarjeta de Residencia/Pasaporte en
    Vigor, NO {customerID}, en adelante el CLIENTE.
    <br />
    El CLIENTE manifiesta y otorga:
    <ul>
      <li>
        Que es el legítimo propietario del/de los terminal/es usados detallado/s
        en el presente contrato.
      </li>
      <li>
        Que el/los terminal/es no tiene/n un origen ilícito, fraudulento ni se
        trata/n de falsificaciones.
      </li>
      <li>Que está libre de cargas, gravámenes o derechos de terceros.</li>
      <li>
        Que ha desbloqueado el/los terminal/es, retirado la/s tarjeta/s SIM
        de/los terminal/es y ha procedido al completo borrado del contenido y
        demás datos almacenados y desactivado: a) La cuenta de iCloud y/o la
        aplicación "Buscar mi iPhone" para dispositivos iOS, b) La aplicación
        Android Device Manager para dispositivos Android y c) Cuenta Google.
      </li>
    </ul>
    Corporate Mobile Recycling España, S.L manifiesta que no podrá adquirir de
    los Clientes aquellas dispositivos iPhone o iPad de la marca APPLE que
    tengan un bloqueo de activación a través de una cuenta de iCloud o "Buscar
    mi iPhone", ni aquellos dispositivos con sistema operativo Android con la
    aplicación "Android Device Manager" o "Google Account" activada de cualquier
    fabricante.
    <br />
    Corporate Mobile Recycling España, S.L no aceptará ninguna responsabilidad
    en relación con la seguridad, protección, confidencialidad o uso de los
    datos contenidos en el/los dispositivos incluidos en el presente contrato y
    es de la exclusiva responsabilidad del cliente asegurarse de que dichos
    datos sean eliminados de su/s dispositivo/s antes entregarlo. La propiedad
    de estos terminales se transmitirá a Corporate Mobile Recycling España, S.L
    en el instante en que se acepte este contrato por parte del cliente Y SE
    ENTREGUE EL TERMINAL POR EL CLIENTE, momento a partir del cual , ya no
    ostentará ni dispondrá de derecho alguno sobre el/los terminal/es o
    cualquiera de los elementos que componen el/los mismo/s.
    <br />
    {storeName} y sus tiendas filiales asociadas NO SON parte del Contrato que
    se formaliza entre el Cliente y Corporate Mobile Recycling España, S.L., y
    EN CONSECUENCIA EL CLIENTE NO TENDRÁ DERECHO A ACCIÓN ALGUNA CONTRA NINGUNA
    SOCIEDAD MEDIA MARKT DERIVADA DEL CONTRATO SUSCRITO CON Corporate Mobile
    Recycling España SL. El/Los dispositivos vendido/s en el presente contrato
    no están sujetos a IVA. Protección de datos: Corporate Mobile Recycling
    España, S.L, se compromete a no divulgar los datos de carácter personal
    recogidos en el presente contrato y solo quedaran almacenado en sus archivos
    para constancia de la transacción económica incluida en el mismo. Solo en el
    caso que la información sea requerida por alguna autoridad (Fuerzas y
    cuerpos de seguridad del estado, por orden judicial, Agencia tributaria, u
    otro organismo competente), Corporate Mobile Recycling España, S.L,
    comunicará los datos solicitados por las mismas. En ningún caso sus datos
    serán cedidos a ninguna sociedad MEDIA MARKT. El responsable del tratamiento
    de los datos es CMR ESPAÑA, SL. Si quiere más información sobre sus
    derechos, el ejercicio de los mismos y la política de privacidad de
    Corporate Mobile Recycling España, S.L consulte el link Política de
    Privacidad de nuestra página web (
    <a href='http://www.cmrecycling.co.uk/privacy-rotice.php'>
      http://www.cmrecycling.co.uk/privacy-rotice.php
    </a>
    ).
  </div>
);
const PTReceiptInfo = ({ customerName, customerID, wlName }) => (
  <div className='receipt-text__portugal'>
    {customerName} maior de idade, portador do cartão de cidadão {customerID},
    declara e subscreve:
    <br />
    - Que é o legítimo proprietário do(s) dispositivo (s) de transmissão e
    recepção de dados, identificado(s) no presente contrato;
    <br />
    - Que o(s) dispositivo (s) não tem (têm) origem ilícita, fraudulenta, nem
    foi (foram) adulterado(s);
    <br />
    - Que está(ão) livre(s) de ónus, encargos ou qualquer outro direito que
    tenha sido atribuído a terceiros;
    <br />
    - Que desbloqueou o(s) dispositivo (s), retirou do (s) mesmo (s) o cartão
    SIM, e procedeu à eliminação do seu conteúdo e dos dados armazenados, tendo
    ainda:
    <br />
    <ul>
      <li>
        Desativado a conta ICloud e/ ou a aplicação “Procurar o meu iPhone” nos
        dispositivos iOS;
      </li>
      <li>
        Desativado a aplicação “Android Device Manager” para dispositivos
        “Android” e;
      </li>
      <li>Desativado a Conta Google.</li>
    </ul>
    <br />
    - Que tem conhecimento que a “Corporate Mobile Recycling España, S.L”, não
    adquire dos clientes dispositivos iPhone e iPad da marca Apple que tenham um
    bloqueio de ativação através de uma conta iCloud ou “Procurar meu iPhone”,
    nem aqueles dispositivos de qualquer fabricante com sistema operativo
    Android com a aplicação “Android Device Manager” ou “Google Account”
    activada.
    <br />
    - Que tem conhecimento e aceita que a “Corporate Mobile Recycling España,
    S.L” repudia qualquer tipo de responsabilidade relacionada com a segurança,
    proteção ou cofidencialidade do uso de dados constantes do dispositivo (s)
    móvel (eis) a que respeita o presente contrato e que é da exclusiva
    responsabilidade do cliente que os mesmos sejam integralmente eliminados
    antes de os entregar.
    <br />
    - A propriedade do(s) dispositivo (s) móvel(eis) transmite-se à “Corporate
    Mobile Recycling España, S.L” no momento em que aceitei e subscrevi o
    presente contrato e entreguei o(s) dispositivo(s), momento a partir do qual
    deixarei de me arrogar, nem poderei dispor de qualquer tipo de direito sobre
    os mesmos ou de qualquer dos elementos que o compõem;
    <br />- A {wlName}, seus estabelecimentos, filiais ou associados são
    entidades alheias ao presente contrato, não sendo parte do mesmo,
    tratando-se de uma exclusiva relação entre mim e a Corporate Mobil Recycling
    LTD e, em consequência, RECONHEÇO QUE NÃO TENHO QUALQUER DIREITO DE AÇÃO OU
    RECLAMAÇÃO CONTRA a {wlName} DERIVADA DO CONTRATO CELEBRADO COM CORPORATE
    MOBILE RECYCLING, LTD.
    <br />- Aceito que o valor atribuído ao(s) dispositivo(s) decorrentes do
    contrato, será pago na forma de desconto ou crédito na aquisição de que
    novos dispositivos móveis nas lojas {wlName} e que não posso exigir qualquer
    montante em dinheiro ou qualquer outra forma de pagamento ou compensação.
    <br />
    - Proteção de dados: Tomei conhecimento e fui esclarecido que Corporate
    Mobile Recycling España, S.L, se compromete a não divulgar os dados de
    natureza pessoal constantes do contrato, que os mesmos serão mantidos nos
    seus arquivos e apenas com o intuito de documentar a transação económica
    refletida no contrato.
    <br />
    - Que tenho conhecimento e aceito que a informação de dados pessoais possa
    ser transmitida quando requerida por entidades oficiais tributárias, ordem
    judicial ou outro organismo estatal com competencia de investigação
    criminal, e que me foi garantido que nunca os mesmos por qualquer razão
    serão transmitidos a terceiros.
    <br />
    - Que me foi dado conhecimento que o tratamento dos dados pessoais são da
    responsabilidade de Corporate Mobile Recycling España, S.L.
    <br />- Que fui informado que qualquer outra informação sobre os meus
    direitos, o exercício dos mesmos e a política de privacidade de Corporate
    Mobile Recycling España, S.L, pode ser consultada no link Política de
    Privacidad da página web da mesma
    (http://www.cmrecycling.co.uk/privacy-notice.php).
  </div>
);

class ReceiptPrint extends Component {
  printReceipt = () => window.print();

  renderText = (text, additionals) => {
    const { recycleTermsURL, country, customer, store, wlName } = this.props;

    if (country === 'ES') {
      return (
        <ESReceiptInfo
          customerName={customer.name}
          customerID={customer.idNumber}
          storeName={store.name}
        />
      );
    } else if (country === 'PT') {
      return (
        <PTReceiptInfo
          customerName={customer.name}
          customerID={customer.idNumber}
          wlName={wlName}
        />
      );
    } else {
      return (
        <div>
          {text.map((word) => {
            return <p>{word}</p>;
          })}
          {additionals.recycleTerms && recycleTermsURL && (
            <p>
              {additionalInfo().recycleTerms}{' '}
              <a
                className='quote-receipt__terms-url'
                target='_blank'
                href={recycleTermsURL}
                rel='noreferrer'
              >
                {recycleTermsURL}
              </a>
            </p>
          )}
        </div>
      );
    }
  };

  renderReceiptPrintItem = (
    text,
    additionals = { clientStoreCopy: false, recycleTerms: false },
  ) => {
    const {
      country,
      currency,
      customer,
      customerTradecartApproval,
      devices,
      orderDate,
      language,
      quoteID,
      tradecartID,
      store,
      totalValue,
      totalSwapCost,
      source,
      barcodeReference,
      recycleBarCodeFormat,
    } = this.props;

    return (
      <Fragment>
        <div className='print-title'>
          <div className='quote-info'>
            <h1>
              <Trans id='receipt.print.subtitle'>Quote</Trans>{' '}
              {`${quoteID ? quoteID : tradecartID}`}
            </h1>

            <RenderDetails
              classes='inline dates'
              config={config.datesTradeIn}
              data={{
                orderDate: orderDate,
              }}
            />

            {country !== 'PT' && <OutcomesHeader />}
          </div>
        </div>

        <div className='print-title-logo'>
          <Barcode
            height={40}
            value={barcodeReference}
            options={{ format: recycleBarCodeFormat, textMargin: 0 }}
          />

          <Logo version='secondary' />
        </div>

        <div className='cust-retail-data'>
          <ul>
            <li>
              <h2>
                <Trans id='receipt.print.customer.title'>Customer</Trans>
              </h2>
            </li>
            <li>{customer.name}</li>
            <li>{customer.email}</li>
            <li>{customer.phone}</li>
            {customer.idNumber && <li>{customer.idNumber}</li>}
            <li>
              {[
                customer?.address,
                customer?.postalCode,
                customer?.city,
                customer?.province,
              ]
                .filter(Boolean)
                .join(', ')}
            </li>
          </ul>

          <ul>
            <li>
              <h2>{store.retailerName}</h2>
            </li>
            <li>{store.streetAddress}</li>
            <li>{store.name}</li>
            <li>{store.email}</li>
            <li>{store.phone}</li>
            <li>{store.city}</li>
            <li>{store.country}</li>
          </ul>
        </div>

        <DevicesSummary
          currency={currency}
          language={language}
          totalValue={totalValue}
          totalSwapCost={totalSwapCost}
          devices={devices}
          source={source}
        />
        {additionals.clientStoreCopy && (
          <h2>{additionalInfo().clientStoreCopy}</h2>
        )}
        {this.renderText(text, additionals)}

        <div className='sign-area'>
          {customerTradecartApproval && (
            <Trans id='receipt.print.signed.on'>
              Accepted and digitally signed on{' '}
              {dateFormatter(orderDate, language, false)}.
            </Trans>
          )}
          {country === 'PT' && (
            <Trans id='receipt.print.client.accepts'>
              The client signs accepting the conditions of this contract
            </Trans>
          )}
          <div className='sign-space'>
            <Trans id='receipt.print.signature'>Signature:</Trans>
            <span className='sign-line' />
          </div>
        </div>
      </Fragment>
    );
  };

  render() {
    const { wlName, country } = this.props;

    return (
      <Section
        className={classNames('quote-receipt print', {
          'quote-receipt__portugal': country === 'PT',
        })}
      >
        <Button classes='btn-print' onClick={this.printReceipt}>
          <img src={print} alt='print' />

          <Trans id='receipt.print.title'>Print Receipt</Trans>
        </Button>
        {this.renderReceiptPrintItem(customerText(wlName), {
          recycleTerms: true,
        })}
        <div className='divider'>
          <h2>
            <Trans id='receipt.print.store.copy.title'>Store copy</Trans>
          </h2>
        </div>
        {this.renderReceiptPrintItem(customerText(wlName), {
          clientStoreCopy: true,
          recycleTerms: true,
        })}
        <div className='divider'>
          <h2>
            <Trans id='receipt.print.cmr.copy.title'>Store copy</Trans>
          </h2>
        </div>
        {this.renderReceiptPrintItem(storeText())}
      </Section>
    );
  }
}

export default connect(
  ({ recycle, whitelabel }) => ({
    customer: recycle.receipt?.customer,
    devices: recycle.receipt?.devices,
    expiredDate: recycle.receipt?.expiredDate,
    orderDate: recycle.receipt?.orderDate,
    quoteID: recycle.receipt?.quoteID,
    store: recycle.receipt?.store,
    source: recycle.receipt?.source,
    totalValue: recycle.receipt?.totalValue,
    totalSwapCost: recycle.receipt?.totalSwapCost,
    country: whitelabel.country,
    customerTradecartApproval: whitelabel.configs.customerTradecartApproval,
    currency: whitelabel.currency,
    language: whitelabel.language,
    wlName: whitelabel.name,
    recycleTermsURL: whitelabel.recycleTermsURL,
    barcodeReference: recycle.receipt?.barcodeReference,
    recycleBarCodeFormat: whitelabel.configs?.recycleBarCodeFormat,
  }),
  null,
)(trackPage(ReceiptPrint, 'RecycleReceiptPrint'));
