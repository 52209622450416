import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { SWAP, TRADE_IN } from 'constants/recycle';

import RecycleMultiSearch from './components/MultiSearch';
import RecycleSearch from './components/Search';

export const RenderRecycleSearch = () => {
  const { orderUUID } = useParams();

  const hasPosaleMultiDeviceTradein = useSelector(
    ({ whitelabel }) => whitelabel?.configs?.hasPosaleMultiDeviceTradein,
  );

  return (
    <Fragment>
      {hasPosaleMultiDeviceTradein ? (
        <RecycleMultiSearch
          recycleType={orderUUID ? SWAP : TRADE_IN}
          orderUUID={orderUUID}
        />
      ) : (
        <RecycleSearch orderUUID={orderUUID} />
      )}
    </Fragment>
  );
};

export default RenderRecycleSearch;
