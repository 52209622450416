import {
  deleteAttachments,
  getAttachmentsAction,
} from 'actions/admin/attachments';
import { closeModalAction, openModalAction } from 'actions/ui';
import { ReactComponent as DeleteAllIcon } from 'assets/images/Ico-bin.svg';
import { reset } from 'redux-form';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import Heading from 'components/Heading';
import Modal from 'components/Modal';
import FileUploadModal from 'components/file_upload/FileUploadModal';
import IconApproved from 'components/icons/IconApproved';
import Table from 'components/table/Table';

import useQueryParams from 'hooks/useQueryParams';

import './Attachments.styl';
import AttachmentForm from './components/AttachmentForm';

const AttachmentNotification = ({ notification }) => {
  return (
    <>
      {notification && (
        <div
          className={
            'attachment__header__notification attachment__notification'
          }
        >
          <IconApproved className='attachment__notification__icon' />
          {notification}
        </div>
      )}
    </>
  );
};

const AttachmentsActions = ({
  isAdminPage,
  dispatch,
  setExtension,
  extension,
  fileExtensions,
  selected,
}) => {
  return (
    <div className='attachments__table_actions_container'>
      <i className='attachments__disclaimer'>{`*Allowed files and sizes....`}</i>
      <div className='attachments__dropdown'>
        <Dropdown
          placeholder={'Show all files'}
          input={{
            onChange: async (ext) => {
              setExtension(ext);
              dispatch(getAttachmentsAction(ext));
            },
            onFocus: () => {},
            onBlur: () => {},
            value: extension,
          }}
          meta={{
            active: false,
            error: '',
            touched: false,
          }}
          variant='underline'
          clearable={true}
          options={fileExtensions}
        />
      </div>
      {isAdminPage && (
        <Button
          disabled={selected}
          onClick={() => {
            dispatch(openModalAction('delete-attachments'));
          }}
        >
          <DeleteAllIcon />
        </Button>
      )}
      <Button
        onClick={() => {
          dispatch(openModalAction('fileUpload'));
        }}
      >
        {t({
          id: 'table.actions.upload.file',
          message: `Upload file`,
        })}
      </Button>
      <Button
        onClick={() => {
          dispatch(openModalAction('create-attachment'));
        }}
      >
        {t({
          id: 'table.actions.create.attachment',
          message: `Create attachment`,
        })}
      </Button>
    </div>
  );
};

const Attachments = () => {
  const dispatch = useDispatch();
  const {
    queryParamsObject: { campaignCode, templateName },
  } = useQueryParams();
  const isAdminPage = !(campaignCode && templateName);

  const columns = [
    {
      label: t({ id: 'table.column.attachment.id', message: `ID` }),
      accessor: 'id',
      type: 'text',
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.attachment.full.name',
        message: `File Name`,
      }),
      accessor: 'fileName',
      type: 'text',
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.attachment.size', message: `Size` }),
      accessor: 'fileSize',
      type: 'text',
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: true,
    },
    {
      label: t({
        id: 'table.column.attachment.campaingIDs',
        message: `Campaigns`,
      }),
      accessor: 'campaigns',
      type: 'multiLine',
      filterable: true,
      sortable: true,
      align: 'left',
      wrap: true,
    },
    {
      label: t({
        id: 'table.column.attachment.templates',
        message: `Templates`,
      }),
      accessor: 'templates',
      type: 'multiLine',
      filterable: true,
      sortable: true,
      align: 'left',
      wrap: true,
    },
    {
      label: t({ id: 'table.column.attachment.uploaded', message: `Uploaded` }),
      accessor: 'uploadedAt',
      type: 'text',
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.attachment.uploaded.by',
        message: `Uploaded by`,
      }),
      accessor: 'uploadedBy',
      type: 'text',
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.actions', message: `Actions` }),
      accessor: 'actions',
      type: 'actions',
      filterable: true,
      sortable: true,
      align: 'center',
      wrap: false,
    },
  ];

  const actions = [
    {
      label: t({
        id: 'table.column.actions.delete',
        message: `Delete`,
      }),
      handler: (_, { id }) => {
        setSelected([id]);
        dispatch(openModalAction('delete-attachments'));
      },
    },
    {
      label: t({ id: 'table.column.actions.add', message: `Add` }),
      handler: (_, { id }) => {
        setSelected([id]);
        // dispatch(openModalAction('delete-attachments'));
      },
    },
  ];

  const attachments = useSelector(
    ({ adminAttachments }) => adminAttachments?.attachments,
  );

  useEffect(() => {
    dispatch(getAttachmentsAction());
  }, [dispatch]);
  const [extension, setExtension] = useState();
  const [selected, setSelected] = useState([]);
  const [notification, setNotification] = useState();

  useEffect(() => {
    if (notification) {
      const timeout = setTimeout(() => setNotification(), 10000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [notification]);

  const cols = isAdminPage
    ? columns
    : columns.filter(
        (column) =>
          column.accessor !== 'templates' && column.accessor !== 'campaigns',
      );

  const rows = attachments?.map((attachment) => ({
    ...attachment,
    actions: [attachment.associated || isAdminPage ? actions[0] : actions[1]],
  }));

  const fileExtensions = [
    ...new Set(attachments?.map((attachment) => attachment.extension)),
  ].map((extension) => ({
    value: extension,
    label: extension,
  }));

  return (
    <>
      <div className='attachment__header'>
        <Heading classes='attachment__header__title' size='big'>
          <Trans id='attachments.list.title'>
            {templateName && `${templateName} `}
            Attachments
          </Trans>
        </Heading>
        <AttachmentNotification notification={notification} />
      </div>
      <AttachmentsActions
        isAdminPage={isAdminPage}
        dispatch={dispatch}
        extension={extension}
        setExtension={setExtension}
        length={selected.length === 0}
        fileExtensions={fileExtensions}
      />
      <Table
        columns={cols}
        data={rows}
        keyColumn='fileName'
        defaultOrder='uploadedAt'
        defaultDir='desc'
        filterable={false}
        multiSelectOptions={{
          onMultiSelectChange: (values) => {
            setSelected(values.map((v) => v.id));
          },
        }}
        multiSelect={isAdminPage}
      />
      <AttachmentForm />
      <Modal
        name='delete-attachments'
        onConfirm={() => {
          dispatch(deleteAttachments(selected));
          setNotification(
            `${selected.length} ${
              selected.length > 1 ? 'files' : 'file'
            } successfully deleted.`,
          );
          setSelected([]);
        }}
      >
        {selected.length > 1 &&
          `${selected.length} (of ${attachments.length}) files selected. `}
        Are you sure you want to delete this?
      </Modal>
      <FileUploadModal
        type='TBD'
        handleUpload={(file) => {
          console.log(`uploadfile`);
        }}
        closeModal={() => {
          dispatch(reset('fileUpload'));
          dispatch(closeModalAction());
        }}
        title='Upload Attachment'
        accept='*'
      />
    </>
  );
};

export default Attachments;
