import { GET_VOUCHERS } from 'actions/admin/voucher';

import initialState from 'reducers/initialState';

export default function (state = initialState.adminVoucher, action) {
  switch (action.type) {
    case GET_VOUCHERS:
      return {
        ...state,
        vouchers: action.data,
      };
    default:
      return state;
  }
}
