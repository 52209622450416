/*
  initialization:
  flow({
    name: "catalog",
    steps: 3,
  })

  provides:
    props
    .next            // func, increases currentStep
    .prev            // func, decreases currentStep
    .start           // func, makes currentStep the first step
    .end             // func, makes currentStep the last step
    .setSteps        // func, updates the steps array
    .syncFlow        // func, change currentStep to any page within the boundaries
    .discardFlow     // func, should be called on componentWillUnmount
    .currentStep     // integer
    .currentStepName // string
*/
import {
  addFlow,
  flowEnd,
  flowGoto,
  flowNextStep,
  flowPrevStep,
  flowSetSteps,
  flowStart,
  removeFlow,
} from 'actions/flow';
import isEmpty from 'lodash/isEmpty';
import store from 'store';

import { connect } from 'react-redux';

const matchToURL = (URL, path) =>
  new RegExp('^' + path.replace(/#\d/g, '[^/]*') + '?$').test(URL);

const flow = (properties = {}) => {
  const { name } = properties;

  if (!store?.getState().flow[name]) {
    store?.dispatch(addFlow(properties));
  }

  const discardFlow = () => store.dispatch(removeFlow(name));
  const end = () => store.dispatch(flowEnd(name));
  const initFlow = () => store.dispatch(addFlow(properties));
  const next = () => store.dispatch(flowNextStep(name));
  const prev = () => store.dispatch(flowPrevStep(name));
  const setSteps = (steps) => store.dispatch(flowSetSteps({ name, steps }));
  const start = () => store.dispatch(flowStart(name));

  return connect(({ flow }) => {
    const activeFlow = flow[name] || { steps: [] };

    const getStepName = (path, prefix = '') => {
      return activeFlow.steps.find((page) =>
        matchToURL(path, `${prefix}/${page.path}`),
      )?.name;
    };

    const syncFlow = async (prefix = '', steps = activeFlow.steps) => {
      const newStep = !isEmpty(steps)
        ? steps.findIndex((page) =>
            matchToURL(window.location.pathname, `${prefix}/${page.path}`),
          )
        : 0;

      newStep >= 0 && (await store.dispatch(flowGoto({ name, newStep })));

      return activeFlow.steps[newStep]?.name;
    };

    return {
      discardFlow,
      end,
      initFlow,
      next,
      prev,
      setSteps,
      start,
      syncFlow,
      getStepName,
      total: activeFlow.total,
      steps: activeFlow.steps,
      currentStep: activeFlow.currentStep || 0,
      currentStepName: activeFlow.currentStepName || '',
    };
  });
};

export default flow;
