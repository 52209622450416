import store from 'store';

import React, { Component } from 'react';

export default function withPolling(WrappedComponent, options) {
  return class withPolling extends Component {
    constructor(props) {
      super(props);

      this.state = {};
      this.intervalRef = null;
    }

    performCallback = () => {
      if (options.hasDispatch) {
        store.dispatch(options.callback({ ...this.props, ...options.args }));
      } else {
        options.callback({ ...this.props, ...options.args });
      }
    };

    componentDidMount() {
      this.performCallback();
      this.intervalRef = setInterval(this.performCallback, options.interval);
    }

    componentWillUnmount() {
      this.stopPolling();
    }

    stopPolling = () => {
      clearInterval(this.intervalRef);
    };

    render() {
      return (
        <WrappedComponent stopPolling={this.stopPolling} {...this.props} />
      );
    }
  };
}
