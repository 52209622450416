import { postponeContractAction } from 'actions/contract';
import { closeModalAction, openModalAction } from 'actions/ui';
import { Field, reduxForm } from 'redux-form';

import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Trans } from '@lingui/macro';

import Button from 'components/Button';
import Modal from 'components/Modal';
import RenderTextAreaField from 'components/RenderTextAreaField';

import { rootUrl } from 'constants/routes';

import './PostponeContract.styl';

const PostponeContract = ({ handleSubmit, pristine, submitting }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { cartUUID } = useParams();

  return (
    <div className='postpone-contract-modal'>
      <Button
        // TODO make this component reliant only on class functions
        // in order to remove arrow functions and enforce this rule
        // eslint-disable-next-line react/jsx-no-bind
        onClick={() => dispatch(openModalAction('postpone-contract-form'))}
        testSelector='finish-later-button'
      >
        <Trans id='contract.postpone.title'>Finish Later</Trans>
      </Button>

      <Modal name='postpone-contract-form'>
        <h2>
          <Trans id='contract.postpone.form.title'>Postpone contract</Trans>
        </h2>
        <Trans id='contract.postpone.form.message'>
          Use the box below to add any relevant notes
        </Trans>

        <form>
          <Field
            name='note'
            component={RenderTextAreaField}
            cols='60'
            testSelector='postpone-contract-note'
          />

          <div className='postpone-contract-actions'>
            <Button
              type='submit'
              disabled={pristine || submitting}
              onClick={handleSubmit(async (values) => {
                await dispatch(postponeContractAction(values, cartUUID));
                navigate(rootUrl);
              })}
              testSelector='postpone-button'
            >
              <Trans id='contract.postpone.form.submit'>Postpone</Trans>
            </Button>

            <Button
              classes='inverted'
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => dispatch(closeModalAction())}
              testSelector='close-button'
            >
              <Trans id='contract.postpone.form.close'>Close</Trans>
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default reduxForm({
  form: 'postpone-contract',
})(PostponeContract);
