import debounce from 'lodash/debounce';

import React, { useCallback, useEffect, useState } from 'react';

const InfiniteScroll = ({
  endPagination = false,
  fetchItems,
  items = [],
  page,
  children,
}) => {
  const handleScroll = debounce(() => {
    let windowHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;

    let scrollPos =
      window.scrollY ||
      window.pageYOffset ||
      document.documentElement.scrollTop;

    if (Math.ceil(windowHeight + scrollPos) >= document.body.offsetHeight) {
      setIsFetching(true);
    }
  }, 300);

  const [pageState, changePage] = useState(!isNaN(page) ? page : 0);

  const [isFetching, setIsFetching] = useState(false);

  const fetchMoreListItems = useCallback(() => {
    changePage(() => {
      let nextPage = (!isNaN(page) ? page : pageState) + 1;
      fetchItems(nextPage);

      return nextPage;
    });

    setIsFetching(false);
  }, [page, pageState, fetchItems]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    if (!isFetching || endPagination) return;

    fetchMoreListItems();
  }, [isFetching, endPagination, fetchMoreListItems]);

  return Object.entries(items).map(([key, list]) => {
    return React.Children.map(children, (child) => {
      return React.cloneElement(child, {
        keyValue: key,
        data: list,
      });
    });
  });
};

export default InfiniteScroll;
