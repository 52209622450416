import { LIST_USERS } from 'actions/admin/user';

import initialState from 'reducers/initialState';

export default function admin(state = initialState.adminUser, action) {
  switch (action.type) {
    case LIST_USERS:
      return { ...state, users: action.data };
    default:
      return state;
  }
}
