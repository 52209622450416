import classNames from 'classnames';
import { currencyFormatter } from 'helpers/formatters';
import { safeHTMLParser } from 'utils';

import React from 'react';

import { Trans } from '@lingui/macro';

import { ImageThumbnail } from 'components/Image';

import './Item.styl';

const stockLevelMap = {
  OUT_OF_STOCK: {
    label: <Trans id='stock.level.out.label'>Out of stock</Trans>,
    marker: 'red',
  },
  LOW_STOCK: {
    label: <Trans id='stock.level.low.label'>Low stock</Trans>,
    marker: 'yellow',
  },
  IN_STOCK: {
    label: <Trans id='stock.level.available.label'>Available</Trans>,
    marker: 'green',
  },
  NO_STOCK_INFORMATION: undefined,
};

const Item = ({
  item,
  idKey,
  nameKey,
  showId,
  type,
  currency,
  language,
  highlight,
  handleClick,
}) => {
  const {
    img_url,
    name,
    price,
    price_difference,
    selected,
    strikethroughPrice,
    stockLevel,
  } = item;

  const stockDetails =
    stockLevelMap[stockLevel?.stockLevel ?? 'NO_STOCK_INFORMATION'];

  return (
    <li
      className={classNames(`item ${type}`, {
        selected,
        disabled: typeof item.enabled !== 'undefined' && !item.enabled,
      })}
      // TODO make this component reliant only on hooks in order
      // to remove arrow functions and enforce this rule
      // eslint-disable-next-line react/jsx-no-bind
      onClick={() =>
        (typeof item.enabled === 'undefined' || item.enabled) &&
        handleClick(item[idKey])
      }
    >
      {type === 'image' && img_url && (
        <div className='item-image-wrapper'>
          <ImageThumbnail src={img_url} alt={name} />
        </div>
      )}
      {(type === 'circle' || (type === 'image' && !img_url)) && (
        <span className='selection-indicator' />
      )}

      <section className='details'>
        <p className='item-name'>{safeHTMLParser(item[nameKey])}</p>
        {showId ? (
          <p className='item-id'>{item[idKey]}</p>
        ) : (
          <p className='item-id'>&nbsp;</p>
        )}

        {price_difference != null && (
          <p className={classNames('item-price-difference', { highlight })}>
            {price_difference >= 0 ? '+ ' : ''}
            {currencyFormatter(price_difference, currency, language)}
            <span>
              <Trans id='extras.per.month'> / month</Trans>
            </span>
          </p>
        )}

        {price > 0 && (
          <p className='item-price'>
            ({currencyFormatter(price, currency, language)})
            {strikethroughPrice > 0 && (
              <span className='item-price--strikethrough'>
                ({currencyFormatter(strikethroughPrice, currency, language)})
              </span>
            )}
          </p>
        )}
        {stockDetails && (
          <div className='stock-level'>
            <div
              className={`stock-level-marker stock-level-marker-${stockDetails.marker}`}
            ></div>
            <span className='stock-level-label'>{stockDetails.label}</span>
          </div>
        )}
      </section>
    </li>
  );
};

Item.defaultProps = {
  highlight: false,
  showId: false,
};

export default Item;
