import { exportReportsAction } from 'actions/reports';
import { getFormValues } from 'redux-form';
import { convertParamsToObj } from 'utils';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';

import Button from 'components/Button';
import Footer from 'components/Footer';
import Loader from 'components/Loader';
import Section from 'components/Section';
import Table from 'components/table/Table';

import { MAX_CONTRACTS_DISPLAYED } from 'constants/contracts';

import useTrackPage from 'hooks/useTrackPage';

import './Contracts.styl';
import FilterForm from './Filter';
import { FILTER_FORM_NAME } from './Filter';
import SearchForm from './Search';

const Contracts = ({
  name,
  title,
  defaultOrder,
  rows,
  columns,
  noWordBreak = false,
  isSales,
  initialFilterValues,
}) => {
  useTrackPage(name);

  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const loading = useSelector(({ ui }) => ui.loading);

  const filterFormValues = useSelector((state) =>
    getFormValues(FILTER_FORM_NAME)(state),
  );
  const colNames = [
    {
      key: 'creation_date',
      header: t({ id: 'table.column.created', message: `Created on` }),
    },
    {
      key: 'activationDate',
      header: t({
        id: 'table.column.activated',
        message: `Activated on`,
      }),
    },
    {
      key: 'lastUpdateDatetime',
      header: t({
        id: 'table.column.updated',
        message: `Updated on`,
      }),
    },
    {
      key: 'vendor',
      header: t({ id: 'table.column.vendor', message: `Vendor` }),
    },
    {
      key: 'store',
      header: t({ id: 'table.column.store', message: `Store` }),
    },
    {
      key: 'order_uuid',
      header: t({ id: 'table.column.order', message: `Order` }),
    },
    {
      key: 'customer',
      header: t({ id: 'table.column.customer', message: `Customer` }),
    },
    {
      key: 'device',
      header: t({ id: 'table.column.device', message: `Device` }),
    },
    {
      key: 'extrasDescription',
      header: t({ id: 'table.column.extras', message: `Extras` }),
    },
    {
      key: 'financing_type',
      header: t({
        id: 'table.column.financing.type',
        message: `Financing Type`,
      }),
    },
    {
      key: 'status',
      header: t({
        id: 'table.column.contract.status',
        message: `Contract Status`,
      }),
    },
    {
      key: 'swapStatus',
      header: t({ id: 'table.column.swap.status', message: `Swap Status` }),
    },
    {
      key: 'expiry_date',
      header: t({ id: 'table.column.expired', message: `Expiry Date` }),
    },
    {
      key: 'amount_financed',
      header: t({
        id: 'table.column.amount',
        message: `Amount Finance`,
      }),
    },
    {
      key: 'currency',
      header: t({ id: 'table.column.currency', message: `Currency` }),
    },
    {
      key: 'deviceReference',
      header: t({
        id: 'table.column.device.reference',
        message: `Device Reference`,
      }),
    },
    {
      key: 'funderReference',
      header: t({
        id: 'table.column.funder.reference',
        message: `Funder Reference`,
      }),
    },
    {
      key: 'milestone',
      header: t({ id: 'table.column.milestone', message: `Milestone` }),
    },
    {
      key: 'notes',
      header: t({ id: 'table.column.notes', message: `Notes` }),
    },
  ];

  const exportClickHandler = () => {
    let formValues = filterFormValues;
    const params = convertParamsToObj(searchParams, ['contractStatus']);

    // Params should always be at least 1, for the active form
    if (Object.keys(params).length > 1) {
      formValues = params;
    }
    dispatch(
      exportReportsAction(
        {
          ...formValues,
          onlySalesWithPendingActions: !isSales,
        },
        colNames,
      ),
    );
  };

  return (
    <>
      {loading && <Loader />}

      <h1 className='admin-page-title' data-test={`posale-${name}-title`}>
        {title}
      </h1>

      <Section
        inline
        className='sales__forms'
        data-test={`posale-${name}-forms`}
      >
        <SearchForm isSales={isSales} />
        <FilterForm initialValues={initialFilterValues} isSales={isSales} />
      </Section>

      <Section>
        <div className='sales-report-actions'>
          {rows.length >= MAX_CONTRACTS_DISPLAYED && (
            <Trans id='reports.export.warning'>
              The table displays a maximum of {MAX_CONTRACTS_DISPLAYED}{' '}
              contracts in total. If you want to get information regarding more
              contracts, please export to CSV.
            </Trans>
          )}
          <Button
            testSelector='export-filters-button'
            type='button'
            disabled={rows.length === 0}
            onClick={exportClickHandler}
          >
            <Trans id='reports.export.button'>Export CSV</Trans>
          </Button>
        </div>

        <Table
          withOverflow
          columns={columns}
          classes='sales-report-table'
          data={rows}
          keyColumn='order_uuid'
          defaultOrder={defaultOrder}
          defaultDir='desc'
          filterable={false}
          sortable={false}
          noWordBreak={noWordBreak}
        />
      </Section>

      <Footer classes='main' copyright={false} />
    </>
  );
};

export default Contracts;
