import * as React from 'react';

function SvgIconApproved({ title, titleId, ...props }) {
  return (
    <svg
      viewBox='0 0 24 24'
      width='1em'
      height='1em'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <clipPath id='icon-approved_svg__a'>
          <path d='M0 0h24v24H0z' />
        </clipPath>
      </defs>
      <g clipPath='url(#icon-approved_svg__a)'>
        <g fill='rgba(255,255,255,0)' stroke='currentColor' strokeWidth={2}>
          <circle cx={12} cy={12} r={12} stroke='none' />
          <circle cx={12} cy={12} r={11} fill='none' />
        </g>
        <path
          fill='currentColor'
          d='M10 13.586l5.293-5.293 1.414 1.414L10 16.414l-3.707-3.707 1.414-1.414z'
        />
      </g>
    </svg>
  );
}

export default SvgIconApproved;
