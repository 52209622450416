import { savePageNameAction } from 'actions/ui';
import { trackPageView } from 'helpers/tracking';
import store from 'store';

import { useEffect } from 'react';

function useTrackPage(pageName) {
  return useEffect(() => {
    store.dispatch(savePageNameAction(pageName));
    trackPageView(pageName, window.location.pathname, window.location.href);
  }, [pageName]);
}

export default useTrackPage;
