import { clearRecycleAction } from 'actions/recycle';
import {
  clearSaleAction,
  getCartActions,
  getCartInvoices,
  getSaleAction,
} from 'actions/sales';
import { dateFormatter } from 'helpers/formatters';
import isEmpty from 'lodash/isEmpty';
import { snakeToText } from 'utils';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';

import Badges from 'components/Badges';
import Button from 'components/Button';
import Footer from 'components/Footer';
import Heading from 'components/Heading';
import Loader from 'components/Loader';
import Section from 'components/Section';
import Stakeholders from 'components/Stakeholders';
import IconAlert from 'components/icons/IconAlert';

import { ACTIVE_STATUS, returnProductStatus } from 'constants/contracts';
import { recycleDetailsUrl, viewSaleUrl } from 'constants/routes';
import { orderDeliveryStatus } from 'constants/status';
import { contractStatus } from 'constants/status';

import useTrackPage from 'hooks/useTrackPage';

import './Sale.styl';
import CancelContract from './components/CancelContract';
import ContractDetails from './components/ContractDetails';
import FinancingStatus from './components/FinancingStatus';
import Invoice from './components/Invoice';
import Messages from './components/Messages';
import PendingActions from './components/PendingActions';
import SaleActions from './components/SaleActions';
import SaleEmpty from './components/SaleEmpty';
import SaleSummary from './components/SaleSummary';

const checkIfItemIsReturned = (item) =>
  returnProductStatus.includes(item.fulfillmentStatus);

const Sale = ({ actionable }) => {
  useTrackPage('Sale');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cartUUID } = useParams();

  const loading = useSelector(({ ui }) => ui.loading);
  const sale = useSelector(({ sales }) => sales.sale);
  const cartInvoices = useSelector(({ sales }) => sales.cartInvoices);
  const saleActions = useSelector(({ sales }) => sales.saleActions);
  const currency = useSelector(({ whitelabel }) => whitelabel.currency);
  const earlySwapMonth = useSelector(
    ({ whitelabel }) => whitelabel.configs.earlySwapMonth,
  );
  const language = useSelector(({ whitelabel }) => whitelabel.language);
  const useAppleValidation = useSelector(
    ({ whitelabel }) => whitelabel.configs.useAppleValidation,
  );
  const withdrawPeriod = useSelector(
    ({ whitelabel }) => whitelabel.configs.withdrawPeriod,
  );
  const wlID = useSelector(({ whitelabel }) => whitelabel.wlId);
  const socketStatus =
    useSelector(({ dashboard }) => dashboard?.socketStatus) || 'Disconnected';

  useEffect(() => {
    dispatch(clearRecycleAction());
    dispatch(getSaleAction(cartUUID));
    dispatch(getCartActions({ cartUUID, wlID }));
    dispatch(getCartInvoices(cartUUID));

    return () => {
      dispatch(clearSaleAction());
    };
  }, [cartUUID, dispatch, wlID]);

  if (loading) return <Loader />;

  if (isEmpty(sale)) return <SaleEmpty />;

  const {
    contracts,
    customer,
    store,
    pickupStore,
    funder,
    summary,
    tradeCartUUID,
    deliveries,
    company,
  } = sale;

  const filteredContracts = contracts.map((contract) => {
    return {
      ...contract,
      products: contract?.products?.filter(
        (product) => !checkIfItemIsReturned(product),
      ),
      extras: contract?.extras?.filter(
        (extra) => !checkIfItemIsReturned(extra),
      ),
    };
  });

  const { dates } =
    contracts.find((contract) => !isEmpty(contract.products)) ||
    contracts.find((contract) => !isEmpty(contract.extras)) ||
    {};

  const isMissingDevRef = filteredContracts.some(
    (contract) => contract?.steps?.hasDeviceReference === false,
  );
  const isMissingActivation = filteredContracts.some(
    (contract) => contract?.steps?.isActive === false,
  );

  const requiresSerialNumbers = contracts.some(
    (contract) => contract?.steps?.requiresSerialNumbers === true,
  );

  const isFullPayment = contracts.some(
    (contract) => contract.financingType === 'CARD_PAYMENT',
  );

  const canApplyForCredit = contracts.some(
    (contract) => contract?.steps?.canApplyForCredit === true,
  );

  const requiresInstallation = contracts.some(
    (contract) => contract?.steps?.requiresInstallation,
  );

  const hasFunderEvents = contracts.some((contract) => contract?.funderData);

  const navigateToRecycleDetails = () =>
    navigate(recycleDetailsUrl(tradeCartUUID));

  const areAllContractsActive = () =>
    actionable &&
    filteredContracts?.length > 1 &&
    filteredContracts.every((contract) => contract.status === ACTIVE_STATUS);

  const titleAnchors = {
    saleDetails: {
      id: 'sale_details',
      label: t({ id: 'sale.details', message: `Sale Details` }),
      active: true,
    },
    salesSummary: {
      id: 'sale_summary',
      label: t({ id: 'sale.summary', message: `Sale Summary` }),
      active: true,
    },
    contractDetails: {
      id: 'contract',
      label: t({ id: 'sale.contract.details', message: `Contract Details` }),
      active: true,
    },
    invoices: {
      id: 'invoices',
      label: t({ id: 'sale.invoices', message: `Invoices` }),
      active: cartInvoices?.length > 0,
    },
    messages: {
      id: 'messages',
      label: t({ id: 'sale.messages.title', message: `Messages` }),
      active: sale?.messages?.length > 0,
    },
    cartActions: {
      id: 'contract_history',
      label: t({ id: 'contract.history', message: `Contract history` }),
      active: saleActions?.length > 0,
    },
    financingStatus: {
      id: 'financing_status',
      label: t({
        id: 'contract.details.financing.status.title',
        message: 'Financing Status',
      }),
      active: true,
    },
  };

  const overallStatus = contractStatus()[sale.overallStatus];

  const scrollTo = (anchorID) => () => {
    navigate(`${viewSaleUrl(cartUUID)}#${anchorID}`);
  };

  return (
    <>
      <div className='sale-header'>
        <Heading classes='sale-title'>
          <Trans id='sale.title'>Sale {cartUUID}</Trans>{' '}
          {contracts.some(
            (contract) => contract.financingType === 'B2B_LEASE',
          ) && (
            <>
              <Trans id='sale.title.b2b'>Business Subscription</Trans>
              <Badges
                badges={{
                  classes: `sale__header__overstatus sale__header__overstatus--${overallStatus.status}`,
                  icon: <IconAlert />,
                  label: overallStatus.label || snakeToText(sale.overallStatus),
                }}
              />
            </>
          )}
        </Heading>

        <div className='sale-title__anchors' data-test='posale-contract-menu'>
          {Object.values(titleAnchors)
            .filter((title) => title.active)
            .map((title) => (
              <div className='sale-title__anchor' onClick={scrollTo(title.id)}>
                {title.label}
              </div>
            ))}
        </div>
      </div>

      {(areAllContractsActive() || tradeCartUUID) && (
        <div className='sale-buttons'>
          {areAllContractsActive() && <CancelContract cartUUID={cartUUID} />}

          {tradeCartUUID && (
            <Button onClick={navigateToRecycleDetails}>
              <Trans id='trade.receipt.title'>Receipt</Trans>
            </Button>
          )}
        </div>
      )}

      {(isMissingDevRef ||
        isMissingActivation ||
        requiresSerialNumbers ||
        requiresInstallation ||
        canApplyForCredit) && (
        <Section>
          <PendingActions
            cartUUID={cartUUID}
            currency={currency}
            contracts={filteredContracts}
            funderName={funder.name}
            language={language}
            salesChannel={sale.salesChannel}
            useAppleValidation={useAppleValidation}
          />
        </Section>
      )}

      <Section id={titleAnchors.saleDetails.id}>
        <Stakeholders
          customer={customer}
          dates={dates}
          funder={funder}
          language={language}
          store={store}
          pickupStore={pickupStore}
          company={company}
          title={t({ id: 'sale.details', message: `Sale Details` })}
        />
      </Section>

      {deliveries?.length > 0 && (
        <Section>
          <h2 data-test='posale-contract-delivery-title'>
            <Trans id='sale.delivery'>Delivery</Trans>
          </h2>

          <table className='sale-summary__table'>
            <thead>
              <tr>
                <th align='left'>
                  <Trans id='sale.shipping.shippedBy'>Shipped by</Trans>
                </th>
                <th align='left'>
                  <Trans id='sale.delivery.reference'>Delivery Reference</Trans>
                </th>
                <th align='left'>
                  <Trans id='sale.shipping.tracking.url'>Tracking URL</Trans>
                </th>
                <th align='left'>
                  <Trans id='sale.shipping.delivery.date'>Delivery at</Trans>
                </th>
                <th align='left'>
                  <Trans id='sale.shipping.delivery.status'>
                    Delivery Status
                  </Trans>
                </th>
              </tr>
            </thead>
            <tbody>
              {deliveries.map((delivery) => (
                <tr id={delivery.uuid} key={delivery.uuid}>
                  <td data-test='posale-contract-delivery-shippedBy'>
                    {delivery.shippedBy}
                  </td>
                  <td data-test='posale-contract-delivery-reference'>
                    {delivery.uuid}
                  </td>
                  <td data-test='posale-contract-delivery-trackingUrl'>
                    <a
                      href={delivery.trackingURL}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {delivery.trackingURL}
                    </a>
                  </td>
                  <td data-test='posale-contract-delivery-deliveryAt'>
                    {dateFormatter(delivery.deliveryAt, language)}
                  </td>
                  <td data-test='posale-contract-delivery-status'>
                    {orderDeliveryStatus()[delivery.deliveryStatus].label}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Section>
      )}

      {hasFunderEvents && (
        <Section
          id={titleAnchors.financingStatus.id}
          hideById={['financing-status']}
          expanded={true}
        >
          <div className='financing__status__heading'>
            <h2>
              <Trans id='contract.details.financing.status.title'>
                Financing Status
              </Trans>
            </h2>
            <span className={socketStatus}>{socketStatus}</span>
          </div>
          {contracts.map((contract) => (
            <FinancingStatus
              cartGUID={sale.cartGUID}
              contract={contract}
              language={language}
            />
          ))}
        </Section>
      )}

      <Section id={titleAnchors.salesSummary.id}>
        <h2 data-test='posale-contract-saleSummary-title'>
          <Trans id='sale.summary'>Sale Summary</Trans>
        </h2>

        <SaleSummary
          contracts={filteredContracts}
          summary={summary}
          currency={currency}
          language={language}
          isFullPayment={isFullPayment}
        />
      </Section>

      {filteredContracts.map((contract) => (
        <Section id={titleAnchors.contractDetails.id}>
          <ContractDetails
            actionable={actionable}
            cartUUID={cartUUID}
            cartGUID={sale.cartGUID}
            contract={{ ...contract, earlySwapMonth, withdrawPeriod }}
            currency={currency}
            funder={funder.name}
            key={contract.id}
            language={language}
          />
        </Section>
      ))}

      {cartInvoices?.length > 0 && (
        <Section id={titleAnchors.invoices.id}>
          <h2 data-test='posale-contract-invoices-title'>
            <Trans id='sale.invoices.title'>Invoices / Credit notes</Trans>
          </h2>
          {cartInvoices
            .sort((a, b) => a.id < b.id)
            .map((cartInvoice, index) => (
              <>
                <Invoice
                  key={cartInvoice.id}
                  invoice={cartInvoice}
                  language={language}
                />
                {index < cartInvoices.length - 1 && (
                  <hr style={{ width: '100%', color: 'var(--border-color)' }} />
                )}
              </>
            ))}
        </Section>
      )}

      {sale?.messages?.length > 0 && (
        <>
          <Section id={titleAnchors.messages.id}>
            <h2>
              <Trans id='sale.messages.title'>Messages</Trans>
            </h2>

            <Messages
              messages={sale?.messages}
              currency={currency}
              language={language}
            />
          </Section>
        </>
      )}

      {saleActions?.length > 0 && (
        <Section
          id={titleAnchors.cartActions.id}
          hideById={['actions', 'actions-pagination']}
        >
          <h2>
            <Trans id='contract.history'>Contract history</Trans>
          </h2>
          <SaleActions saleActions={saleActions} />
        </Section>
      )}

      <Footer classes='main' copyright={false} />
    </>
  );
};

export default Sale;
