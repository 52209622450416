import { currencyFormatter } from 'helpers/formatters';
import { format, numericality, required } from 'helpers/validators';
import { Field, reduxForm } from 'redux-form';

import React from 'react';

import { Trans, t } from '@lingui/macro';

import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import LineItem from 'components/LineItem';
import Modal from 'components/Modal';
import RenderField from 'components/RenderField';

import { discountLabels, discountList } from 'constants/discount';
import { currencyRegex } from 'constants/forms';

import './DiscountForm.styl';

const DiscountForm = ({
  addDiscount,
  availableDiscounts,
  currency,
  handleSubmit,
  invalid,
  language,
  onCloseDiscount,
  order,
  pristine,
  removeDiscount,
  reset,
  submitting,
}) => {
  const { items, total_discounts, max_discounts } = order?.discounts || {};

  const handleDiscountSubmit = async (discount) => {
    await addDiscount(order.uuid, discount);
    reset();
  };

  const handleDiscountRemoval = (discountUUID) => () =>
    removeDiscount(order.uuid, discountUUID);

  return (
    <Modal
      name='discountForm'
      title={t({ id: 'discount.form.title', message: `Add discount` })}
      onClose={onCloseDiscount}
      classes='discountForm'
    >
      <form className='discount-form'>
        <Field
          name='typeID'
          component={Dropdown}
          placeholder={t({
            id: 'discount.form.discount.placeholder',
            message: `Select Discount`,
          })}
          options={discountList().filter(({ configString }) => {
            return availableDiscounts.includes(configString);
          })}
          validate={required()}
          testSelector='discount-form-type'
          variant='underline'
          labelType='outside'
        />

        <Field
          name='value'
          type='text'
          component={RenderField}
          validate={[
            required(),
            numericality({ greaterThan: 0 }),
            format({ with: currencyRegex }),
          ]}
          label={t({
            id: 'discount.form.max.discounts.placeholder',
            message: `Max amount ${max_discounts}`,
          })}
          testSelector='discount-form-value'
        />

        <Button
          type='submit'
          disabled={pristine || submitting || invalid}
          onClick={handleSubmit((discount) => handleDiscountSubmit(discount))}
          testSelector='discount-form-add-btn'
        >
          <Trans id='discount.form.submit.button'>Add</Trans>
        </Button>
      </form>

      <ul data-test='discount-list' className='discount-list'>
        {items?.map(({ type_id, uuid, value, locked, description }) => (
          <li key={uuid} data-test='discount-item' className='discount-item'>
            <div className='discount-details'>
              <span>{description || discountLabels()[type_id]}</span>
              <span>{`${currencyFormatter(value, currency, language)}`}</span>
            </div>

            {!locked && (
              <Button
                testSelector='discount-item-remove-btn'
                onClick={handleDiscountRemoval(uuid)}
                classes='red small'
              >
                <Trans id='discount.form.remove.button'>Remove</Trans>
              </Button>
            )}
          </li>
        ))}
      </ul>

      <LineItem
        label={
          <h2>
            <Trans id='discount.form.total.discount.label'>
              Total discount:
            </Trans>
          </h2>
        }
        value={
          <h2>
            {currencyFormatter(Math.abs(total_discounts), currency, language)}
          </h2>
        }
        testSelector='discount-total'
        classes='filled discount-total'
      />
    </Modal>
  );
};

export default reduxForm({
  form: 'discountForm',
  destroyOnUnmount: true,
})(DiscountForm);
