import React from 'react';

const DatabaseTable = ({ item, className }) => {
  const renderDBErrorCounterRow = (item, index, stripe) => {
    return (
      <tr key={'ec' + index} className={stripe}>
        <td>{item.error}</td>
        <td>{item.count}</td>
      </tr>
    );
  };
  const renderDBErrorListRow = (item, index, stripe) => {
    return (
      <tr key={'el' + index} className={stripe}>
        <td>{item.datetime}</td>
        <td>{item.service}</td>
        <td>{item.class + ' ' + item.method + ' line:' + item.lineNumber}</td>
        <td>
          <span className='ServiceTable_smallText'>{item.errorMessage}</span>
        </td>
      </tr>
    );
  };
  const renderDBErrorListRow2 = (item, index, stripe) => {
    return (
      <tr key={'el2' + index} className={stripe}>
        <td colSpan='4' align='left' className='ServiceTable_stackTrace'>
          {item.stacktrace}
        </td>
      </tr>
    );
  };
  const renderDBCallRow = (item, index, stripe, className) => {
    return (
      <tr key={'m' + index} className={stripe}>
        <td>{className}</td>
        <td>{item.methodLine}</td>
        <td>{item.queryType}</td>
        <td>{item.counter[0]}</td>
        <td>{item.counter[1]}</td>
        <td>{item.counter[2]}</td>
        <td>{item.counter[3]}</td>
        <td>{item.counter[4]}</td>
        <td>{item.counter[5]}</td>
        <td>{item.counter[6]}</td>
        <td>{item.counter[7]}</td>
      </tr>
    );
  };
  const renderClass = (item, index) => {
    return (
      (
        <tr>
          <td colspan='10'>CLASSNAME: {item.className}</td>
        </tr>
      ) &&
      item.counters.map((item1, index) => {
        var stripe = index % 2 === 1 ? 'stripe_even' : 'stripe_odd';
        var rows = [];
        rows.push(renderDBCallRow(item1, index, stripe, item.className));
        return rows;
      })
    );
  };

  return (
    item !== '' && (
      <div>
        <table className={`ServiceTable ${className}`} key={'db'}>
          <caption className='ServiceTable_innercaption'>Database</caption>
          <colgroup>
            <col width='50%;' />
            <col width='50%;' />
          </colgroup>
          <tbody>
            <tr>
              <td colSpan='2'>
                <table className='MethodTable'>
                  <colgroup>
                    <col width='20%;' />
                    <col width='22%;' />
                    <col width='10%;' />
                    <col width='6%;' />
                    <col width='6%;' />
                    <col width='6%;' />
                    <col width='6%;' />
                    <col width='6%;' />
                    <col width='6%;' />
                    <col width='6%;' />
                    <col width='6%;' />
                  </colgroup>
                  <tbody>
                    <tr className='ServiceTable_innerHeader'>
                      <th>Class</th>
                      <th>Method</th>
                      <th>Query Type</th>
                      <th>Total</th>
                      <th>Errors</th>
                      {item.responseTimeRanges.map((item, index) => {
                        return (
                          <th key={'r' + index}>
                            {item.minMs}-
                            {item.maxMs === -1 ? '   ' : item.maxMs}
                          </th>
                        );
                      })}
                    </tr>

                    {item.classes.map((item, index) => {
                      var rows = [];
                      rows.push(renderClass(item, index));
                      return rows;
                    })}
                  </tbody>
                </table>
              </td>
            </tr>
            {item.errors.totalErrors > 0 && (
              <tr>
                <td className='ServiceTable_none'>
                  <table className='MethodTable'>
                    <colgroup>
                      <col width='80%;' />
                      <col width='20%;' />
                    </colgroup>
                    <caption className='ServiceTable_innercaption'>
                      Database Error Counters
                    </caption>
                    <tbody>
                      <tr className='ServiceTable_innerHeader'>
                        <th>Error</th>
                        <th>Count</th>
                      </tr>
                      {item.errors.errorCounters.map((item, index) => {
                        var stripe =
                          index % 2 === 1 ? 'stripe_even' : 'stripe_odd';
                        var rows = [];
                        rows.push(renderDBErrorCounterRow(item, index, stripe));
                        return rows;
                      })}
                    </tbody>
                  </table>
                </td>
                <td className='ServiceTable_none'>
                  <table className='MethodTable'>
                    <colgroup>
                      <col width='10%;' />
                      <col width='20%;' />
                      <col width='20%;' />
                      <col width='50%;' />
                    </colgroup>
                    <caption className='ServiceTable_innercaption'>
                      Database Error List
                    </caption>
                    <tbody>
                      <tr className='ServiceTable_innerHeader'>
                        <th>DateTime</th>
                        <th>Sender</th>
                        <th>Method</th>
                        <th>Error</th>
                      </tr>
                      {item.errors.errorList.map((item, index) => {
                        var stripe =
                          index % 2 === 1 ? 'stripe_even' : 'stripe_odd';
                        var rows = [];
                        rows.push(renderDBErrorListRow(item, index, stripe));
                        if (item?.stacktrace?.length > 0) {
                          rows.push(renderDBErrorListRow2(item, index, stripe));
                        }
                        return rows;
                      })}
                    </tbody>
                  </table>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    )
  );
  //  };

  //  return (
  //    <div>
  //      {console.log(item)}
  //      <h1>Database</h1>
  //      {renderDatabase(item)}
  //    </div>
  //  );
};

export default DatabaseTable;
