import React, { Component } from 'react';

import './Tooltip.styl';

export class Tooltip extends Component {
  state = { showTooltip: false };

  toggleTooltip = () => {
    this.setState({
      showTooltip: !this.state.showTooltip,
    });
  };

  render() {
    const { classes, text, tooltipText } = this.props;
    const { showTooltip } = this.state;

    return (
      <div className={`tooltip ${classes}`}>
        <span
          className='tooltip-title'
          onMouseOut={this.toggleTooltip}
          onMouseOver={this.toggleTooltip}
        >
          {text}
        </span>
        {showTooltip && (
          <div className='tooltip-description'>{tooltipText}</div>
        )}
      </div>
    );
  }
}

export default Tooltip;
