import { cleanStoreAction } from 'actions/app';

import { Component } from 'react';
import { connect } from 'react-redux';

class CacheReloader extends Component {
  componentDidUpdate({ buildHash: prevBuildHash }) {
    const { buildHash: newBuildHash, cleanStore } = this.props;

    if (prevBuildHash && newBuildHash && prevBuildHash !== newBuildHash) {
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
          for (let name of names) {
            caches.delete(name);
          }
        });
      }

      cleanStore();

      // delete browser cache and hard reload
      window.location.reload(true);
    }
  }

  render() {
    return this.props.children;
  }
}

export default connect(
  ({ app }) => ({
    buildHash: app.buildHash,
  }),
  { cleanStore: cleanStoreAction },
)(CacheReloader);
