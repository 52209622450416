import {
  publishTemplateAction,
  saveTemplateAction,
} from 'actions/admin/templates';
import { required } from 'helpers/validators';
import { Field, reduxForm } from 'redux-form';

import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';

import Button from 'components/Button';
import Heading from 'components/Heading';
import Input from 'components/Input';
import RenderTextAreaField from 'components/RenderTextAreaField';

import { adminTemplateAttachmentsURL } from 'constants/routes';
import { ADMIN } from 'constants/user';

import ParamDocs from './ParamDocs';

export const TemplateForm = ({
  campaignCode,
  change,
  handleSubmit,
  invalid,
  submitting,
  templateName,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const draftTemplate = useSelector(
    ({ adminTemplates }) => adminTemplates?.draftTemplate,
  );

  const emailBlocks = useSelector(
    ({ adminTemplates }) => adminTemplates?.blocks,
  );

  const liveTemplate = useSelector(
    ({ adminTemplates }) => adminTemplates?.liveTemplate,
  );

  const userRole = ADMIN === useSelector(({ user }) => user.role);

  const [openDocs, setOpenDocs] = useState('');

  const toggleOpenDocs = (docs) => {
    setOpenDocs(openDocs === docs ? '' : docs);
  };

  useEffect(() => {
    change('subject', draftTemplate?.subject);
    change('htmlPart', draftTemplate?.htmlPart);
  }, [draftTemplate]);

  return (
    <Fragment>
      {openDocs !== '' && (
        <ParamDocs
          blocks={emailBlocks}
          isData={openDocs === 'data'}
          setOpenDocs={setOpenDocs}
        />
      )}

      <form className='templateForm'>
        <Heading size='small'>
          <Trans id='template.editor.title'>Email Editor</Trans>
        </Heading>

        {draftTemplate?.templateType === 'EMAIL' && (
          <Field
            name='subject'
            component={Input}
            placeholder={t({
              id: 'template.editor.subject.title',
              message: `Subject`,
            })}
            validate={required()}
            testSelector='campaign-subject'
          />
        )}

        <div className='blockToggles'>
          {userRole && (
            <Button
              classes='small'
              onClick={() =>
                navigate(
                  adminTemplateAttachmentsURL(campaignCode, templateName),
                )
              }
            >
              <Trans id='template.editor.attachments.toggle'>Attachments</Trans>
            </Button>
          )}
          <Button
            selected={openDocs === 'blocks'}
            classes='small'
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => toggleOpenDocs('blocks')}
          >
            <Trans id='template.editor.block.toggle'>Add HTML block</Trans>
          </Button>
          <Button
            selected={openDocs === 'data'}
            classes='small'
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => toggleOpenDocs('data')}
          >
            <Trans id='template.editor.data.toggle'>Add data placeholder</Trans>
          </Button>
        </div>

        <Field
          className='templateForm--textArea'
          validate={required()}
          name='htmlPart'
          fieldName={t({ id: 'template.editor.body.title', message: `Body` })}
          component={RenderTextAreaField}
          testSelector='campaign-body'
        />

        <div className='templateForm--actionButtons'>
          <Button
            // TODO make this component reliant only on hooks
            // in order to remove arrow functions and enforce this rule
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => {
              change('subject', draftTemplate?.subject);
              change('htmlPart', draftTemplate?.htmlPart);
            }}
            classes='inverted'
          >
            Revert to draft version
          </Button>

          <Button disabled={invalid || submitting} onClick={handleSubmit}>
            Save draft
          </Button>

          <Button
            // TODO make this component reliant only on hooks
            // in order to remove arrow functions and enforce this rule
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => {
              change('subject', liveTemplate.subject);
              change('htmlPart', liveTemplate.htmlPart);
            }}
            classes='inverted'
          >
            Revert to production version
          </Button>

          <Button
            // eslint-disable-next-line react/jsx-no-bind
            onClick={async () => {
              const template = await dispatch(
                publishTemplateAction(campaignCode, templateName),
              );

              if (template) {
                change('subject', template.subject);
                change('htmlPart', template.htmlPart);
              }
            }}
          >
            Upload to production
          </Button>
        </div>
      </form>
    </Fragment>
  );
};

export default reduxForm({
  form: 'template-editor',
  enableReinitialize: true,
  onSubmit: (values, dispatch, { template }) => {
    dispatch(
      saveTemplateAction({
        ...template,
        subject: values.subject,
        stage: values.stage,
        htmlPart: values.htmlPart,
      }),
    );
  },
})(TemplateForm);
