import {
  getCollectionFormInfoAction,
  getCollectorsAction,
  getCollectorsRulesAction,
  getReturnedDevicesAction,
  setCollectionDevicesAction,
} from 'actions/admin/collection';
import { openModalAction } from 'actions/ui';
import { optionsToArrayObjs } from 'helpers/formatters';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import { getFormValues } from 'redux-form';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import Dropdown from 'components/Dropdown';
import Form from 'components/Form';
import Loader from 'components/Loader';
import Modal from 'components/Modal';
import RenderDatePicker, { formatDate } from 'components/RenderDatePicker';
import Table from 'components/table/Table';

import { collectorsLabels } from 'constants/collections';
import { recycleStatus } from 'constants/status';

import useTrackPage from 'hooks/useTrackPage';

import './CollectionsOrganise.styl';
import CollectionsForm from './components/CollectionsForm';
import CourierDetails from './components/CourierDetails';
import Tooltip from './components/Tooltip';

const CollectionsOrganise = () => {
  useTrackPage('CollectionsOrganise');

  const [disableCreateBtn, setDisableCreateBtn] = useState(true);
  const [filterValues, setFilterValues] = useState(null);

  const dispatch = useDispatch();

  const returnedDevices = useSelector(
    ({ adminCollection }) => adminCollection?.returnedDevices,
  );
  const collectors = useSelector(
    ({ adminCollection }) => adminCollection?.collectors,
  );
  const collectorsRules = useSelector(
    ({ adminCollection }) => adminCollection?.collectorsRules,
  );
  const collectionUrl = useSelector(
    ({ adminCollection }) => adminCollection?.collectionUrl,
  );
  const collectionStore = useSelector(
    ({ adminCollection }) => adminCollection?.collectionStore,
  );

  const wlName = useSelector(({ whitelabel }) => whitelabel?.name);

  const showCollectionTimeFields = useSelector(
    ({ whitelabel }) => whitelabel.configs.showCollectionTimeFields,
  );

  const loading = useSelector(({ ui }) => ui?.loading);

  const selectedStore = useSelector(({ user }) => user?.selectedStore);

  const collector = useSelector(
    (state) => getFormValues('collections-organise')(state)?.collector,
  );

  useEffect(() => {
    dispatch(getCollectorsAction());
  }, [dispatch]);

  useEffect(() => {
    filterValues?.collector && dispatch(getReturnedDevicesAction(filterValues));
  }, [dispatch, filterValues]);

  useEffect(() => {
    collector &&
      isUndefined(collectorsRules) &&
      dispatch(getCollectorsRulesAction());
  }, [collector, collectorsRules, dispatch]);

  const submitFiltersForm = (values) => setFilterValues(values);

  const validate = (values) => {
    const { value, devicesLimit } = collectorsRules[collector];

    return (
      ((value !== 0 && values.length > 0) || values.length >= devicesLimit) &&
      !isEmpty(collector)
    );
  };

  if (loading) return <Loader />;

  const columns = [
    {
      label: t({ id: 'table.column.source', message: `Source` }),
      accessor: 'source',
      type: 'text',
      list: recycleStatus(),
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.id', message: `ID` }),
      accessor: 'id',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.store', message: `Store` }),
      accessor: 'storeName',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.model', message: `Model` }),
      accessor: 'deviceName',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'left',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.imei.or.serial',
        message: `IMEI / Serial No.`,
      }),
      accessor: 'imei',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.condition', message: `Condition` }),
      accessor: 'condition',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'left',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.received.date', message: `Received Date` }),
      accessor: 'receivedDate',
      type: 'date',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.last.collection.date',
        message: `Latest Collection Date`,
      }),
      accessor: 'latestCollectionDate',
      type: 'date',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
  ];

  return (
    <div className='collectionsWrapper'>
      <h1 className='admin-page-title'>
        <Trans id='organise.collections.title'>Organise Collections</Trans>
      </h1>
      <Form
        form='collections-organise'
        classes='collectors-organise-filter'
        fields={[
          collectors && {
            name: 'collector',
            component: Dropdown,
            placeholder: t({
              id: 'organise.collections.collector',
              message: `Choose Collector`,
            }),
            options: optionsToArrayObjs(collectors),
            variant: 'underline',
            labelType: 'outside',
            clearable: true,
          },
          {
            component: RenderDatePicker,
            label: t({
              id: 'organise.collections.from.label',
              message: `From`,
            }),
            variant: 'underline',
            name: 'startDate',
            format: formatDate,
            parse: formatDate,
          },
          {
            component: RenderDatePicker,
            label: t({
              id: 'organise.collection.form.to.label',
              message: `to`,
            }),
            variant: 'underline',
            name: 'endDate',
            format: formatDate,
            parse: formatDate,
          },
        ].filter((field) => field)}
        onChange={submitFiltersForm}
      />

      {disableCreateBtn && collector && collectorsRules && (
        <Tooltip
          classes='collector-rules'
          text={t({
            id: 'organise.collections.rules.tooltip',
            message: `* ${collector} conditions for collection`,
          })}
          tooltipText={collectorsLabels({
            devicesLimit: collectorsRules[collector]?.devicesLimit,
            value: collectorsRules[collector]?.value,
          })}
        />
      )}

      {collector && (
        <Table
          columns={columns}
          data={returnedDevices}
          multiSelect={!isEmpty(collector)}
          multiSelectOptions={{
            isSelectableAccessor: 'isCollectable',
            onMultiSelectChange: (values) => {
              setDisableCreateBtn(!validate(values));
            },
            multiSelectActions: [
              {
                label: t({
                  id: 'create.collection.title',
                  message: `Create Collection`,
                }),
                handler: (values) => {
                  dispatch(setCollectionDevicesAction(values));
                  dispatch(getCollectionFormInfoAction(selectedStore));
                  dispatch(openModalAction('admin-collections'));
                },
                classes: 'create-collection-btn',
                disabled: disableCreateBtn,
              },
            ],
          }}
        />
      )}

      <Modal name='admin-collections'>
        <CollectionsForm
          destroyOnUnmount
          showCollectionTimeFields={showCollectionTimeFields}
          initialValues={collectionStore}
        />
      </Modal>
      <CourierDetails collectionUrl={collectionUrl} whitelabel={wlName} />
    </div>
  );
};

export default CollectionsOrganise;
