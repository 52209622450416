import { t } from '@lingui/macro';

export const collectorsLabels = ({ devicesLimit, value }) => {
  const rules = {
    noFee: t({
      id: 'collection.rule.noFee',
      message: `This collector only allows collections with a minimum of ${devicesLimit} devices. There are no extra fees associated with these collections.`,
    }),
    fee: t({
      id: 'collection.rule.fee',
      message: `This collector allows collections with any number of devices. However, there is an additional fee of ${value} + vat per unit.`,
    }),
  };

  return rules[value === 0 ? 'noFee' : 'fee'];
};

export const techDataNorway = 'TechDataNO';
