import {
  clearRecycleAction,
  evaluateDeviceAction,
  saveDeviceReferenceAction,
  saveEvaluationAnswerAction,
  selectPromotion,
} from 'actions/recycle';
import { addNotificationAction, openModalAction } from 'actions/ui';
import { currencyFormatter } from 'helpers/formatters';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import { allGradingQuestionsAnswered, currencySymbolToISO3 } from 'utils';

import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';

import Footer from 'components/Footer';
import Heading from 'components/Heading';
import LineItem from 'components/LineItem';
import Loader from 'components/Loader';
import DeviceReferenceForm from 'components/device_reference/DeviceReferenceForm';
import Criteria from 'components/recycle/Criteria';
import DeviceDisownmentChecks from 'components/recycle/DeviceDisownmentChecks';
import PromotionsDropdown from 'components/recycle/PromotionsDropdown';
import Summary from 'components/recycle/Summary';
import SwapLegalText from 'components/recycle/SwapLegalText';

import { deviceValueNotHighEnough } from 'constants/notifications';
import { SWAP, appleDEPLink, removeICloudLink } from 'constants/recycle';
import {
  recycleOutcomesUrl,
  recycleQuoteSearchUrl,
  recycleSearchOrderUrl,
  recycleSearchUrl,
  viewSaleUrl,
} from 'constants/routes';

import useTrackPage from 'hooks/useTrackPage';

import './Evaluation.styl';
import RecycleBlockedDevice from './components/RecycleBlockedDevice';
import RecycleDiagnostic from './components/RecycleDiagnostic';

export const Evaluation = () => {
  useTrackPage('RecycleEvaluation');

  const { orderUUID } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const checkmendOk = useSelector(({ recycle }) => recycle.checkmendOk);
  const country = useSelector(({ whitelabel }) => whitelabel.country);
  const currency = useSelector(({ whitelabel }) => whitelabel.currency);
  const deviceReference = useSelector(({ recycle }) => recycle.deviceReference);
  const fmi = useSelector(({ recycle }) => recycle.fmi);
  const funder = useSelector(({ recycle }) => recycle.funder);
  const language = useSelector(({ whitelabel }) => whitelabel.language);
  const loading = useSelector(({ ui }) => ui.loading);
  const onlineQuote = useSelector(({ recycle }) => recycle.onlineQuote);
  const referenceType = useSelector(({ recycle }) => recycle.referenceType);
  const selectedDevice = useSelector(({ recycle }) => recycle.selectedDevice);
  const diagnosticReportGrade = useSelector(
    ({ recycle }) => recycle.selectedDevice?.diagnostic?.reportGrade,
  );

  const promotions = useSelector(
    ({ recycle }) => recycle.selectedDevice?.promotions,
  );
  const onlyUpgradeMaxValue = useSelector(
    ({ whitelabel }) => whitelabel.configs.onlyUpgradeMaxValue,
  );

  const selectedSIMStatus = useSelector(
    ({ recycle }) => recycle.selectedSIMStatus,
  );
  const type = useSelector(({ recycle }) => recycle.type);
  const useDiagnosticsMinval = useSelector(
    ({ recycle }) => recycle.useDiagnosticsMinval,
  );

  const deviceIsBlocked = checkmendOk === false || includes(fmi, true);

  useEffect(() => {
    deviceIsBlocked && dispatch(openModalAction('recycleBlockedDevice'));
  }, [deviceIsBlocked, dispatch]);

  useEffect(() => {
    orderUUID &&
      diagnosticReportGrade &&
      diagnosticReportGrade !== 'W' &&
      dispatch(addNotificationAction(deviceValueNotHighEnough()));
  }, [orderUUID, diagnosticReportGrade, dispatch]);

  const evaluationsDifference =
    onlineQuote?.devices[0]?.value - selectedDevice?.evaluation?.deviceValue;

  const deviceIsLaptop = selectedDevice?.manufacturerCode
    ?.toLowerCase()
    .match(/apple/);

  const showPromotions = () => {
    const {
      questionsTree: {
        criteriaAnswers = [],
        deductionAnswers = [],
        additionAnswers = [],
      },
    } = selectedDevice;

    return (
      promotions?.length > 0 &&
      [criteriaAnswers, deductionAnswers, additionAnswers].every(
        allGradingQuestionsAnswered,
      )
    );
  };

  const canProceed = () => {
    const {
      questionsTree: {
        criteriaAnswers = [],
        deductionAnswers = [],
        additionAnswers = [],
      },
      evaluation,
    } = selectedDevice;

    return (
      !isEmpty(criteriaAnswers) &&
      [criteriaAnswers, deductionAnswers, additionAnswers].every(
        allGradingQuestionsAnswered,
      ) &&
      evaluation?.grade !== 'NWZ' &&
      !deviceIsBlocked &&
      selectedDevice?.deviceReference
    );
  };

  const deviceValueTooLow = () => {
    const { evaluation } = selectedDevice;

    return (
      (orderUUID && diagnosticReportGrade && diagnosticReportGrade !== 'W') ||
      (orderUUID && onlyUpgradeMaxValue && evaluation.swapCost > 0)
    );
  };

  const handleDeviceConditionAnswer = async (question, answer, type) => {
    const response = await dispatch(
      saveEvaluationAnswerAction({
        question,
        answer,
        type,
        selectedDevice,
        selectedSIMStatus,
        orderUUID,
      }),
    );

    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });

    if (
      canProceed() &&
      orderUUID &&
      onlyUpgradeMaxValue &&
      response.swapCost > 0
    )
      dispatch(addNotificationAction(deviceValueNotHighEnough()));
  };

  const handleDeviceReferenceSave = (data) =>
    dispatch(saveDeviceReferenceAction(data));

  const handleSelectPromotion = async (value) => {
    dispatch(selectPromotion(value));
    dispatch(
      evaluateDeviceAction({
        orderUUID,
        selectedDevice: { ...selectedDevice, promotionID: value },
        selectedSIMStatus,
        onlineQuote,
      }),
    );
  };

  const goToOutcomes = () => navigate(recycleOutcomesUrl);

  const getSearchUrl = () =>
    orderUUID ? recycleSearchOrderUrl(orderUUID) : recycleSearchUrl;

  const shouldPerformDiagnostic =
    selectedDevice?.hasDiagnostics &&
    !selectedDevice?.diagnostic?.reportUid &&
    selectedDevice?.evaluation?.deviceValue >= useDiagnosticsMinval;

  const shouldShowSwapLegalText =
    type === SWAP && selectedDevice?.evaluation?.grade && funder === 'Klarna';

  const getNextBtnLabel = () => {
    return shouldPerformDiagnostic
      ? t({
          id: 'evaluation.device.start.diagnostic.button',
          message: `Start device diagnostic`,
        })
      : t({ id: 'navigation.recycle.outcomes', message: `Outcomes` });
  };

  const backBtnAction = () => {
    if (type === 'TRADE_IN' && isEmpty(onlineQuote)) {
      dispatch(clearRecycleAction());

      navigate(getSearchUrl());
    } else if (!isEmpty(onlineQuote)) {
      navigate(recycleQuoteSearchUrl);
    } else {
      navigate(viewSaleUrl(orderUUID));
    }
  };

  const nextBtnAction = () => {
    if (shouldPerformDiagnostic) {
      dispatch(openModalAction('deviceDiagnostic'));
    } else {
      goToOutcomes();
    }
  };

  if (!type) {
    return (
      <div>
        <h1>
          <Trans id='data.unavailable.title'>Data unavailable</Trans>
        </h1>
        <p>
          <Trans id='data.unavailable.text'>
            The data you are trying to access is no longer available.
          </Trans>
        </p>
      </div>
    );
  }

  return (
    <div className='recycleEvaluation'>
      {loading && <Loader />}

      <RecycleBlockedDevice fmi={fmi} checkmendOk={checkmendOk} />

      <main className='content-left'>
        <Heading>
          <Trans id='device.evaluation.title'>Device Evaluation</Trans>
        </Heading>

        {deviceIsLaptop && (
          <div className='disowned-disclaimer'>
            <Trans id='evaluation.trade.icloud.disown.warning'>
              * Please make sure devices are{' '}
              <a
                href={removeICloudLink(country)}
                target='_blank'
                rel='noopener noreferrer'
              >
                {'removed from iCloud'}
              </a>{' '}
              and are disowned from the{' '}
              <a
                href={appleDEPLink(country)}
                target='_blank'
                rel='noopener noreferrer'
              >
                {'Apple Device Enrolment Program (DEP)'}
              </a>
              .
            </Trans>
          </div>
        )}

        {!isEmpty(onlineQuote) && (
          <Fragment>
            <LineItem
              label={`${t({
                id: 'online.tradein.quoteid',
                message: `Quote ID`,
              })}:`}
              value={onlineQuote.tradecartID}
            />

            {onlineQuote.devices.map((item) => (
              <Fragment>
                <LineItem
                  label={`${t({
                    id: 'recycle.summary.device',
                    message: `Device`,
                  })}:`}
                  value={item.name}
                />

                <LineItem
                  label={`${t({
                    id: 'recycle.device.imei',
                    message: `IMEI / Serial Number`,
                  })}:`}
                  value={item.deviceReference}
                />

                <LineItem
                  label={`${t({
                    id: 'recycle.sim.status',
                    message: `SIM Status`,
                  })}:`}
                  value={item.networkStatus}
                />

                <LineItem
                  label={`${t({
                    id: 'recycle.condition.description',
                    message: `Condition`,
                  })}:`}
                  value={item.grade}
                />

                <LineItem
                  label={`${t({
                    id: 'recycle.device.value',
                    message: `Device Value`,
                  })}:`}
                  value={currencyFormatter(
                    item.value,
                    currencySymbolToISO3(currency),
                    language,
                  )}
                  classes='filled bold'
                />
              </Fragment>
            ))}
          </Fragment>
        )}

        {!deviceReference && (
          <Fragment>
            <Heading size='medium'>
              <Trans id='device.evaluation.insert.device.reference'>
                Insert IMEI / Serial Number
              </Trans>
            </Heading>

            <DeviceReferenceForm
              destroyOnUnmount={false}
              onSubmit={handleDeviceReferenceSave}
              deviceReference={deviceReference}
              referenceType={referenceType}
              showRefHelper
            />
          </Fragment>
        )}

        <DeviceDisownmentChecks orderUUID={orderUUID} />

        {((selectedDevice?.hasNetwork && selectedSIMStatus) ||
          !selectedDevice?.hasNetwork) &&
          selectedDevice.questionsTree.criteriaAnswers && (
            <Fragment>
              <Criteria
                data={[
                  {
                    name: 'criteria',
                    list: selectedDevice.questionsTree.criteria,
                    answers: selectedDevice.questionsTree.criteriaAnswers,
                    answerHandler: (question, answer) =>
                      handleDeviceConditionAnswer(question, answer, 'criteria'),
                  },
                ]}
              />

              {allGradingQuestionsAnswered(
                selectedDevice.questionsTree.criteriaAnswers,
              ) && (
                <Criteria
                  title={t({
                    id: 'evaluation.device.more.questions.title',
                    message: `More Questions`,
                  })}
                  data={[
                    {
                      name: 'addition',
                      list: selectedDevice.questionsTree.addition,
                      answers: selectedDevice.questionsTree.additionAnswers,
                      answerHandler: (question, answer) =>
                        handleDeviceConditionAnswer(
                          question,
                          answer,
                          'addition',
                        ),
                    },
                    {
                      name: 'deduction',
                      list: selectedDevice.questionsTree.deduction,
                      answers: selectedDevice.questionsTree.deductionAnswers,
                      answerHandler: (question, answer) =>
                        handleDeviceConditionAnswer(
                          question,
                          answer,
                          'deduction',
                        ),
                    },
                  ]}
                />
              )}
            </Fragment>
          )}

        {!isEmpty(onlineQuote) && !isEmpty(selectedDevice?.evaluation) && (
          <p className='multipleEvalsInfo'>
            {evaluationsDifference === 0 && (
              <Trans id='recycle.matching.evals.info'>
                This evaluation value,{' '}
                {currencyFormatter(
                  selectedDevice?.evaluation?.deviceValue,
                  currencySymbolToISO3(currency),
                  language,
                )}
                , is equal to the value of the customer's online valuation.
                Please confirm that the customer wishes to proceed with the
                trade-in.
              </Trans>
            )}

            {evaluationsDifference > 0 && (
              <Trans id='recycle.lower.eval.info'>
                This evaluation value,{' '}
                {currencyFormatter(
                  selectedDevice?.evaluation?.deviceValue,
                  currencySymbolToISO3(currency),
                  language,
                )}
                , is
                {currencyFormatter(
                  Math.abs(evaluationsDifference),
                  currencySymbolToISO3(currency),
                  language,
                )}{' '}
                lower than the value based on the customer's online valuation.
                Please confirm that the customer wishes to proceed with the
                trade-in.
              </Trans>
            )}

            {evaluationsDifference < 0 && (
              <Trans id='recycle.higher.eval.info'>
                This evaluation value,{' '}
                {currencyFormatter(
                  selectedDevice?.evaluation?.deviceValue,
                  currencySymbolToISO3(currency),
                  language,
                )}
                , is
                {currencyFormatter(
                  Math.abs(evaluationsDifference),
                  currencySymbolToISO3(currency),
                  language,
                )}{' '}
                higher than the value based on the customer's online valuation.
                Please confirm that the customer wishes to proceed with the
                trade-in.
              </Trans>
            )}
          </p>
        )}

        {shouldPerformDiagnostic && (
          <RecycleDiagnostic continueAction={goToOutcomes} />
        )}
        {showPromotions() && (
          <PromotionsDropdown
            // eslint-disable-next-line react/jsx-no-bind
            handleSelect={handleSelectPromotion}
            promotionID={selectedDevice.promotionID}
            promotions={promotions}
            currency={currency}
            language={language}
          />
        )}
      </main>

      <aside className='content-right'>
        <Summary device={selectedDevice} source={type} />

        {shouldShowSwapLegalText && (
          <SwapLegalText evaluation={selectedDevice?.evaluation} />
        )}
      </aside>

      <Footer
        classes='main'
        copyright={false}
        backBtnLabel={t({ id: 'navigation.back.button', message: `Back` })}
        backBtnAction={backBtnAction}
        nextBtnLabel={getNextBtnLabel()}
        nextBtnAction={nextBtnAction}
        nextBtnDisabled={!canProceed() || deviceValueTooLow()}
      />
    </div>
  );
};

export default Evaluation;
