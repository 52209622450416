import { StoreDTO } from 'DTOs/admin';
import {
  addNotificationAction,
  closeModalAction,
  hideLoaderAction,
  showLoaderAction,
} from 'actions/ui';
import { adminPost as post } from 'apis/v2';

import { storeAddSuccess, storeEditSuccess } from 'constants/notifications';

export const LIST_STORES = 'LIST_STORES';
export function listStoresAction(noLoader = false) {
  return async function (dispatch) {
    !noLoader && dispatch(showLoaderAction());

    // TODO move this destructuring to api layer?
    const {
      data: { status, data },
    } = await post(LIST_STORES);

    if (status === 'SUCCESS') {
      dispatch({
        type: LIST_STORES,
        data: data.stores.map((storeRaw) => StoreDTO.parse(storeRaw).toModel()),
      });
    }

    !noLoader && dispatch(hideLoaderAction());
  };
}

export const CREATE_STORE = 'CREATE_STORE';
export const UPDATE_STORE = 'UPDATE_STORE';
export function upsertStoreAction(values) {
  return async function (dispatch) {
    dispatch(showLoaderAction());

    const dto = StoreDTO.fromModel(values);

    // TODO move this destructuring to api layer?
    const {
      data: { status },
    } = dto.id
      ? await post(UPDATE_STORE, { store: dto.toJSON() })
      : await post(CREATE_STORE, { store: dto.toJSON() });

    if (status === 'SUCCESS') {
      dispatch(closeModalAction());
      await dispatch(listStoresAction());
      dispatch(
        addNotificationAction(dto.id ? storeEditSuccess() : storeAddSuccess()),
      );
    }

    dispatch(hideLoaderAction());
  };
}
