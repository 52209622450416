/* eslint-disable import/no-anonymous-default-export */
import {
  CHANGE_LOGIN_METHOD,
  GET_STARTING_INFO,
  SET_LANGUAGE,
} from 'actions/whitelabel';

import initialState from 'reducers/initialState';

export default function (whitelabel = initialState.whitelabel, action) {
  switch (action.type) {
    // TODO replace the condition for onlyUpgradeMaxValue with a config
    case GET_STARTING_INFO:
      const { data, shortName, subdomain } = action;
      return {
        ...data,
        shortName,
        subdomain,
        configs: {
          ...data.configs,
          onlyUpgradeMaxValue: shortName === 'mediamarktespana',
        },
        language: data.configs.canChangeLanguage
          ? whitelabel.language || data.configs.defaultLanguage
          : data.configs.defaultLanguage,
        translations: whitelabel.translations,
      };
    case SET_LANGUAGE:
      return { ...whitelabel, language: action.data };
    case CHANGE_LOGIN_METHOD:
      return {
        ...whitelabel,
        configs: {
          ...whitelabel.configs,
          hasSingleSignOn: !whitelabel.configs.hasSingleSignOn,
        },
      };
    default:
      return whitelabel;
  }
}
