import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const DEFAULT_VERSION = 'primary';

const Logo = ({ version = DEFAULT_VERSION, linkTo }) => {
  const navigate = useNavigate();

  const whitelabelName = useSelector(({ whitelabel }) => whitelabel.name);
  const primaryLogo = useSelector(
    ({ whitelabel }) => whitelabel.posalePrimaryLogo,
  );
  const secondaryLogo = useSelector(
    ({ whitelabel }) => whitelabel.posaleSecondaryLogo,
  );

  const logo = version === DEFAULT_VERSION ? primaryLogo : secondaryLogo;

  const handleClick = (event) => {
    event.preventDefault();

    if (linkTo) {
      navigate(linkTo);
    }
  };

  return (
    <img
      className='whitelabel-logo'
      src={logo}
      alt={whitelabelName}
      onClick={handleClick}
      data-test='whitelabelLogo'
    />
  );
};

export default Logo;
