import {
  companyLookup,
  getCompanyDetailsFormData,
  setCompanyDetailAction,
  updateCompanyDetailsAction,
  updateCustomerAction,
} from 'actions/customer';
import { formatDateForDatepicker } from 'helpers/formatters';
import isEmpty from 'lodash/isEmpty';
import { change, getFormValues, reduxForm } from 'redux-form';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import Checkbox from 'components/Checkbox';
import Dropdown from 'components/Dropdown';
import Form from 'components/Form';
import Heading from 'components/Heading';
import Input from 'components/Input';
import RadioButtons from 'components/RadioButtons';
import Section from 'components/Section';

import { posCompanyInfoFields, posCustomerInfoFields } from 'constants/forms';

const CUSTOMER_INFO = 'customerInfo';
const COMPANY_SEARCH_FORM = 'companySearchForm';
const COMPANY_INFO_FORM = 'companyInfo';

const COMPANY_NAME = 'name';
const COMPANY_NUMBER = 'number';
const POSTAL_CODE = 'postalCode';
const ADDRESS_LINE1 = 'addressLine1';
const ADDRESS_LINE2 = 'addressLine2';
const ADDRESS_LINE3 = 'addressLine3';
const CITY = 'city';
const COUNTRY = 'country';
const INCORPORATION_DATE = 'dateOfCreation';
const AUTHORISED_SIGNATORY_NAME = 'authorisedSignatoryName';
const AUTHORISED_SIGNATORY_POSITION = 'authorisedSignatoryPosition';
const TYPE = 'type';

const B2BCustomerInfo = ({
  testSelector,
  initialValues,
  country,
  countryPhoneCodes,
  invalid,
  submitting,
  disabled,
  hasCompanyLookup,
  cartUUID,
}) => {
  const dispatch = useDispatch();
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState(initialValues.company);
  const [companyDropDownValues, setCompanyDropDownValues] = useState([]);
  const [selected, setSelected] = useState('');
  const [companySearchFailed, setCompanySearchFailed] = useState(false);
  const [applyForFinance, setApplyForFinance] = useState(true);
  const [orderProductsFromTD, setOrderProductsFromTD] = useState(true);
  const [supportedBusinessTypes, setSupportedBusinessTypes] = useState([]);
  const [isNewcustomer, setIsNewcustomer] = useState(true);

  const searchForm = useSelector((state) =>
    getFormValues(COMPANY_SEARCH_FORM)(state),
  );

  useEffect(() => {
    async function fetchCompanyFormData() {
      const response = await dispatch(getCompanyDetailsFormData(cartUUID));
      setSupportedBusinessTypes(
        response?.supportedBusinessTypes?.map(({ businessTypeId, name }) => ({
          value: businessTypeId,
          label: name,
        })) || [],
      );
    }
    fetchCompanyFormData();
  }, [cartUUID, dispatch]);

  useEffect(() => {
    setCompanyDropDownValues(
      companies?.map(({ number, name }) => ({
        value: number,
        label: name,
      })) || [],
    );
  }, [companies]);

  useEffect(() => {
    if (!isEmpty(company)) {
      const {
        name,
        number,
        postalCode,
        addressLine1,
        addressLine2,
        addressLine3,
        dateOfCreation,
        city,
        country,
        authorisedSignatoryName,
        authorisedSignatoryPosition,
        type,
      } = company;
      dispatch(setCompanyDetailAction(company));
      dispatch(change(COMPANY_INFO_FORM, COMPANY_NAME, name));
      dispatch(change(COMPANY_INFO_FORM, TYPE, type));
      dispatch(change(COMPANY_INFO_FORM, COMPANY_NUMBER, number));
      dispatch(change(COMPANY_INFO_FORM, POSTAL_CODE, postalCode));
      dispatch(change(COMPANY_INFO_FORM, ADDRESS_LINE1, addressLine1));
      dispatch(change(COMPANY_INFO_FORM, ADDRESS_LINE2, addressLine2));
      dispatch(change(COMPANY_INFO_FORM, ADDRESS_LINE3, addressLine3));
      dispatch(change(COMPANY_INFO_FORM, CITY, city));
      dispatch(change(COMPANY_INFO_FORM, COUNTRY, country));
      dispatch(
        change(
          COMPANY_INFO_FORM,
          INCORPORATION_DATE,
          formatDateForDatepicker(dateOfCreation),
        ),
      );
      dispatch(
        change(
          COMPANY_INFO_FORM,
          AUTHORISED_SIGNATORY_NAME,
          authorisedSignatoryName,
        ),
      );
      dispatch(
        change(
          COMPANY_INFO_FORM,
          AUTHORISED_SIGNATORY_POSITION,
          authorisedSignatoryPosition,
        ),
      );
    }
  }, [company, dispatch]);

  const handleSearch = async () => {
    setCompanies([]);
    setCompany({});
    const companyName = searchForm?.name;
    if (companyName) {
      const { status, data } = await dispatch(
        companyLookup(cartUUID, companyName.trim()),
      );
      if (status === 'SUCCESS' && !isEmpty(data)) {
        if (data.companies.length === 1) {
          setCompany(data.companies[0]);
        } else {
          setCompanies(data?.companies ?? []);
        }
      } else {
        setCompanySearchFailed(true);
      }
    }
  };

  const handleChange = (input, value) => {
    dispatch(updateCustomerAction(input.target.name, value));
  };

  const handleCompanyDetailsChange = (input, value) => {
    dispatch(updateCompanyDetailsAction(input.target.name, value));
  };

  return (
    <>
      <Section inline>
        <RadioButtons
          disabled={disabled}
          options={[
            {
              key: 'applyForFinance',
              value: true,
              label: t({
                id: 'b2b.customer.info.form.apply.for.finance',
                message: 'Apply for Finance now',
              }),
            },
            {
              key: 'quoteOnly',
              value: false,
              label: t({
                id: 'b2b.customer.info.form.create.quote.only',
                message: 'Create Quote Only',
              }),
            },
          ]}
          selectedValue={applyForFinance}
          onClick={({ value }) => {
            dispatch(updateCustomerAction('applyForFinance', value));
            setApplyForFinance(value);
          }}
        />

        <div className='b2b__checkbox__wrapper'>
          <Checkbox
            id='orderFromTD'
            checked={orderProductsFromTD}
            onChange={(event) => {
              dispatch(
                updateCustomerAction(
                  'orderProductsFromTD',
                  event.currentTarget.checked,
                ),
              );
              setOrderProductsFromTD(event.currentTarget.checked);
            }}
            label={t({
              id: 'b2b.customer.info.form.order.from.tdsynnex',
              message: 'Order from TD SYNNEX',
            })}
          />
          <Checkbox
            id='isNewCustomer'
            checked={isNewcustomer}
            onChange={(event) => {
              dispatch(
                updateCustomerAction(
                  'isNewcustomer',
                  event.currentTarget.checked,
                ),
              );
              setIsNewcustomer(event.currentTarget.checked);
            }}
            label={t({
              id: 'b2b.customer.info.is.new.customer',
              message: 'Is this a new customer of yours?',
            })}
          />
        </div>
      </Section>
      <Heading size={'h2'}>
        <Trans id='customer.info.ekm.company.details'>Company details</Trans>
      </Heading>
      {hasCompanyLookup && (
        <Section inline>
          <Form
            form={COMPANY_SEARCH_FORM}
            classes='company-form'
            customFieldValidation
            fields={[
              {
                component: Input,
                placeholder: t({
                  id: 'customer.info.ekm.company.search.placeholder',
                  message: `Search Name`,
                }),
                name: COMPANY_NAME,
                size: 'medium',
                width: 'half',
              },
            ]}
            actionButtons={[
              {
                name: 'searchCompany',
                label: t({
                  id: 'customer.info.ekm.company.search.button',
                  message: `Search`,
                }),
                loading: submitting,
                handleClick: async () => await handleSearch(),
              },
            ]}
            disabled={invalid || submitting || disabled}
          />
          {!isEmpty(companyDropDownValues) && (
            <div className='companies-dropdown'>
              <Dropdown
                placeholder={t({
                  id: 'customer.info.ekm.company.dropdown.showall',
                  message: `Show all`,
                })}
                input={{
                  onChange: async (value) => {
                    setSelected(value);
                    setCompany(companies.find((i) => i.number === value) || {});
                  },
                  onFocus: () => {},
                  onBlur: () => {},
                  value: selected,
                }}
                meta={{
                  active: false,
                  error: '',
                  touched: false,
                }}
                variant='underline'
                clearable={true}
                options={companyDropDownValues}
              />
            </div>
          )}
        </Section>
      )}

      {(!isEmpty(company) || companySearchFailed || !hasCompanyLookup) && (
        <Section>
          <Form
            data-test={testSelector}
            initialValues={initialValues.company}
            form={COMPANY_INFO_FORM}
            destroyOnUnmount={true}
            fields={posCompanyInfoFields({
              handleChange: handleCompanyDetailsChange,
              supportedBusinessTypes: supportedBusinessTypes,
            })}
          />
        </Section>
      )}

      {(!isEmpty(company) || companySearchFailed || !hasCompanyLookup) && (
        <Section inline>
          <Form
            data-test={testSelector}
            initialValues={initialValues}
            form={CUSTOMER_INFO}
            destroyOnUnmount={true}
            fields={posCustomerInfoFields({
              country,
              countryPhoneCodes,
              handleChange,
            }).filter((field) =>
              ['firstName', 'lastName', 'email', 'phone'].includes(field.name),
            )}
          />
        </Section>
      )}
    </>
  );
};

export default reduxForm({
  form: COMPANY_SEARCH_FORM,
  destroyOnUnmount: true,
  onSubmit: (e) => {
    e.preventDefault();
  },
})(B2BCustomerInfo);
