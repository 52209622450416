import logo from 'assets/logos/TD_SYNNEX_logo.png';

import React from 'react';

import './DashboardHeader.styl';

const DashboardHeader = ({ socketStatus }) => (
  <div className='dashboardHeader'>
    <img src={logo} alt='TD SYNNEX' className='logo' />

    <div className='socket-status'>{socketStatus}</div>
  </div>
);

export default DashboardHeader;
