import AbstractDTO from 'DTOs/AbstractDTO';

import { ALL_ROLES, STORE_PERSON, userStatus } from 'constants/user';

const userValidator = (raw) => {
  return {
    defaultStore:
      raw?.userStores.find((store) => store.isDefault === true)?.storeID ??
      null,
    email: raw?.email ?? '',
    firstName: raw?.firstName ?? '',
    id: raw?.id ?? null,
    lastActivity: raw?.lastActivity ?? null,
    lastName: raw?.lastName ?? '',
    roleID: ALL_ROLES.includes(raw?.roleID) ? raw?.roleID : STORE_PERSON,
    status: raw?.status ?? userStatus.inactive,
    username: raw?.username ?? '',
    // map using StoreDTO?
    userStores: raw?.userStores ?? [],
  };
};

export default AbstractDTO(userValidator);
