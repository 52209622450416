import {
  clearSelectionsAction,
  selectRecycleDeviceAction,
  setRecycleTypeAction,
  validateDeviceReferenceAction,
} from 'actions/recycle';
import { addNotificationAction, openModalAction } from 'actions/ui';
import { ReactComponent as Info } from 'assets/images/info.svg';
import { currencyFormatter } from 'helpers/formatters';

import React, { Fragment, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Trans } from '@lingui/macro';

import Footer from 'components/Footer';
import Heading from 'components/Heading';
import Loader from 'components/Loader';
import RadioButtons from 'components/RadioButtons';
import TabbedContent from 'components/TabbedContent.js';
import DeviceReferenceForm from 'components/device_reference/DeviceReferenceForm';

import { incorrectDeviceReferenceWarning } from 'constants/notifications';
import { recycleEvaluateOrderUrl, recycleEvaluateUrl } from 'constants/routes';

import useTrackPage from 'hooks/useTrackPage';

import DeviceNameForm from './DeviceNameForm';
import RecycleCTO from './RecycleCTO';
import './Search.styl';

export const Search = ({ orderUUID }) => {
  useTrackPage('RecycleSearch');

  const navigate = useNavigate();

  const currency = useSelector(({ whitelabel }) => whitelabel.currency);
  const devices = useSelector(({ recycle }) => recycle.devices);
  const contractDeviceReference = useSelector(
    ({ recycle }) => recycle.deviceReference,
  );

  const dispatch = useDispatch();
  const fmi = useSelector(({ recycle }) => recycle.fmi);
  const selectedSIMStatus = useSelector(
    ({ recycle }) => recycle.selectedSIMStatus,
  );
  const language = useSelector(({ whitelabel }) => whitelabel.language);
  const loading = useSelector(({ ui }) => ui.loading);

  const getEvaluationUrl = useCallback(
    () => (orderUUID ? recycleEvaluateOrderUrl(orderUUID) : recycleEvaluateUrl),
    [orderUUID],
  );

  useEffect(() => {
    !orderUUID && dispatch(setRecycleTypeAction({ recycleType: 'TRADE_IN' }));
    dispatch(clearSelectionsAction());
  }, [orderUUID, dispatch]);

  useEffect(() => {
    if (devices?.length === 1) {
      navigate(getEvaluationUrl());
    }
  }, [getEvaluationUrl, devices, navigate]);

  const thereAreMultipleDevices = () => devices.length > 1;

  const atLeastOneDeviceIsAMac = () =>
    devices.find(
      (device) =>
        device.categoryCode?.toLowerCase()?.includes('mac') ||
        device.modelCode?.toLowerCase()?.includes('mac'),
    ) !== undefined;

  const openCTOTooltip = () => dispatch(openModalAction('cannotFindDevice'));

  const handleDeviceSelection = async ({ value }) => {
    const response = dispatch(
      selectRecycleDeviceAction({
        selectedDevice: devices.find((device) => value === device?.deviceID),
        networkLockStatus: fmi?.networkLockStatus,
        selectedSIMStatus,
        orderUUID,
      }),
    );

    if (response.status !== 'ERROR') navigate(getEvaluationUrl());
  };

  const handleDeviceSearch = ({
    deviceReference,
    referenceType,
    searchText,
  }) => {
    dispatch(
      validateDeviceReferenceAction({
        deviceReference,
        referenceType,
        searchText,
        orderUUID,
      }),
    );
  };

  return (
    <Fragment>
      {loading && <Loader />}

      <main className='recycleSearch'>
        <Heading>
          <Trans id='search.device.title'>Search Device</Trans>
        </Heading>

        {!orderUUID && (
          <TabbedContent
            theme='filled'
            content={[
              {
                tab: (
                  <Trans id='recycle.search.reference.title'>
                    Search by device reference
                  </Trans>
                ),
                content: (
                  <DeviceReferenceForm
                    enableReinitialize={true}
                    destroyOnUnmount={false}
                    onSubmit={handleDeviceSearch}
                    showRefHelper
                  />
                ),
                dataTest: 'deviceReference',
              },
              {
                tab: (
                  <Trans id='recycle.search.name.title'>
                    Search by device name
                  </Trans>
                ),
                content: (
                  <DeviceNameForm
                    enableReinitialize={true}
                    destroyOnUnmount={false}
                    onSubmit={handleDeviceSearch}
                  />
                ),
                dataTest: 'search-by-name',
              },
            ]}
          />
        )}

        {orderUUID && (
          <Fragment>
            <Heading size='medium'>
              <Trans id='device.evaluation.insert.device.reference'>
                Insert IMEI / Serial Number
              </Trans>
            </Heading>

            <DeviceReferenceForm
              enableReinitialize={true}
              destroyOnUnmount={false}
              // eslint-disable-next-line react/jsx-no-bind
              onSubmit={(formData) => {
                contractDeviceReference !== formData.deviceReference &&
                  dispatch(
                    addNotificationAction(incorrectDeviceReferenceWarning()),
                  );

                handleDeviceSearch(formData);
              }}
              showRefHelper
            />
          </Fragment>
        )}

        {thereAreMultipleDevices() && (
          <Fragment>
            <Heading size='medium'>
              <Trans id='recycle.search.results.title'>Select Device</Trans>
            </Heading>

            {atLeastOneDeviceIsAMac() && (
              <div className='recycleSearch__tooltip' onClick={openCTOTooltip}>
                <Info className='icon__info-icon' />
                <Trans id='search.device.info.title'>
                  Can't find your model?
                </Trans>
              </div>
            )}

            <RadioButtons
              wrap={true}
              size='big'
              onClick={handleDeviceSelection}
              options={devices?.map((device) => ({
                value: device?.deviceID,
                label: (
                  <Fragment>
                    <p>{device?.name}</p>
                    <p className='bold'>
                      <Trans id='search.device.info.max.value'>Up to</Trans>{' '}
                      {currencyFormatter(device?.maxValue, currency, language)}
                    </p>
                  </Fragment>
                ),
              }))}
            />
          </Fragment>
        )}
      </main>

      <RecycleCTO />

      <Footer classes='main' copyright={false} />
    </Fragment>
  );
};

export default Search;
