import { listBlocksAction, readTemplateAction } from 'actions/admin/templates';
import { dateFormatter } from 'helpers/formatters';
import { getFormValues } from 'redux-form';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Trans } from '@lingui/macro';

import Heading from 'components/Heading';
import Section from 'components/Section';
import TabbedContent from 'components/TabbedContent.js';
import IconAlert from 'components/icons/IconAlert';
import IconApproved from 'components/icons/IconApproved';

import './TemplateEditor.styl';
import TemplateForm from './components/TemplateForm';
import TemplatePreview from './components/TemplatePreview';

export let TemplateEditor = () => {
  const dispatch = useDispatch();

  const { campaignCode, templateName } = useParams();

  const draftTemplate = useSelector(
    ({ adminTemplates }) => adminTemplates?.draftTemplate,
  );
  const htmlPart = useSelector(
    (state) => getFormValues('template-editor')(state)?.htmlPart,
  );
  const language = useSelector(({ whitelabel }) => whitelabel.language);
  const liveTemplate = useSelector(
    ({ adminTemplates }) => adminTemplates?.liveTemplate,
  );
  const subject = useSelector(
    (state) => getFormValues('template-editor')(state)?.subject,
  );
  const templateData = useSelector(
    ({ adminTemplates }) => adminTemplates?.templateData,
  );

  useEffect(() => {
    const getData = async () => {
      dispatch(listBlocksAction());
      dispatch(readTemplateAction(campaignCode, templateName));
    };

    getData();
  }, [campaignCode, templateName, dispatch]);

  return (
    <div className='templateEditor'>
      <Heading classes='templateTitle' size='big'>
        {draftTemplate?.templateName} ({draftTemplate?.campaignCode})
      </Heading>

      <div className='templateIndicator'>
        <IconApproved className='templateIndicatorIcon' />

        <Trans id='template.last.production.change'>
          Uploaded to production on{' '}
          {dateFormatter(liveTemplate?.updatedAt, language)} by{' '}
          {liveTemplate?.updatedByName}
        </Trans>
      </div>

      <Section className='templateEditionArea'>
        <TemplateForm
          campaignCode={campaignCode}
          template={draftTemplate}
          templateName={templateName}
        />

        <div className='statusMessage'>
          <IconApproved className='statusMessageIcon--approved' />

          <Trans id='template.last.draft.change'>
            Draft updated on {dateFormatter(draftTemplate?.updatedAt, language)}{' '}
            by {draftTemplate?.updatedByName}
          </Trans>
        </div>

        {!templateData?.versionsMatch && (
          <div className='statusMessage'>
            <IconAlert className='statusMessageIcon--alert' />

            <Trans id='template.versions.mismatch'>
              Production version does not match current draft
            </Trans>
          </div>
        )}
      </Section>

      <Section className='templatePreviewArea'>
        <TabbedContent
          theme='light'
          content={[
            {
              tab: <Trans id='template.preview.draft.title'>Draft</Trans>,
              content: (
                <TemplatePreview
                  campaignCode={campaignCode}
                  template={{
                    ...draftTemplate,
                    subject,
                    htmlPart,
                  }}
                />
              ),
              showTitle: false,
            },
            {
              tab: (
                <Trans id='template.preview.production.title'>Production</Trans>
              ),
              content: (
                <TemplatePreview
                  campaignCode={campaignCode}
                  template={liveTemplate}
                />
              ),
              showTitle: false,
            },
          ]}
        />
      </Section>
    </div>
  );
};

export default TemplateEditor;
