import React, { Fragment } from 'react';

const OptionSelector = ({ item }) => {
  return (
    <Fragment>
      <span className='selection-indicator' />
      <label>{item}</label>
    </Fragment>
  );
};

export default OptionSelector;
