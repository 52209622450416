import {
  CLEAN_SETTLEMENTS,
  EXPORT,
  FILTER_REPORTS,
  GET_REPORTS,
  GET_SETTLEMENTS,
  LIST_RECYCLE_ORDERS,
} from 'actions/reports';

import initialState from 'reducers/initialState';

export default function (state = initialState.reports, action) {
  switch (action.type) {
    case GET_REPORTS:
      return {
        ...state,
        ...action.data,
      };
    case FILTER_REPORTS:
      return { ...state, list: action.data };
    case GET_SETTLEMENTS:
      return { ...state, settlements: action.data };
    case CLEAN_SETTLEMENTS:
      return { ...state, settlements: initialState.reports.settlements };
    case LIST_RECYCLE_ORDERS:
      return { ...state, recycle: action.data };
    case EXPORT:
    default:
      return state;
  }
}
