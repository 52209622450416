import { performCreditCheckAction } from 'actions/contract';
import { clearCustomerAction } from 'actions/customer';
import { addNotificationAction } from 'actions/ui';
import flow from 'enhancers/flow';
import { stringFormatter } from 'helpers/formatters';
import { parsePhoneNumber } from 'libphonenumber-js';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { getCurrentCart, hasDevicesInOrders } from 'utils';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { t } from '@lingui/macro';

import Footer from 'components/Footer';
import Multistep from 'components/Multistep';

import { checkout } from 'constants/flows';
import { quotationOnlyNotification } from 'constants/notifications';
import {
  cartPageUrl,
  checkoutFlowUrl,
  contractPageUrl,
  rootUrl,
} from 'constants/routes';

const Checkout = ({
  total,
  steps = [],
  currentStep = 0,
  next,
  prev,
  currentStepName,
  syncFlow,
  initFlow,
  discardFlow,
}) => {
  const { cartUUID } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cart = useSelector(({ carts }) => getCurrentCart(carts, cartUUID));
  const financingType = cart?.financingType;

  const countryCode = useSelector(({ whitelabel }) => whitelabel.country);
  const phone = useSelector(
    ({ customer }) => customer.bnpPhone || customer.phone,
  );
  const email = useSelector(({ customer }) => customer.email);
  const firstName = useSelector(({ customer }) => customer.firstName);
  const lastName = useSelector(({ customer }) => customer.lastName);
  const options = useSelector(({ customer }) => customer.options);
  const contractSteps = useSelector(({ contract }) => contract.steps);
  const customerInfo = useSelector(({ form }) => form.customerInfo);
  const companyInfo = useSelector(({ form }) => form.companyInfo);
  const applyForFinance = useSelector(
    ({ customer }) => customer.applyForFinance,
  );
  const orderProductsFromTD = useSelector(
    ({ customer }) => customer.orderProductsFromTD,
  );
  const isNewCustomer = useSelector(({ customer }) => customer.isNewCustomer);

  useEffect(() => {
    initFlow();
    syncFlow(checkoutFlowUrl(cartUUID), checkout());
  }, [cartUUID]);

  useEffect(() => {
    return () => {
      discardFlow();
    };
  }, [dispatch]);

  const blockedButtons = () => {
    switch (currentStepName) {
      case 'customerInfo':
        return (
          (customerInfo && !isEmpty(customerInfo.syncErrors)) ||
          (companyInfo && !isEmpty(companyInfo.syncErrors)) ||
          !hasDevicesInOrders(cart.orders)
        );
      case 'contractStatus':
        return Object.values(omit(contractSteps, ['swap'])).some(
          (step) => step !== 'APPROVED',
        );
      default:
        return false;
    }
  };

  const prevPage = async () => {
    if (currentStep > 0) {
      await prev();

      /**
       * The selected family variable needs to be encoded because it contains
       * a forward slash which would mess with the router path matching
       */
      steps &&
        navigate(stringFormatter(steps[currentStep - 1].path, [cartUUID], '#'));
    } else {
      navigate(cartPageUrl(cartUUID));
    }
  };

  const hiddenBackButtons = () => {
    switch (currentStepName) {
      case 'customerInfo':
        return true;
      case 'contractStatus':
        return true;
      default:
        return false;
    }
  };

  const nextPage = async () => {
    let allowNavigation = true;

    switch (currentStepName) {
      case 'customerInfo':
        const phoneNumber = parsePhoneNumber(phone.trim(), countryCode);

        const { creditStatus, quotationOnly } = await dispatch(
          performCreditCheckAction(cartUUID, {
            email,
            phone: phoneNumber.format('E.164'),
            firstName,
            lastName,
            distributionMethod: options.find(
              ({ selected }) => selected === true,
            ).id,
            company: companyInfo?.values,
            applyForFinance,
            orderProductsFromTD,
            isNewCustomer,
          }),
        );

        if (creditStatus !== 'SUCCESS') {
          allowNavigation = false;
        } else {
          if ('B2B_LEASE' === financingType) {
            allowNavigation = false;
            if (quotationOnly) {
              await dispatch(
                addNotificationAction(quotationOnlyNotification()),
              );
              navigate(rootUrl);
            } else {
              navigate(contractPageUrl(cartUUID));
            }
          }
          dispatch(clearCustomerAction());
        }
        break;
      case 'contractStatus':
        navigate(contractPageUrl(cartUUID));
        allowNavigation = false;
        break;
      default:
        break;
    }

    allowNavigation && (await next());

    return (
      steps &&
      allowNavigation &&
      navigate(stringFormatter(steps[currentStep + 1].path, [cartUUID], '#'))
    );
  };

  const getTitle = (steps, currentStep) =>
    !isEmpty(steps) ? steps[currentStep].title : checkout()[currentStep].title;

  const getNextBtnLabel = (steps, currentStep) =>
    !isEmpty(steps)
      ? steps[currentStep].nextBtnLabel
      : checkout()[currentStep].nextBtnLabel;

  return (
    <>
      <Multistep
        step={currentStep + 1}
        total={total}
        title={getTitle(steps, currentStep)}
      />

      <Outlet />

      <Footer
        classes='main'
        copyright={false}
        backBtnLabel={t({ id: 'button.back', message: `Back` })}
        backBtnHidden={hiddenBackButtons()}
        backBtnAction={prevPage}
        nextBtnLabel={getNextBtnLabel(steps, currentStep)}
        nextBtnDisabled={blockedButtons()}
        nextBtnAction={nextPage}
      />
    </>
  );
};

export default flow({
  name: 'checkout',
  total: checkout().length,
  steps: checkout(),
})(Checkout);
