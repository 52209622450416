import {
  saveSelectedDeviceAction,
  saveSelectedExtraOptionAction,
  saveSelectedInsuranceAction,
} from 'actions/carts';
import { currencyFormatter } from 'helpers/formatters';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { capitalize, getCurrentCart, safeHTMLParser } from 'utils';

import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';

import Heading from 'components/Heading';
import Image from 'components/Image';
import Loader from 'components/Loader';
import Slideshow from 'components/Slideshow';
import Cart from 'components/cart/Cart';
import List from 'components/deprecated_list/DeprecatedList';

import productOptionsTitles from 'constants/productOptions';

import useTrackPage from 'hooks/useTrackPage';

import './ProductOptions.styl';

const SelectedOption = ({ selectedOption, currency, language }) => {
  const {
    part_number,
    sku,
    ean,
    images,
    img_url,
    selected_options,
    documents,
    cash_price,
    specs,
    monthly_price,
    cashPriceExclVAT,
    pricingIncludesTax,
  } = selectedOption || undefined;

  return (
    <div className='product-option-selected'>
      <div className='product-option-selected-slideshow'>
        {images?.length > 0 ? (
          <Slideshow
            images={images.map((img, idx) => {
              return {
                imageUrl: img.url,
                caption: `${part_number}-${idx + 1}`,
                thumbnailUrl: img.url,
              };
            })}
          />
        ) : (
          <Image
            className='product-option-selected-single-image'
            src={img_url}
          />
        )}
      </div>
      {selectedOption && (
        <div className='product-option-selected-details'>
          <div>
            <h4>
              <Trans id='product-option-selected-tech-details'>
                Technical details
              </Trans>
            </h4>
            <ul>
              {part_number && (
                <li>
                  <strong>
                    <Trans id='product.option.selected.part.number'>
                      Manuf P/N :
                    </Trans>
                  </strong>
                  {part_number}
                </li>
              )}
              {sku && (
                <li>
                  <strong>
                    <Trans id='product.option.selected.ref.number'>
                      Ref No. :
                    </Trans>
                  </strong>
                  {sku}
                </li>
              )}
              {ean && (
                <li>
                  <strong>
                    <Trans id='product.option.selected.ean'>EAN : </Trans>
                  </strong>
                  {ean}
                </li>
              )}
            </ul>
          </div>
          {specs ? (
            <div>{safeHTMLParser(specs)}</div>
          ) : (
            selected_options && (
              <ul>
                {map(selected_options, (v, k) => (
                  <li key={`${k}-option`}>
                    <strong>
                      {t({
                        id: `product.option.selected.${k}`,
                        message: `${capitalize(k)} :`,
                      })}
                    </strong>
                    {` ${v}`}
                  </li>
                ))}
              </ul>
            )
          )}
          {documents && (
            <div>
              <h4>
                <Trans id='product-option-selected-documents'>
                  Associated documents
                </Trans>
              </h4>
              <ul>
                {Object.entries(documents).map(([key, value], idx) => (
                  <li key={`document-${key}-${idx}`}>
                    <a target='_blank' rel='noreferrer' href={value}>
                      {key}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <ul>
            {monthly_price && (
              <li>
                <strong>
                  <Trans id='product.option.selected.monthly.price'>
                    Monthly price :
                  </Trans>
                </strong>
                <span>
                  {`${currencyFormatter(monthly_price, currency, language)} ${t(
                    {
                      id: 'extras.per.month',
                      message: '/ month',
                    },
                  )}`}
                </span>
              </li>
            )}
            {pricingIncludesTax
              ? cash_price && (
                  <li>
                    <strong>
                      <Trans id='product.option.selected.cash.price'>
                        Cash price :
                      </Trans>
                    </strong>
                    <span>
                      {currencyFormatter(cash_price, currency, language)}
                    </span>
                  </li>
                )
              : cashPriceExclVAT >= 0 && (
                  <li>
                    <strong>
                      <Trans id='product.option.selected.cash.price.excl.vat'>
                        Cash price excl. VAT :
                      </Trans>
                    </strong>
                    <span>
                      {currencyFormatter(cashPriceExclVAT, currency, language)}
                    </span>
                  </li>
                )}
          </ul>
        </div>
      )}
    </div>
  );
};

const ProductOptions = () => {
  useTrackPage('ProductOptions');

  const dispatch = useDispatch();
  const { cartUUID } = useParams();

  const cart = useSelector(({ carts }) => getCurrentCart(carts, cartUUID));
  const financingType = cart?.financingType;
  const currency = useSelector(({ whitelabel }) => whitelabel.currency);
  const language = useSelector(({ whitelabel }) => whitelabel.language);
  const loading = useSelector(({ ui }) => ui.loading);
  const productOptions = useSelector(({ catalog }) => catalog.productOptions);
  const paymentPlanOptions = useSelector(
    ({ catalog }) => catalog?.paymentPlanOptions || [],
  )[0]?.options;
  const insuranceOptions = useSelector(
    ({ catalog }) => catalog.insuranceOptions,
  );
  const extrasOptions = useSelector(({ catalog }) => catalog.extrasOptions);

  const selectedOption = useSelector(({ catalog }) => catalog.selectedOption);
  const pricingIncludesTax = cart?.pricingIncludesTax;

  const selectVariant = ({ id, hasCTOs }) => {
    return dispatch(
      saveSelectedDeviceAction(cartUUID, { deviceCode: id, hasCTOs }),
    );
  };

  const drawOptionCategory = (name, type, options, onClick) => {
    const lowercaseName = name.toLowerCase();

    if (!isEmpty(options)) {
      return (
        <Fragment key={name}>
          <h2 className={`list-title ${lowercaseName}`}>
            {productOptionsTitles(name)}
          </h2>

          <List
            type={type}
            items={options}
            currency={currency}
            language={language}
            classes={lowercaseName}
            handleClick={onClick}
            highlight
            showId
          />
        </Fragment>
      );
    }
  };

  const drawProductOptionCategory = ({ name, options }) =>
    drawOptionCategory(
      name,
      name === 'COLOR' ? 'image' : 'circle',
      options,
      (deviceCode) =>
        selectVariant(options.find((option) => option.id === deviceCode)),
    );

  const drawExtrasOptionsCategory = ({ name, options }) =>
    drawOptionCategory(name, 'image', options, (selected) =>
      dispatch(saveSelectedExtraOptionAction(cartUUID, selected)),
    );

  if (loading) return <Loader />;

  return (
    <>
      <main className='content-left'>
        <Heading size='big' classes={'product-options-page-title'}>
          {t({
            id: 'product.options.page.title',
            message: `Choose your ${cart?.family}`,
          })}
        </Heading>

        <SelectedOption
          selectedOption={selectedOption}
          currency={currency}
          language={language}
          financingType={financingType}
          pricingIncludesTax={pricingIncludesTax}
        />

        {!isEmpty(extrasOptions) &&
          extrasOptions.map(drawExtrasOptionsCategory)}

        {!isEmpty(productOptions) &&
          productOptions.map(drawProductOptionCategory)}

        {!isEmpty(insuranceOptions) &&
          drawOptionCategory(
            'Insurances',
            'circle',
            insuranceOptions,
            (insuranceID) =>
              dispatch(saveSelectedInsuranceAction(cartUUID, insuranceID)),
          )}
      </main>

      <aside className='content-right'>
        <Cart
          cart={cart}
          options={paymentPlanOptions}
          currency={currency}
          language={language}
          updateOptions
          actionable
        />
      </aside>
    </>
  );
};

export default ProductOptions;
