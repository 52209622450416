import classNames from 'classnames';

import React, { useEffect, useRef, useState } from 'react';

import Spinner from 'components/Spinner';

import './Button.styl';

export const Button = ({
  type = 'button',
  classes,
  children,
  loading = false,
  disabled = false,
  onAnimationEnd,
  onClick = () => {},
  selected,
  testSelector,
}) => {
  const [performingAction, setPerformingAction] = useState(false);
  const abortControllerRef = useRef(null);

  useEffect(() => {
    abortControllerRef.current = new AbortController();

    return () => {
      abortControllerRef.current.abort();
    };
  }, []);

  const handleOnClick = async (evt) => {
    if (!performingAction) {
      setPerformingAction(true);
      try {
        const signal = abortControllerRef.current.signal;
        await onClick(evt, signal);
      } finally {
        if (!abortControllerRef.current.signal.aborted) {
          setPerformingAction(false);
        }
      }
    }
  };

  const isDisabled = disabled || performingAction;
  const isLoading = loading || performingAction;

  const cx = classNames('button', classes, {
    loading: isLoading,
    disabled: isDisabled,
    selected,
  });

  let content = children;

  if (isLoading) content = <Spinner />;

  return (
    <button
      type={type}
      onClick={handleOnClick}
      className={cx}
      disabled={isDisabled}
      data-test={testSelector}
      onAnimationEnd={onAnimationEnd}
    >
      {content}
    </button>
  );
};

export default Button;
