import {
  getCollectionsAction,
  getCollectorsAction,
} from 'actions/admin/collection';
import { optionsToArrayObjs } from 'helpers/formatters';
import { Field, getFormValues, reduxForm, submit } from 'redux-form';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';

import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import Loader from 'components/Loader';
import RenderDatePicker, { formatDate } from 'components/RenderDatePicker';
import Table from 'components/table/Table';

import {
  adminCollectionDetailsUrl,
  adminOrganiseCollectionUrl,
} from 'constants/routes';
import { collectionStatus } from 'constants/status';

import useTrackPage from 'hooks/useTrackPage';

import './Collections.styl';

const Collections = () => {
  useTrackPage('Collections');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formValues = useSelector((state) =>
    getFormValues('collections-status')(state),
  );
  const loading = useSelector(({ ui }) => ui.loading);
  const language = useSelector(({ whitelabel }) => whitelabel.language);
  const showCollectionInstructions = useSelector(
    ({ whitelabel }) => whitelabel.configs.showCollectionInstructions,
  );
  const collections = useSelector(
    ({ adminCollection }) => adminCollection.collections,
  );
  const collectors = useSelector(
    ({ adminCollection }) => adminCollection.collectors,
  );

  useEffect(() => {
    dispatch(getCollectorsAction());
    dispatch(getCollectionsAction(formValues));
  }, [dispatch, formValues]);

  if (loading) return <Loader />;

  const columns = [
    {
      label: t({
        id: 'table.column.collection.id',
        message: `Collection ID`,
      }),
      accessor: 'collectionUUID',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.created', message: `Created on` }),
      accessor: 'createdDate',
      type: 'date',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.collector', message: `Collector` }),
      accessor: 'collector',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'left',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.store', message: `Store` }),
      accessor: 'storeName',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'left',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.courier', message: `Courier` }),
      accessor: 'courier',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'left',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.devices', message: `Devices` }),
      accessor: 'devicesQuantity',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.value', message: `Value` }),
      accessor: 'sumOfOfferedValues',
      type: 'currency',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.scheduled', message: `Scheduled` }),
      accessor: 'scheduleDate',
      type: 'date',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.status', message: `Status` }),
      accessor: 'status',
      type: 'status',
      list: collectionStatus(),
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.actions', message: `Actions` }),
      accessor: 'actions',
      type: 'actions',
      filterable: false,
      sortable: false,
      align: 'right',
      wrap: false,
    },
  ];

  const actions = [
    {
      label: t({ id: 'collection.action.details', message: `Details` }),
      handler: (_, { collectionUUID }) => {
        navigate(adminCollectionDetailsUrl(collectionUUID));
      },
      testSelector: 'collection-details',
    },
  ];

  const rows = collections.map((collection) => ({ ...collection, actions }));

  return (
    <>
      <h1 className='admin-page-title'>
        <Trans id='collections.title'> Collections </Trans>
      </h1>

      <div className='collectors-filter'>
        {showCollectionInstructions && (
          <Button
            classes='organise-collection-btn'
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() =>
              window.open(
                `https://cdn.financetech365.com/ft/crm/${language}/Packaging_Request_Form_and_Instructions_2023.pdf`,
              )
            }
            testSelector='organise-collection'
          >
            <Trans id='organise.collection.packaging.request'>
              Packaging request form and instructions
            </Trans>
          </Button>
        )}

        <Button
          classes='organise-collection-btn'
          // TODO make this component reliant only on class functions
          // in order to remove arrow functions and enforce this rule
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => navigate(adminOrganiseCollectionUrl)}
          testSelector='organise-collection'
        >
          <Trans id='organise.collection.title'> Organise Collection </Trans>
        </Button>

        <form className='organise-collection-form' autoComplete='off'>
          <div className='collection-form-collector'>
            {collectors && (
              <Field
                name='collector'
                component={Dropdown}
                clearable
                placeholder={t({
                  id: 'collection.form.select.collector.placeholder',
                  message: `Select Collector`,
                })}
                options={optionsToArrayObjs(collectors)}
                variant='underline'
                labelType='outside'
                testSelector='collector-dropdown'
              />
            )}
          </div>

          <div className='datepicker-container'>
            <Trans id='collection.date.tittle'>Created</Trans>
            <Field
              name='startDate'
              component={RenderDatePicker}
              label={t({ id: 'collection.form.from.label', message: `From` })}
              format={formatDate}
              parse={formatDate}
              testSelector='inicial-date-collection-search'
            />

            <Field
              name='endDate'
              component={RenderDatePicker}
              label={t({ id: 'collection.form.to.label', message: `to` })}
              format={formatDate}
              parse={formatDate}
              testSelector='final-date-collection-search'
            />
          </div>
        </form>
      </div>

      <Table columns={columns} data={rows} keyColumn='collectionUUID' />
    </>
  );
};

export default reduxForm({
  form: 'collections-status',
  onSubmit: (values, dispatch) => {
    dispatch(getCollectionsAction(values));
  },
  onChange: (_values, dispatch) => {
    dispatch(submit('collections-status'));
  },
})(Collections);
