import { openModalAction } from 'actions/ui';
import classNames from 'classnames';
import { b64toBlob } from 'utils';

import React, { Fragment, useState } from 'react';
import Resizer from 'react-image-file-resizer';
import { useDispatch } from 'react-redux';

import { Trans } from '@lingui/react';

import Button from 'components/Button';
import Hyperlink from 'components/Hyperlink';
import Image from 'components/Image';
import Modal from 'components/Modal';
import IconAlert from 'components/icons/IconAlert';
import IconApproved from 'components/icons/IconApproved';
import IconCamera from 'components/icons/IconCamera';
import IconRotate90 from 'components/icons/IconRotate90';
import IconTrash from 'components/icons/IconTrash';
import IconZoom from 'components/icons/IconZoom';

import {
  COMPRESSION_QUALITY,
  MAX_HEIGHT_UPLOAD,
  MAX_WIDTH_UPLOAD,
} from 'constants/images';

import './CustomerIDCheck.styl';

const Instruction = ({ icon, label, imgClass, country }) => {
  return (
    <div className='customerId-instruction'>
      <div className='description'>
        {icon}
        <span>{label}</span>
      </div>

      <div className='customerId-mock-phone'>
        <Image
          src={`https://cdn.financetech365.com/ft/ft_generic/${country}/customerId-mock.png`}
          alt='Customer ID mock phone'
          className={imgClass}
        />
        <div className='circle' />
      </div>
    </div>
  );
};

export const CustomerIDCheck = ({ disabled, image, setImage, country }) => {
  const [rotationDegree, setRotationDegree] = useState(0);
  const dispatch = useDispatch();

  const handleSaveImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      transformImage(file);
    } else {
      resetImage();
    }
    // This is necessary to allow re-upload of the same file, after being deleted for example
    event.target.value = null;
  };

  const openImageModal = () => dispatch(openModalAction('expand-customer-id'));
  const openHelperModal = (event) => {
    !disabled && dispatch(openModalAction('customerId-helper'));
  };

  const transformImage = (file, rotationAngle = 0) => {
    Resizer.imageFileResizer(
      file,
      MAX_WIDTH_UPLOAD,
      MAX_HEIGHT_UPLOAD,
      'JPEG',
      COMPRESSION_QUALITY,
      rotationAngle,
      (uri) => {
        setImage(uri);
      },
    );
  };

  const resetImage = () => {
    setImage(null);
  };

  const rotateImage = async () => {
    const newRotationDegree = rotationDegree + 90;
    const blobImage = await b64toBlob(image);

    transformImage(blobImage, newRotationDegree);

    if (rotationDegree === 270) {
      setRotationDegree(0);
    } else {
      setRotationDegree(newRotationDegree);
    }
  };

  return (
    <div className='customerIDCheck'>
      <p className={classNames('info', { disabled: disabled })}>
        <Trans id='trade.in.review.customer.id.info'>
          Spanish law requires that we check and retain an image of the id of
          the customer selling their phone. Please allow access to the phone
          camera and use the button to take a photo of your id card, or upload a
          saved image of your id card.
        </Trans>
      </p>

      <div
        className={classNames('camera-button-wrapper', {
          disabled: disabled,
        })}
      >
        <label htmlFor='customerIdPhoto' className='button capture-id-btn'>
          <IconCamera />
          <Trans id='trade.in.review.customer.id.button.capture'>
            Take ID photo
          </Trans>
        </label>
        <input
          disabled={disabled}
          type='file'
          id='customerIdPhoto'
          name='customerId'
          accept='image/*'
          capture='environment'
          onChange={handleSaveImage}
        />
        <label htmlFor='customerIdFile' className='button'>
          <Trans id='trade.in.review.customer.id.button.upload'>
            Attach ID Image
          </Trans>
        </label>

        <input
          disabled={disabled}
          type='file'
          id='customerIdFile'
          accept='image/*'
          onChange={handleSaveImage}
        />
      </div>

      <Hyperlink
        classes={classNames('customerId-helper', {
          disabled: disabled,
        })}
        onClick={openHelperModal}
        variant='underline'
        content={
          <Trans id='trade.in.review.customer.id.button.helper'>
            Please take a look at examples how to properly record your ID
          </Trans>
        }
      />

      {image && !disabled && (
        <Fragment>
          <div className='customerId-wrapper'>
            <Button classes='button btn-rotate-img light' onClick={rotateImage}>
              <IconRotate90 />
            </Button>

            <Button
              onClick={openImageModal}
              classes='button btn-expand-img light'
            >
              <IconZoom />
            </Button>
            <Button onClick={resetImage} classes='button btn-delete-img light'>
              <IconTrash />
            </Button>
            <img
              className='customerId-preview'
              alt='Customer ID preview'
              src={image}
            />
          </div>

          <p className='info'>
            <Trans id='trade.in.review.customer.id.warning'>
              Before submitting, please check if ID photo is clear, and all ID
              is readable. Enlarge ID image by click on zoom icon on the top
              corner.
            </Trans>
          </p>
        </Fragment>
      )}

      <Modal name='expand-customer-id'>
        <img
          className='customerId-expanded'
          alt='Customer ID expanded'
          src={image}
        />
      </Modal>

      <Modal name='customerId-helper'>
        <Fragment>
          <Instruction
            icon={<IconApproved className={'icon icon--approved'} />}
            label={
              <Trans id='trade.in.review.customer.photo.ok'>ID photo OK</Trans>
            }
            imgClass={'correct'}
            country={country}
          />

          <Instruction
            icon={<IconAlert className={'icon icon--alert'} />}
            label={
              <Trans id='trade.in.review.customer.photo.not.ok'>
                ID photo not OK!
              </Trans>
            }
            imgClass={'incorrect_orientation'}
            country={country}
          />

          <Instruction
            icon={<IconAlert className={'icon icon--alert'} />}
            label={
              <Trans id='trade.in.review.customer.photo.not.ok'>
                ID photo not OK!
              </Trans>
            }
            imgClass={'incorrect_size'}
            country={country}
          />

          <Instruction
            icon={<IconAlert className={'icon icon--alert'} />}
            label={
              <Trans id='trade.in.review.customer.photo.not.ok'>
                ID photo not OK!
              </Trans>
            }
            imgClass={'blurry'}
            country={country}
          />
        </Fragment>
      </Modal>
    </div>
  );
};

export default CustomerIDCheck;
