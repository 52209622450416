import { savePageNameAction } from 'actions/ui';
import { trackPageView } from 'helpers/tracking';
import store from 'store';

function trackPage(WrappedComponent, pageName) {
  return class TrackPage extends WrappedComponent {
    componentDidMount() {
      super.componentDidMount && super.componentDidMount();
      store.dispatch(savePageNameAction(pageName));

      trackPageView(pageName, window.location.pathname, window.location.href);
    }
  };
}

export default trackPage;
