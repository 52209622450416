import { hideLoaderAction, showLoaderAction } from 'actions/ui';
import { posalePost as post } from 'apis/v2';

import { PAGE_QUANTITY } from 'constants/scroll';

export const GET_PRODUCT_CATEGORIES = 'GET_PRODUCT_CATEGORIES';
export function getProductCategoriesAction(
  financingType = undefined,
  paymentplanID = undefined,
  tags = [],
  tagFilterType,
  name,
) {
  return async function (dispatch) {
    dispatch(showLoaderAction());
    const response = await post(GET_PRODUCT_CATEGORIES, {
      financingType,
      paymentplanID,
      tagFilterList: tags,
      tagFilterType: tagFilterType,
      serviceName: name,
    });

    const data = response.data.data.categories;
    dispatch(hideLoaderAction());
    await dispatch({ type: GET_PRODUCT_CATEGORIES, data });
  };
}

export const CLEAR_PRODUCT_CATEGORIES = 'CLEAR_PRODUCT_CATEGORIES';
export function clearProductCategoriesAction() {
  return function (dispatch) {
    dispatch({ type: CLEAR_PRODUCT_CATEGORIES });
  };
}

export const GET_PRODUCT_FAMILIES = 'GET_PRODUCT_FAMILIES';
export function getProductFamiliesAction(
  financingType,
  paymentplanID,
  categoryCode,
  tags = [],
  tagFilterType,
  options = {},
) {
  return async function (dispatch) {
    const { page = 0, searchText = '' } = options;

    dispatch(showLoaderAction());

    const response = await post(GET_PRODUCT_FAMILIES, {
      categoryCode,
      financingType,
      paymentplanID,
      tagFilterList: tags,
      tagFilterType,
      searchText,
      page,
      quantity: PAGE_QUANTITY,
    });

    if (response.data.status !== 'ERROR') {
      const { families } = response.data.data;

      if (page === 0) {
        dispatch(saveProductFamiliesAction(families));
      } else {
        dispatch(
          saveMoreProductFamiliesAction(
            response.data.data.families,
            families?.length < PAGE_QUANTITY,
          ),
        );
      }
    }
    dispatch(hideLoaderAction());
  };
}

export const SAVE_PRODUCT_FAMILIES = 'SAVE_PRODUCT_FAMILIES';
export function saveProductFamiliesAction(families) {
  return function (dispatch) {
    dispatch({ type: SAVE_PRODUCT_FAMILIES, families });
  };
}

export const SAVE_MORE_PRODUCT_FAMILIES = 'SAVE_MORE_PRODUCT_FAMILIES';
export function saveMoreProductFamiliesAction(families, allProductsFetched) {
  return function (dispatch) {
    dispatch({
      type: SAVE_MORE_PRODUCT_FAMILIES,
      families,
      allProductsFetched,
    });
  };
}

export const CLEAR_PRODUCT_FAMILIES = 'CLEAR_PRODUCT_FAMILIES';
export function clearProductFamiliesAction() {
  return function (dispatch) {
    dispatch({ type: CLEAR_PRODUCT_FAMILIES });
  };
}

export const SAVE_PRODUCT_OPTIONS = 'SAVE_PRODUCT_OPTIONS';
export function saveProductOptionsAction(options) {
  return function (dispatch) {
    dispatch({ type: SAVE_PRODUCT_OPTIONS, options });
  };
}

export const CLEAR_PRODUCT_OPTIONS = 'CLEAR_PRODUCT_OPTIONS';
export function clearProductOptionsAction() {
  return function (dispatch) {
    dispatch({ type: CLEAR_PRODUCT_OPTIONS });
  };
}

export const SAVE_EXTRAS = 'SAVE_EXTRAS';
export function saveExtrasAction(extras) {
  return function (dispatch) {
    dispatch({ type: SAVE_EXTRAS, extras });
  };
}

export const SAVE_MORE_EXTRAS = 'SAVE_MORE_EXTRAS';
export function saveMoreExtrasAction(extras, allExtrasFetched) {
  return function (dispatch) {
    dispatch({ type: SAVE_MORE_EXTRAS, extras, allExtrasFetched });
  };
}

export const GET_EXTRAS_CATEGORIES = 'GET_EXTRAS_CATEGORIES';
export function getExtrasCategoriesAction() {
  return async function (dispatch) {
    const response = await post(GET_EXTRAS_CATEGORIES);

    dispatch({
      type: GET_EXTRAS_CATEGORIES,
      data: response.data.data.categories,
    });
  };
}

export const UNLOCK_MORE_EXTRAS = 'UNLOCK_MORE_EXTRAS';
export function unlockMoreExtrasAction() {
  return function (dispatch) {
    dispatch({ type: UNLOCK_MORE_EXTRAS });
  };
}

export const GET_SERVICES = 'GET_SERVICES';
export function getServicesAction(tradecartID, selectedOutcome) {
  return async function (dispatch) {
    dispatch(showLoaderAction());
    const response = await post(GET_SERVICES, {
      tradecartID,
      selectedOutcome,
    });

    const { data, status } = response.data;
    dispatch(hideLoaderAction());
    if (status !== 'ERROR') {
      return data;
    }
  };
}

export const CLEAR_EXTRAS_CATALOG = 'CLEAR_EXTRAS_CATALOG';
export function clearExtrasCatalogAction() {
  return function (dispatch) {
    dispatch({ type: CLEAR_EXTRAS_CATALOG });
  };
}
