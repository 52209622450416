import { currencyFormatter } from 'helpers/formatters';
import { currencySymbolToISO3 } from 'utils';

import React, { Fragment } from 'react';

import Heading from 'components/Heading';

import { ONLINE, POSALE, swapLegalText } from 'constants/recycle';

import './SwapLegalText.styl';

const defaultConfig = {
  evaluationDescription: true,
  header: true,
  disclaimer: true,
};

export const SwapLegalText = ({ evaluation, isOnline, language, config }) => {
  const context = isOnline ? ONLINE : POSALE;
  const actualConfig = {
    ...defaultConfig,
    ...config,
  };

  return (
    <div className='swap-legal-text__result'>
      {actualConfig.header && (
        <div className='swap-legal-text__disclaimer--header'>
          <Heading size='medium'>
            {swapLegalText(
              context,
              'title',
              currencyFormatter(
                evaluation.remainingLoan,
                currencySymbolToISO3(evaluation.currencyCode),
                language,
              ),
            )}
          </Heading>
          <p>
            {swapLegalText(
              context,
              'subtitle',
              currencyFormatter(
                evaluation.monthlyPayment,
                currencySymbolToISO3(evaluation.currencyCode),
                language,
              ),
            )}
          </p>
        </div>
      )}

      <div className='swap-legal-text__device-value'>
        {swapLegalText(
          context,
          'returnValue',
          currencyFormatter(
            evaluation.deviceValue,
            currencySymbolToISO3(evaluation.currencyCode),
            language,
          ),
        )}
      </div>

      <p>{swapLegalText(context, 'body1')}</p>
      <p>
        {swapLegalText(
          context,
          'body2',
          currencyFormatter(
            Math.max(evaluation.remainingLoan - evaluation.deviceValue, 0),
            currencySymbolToISO3(evaluation.currencyCode),
            language,
          ),
        )}
      </p>

      {actualConfig.disclaimer && (
        <Fragment>
          {evaluation.loanBalance && evaluation.loanBalance > 0 && (
            <p>{swapLegalText(context, 'body3')}</p>
          )}
          <p>{swapLegalText(context, 'body4')}</p>
          <p>{swapLegalText(context, 'body5')}</p>
          <p>{swapLegalText(context, 'body6')}</p>
          <p>{swapLegalText(context, 'body7')}</p>
        </Fragment>
      )}
    </div>
  );
};

export default SwapLegalText;
