import {
  acceptance,
  email,
  format,
  ibanValidator,
  idNumberValidator,
  length,
  phoneValidator,
  postcodeValidator,
  required,
} from 'helpers/validators';
import isEmpty from 'lodash/isEmpty';
import { Field, getFormValues, reduxForm, stopSubmit } from 'redux-form';

import React, { Fragment, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import Button from 'components/Button';
import { renderCheckbox } from 'components/Checkbox';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';

import { phoneRegex } from 'constants/forms';

import CustomerIDCheck from './CustomerIDCheck';
import './RecycleCustomerInfo.styl';
import TransOverride from './TransOverride';

const AddressFields = ({ country }) => (
  <Fragment>
    <Field
      component={Input}
      placeholder={t({
        id: 'forms.contact.placeholder.address',
        message: `Address`,
      })}
      name='address'
      validate={[required(), length({ max: 255 })]}
      size='medium'
      variant='underline'
      testSelector='address'
    />

    <Field
      component={Input}
      placeholder={t({
        id: 'forms.contact.placeholder.postalcode',
        message: `Postal Code`,
      })}
      name='postalCode'
      validate={[
        required(),
        postcodeValidator({ country: country }),
        length({ max: 45 }),
      ]}
      size='medium'
      variant='underline'
      testSelector='postalCode'
    />

    <Field
      component={Input}
      placeholder={t({ id: 'forms.contact.placeholder.city', message: `City` })}
      name='city'
      validate={[required(), length({ max: 255 })]}
      size='medium'
      variant='underline'
      testSelector='city'
    />
  </Fragment>
);

const ExtraCustomerFields = ({
  recycleCustomerExtraFields = [],
  country,
  idPhoto,
  showExtraAddressFields,
  mode,
  requireIDPhotoOnOnlineTradein,
  setIdPhoto,
  wlCode,
}) => {
  return (
    <>
      {recycleCustomerExtraFields.includes('PROVINCE') && (
        <Field
          component={Input}
          placeholder={t({
            id: 'forms.contact.placeholder.province',
            message: `Province`,
          })}
          name='province'
          validate={[required(), length({ max: 255 })]}
          size='medium'
          variant='underline'
          testSelector='province'
        />
      )}

      {recycleCustomerExtraFields.includes('IDTYPE') && (
        <Field
          component={Dropdown}
          placeholder={t({
            id: 'forms.contact.placeholder.idtype',
            message: `ID Type`,
          })}
          name='idType'
          options={[
            {
              value: 'PASSPORT',
              label: t({
                id: 'forms.contact.dropdown.label.passport',
                message: `Passport`,
              }),
            },
            {
              value: 'BANK_CARD',
              label: t({
                id: 'forms.contact.dropdown.label.bankcard',
                message: `Bank Card`,
              }),
            },
            {
              value: 'DRIVING_LICENCE',
              label: t({
                id: 'forms.contact.dropdown.label.drivinglicense',
                message: `Driving License`,
              }),
            },
          ]}
          variant='underline'
          size='medium'
          testSelector='idType'
        />
      )}

      {recycleCustomerExtraFields.includes('IDNUMBER') && (
        <Field
          component={Input}
          placeholder={t({
            id: 'forms.contact.placeholder.idnumber',
            message: `ID Number`,
          })}
          name='idNumber'
          validate={[
            required(),
            idNumberValidator({ country: country }),
            length({ max: 100 }),
          ]}
          size='medium'
          variant='underline'
          testSelector='idNumber'
        />
      )}

      {recycleCustomerExtraFields.includes('IBAN') && (
        <Field
          component={Input}
          placeholder={t({
            id: 'forms.contact.placeholder.iban',
            message: `IBAN`,
          })}
          name='IBAN'
          validate={[required(), ibanValidator(), length({ max: 100 })]}
          size='medium'
          variant='underline'
          testSelector='IBAN'
          helpText={t({
            id: 'forms.contact.helper.iban',
            message: `Payment via bank transfer - 48 hours after buyback`,
          })}
        />
      )}

      {recycleCustomerExtraFields.includes('BENEFICIARY') && (
        <Field
          component={Input}
          placeholder={t({
            id: 'forms.contact.placeholder.beneficiary',
            message: `Beneficiary Name`,
          })}
          name='beneficiary'
          validate={[required(), length({ max: 100 })]}
          size='medium'
          variant='underline'
          testSelector='beneficiary'
          helpText={t({
            id: 'forms.contact.helper.beneficiary',
            message: `Bank account holder name`,
          })}
        />
      )}

      {(showExtraAddressFields ||
        recycleCustomerExtraFields.includes('ADDRESS')) && (
        <>
          <Field
            component={Input}
            placeholder={t({
              id: 'forms.contact.placeholder.address',
              message: `Address`,
            })}
            name='address'
            validate={[required(), length({ max: 255 })]}
            size='medium'
            variant='underline'
            testSelector='address'
          />

          <Field
            component={Input}
            placeholder={t({
              id: 'forms.contact.placeholder.postalcode',
              message: `Postal Code`,
            })}
            name='postalCode'
            validate={[
              required(),
              postcodeValidator({ country: country }),
              length({ max: 45 }),
            ]}
            size='medium'
            variant='underline'
            testSelector='postalCode'
          />
        </>
      )}

      {mode === 'endUser' && (
        <>
          {recycleCustomerExtraFields.includes('TERMS') && (
            <Field
              component={renderCheckbox}
              placeholder={
                <Trans
                  // eslint-disable-next-line react/jsx-no-bind
                  render={({ translation }) => (
                    <TransOverride
                      translation={translation}
                      id='trade.in.terms.conditions'
                    />
                  )}
                  id='trade.in.terms.conditions'
                >
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href={`https://cdn.financetech365.com/legal/${country}/${wlCode}/mediamarkt-terms-and-conditions.pdf`}
                  >
                    I accept the terms and conditions
                  </a>
                </Trans>
              }
              validate={[acceptance()]}
              name='termsAndConditions'
            />
          )}

          {requireIDPhotoOnOnlineTradein && (
            <CustomerIDCheck
              disabled={false}
              image={idPhoto}
              setImage={setIdPhoto}
              country={country}
            />
          )}
        </>
      )}
    </>
  );
};

const ExtraWhitelabelFields = ({
  showMarketingOptIn,
  showSapCustomerForm,
  wlName,
}) => (
  <Fragment>
    {showSapCustomerForm && (
      <Field
        component={Input}
        placeholder={t({
          id: 'forms.contact.placeholder.customerReference',
          message: `SAP Customer Reference`,
        })}
        name='customerReference'
        size='medium'
        variant='underline'
      />
    )}

    {showMarketingOptIn && (
      <Field
        component={renderCheckbox}
        placeholder={t({
          id: 'forms.contact.placeholder.marketingOptIn',
          message: `Subscribe to receive updates from ${wlName} via our email newsletter`,
        })}
        name='marketingOptIn'
        classes='marketingOptInToggle'
      />
    )}
  </Fragment>
);

const RecycleCustomerInfo = ({
  recycleCustomerExtraFields,
  country,
  countryPhoneCodes = [],
  disabled = false,
  handleSubmit,
  invalid,
  mode,
  requireIDPhotoOnOnlineTradein = false,
  showAddressFields = false,
  showMarketingOptIn,
  showSapCustomerForm,
  submitLabel = t({
    id: 'forms.recycle.customer.submit.button',
    message: `Add to Upgrade plan`,
  }),
  submitFunction,
  submitting,
  wlName = '',
  wlCode = '',
}) => {
  const [idPhoto, setIdPhoto] = useState(null);
  const isFormDisabled =
    submitting ||
    invalid ||
    disabled ||
    (requireIDPhotoOnOnlineTradein && mode === 'endUser' && isEmpty(idPhoto));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(stopSubmit('recycleCustomerInfo'));
  });

  return (
    <form className='recycle-customer-info' onSubmit={handleSubmit}>
      <Field
        component={Input}
        placeholder={t({
          id: 'forms.contact.placeholder.firstname.lastname',
          message: `Customer first / last name`,
        })}
        name='name'
        validate={[required(), length({ max: 255 })]}
        size='medium'
        variant='underline'
      />

      <Field
        component={Input}
        placeholder={t({
          id: 'forms.contact.placeholder.email',
          message: `E-mail address`,
        })}
        name='email'
        size='medium'
        variant='underline'
        validate={[required(), email(), length({ max: 255 })]}
      />

      <Field
        component={Input}
        placeholder={t({
          id: 'forms.contact.placeholder.phone',
          message: `Phone number`,
        })}
        name='phone'
        size='medium'
        variant='underline'
        validate={[
          required(),
          format({ with: phoneRegex }),
          length({ max: 45 }),
          phoneValidator({
            country,
            validateWLCountry: true,
            countryPhoneCodes,
          }),
        ]}
        helpText={t({
          id: 'forms.contact.helper.phone.number',
          message: `Please enter your country phone number`,
        })}
      />

      {showAddressFields && <AddressFields country={country} />}

      <ExtraCustomerFields
        recycleCustomerExtraFields={recycleCustomerExtraFields}
        country={country}
        idPhoto={idPhoto}
        showExtraAddressFields={!showAddressFields}
        mode={mode}
        requireIDPhotoOnOnlineTradein={requireIDPhotoOnOnlineTradein}
        setIdPhoto={setIdPhoto}
        wlCode={wlCode}
      />

      <ExtraWhitelabelFields
        showSapCustomerForm={showSapCustomerForm}
        showMarketingOptIn={showMarketingOptIn}
        wlName={wlName}
      />

      <Button
        type='submit'
        disabled={isFormDisabled}
        onClick={handleSubmit((data) => {
          const { collectionDate, collectionTimeSlotCode, ...customer } = data;
          return submitFunction({
            customer,
            collectionDate,
            collectionTimeSlotCode,
            idPhoto,
          });
        })}
      >
        {submitLabel}
      </Button>
      {mode === 'endUser' && (
        <div className='recycle-disclaimer'>
          <Trans
            render={({ translation }) => (
              <TransOverride
                translation={translation}
                id='trade.in.disclaimer'
              />
            )}
            id='trade.in.disclaimer'
          />
        </div>
      )}
    </form>
  );
};

const ReduxRecycleCustomerInfo = reduxForm({
  form: 'recycleCustomerInfo',
  destroyOnUnmount: true,
})(RecycleCustomerInfo);

export default connect((state) => ({
  country: state.whitelabel.country,
  formValues: getFormValues('recycleCustomerInfo')(state),
}))(ReduxRecycleCustomerInfo);
