import {
  changeTestContractStageAction,
  expireTestContractDelayAction,
  getTestContractAction,
} from 'actions/admin/testContracts';
import { closeModalAction, openModalAction } from 'actions/ui';
import { required } from 'helpers/validators';

import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';

import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import Form from 'components/Form';
import Heading from 'components/Heading';
import Modal from 'components/Modal';
import RenderDetails from 'components/RenderDetails';
import Table from 'components/table/Table';

import { triggerEventsDict } from 'constants/dictionaries';

import './TestContractDetails.styl';

export const TestContractDetails = ({
  availableStages,
  changeTestContractStage,
  testContractMessages = [],
  testContract,
  getTestContract,
  expireTestContractDelay,
  openModal,
  closeModal,
}) => {
  const { testContractID } = useParams();

  const columns = [
    {
      label: t({ id: 'table.column.contract.uuid', message: `Contract` }),
      accessor: 'cartUuid',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.campaing.id', message: `Campaing ID` }),
      accessor: 'campaignId',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.message.email', message: `Email` }),
      accessor: 'destination',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.template.name', message: `Template name` }),
      accessor: 'templateName',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.stage', message: `Stage` }),
      accessor: 'stage',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({ id: 'table.column.send.status', message: `Send Status` }),
      accessor: 'sendStatus',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
    {
      label: t({
        id: 'table.column.delivery.status',
        message: `Delivery Status`,
      }),
      accessor: 'deliveryStatus',
      type: 'text',
      filterable: false,
      sortable: true,
      align: 'center',
      wrap: false,
    },
  ];

  useEffect(() => {
    getTestContract(testContractID);
  }, [testContractID, getTestContract]);

  const config = [
    {
      label: t({ id: 'test.contract.id', message: `Contract ID` }),
      accessor: 'contractID',
      type: 'text',
    },
    {
      label: t({ id: 'test.contract.campaign.id', message: `Campaign ID` }),
      accessor: 'campaignID',
      type: 'text',
    },
    {
      label: t({ id: 'test.contract.payment.plan', message: `Payment Plan` }),
      accessor: 'paymentPlan',
      type: 'text',
    },
    {
      label: t({ id: 'test.contract.device', message: `Device` }),
      accessor: 'device',
      type: 'text',
    },
    {
      label: t({
        id: 'test.contract.trigger.start.date',
        message: `Trigger Start Date`,
      }),
      accessor: 'triggerStartDate',
      type: 'date',
    },
    {
      label: t({
        id: 'test.contract.trigger.end.date',
        message: `Trigger End Date`,
      }),
      accessor: 'triggerEndDate',
      type: 'date',
    },
    {
      label: t({ id: 'test.contract.trigger.event', message: `Trigger Event` }),
      accessor: 'triggerEvent',
      type: 'dict',
      list: triggerEventsDict(),
    },
    {
      label: t({ id: 'test.contract.email.address', message: `Email Address` }),
      accessor: 'email',
      type: 'text',
    },
    {
      label: t({ id: 'test.contract.phone.number', message: `Phone Number` }),
      accessor: 'phone',
      type: 'text',
    },
    {
      label: t({ id: 'test.contract.stage', message: `Stage` }),
      accessor: 'stage',
      type: 'text',
    },
  ];

  return (
    <Fragment>
      <div className='test-contract-details'>
        <Button
          // TODO make this component reliant only on hooks
          // in order to remove arrow functions and enforce this rule
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => {
            openModal('admin-test-contract-stage');
          }}
          classes='admin-main-action'
        >
          <Trans id='admin.test.contracts.change.stage'>Change Stage</Trans>
        </Button>

        <Button
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => {
            expireTestContractDelay(testContractID);
          }}
          classes='admin-main-action'
        >
          <Trans id='admin.test.contracts.expire.delay'>Expire Delay</Trans>
        </Button>

        <Heading size='big'>
          <Trans id='test.contract.details.title'>Test Contract Details</Trans>
        </Heading>
        <RenderDetails
          config={config}
          data={testContract}
          name={'test-contract-details'}
        />
        <Heading size='big'>
          <Trans id='test.contract.messages.title'>
            Test Contract Messages
          </Trans>
        </Heading>

        <Table
          columns={columns}
          data={testContractMessages}
          keyColumn='id'
          defaultDir='desc'
        />
      </div>
      <Modal name='admin-test-contract-stage'>
        <Form
          initialValues={{ stage: testContract?.stage }}
          form='testContractStageForm'
          fields={[
            {
              component: Dropdown,
              options: availableStages,
              placeholder: t({
                id: 'forms.contact.placeholder.stage',
                message: `Stage`,
              }),
              name: 'stage',
              size: 'medium',
              variant: 'underline',
              validate: [required()],
            },
          ]}
          submitLabel='Submit'
          // eslint-disable-next-line react/jsx-no-bind
          onSubmit={async (values) => {
            changeTestContractStage(testContractID, values);
            closeModal('admin-test-contract-stage');
          }}
        />
      </Modal>
    </Fragment>
  );
};

export default connect(
  ({ whitelabel, adminTestContract }) => ({
    currency: whitelabel.currency,
    availableStages: adminTestContract?.availableStages?.map((campaignCode) => {
      return { value: campaignCode, label: campaignCode };
    }),
    testContractMessages: adminTestContract?.testContractMessages,
    testContract: adminTestContract?.testContract,
    language: whitelabel.language,
  }),
  {
    getTestContract: getTestContractAction,
    changeTestContractStage: changeTestContractStageAction,
    expireTestContractDelay: expireTestContractDelayAction,
    openModal: openModalAction,
    closeModal: closeModalAction,
  },
)(TestContractDetails);
